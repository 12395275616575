// import { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { RegularClientUserFragment } from '../../../generated/graphql';
import { IColumns } from '../../../Interfaces/IColumns';
import { getRoleRu } from '../../../utils/get/getRoleRu';
import { useStore } from '../../../store';
import ReviewIcon from '../../../Assets/icons/ReviewIcon';
import { roles } from 'src/utils/constVariables';
import { autoRateColor } from 'src/utils/autoRateReduce';
import Check from 'src/Assets/icons/check';
import Across from 'src/Assets/icons/Across';
// import Tooltip from '../../ui/Tooltip';

interface IUsersRow {
  user: RegularClientUserFragment;
  columns: IColumns[];
}

const WorkersRow: React.FC<IUsersRow> = ({ user, columns }) => {
  // const [showTooltip, setShowTooltip] = useState(true);
  const { me } = useStore();
  const userAutoRating = user?.userAutoRating;

  const userFacilities = () => {
    if (user.role === roles.Worker) {
      return [
        ...new Map(
          user?.workposts?.map(item => [item['facilityId'], item])
        ).values(),
      ].map(workpost => (
        <div key={workpost.facilityId}>
          {me?.role === roles.Admin ? (
            <Link
              to={`/facilities/${workpost.facilityId}`}
              className="text-primary underline"
            >
              {workpost.facility?.name}
            </Link>
          ) : (
            <span>{workpost.facility?.name}</span>
          )}
        </div>
      ));
    }

    if (user.role === roles.Foreman) {
      return user.Facility_FOREMAN?.map(facility => (
        <div key={facility.id}>
          {me?.role === roles.Admin ? (
            <Link
              to={`/facilities/${facility.id}`}
              className="text-primary underline"
            >
              {facility?.name}
            </Link>
          ) : (
            <span>{facility?.name}</span>
          )}
        </div>
      ));
    }
    if (user.role === roles.Supervisor) {
      return user.Facility_SUPERVISOR?.map(facility => (
        <div key={facility.id}>
          {me?.role === roles.Admin ? (
            <Link
              to={`/facilities/${facility.id}`}
              className="text-primary underline"
            >
              {facility?.name}
            </Link>
          ) : (
            <span>{facility?.name}</span>
          )}
        </div>
      ));
    }
    return null;
  };

  return (
    <div
      key={user.id}
      className="grid table__row w-fit overflow-x-auto"
      style={{
        gridTemplateColumns: columns
          .map(
            column =>
              'minmax(' +
              column.style.minWidth +
              'px' +
              ', ' +
              column.style.width +
              'px)'
          )
          .join(' '),
        columnGap: 10,
      }}
    >
      <div className={columns[0].className}>
        <Link
          to={`/users/${user.id}`}
          className="Table-small text-primary hover:underline"
        >
          {user.lastname ? user.lastname : 'Нет информации'}
        </Link>
      </div>
      <div className={columns[1].className}>{user.firstname}</div>
      <div className={columns[2].className}>{user.patronymic}</div>
      <div className={columns[3].className}>{user.phone}</div>
      <div className={columns[4].className}>{getRoleRu(user.role)}</div>
      <div className={columns[5].className}>{userFacilities()}</div>
      <div className={columns[6].className}>
        {user.activeShifts ? (
          <span className="text-smena_green-dark">
            <Check />
          </span>
        ) : (
          <Across color="text-smena_red" />
        )}
      </div>
      <div
        className={clsx(
          columns[7].className,
          user.hideRating ? '' : autoRateColor(userAutoRating?.rating)
        )}
      >
        {user.hideRating ? 'Еще нет рейтинга' : userAutoRating?.rating}
      </div>
      <div className={columns[8].className}>
        <Link
          to={`/users/${user.id}/questionnaire`}
          className="inline-block"
          // onMouseEnter={() => {
          //   setShowTooltip(true);
          // }}
          // onMouseLeave={() => {
          //   setShowTooltip(false);
          // }}
        >
          <ReviewIcon />
        </Link>
        {/* {showTooltip && <Tooltip content="Оценить" />} */}
      </div>
    </div>
  );
};
export default WorkersRow;
