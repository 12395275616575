import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import MainLayout from 'src/components/Layout/main';
import Loading from 'src/components/ui/Loading';
import { useUserQuery } from 'src/generated/graphql';
import { roles } from 'src/utils/constVariables';
import { useStore } from 'src/store';
import ReviewOutlineIcon from 'src/Assets/icons/ReviewOutlineIcon';
import { UserNameSurename } from 'src/utils/userFullName';
import Questionnaire from 'src/components/users/Questionnaire';
import RateBlock from 'src/components/users/Questionnaire/Rate';
import ReviewBlock from 'src/components/users/Questionnaire/Review';
import HistoryBlock from 'src/components/users/Questionnaire/HistoryBlock';
import CreateReviewSidebar from 'src/components/users/Questionnaire/CreateReviewSidebar';
import useUserHeaderMenu from 'src/hooks/headerMenu/useUserHeaderMenu';
import useUserBreadcrumbs from 'src/hooks/breadcrumbs/useUserBreadcrumbs';

export const QuestionnairePage = () => {
  const { me } = useStore();
  const router = useParams();

  const [showSidebar, setShowSidebar] = useState(false);

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const { data, loading } = useUserQuery({
    variables: {
      userId,
    },
  });

  const user = data?.user;

  const isMeAdminSuper = me && [roles.Admin, roles.Supervisor].includes(me.role);
  const isCurrentUserWorkerOrForeman = user && [roles.Worker, roles.Foreman].includes(user.role);
  const crudUserTitle = useMemo(() => {
    if (user) {
      if (isMeAdminSuper) {
        return 'Анкета пользователя ' + UserNameSurename(user);
      }
      return 'Анкета исполнителя ' + UserNameSurename(user);
    }
  }, [user, isMeAdminSuper]);

  const headerMenu = useUserHeaderMenu({
    currentLocation: 'Анкета',
    userId,
    role: user?.role,
    isMeAdminSuper,
    isCurrentUserWorkerOrForeman,
  });

  const breadCrumbs = useUserBreadcrumbs({ isMeAdminSuper, userId });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <MainLayout
        title={crudUserTitle}
        bg="bg-smena_bb-background_base"
        headerMenu={headerMenu}
        breadCrumbs={breadCrumbs}
      >
        <div className="grid grid-cols-12 gap-6">
          <div className="userPageBlock blockTemplate">
            <div className="grid justify-items-end">
              <button
                type="button"
                className="btn-primary_big flex gap-x-2"
                onClick={() => {
                  setShowSidebar(value => !value);
                }}
              >
                <ReviewOutlineIcon />
                <span>Оценить</span>
              </button>
            </div>
          </div>

          <Questionnaire user={user} />
          <RateBlock user={user} />
          <ReviewBlock user={user} />
          <HistoryBlock user={user} />
        </div>
      </MainLayout>
      <CreateReviewSidebar user={user} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
    </>
  );
};
