import { useCallback, useMemo } from 'react';
import { RegularUserShiftsFragment, ShiftStage } from 'src/generated/graphql';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';
import { useMeRole } from '../../../hooks/useRole';

const useRestShits = ({
  userShifts,
}: {
  userShifts?: RegularUserShiftsFragment[];
}) => {
  const { hiddenCanceledShifts, superShifts, facilityId, workpostId } = useStore();

  const isMeSupervisor = useMeRole(roles.Supervisor)

  const filterCanceledShifts = useCallback(
    (shift?: RegularUserShiftsFragment[]) => shift?.filter(shift => ![ShiftStage.Refused, ShiftStage.Ineligible].includes(shift.stage)),
    []
  );

  return useMemo(() => {
    const roleShifts = isMeSupervisor ? superShifts : userShifts
    const shifts = (hiddenCanceledShifts ? filterCanceledShifts(roleShifts) : roleShifts) || []
    return shifts.filter(shift => {
      const isPositionFits = workpostId ? shift.positionId !== workpostId : false;
      const shiftFacilityId = shift.facility?.id;
      const isFacilityFits = facilityId ? shiftFacilityId !== facilityId : false;
      return isFacilityFits ? isFacilityFits && isPositionFits : isPositionFits;
    })

  },[facilityId, filterCanceledShifts, hiddenCanceledShifts, isMeSupervisor, superShifts, userShifts, workpostId])
};
export default useRestShits;
