import React, { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';

import {
  FacilityGroupsDocument,
  FacilityGroupsQueryVariables,
  useAddDirectorToFacilityGroupMutation,
  useCreateFacilityGroupMutation,
  useFacilityGroupQuery,
  useFacilityGroupUploadImageMutation,
  useUpdateFacilityGroupMutation,
} from '../../generated/graphql';

import MainLayout from '../../components/Layout/main';
import SimpleInput from '../../components/ui/Input';

import { useStore } from '../../store';
import camera from '../../images/Subtract.svg';
import { errorToast, successToast } from '../../utils/notify';
import { errorHandler } from '../../utils/errorHandler';
// import Manager from '../../components/users/Managament/Manager';
import { roles } from '../../utils/constVariables';
import ResponsibleUser from '../../components/facility/ResponsibleUsers';
import SelectArrow from '../../Assets/icons/SelectArrow';
import { IOption } from 'src/Interfaces/IOption';
// import ResponsibleUser from '../../components/facilities/ResponsibleUsers';
// import { Autocomplete, TextField } from '@mui/material';
// import SelectArrow from '../../Assets/icons/SelectArrow';

const UpdateGroup: React.FC = () => {
  const [imgSrc, setImgSrc] = useState('');

  const router = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  let groupId = '';

  if (typeof router.groupId === 'string') {
    groupId = router.groupId;
  }
  const { files, setFiles, me } = useStore();

  const { data } = useFacilityGroupQuery({
    variables: { id: groupId },
  });

  const group = data?.facilityGroup;

  const [addDirectorToFacilityGroup] = useAddDirectorToFacilityGroupMutation();

  const directors = group?.clientDirectors;

  const directorsArr = directors?.map(director => {
    const { id, lastname, firstname } = director;
    return {
      name: lastname + ' ' + firstname,
      id,
    };
  });

  const managers = group?.groupManagers;

  const groupsQueryConfig: {
    query: typeof FacilityGroupsDocument;
    variables: FacilityGroupsQueryVariables;
  } = {
    query: FacilityGroupsDocument,
    variables: {},
  };

  const [uploadImage] = useFacilityGroupUploadImageMutation();
  const [updateGroup] = useUpdateFacilityGroupMutation();
  const [createGroup] = useCreateFacilityGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [groupsQueryConfig],
  });

  let inputRef: HTMLInputElement | null;

  const onChange = ({ target: { validity, files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (validity.valid && files) {
      const file = files.item(0);
      if (file) {
        if (file.size > 1024 * 100) {
          return errorToast('Допустимый вес картинки - до 100 Кб');
        }
        setFiles([file]);
        setImgSrc(URL.createObjectURL(file));
      }
    }
  };

  const dragOverHandle = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragLeaveHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragEnterHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dropHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setImgSrc(URL.createObjectURL(e.dataTransfer.files[0]));
    }
  };

  const title = useMemo(() => {
    if (group) {
      return `Группа ${group.name}`;
    }
    return 'Создать группу';
  }, [group]);

  const updateGroupFn = useCallback(
    (name: string) => {
      updateGroup({
        variables: {
          id: groupId,
          name,
        },
      })
        .then(async response => {
          if (response.data?.updateFacilityGroup) {
            successToast('Группа изменена');
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    },
    [groupId, updateGroup]
  );

  const onLoadHandler = useCallback(
    (e: React.ChangeEvent<HTMLImageElement>) => {
      const img = e.currentTarget;
      const height = img.naturalHeight;
      const width = img.naturalWidth;
      if (height > 500 || width > 500) {
        setFiles([]);
        setImgSrc('');
        errorToast('Допустимые размеры картинки - 500х500');
      }
    },
    [setFiles]
  );

  const addDirectorToFacilityGroupHandler = useCallback(
    async (userId?: string) => {
      if (userId) {
        await addDirectorToFacilityGroup({
          variables: {
            groupId,
            userId,
          },
        })
          .then(() => successToast('Директор успешно добавлен'))
          .catch(err => errorToast(errorHandler(err)));
      }
    },
    [addDirectorToFacilityGroup, groupId]
  );

  return (
    <MainLayout
      title={title}
      bg="bg-smena_bb-background_base"
      headerMenu={[
        { name: 'Информация', link: location.pathname },
        {
          name: 'Объекты группы',
          link: '/groups/' + groupId + '/facilities',
          disabled: !group?.id,
        },
      ]}
      breadCrumbs={[
        { path: '/groups', name: 'Группы' },
        group?.id
          ? { path: '/groups/' + groupId, name: 'Страница группы' }
          : { path: '/groups/create', name: 'Новая группа' },
      ]}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: group?.name || '',
          imageURL: group?.logo?.path,
          director: null as unknown as IOption | null,
        }}
        validate={values => {
          let errors: any = {};
          if (!values.name) {
            errors.name = 'Вы не указали название группы';
          }
          return errors;
        }}
        onSubmit={async values => {
          const name = values.name.trim();
          if (group?.id) {
            if (files.length === 1) {
              await uploadImage({
                variables: {
                  id: groupId,
                  image: files[0],
                },
              })
                .then(() => {
                  successToast('Фото загружено');
                  updateGroupFn(name);
                })
                .catch(e => {
                  errorToast(errorHandler(e));
                  setFiles([]);
                  setImgSrc('');
                });
            } else {
              updateGroupFn(name);
            }
          } else {
            await createGroup({
              variables: {
                name,
              },
            })
              .then(async response => {
                successToast('Группа создана');
                const newGroup = response.data?.createFacilityGroup;
                if (newGroup) {
                  if (files.length === 1) {
                    await uploadImage({
                      variables: {
                        id: newGroup.id,
                        image: files[0],
                      },
                    })
                      .then(() => {
                        successToast('Фото загружено');
                      })
                      .catch(e => errorToast(errorHandler(e)));
                  }
                  setTimeout(() => {
                    navigate(`/groups/${newGroup.id}`);
                  }, 2000);
                }
              })
              .catch(e => errorToast(errorHandler(e)));
          }
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
          const imagePath = process.env.REACT_APP_PUBLIC_DOMAIN + '/' + values.imageURL;
          return (
            <form onSubmit={handleSubmit} className="grid grid-cols-12 gap-6">
              <div className="groupPageBlock blockTemplate">
                <span className="blockTitleTemplate">Основная информация</span>
                <div className="flex sm:flex-row flex-col justify-between gap-5">
                  <div className="flex flex-col gap-y-5">
                    <SimpleInput
                      divClassName="crudUserMulty"
                      label="Название группы"
                      placeholder="Введите название группы"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      type="text"
                      name="name"
                      error={touched.name && errors.name ? errors.name : ''}
                    />
                  </div>

                  <input
                    type="file"
                    onChange={onChange}
                    name="fileUpload"
                    id="fileUpload"
                    ref={refParam => (inputRef = refParam)}
                    className="hidden"
                    accept="image/png, image/jpeg"
                    value=""
                  />
                  <div className="flex flex-col items-center" style={{ width: '210px' }}>
                    <button
                      className="uploadButton rounded-full border-primary
                      overflow-hidden flex justify-center items-center"
                      type="button"
                      onDragOver={dragOverHandle}
                      onDragLeave={dragLeaveHandler}
                      onDrop={dropHandler}
                      onDragEnter={dragEnterHandler}
                      onClick={() => inputRef?.click()}
                    >
                      {imgSrc || values.imageURL ? (
                        <img
                          src={imgSrc ? imgSrc : process.env.REACT_APP_PUBLIC_DOMAIN + '/' + values.imageURL}
                          alt=""
                          onLoad={onLoadHandler}
                          className="rounded-full object-cover"
                          style={{ width: '175px', height: '175px' }}
                        />
                      ) : (
                        <div
                          className="bg-smena_bb-background flex justify-center items-center rounded-full"
                          style={{ width: '175px', height: '175px' }}
                        >
                          <img
                            src={values.imageURL ? imagePath : camera}
                            alt=""
                            style={{ width: '70px', height: '59px' }}
                          />
                        </div>
                      )}
                    </button>
                    <span className="Table text-smena_text px-4 pt-4 text-center">Нажмите, чтобы добавить логотип</span>
                  </div>
                </div>
              </div>
              {me && [roles.Admin, roles.Supervisor].includes(me.role) && (
                <div className="groupPageBlock blockTemplate">
                  <div className="grid grid-cols-6 gap-x-5">
                    <span className="blockTitleTemplate">Представители</span>
                    <div className="col-span-full grid grid-cols-8 gap-x-5 pb-10 mb-5 border-b border-smena_gray-30">
                      <div className="sm:col-span-4 col-span-8">
                        <div
                          className="grid gap-x-2 items-baseline"
                          style={{
                            gridTemplateColumns: 'max-content 1fr',
                          }}
                        >
                          <span className="Subtitle2">Директор</span>
                          <div>
                            {directors?.length ? (
                              directors.map(clientDirectors => (
                                <ResponsibleUser
                                  key={clientDirectors.id}
                                  user={clientDirectors}
                                  // canRemove={false}
                                />
                              ))
                            ) : (
                              <span className="Body2 text-smena_text-secondary">Не назначен</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-4 col-span-8">
                        <div
                          className="grid gap-x-2 items-baseline"
                          style={{
                            gridTemplateColumns: 'max-content 1fr',
                          }}
                        >
                          <span className="Subtitle2">Менеджер</span>
                          <div>
                            {managers?.length ? (
                              managers.map(manager => (
                                <ResponsibleUser key={manager.id} user={manager} canRemove={false} />
                              ))
                            ) : (
                              <span className="Body2 text-smena_text-secondary">Не назначен</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-3 col-span-full grid grid-cols-5 sm:mb-0 mb-2 gap-x-2">
                      <div className="col-span-3">
                        <label htmlFor="managerId" className="label-primary">
                          Директор
                        </label>
                        <Autocomplete
                          id="managerId"
                          value={values.director}
                          isOptionEqualToValue={(option: IOption | any, value: IOption) =>
                            value === undefined || value.id === '' || option.id === value.id
                          }
                          noOptionsText="Ничего не найдено"
                          getOptionLabel={option => option.name}
                          onChange={(_, value: { name: string; id: string } | null) => {
                            setFieldValue('director', value);
                          }}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                          options={directorsArr ? directorsArr : []}
                          popupIcon={<SelectArrow className="text-smena_text" />}
                          renderInput={params => <TextField {...params} placeholder="Не выбран" />}
                        />
                      </div>
                      <div className="self-end col-span-2">
                        <button
                          className="btn-secondary_small w-full"
                          onClick={() => {
                            addDirectorToFacilityGroupHandler(values.director?.id);
                          }}
                          type="button"
                        >
                          <span>Добавить</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="groupPageBlock justify-self-end py-5">
                <button type="submit" disabled={isSubmitting} className="btn-primary_big">
                  {group?.id ? 'Изменить' : 'Создать группу'}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdateGroup;
