import React, { useState } from 'react';
import clsx from 'clsx';

import { useStore } from '../../../../../store';

import { getStatus } from '../../../../../utils/get/getStatus';
import { durationFn } from '../../../../../utils/durationFn';
import { showTime } from '../../../../../utils/showTime';

import CoffeIcon from '../../../../../Assets/icons/coffeIcon';
import Tooltip from '../../../../ui/Tooltip';
import { ICustomEvent } from '../../../../../Interfaces/IEvent';
import { scheduleClassName } from 'src/utils/scheduleClassName';
import { roles } from 'src/utils/constVariables';
import { positionName } from 'src/utils/filters/filtersName';

const CustomEvent = (event: { event: ICustomEvent }) => {
  const [tooltip, setTooltip] = useState(false);
  const { positions, facilityId, workpostId, nonSuperShifts, me } = useStore();

  const isMeSupervisor = me?.role === roles.Supervisor;

  const {
    resource: { stage, facility, comment, lunchDuration, id, positionId },
    title,
  } = event.event;

  const { className } = scheduleClassName(facilityId, workpostId, event.event.resource, nonSuperShifts, isMeSupervisor);

  return (
    <div className="flex flex-col gap-y-1 mb-4 p-4 border rounded-lg shadow-smena bg-smena_white">
      <div className="flex justify-between relative">
        <div className="flex gap-x-1 Caption-numbers text-smena_text">
          <span>{facility?.name};</span>
          <span>{positionName(positions, positionId)}</span>
        </div>
      </div>
      <span className="Tag text-smena_text-secondary">{getStatus(stage)}</span>
      {comment && (
        <div className="flex gap-x-1 Tag">
          <span className="text-smena_text">Комментарий:</span>
          <span className="text-smena_text-secondary">{comment}</span>
        </div>
      )}
      <div className="flex gap-x-2">
        <span className={clsx('Tag text-smena_gray-80 px-6 rounded h-[17px]', className)}>{title}</span>
        <span
          className="Tag text-smena_gray-80 bg-smena_bb-background px-2 flex justify-center items-center relative rounded h-[17px]"
          onMouseEnter={() => {
            setTooltip(true);
          }}
          onMouseLeave={() => {
            setTooltip(false);
          }}
        >
          <CoffeIcon />
          <span className="pl-1 Tag text-smena_text-secondary">
            {lunchDuration ? showTime(durationFn(lunchDuration)) : 'Нет обеда'}
          </span>
          {tooltip && <Tooltip content="Время обеда" />}
        </span>
      </div>
      <div className="Tag text-smena_text-helper mt-3 gap-x-1">
        <span>id смены:</span>
        <span>{id}</span>
      </div>
    </div>
  );
};

export default CustomEvent;
