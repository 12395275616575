import dayjs from 'dayjs';

export const resetTime = (date: Date) => {
  return dayjs(date)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0)
    .valueOf();
};
