import { useMemo } from 'react';

interface IUseUserBreadcrums {
  isMeAdminSuper?: boolean;
  userId: string;
}

const useUserBreadcrumbs = ({ isMeAdminSuper, userId }: IUseUserBreadcrums) => {
  return useMemo(
    () => [
      {
        path: '/users',
        name: isMeAdminSuper ? 'Пользователи' : 'Мои исполнители',
      },
      userId
        ? {
            path: '/users/' + userId,
            name: isMeAdminSuper ? 'Страница пользователя' : 'Страница исполнителя',
          }
        : {
            path: '/users/create',
            name: isMeAdminSuper ? 'Новый пользователь' : 'Новый исполнитель',
          },
    ],
    [isMeAdminSuper, userId]
  );
};

export default useUserBreadcrumbs;
