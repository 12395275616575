import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import SimpleInput from '../../ui/Input';
import { PhotoPopup } from './Popup';
import { IPhotoPopup } from '../../../Interfaces/IPhotoPopup';
import { PhotoBlock } from './PhotoBlock';
import { ApproveBlock } from './ApproveBlock';
import { DocumentType, ReviewType, useChangePersonalInfoPassportMutation } from '../../../generated/graphql';
import { errorToast, successToast } from '../../../utils/notify';
import { errorHandler } from '../../../utils/errorHandler';
import { useStore } from '../../../store';
import {
  photoRejectsList,
  photoWithoutFaceRejectsList,
  photoWithoutSelfieRejectsList,
} from '../../../utils/lists/commentsList';
import { getVerificationDate } from '../../../utils/get/getVerificationDate';
import { userDocumentsQueryConfigFn } from '../../../utils/graphqlConfigs/userDocumentsQueryConfig';
import { ISO_DATE, roles, RU_DATE } from '../../../utils/constVariables';
import DatePickerInput from '../../ui/Pickers/DatePickerInput';

export const PassportPage = () => {
  dayjs.locale('ru');
  const router = useParams();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const { userRequisites, userReviews, me } = useStore();

  const [popup, setPopup] = useState<IPhotoPopup>();
  const [showPopup, setShowPopup] = useState(false);

  const firstPageRef = useRef<HTMLInputElement>(null);
  const regPageRef = useRef<HTMLInputElement>(null);
  const photoPageRef = useRef<HTMLInputElement>(null);

  const firstPageBlockRef = useRef<HTMLInputElement>(null);
  const regPageBlockRef = useRef<HTMLInputElement>(null);
  const photoPageBlockRef = useRef<HTMLInputElement>(null);

  const userQueryDocumentsConfig = userDocumentsQueryConfigFn(userId);

  const [changePassport] = useChangePersonalInfoPassportMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryDocumentsConfig],
  });

  if (!me) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        seria: userRequisites?.passportSeries || '',
        number: userRequisites?.passportNumber || '',
        getDate: userRequisites?.passportIssuedDate ? dayjs(userRequisites?.passportIssuedDate) : null,
        whoGive: userRequisites?.passportIssuedBy || '',
        passportRegistrationAddress: userRequisites?.passportRegistrationAddress || '',
      }}
      validate={values => {
        let errors: any = {};
        if (!values.getDate) {
          errors.getDate = 'Обязательное поле';
        }

        return errors;
      }}
      onSubmit={async values => {
        await changePassport({
          variables: {
            input: {
              userId,
              passportSeries: values.seria,
              passportNumber: values.number,
              passportIssuedDate: dayjs(values.getDate).format(ISO_DATE),
              passportIssuedBy: values.whoGive,
              passportRegistrationAddress: values.passportRegistrationAddress,
            },
          },
        })
          .then(res => {
            if (res.data?.changePersonalInfoPassport) {
              successToast('Данные паспорта успешно изменены');
            }
          })
          .catch(err => errorToast(errorHandler(err)));
      }}
    >
      {({ values, touched, errors, handleChange, isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <div className="flex flex-col gap-y-4 pb-5 border-b border-smena_gray-5">
              <span className="Subtitle2 text-smena_text-secondary">Главный разворот</span>
              <div className="flex flex-wrap">
                <div className="flex flex-col border-r border-smena_gray-5 pr-5 gap-y-6 data-block flex-wrap">
                  <div className="flex gap-x-5">
                    <SimpleInput
                      divClassName="seria"
                      label="Серия"
                      placeholder="0000"
                      onChange={e => {
                        const value = e.target.value;
                        if (/[A-Za-z0-9]+$/.test(value) || value === '') {
                          handleChange(e);
                        }
                      }}
                      value={values.seria}
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      type="text"
                      name="seria"
                      autoComplete="seria"
                      validation={true}
                      error={touched.seria && errors.seria ? errors.seria : ''}
                    />
                    <SimpleInput
                      divClassName="number"
                      label="Номер"
                      placeholder="000000"
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      // maxLength={6}
                      onChange={e => {
                        const value = e.target.value;
                        if (/[A-Za-z0-9]+$/.test(value) || value === '') {
                          handleChange(e);
                        }
                      }}
                      value={values.number}
                      type="text"
                      name="number"
                      autoComplete="number"
                      validation={true}
                      error={touched.number && errors.number ? errors.number : ''}
                    />
                    {![roles.ClientManager, roles.ClientDirector].includes(me.role) ? (
                      <DatePickerInput
                        label="Дата выдачи"
                        divClassName="get-date"
                        value={values.getDate}
                        onChange={getDate => {
                          setFieldValue('getDate', getDate);
                        }}
                        maxDate={dayjs()}
                        error={{ bool: Boolean(touched.getDate && errors.getDate), text: errors.getDate }}
                      />
                    ) : values.getDate ? (
                      <div className="flex flex-col gap-x-5">
                        <label htmlFor="dateOfBirth" className="label-primary">
                          Дата рождения
                        </label>
                        <span>{dayjs(values.getDate).format(RU_DATE)}</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <label htmlFor="whoGive" className="label-primary">
                      Кем выдан
                    </label>
                    {![roles.ClientManager, roles.ClientDirector].includes(me.role) ? (
                      <textarea
                        className="textarea-primary documents-textarea"
                        name="whoGive"
                        value={values.whoGive}
                        onChange={handleChange}
                        placeholder="Отдел выдачи"
                      />
                    ) : (
                      <span>{values.whoGive}</span>
                    )}
                  </div>
                </div>
                <PhotoBlock
                  name="firstPage"
                  blockType={DocumentType.MainPage}
                  inputPhotoRef={firstPageRef}
                  inputPhotoBlockRef={firstPageBlockRef}
                  setPopup={setPopup}
                  setShowPopup={setShowPopup}
                />

                <ApproveBlock
                  reviewType={ReviewType.MainPage}
                  updatedAtBlock={userRequisites?.updatedAtPassport}
                  commentsList={photoWithoutSelfieRejectsList}
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-4 py-5 border-b border-smena_gray-5">
              <span className="Subtitle2 text-smena_text-secondary">Разворот с регистрацией</span>
              <div className="flex flex-wrap">
                <div className="flex flex-col border-r border-smena_gray-5 pr-5 gap-y-6 data-block">
                  <div className="flex flex-col gap-x-5">
                    <label htmlFor="passportRegistrationAddress" className="label-primary">
                      Адрес регистрации
                    </label>
                    {![roles.ClientManager, roles.ClientDirector].includes(me.role) ? (
                      <textarea
                        className="textarea-primary documents-textarea"
                        name="passportRegistrationAddress"
                        value={values.passportRegistrationAddress}
                        onChange={handleChange}
                        placeholder="Адрес регистрации"
                      />
                    ) : (
                      <span>{values.passportRegistrationAddress}</span>
                    )}
                  </div>
                </div>
                <PhotoBlock
                  name="regPage"
                  blockType={DocumentType.Registration}
                  inputPhotoRef={regPageRef}
                  inputPhotoBlockRef={regPageBlockRef}
                  setPopup={setPopup}
                  setShowPopup={setShowPopup}
                />
                <ApproveBlock
                  reviewType={ReviewType.Registration}
                  updatedAtBlock={userRequisites?.updatedAtPassport}
                  commentsList={photoWithoutFaceRejectsList}
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-4 py-5 border-smena_gray-5">
              <span className="Subtitle2 text-smena_text-secondary">Фото с паспортом</span>
              <div className="flex flex-wrap">
                <div className="flex flex-col border-r border-smena_gray-5 pr-5 gap-y-6 data-block">
                  <span className="Body2 text-smena_text-secondary">Убедитесь, что лица на фотографии похожи</span>
                </div>

                <PhotoBlock
                  name="photoPage"
                  blockType={DocumentType.Selfie}
                  inputPhotoRef={photoPageRef}
                  inputPhotoBlockRef={photoPageBlockRef}
                  setPopup={setPopup}
                  setShowPopup={setShowPopup}
                />
                <ApproveBlock
                  reviewType={ReviewType.Selfie}
                  updatedAtBlock={userRequisites?.updatedAtPassport}
                  commentsList={photoRejectsList}
                />
              </div>
            </div>
            <div className="flex justify-between items-center save-block flex-wrap">
              <div className="flex gap-x-1">
                <span className="Caption text-smena_text-secondary">Дата верификации:</span>
                <span className="Caption text-smena_text-secondary">
                  {getVerificationDate([ReviewType.MainPage, ReviewType.Registration, ReviewType.Selfie], userReviews)}
                </span>
              </div>
              <div className="flex gap-x-2 items-center flex-wrap">
                {userRequisites?.updatedAtPassport && (
                  <div className="flex gap-x-1">
                    <span className="Caption text-smena_text-secondary">Посл. изменения</span>
                    <span className="Caption text-smena_text-secondary">
                      {dayjs(userRequisites?.updatedAtPassport).format(RU_DATE)}
                    </span>
                  </div>
                )}
                {me && ![roles.ClientManager, roles.ClientDirector].includes(me.role) && (
                  <button type="submit" className="btn-primary_small" disabled={isSubmitting}>
                    Сохранить
                  </button>
                )}
              </div>
            </div>
            {showPopup && popup ? (
              <PhotoPopup popup={popup.popup} place={popup.place} setShowPopup={setShowPopup} />
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};
