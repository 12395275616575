import dayjs from 'dayjs';

import { ICustomEvent } from 'src/Interfaces/IEvent';
import { resetTime } from 'src/utils/resetTime';

const showSelectedShiftsAtSidebar = ({
  start,
  mainEvents,
  selectedSchedule,
  setSelectedSchedule,
  showSidebar,
  setShowSidebar,
  setCreateScheduleMenu,
}: {
  start: Date;
  mainEvents: ICustomEvent[];
  selectedSchedule?: ICustomEvent[];
  setSelectedSchedule: (value?: ICustomEvent[]) => void;
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
  setCreateScheduleMenu: (menu: boolean) => void;
}) => {
  const shifts = mainEvents.filter(
    event =>
      dayjs(event.start).startOf('day').valueOf() ===
      dayjs(start).startOf('day').valueOf()
  );

  if (
    selectedSchedule &&
    resetTime(start) === resetTime(selectedSchedule[0].start)
  ) {
    return;
  }

  if (showSidebar) {
    setCreateScheduleMenu(false);
    setShowSidebar(false);
    setTimeout(() => {
      if (shifts.length > 0) {
        setSelectedSchedule(shifts);
        setShowSidebar(true);
      }
    }, 400);
  } else {
    if (shifts.length > 0) {
      setSelectedSchedule(shifts);
      setShowSidebar(true);
    }
  }
};
export default showSelectedShiftsAtSidebar;
