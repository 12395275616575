import clsx from 'clsx';

import { ZayavkaChangesStatus, ZayavkaStage, ZayavkaToCancelStatus } from 'src/generated/graphql';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';
import { getRequestStatusRu } from 'src/utils/get/getRequestStatusRu';
import { getRequestClassName } from 'src/utils/get/getZayavkaStyle';
import StatusIcon from './helper/icon';

interface IRequestStatus {
  request?: {
    authorId: string;
    status: ZayavkaStage;
    zayavkaChanges?: { authorId: string; stage: ZayavkaChangesStatus }[] | null;
    toCancel: ZayavkaToCancelStatus;
  };
}

const RequestStatus: React.FC<IRequestStatus> = ({ request }) => {
  const { me } = useStore();

  const meSupervisor = me?.role === roles.Supervisor;

  const zayavkaStatus = (status?: ZayavkaStage) => {
    if (meSupervisor) {
      return getRequestStatusRu(status)?.supervisorStatus;
    }
    return getRequestStatusRu(status)?.clientStatus;
  };

  return (
    <div className="flex gap-x-1">
      <span
        className={clsx(
          'h-6 flex items-center justify-center rounded-full px-2 Table-small',
          getRequestClassName(request?.status)
        )}
      >
        {zayavkaStatus(request?.status)}
      </span>
      <StatusIcon request={request} />
    </div>
  );
};
export default RequestStatus;
