import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import MainSidebar from 'src/components/ui/Sidebar';
import {
  useDeleteZayavkaScheduleMutation,
  useFacilityLazyQuery,
  useUpdateZayavkaScheduleMutation,
} from 'src/generated/graphql';

import { zayavkaConfigFn } from 'src/utils/graphqlConfigs/zayavkaConfig';
import { useStore } from 'src/store';
import { errorToast, successToast } from 'src/utils/notify';
import { errorHandler } from 'src/utils/errorHandler';
import SimpleSelect from 'src/components/ui/Select';
import { scheduleTypes } from 'src/utils/lists/scheduleTypes';
import { LunchDurationSelect } from 'src/components/ui/Select/LunchDurationSelect';
import { auchanLunchDurationTypes, getLunchDuration, lunchDurationTypes } from 'src/utils/lists/lunchDurationTypes';
import { groupName, positionName } from 'src/utils/filters/filtersName';
import { timeDifference } from 'src/utils/timeDifference';
import { lunchDurationHandle } from 'src/utils/lunchDuration';
import { roles, RU_DATE, TIME_DATE } from 'src/utils/constVariables';
import { LunchDurationToString } from 'src/utils/lunchDurationToString';
import clsx from 'clsx';
import DatePickerInput from '../../../ui/Pickers/DatePickerInput';
import TimePickerInput from '../../../ui/Pickers/TimePicker';
import moment from 'moment';
import uniqueArray from '../../../../hooks/uniqueArray';

interface IEditSchedule {
  showEditGraphicSidebar: boolean;
  setShowEditGraphicSidebar: (value: boolean) => void;
}

const EditSchedule: React.FC<IEditSchedule> = ({ showEditGraphicSidebar, setShowEditGraphicSidebar }) => {
  const { groups, positions, request, requestSchedule, me } = useStore();
  const router = useParams();

  const meSupervisor = me?.role === roles.Supervisor;

  let requestIdURL = '';

  if (typeof router.requestId === 'string') {
    requestIdURL = router.requestId;
  }

  const [positionId, setPositionId] = useState('');
  const [shiftStartTime, setShiftStartTime] = useState<moment.Moment | null>(null);
  const [shiftEndTime, setShiftEndTime] = useState<moment.Moment | null>(null);
  const [shiftStartDate, setShiftStartDate] = useState<dayjs.Dayjs | null>(null);
  const [shiftEndDate, setShiftEndDate] = useState<dayjs.Dayjs | null>(null);
  const [lunchDuration, setLunchDuration] = useState(0);
  const [scheduleType, setScheduleType] = useState(1);
  const [errors, setErrors] = useState<any>({});

  const zayavkaConfig = zayavkaConfigFn(requestIdURL);

  const zayavkaRefetch = {
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfig],
  };

  const [loadFacility, { data: facilityData }] = useFacilityLazyQuery();

  const requestPositions = useMemo(
    () =>
      uniqueArray({
        field: 'id',
        array: request?.plans.map(plan => ({
          id: plan.positionId,
          name: positionName(positions, plan.positionId),
        })),
      }),
    [positions, request?.plans]
  );

  const [updateZayavkaSchedule] = useUpdateZayavkaScheduleMutation(zayavkaRefetch);

  const [deleteZayavkaSchedule] = useDeleteZayavkaScheduleMutation(zayavkaRefetch);

  let errorCounter = 0;
  const errorStateHandler = (field: string | dayjs.Dayjs | moment.Moment | null, name: string) => {
    if (!field) {
      setErrors((value: any) => {
        return {
          ...value,
          [name]: 'Обязательное поле',
        };
      });
      errorCounter += 1;
    } else {
      setErrors((value: any) => {
        return {
          ...value,
          [name]: '',
        };
      });
      if (errorCounter === 0) {
        errorCounter = 0;
      } else {
        errorCounter -= 1;
      }
    }
  };

  const deleteZayavkaScheduleHandler = async () => {
    await deleteZayavkaSchedule()
      .then(() => successToast('График удален'))
      .catch(e => errorToast(errorHandler(e)));
  };

  const updateZayavkaScheduleHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    errorStateHandler(positionId, 'positionId');
    errorStateHandler(shiftStartTime, 'shiftStartTime');
    errorStateHandler(shiftEndTime, 'shiftEndTime');
    errorStateHandler(shiftStartDate, 'shiftStartDate');
    errorStateHandler(shiftEndDate, 'shiftEndDate');

    if (errorCounter > 0) {
      return;
    }
    if (requestSchedule && request) {
      await updateZayavkaSchedule({
        variables: {
          id: requestSchedule.id,
          input: {
            dateStart: shiftStartDate
              ?.set('hours', moment(shiftStartTime).get('hours'))
              .set('minutes', moment(shiftStartTime).get('minutes')),
            dateEnd: shiftEndDate
              ?.set('hours', moment(shiftEndTime).get('hours'))
              .set('minutes', moment(shiftEndTime).get('minutes')),
            facilityId: request.facilityId,
            positionId,
            zayavkaId: requestIdURL,
            lunchDuration,
            dayInterleaving: scheduleType,
            shiftEndDate: shiftStartDate
              ?.set('hours', moment(shiftEndTime).get('hours'))
              .set('minutes', moment(shiftEndTime).get('minutes')),
          },
        },
      })
        .then(() => successToast('График обновлен'))
        .catch(e => errorToast(errorHandler(e)));
    }
  };

  const facilityGroup = useMemo(
    () => groupName(groups, facilityData?.facility.facilityGroupId),
    [groups, facilityData?.facility.facilityGroupId]
  );

  useEffect(() => {
    if (requestSchedule) {
      setPositionId(requestSchedule.positionId);
      setScheduleType(Number(requestSchedule.dayInterleaving));
      setShiftStartTime(moment(requestSchedule.dateFrom));
      setShiftEndTime(moment(requestSchedule.dateTo));
      setShiftStartDate(dayjs(requestSchedule.dateFrom));
      setShiftEndDate(dayjs(requestSchedule.dateTo));
      setLunchDuration(Number(requestSchedule.lunchDuration));
    }
  }, [requestSchedule]);

  useEffect(() => {
    if (request?.facilityId) {
      loadFacility({
        variables: {
          id: request.facilityId,
        },
      });
    }
  }, [request?.facilityId, loadFacility]);

  const changes =
    Boolean(requestSchedule?.newDateFrom) ||
    Boolean(requestSchedule?.newDateTo) ||
    Boolean(requestSchedule?.newDayInterleaving) ||
    Boolean(requestSchedule?.newLunchDuration) ||
    Boolean(requestSchedule?.newShiftDuration);

  const title = useMemo(
    () =>
      meSupervisor
        ? requestSchedule?.isNew
          ? 'Новый график'
          : changes
          ? 'Изменения в графике'
          : 'График'
        : 'Редактировать график',
    [changes, meSupervisor, requestSchedule?.isNew]
  );

  const scheduleTypeName = useCallback(
    (scheduleType?: number | null) => scheduleTypes.find(type => type.id === scheduleType)?.name,
    []
  );

  return (
    <MainSidebar title={title} show={showEditGraphicSidebar} setShow={setShowEditGraphicSidebar}>
      <form className="relative flex flex-col gap-y-6" onSubmit={updateZayavkaScheduleHandler}>
        <div>
          <span className="text-smena_text-secondary">
            {dayjs(requestSchedule?.dateFrom).format(RU_DATE)} – {dayjs(requestSchedule?.dateTo).format(RU_DATE)}
          </span>
        </div>
        <div className="flex flex-col gap-y-1">
          <span className="H4">{positionName(positions, requestSchedule?.positionId)}</span>
          <div className="flex gap-x-2 Subtitle2 text-smena_text-secondary">
            <span>{dayjs(requestSchedule?.dateFrom).format(RU_DATE)}</span>
            <span>·</span>
            <span>
              {dayjs(requestSchedule?.dateFrom).format(TIME_DATE)} – {dayjs(requestSchedule?.dateTo).format(TIME_DATE)}
            </span>
            <span>·</span>
            <span>{getLunchDuration(requestSchedule?.lunchDuration)}</span>
          </div>
        </div>
        <hr className="bg-smena_gray-30" />
        <div className="flex flex-col">
          <div className="grid grid-cols-2 gap-x-6">
            {meSupervisor ? (
              <div className="col-span-1 flex flex-col">
                <span className="Subtitle2">Профессия</span>
                <span className={clsx('Body2', requestSchedule?.isNew ? 'text-primary' : '')}>
                  {positionName(positions, positionId)}
                </span>
              </div>
            ) : (
              <SimpleSelect
                divClassName="col-span-1"
                label="Профессия"
                onChange={e => {
                  setPositionId(e.target.value);
                }}
                value={positionId}
                name="positionId"
                validation
                options={requestPositions}
                disabledOption="Не выбрано"
                error={errors.positionId}
              />
            )}
            {meSupervisor ? (
              <div className="col-span-1 flex flex-col">
                <span className="Subtitle2">График</span>
                {requestSchedule?.isDeleted ? (
                  <span className="Body2 text-smena_text-secondary line-through">{scheduleTypeName(scheduleType)}</span>
                ) : requestSchedule?.isNew ? (
                  <span className="Body2 text-primary">{scheduleTypeName(scheduleType)}</span>
                ) : requestSchedule?.newDayInterleaving ? (
                  <>
                    <span className="Body2 text-smena_text-secondary line-through">
                      {scheduleTypeName(scheduleType)}
                    </span>
                    <span className="Body2 text-primary">{scheduleTypeName(requestSchedule?.newDayInterleaving)}</span>
                  </>
                ) : (
                  <span className="Body2 text-smena_text-secondary">{scheduleTypeName(scheduleType)}</span>
                )}
              </div>
            ) : (
              <SimpleSelect
                divClassName="col-span-1"
                label="График"
                required
                onChange={({ target: { value } }) => {
                  setScheduleType(Number(value));
                }}
                value={scheduleType}
                validation
                name="type"
                disabledOption="Выберите график"
                showTextRole={[roles.Supervisor]}
                options={scheduleTypes}
              />
            )}
          </div>
          <div className="grid grid-cols-2 gap-x-6">
            {!meSupervisor ? (
              <TimePickerInput
                //check
                label="Начало смены"
                className="col-span-1 inputHeight"
                popupClassName="fixed"
                value={shiftStartTime}
                onChange={timeValue => {
                  setShiftStartTime(timeValue);
                  if (facilityGroup === 'Ашан') {
                    setLunchDuration(lunchDurationHandle(timeDifference(timeValue, shiftEndTime)));
                  }
                }}
                error={{ bool: Boolean(errors.shiftStartTime), text: errors.shiftStartTime }}
              />
            ) : (
              <div className="col-span-1 flex flex-col">
                <label className="Subtitle2">Начало смены</label>
                {requestSchedule?.isDeleted ? (
                  <span className="Body2 text-smena_text-secondary line-through">
                    {shiftStartTime?.format('HH:mm')}
                  </span>
                ) : requestSchedule?.isNew ? (
                  <span className="Body2 text-primary">{shiftStartTime?.format('HH:mm')}</span>
                ) : requestSchedule?.newDateFrom ? (
                  <>
                    <span className="Body2 text-smena_text-secondary line-through">
                      {shiftStartTime?.format('HH:mm')}
                    </span>
                    <span className="Body2 text-primary">{dayjs(requestSchedule?.newDateFrom).format('HH:mm')}</span>
                  </>
                ) : (
                  <span className="Body2 text-smena_text-secondary">{shiftStartTime?.format('HH:mm')}</span>
                )}
              </div>
            )}
            {!meSupervisor ? (
              <TimePickerInput
                //check
                label="Конец смены"
                className="col-span-1 inputHeight"
                popupClassName="fixed"
                value={shiftEndTime}
                onChange={timeValue => {
                  setShiftEndTime(timeValue);
                  if (facilityGroup === 'Ашан') {
                    setLunchDuration(lunchDurationHandle(timeDifference(shiftStartTime, timeValue)));
                  }
                }}
                error={{ bool: Boolean(errors.shiftEndTime), text: errors.shiftEndTime }}
              />
            ) : (
              <div className="col-span-1 flex flex-col">
                <label className="Subtitle2">Конец смены</label>
                {requestSchedule?.isDeleted ? (
                  <span className="Body2 text-smena_text-secondary line-through">{shiftEndTime?.format('HH:mm')}</span>
                ) : requestSchedule?.isNew ? (
                  <span className="Body2 text-primary">{shiftEndTime?.format('HH:mm')}</span>
                ) : requestSchedule?.newDateTo ? (
                  <>
                    <span className="Body2 text-smena_text-secondary line-through">
                      {shiftEndTime?.format('HH:mm')}
                    </span>
                    <span className="Body2 text-primary">{dayjs(requestSchedule?.newDateTo).format('HH:mm')}</span>
                  </>
                ) : (
                  <span className="Body2 text-smena_text-secondary">{shiftEndTime?.format('HH:mm')}</span>
                )}
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-x-6">
            {!meSupervisor ? (
              <DatePickerInput
                //check
                label="Дата начала"
                divClassName="col-span-1 inputHeight"
                popupClassName="fixed"
                value={shiftStartDate}
                onChange={selectedDate => setShiftStartDate(selectedDate)}
                minDate={dayjs(request?.dateFrom)}
                maxDate={dayjs(request?.dateTo)}
                placeholder={dayjs().startOf('month').format(RU_DATE)}
                error={{ bool: Boolean(errors.shiftStartDate), text: errors.shiftStartDate }}
              />
            ) : (
              <div className="col-span-1 flex flex-col">
                <label className="Subtitle2">Дата начала</label>
                {requestSchedule?.isDeleted ? (
                  <span className="Body2 text-smena_text-secondary line-through">
                    {dayjs(shiftStartDate).format(RU_DATE)}
                  </span>
                ) : requestSchedule?.isNew ? (
                  <span className="Body2 text-primary">{dayjs(shiftStartDate).format(RU_DATE)}</span>
                ) : requestSchedule?.newDateFrom ? (
                  <>
                    <span className="Body2 text-smena_text-secondary line-through">
                      {dayjs(shiftStartDate).format(RU_DATE)}
                    </span>
                    <span className="Body2 text-primary">{dayjs(requestSchedule?.newDateFrom).format(RU_DATE)}</span>
                  </>
                ) : (
                  <span className="Body2 text-smena_text-secondary">{dayjs(shiftStartDate).format(RU_DATE)}</span>
                )}
              </div>
            )}
            {!meSupervisor ? (
              <DatePickerInput
                //check
                label="Дата конца"
                divClassName="col-span-1 inputHeight"
                popupClassName="fixed"
                value={shiftEndDate}
                onChange={selectedDate => setShiftEndDate(selectedDate)}
                minDate={dayjs(request?.dateFrom)}
                maxDate={dayjs(request?.dateTo)}
                placeholder={dayjs().startOf('month').format(RU_DATE)}
                error={{ bool: Boolean(errors.shiftEndDate), text: errors.shiftEndDate }}
              />
            ) : (
              <div className="col-span-1 flex flex-col">
                <label className="Subtitle2">Дата конца</label>
                {requestSchedule?.isDeleted ? (
                  <span className="Body2 text-smena_text-secondary line-through">
                    {dayjs(shiftEndDate).format(RU_DATE)}
                  </span>
                ) : requestSchedule?.isNew ? (
                  <span className="Body2 text-primary">{dayjs(shiftEndDate).format(RU_DATE)}</span>
                ) : requestSchedule?.newDateTo ? (
                  <>
                    <span className="Body2 text-smena_text-secondary line-through">
                      {dayjs(shiftEndDate).format(RU_DATE)}
                    </span>
                    <span className="Body2 text-primary">{dayjs(requestSchedule?.newDateTo).format(RU_DATE)}</span>
                  </>
                ) : (
                  <span className="Body2 text-smena_text-secondary">{dayjs(shiftEndDate).format(RU_DATE)}</span>
                )}
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-x-6">
            {meSupervisor ? (
              <div className="col-span-1 flex flex-col">
                <span className="Subtitle2">Обед</span>
                {requestSchedule?.isDeleted ? (
                  <span className="Body2 text-smena_text-secondary line-through">
                    {LunchDurationToString(lunchDuration)}
                  </span>
                ) : requestSchedule?.isNew ? (
                  <span className="Body2 text-primary">{LunchDurationToString(requestSchedule?.newLunchDuration)}</span>
                ) : (
                  <span className="Body2 text-smena_text-secondary">{LunchDurationToString(lunchDuration)}</span>
                )}
              </div>
            ) : (
              <LunchDurationSelect
                divClassName="col-span-1"
                label="Обед"
                required
                onChange={e => {
                  if (facilityGroup !== 'Ашан') {
                    setLunchDuration(Number(e.target.value));
                  }
                }}
                value={lunchDuration}
                name="lunchDuration"
                autoComplete="lunchDuration"
                options={facilityGroup === 'Ашан' ? auchanLunchDurationTypes : lunchDurationTypes}
                disabled={facilityGroup === 'Ашан'}
                showTextRole={[roles.Supervisor]}
              />
            )}
          </div>
          {meSupervisor && requestSchedule?.isDeleted && (
            <span className="Subtitle1 text-smena_red">График удален</span>
          )}
          {!meSupervisor && (
            <div className="flex justify-end gap-x-6">
              <button className="btn-stroke_reject" type="button" onClick={deleteZayavkaScheduleHandler}>
                Удалить
              </button>
              <button className="btn-primary" type="submit">
                Изменить график
              </button>
            </div>
          )}
        </div>
      </form>
    </MainSidebar>
  );
};

export default EditSchedule;
