import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { useStore } from '../../../../store';
import { positionName } from '../../../../utils/filters/filtersName';
import { UserFullName } from '../../../../utils/userFullName';
import { Link } from 'react-router-dom';
import MainSidebar from '../../../ui/Sidebar';

const ViewShiftSidebar: React.FC = () => {
  const {
    positions,
    showViewRequestShiftSidebar,
    setViewRequestShiftSidebar,
    requestShift,
    setShowEditRequestShiftSidebar,
  } = useStore();

  const title = useMemo(() => {
    return positionName(positions, requestShift?.positionId);
  }, [positions, requestShift?.positionId]);

  const closePopupHandler = useCallback(() => {
    setViewRequestShiftSidebar(false);
  }, [setViewRequestShiftSidebar]);

  return (
    <MainSidebar title={title} show={showViewRequestShiftSidebar} setShow={setViewRequestShiftSidebar}>
      <div className="flex flex-col gap-y-2">
        <div>
          <h4 className="H4">
            {dayjs(requestShift?.dateFrom).format('HH:mm')} - {dayjs(requestShift?.dateEnd).format('HH:mm')}
          </h4>
          <span className="Body1 text-smena_text-secondary">{dayjs(requestShift?.dateFrom).format('DD MMMM')}</span>
        </div>
      </div>

      <div className="Subtitle1 flex gap-x-1">
        <span>Исполнитель:</span>
        <Link className="text-primary" to={`/users/${requestShift?.shift?.user?.id}`}>
          {UserFullName(requestShift?.shift?.user)}
        </Link>
      </div>

      <div className="flex justify-end gap-x-6 mt-5">
        <button className="btn-stroke_reject" onClick={closePopupHandler}>
          Отменить
        </button>
        <button
          className="btn-primary"
          onClick={() => {
            setViewRequestShiftSidebar(false);
            setShowEditRequestShiftSidebar(true);
          }}
        >
          Редактировать
        </button>
      </div>
    </MainSidebar>
  );
};

export default ViewShiftSidebar;
