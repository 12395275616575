import { Autocomplete, TextField } from '@mui/material';
import SelectArrow from '../../../Assets/icons/SelectArrow';
import { IOption } from '../../../Interfaces/IOption';

interface IAutocompleteBlock {
  id: string;
  label: string;
  value: IOption | null;
  onChangeHandler: (value: IOption | null) => void;
  options?: IOption[];
  buttonHandler: () => void;
}

const AutocompleteBlock: React.FC<IAutocompleteBlock> = ({
  label,
  id,
  value,
  onChangeHandler,
  options,
  buttonHandler,
}) => {
  return (
    <div className="sm:col-span-4 col-span-full grid sm:grid-cols-4 grid-cols-5 sm:mb-0 mb-2">
      <div className="col-span-3">
        <label htmlFor={id} className="label-primary">
          {label}
        </label>
        <Autocomplete
          id={id}
          value={value}
          isOptionEqualToValue={(option: IOption | any, value: IOption) =>
            value === undefined || value.id === '' || option.id === value.id
          }
          noOptionsText="Ничего не найдено"
          getOptionLabel={option => option.name}
          onChange={(_, value: IOption | null) => {
            onChangeHandler(value);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
          options={options || []}
          popupIcon={<SelectArrow className="text-smena_text" />}
          renderInput={params => (
            <TextField {...params} placeholder="Не выбран" />
          )}
        />
      </div>
      <div className="self-end sm:col-span-1 col-span-2 ml-2">
        <button
          className="btn-secondary_small w-full"
          onClick={buttonHandler}
          type="button"
        >
          <span>Добавить</span>
        </button>
      </div>
    </div>
  );
};

export default AutocompleteBlock;
