import { useCallback, useState } from 'react';
import { useAddManagerToFacilityGroupMutation } from '../../../../generated/graphql';
import { useStore } from '../../../../store';
import { errorHandler } from '../../../../utils/errorHandler';
import { userQueryConfigFn } from '../../../../utils/graphqlConfigs/userQueryConfig';
import { errorToast, successToast } from '../../../../utils/notify';
import SimpleSelect from '../../../ui/Select';
import ManagerRow from './ManagerRow';

const MainInfoAddManagerToFacility = () => {
  const [groupId, setGroupId] = useState('');
  const { currentUser, groups } = useStore();

  const userQueryConfig = userQueryConfigFn(currentUser?.id);

  const [addManagerToFacilityGroup] = useAddManagerToFacilityGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const addManagerToFacilityGroupHandler = useCallback(async () => {
    if (currentUser?.id) {
      await addManagerToFacilityGroup({
        variables: {
          groupId,
          userId: currentUser.id,
        },
      })
        .then(() => {
          successToast('Менеджер успешно добавлен в группу');
        })
        .catch(err => errorToast(errorHandler(err)));
    }
  }, [currentUser?.id, groupId, addManagerToFacilityGroup]);
  return (
    <div className="userPageBlock blockTemplate">
      <span className="blockTitleTemplate">
        Ответственность на объектах как менеджера
      </span>
      <div className="flex flex-col gap-y-4">
        {currentUser?.groupsManager?.map(managerGroup => (
          <ManagerRow managerGroupId={managerGroup.id} key={managerGroup.id} />
        ))}
        <div className="flex gap-x-5 mb-6">
          <SimpleSelect
            divClassName="w-200px"
            label="Группа"
            onChange={e => {
              setGroupId(e.target.value);
            }}
            value={groupId}
            name="groupId"
            options={groups}
            allOption="Не выбрано"
          />
          <div className="flex items-end">
            <button
              className="btn-secondary"
              onClick={addManagerToFacilityGroupHandler}
            >
              Добавить группу
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainInfoAddManagerToFacility;
