const NotificationBell = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.40034 4.06704C11.1507 2.3167 13.5246 1.33337 16 1.33337C18.4754 1.33337 20.8493 2.3167 22.5997 4.06704C24.35 5.81738 25.3333 8.19135 25.3333 10.6667C25.3333 15.1369 26.2894 17.9129 27.1705 19.5282C27.6129 20.3393 28.0437 20.8714 28.3429 21.1872C28.4929 21.3455 28.6108 21.4505 28.6817 21.5096C28.7172 21.5392 28.741 21.5573 28.7513 21.565C28.7529 21.5662 28.7541 21.5671 28.7551 21.5678C29.2331 21.8961 29.4446 22.4967 29.2761 23.0531C29.1059 23.6154 28.5876 24 28 24H4C3.41245 24 2.89414 23.6154 2.72388 23.0531C2.55542 22.4967 2.76694 21.8961 3.24492 21.5678C3.24586 21.5671 3.24713 21.5662 3.24873 21.565C3.259 21.5573 3.2828 21.5392 3.3183 21.5096C3.38925 21.4505 3.5071 21.3455 3.65707 21.1872C3.9563 20.8714 4.38708 20.3393 4.82947 19.5282C5.71058 17.9129 6.66667 15.1369 6.66667 10.6667C6.66667 8.19135 7.65 5.81738 9.40034 4.06704ZM3.2584 21.5586C3.2585 21.5586 3.25861 21.5585 3.25871 21.5584C3.25871 21.5584 3.2587 21.5584 3.2587 21.5584L3.2584 21.5586ZM6.86577 21.3334H25.1342C25.0328 21.1671 24.9309 20.9912 24.8295 20.8052C23.7106 18.7539 22.6667 15.5298 22.6667 10.6667C22.6667 8.8986 21.9643 7.2029 20.7141 5.95266C19.4638 4.70242 17.7681 4.00004 16 4.00004C14.2319 4.00004 12.5362 4.70242 11.286 5.95266C10.0357 7.2029 9.33334 8.8986 9.33334 10.6667C9.33334 15.5298 8.28943 18.7539 7.17053 20.8052C7.06907 20.9912 6.96722 21.1671 6.86577 21.3334ZM13.0253 26.8461C13.6626 26.4772 14.4783 26.6947 14.8472 27.332C14.9643 27.5341 15.1324 27.7019 15.3347 27.8186C15.537 27.9352 15.7665 27.9966 16 27.9966C16.2336 27.9966 16.463 27.9352 16.6653 27.8186C16.8676 27.7019 17.0357 27.5341 17.1528 27.332C17.5217 26.6947 18.3374 26.4772 18.9747 26.8461C19.612 27.2151 19.8295 28.0308 19.4606 28.6681C19.1093 29.2748 18.6047 29.7786 17.9973 30.1288C17.3899 30.479 16.7011 30.6633 16 30.6633C15.2989 30.6633 14.6101 30.479 14.0027 30.1288C13.3953 29.7786 12.8907 29.2748 12.5394 28.6681C12.1705 28.0308 12.388 27.2151 13.0253 26.8461Z"
        fill="#393B55"
      />
    </svg>
  );
};

export default NotificationBell;
