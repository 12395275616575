import { Rate } from 'antd';
import dayjs from 'dayjs';

import StarIcon from 'src/Assets/icons/StarIcon';
import { useStore } from 'src/store';
import {
  facilityGroupName,
  facilityName,
  positionName,
} from 'src/utils/filters/filtersName';
import { getRoleRu } from 'src/utils/get/getRoleRu';

const GradeElement = ({ grade }: any) => {
  const { facilities, positions } = useStore();
  return (
    <>
      <h3 className="Caption text-smena_text-secondary">
        {dayjs(grade.createdAt).format('DD.MM.YYYY HH:mm')}
      </h3>
      <div className="flex justify-between">
        <div className="flex gap-x-1 Subtitle1">
          <span>{`${grade.author.firstname} ${grade.author.lastname}`}</span>
          <span className="text-smena_text-secondary">
            ({`${getRoleRu(grade.author.role)}`})
          </span>
        </div>
      </div>
      <div className="flex gap-x-1 Body1 text-smena_text-secondary">
        <span>{facilityGroupName(facilities, grade.facilityId)}</span>
        <span>·</span>
        <span>{facilityName(facilities, grade.facilityId)}</span>
        <span>·</span>
        <span>{positionName(positions, grade.positionId)}</span>
      </div>
      <div className="flex gap-x-6">
        <div className="flex flex-col gap-y-1">
          <span className="Body2 text-smena_text-secondary">Скорость</span>
          <Rate
            defaultValue={grade.speed}
            disabled
            character={<StarIcon />}
            style={{ color: '#F9CA3E', fontSize: 10 }}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <span className="Body2 text-smena_text-secondary">
            Ответственность
          </span>
          <Rate
            defaultValue={grade.responsiveness}
            disabled
            character={<StarIcon />}
            style={{ color: '#F9CA3E', fontSize: 10 }}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <span className="Body2 text-smena_text-secondary">Характер</span>
          <Rate
            defaultValue={grade.character}
            disabled
            character={<StarIcon />}
            style={{ color: '#F9CA3E', fontSize: 10 }}
          />
        </div>
      </div>
    </>
  );
};

export default GradeElement;
