import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

import { useStore } from '../../../store';
import { sortObj } from '../../../utils/sortObject';
import Across from '../../../Assets/icons/Across';
import Magnifier from '../../../Assets/icons/magnifier';
import Plus from '../../../Assets/icons/plus';
import { getActive } from '../../../utils/get/getActive';
import Filter from './Filter';
import { getPeriod } from '../../../utils/get/getPeriod';
import { InputBase, MenuItem, Select } from '@mui/material';
import FakeArrow from '../../../Assets/icons/fakeArrow';
import { schedules } from '../../../utils/lists/schedule';
import { cityName } from '../../../utils/filters/filtersName';

interface IFacilityVacanciesFilter {
  cityId: string;
  period: string;
  schedule: string[];
  isActive: string | boolean;
  facilityId: string;
  returnData: () => void;
  setSearch: (param: string) => void;
  setPage: (param: number) => void;
  setCityId: (param: string) => void;
  setPeriod: (param: string) => void;
  setSchedule: (param: string[]) => void;
  setIsActive: (param: string | boolean) => void;
}

const FacilityVacanciesFilter: React.FC<IFacilityVacanciesFilter> = ({
  returnData,
  setSearch,
  setPage,
  cityId,
  setCityId,
  period,
  setPeriod,
  schedule,
  setSchedule,
  isActive,
  setIsActive,
  facilityId,
}) => {
  const [filter, setFilter] = useState(false);
  const [params, setParams] = useSearchParams();

  const { cities } = useStore();

  let cityParam = params.get('cityId');
  let periodParam = params.get('period');
  let scheduleParam = params.get('schedule');
  let activeParam = params.get('active');

  let paramsCount = 0;

  const paramCounter = (name: string) => {
    if (params.has(name)) {
      return paramsCount++;
    }
  };

  paramCounter('cityId');
  paramCounter('period');
  paramCounter('schedule');
  paramCounter('active');

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value !== '') {
      params.set('search', e.target.value);
      setParams(params);
    } else {
      params.delete('search');
      setParams(params);
    }
    setPage(1);
  };

  const debounceOnChange = debounce(handleChangeSearch, 600);

  const removeParam = (param: string) => {
    if (params.has(param)) {
      params.delete(param);
    } else {
      return;
    }
  };

  const addParams = (paramName: string, param: string) => {
    params.set(paramName, param);
  };

  const isAllSelected = schedules.length > 0 && schedule.length === schedules.length;

  return (
    <div className="filter-bar justify-between flex xs:flex-row flex-col gap-y-2">
      <div className="flex flex-col">
        <div className={`flex relative ${paramsCount ? 'mb-2' : ''}`}>
          <button
            type="button"
            className="btn-stroke mr-2 flex gap-x-1 items-center"
            onClick={() => {
              setFilter(value => !value);
            }}
          >
            Фильтр
            {paramsCount ? (
              <span
                className="Button3 text-primary rounded-full bg-primary bg-opacity-20"
                style={{
                  padding: '0px 7.5px',
                }}
              >
                {String(paramsCount)}
              </span>
            ) : null}
          </button>
          <div className="relative w-64">
            <span className="absolute top-0 left-0 transform translate-x-2/4 translate-y-2/4">
              <Magnifier />
            </span>
            <input
              className="input-primary pl-8"
              onChange={debounceOnChange}
              type="search"
              name="search"
              id="search"
              placeholder="Поиск"
            />
          </div>
          {filter && (
            <div className="filter__popup">
              <span
                className="absolute top-0 right-0 cursor-pointer"
                style={{ padding: '15px 15px 0 0' }}
                onClick={() => {
                  setFilter(value => !value);
                }}
              >
                <Across color={'text-smena_gray-90'} />
              </span>
              <span className="H4 text-smena_text mb-3 inline-block">Фильтры</span>
              <div className="flex flex-col gap-y-3">
                <div>
                  <label htmlFor="city" className="label-primary">
                    Город
                  </label>
                  <select
                    name="city"
                    id="city"
                    className="select-primary"
                    value={cityId}
                    onChange={e => {
                      setCityId(e.target.value);
                    }}
                  >
                    <option value="">Все города</option>
                    {cities &&
                      sortObj(cities).map(city => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="period" className="label-primary">
                    День/Ночь
                  </label>
                  <select
                    name="period"
                    id="period"
                    className="select-primary"
                    value={period}
                    onChange={e => {
                      setPeriod(e.target.value);
                    }}
                  >
                    <option value="">Все периоды</option>
                    <option value="DAY">День</option>
                    <option value="NIGHT">Ночь</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="schedule" className="label-primary pb-1">
                    График
                  </label>
                  <Select
                    id="schedule"
                    multiple
                    displayEmpty
                    IconComponent={FakeArrow}
                    value={schedule}
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <span>Все графики</span>;
                      }

                      return selected.join(', ');
                    }}
                    onChange={event => {
                      const value = event.target.value;
                      if (value[value.length - 1] === 'all') {
                        setSchedule(schedule.length === schedules.length ? [] : schedules);
                        return;
                      }
                      setSchedule(typeof value === 'string' ? value.split(',') : value);
                    }}
                    input={<InputBase className="multySelect" />}
                  >
                    <MenuItem value="all" className={isAllSelected ? 'Mui-selected' : ''}>
                      <span>Все графики</span>
                    </MenuItem>
                    {schedules.map(type => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div>
                  <label htmlFor="isActive" className="label-primary">
                    Статус активности
                  </label>
                  <select
                    name="isActive"
                    id="isActive"
                    className="select-primary mt-1"
                    value={String(isActive)}
                    onChange={({ target: { value } }) => {
                      setIsActive('');
                      if (value === 'true') {
                        setIsActive(true);
                      }
                      if (value === 'false') {
                        setIsActive(false);
                      }
                    }}
                  >
                    <option value="">Все статусы</option>
                    <option value="true">Активный</option>
                    <option value="false">Не активный</option>
                  </select>
                </div>
                <div className="flex gap-x-5">
                  <button
                    className="btn-primary"
                    onClick={() => {
                      if (cityId === '') {
                        removeParam('cityId');
                      } else {
                        addParams('cityId', cityId);
                      }
                      if (schedule.length === 0) {
                        removeParam('schedule');
                      } else {
                        addParams('schedule', schedule.join(', '));
                      }
                      if (period === '') {
                        removeParam('period');
                      } else {
                        addParams('period', period);
                      }
                      if (isActive === '') {
                        removeParam('active');
                      } else {
                        addParams('active', String(isActive));
                      }
                      setParams(params);
                      setFilter(false);
                      returnData();
                    }}
                  >
                    <span>Применить</span>
                  </button>
                  <button
                    className="btn-stroke"
                    onClick={() => {
                      setCityId('');
                      setPeriod('');
                      setIsActive('');
                      setSchedule([]);
                      setSearch('');
                      params.delete('cityId');
                      params.delete('period');
                      params.delete('schedule');
                      params.delete('active');
                      setParams(params);
                      setFilter(false);
                    }}
                  >
                    <span>Сбросить</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex">
          {cityParam && (
            <Filter
              content={'Город ' + cityName(cities, cityParam)}
              clickHandler={() => {
                setCityId('');
                params.delete('cityId');
                setParams(params);
                setPage(1);
              }}
            />
          )}

          {periodParam && (
            <Filter
              content={getPeriod(periodParam)}
              clickHandler={() => {
                setPeriod('');
                params.delete('period');
                setParams(params);
                setPage(1);
              }}
            />
          )}

          {scheduleParam && (
            <Filter
              content={scheduleParam}
              clickHandler={() => {
                setSchedule([]);
                params.delete('schedule');
                setParams(params);
                setPage(1);
              }}
            />
          )}

          {activeParam && (
            <Filter
              content={getActive(activeParam)}
              clickHandler={() => {
                setIsActive('');
                params.delete('active');
                setParams(params);
                setPage(1);
              }}
            />
          )}
        </div>
      </div>
      <div className="flex self-start">
        <Link to={`/vacancies/create?facilityId=${facilityId}`}>
          <button type="button" className="btn-primary_big">
            <span className="flex items-center">
              <span className="inline-flex mr-2">
                <Plus className="text-smena_white" />
              </span>

              <span>Новая вакансия</span>
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default FacilityVacanciesFilter;
