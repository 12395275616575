import { Document, Page, pdfjs } from 'react-pdf';
import MoiNalogPDF from '../../../Assets/PDF/Registratsiya_v_Moy_Nalog_2.pdf';


export const MoiNalog = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return (
    <div className="flex flex-col">
      <Document
        file={MoiNalogPDF}
        onLoadError={console.error}
        className="w-screen"
      >
        {[1, 2].map((page, index) => (
          <Page pageNumber={page} key={index} />
        ))}
      </Document>
    </div>
  );
};
