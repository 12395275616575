import { roles } from './constVariables';

export const isDisabled = (id: string, role?: string, can?: boolean) => {
  if (id && role) {
    if ([roles.Admin, roles.Supervisor, roles.Newbie].includes(role)) {
      if (can) {
        return false;
      }
      return true;
    }
    return false;
  }
  return true;
};
