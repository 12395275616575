import React, { useEffect, useState, useRef } from 'react';
import { Pagination } from '@mui/material';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useClientUsersLazyQuery } from '../../../generated/graphql';

import MainLayout from '../../Layout/main';
import TableHeader from '../../Table/header';
import { workersColumns } from '../../Table/columns';
import SkeletonLayout from '../../Table/Skeleton';
import ClientUsersFilters from '../../Table/Filters/ClientUsersFilters';
import WorkersRow from '../../Table/Row/WorkersRow';
import { maxTableWidth } from 'src/utils/maxTableWidth';
import { nullHandler } from 'src/utils/isNullHandler';
import { activeHandler } from 'src/utils/isActiveHandler';

const ClientUsersPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [facilityId, setFacilityId] = useState('');
  const [role, setRole] = useState('');
  const [rating, setRating] = useState<string | null>('');
  const [activeShifts, setActiveShifts] = useState<string | boolean>('');
  const [params] = useSearchParams();

  const [loadData, { data: dataUsers, loading: loadingUsers }] = useClientUsersLazyQuery();

  const mutationInput = {
    page: 1,
    searchQuery: search,
    sortBy,
    sortWay,
    facilityId,
    rating: nullHandler(rating),
    activeShifts: activeHandler(activeShifts),
    role,
  };

  const returnData = () => {
    return loadData({
      variables: {
        input: mutationInput,
      },
    });
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const clientUsers = dataUsers?.clientUsers;
  const users = clientUsers?.users;

  let facilityIdParam = params.get('facilityId');
  let roleParam = params.get('role');
  let ratingPatam = params.get('rating');
  let activeShiftsPatam = params.get('activeShifts');
  let searchParam = params.get('search');

  const location = useLocation();

  const userTableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (facilityIdParam) {
      setFacilityId(facilityIdParam);
    } else {
      setFacilityId('');
    }
    if (roleParam) {
      setRole(roleParam);
    } else {
      setRole('');
    }
    if (ratingPatam) {
      setRating(ratingPatam);
    } else {
      setRating('');
    }
    if (activeShiftsPatam) {
      setActiveShifts(activeShiftsPatam);
    } else {
      setActiveShifts('');
    }
    if (searchParam) {
      setSearch(searchParam);
    } else {
      setSearch('');
    }
    loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
          facilityId: facilityIdParam || '',
          role: roleParam || '',
          rating: ratingPatam ? nullHandler(ratingPatam) : '',
          activeShifts: activeShiftsPatam ? activeHandler(activeShiftsPatam) : null,
          searchQuery: searchParam || '',
        },
      },
    });
    // eslint-disable-next-line
  }, [page, search, sortBy, sortWay, location]);

  return (
    <MainLayout
      title="Мои исполнители"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[{ path: '/users', name: 'Мои исполнители' }]}
    >
      <div
        style={{
          maxWidth: maxTableWidth(workersColumns) + 'px',
        }}
      >
        <ClientUsersFilters
          returnData={returnData}
          setSearch={setSearch}
          facilityId={facilityId}
          setFacilityId={setFacilityId}
          role={role}
          setRole={setRole}
          rating={rating}
          setRating={setRating}
          activeShifts={activeShifts}
          setActiveShifts={setActiveShifts}
          setPage={setPage}
        />
        <ScrollSync>
          <div className="flex flex-col shadow-smena rounded-lg">
            <TableHeader
              dataLength={users?.length}
              columns={workersColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />
            {loadingUsers || !users ? (
              <SkeletonLayout columns={workersColumns} />
            ) : users.length > 0 ? (
              <ScrollSyncPane>
                <div className="main-table" ref={userTableRef}>
                  {users?.map(user => (
                    <WorkersRow key={user.id} user={user} columns={workersColumns} />
                  ))}
                </div>
              </ScrollSyncPane>
            ) : (
              <div className="w-full flex justify-center my-5">
                <h3 className="text-smena_text Body1">
                  По вашему запросу <span className="font-bold">{search}</span> ничего не нашлось
                </h3>
              </div>
            )}
          </div>
        </ScrollSync>
        <div className="py-5">
          {clientUsers?.pages! > 1 ? (
            <Pagination
              shape="rounded"
              count={clientUsers ? clientUsers.pages : 10}
              page={page}
              onChange={handleChange}
            />
          ) : null}
        </div>
      </div>
    </MainLayout>
  );
};

export default ClientUsersPage;
