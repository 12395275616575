import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React from 'react';

import { ISplitButton } from '../../../Interfaces/ISplitButton';
import { useStore } from '../../../store';
import SelectArrow from '../../../Assets/icons/SelectArrow';

export const SplitButton: React.FC<ISplitButton> = ({
  buttonContent,
  handleClick,
  options,
  disabled,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { selectedIndex, setSelectedIndex } = useStore();

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup ref={anchorRef} style={{ height: '32px' }}>
        <Button
          onClick={handleClick}
          className="btn-split btn-split__left"
          disabled={disabled}
        >
          {buttonContent}
        </Button>
        <Button
          size="small"
          className="btn-split btn-split__right"
          onClick={handleToggle}
          disabled={disabled}
        >
          <SelectArrow className="text-smena_white" />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: '21', width: anchorRef.current?.offsetWidth + 'px' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
