import { useMemo, useState, useCallback, useEffect } from 'react';
import EyeHide from '../../../../Assets/icons/EyeHide';
import EyeShow from '../../../../Assets/icons/EyeShow';
import {
  DocumentStatus,
  ReviewType,
  useChangeUserPasswordMutation,
  useChangeUserRoleMutation,
  useGetUserDocumentsQuery,
} from '../../../../generated/graphql';
import { generatePassword } from '../../../../hooks/generatePassword';
import { useStore } from '../../../../store';
import { roles } from '../../../../utils/constVariables';
import { errorHandler } from '../../../../utils/errorHandler';
import { allRoleArray, roleArray, superWithoutNewbie } from '../../../../utils/lists/roleArray';
import { errorToast, successToast } from '../../../../utils/notify';
import SimpleInput from '../../../ui/Input';

const MainInfoRoleAndAccess = () => {
  const { me, currentUser } = useStore();

  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordType, setPasswordType] = useState(true);

  const { data: userDocuments } = useGetUserDocumentsQuery({
    variables: {
      workerId: currentUser?.id,
    },
  });

  const meRole = me?.role;

  const canChangePassword = [roles.Admin, roles.ClientDirector, roles.ClientManager].includes(role);

  const AdminOrSupervisor = useMemo(() => me && [roles.Admin, roles.Supervisor].includes(me.role), [me]);

  const canChangeRole = useMemo(
    () => currentUser && [roles.Worker, roles.Foreman].includes(currentUser.role),
    [currentUser]
  );

  const disableChangeRole = useMemo(() => {
    if (AdminOrSupervisor) {
      if (canChangeRole) {
        return [ReviewType.MainPage, ReviewType.Registration, ReviewType.Selfie]
          .map(
            el =>
              userDocuments?.getUserDocuments.reviews.find(review => review.type === el)?.status ===
              DocumentStatus.Accepted
          )
          .includes(false);
      }
      return false;
    }

    return true;
  }, [userDocuments?.getUserDocuments.reviews, canChangeRole, AdminOrSupervisor]);

  const [changeUserRole] = useChangeUserRoleMutation();
  const [changePassword] = useChangeUserPasswordMutation();

  const changeUserRoleHandler = useCallback(
    (role: string) => {
      changeUserRole({
        variables: {
          role,
          userId: currentUser?.id || '',
        },
      })
        .then(() => successToast('Роль изменена'))
        .catch(err => errorToast(errorHandler(err)));
    },
    [changeUserRole, currentUser?.id]
  );

  const roleOptions = useMemo(() => {
    if (meRole === roles.Supervisor) {
      return superWithoutNewbie;
    }
    if (currentUser?.role !== roles.Newbie) {
      return roleArray;
    }
    return allRoleArray;
  }, [currentUser?.role, meRole]);

  const handlePasswordChange = useCallback(() => {
    if (!password) {
      setPasswordError('Обязательное поле');
    }
    if (currentUser?.id && password) {
      changePassword({
        variables: { password, userId: currentUser?.id },
      })
        .then(() => successToast('Пароль изменен'))
        .catch(err => errorToast(errorHandler(err)));
    }
  }, [password, changePassword, currentUser?.id]);

  useEffect(() => {
    if (currentUser) {
      setRole(currentUser?.role);
    }
  }, [currentUser]);

  return (
    <div className="userPageBlock blockTemplate">
      <span className="blockTitleTemplate">Роль и доступы</span>
      <div className="flex gap-x-5">
        <div className="crudUserInput">
          <label htmlFor="role" className="label-primary">
            Роль
          </label>
          <select
            required
            onChange={({ target: { value } }) => {
              setRole(value);
              changeUserRoleHandler(value);
            }}
            value={role}
            disabled={disableChangeRole}
            id="role"
            name="role"
            autoComplete="role"
            className="select-primary"
          >
            {roleOptions.map(role => (
              <option key={role.value} value={role.value}>
                {role.name}
              </option>
            ))}
          </select>
        </div>
        {[roles.Admin, roles.ClientDirector, roles.ClientManager].includes(role) && (
          <div className="flex gap-x-2">
            <SimpleInput
              divClassName="crudUserInput"
              label="Пароль"
              placeholder="Введите пароль"
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
              type={passwordType ? 'password' : 'text'}
              name="password"
              autoComplete="current-password"
              error={passwordError}
            />
            <button
              className="flex mt-8"
              type="button"
              onClick={() => {
                setPasswordType(value => !value);
              }}
            >
              {passwordType ? <EyeHide /> : <EyeShow />}
            </button>
            <div className="flex mt-6">
              <button
                className="btn-secondary"
                onClick={() => {
                  setPassword(generatePassword());
                }}
              >
                Сгенерировать
              </button>
            </div>
          </div>
        )}
      </div>
      {canChangePassword && (
        <div className="flex justify-end">
          <button className="btn-primary_small" onClick={handlePasswordChange}>
            Сохранить
          </button>
        </div>
      )}
    </div>
  );
};

export default MainInfoRoleAndAccess;
