import { FilterPeriod } from '../../../../generated/graphql';

export const lastElementLength = ({ time, resArr }: { time: FilterPeriod; resArr: any[] }) => {
  switch (time) {
    case FilterPeriod.Year:
      const valuesArrYear = resArr.slice(6, 12).map(data => data.foreman + data.supervisor + data.worker);
      if (valuesArrYear.length > 0) {
        return valuesArrYear.reduce((prev, curr) => prev + curr);
      }
      return 0;
    case FilterPeriod.AllTime:
      const valuesArrAllTime = resArr
        .slice(resArr.length - 12, resArr.length)
        .map(data => data.foreman + data.supervisor + data.worker);
      if (valuesArrAllTime.length > 0) {
        return valuesArrAllTime.reduce((prev, curr) => prev + curr);
      }
      return 0;
    default:
      const lastEl = resArr[resArr.length - 1];
      return resArr.length > 0 ? lastEl.foreman + lastEl.supervisor + lastEl.worker : 0;
  }
};
