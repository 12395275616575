import { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import locale from 'antd/lib/locale/ru_RU';
import { ConfigProvider, DatePicker } from 'antd';
import moment from 'moment';
// import { SmileOutlined } from '@ant-design/icons';

import { useStore } from '../../../store';
import Plus from '../../../Assets/icons/plus';
import Filter from './Filter';

import { sortObj } from '../../../utils/sortObject';
import { filterFacilities } from '../../../utils/filters/filterFacilities';
import { periodShift } from '../../../utils/lists/periodType';
import { getPeriodRu } from '../../../utils/get/getPeriodRu';
import { getStatusRu } from '../../../utils/get/getStatusRu';
import { moderationStatus } from '../../../utils/lists/statusType';
import { ModerationStatus, PeriodType, useSetModerationReportStatusMutation } from '../../../generated/graphql';
import Across from '../../../Assets/icons/Across';
import { facilityName, groupName } from '../../../utils/filters/filtersName';
import { SplitButton } from '../../ui/SplitButton';
import { errorToast, successToast } from '../../../utils/notify';
import { errorHandler } from '../../../utils/errorHandler';
import { reportsQueryConfig } from '../../../utils/graphqlConfigs/moderationReports';
import DatePickerIcon from '../../../Assets/icons/DatePickerIcon';
import { RU_DATE } from '../../../utils/constVariables';

const { RangePicker } = DatePicker;

const ModerationReportsFilter = ({
  returnData,
  groupId,
  setGroupId,
  facilityId,
  setFacilityId,
  period,
  setPeriod,
  status,
  setStatus,
  rangeDates,
  setRangeDates,
}: {
  groupId: string;
  facilityId: string;
  period?: PeriodType;
  status?: ModerationStatus;
  rangeDates: moment.Moment[] | null;
  returnData: () => void;
  setGroupId: (param: string) => void;
  setFacilityId: (param: string) => void;
  setPeriod: (param?: PeriodType) => void;
  setStatus: (param?: ModerationStatus) => void;
  setRangeDates: (param: moment.Moment[] | null) => void;
}) => {
  const [filter, setFilter] = useState(false);
  const [params, setParams] = useSearchParams();

  const { groups, facilities, selectedIndex, setSelectedIndex, moderationReports } = useStore();

  let dateFrom = params.get('dateFrom');
  let dateTo = params.get('dateTo');
  let groupParam = params.get('groupId');
  let facilityParam = params.get('facilityId');
  let periodTypeParam = params.get('period');
  let statusParam = params.get('status');

  let paramsCount = 0;

  const paramCounter = (name: string) => {
    if (params.has(name)) {
      return paramsCount++;
    }
  };

  useEffect(() => {
    if (facilityParam) {
      setFacilityId(facilityParam);
    } else {
      setFacilityId('');
    }
    if (groupParam) {
      setGroupId(groupParam);
    } else {
      setGroupId('');
    }
    if (periodTypeParam) {
      setPeriod(periodTypeParam as PeriodType);
    } else {
      setPeriod(undefined);
    }
    if (statusParam) {
      setStatus(statusParam as ModerationStatus);
    } else {
      setStatus(undefined);
    }
    if (params.has('dateFrom') && params.has('dateTo')) {
      setRangeDates([moment(dateFrom), moment(dateTo)]);
    } else {
      setRangeDates(null);
    }
    setSelectedIndex(0);
  }, []); //eslint-disable-line

  paramCounter('groupId');
  paramCounter('facilityId');
  paramCounter('period');
  paramCounter('status');

  if (params.has('dateFrom') || params.has('dateTo')) {
    paramsCount++;
  }

  const filteredObjects = useMemo(() => filterFacilities(groupId, facilities), [facilities, groupId]);

  const removeParam = (param: string) => {
    if (params.has(param)) {
      params.delete(param);
    }
  };

  const addParams = (paramName: string, param: string) => {
    params.set(paramName, param);
  };

  const options = ['Отправлено', 'В работе'];

  const [changeStatus] = useSetModerationReportStatusMutation({
    awaitRefetchQueries: true,
    refetchQueries: [reportsQueryConfig()],
  });

  const mutation = async (status: string) => {
    await changeStatus({
      variables: {
        input: {
          reportIds: moderationReports,
          status: status as ModerationStatus,
        },
      },
    })
      .then(e => {
        if (e.data?.setModerationReportStatus) {
          successToast('Статус успешно изменен');
        }
      })
      .catch(e => {
        errorToast(errorHandler(e));
      });
  };

  const handleClick = async () => {
    if (selectedIndex === 0) {
      await mutation('SENT');
    } else if (selectedIndex === 1) {
      await mutation('IN_WORK');
    }
  };

  return (
    <div className="filter-bar flex justify-between flex-wrap gap-y-2">
      <div className="flex flex-col">
        {moderationReports.length > 0 ? (
          <SplitButton
            handleClick={handleClick}
            options={options}
            buttonContent={`Статус «${options[selectedIndex]}»`}
          />
        ) : (
          <div className={`flex relative ${paramsCount ? 'mb-2' : ''}`}>
            <button
              type="button"
              className="btn-stroke mr-2 flex gap-x-1 items-center"
              onClick={() => {
                setFilter(value => !value);
              }}
            >
              Фильтр
              {paramsCount ? (
                <span
                  className="Button3 text-primary rounded-full bg-primary bg-opacity-20"
                  style={{
                    padding: '0px 7.5px',
                  }}
                >
                  {String(paramsCount)}
                </span>
              ) : null}
            </button>
            {filter && (
              <div className="filter__popup">
                <span
                  className="absolute top-0 right-0 cursor-pointer"
                  style={{ padding: '15px 15px 0 0' }}
                  onClick={() => {
                    setFilter(value => !value);
                  }}
                >
                  <Across color={'text-smena_gray-90'} />
                </span>
                <span className="H4 text-smena_text mb-3 inline-block">Фильтры</span>
                <div className="flex flex-col gap-y-3">
                  <div>
                    <label className="label-primary">Даты</label>
                    <ConfigProvider locale={locale}>
                      <RangePicker
                        size="middle"
                        separator={<span>→</span>}
                        placeholder={['Начало', 'Конец']}
                        className="custom__picker"
                        // suffixIcon={<SmileOutlined />}
                        suffixIcon={<DatePickerIcon opacity="opacity-100" />}
                        value={rangeDates && [rangeDates[0], rangeDates[1]]}
                        format={RU_DATE}
                        onChange={(newValue: any) => {
                          setRangeDates(newValue);
                        }}
                      />
                    </ConfigProvider>
                  </div>
                  <div>
                    <label htmlFor="groupId" className="label-primary">
                      Группа
                    </label>
                    <select
                      name="groupId"
                      id="groupId"
                      className="select-primary"
                      value={groupId}
                      onChange={e => {
                        setGroupId(e.target.value);
                        setFacilityId('');
                      }}
                    >
                      <option value="">Все группы</option>
                      {groups &&
                        sortObj(groups).map(group => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="facility" className="label-primary">
                      Объект
                    </label>
                    <select
                      name="facility"
                      id="facility"
                      className="select-primary"
                      value={facilityId}
                      onChange={e => {
                        setFacilityId(e.target.value);
                      }}
                    >
                      <option value="">Все объекты</option>
                      {filteredObjects &&
                        sortObj(filteredObjects).map(facility => (
                          <option key={facility.id} value={facility.id}>
                            {facility.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="period" className="label-primary">
                      Период
                    </label>
                    <select
                      name="period"
                      id="period"
                      className="select-primary"
                      value={period}
                      onChange={e => {
                        setPeriod(e.target.value as PeriodType);
                        if (e.target.value === 'Все периоды') {
                          setPeriod(undefined);
                        }
                      }}
                    >
                      <option value={undefined}>Все периоды</option>
                      {periodShift.map(period => (
                        <option key={period} value={period}>
                          {getPeriodRu(period)}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="status" className="label-primary">
                      Статус
                    </label>
                    <select
                      name="status"
                      id="status"
                      className="select-primary"
                      value={status}
                      onChange={e => {
                        setStatus(e.target.value as ModerationStatus);
                        if (e.target.value === 'Все статусы') {
                          setStatus(undefined);
                        }
                      }}
                    >
                      <option value={undefined}>Все статусы</option>
                      {moderationStatus.map(status => (
                        <option key={status} value={status}>
                          {getStatusRu(status)}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex gap-x-5">
                    <button
                      className="btn-primary"
                      onClick={() => {
                        if (groupId === '') {
                          removeParam('groupId');
                        } else {
                          addParams('groupId', groupId);
                        }
                        if (facilityId === '') {
                          removeParam('facilityId');
                        } else {
                          addParams('facilityId', facilityId);
                        }
                        if (period === undefined) {
                          removeParam('period');
                        } else {
                          addParams('period', period);
                        }
                        if (status === undefined) {
                          removeParam('status');
                        } else {
                          addParams('status', status);
                        }
                        if (rangeDates) {
                          params.set('dateFrom', String(moment(rangeDates[0]).format('YYYY-MM-DD')));
                          params.set('dateTo', String(moment(rangeDates[1]).format('YYYY-MM-DD')));
                        } else {
                          if (params.has('dateFrom') && params.has('dateTo')) {
                            params.delete('dateFrom');
                            params.delete('dateTo');
                          }
                        }
                        setParams(params);
                        setFilter(false);
                        returnData();
                      }}
                    >
                      Применить
                    </button>
                    <button
                      className="btn-stroke"
                      onClick={() => {
                        setRangeDates(null);
                        setGroupId('');
                        setFacilityId('');
                        setPeriod(undefined);
                        setStatus(undefined);
                        params.delete('groupId');
                        params.delete('facilityId');
                        params.delete('period');
                        params.delete('status');
                        params.delete('dateFrom');
                        params.delete('dateTo');
                        setParams(params);
                        setFilter(false);
                      }}
                    >
                      Сбросить
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex">
          {(dateFrom || dateTo) && (
            <Filter
              content={moment(dateFrom).format(RU_DATE) + ' - ' + moment(dateTo).format(RU_DATE)}
              clickHandler={() => {
                setRangeDates(null);
                params.delete('dateFrom');
                params.delete('dateTo');
                setParams(params);
              }}
            />
          )}
          {groupParam && (
            <Filter
              content={'Группа ' + groupName(groups, groupParam)}
              clickHandler={() => {
                setGroupId('');
                params.delete('groupId');
                setParams(params);
              }}
            />
          )}

          {facilityParam && (
            <Filter
              content={facilityName(facilities, facilityParam)}
              clickHandler={() => {
                setFacilityId('');
                params.delete('facilityId');
                setParams(params);
              }}
            />
          )}

          {periodTypeParam && (
            <Filter
              content={getPeriodRu(periodTypeParam)}
              clickHandler={() => {
                setPeriod(undefined);
                params.delete('period');
                setParams(params);
              }}
            />
          )}

          {statusParam && (
            <Filter
              content={getStatusRu(statusParam)}
              clickHandler={() => {
                setStatus(undefined);
                params.delete('status');
                setParams(params);
              }}
            />
          )}
        </div>
      </div>
      <div className="flex self-start">
        <Link to="/moderations-period/create">
          <button type="button" className="btn-primary_big flex items-center">
            <span className="inline-flex mr-2">
              <Plus className="text-smena_white" />
            </span>
            Новый период
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ModerationReportsFilter;
