export const GreenCheck = () => {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2071 0.292893C16.5976 0.683417 16.5976 1.31658 16.2071 1.70711L6.20711 11.7071C5.81658 12.0976 5.18342 12.0976 4.79289 11.7071L0.792893 7.70711C0.402369 7.31658 0.402369 6.68342 0.792893 6.29289C1.18342 5.90237 1.81658 5.90237 2.20711 6.29289L5.5 9.58579L14.7929 0.292893C15.1834 -0.0976311 15.8166 -0.0976311 16.2071 0.292893Z"
        fill="#1B984D"
      />
    </svg>
  );
};
