interface IUserFullName {
  lastname?: string;
  firstname?: string;
  patronymic?: string | null;
}

export const UserFullName = (user?: IUserFullName | null) => {
  return (
    user?.lastname +
    ' ' +
    user?.firstname +
    ' ' +
    (user?.patronymic ? user?.patronymic : ' ')
  );
};

export const UserNameSurename = (user?: IUserFullName | null) => {
  return user?.lastname + ' ' + user?.firstname;
};
