import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { FilterPeriod, MetricTypes, useGetMetricsQuery } from '../../../../generated/graphql';

import { useStore } from '../../../../store';
import SimpleSelect from '../../../ui/Select';
import { getTimePeriodCharts } from '../../../../utils/get/getTimeePeriodCharts';
import { chartTimes } from '../../../../utils/lists/chartTimes';
import { getRoleRu } from '../../../../utils/get/getRoleRu';
import { tickFormatterHandlerFn } from '../../../../utils/charts/tickFormatter';
import { chartClick, chartHover } from '../../../../utils/charts/chartHandlers';
import { labelFormatterHandler } from '../../../../utils/charts/getLabelFormaterCharts';
import { intervalChart } from '../../../../utils/charts/intervalChart';
import { ITooltipStatus } from '../../../../Interfaces/ITooltipStatus';
import { useTypes } from '../../hooks';
import { lastElementLength } from './hooks';
import { allValue } from '../helpers';

const AllUsersChart = () => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  const [time, setTime] = useState(FilterPeriod.Month);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });

  const types = useTypes({
    facility: MetricTypes.UserCountFacility,
    group: MetricTypes.UserCountFacilityGroup,
    all: MetricTypes.UserCountAll,
  });

  const { data } = useGetMetricsQuery({
    variables: {
      input: {
        facilityId: allValue(trendsFacilityId),
        facilityGroupId: allValue(trendsGroupId),
        filterPeriod: time,
        types,
      },
    },
  });

  const handleTimeChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setTime(value as FilterPeriod);
  }, []);

  const arr1: any[] = [];
  const arr2: any[] = [];
  const arr3: any[] = [];
  //eslint-disable-next-line
  const resArr: any[] = useMemo(() => [], [arr2]);
  const fillArr = useCallback(
    (array: any[], metricTypes: MetricTypes) => {
      const response = data?.getMetrics?.find(metric => metric.metricType === metricTypes)?.data;
      const sortArr = response
        ?.map(el => ({ ...el, createdAt: dayjs(el.createdAt) }))
        .sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());
      sortArr?.forEach(el => {
        array[el.createdAt.valueOf()] = el.value;
      });
    },
    [data?.getMetrics]
  );

  if (trendsFacilityId !== 'all') {
    fillArr(arr1, MetricTypes.CountForemanFacility);
    fillArr(arr2, MetricTypes.CountWorkerFacility);
    fillArr(arr3, MetricTypes.CountSupervisorFacility);
  }
  if (trendsGroupId !== 'all') {
    fillArr(arr1, MetricTypes.CountForemanFacilityGroup);
    fillArr(arr2, MetricTypes.CountWorkerFacilityGroup);
    fillArr(arr3, MetricTypes.CountSupervisorFacilityGroup);
  } else {
    fillArr(arr1, MetricTypes.CountForeman);
    fillArr(arr2, MetricTypes.CountWorker);
    fillArr(arr3, MetricTypes.CountSupervisor);
  }

  for (const key in arr2) {
    resArr.push({
      createdAt: Number(key),
      worker: arr2[key] || 0,
      foreman: arr1[key] || 0,
      supervisor: arr3[key] || 0,
    });
  }

  const tickFormatterHandler = useCallback(
    (value: any) => {
      if (value && value !== 'auto') {
        return tickFormatterHandlerFn(value, time);
      }
      return '0';
    },
    [time]
  );

  const lastElLength = useMemo(() => lastElementLength({ time, resArr }), [resArr, time]);

  return (
    <div className="px-5">
      <div className="flex justify-between items-center">
        <span className="Subtitle1 text-smena_text-secondary">Пользователи</span>
        <div className="flex">
          <SimpleSelect
            divClassName="chart-filter"
            label=""
            onChange={handleTimeChange}
            value={time}
            sort={false}
            name="time"
            options={chartTimes}
          />
        </div>
      </div>
      <div className="flex gap-x-1 items-end mt-5 mb-2">
        <span className="ChartNumber text-smena_text">{lastElLength}</span>
        <span className="Caption text-smena_text-secondary">за {getTimePeriodCharts(time)}</span>
      </div>
      <div className="chartTrendBlock">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={resArr}
            width={150}
            height={40}
            margin={{ right: 20, bottom: 5 }}
            onClick={state => {
              if (state !== null) {
                if (tooltipStatus.barId === state.activeTooltipIndex) {
                  setTooltipStatus(chartHover(state));
                } else {
                  setTooltipStatus(chartClick(state));
                }
              }
            }}
          >
            <XAxis
              dataKey="createdAt"
              interval={intervalChart(time)}
              tickFormatter={tickFormatterHandler}
              height={30}
              angle={-25}
              tickMargin={10}
            />
            <YAxis />
            <Tooltip
              cursor={false}
              trigger={tooltipStatus.status}
              labelFormatter={(label: string) => labelFormatterHandler({ label, time })}
              formatter={(value: string, name: string) => {
                const ruName = getRoleRu(name.toUpperCase());
                return [value, ruName];
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Area type="monotone" dataKey="worker" stroke="#FF9EB5" fill="#FF9EB540" />
            <Area type="monotone" dataKey="foreman" stroke="#FF7A99" fill="#FF7A9940" />
            <Area type="monotone" dataKey="supervisor" stroke="#FE426E" fill="#FE426E40" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default AllUsersChart;
