import { Progress, Rate } from 'antd';

import StarIcon from 'src/Assets/icons/StarIcon';
import { RegularUserRatingFragment } from 'src/generated/graphql';

interface IRateBlock {
  user?: RegularUserRatingFragment;
}

const RateBlock: React.FC<IRateBlock> = ({ user }) => {
  const userAutoRating = user?.userAutoRating;

  const countRate = (rate: number) => {
    if (rate) {
      return rate * 33 + 1;
    }
    return 0;
  };
  return (
    <div className="userPageBlock blockTemplate">
      <h2 className="blockTitleTemplate">Рейтинг</h2>
      <div className="rate-block">
        <div className="flex flex-col gap-y-3">
          <div className="flex flex-col gap-y-1">
            <div className="Subtitle2 flex items-center gap-x-1">
              <span>Автоматическая оценка</span>
            </div>
            <div className="flex gap-x-2 w-[140px]">
              <span className="Subtitle2">{userAutoRating?.rating}</span>
              <Progress
                percent={Number(userAutoRating?.rating) * 20}
                strokeColor={
                  userAutoRating?.rating
                    ? userAutoRating?.rating * 20 === 100
                      ? '#55D581'
                      : '#397DFF'
                    : ''
                }
                trailColor="#E0E0E0"
                size="small"
                showInfo={false}
                strokeWidth={8}
              />
            </div>
          </div>
          <div className="rateBlockAuto gap-x-2 gap-y-1">
            <span className="Body2 text-smena_text-secondary">Документы</span>
            <Progress
              percent={Number(userAutoRating?.documents) * 100}
              strokeColor={
                Number(userAutoRating?.documents) * 100 === 100
                  ? '#55D581'
                  : '#397DFF'
              }
              trailColor="#E0E0E0"
              size="small"
              showInfo={false}
              strokeWidth={8}
            />
            <span className="Body2 text-smena_text-secondary">Дисциплина</span>
            <Progress
              percent={countRate(Number(userAutoRating?.discipline))}
              strokeColor={
                countRate(Number(userAutoRating?.discipline)) === 100
                  ? '#55D581'
                  : '#397DFF'
              }
              trailColor="#E0E0E0"
              size="small"
              showInfo={false}
              strokeWidth={8}
            />
            <span className="Body2 text-smena_text-secondary">
              Работоспособность
            </span>
            <Progress
              percent={countRate(Number(userAutoRating?.performance))}
              strokeColor={
                countRate(Number(userAutoRating?.performance)) === 100
                  ? '#55D581'
                  : '#397DFF'
              }
              trailColor="#E0E0E0"
              size="small"
              showInfo={false}
              strokeWidth={8}
            />
            <span className="Body2 text-smena_text-secondary">
              Исполнительность
            </span>
            <Progress
              percent={countRate(Number(userAutoRating?.diligence))}
              strokeColor={
                countRate(Number(userAutoRating?.diligence)) === 100
                  ? '#55D581'
                  : '#397DFF'
              }
              trailColor="#E0E0E0"
              size="small"
              showInfo={false}
              strokeWidth={8}
            />
            <span className="Body2 text-smena_text-secondary">
              Штрафуемость
            </span>
            <Progress
              percent={countRate(Number(userAutoRating?.penalization))}
              strokeColor={
                countRate(Number(userAutoRating?.penalization)) === 100
                  ? '#55D581'
                  : '#397DFF'
              }
              trailColor="#E0E0E0"
              size="small"
              showInfo={false}
              strokeWidth={8}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-5 md:gap-y-10">
          <span className="Subtitle2">Ручная оценка</span>
          <div className="rateBlockAuto gap-x-2 gap-y-1">
            <span className="Body2 text-smena_text-secondary">Скорость</span>
            <Rate
              defaultValue={user?.speed}
              disabled
              character={<StarIcon />}
              style={{ color: '#F9CA3E', fontSize: 10 }}
            />
            <span className="Body2 text-smena_text-secondary">
              Ответственность
            </span>
            <Rate
              defaultValue={user?.responsiveness}
              disabled
              character={<StarIcon />}
              style={{ color: '#F9CA3E', fontSize: 10 }}
            />
            <span className="Body2 text-smena_text-secondary">Характер</span>
            <Rate
              defaultValue={user?.character}
              disabled
              character={<StarIcon />}
              style={{ color: '#F9CA3E', fontSize: 10 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateBlock;
