import { FilterPeriod } from '../../generated/graphql';
import dayjs from 'dayjs';

export const tickFormatterHandlerFn = (value: any, time: FilterPeriod) => {
  switch (time) {
    case FilterPeriod.Day:
      return dayjs(value).format('HH:mm');
    case FilterPeriod.Week:
      return dayjs(value).format('dd');
    case FilterPeriod.Month:
      return dayjs(value).format('DD.MM.YY');
    case FilterPeriod.HalfYear:
      return dayjs(value).format('MMMM');
    case FilterPeriod.Year:
      return dayjs(value).format('MMM');
    default:
      return dayjs(value).format('MMM, YY');
  }
};
