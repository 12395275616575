import { ICustomEvent } from 'src/Interfaces/IEvent';
import dayjs from 'dayjs';
import { SlotInfo } from 'react-big-calendar';
import { resetTime } from 'src/utils/resetTime';

export const eventHandlerHelper = ({
  slotInfo,
  events,
  selectedShifts,
  showSidebar,
  setShowSidebar,
  setSelectedShifts,
}: {
  slotInfo: SlotInfo;
  events?: ICustomEvent[];
  selectedShifts?: ICustomEvent[];
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
  setSelectedShifts: (value: ICustomEvent[]) => void;
}) => {
  const shifts = events?.filter(
    event =>
      dayjs(event.start).startOf('day').valueOf() === Number(slotInfo.start)
  );

  if (
    selectedShifts &&
    resetTime(slotInfo.start) === resetTime(selectedShifts[0].start)
  ) {
    return;
  }
  if (showSidebar) {
    setShowSidebar(false);
    setTimeout(() => {
      if (shifts && shifts.length > 0) {
        setShowSidebar(true);
        setSelectedShifts(shifts);
      }
    }, 500);
  } else {
    if (shifts && shifts.length > 0) {
      setShowSidebar(true);
      setSelectedShifts(shifts);
    }
  }
};
