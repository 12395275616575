import { useMemo } from 'react';
import { RegularWorkpostsFragment } from 'src/generated/graphql';
import { useStore } from 'src/store';

const useSupervisorWorkposts = (
  workpostsFacilities: RegularWorkpostsFragment[]
) => {
  const { me } = useStore();
  return useMemo(
    () =>
      me?.Facility_SUPERVISOR?.map(facility =>
        workpostsFacilities.filter(
          workpost => workpost.facilityId === facility.id
        )
      ).flat(),
    [me, workpostsFacilities]
  );
};

export default useSupervisorWorkposts;
