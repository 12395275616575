import { parsePhoneNumber } from 'libphonenumber-js';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useGetUserDocumentsQuery, useSendDownloadSmsMutation } from '../../../generated/graphql';
import { useStore } from '../../../store';

import { errorHandler } from '../../../utils/errorHandler';
import { cityName } from '../../../utils/filters/filtersName';
import { errorToast, successToast } from '../../../utils/notify';
import { getSexRu } from '../../../utils/get/getSexRu';
import { roles, RU_DATE } from '../../../utils/constVariables';

import { MainInfoLoader } from './MainInfoLoader';
import FacilityResponsibility from '../FacilityResponsibility';
import MainInfoAddManagerToFacility from './ClientManager';
import MainInfoAddDirectorToGroup from './ClientDirector';
import MainInfoRoleAndAccess from './RoleAndAccess';
import convertPhone from 'src/hooks/convertPhone';

const MainUserInfo = () => {
  const { me, cities, currentUser, isUserLoading } = useStore();

  const { data: userDocumnents, loading } = useGetUserDocumentsQuery({
    variables: {
      workerId: currentUser?.id,
    },
  });

  const [sendDownloadSmsMutation] = useSendDownloadSmsMutation();

  const meRole = me?.role;

  const userFIO = useMemo(() => {
    const firstname = currentUser?.firstname;
    const lastname = currentUser?.lastname;
    const patronymic = currentUser?.patronymic ? currentUser?.patronymic : '';

    return `${lastname} ${firstname} ${patronymic}`;
  }, [currentUser?.lastname, currentUser?.firstname, currentUser?.patronymic]);

  const phoneConvert = convertPhone(currentUser?.phone);

  const submitForm = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (currentUser?.phone && currentUser.phone.length === 10 && /^\S*$/.test(currentUser.phone)) {
        const phone = parsePhoneNumber('8' + currentUser.phone, 'RU');
        if (phone.isValid()) {
          await sendDownloadSmsMutation({
            variables: {
              phone: currentUser.phone,
            },
          })
            .then(() => successToast('Сообщение успешно отправлено!'))
            .catch(err => errorToast(errorHandler(err)));
        } else {
          return errorToast('Некорректный номер телефона');
        }
      }
    },
    [currentUser?.phone, sendDownloadSmsMutation]
  );

  if (isUserLoading || loading || !currentUser || !me) {
    return <MainInfoLoader />;
  }

  const canSendApplicationLink = meRole === roles.Admin && [roles.Worker, roles.Foreman].includes(currentUser.role);

  const canSeeEditLink = ![roles.ClientDirector, roles.ClientManager].includes(me.role);

  return (
    <>
      <div className="userPageBlock blockTemplate">
        <div className="flex justify-between items-center flex-wrap">
          <span className="blockTitleTemplate">Основная информация</span>
          {canSeeEditLink && (
            <Link to={'/users/' + currentUser.id + '/documents'} className="Button2 text-primary">
              Изменить
            </Link>
          )}
        </div>

        <div className="flex items-center gap-x-7 flex-wrap">
          <span className="Tag text-smena_text-secondary">ФИО</span>
          <span className="Body1 text-smena_text">{userFIO}</span>
        </div>
        <div className="flex gap-x-32 flex-wrap gap-y-3">
          <div className="flex flex-col gap-y-2">
            <div className="flex items-center flex-wrap userMainInfo gap-x-7">
              <span className="Tag text-smena_text-secondary w-20">Телефон</span>
              <span className="Body1 text-smena_text">{phoneConvert}</span>
            </div>
            {currentUser.email && (
              <div className="flex items-center userMainInfo gap-x-7">
                <span className="Tag text-smena_text-secondary w-20">E-mail</span>
                <span className="Body1 text-smena_text">{currentUser.email}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-y-2">
            {currentUser.questionnaireTable?.sex && (
              <div className="flex items-center gap-x-7">
                <span className="Tag text-smena_text-secondary w-20">Пол</span>
                <span className="Body1 text-smena_text">{getSexRu(currentUser.questionnaireTable?.sex) || '-'}</span>
              </div>
            )}
            {userDocumnents?.getUserDocuments.requisite?.birthDate && (
              <div className="flex items-center gap-x-7">
                <span className="Tag text-smena_text-secondary w-20">Дата рождения</span>
                <span className="Body1 text-smena_text">
                  {dayjs(userDocumnents.getUserDocuments.requisite.birthDate).isValid()
                    ? dayjs(userDocumnents.getUserDocuments.requisite.birthDate).format(RU_DATE)
                    : userDocumnents?.getUserDocuments.requisite?.birthDate}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-x-32 flex-wrap gap-y-3">
          <div className="flex flex-col gap-y-2">
            {currentUser.cityId && (
              <div className="flex items-center userMainInfo gap-x-7">
                <span className="Tag text-smena_text-secondary w-20">Город</span>
                <span className="Body1 text-smena_text">{cityName(cities, currentUser.cityId)}</span>
              </div>
            )}
            {currentUser?.citizenship?.name && (
              <div className="flex items-center userMainInfo gap-x-7">
                <span className="Tag text-smena_text-secondary w-20">Гражданство</span>
                <span className="Body1 text-smena_text">{currentUser?.citizenship?.name}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="lg:col-span-4 md:col-span-6 col-span-full p-6 h-fit border rounded-lg shadow-smena bg-smena_white flex flex-col">
        <span className="Subtitle1 text-smena_text-secondary mb-5">Дополнительная информация</span>

        {currentUser && (
          <>
            <div className="flex gap-x-1 flex-wrap">
              <span className="Body2 text-smena_text-secondary">Текущая версия приложения:</span>
              <span className="Body2 text-smena_text">{currentUser.currentVersion}</span>
            </div>
            <div className="flex gap-x-1 mb-7 flex-wrap truncate">
              <span className="Body2 text-smena_text-secondary">ID Пользователя:</span>
              <span className="Body2 text-smena_text" title={currentUser.id}>
                {currentUser.id}
              </span>
            </div>
          </>
        )}
        {canSendApplicationLink && (
          <>
            <div className="flex items-center flex-wrap gap-x-2">
              <span className="Body2 text-smena_text">Отправить ссылку на приложение:</span>
              <button
                type="button"
                className="btn-secondary_small"
                disabled={!(currentUser.phone.length === 10 && /^\S*$/.test(currentUser.phone))}
                onClick={e => submitForm(e)}
              >
                <span>Отправить</span>
              </button>
            </div>

            <span className="Caption text-smena_text-secondary mt-2">
              Используйте активный мобильный телефон, по которому пользователь сможет получать СМС.
            </span>
          </>
        )}
      </div>
      {[roles.Admin, roles.Supervisor].includes(me.role) && <MainInfoRoleAndAccess />}

      {[roles.Supervisor, roles.Foreman].includes(currentUser.role) && <FacilityResponsibility />}

      {meRole && [roles.Admin].includes(meRole) && [roles.ClientDirector].includes(currentUser.role) && (
        <MainInfoAddDirectorToGroup />
      )}
      {[roles.ClientManager].includes(currentUser.role) && <MainInfoAddManagerToFacility />}
    </>
  );
};
export default MainUserInfo;
