import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import locale from 'antd/lib/locale/ru_RU';
import moment from 'moment';

import DatePickerIcon from '../../Assets/icons/DatePickerIcon';
import Plus from '../../Assets/icons/plus';

import MainLayout from '../../components/Layout/main';
import NewRequestRow from '../../components/Request/NewRequest/row';

import {
  useCreateZayavkaMutation,
  useFacilityQuery,
  useGetZayavkaQuery,
  useUpdateZayavkaMutation,
} from '../../generated/graphql';
import { IZayavkaPlanInput } from '../../Interfaces/IZayavkaPlanInput';
import { errorToast, infoToast, successToast } from '../../utils/notify';
import { errorHandler } from '../../utils/errorHandler';
import { ISO_DATE, RU_DATE } from 'src/utils/constVariables';
import { nanoid } from 'nanoid';
import SimpleSelect from 'src/components/ui/Select';
import { useStore } from 'src/store';
import { filterFacilities } from 'src/utils/filters/filterFacilities';
import { requestBreadcrumbs } from './helpers';

const { RangePicker } = DatePicker;

const UpdateRequest = () => {
  const { groups, facilities } = useStore();
  const router = useParams();
  const navigate = useNavigate();

  let facilityIdURL = '';
  let requestId = '';

  if (typeof router.requestId === 'string') {
    requestId = router.requestId;
  }

  if (typeof router.facilityId === 'string') {
    facilityIdURL = router.facilityId;
  }

  const [value, setValue] = useState<any[] | null>(null);
  const [facilityId, setFacilityId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [positionsList, setPositionsList] = useState<IZayavkaPlanInput[]>([]);

  const { data } = useGetZayavkaQuery({
    variables: {
      id: requestId,
    },
  });

  const request = data?.getZayavka;
  const requestPlan = request?.plans;

  const { data: dataFacility } = useFacilityQuery({
    variables: {
      id: request?.facilityId || '',
    },
  });

  const countFieldOfPositionList = useCallback(
    (field: keyof IZayavkaPlanInput) => {
      const clearPositionsLists = positionsList.map(listEl => Number(listEl[field])).filter(el => el);

      if (clearPositionsLists.length > 0) {
        return clearPositionsLists.reduce((prev, curr) => prev + curr);
      }
      return 0;
    },
    [positionsList]
  );

  const filteredFacilities = useMemo(() => filterFacilities(groupId, facilities), [groupId, facilities]);

  const [createRequest] = useCreateZayavkaMutation();
  const [updateRequest] = useUpdateZayavkaMutation();

  const handeSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!facilityIdURL) {
        if (!facilityId) {
          return infoToast('Выберите объект');
        }
      }
      if (!value) return infoToast('Выберите период');

      const requestInput = {
        dateFrom: moment(value[0]).format(ISO_DATE),
        dateTo: moment(value[1]).format(ISO_DATE),
        facilityId: facilityIdURL ? facilityIdURL : facilityId,
        plans: positionsList.map(el => ({
          positionId: el.positionId,
          hours: Number(el.hours),
          shiftCount: Number(el.shiftCount),
          shiftDuration: Number(el.shiftDuration),
        })),
      };
      if (requestId) {
        return updateRequest({
          variables: {
            id: requestId,
            input: requestInput,
          },
        })
          .then(() => successToast('Заявка успешно обновлена'))
          .catch(e => errorToast(errorHandler(e)));
      }
      return createRequest({
        variables: {
          input: requestInput,
        },
      })
        .then(response => {
          successToast('Заявка успешно создана');
          setTimeout(() => {
            navigate(`/requests/${response.data?.createZayavka.id}`);
          }, 2000);
        })
        .catch(e => errorToast(errorHandler(e)));
    },
    [createRequest, updateRequest, facilityIdURL, facilityId, positionsList, value, requestId, navigate]
  );

  const requestTitle = useMemo(() => {
    if (requestId) {
      return 'Редактировать заявку';
    }
    return 'Новая заявка';
  }, [requestId]);

  const breadcrumbs = useMemo(() => requestBreadcrumbs({ facilityIdURL, requestId }), [facilityIdURL, requestId]);

  const handleFacilityChange = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setFacilityId(value);
  };

  const handleGroupChange = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setGroupId(value);
  };

  useEffect(() => {
    if (dataFacility?.facility.facilityGroupId) {
      setGroupId(dataFacility.facility.facilityGroupId);
    }
  }, [dataFacility?.facility.facilityGroupId]);

  useEffect(() => {
    if (requestPlan) {
      setPositionsList(requestPlan.map(el => ({ ...el, number: nanoid() })));
    }
  }, [requestPlan]);

  useEffect(() => {
    if (request) {
      setValue([moment(request.dateFrom), moment(request.dateTo)]);
      setFacilityId(request.facilityId);
    }
  }, [request]);

  return (
    <MainLayout title={requestTitle} bg="bg-smena_bb-background_base" breadCrumbs={breadcrumbs}>
      <form className="grid grid-cols-12 gap-x-6 gap-y-6" onSubmit={handeSubmit}>
        <div className="grid grid-cols-12 col-span-full overflow-auto">
          <div className="requestPageBlock blockTemplate min-w-[720px] md:min-w-full">
            <h3 className="blockTitleTemplate">Определите период заявки</h3>
            <div className="flex flex-wrap gap-x-6">
              {!facilityIdURL && (
                <div className="flex gap-x-6">
                  <SimpleSelect
                    divClassName="max-w-[200px]"
                    label="Группа"
                    onChange={handleGroupChange}
                    value={groupId}
                    name="groupId"
                    disabledOption="Не выбрана"
                    validation
                    options={groups}
                  />
                  <SimpleSelect
                    divClassName="max-w-[200px]"
                    label="Объект"
                    onChange={handleFacilityChange}
                    value={facilityId}
                    name="facilityId"
                    disabledOption="Не выбрана"
                    validation
                    options={filteredFacilities}
                  />
                </div>
              )}
              <div>
                <label className="label-primary">Период</label>
                <ConfigProvider locale={locale}>
                  <RangePicker
                    size="middle"
                    separator={<span>→</span>}
                    placeholder={['Начало', 'Конец']}
                    className="custom__picker"
                    disabledDate={current => {
                      return current && current < moment().startOf('day');
                    }}
                    suffixIcon={<DatePickerIcon opacity="opacity-100" />}
                    value={value && [value[0], value[1]]}
                    format={RU_DATE}
                    onChange={(newValue: any) => {
                      setValue(newValue);
                    }}
                  />
                </ConfigProvider>
              </div>
            </div>

            <div>
              <h3 className="Subtitle1 text-smena_text-secondary py-6">Добавьте профессии и объем потребности</h3>
              {positionsList.length > 0 && (
                <div className="flex flex-col gap-y-6">
                  {positionsList.map(profession => (
                    <NewRequestRow
                      key={profession.id}
                      setPositionsList={setPositionsList}
                      list={positionsList}
                      el={profession}
                      facilityId={facilityId}
                    />
                  ))}
                </div>
              )}
              <div className="flex justify-between flex-wrap">
                <button
                  className="flex items-center gap-x-2 my-7 Button1 text-primary"
                  type="button"
                  onClick={() => {
                    setPositionsList(value => [
                      ...value,
                      {
                        id: nanoid(),
                        number: nanoid(),
                        positionId: '',
                        shiftCount: undefined,
                        shiftDuration: undefined,
                        hours: undefined,
                        error: {
                          shiftCount: '',
                          shiftDuration: '',
                          positionId: '',
                        },
                      },
                    ]);
                  }}
                >
                  <Plus className="text-primary" />
                  <span> Добавить профессию</span>
                </button>
                <div className="flex gap-x-6">
                  <div className="flex gap-x-2 items-center">
                    <span className="Subtitle2">Итого смен:</span>
                    <span className="Body2 text-smena_text-secondary">{countFieldOfPositionList('shiftCount')}</span>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <span className="Subtitle2">Итого часов:</span>
                    <span className="Body2 text-smena_text-secondary">{countFieldOfPositionList('hours')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="requestPageBlock justify-self-end">
          <button className="btn-primary" type="submit" disabled={positionsList.length < 1}>
            {requestId ? 'Сохранить заявку' : 'Создать заявку'}
          </button>
        </div>
      </form>
    </MainLayout>
  );
};

export default UpdateRequest;
