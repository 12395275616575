export const showTime = (time: { hours: number; minutes: number }) => {
  if (time.hours) {
    if (time.minutes) {
      return time.hours + ' час ' + time.minutes + ' минут';
    } else {
      return time.hours + ' час';
    }
  } else if (time.minutes) {
    return time.minutes + ' минут';
  }
};
