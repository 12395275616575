const SelfEmployedCheck = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="24" height="24" rx="12" fill="#55D581" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2254 7.26849C19.5915 7.62647 19.5915 8.20687 19.2254 8.56485L9.85041 17.7315C9.4843 18.0895 8.8907 18.0895 8.52459 17.7315L4.77459 14.0648C4.40847 13.7069 4.40847 13.1265 4.77459 12.7685C5.1407 12.4105 5.7343 12.4105 6.10041 12.7685L9.1875 15.787L17.8996 7.26849C18.2657 6.9105 18.8593 6.9105 19.2254 7.26849Z"
        fill="white"
      />
    </svg>
  );
};
export default SelfEmployedCheck;
