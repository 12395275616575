import RequestStatus from '../Status';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { isTest, RU_DATE } from '../../../utils/constVariables';
// import { Progress } from 'antd';
import DeclineButton from '../Buttons/decline';
import SendButton from '../Buttons/send';
import React, { useMemo } from 'react';
import { RegularZayavkaProgressFragment } from '../../../generated/graphql';
import { facilityName } from '../../../utils/filters/filtersName';
import { useStore } from '../../../store';
// import { percentColor, progressPercent } from '../../../utils/progressPercentHelper';

const RequestInformation = ({ request }: { request?: RegularZayavkaProgressFragment }) => {
  const { facilities } = useStore();
  const requestFacilityName = useMemo(
    () => facilityName(facilities, request?.facilityId),
    [facilities, request?.facilityId]
  );

  // const shiftsProgress = progressPercent(Number(request?.shiftsPlanCount), Number(request?.shiftsScheduleCount));
  // const shiftsStroke = percentColor(shiftsProgress);
  // const hoursProgress = progressPercent(Number(request?.hoursPlanCount), Number(request?.hoursScheduleCount));
  // const hoursStroke = percentColor(hoursProgress);

  return (
    <div className="flex justify-between flex-wrap gap-y-4">
      <div className="flex flex-wrap gap-x-6">
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Статус</span>
          <RequestStatus request={request} />
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Номер заявки</span>
          <span className="Body2">{request?.number}</span>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Объект</span>
          <Link className="text-primary Body2 hover:underline" to={`/facilities/${request?.facilityId}`}>
            {requestFacilityName}
          </Link>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Период</span>
          <span className="Body2">
            {dayjs(request?.dateFrom).format(RU_DATE)}
            {' - '}
            {dayjs(request?.dateTo).format(RU_DATE)}
          </span>
        </div>
        {/*<div className="flex flex-col gap-y-2">*/}
        {/*  <span className="Subtitle2">Сумма смен</span>*/}
        {/*  <div className="Body2 flex items-center gap-x-1">*/}
        {/*    <Progress*/}
        {/*      type="circle"*/}
        {/*      percent={shiftsProgress}*/}
        {/*      width={16}*/}
        {/*      showInfo={false}*/}
        {/*      strokeWidth={10}*/}
        {/*      trailColor="#E0E0E0"*/}
        {/*      strokeColor={shiftsStroke}*/}
        {/*    />*/}
        {/*    <div className="flex">*/}
        {/*      <span>{request?.shiftsScheduleCount}</span>*/}
        {/*      <span>/</span>*/}
        {/*      <span>{request?.shiftsPlanCount}</span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="flex flex-col gap-y-2">*/}
        {/*  <span className="Subtitle2">Сумма часов</span>*/}
        {/*  <div className="Body2 flex items-center gap-x-1">*/}
        {/*    <Progress*/}
        {/*      type="circle"*/}
        {/*      percent={hoursProgress}*/}
        {/*      width={16}*/}
        {/*      showInfo={false}*/}
        {/*      strokeWidth={10}*/}
        {/*      trailColor="#E0E0E0"*/}
        {/*      strokeColor={hoursStroke}*/}
        {/*    />*/}
        {/*    <div className="flex">*/}
        {/*      <span>{request?.hoursScheduleCount}</span>*/}
        {/*      <span>/</span>*/}
        {/*      <span>{request?.hoursPlanCount}</span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {isTest && (
          <>
            <div className="flex flex-col gap-y-2">
              <span className="Subtitle2">Статус заявки</span>
              <div className="Body2 flex items-center gap-x-1">{request?.status}</div>
            </div>
            <div className="flex flex-col gap-y-2">
              <span className="Subtitle2">Изменения</span>
              <div className="Body2 flex items-center gap-1 ">
                {request?.zayavkaChanges?.map(el => (
                  <div className="flex gap-x-2" key={el.id}>
                    <div className="flex flex-col">
                      <span>Статус (stage)</span>
                      <span>{el.stage}</span>
                    </div>
                    <div className="flex flex-col">
                      <span>Автор</span>
                      <span>{el.authorId}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex gap-x-2">
        <DeclineButton />
        <SendButton />
      </div>
    </div>
  );
};
export default RequestInformation;
