export const payoutTypes = [
  {
    id: 'HOURLY',
    name: 'Почасовая',
  },
  {
    id: 'PIECEWORK',
    name: 'Сдельная ',
  },
];
