import { useCallback, useState } from 'react';
import { FilterPeriod, MetricTypes, useGetMetricsQuery } from '../../../../generated/graphql';
import { useStore } from '../../../../store';
import { getTimePeriodCharts } from '../../../../utils/get/getTimeePeriodCharts';
import { chartTimes } from '../../../../utils/lists/chartTimes';
import SimpleSelect from '../../../ui/Select';
import { ITooltipStatus } from '../../../../Interfaces/ITooltipStatus';
import { lastTimeLength } from '../../../../utils/charts/lastTimeLength';
import { useGetMetrics, useTypes } from '../../hooks';
import CustomBarChart from '../../CustomBarChart';
import { allValue } from '../helpers';

const AcceptedShiftsChart = () => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  const [time, setTime] = useState(FilterPeriod.Week);
  const [focusBar, setFocusBar] = useState<undefined | number>(undefined);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });

  const types = useTypes({
    facility: MetricTypes.ShiftsAcceptedFacility,
    group: MetricTypes.ShiftsAcceptedFacilityGroup,
    all: MetricTypes.ShiftsAcceptedAll,
  });

  const { data } = useGetMetricsQuery({
    variables: {
      input: {
        facilityId: allValue(trendsFacilityId),
        facilityGroupId: allValue(trendsGroupId),
        filterPeriod: time,
        types,
      },
    },
  });

  const handleTimeChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setTime(value as FilterPeriod);
  }, []);

  const res = useGetMetrics({
    getMetrics: data?.getMetrics,
    facilityMetricType: MetricTypes.ShiftsAcceptedFacility,
    facilityGroupMetricType: MetricTypes.ShiftsAcceptedFacilityGroup,
    allMetricType: MetricTypes.ShiftsAcceptedAll,
  });

  return (
    <div className="px-5">
      <div className="flex justify-between items-center">
        <span className="Subtitle1 text-smena_text-secondary">Подтвержденные смены</span>
        <div className="flex">
          <SimpleSelect
            divClassName="chart-filter"
            label=""
            onChange={handleTimeChange}
            value={time}
            sort={false}
            name="time"
            options={chartTimes}
          />
        </div>
      </div>
      <div className="flex gap-x-1 items-end mt-5 mb-2">
        <span className="ChartNumber text-smena_text">{lastTimeLength(time, res)}</span>
        <span className="Caption text-smena_text-secondary">за {getTimePeriodCharts(time)}</span>
      </div>
      <div className="chartTrendBlock">
        <CustomBarChart
          setFocusBar={setFocusBar}
          fillColorOpacity="#BD6CFE40"
          fillColor="#BD6CFE"
          tooltipStatus={tooltipStatus}
          setTooltipStatus={setTooltipStatus}
          formatter="Подтвержденных смен"
          focusBar={focusBar}
          res={res}
          time={time}
        />
      </div>
    </div>
  );
};
export default AcceptedShiftsChart;
