import { useMemo } from 'react';
import AcrossSmall from '../../../../Assets/icons/AcrossSmall';
import { useStore } from '../../../../store';
interface IManagamentWorkpost {
  workpost: {
    positionId: string;
    facilityId: string;
  };
  canRemove?: boolean;
  removeWorkpost: (facilityId: string, positionId: string) => void;
}

export const ManagamentWorkpost: React.FC<IManagamentWorkpost> = ({
  workpost: { positionId, facilityId },
  removeWorkpost,
  canRemove = true,
}) => {
  const { positions } = useStore();
  const positionName = useMemo(
    () => positions?.find(position => position.id === positionId)?.name,
    [positions, positionId]
  );
  return (
    <div
      className="py-1 pl-3 pr-2 bg-smena_bb-background rounded-lg items-center flex gap-x-1 group hover:bg-smena_red-extra_light"
      style={{ height: 'max-content' }}
    >
      <span className="Caption-numbers text-primary group-hover:text-smena_red">
        {positionName}
      </span>
      {canRemove && (
        <button
          onClick={() => {
            if (facilityId && positionId) {
              removeWorkpost(facilityId, positionId);
            }
          }}
        >
          <AcrossSmall color="text-primary group-hover:text-smena_red" />
        </button>
      )}
    </div>
  );
};
