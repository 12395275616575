import clsx from 'clsx';
import { useMemo } from 'react';
import { ErrorNotification } from 'src/components/ui/ErrorNotification';
import { RegularWorkpostsFragment } from 'src/generated/graphql';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';
import { sortObj } from 'src/utils/sortObject';

interface IFacilitySelect {
  facilityIdError: boolean;
  facilityId: string;
  selectFacilityHandler: ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => void;
  supervisorWorkposts?: RegularWorkpostsFragment[];
  workpostsFacilities: RegularWorkpostsFragment[];
}

const FacilitySelect: React.FC<IFacilitySelect> = ({
  facilityIdError,
  facilityId,
  selectFacilityHandler,
  supervisorWorkposts,
  workpostsFacilities,
}) => {
  const { me } = useStore();

  const options = useMemo(() => {
    if (me?.role === roles.Supervisor) {
      return supervisorWorkposts;
    }
    return workpostsFacilities;
  }, [me?.role, supervisorWorkposts, workpostsFacilities]);
  return (
    <div className="inputHeight crudUserInput">
      <label htmlFor="facilityId" className="label-primary">
        Объект
      </label>
      <select
        name="facilityId"
        className={clsx(facilityIdError ? 'select-error' : 'select-primary')}
        value={facilityId}
        onChange={selectFacilityHandler}
      >
        {options?.length === 1 ? (
          <option key={options[0].facilityId} value={options[0].facilityId}>
            {options[0].facility?.name}
          </option>
        ) : (
          <>
            <option value="">Все объекты</option>
            {options &&
              sortObj(options).map(workpost => (
                <option key={workpost.facilityId} value={workpost.facilityId}>
                  {workpost.facility?.name}
                </option>
              ))}
          </>
        )}
      </select>
      {facilityIdError && <ErrorNotification text="Выберите объект" />}
    </div>
  );
};

export default FacilitySelect;
