export const usersColumns = [
  {
    Header: 'Фамилия',
    header_className: 'table__header pl-4',
    name: 'lastname',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 150,
      width: 170,
    },
  },
  {
    Header: 'Имя',
    header_className: 'table__header pl-4',
    name: 'firstname',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 130,
      width: 150,
    },
  },
  {
    Header: 'Отчество',
    header_className: 'table__header pl-4',
    name: 'patronymic',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 180,
      minWidth: 130,
    },
  },
  {
    Header: 'Док-ты',
    header_className: 'table__header flex justify-center',
    name: 'documents',
    sorted: false,
    className: 'table__content flex justify-center items-start',
    style: {
      width: 105,
      minWidth: 100,
    },
  },
  {
    Header: 'Роль',
    header_className: 'table__header pl-4',
    name: 'role',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 90,
      width: 100,
    },
  },
  {
    Header: 'Телефон',
    header_className: 'table__header pl-4',
    name: 'phone',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 100,
      width: 115,
    },
  },
  {
    Header: 'Объект',
    header_className: 'table__header pl-4',
    name: 'facility',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 200,
      width: 215,
    },
  },
  {
    Header: 'Версия',
    header_className: 'table__header pl-4',
    name: 'currentVersion',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 90,
      width: 90,
    },
  },
  {
    Header: 'Статус',
    header_className: 'table__header flex justify-center',
    name: 'isActive',
    sorted: false,
    className: 'table__content flex justify-center',
    style: {
      minWidth: 60,
      width: 70,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 130,
      width: 130,
    },
  },
];
export const workersColumns = [
  {
    Header: 'Фамилия',
    header_className: 'table__header pl-4',
    name: 'lastname',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 150,
      width: 170,
    },
  },
  {
    Header: 'Имя',
    header_className: 'table__header pl-4',
    name: 'firstname',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 130,
      width: 150,
    },
  },
  {
    Header: 'Отчество',
    header_className: 'table__header pl-4',
    name: 'patronymic',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 180,
      minWidth: 130,
    },
  },
  {
    Header: 'Телефон',
    header_className: 'table__header pl-4',
    name: 'phone',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 120,
      width: 125,
    },
  },
  {
    Header: 'Роль',
    header_className: 'table__header pl-4',
    name: 'role',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 90,
      width: 100,
    },
  },
  {
    Header: 'Объект',
    header_className: 'table__header pl-4',
    name: 'facility',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 200,
      width: 215,
    },
  },
  {
    Header: 'Активные смены',
    header_className: 'table__header pl-4',
    name: 'activeShifts',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 120,
      width: 130,
    },
  },
  {
    Header: 'Рейтинг',
    header_className: 'table__header pl-4',
    name: 'rating',
    sorted: true,
    className: 'py-4 pl-4 Button1',
    style: {
      minWidth: 110,
      width: 115,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4 relative',
    style: {
      minWidth: 100,
      width: 110,
    },
  },
];

export const facilitiesColumns = [
  {
    Header: 'Группа',
    header_className: 'table__header pl-4',
    name: 'group',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 130,
      width: 150,
    },
  },
  {
    Header: 'Объект',
    header_className: 'table__header pl-4',
    name: 'name',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 160,
      width: 180,
    },
  },
  {
    Header: 'Город',
    header_className: 'table__header pl-4',
    name: 'city',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 140,
      width: 160,
    },
  },
  {
    Header: 'Адрес',
    header_className: 'table__header pl-4',
    name: 'address',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 250,
      width: 270,
    },
  },
  {
    Header: 'Метро',
    header_className: 'table__header pl-4',
    name: 'metro',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 210,
      width: 230,
    },
  },
  {
    Header: 'Статус',
    header_className: 'table__header flex justify-center',
    name: 'status',
    sorted: false,
    className: 'table__content flex justify-center',
    style: {
      minWidth: 65,
      width: 77,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 100,
      width: 100,
    },
  },
];

export const facilitiesClientColumns = [
  {
    Header: 'Объект',
    header_className: 'table__header pl-4',
    name: 'name',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 160,
      width: 180,
    },
  },
  {
    Header: 'Город',
    header_className: 'table__header pl-4',
    name: 'city',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 140,
      width: 160,
    },
  },
  {
    Header: 'Адрес',
    header_className: 'table__header pl-4',
    name: 'address',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 250,
      width: 270,
    },
  },
  {
    Header: 'Метро',
    header_className: 'table__header pl-4',
    name: 'metro',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 210,
      width: 230,
    },
  },
  {
    Header: 'Статус',
    header_className: 'table__header flex justify-center',
    name: 'status',
    sorted: false,
    className: 'table__content flex justify-center',
    style: {
      minWidth: 65,
      width: 77,
    },
  },
];

export const facilitiesGroupColumns = [
  {
    Header: 'Объект',
    header_className: 'table__header pl-4',
    name: 'name',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 120,
      width: 183,
    },
  },
  {
    Header: 'Город',
    header_className: 'table__header pl-4',
    name: 'city',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 140,
      width: 184,
    },
  },
  {
    Header: 'Адрес',
    header_className: 'table__header pl-4',
    name: 'address',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 150,
      width: 285,
    },
  },
  {
    Header: 'Метро',
    header_className: 'table__header pl-4',
    name: 'metro',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 150,
      width: 285,
    },
  },
  {
    Header: 'Статус',
    header_className: 'table__header flex justify-center',
    name: 'status',
    sorted: false,
    className: 'table__content flex justify-center',
    style: {
      minWidth: 60,
      width: 81,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 100,
      width: 100,
    },
  },
];

export const leadColumns = [
  {
    Header: 'Дата',
    header_className: 'table__header pl-4',
    name: 'date',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 90,
      width: 100,
    },
  },
  {
    Header: 'Реферер',
    header_className: 'table__header pl-4',
    name: 'referar',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 130,
      width: 152,
    },
  },
  {
    Header: 'Телефон',
    header_className: 'table__header pl-4',
    name: 'leadPhone',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 90,
      width: 120,
    },
  },
  {
    Header: 'Лид',
    header_className: 'table__header pl-4',
    name: 'lead',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 197,
      minWidth: 150,
    },
  },
  {
    Header: 'Телефон',
    header_className: 'table__header pl-4',
    name: 'referalPhone',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 115,
      minWidth: 100,
    },
  },
  {
    Header: 'Обработан',
    header_className: 'table__header flex justify-center',
    name: 'called',
    sorted: true,
    className: 'table__content flex justify-center',
    style: {
      width: 119,
      minWidth: 90,
    },
  },
  {
    Header: 'Статус',
    header_className: 'table__header pl-4',
    name: 'status',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 131,
      minWidth: 110,
    },
  },
  {
    Header: 'Смены',
    header_className: 'table__header pl-4',
    name: 'shifts',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      flex: '1 0 auto',
      width: 115,
      minWidth: 90,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 100,
      minWidth: 100,
    },
  },
];

export const positionsColumns = [
  {
    Header: 'Название',
    header_className: 'table__header pl-4',
    name: 'name',
    sorted: false,
    className: 'py-4 pl-4 Table-small text-primary underline',
    style: {
      width: 200,
      minWidth: 185,
    },
  },
  {
    Header: 'Самозанятость',
    header_className: 'table__header flex justify-center',
    name: 'selfEmployed',
    sorted: false,
    className: 'table__content flex justify-center',
    style: {
      width: 160,
      minWidth: 150,
    },
  },
];

export const vacanciesColumns = [
  {
    Header: 'Вакансия',
    header_className: 'table__header pl-4',
    name: 'position',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      width: 180,
      minWidth: 160,
    },
  },
  {
    Header: 'Объект',
    header_className: 'table__header pl-4',
    name: 'facility',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 180,
      minWidth: 160,
    },
  },
  {
    Header: 'Начало смены',
    header_className: 'table__header pl-4',
    name: 'timeStart',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      width: 93,
      minWidth: 90,
    },
  },
  {
    Header: 'Конец Смены',
    header_className: 'table__header pl-4',
    name: 'end',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 86,
      minWidth: 80,
    },
  },
  {
    Header: 'День/Ночь',
    header_className: 'table__header pl-4',
    name: 'day/night',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 109,
      minWidth: 100,
    },
  },
  {
    Header: 'График',
    header_className: 'table__header pl-4',
    name: 'schedule',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      width: 107,
      minWidth: 100,
    },
  },
  {
    Header: 'Отдел',
    header_className: 'table__header pl-4',
    name: 'section',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 160,
      minWidth: 150,
    },
  },
  {
    Header: 'Тип оплаты',
    header_className: 'table__header pl-4',
    name: 'payout',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 110,
      minWidth: 100,
    },
  },
  {
    Header: 'Ед. измерения',
    header_className: 'table__header pl-4',
    name: 'unit',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 110,
      minWidth: 110,
    },
  },
  {
    Header: 'ставка(₽)',
    header_className: 'table__header pl-4',
    name: 'rate',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 100,
      minWidth: 95,
    },
  },
  {
    Header: 'Статус',
    header_className: 'table__header flex justify-center',
    name: 'status',
    sorted: false,
    className: 'table__content flex justify-center',
    style: {
      width: 65,
      minWidth: 65,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 100,
      minWidth: 100,
    },
  },
];

export const facilityVacanciesColumns = [
  {
    Header: 'Вакансия',
    header_className: 'table__header pl-4',
    name: 'position',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      width: 180,
      minWidth: 160,
    },
  },
  {
    Header: 'Начало смены',
    header_className: 'table__header pl-4',
    name: 'timeStart',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      width: 160,
      minWidth: 130,
    },
  },
  {
    Header: 'Конец Смены',
    header_className: 'table__header pl-4',
    name: 'end',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 125,
      minWidth: 120,
    },
  },
  {
    Header: 'День/Ночь',
    header_className: 'table__header pl-4',
    name: 'day/night',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 109,
      minWidth: 100,
    },
  },
  {
    Header: 'График',
    header_className: 'table__header pl-4',
    name: 'schedule',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      width: 107,
      minWidth: 100,
    },
  },
  {
    Header: 'Отдел',
    header_className: 'table__header pl-4',
    name: 'section',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 150,
      minWidth: 100,
    },
  },
  {
    Header: 'Тип оплаты',
    header_className: 'table__header pl-4',
    name: 'payout',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 110,
      minWidth: 100,
    },
  },
  {
    Header: 'Ед. измерения',
    header_className: 'table__header pl-4',
    name: 'unit',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 110,
      minWidth: 110,
    },
  },
  {
    Header: 'ставка(₽)',
    header_className: 'table__header pl-4',
    name: 'rate',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 100,
      minWidth: 95,
    },
  },
  {
    Header: 'Статус',
    header_className: 'table__header flex justify-center',
    name: 'status',
    sorted: false,
    className: 'table__content flex justify-center',
    style: {
      width: 65,
      minWidth: 65,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 100,
      minWidth: 100,
    },
  },
];

export const searchUserUpdateModeration = [
  {
    Header: 'Фамилия',
    header_className: 'table__header pl-4',
    name: 'lastname',
    sorted: false,
    className: 'table__content pl-4 flex items-center z-10',
    style: {
      minWidth: 150,
      width: 170,
    },
  },
  {
    Header: 'Имя',
    header_className: 'table__header pl-4',
    name: 'firstname',
    sorted: false,
    className: 'table__content pl-4 flex items-center',
    style: {
      minWidth: 130,
      width: 150,
    },
  },
  {
    Header: 'Отчество',
    header_className: 'table__header pl-4',
    name: 'patronymic',
    sorted: false,
    className: 'table__content pl-4 flex items-center',
    style: {
      width: 180,
      minWidth: 130,
    },
  },
  {
    Header: 'Телефон',
    header_className: 'table__header pl-4',
    name: 'phone',
    sorted: false,
    className: 'table__content pl-4 flex items-center',
    style: {
      minWidth: 100,
      width: 115,
    },
  },
  {
    Header: 'Профессия',
    header_className: 'table__header pl-4',
    name: 'profession',
    sorted: false,
    className: 'table__content pl-4 flex items-center',
    style: {
      minWidth: 240,
      width: 240,
    },
  },
  {
    Header: '',
    header_className: 'table__header pl-4',
    name: 'action',
    sorted: false,
    className: 'table__content pl-4 flex items-center',
    style: {
      minWidth: 100,
      width: 115,
    },
  },
];

export const moderationReportColumns = [
  {
    Header: 'Период',
    header_className: 'table__header pl-4',
    name: 'dateStart',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      width: 165,
      minWidth: 165,
    },
  },
  {
    Header: 'Группа',
    header_className: 'table__header pl-4',
    name: 'group',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 165,
      minWidth: 165,
    },
  },
  {
    Header: 'Объект',
    header_className: 'table__header pl-4',
    name: 'facility',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 165,
      minWidth: 165,
    },
  },
  {
    Header: 'Исполнители',
    header_className: 'table__header pl-4',
    name: 'employes',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 132,
      minWidth: 132,
    },
  },
  {
    Header: 'Изменен',
    header_className: 'table__header pl-4',
    name: 'updatedAt',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      width: 120,
      minWidth: 120,
    },
  },
  {
    Header: 'Статус',
    header_className: 'table__header flex justify-center',
    name: 'status',
    sorted: false,
    className: 'table__content flex justify-center',
    style: {
      width: 131,
      minWidth: 131,
    },
  },
  {
    Header: 'Дата статуса',
    header_className: 'table__header pl-4',
    name: 'statusDate',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      width: 150,
      minWidth: 150,
    },
  },
  {
    Header: '',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      width: 100,
      minWidth: 100,
    },
  },
];

export const requestsColumns = [
  {
    Header: 'Номер',
    header_className: 'table__header pl-4',
    name: 'number',
    sorted: true,
    className: 'py-4 pl-4 Table-small text-primary',
    style: {
      minWidth: 70,
      width: 75,
    },
  },
  {
    Header: 'Период',
    header_className: 'table__header pl-4',
    name: 'period',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 160,
      width: 170,
    },
  },
  {
    Header: 'Группа',
    header_className: 'table__header pl-4',
    name: 'group',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 140,
      width: 150,
    },
  },
  {
    Header: 'Объект',
    header_className: 'table__header pl-4',
    name: 'facility',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 140,
      width: 150,
    },
  },
  {
    Header: 'Профессии',
    header_className: 'table__header pl-4',
    name: 'positions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 150,
      width: 150,
    },
  },
  // {
  //   Header: 'Кол-во смен',
  //   header_className: 'table__header pl-4',
  //   name: 'shifts',
  //   sorted: false,
  //   className: 'table__content flex gap-x-2 pl-4',
  //   style: {
  //     minWidth: 100,
  //     width: 120,
  //   },
  // },
  // {
  //   Header: 'Кол-во часов',
  //   header_className: 'table__header pl-4',
  //   name: 'hours',
  //   sorted: false,
  //   className: 'table__content flex gap-x-2 pl-4',
  //   style: {
  //     minWidth: 100,
  //     width: 130,
  //   },
  // },
  {
    Header: 'Статусы',
    header_className: 'table__header pl-4',
    name: 'status',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 170,
      width: 190,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 95,
      width: 120,
    },
  },
];

export const facilityRequestsColumns = [
  {
    Header: 'Номер',
    header_className: 'table__header pl-4',
    name: 'number',
    sorted: true,
    className: 'py-4 pl-4 Table-small text-primary',
    style: {
      minWidth: 70,
      width: 75,
    },
  },
  {
    Header: 'Период',
    header_className: 'table__header pl-4',
    name: 'period',
    sorted: true,
    className: 'table__content pl-4',
    style: {
      minWidth: 160,
      width: 170,
    },
  },
  {
    Header: 'Профессии',
    header_className: 'table__header pl-4',
    name: 'positions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 150,
      width: 150,
    },
  },
  // {
  //   Header: 'Кол-во смен',
  //   header_className: 'table__header pl-4',
  //   name: 'shifts',
  //   sorted: false,
  //   className: 'table__content flex gap-x-2 pl-4',
  //   style: {
  //     minWidth: 100,
  //     width: 120,
  //   },
  // },
  // {
  //   Header: 'Кол-во часов',
  //   header_className: 'table__header pl-4',
  //   name: 'hours',
  //   sorted: false,
  //   className: 'table__content flex gap-x-2 pl-4',
  //   style: {
  //     minWidth: 100,
  //     width: 130,
  //   },
  // },
  {
    Header: 'Статус',
    header_className: 'table__header pl-4',
    name: 'status',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 170,
      width: 190,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header pl-4',
    name: 'actions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 95,
      width: 120,
    },
  },
];

export const scheduleRequestsColumns = [
  {
    Header: 'Номер',
    header_className: 'table__header pl-4',
    name: 'number',
    sorted: false,
    className: 'py-4 pl-4 Table-small text-primary',
    style: {
      minWidth: 70,
      width: 75,
    },
  },
  {
    Header: 'Период',
    header_className: 'table__header pl-4',
    name: 'period',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 160,
      width: 170,
    },
  },
  {
    Header: 'Профессии',
    header_className: 'table__header pl-4',
    name: 'positions',
    sorted: false,
    className: 'table__content pl-4',
    style: {
      minWidth: 150,
      width: 150,
    },
  },
  // {
  //   Header: 'Кол-во смен',
  //   header_className: 'table__header pl-4',
  //   name: 'shifts',
  //   sorted: false,
  //   className: 'table__content flex gap-x-2 pl-4',
  //   style: {
  //     minWidth: 100,
  //     width: 120,
  //   },
  // },
  // {
  //   Header: 'Кол-во часов',
  //   header_className: 'table__header pl-4',
  //   name: 'hours',
  //   sorted: false,
  //   className: 'table__content flex gap-x-2 pl-4',
  //   style: {
  //     minWidth: 100,
  //     width: 130,
  //   },
  // },
];
