import { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { useChangeCustomScheduleMutation } from '../../../../generated/graphql';

import { useStore } from '../../../../store';

import { errorHandler } from '../../../../utils/errorHandler';
import { errorToast, successToast } from '../../../../utils/notify';
import { IPopup } from '../../../../Interfaces/IPopup';
import Popup from '../../../ui/Popup';
import { userShiftsQueryConfigFn } from 'src/utils/graphqlConfigs/userShiftsConfig';

const SchedulePopup: React.FC<IPopup> = ({ setPopup, popup, dateFrom, dateTo }) => {
  const [loading, setLoading] = useState(false);
  const { customScheduleEvents, setCustomScheduleEvents, currentUser } = useStore();

  const userShiftsQueryConfig = userShiftsQueryConfigFn(
    String(dayjs(dateFrom).startOf('month').valueOf()),
    String(dayjs(dateTo).endOf('month').valueOf()),
    currentUser?.id
  );

  const [changeCustomScheduleMutation] = useChangeCustomScheduleMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userShiftsQueryConfig],
  });

  const customScheduleValue = useMemo(
    () =>
      customScheduleEvents.map(event => {
        const {
          resource: { userId, lunchDuration, dateFrom, dateEnd, positionId, facility },
        } = event;

        return {
          userId,
          facilityId: facility?.id || '',
          positionId,
          dateFrom,
          shiftEndDate: dateEnd,
          lunchDuration: lunchDuration || 0,
        };
      }),
    [customScheduleEvents]
  );

  const saveCustomSchedule = () => {
    setLoading(true);

    if (customScheduleEvents.length === 0) {
      return errorToast('Добавьте смены на график');
    }

    changeCustomScheduleMutation({
      variables: {
        inputs: customScheduleValue,
      },
    })
      .then(() => {
        setCustomScheduleEvents([]);
        successToast('Вы успешно предложили график');
      })
      .catch(e => errorToast(`Произошла ошибка: ${errorHandler(e)}`))
      .finally(() => {
        setLoading(false);
        setPopup(false);
      });
  };

  return (
    <Popup setShowPopup={setPopup} showPopup={popup}>
      <div className="fixed top-12 left-1/2 transform -translate-x-2/4 shadow-smena rounded-lg bg-smena_white z-50 w-[780px] h-[255px] px-[30px] py-10">
        <h3 className="H3 text-smena_text mb-4">Сохранить новые смены?</h3>
        <h4 className="H4 text-smena_text-secondary mb-7">
          После сохранения смены будут добавлены в график и исполнитель увидит их в своем приложении.
        </h4>
        <div className="flex items-center justify-end gap-x-5">
          <button className="btn-stroke" onClick={() => setPopup(false)}>
            Отмена
          </button>
          <button className="btn-primary_small" disabled={loading} onClick={saveCustomSchedule} type="button">
            Сохранить
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default SchedulePopup;
