import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ResponsibleUser from '../../../components/facility/ResponsibleUsers';
import AutocompleteBlock from '../../../components/ui/AutocompleteBlock';
import {
  useAddForemanToFacilityMutation,
  useAddSupervisorToFacilityMutation,
  useForemansQuery,
  useSupervisorsQuery,
} from '../../../generated/graphql';
import { IOption } from '../../../Interfaces/IOption';
import { errorToast, successToast } from '../../../utils/notify';
import { useStore } from '../../../store';
import { roles } from '../../../utils/constVariables';
import { facilityConfigFn } from '../../../utils/graphqlConfigs/facilityConfig';
import { errorHandler } from '../../../utils/errorHandler';
import { IClient } from '../../../Interfaces/IClient';

interface IFacilityForemenSupervisors {
  facilityForemen?: IClient[] | null;
  facilitySupervisors?: IClient[] | null;
}

const FacilityForemenSupervisors: React.FC<IFacilityForemenSupervisors> = ({
  facilityForemen,
  facilitySupervisors,
}) => {
  const router = useParams();

  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }

  const facilityConfig = facilityConfigFn(facilityId);

  const { me } = useStore();

  const admin = me?.role === roles.Admin;

  const [foreman, setForeman] = useState<IOption | null>(null);

  const [supervisor, setSupervisor] = useState<IOption | null>(null);

  const { data: dataForemen } = useForemansQuery();
  const { data: dataSupervisors } = useSupervisorsQuery();

  const foremenOptions = dataForemen?.foremans.map(foreman => {
    const { id, lastname, firstname } = foreman;
    return {
      name: lastname + ' ' + firstname,
      id,
    };
  });

  const supervisorsOptions = dataSupervisors?.supervisors.map(supervisor => {
    const { id, firstname, lastname } = supervisor;
    return {
      name: lastname + ' ' + firstname,
      id,
    };
  });

  const [addForeman] = useAddForemanToFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilityConfig],
  });

  const [addSupervisor] = useAddSupervisorToFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilityConfig],
  });

  const addSupervisorHandler = async (userId: string) => {
    await addSupervisor({
      variables: {
        facilityId,
        userId,
      },
    })
      .then(response => {
        if (response.data?.addSupervisorToFacility) {
          successToast('Супервайзер прикреплен');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  const addForemanHandler = async (userId: string) => {
    await addForeman({
      variables: {
        facilityId,
        userId,
      },
    })
      .then(response => {
        if (response.data?.addForemanToFacility) {
          successToast('Бригадир прикреплен');
        }
      })
      .catch(error => errorToast(errorHandler(error)));
  };

  return (
    <div className="facilityPageBlock blockTemplate">
      <div className="grid grid-cols-8 gap-x-5">
        <span className="Subtitle1 text-smena_text-secondary inline-block col-span-full">Ответственные</span>
        <div className="col-span-full grid grid-cols-8 gap-x-5 pb-10 mb-5 border-b border-smena_gray-30">
          <div className="sm:col-span-4 col-span-8">
            <div
              className="grid gap-x-2 items-baseline"
              style={{
                gridTemplateColumns: 'max-content 1fr',
              }}
            >
              <span className="Subtitle2">Супервайзер</span>
              <div>
                {facilitySupervisors?.length ? (
                  facilitySupervisors.map(supervisor => (
                    <ResponsibleUser key={supervisor.id} user={supervisor} canRemove={me?.role === roles.Admin} />
                  ))
                ) : (
                  <span className="Body2 text-smena_text-secondary">Не назначен</span>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-4 col-span-8">
            <div
              className="grid gap-x-2 items-baseline"
              style={{
                gridTemplateColumns: 'max-content 1fr',
              }}
            >
              <span className="Subtitle2">Бригадир</span>
              <div>
                {facilityForemen?.length ? (
                  facilityForemen.map(foreman => (
                    <ResponsibleUser key={foreman.id} user={foreman} canRemove={me?.role === roles.Admin} />
                  ))
                ) : (
                  <span className="Body2 text-smena_text-secondary">Не назначен</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {admin && (
          <>
            <AutocompleteBlock
              id="supervisorId"
              label="Супервайзер"
              value={supervisor}
              onChangeHandler={value => setSupervisor(value)}
              options={supervisorsOptions}
              buttonHandler={() => {
                if (supervisor) {
                  addSupervisorHandler(supervisor.id);
                }
              }}
            />
            <AutocompleteBlock
              id="foremanId"
              label="Бригадир"
              value={foreman}
              onChangeHandler={value => setForeman(value)}
              options={foremenOptions}
              buttonHandler={() => {
                if (foreman) {
                  addForemanHandler(foreman.id);
                }
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default FacilityForemenSupervisors;
