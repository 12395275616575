export const units = [
  {
    id: 'PIECE',
    name: 'Заказы, шт.',
  },
  {
    id: 'POINT',
    name: 'Процент выработки, %',
  },
];
export const hourlyUnit = [
  {
    id: 'HOUR',
    name: 'Часы, ч.',
  },
];
export const allUnits = [
  {
    id: 'PIECE',
    name: 'Заказы, шт.',
  },
  {
    id: 'POINT',
    name: 'Процент выработки, %',
  },
  {
    id: 'HOUR',
    name: 'Часы, ч.',
  },
];
