export const LunchDurationToString = (lunchDuration?: number | null) => {
  if (lunchDuration) {
    if (lunchDuration > 60) {
      return `${lunchDuration - (lunchDuration % 60)} часов ${
        lunchDuration % 60
      } минут`;
    }
    return `${lunchDuration % 60} минут`;
  }
  return 'Без обеда';
};
