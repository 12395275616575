import { useMemo } from 'react';
import {
  clientMenuItemList,
  menuItemList,
  superMenuItemList,
} from 'src/components/Layout/Menu/List';

import { roles } from 'src/utils/constVariables';

const useMenuElement = (role?: string) => {
  return useMemo(() => {
    if (role === roles.Supervisor) {
      return superMenuItemList;
    }
    if (role && [roles.ClientManager, roles.ClientDirector].includes(role)) {
      return clientMenuItemList;
    }
    return menuItemList;
  }, [role]);
};

export default useMenuElement;
