import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';

import {
  ModerationStatus,
  RegularModerationShiftFragment,
  ShiftStage,
  UnitType,
  useChangeShiftModerationMutation,
} from '../../../../../generated/graphql';

import { errorToast, infoToast, successToast } from '../../../../../utils/notify';
import { durationFn } from '../../../../../utils/durationFn';

import SimpleInput from '../../../../../components/ui/Input';
import SelectArrow from '../../../../../Assets/icons/SelectArrow';
import { getDistance } from '../../../../../utils/get/getDistance';
import { useStore } from '../../../../../store';
import { timeObject2 } from '../../../../../utils/timeObject';
import { unitSymbolFn } from '../../../../../utils/unitSymbol';
import { moderationReportConfigFn } from '../../../../../utils/graphqlConfigs/moderationReportConfig';
import { errorHandler } from '../../../../../utils/errorHandler';
import ShiftTitle from './ShiftTitle';
import ShiftDateStartEnd from './ShiftDateStartEnd';
import ShiftAproveBlock from './ShiftAproveBlock';
import { isInt } from '../../../../../utils/isInt';
import { RU_DATE } from '../../../../../utils/constVariables';

export const InternShifts: React.FC<{
  shift: RegularModerationShiftFragment;
  index: number;
}> = ({ shift, index }) => {
  const { durationFact, durationPlan, stage, moderation, marks, unit, cost } = shift;

  const { moderationReport } = useStore();

  const shiftResult = moderation || shift;
  const [show, setShow] = useState(index === 0);
  const [showCheckins, setShowCheckins] = useState(false);
  const [time, setTime] = useState<Date | null>(null);
  const [value, setValue] = useState(String(isInt(cost)) || '0');
  const [toPayment, setToPayment] = useState(true);
  const [reason, setReason] = useState(moderation?.cause || '');
  const [comment, setComment] = useState(shiftResult.comment || '');
  const [showComment, setShowComment] = useState(false);
  const isMarks = marks && marks.length > 0;

  const durationShift = time && time.getHours() * 60 + time.getMinutes();

  const moderationStatus = moderationReport?.getModerationReport.report.status;
  const sendStatus = moderationStatus === ModerationStatus.Sent;

  const timeFn = (duration: number) => durationFn(Number(Math.round(duration)));

  const duration = useMemo(() => {
    return moderation ? (durationFact ? timeFn(durationFact) : timeFn(durationPlan)) : timeFn(durationPlan);
  }, [shiftResult]); //eslint-disable-line

  const unitSymbol = unitSymbolFn(unit);

  const radioHandler = useCallback(
    (value: string) => {
      setToPayment(Boolean(value));
    },
    [setToPayment]
  );

  const [chageShift] = useChangeShiftModerationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [moderationReportConfigFn(moderationReport?.getModerationReport.report.id)],
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const send = () => {
        chageShift({
          variables: {
            moderation: {
              cause: reason,
              comment: comment,
              shiftId: shift.id,
              duration: durationShift || Number(shift.durationPlan),
              toPayment,
              hourRate: shiftResult.hourRate || 0,
              value: parseFloat(value),
            },
          },
        })
          .then(e => {
            if (e.data?.changeShiftModeration) {
              successToast('Смена успешно изменена');
            }
          })
          .catch(e => errorToast(errorHandler(e)));
      };

      const changedPayment = moderation?.toPayment !== toPayment;
      const changedShiftDuration = moderation?.duration !== durationShift;

      const returnErrorReason = () => {
        if (reason === '') {
          return infoToast('Причина редактирования - обязательное поле');
        }
        if (comment === '') {
          return infoToast('Коментарий - обязательное поле');
        } else {
          send();
        }
      };

      if (stage === ShiftStage.Finished) {
        // Хороший чекин/чекаут
        if (moderation === null) {
          // Смена не модерировалась
          if (!toPayment || Math.round(Number(shift.durationFact)) !== Number(durationShift)) {
            return returnErrorReason();
          }
          send();
        } else {
          // смена модерировалась
          if (changedPayment || changedShiftDuration) {
            return returnErrorReason();
          }
          send();
        }
      } else {
        // смена в другом статусе
        if (changedPayment || changedShiftDuration) {
          return returnErrorReason();
        }
        send();
      }
    },
    [reason, comment, durationShift, toPayment, value] //eslint-disable-line
  );

  const checkins = useMemo(() => {
    return marks?.filter(mark => mark.type === 'CHECKIN').filter(mark => mark.time);
  }, [marks]);

  const checkouts = useMemo(() => {
    return marks?.filter(mark => mark.type === 'CHECKOUT').filter(mark => Number(mark.time));
  }, [marks]);

  const message = (message?: string) => {
    if (message) {
      return 'text-smena_red-dark';
    } else {
      return 'text-smena_text-secondary';
    }
  };

  useEffect(() => {
    setTime(
      dayjs()
        .set('hour', Number(timeObject2(duration).hours))
        .set('minutes', Number(timeObject2(duration).minutes))
        .toDate()
    );
  }, [duration]);

  useEffect(() => {
    if (moderation) {
      if (moderation?.toPayment !== null && moderation?.toPayment !== undefined) {
        setToPayment(moderation?.toPayment);
      }
    }
  }, [moderation]);

  return (
    <form className={`border-b border-smena_gray-30 ${show ? 'pb-5' : ''}`} onSubmit={handleSubmit}>
      <div
        className="flex justify-between py-5"
        onClick={() => {
          setShow(!show);
        }}
      >
        <ShiftTitle shift={shift} duration={duration} />
        <div>
          <span className={`inline-flex transform transition-transform ${show && 'rotate-180'}`}>
            <SelectArrow className="text-smena_text" />
          </span>
        </div>
      </div>
      {show && (
        <div className="flex flex-col gap-y-2">
          <ShiftDateStartEnd shift={shift} />
          <div className="sidebar__shift grid-cols-2 gap-y-4">
            {moderation ? (
              <div className="col-span-full flex gap-x-1 Subtitle2">
                <span className="text-smena_text">Факт:</span>
                <span className="text-smena_text-secondary">
                  {unit === UnitType.Hour
                    ? `${timeObject2(timeFn(durationFact)).hours}:${timeObject2(timeFn(durationFact)).minutes}`
                    : moderation.value}
                  {unitSymbol}
                </span>
              </div>
            ) : null}

            <div className="col-span-1">
              <div className="flex gap-x-2">
                <div className="flex flex-col">
                  <span className="Subtitle2 text-smena_text">Чекин</span>
                  <span
                    className={`Table-small ${message(
                      checkins && checkins?.length > 0 ? checkins[0].statusMessage : 'empty'
                    )}`}
                  >
                    {checkins && checkins?.length > 0 ? dayjs(Number(checkins[0].time)).format('HH:mm') : '—:—'}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="Subtitle2 text-smena_text">Расстояние</span>
                  <span
                    className={`Table-small ${message(
                      checkins && checkins?.length > 0 ? checkins[0].statusMessage : 'empty'
                    )}`}
                  >
                    {checkins && checkins?.length > 0 ? getDistance(Number(checkins[0].distance)) : '—'}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="flex gap-x-2">
                <div className="flex flex-col">
                  <span className="Subtitle2 text-smena_text">Чекаут</span>
                  <span
                    className={`Table-small ${message(
                      checkouts && checkouts?.length > 0 ? checkouts[0].statusMessage : 'empty'
                    )}`}
                  >
                    {checkouts && checkouts?.length > 0
                      ? dayjs(Number(checkouts[checkouts.length - 1].time)).format('HH:mm')
                      : '—:—'}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="Subtitle2 text-smena_text">Расстояние</span>
                  <span
                    className={`Table-small ${message(
                      checkouts && checkouts?.length > 0 ? checkouts[checkouts.length - 1].statusMessage : 'empty'
                    )}`}
                  >
                    {checkouts && checkouts?.length > 0
                      ? getDistance(Number(checkouts[checkouts.length - 1].distance))
                      : '—'}
                  </span>
                </div>
              </div>
            </div>
            {isMarks && marks.length > 2 && (
              <>
                <div className="col-span-2 gap-x-2 cursor-pointer my-3">
                  <div
                    className="flex items-center"
                    onClick={() => {
                      setShowCheckins(value => !value);
                    }}
                  >
                    <span className="Caption-numbers text-primary">
                      {showCheckins ? 'Скрыть детали' : 'Раскрыть детали'}
                    </span>
                    <span className={`transform transition-transform ${showCheckins && 'rotate-180'}`}>
                      <SelectArrow className="text-primary" />
                    </span>
                  </div>
                </div>
                {showCheckins &&
                  marks.length > 2 &&
                  marks.map(mark => (
                    <div
                      className="col-span-1 flex gap-x-2 border-b border-smena_gray-5"
                      style={{ paddingBottom: '2px' }}
                      key={nanoid()}
                    >
                      <div className="flex gap-x-2">
                        <div className="flex flex-col">
                          <span className="Subtitle2 text-smena_text">
                            {mark.type === 'CHECKOUT' ? 'Чекаут ' : 'Чекин'}
                          </span>
                          <span className={`Table-small ${message(mark.statusMessage)}`}>
                            {dayjs(Number(mark.time)).format('HH:mm')}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="Subtitle2 text-smena_text">Расстояние</span>
                          <span className={`Table-small ${message(mark.statusMessage)}`}>
                            {getDistance(Number(mark.distance))}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
          <div className="sidebar__shift grid-cols-2">
            <span className="Caption col-span-2">Фиксированная оплата</span>
            <div className="col-span-1 flex flex-col gap-y-2">
              <span className="Subtitle2">Оплата, ₽</span>
              <span className="Table-small text-smena_text-secondary">{cost}</span>
            </div>
            <SimpleInput
              divClassName="col-span-1"
              label="Ред. ставку"
              onChange={({ target: { value } }) => {
                if (/^\d+$/.test(value) || value === '') {
                  setValue(value);
                }
              }}
              value={value}
              regText
              disabled={sendStatus}
              type="text"
              name="hourRate"
            />
          </div>
          <ShiftAproveBlock
            toPayment={toPayment}
            sendStatus={sendStatus}
            radioHandler={radioHandler}
            comment={comment}
            setComment={setComment}
            showComment={showComment}
            setShowComment={setShowComment}
            reason={reason}
            setReason={setReason}
          />
          <div className="grid grid-cols-4 items-center">
            <div className="col-span-2 Caption text-smena_text-secondary flex gap-x-1">
              <span>Посл. изменения</span>
              <span>{dayjs(shiftResult.updatedAt).format(RU_DATE)}</span>
            </div>
            <div className="col-span-1 col-start-4 self-end">
              <button type="submit" className="btn-primary_small mt-6" disabled={sendStatus}>
                Сохранить
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};
