import { useCallback, useEffect, useMemo, useState } from 'react';
import GarbageArrow from '../../../../../../Assets/icons/GarbageArrow';
import CheckCircle from '../../../../../../Assets/icons/checkCircle';
import GarbageArrowless from '../../../../../../Assets/icons/GarbageArrowless';
import ModerationResolvePencil from '../../../../../../Assets/icons/ModerationResolvePencil';
import {
  ResolveReportUserModel,
  useChangeResolveReportUserMutation,
  useUserLazyQuery,
} from '../../../../../../generated/graphql';
import { useStore } from '../../../../../../store';
import { errorHandler } from '../../../../../../utils/errorHandler';
import { getUserFIO } from '../../../../../../utils/get/getUserFIO';
import { errorToast, successToast } from '../../../../../../utils/notify';
import SimpleSelect from '../../../../../ui/Select';

interface IRow {
  row: ResolveReportUserModel;
}

const Row: React.FC<IRow> = ({ row }) => {
  const { setResolveModerationDataElement, usersByFacilityAndPosition } =
    useStore();
  const [edit, setEdit] = useState(false);
  const [userId, setUserId] = useState('');
  const fio = row.fio?.split(' ');
  const [loadUser, { data: user }] = useUserLazyQuery();

  const userHandleChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
      setUserId(value);
    },
    []
  );

  const usersByFacilityAndPositionOptions = useMemo(() => {
    if (usersByFacilityAndPosition) {
      return usersByFacilityAndPosition.map(user => ({
        id: user.id,
        name: getUserFIO({
          lastname: user.lastname,
          name: user.firstname,
          patronymic: user.patronymic,
        }),
      }));
    }
  }, [usersByFacilityAndPosition]);

  const [changeResolveUser] = useChangeResolveReportUserMutation();

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (userId) {
        return changeResolveUser({
          variables: {
            input: { userId, id: row.id },
          },
        })
          .then(response => {
            if (response.data?.changeResolveReportUser) {
              setResolveModerationDataElement(
                response.data.changeResolveReportUser
              );
              successToast('Исполнители сопоставлены');
            }
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      setEdit(false);
    },
    [userId, changeResolveUser, row.id, setResolveModerationDataElement]
  );

  const removeHandler = useCallback(() => {
    changeResolveUser({
      variables: {
        input: { id: row.id, disable: !row.disable },
      },
    })
      .then(response => {
        if (response.data?.changeResolveReportUser) {
          setResolveModerationDataElement(
            response.data.changeResolveReportUser
          );
          successToast('Исполнитель удален');
        }
      })
      .catch(error => errorToast(errorHandler(error)));
  }, [changeResolveUser, row.id, row.disable, setResolveModerationDataElement]);

  useEffect(() => {
    if (row.userId) {
      loadUser({
        variables: {
          userId: row.userId,
        },
      });
    }
  }, [loadUser, row.userId]);

  useEffect(() => {
    if (user?.user.id) {
      setUserId(user.user.id);
    }
  }, [user?.user.id]);

  return (
    <div className="ozon__import-error_table import-error__row px-2 py-3">
      <div>
        <span className="Body2 text-smena_text">
          {fio
            ?.map((el: string, index: number) =>
              index > 0 ? el.charAt(0) + '.' : el
            )
            .join(' ')}
        </span>
      </div>
      <div>
        {edit ? (
          <form className="flex items-center gap-x-2" onSubmit={handleSubmit}>
            <SimpleSelect
              divClassName=""
              label=""
              onChange={userHandleChange}
              value={userId}
              name="users"
              options={usersByFacilityAndPositionOptions}
              disabledOption="Поиск исполнителя"
            />
            <button className="justify-self-end" type="submit">
              <CheckCircle className="#1B984D" />
            </button>
          </form>
        ) : user?.user ? (
          <span className="Body2 text-smena_text">
            {getUserFIO({
              lastname: user?.user.lastname,
              name: user?.user.firstname,
              patronymic: user?.user.patronymic,
            })}
          </span>
        ) : (
          <span className="Table-small text-smena_text-helper">
            {row.disable ? 'Нет в отчете' : 'Нет на объекте'}
          </span>
        )}
      </div>
      <div className="flex gap-x-2">
        <button disabled={edit} onClick={() => setEdit(value => !value)}>
          <ModerationResolvePencil
            className={edit ? 'text-smena_text-disabled' : ''}
          />
        </button>
        <button onClick={removeHandler}>
          {row.disable ? <GarbageArrowless /> : <GarbageArrow />}
        </button>
      </div>
    </div>
  );
};

export default Row;
