const Edit = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.098 4.66947C16.9907 3.77684 18.4379 3.77684 19.3305 4.66947C20.2232 5.5621 20.2232 7.00933 19.3305 7.90196L18.4244 8.80812L15.1919 5.57563L16.098 4.66947Z"
        fill="#393B55"
      />
      <path
        d="M13.5756 7.19188L4 16.7675V20H7.23248L16.8081 10.4244L13.5756 7.19188Z"
        fill="#393B55"
      />
    </svg>
  );
};

export default Edit;
