import { useState } from 'react';
import { toast } from 'react-toastify';
import { Dayjs } from 'dayjs';

import { useSmsReportMutation } from '../../generated/graphql';
import { errorHandler } from '../../utils/errorHandler';

import { errorToast } from '../../utils/notify';
import TimesheetLoader from '../../components/Timesheets/Loader';
import DatePickerInput from '../../components/ui/Pickers/DatePickerInput';

const SmsReport = () => {
  const [value, setValue] = useState<Dayjs | null>(null);
  const [load, setLoad] = useState(false);
  const [resLink, setResLink] = useState('');
  const [smsReport] = useSmsReportMutation();
  return (
    <div className="xs:col-span-4 col-span-full xs:col-start-1 h-fit mt-6 pb-5 px-6 gap-y-5 border rounded-lg shadow-smena bg-smena_white flex flex-col">
      <span className="Subtitle1 text-smena_text-secondary pt-5">Отправленные СМС</span>
      <form
        className="flex xs:flex-row flex-col xs:items-end gap-x-5 flex-wrap"
        onSubmit={e => {
          e.preventDefault();
          toast.info('Создаем отчет...', {
            theme: 'colored',
            type: 'info',
            toastId: 'infoToast',
          });
          setLoad(true);
          smsReport({ variables: {} })
            .then(response => {
              if (response.data?.smsReport) {
                setLoad(false);
                const filePath = response.data.smsReport.filePath;
                const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
                setResLink(url);
                toast.dismiss('infoToast');
              }
            })
            .catch(e => {
              setLoad(false);
              errorToast(errorHandler(e));
              toast.dismiss('infoToast');
              setTimeout(() => {
                errorToast(errorHandler(e));
              }, 1000);
            });
        }}
      >
        <DatePickerInput
          // check
          divClassName="crudUserInput inputHeight"
          label="Период"
          value={value}
          disabled
          placeholder="За весь период"
          onChange={selectedDate => {
            setValue(selectedDate);
            setResLink('');
          }}
        />
        <div className="paddingForSelects">
          <button type="submit" disabled={load || Boolean(resLink)} className="btn-secondary_big">
            Сформировать
          </button>
        </div>
      </form>
      <TimesheetLoader load={load} resLink={resLink} />
    </div>
  );
};

export default SmsReport;
