import {
  FacilityCasingDocument,
  FacilityCasingQueryVariables,
} from '../../generated/graphql';

interface IFacilitiesCasingQueryConfig {
  query: typeof FacilityCasingDocument;
  variables: FacilityCasingQueryVariables;
}

export const FacilitiesCasingQueryConfigFn = (
  facilityId: string
): IFacilitiesCasingQueryConfig => ({
  query: FacilityCasingDocument,
  variables: {
    facilityId,
  },
});
