import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

import { useStore } from '../../../store';
import { sortObj } from '../../../utils/sortObject';
import Across from '../../../Assets/icons/Across';
import Magnifier from '../../../Assets/icons/magnifier';
import Plus from '../../../Assets/icons/plus';
import { getActive } from '../../../utils/get/getActive';
import Filter from './Filter';
import { cityName, groupName } from '../../../utils/filters/filtersName';
import { roles } from '../../../utils/constVariables';
import OutsideClick from '../../../hooks/useOutsideClick';

interface IFacilityFilter {
  cityId: string;
  isActive: string | boolean;
  groupId: string;
  returnData: () => void;
  setPage: (param: number) => void;
  setGroupId: (param: string) => void;
  setCityId: (param: string) => void;
  setIsActive: (param: string | boolean) => void;
}

const FacilitiesFilter: React.FC<IFacilityFilter> = ({
  returnData,
  setPage,
  groupId,
  setGroupId,
  cityId,
  setCityId,
  isActive,
  setIsActive,
}) => {
  const [filter, setFilter] = useState(false);
  const [params, setParams] = useSearchParams();

  const { groups, cities, me } = useStore();

  let paramsCount = 0;

  let groupParam = params.get('groupId');
  let cityParam = params.get('cityId');
  let activeParam = params.get('active');

  const paramCounter = (name: string) => {
    if (params.has(name)) {
      return paramsCount++;
    }
  };

  paramCounter('groupId');
  paramCounter('cityId');
  paramCounter('active');

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      params.set('search', e.target.value);
      setParams(params);
    } else {
      params.delete('search');
      setParams(params);
    }
    setPage(1);
  };

  const debounceOnChange = debounce(handleChangeSearch, 600);

  const removeParam = (param: string) => {
    if (params.has(param)) {
      params.delete(param);
    } else {
      return;
    }
  };

  const addParams = (paramName: string, param: string) => {
    params.set(paramName, param);
  };

  const canCreate = me && [roles.Admin, roles.Supervisor].includes(me.role);

  return (
    <div className="filter-bar flex xs:flex-row flex-col gap-y-2 justify-between">
      <div className="flex flex-col">
        <div className={`flex relative ${paramsCount ? 'mb-2' : ''}`}>
          <button
            type="button"
            className="btn-stroke mr-2 flex gap-x-1 items-center"
            onClick={() => {
              setFilter(value => !value);
            }}
          >
            Фильтр
            {paramsCount ? (
              <span
                className="Button3 text-primary rounded-full bg-primary bg-opacity-20"
                style={{
                  padding: '0px 7.5px',
                }}
              >
                {String(paramsCount)}
              </span>
            ) : null}
          </button>
          <div className="relative w-64">
            <span className="absolute top-0 left-0 transform translate-x-2/4 translate-y-2/4">
              <Magnifier />
            </span>
            <input
              className="input-primary pl-8"
              onChange={debounceOnChange}
              type="search"
              name="search"
              id="search"
              placeholder="Поиск"
            />
          </div>
          {filter && (
            <OutsideClick setShow={setFilter} show={filter}>
              <div className="filter__popup">
                <span
                  className="absolute top-0 right-0 cursor-pointer"
                  style={{ padding: '15px 15px 0 0' }}
                  onClick={() => {
                    setFilter(value => !value);
                  }}
                >
                  <Across color={'text-smena_gray-90'} />
                </span>
                <span className="H4 text-smena_text mb-3 inline-block">Фильтры</span>

                <div className="mb-3">
                  <label htmlFor="city" className="label-primary">
                    Группа
                  </label>
                  <select
                    name="city"
                    id="city"
                    className="select-primary"
                    value={groupId}
                    onChange={({ target: { value } }) => {
                      setGroupId(value);
                    }}
                  >
                    <option value="">Не выбрано</option>
                    {groups &&
                      sortObj(groups).map(group => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="city" className="label-primary">
                    Город
                  </label>
                  <select
                    name="city"
                    id="city"
                    className="select-primary"
                    value={cityId}
                    onChange={({ target: { value } }) => {
                      setCityId(value);
                    }}
                  >
                    <option value="">Не выбрано</option>
                    {cities &&
                      sortObj(cities).map(city => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="isActive" className="label-primary">
                    Статус активности
                  </label>
                  <select
                    name="isActive"
                    id="isActive"
                    className="select-primary mt-1"
                    value={String(isActive)}
                    onChange={({ target: { value } }) => {
                      setIsActive('');
                      if (value === 'true') {
                        setIsActive(true);
                      }
                      if (value === 'false') {
                        setIsActive(false);
                      }
                    }}
                  >
                    <option value="">Не выбрано</option>
                    <option value="true">Активный</option>
                    <option value="false">Не активный</option>
                  </select>
                </div>
                <div className="flex">
                  <button
                    className="btn-primary mr-5"
                    onClick={() => {
                      if (groupId === '') {
                        removeParam('groupId');
                      } else {
                        addParams('groupId', groupId);
                      }
                      if (cityId === '') {
                        removeParam('cityId');
                      } else {
                        addParams('cityId', cityId);
                      }
                      if (isActive === '') {
                        removeParam('active');
                      } else {
                        addParams('active', String(isActive));
                      }
                      setParams(params);
                      setFilter(false);
                      returnData();
                    }}
                  >
                    <span>Применить</span>
                  </button>
                  <button
                    className="btn-stroke xs:w-auto w-full"
                    onClick={() => {
                      setGroupId('');
                      setCityId('');
                      setIsActive('');
                      params.delete('groupId');
                      params.delete('active');
                      params.delete('cityId');
                      params.delete('search');
                      setParams(params);
                      setFilter(false);
                    }}
                  >
                    Сбросить
                  </button>
                </div>
              </div>
            </OutsideClick>
          )}
        </div>

        <div className="flex">
          {groupParam && (
            <Filter
              content={'Группа ' + groupName(groups, groupParam)}
              clickHandler={() => {
                setGroupId('');
                params.delete('groupId');
                setParams(params);
                setPage(1);
              }}
            />
          )}

          {cityParam && (
            <Filter
              content={'Город ' + cityName(cities, cityParam)}
              clickHandler={() => {
                setCityId('');
                params.delete('cityId');
                setParams(params);
                setPage(1);
              }}
            />
          )}

          {activeParam && (
            <Filter
              content={getActive(activeParam)}
              clickHandler={() => {
                setIsActive('');
                params.delete('active');
                setParams(params);
                setPage(1);
              }}
            />
          )}
        </div>
      </div>
      {canCreate && (
        <div className="flex self-start">
          <Link to="/facilities/create">
            <button type="button" className="btn-primary_big" disabled={!canCreate}>
              <span className="flex items-center">
                <span className="inline-flex mr-2">
                  <Plus className="text-smena_white" />
                </span>

                <span>Новый объект</span>
              </span>
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default FacilitiesFilter;
