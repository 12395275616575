export const getPeriod = (period: string) => {
  const periodList = [
    {
      period: '',
      periodRu: 'Не указан',
    },
    {
      period: 'NIGHT',
      periodRu: 'Ночь',
    },
    {
      period: 'DAY',
      periodRu: 'День',
    },
  ];

  return periodList.find(item => period === item.period)?.periodRu;
};
