import { Routes, Route } from 'react-router-dom';
import dayjs from 'dayjs';

import Offer from './helpfull/Offer';
import { Alfa } from './helpfull/Alfa';
import { MoiNalog } from './helpfull/MoiNalog';
import { Pamyatka } from './helpfull/Pamyatka';
import MapPage from './map';
import PrivacyPolicyPage from './privacyPolicy';
import MapTwoPage from './mapTwo';
import NotFound from './404';
import WrongWay from './wrongWay';

import Home from '.';
import UserPage from './users';
import User from './users/index';
import { DocumentsPage } from './users/user/documents';
import { QuestionnairePage } from './users/user/questionnaire';
import UserShifts from './users/user/shifts';
import UserSchedule from './users/user/schedule';

import Facilities from './facilities';
import UpdateFacility from './facilities/index';
import AddProfession from './facilities/add-profession';
import FacilityVacancies from './facilities/vacancies';

import Groups from './groups';
import UpdateGroup from './groups/index';
import FacilitiesGroupPage from './groups/facilities';

import Vacancies from './vacancies';
import Vacancy from './vacancies/index';

import Positions from './positions';
import UpdatePositions from './position/index';

import Payments from './payments';

import Timesheet from './timesheet';

import Leads from './leads';
import EditLead from './refferals';

import ModerationsReportsPage from './moderationsReport';
import CreateRepot from './reports/create';
import { UpdateModerationReport } from './reports/report';

import Requests from './requests';

import EditRequest from './request/edit';
import UpdateRequest from './request';
import FacilityRequestsPage from './facilities/requests';
import GraphicPage from './facilities/schedule';
import { roles } from '../utils/constVariables';
import ProtectedRoutes from '../Routes/Protected';
import Login from './login';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

const App = () => {
  dayjs.locale('ru');
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="wrong-way" element={<WrongWay />} />
      <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="offer" element={<Offer />} />
      <Route path="alfa-bank" element={<Alfa />} />
      <Route path="moi-nalog" element={<MoiNalog />} />
      <Route path="pamyatka" element={<Pamyatka />} />
      <Route path="login" element={<Login />} />
      <Route path="map" element={<MapPage />} />
      <Route path="map-two" element={<MapTwoPage />} />
      <Route
        element={
          <ProtectedRoutes allowedRoles={[roles.Admin, roles.Supervisor, roles.ClientDirector, roles.ClientManager]} />
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="timesheet" element={<Timesheet />} />
        <Route path="users">
          <Route index element={<UserPage />} />
          <Route path=":userId">
            <Route index element={<User />} />
            <Route path="documents" element={<DocumentsPage />} />
            <Route path="questionnaire" element={<QuestionnairePage />} />
            <Route path="shifts" element={<UserShifts />} />
            <Route path="schedule" element={<UserSchedule />} />
          </Route>
        </Route>
        <Route path="requests">
          <Route index element={<Requests />} />
          <Route path="create" element={<UpdateRequest />} />
          <Route path=":requestId/edit" element={<UpdateRequest />} />
          <Route path=":requestId" element={<EditRequest />} />
        </Route>
        <Route path="facilities">
          <Route index element={<Facilities />} />
          <Route path=":facilityId">
            <Route index element={<UpdateFacility />} />
            <Route path="requests">
              <Route index element={<FacilityRequestsPage />} />
              <Route path=":requestId" element={<EditRequest />} />
              <Route path="create" element={<UpdateRequest />} />
              <Route path=":requestId/edit" element={<UpdateRequest />} />
            </Route>
            <Route path="schedule">
              <Route index element={<GraphicPage />} />
            </Route>
            <Route path="add-profession" element={<AddProfession />} />
          </Route>
        </Route>
      </Route>

      <Route element={<ProtectedRoutes allowedRoles={[roles.Admin, roles.Supervisor]} />}>
        <Route path="users">
          <Route index element={<UserPage />} />
          <Route path="create" element={<DocumentsPage />} />
        </Route>
        <Route path="moderations-period">
          <Route index element={<ModerationsReportsPage />} />
          <Route path="create" element={<CreateRepot />} />
          <Route path=":periodId" element={<UpdateModerationReport />} />
        </Route>

        <Route path="payments" element={<Payments />} />
      </Route>

      <Route path="facilities">
        <Route element={<ProtectedRoutes allowedRoles={[roles.Admin]} />}>
          <Route path="create" element={<UpdateFacility />} />
        </Route>
        <Route path=":facilityId">
          <Route element={<ProtectedRoutes allowedRoles={[roles.Admin]} />}>
            <Route path="vacancies" element={<FacilityVacancies />} />
          </Route>
        </Route>
      </Route>

      <Route element={<ProtectedRoutes allowedRoles={[roles.Admin]} />}>
        <Route path="groups">
          <Route index element={<Groups />} />
          <Route path="create" element={<UpdateGroup />} />
          <Route path=":groupId">
            <Route index element={<UpdateGroup />} />
            <Route path="facilities" element={<FacilitiesGroupPage />} />
          </Route>
        </Route>
        <Route path="vacancies">
          <Route index element={<Vacancies />} />
          <Route path="create" element={<Vacancy />} />
          <Route path=":vacancyId" element={<Vacancy />} />
        </Route>

        <Route path="positions">
          <Route index element={<Positions />} />
          <Route path="create" element={<UpdatePositions />} />
          <Route path=":positionId" element={<UpdatePositions />} />
        </Route>

        <Route path="leads">
          <Route index element={<Leads />} />
          <Route path=":leadId" element={<EditLead />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
