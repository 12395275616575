import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store';

const PaymentCheckbox = ({ userId }: { userId?: string }) => {
  const {
    manualAccural,
    paymentCheckbox,
    manualAccuralUserIds,
    setManualAccuralUserIds,
    paymentCheckboxes,
    setPaymentCheckboxes,
  } = useStore();
  const [checked, setChecked] = useState(true);

  const disabledAccural = manualAccural?.manualUserAccruals?.find(
    accural => accural.userId === userId
  );

  useEffect(() => {
    if (disabledAccural) {
      const remove = manualAccuralUserIds.filter(
        id => id !== disabledAccural.userId
      );
      setManualAccuralUserIds([...remove]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setChecked(paymentCheckbox);
  }, [paymentCheckbox]);

  return (
    <input
      type="checkbox"
      name="paymentCheckbox"
      disabled={disabledAccural ? true : false}
      className={clsx(
        'mx-4 my-2 checkbox-primary',
        disabledAccural && 'cursor-not-allowed bg-smena_gray-80'
      )}
      checked={disabledAccural ? true : checked}
      onChange={({ target: { checked } }) => {
        setChecked(checked);
        if (checked) {
          setPaymentCheckboxes(paymentCheckboxes + 1);
          if (userId) {
            setManualAccuralUserIds([...manualAccuralUserIds, userId]);
          }
        } else {
          const remove = manualAccuralUserIds.filter(id => id !== userId);
          setManualAccuralUserIds([...remove]);
          setPaymentCheckboxes(paymentCheckboxes - 1);
        }
      }}
    />
  );
};

export default PaymentCheckbox;
