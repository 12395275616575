import {
  FacilityDocument,
  FacilityQueryVariables,
} from '../../generated/graphql';

interface IFacilityConfig {
  query: typeof FacilityDocument;
  variables: FacilityQueryVariables;
}

export const facilityConfigFn = (id: string): IFacilityConfig => ({
  query: FacilityDocument,
  variables: {
    id,
  },
});
