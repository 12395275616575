export const calendarHasProbationDays = [
  { id: 1, name: '1 день' },
  { id: 2, name: '2 дня' },
  { id: 5, name: '5 дней' },
  { id: 6, name: '6 дней' },
  { id: 7, name: '7 дней' },
  { id: 8, name: '8 дней' },
  { id: 9, name: '9 дней' },
  { id: 10, name: '10 дней' },
  { id: 14, name: '14 дней' },
  { id: 30, name: '30 дней' },
];
