import { debounce } from 'lodash';
import { useCallback } from 'react';
import SimpleInput from 'src/components/ui/Input';
import SimpleSelect from 'src/components/ui/Select';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';
import { cityName } from 'src/utils/filters/filtersName';

interface IMainInfoFacility {
  values: any;
  touched: any;
  errors: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setAddress: React.Dispatch<
    React.SetStateAction<{
      city: string;
      address: string;
    }>
  >;
}

const MainInfoFacility: React.FC<IMainInfoFacility> = ({
  values,
  touched,
  errors,
  handleChange,
  setAddress,
}) => {
  const { groups, cities } = useStore();
  const handleChangeAddress = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(fullAddress => ({ ...fullAddress, address: value }));
  };

  //eslint-disable-next-line
  const debounceOnChangeAddress = useCallback(
    debounce(handleChangeAddress, 1000),
    []
  );

  return (
    <div className="facilityPageBlock blockTemplate">
      <div className="grid grid-cols-8 gap-x-5 gap-y-7">
        <span className="inline-block Subtitle1 text-smena_text-secondary col-span-full">
          Основная информация
        </span>
        <SimpleInput
          divClassName="sm:col-span-3 col-span-4"
          label="Название объекта"
          placeholder="Введите название объекта"
          onChange={handleChange}
          value={values.name}
          type="text"
          name="name"
          validation={true}
          showTextRole={[roles.ClientDirector, roles.ClientManager]}
          error={touched.name && errors.name ? errors.name : ''}
        />
        <SimpleSelect
          divClassName="sm:col-span-2 col-span-4"
          label="Группа"
          onChange={handleChange}
          value={values.group}
          name="group"
          autoComplete="group"
          disabledOption="Группа"
          options={groups}
          validation={true}
          showTextRole={[roles.ClientDirector, roles.ClientManager]}
          error={touched.group && errors.group ? errors.group : ''}
        />
        <SimpleSelect
          divClassName="sm:col-span-3 col-span-4"
          label="Город"
          onChange={e => {
            handleChange(e);
            setAddress(fullAddress => ({
              ...fullAddress,
              city: String(cityName(cities, e.target.value)),
            }));
          }}
          value={values.cityId}
          name="cityId"
          autoComplete="cityId"
          disabledOption="Город"
          options={cities}
          validation={true}
          showTextRole={[roles.ClientDirector, roles.ClientManager]}
          error={touched.cityId && errors.cityId ? errors.cityId : ''}
        />
        <SimpleInput
          divClassName="col-span-4"
          placeholder="Введите адрес"
          label="Адрес"
          onChange={e => {
            handleChange(e);
            debounceOnChangeAddress(e);
          }}
          value={values.address}
          type="text"
          name="address"
          validation={true}
          showTextRole={[roles.ClientDirector, roles.ClientManager]}
          error={touched.address && errors.address ? errors.address : ''}
        />
        <SimpleInput
          divClassName="sm:col-span-2 col-span-4"
          label="ERP ID"
          placeholder="Введите ERP ID"
          required={false}
          onChange={handleChange}
          value={values.externalId}
          regText={true}
          type="text"
          name="externalId"
          showTextRole={[roles.ClientDirector, roles.ClientManager]}
        />
        <SimpleInput
          divClassName="sm:col-span-6 col-span-4"
          placeholder="Станция метро"
          label="Станция метро"
          onChange={handleChange}
          value={values.metro}
          type="text"
          name="metro"
          validation={true}
          showTextRole={[roles.ClientDirector, roles.ClientManager]}
          error={touched.metro && errors.metro ? errors.metro : ''}
        />
      </div>
    </div>
  );
};

export default MainInfoFacility;
