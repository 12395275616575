import React from 'react';
import { Link } from 'react-router-dom';

interface IManager {
  role: string;
  arrayOf?:
    | {
        id: string;
        firstname: string;
        lastname: string;
        patronymic?: string | null;
      }[]
    | null;
}

const Manager: React.FC<IManager> = ({ role, arrayOf }) => {
  return (
    <div className="row-span-1 xs:flex xs:flex-col gap-y-2">
      <span className="Subtitle2 text-smena_text mr-2">{role}</span>
      <div className="flex flex-col gap-y-2">
        {arrayOf && arrayOf.length > 0 ? (
          arrayOf.map(manager => {
            const { id, firstname, lastname, patronymic } = manager;
            return (
              <Link target="_blank" to={`/users/${id}`} className="Body2 text-primary" key={id}>
                {`${lastname} ${firstname} ${patronymic || ''}`}
              </Link>
            );
          })
        ) : (
          <span className="Subtitle2 text-smena_text-secondary">Не назначен</span>
        )}
      </div>
    </div>
  );
};

export default Manager;
