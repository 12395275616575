import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import SimpleInput from 'src/components/ui/Input';

import {
  RegularUserRatingFragment,
  useChangeQuestionnaireExtendedMutation,
} from '../../../generated/graphql';

import { useStore } from '../../../store';

import { roles } from '../../../utils/constVariables';
import { errorHandler } from '../../../utils/errorHandler';
import { userQueryConfigFn } from '../../../utils/graphqlConfigs/userQueryConfig';
import { genders } from '../../../utils/lists/gendersList';
import {
  FOOT_FEMALE_SIZE,
  FOOT_MALE_SIZE,
} from '../../../utils/lists/shoeSizeList';
import {
  CLOTHING_FEMALE_SIZE,
  CLOTHING_MALE_SIZE,
} from '../../../utils/lists/сlothingSizeList';
import { errorToast, successToast } from '../../../utils/notify';

import MySelect from '../../ui/Formik/Select';

interface IQuestionnaire {
  user?: RegularUserRatingFragment;
}

const Questionnaire: React.FC<IQuestionnaire> = ({ user }) => {
  const { me } = useStore();
  const router = useParams();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const questionnaireUser = user?.questionnaireTable;

  const adminSuper = me && [roles.Admin, roles.Supervisor].includes(me.role);

  const userQueryConfig = userQueryConfigFn(userId);

  const [updateQuestionnaire] = useChangeQuestionnaireExtendedMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        sex: questionnaireUser?.sex || '',
        growth: questionnaireUser?.growth
          ? String(questionnaireUser?.growth)
          : '',
        clothingSize: questionnaireUser?.clothingSize || '',
        footSize: questionnaireUser?.footSize || '',
      }}
      validate={values => {
        const errors: any = {};
        if (!values.sex) {
          errors.sex = 'Обязательное поле';
        }
        if (values.growth === '0') {
          errors.growth = 'Обязательное поле';
        }
        if (Number(values.growth) < 140 || Number(values.growth) > 220) {
          errors.growth = 'Введите корректный рост';
        }
        if (!values.clothingSize) {
          errors.clothingSize = 'Обязательное поле';
        }
        if (!values.footSize) {
          errors.footSize = 'Обязательное поле';
        }
        return errors;
      }}
      onSubmit={async values => {
        await updateQuestionnaire({
          variables: {
            workerId: userId,
            questionnaire: {
              sex: values.sex,
              growth: Number(values.growth),
              clothingSize: values.clothingSize,
              footSize: Number(values.footSize),
            },
          },
        })
          .then(e => {
            if (e.data?.changeQuestionnaireExtended.userId) {
              return successToast('Анкета обновлена');
            }
          })
          .catch(e => {
            return errorToast(errorHandler(e));
          });
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        isSubmitting,
        setFieldValue,
      }) => {
        const changeWithResetValues = (e: React.ChangeEvent) => {
          handleChange(e);
          setFieldValue('clothingSize', '');
          setFieldValue('footSize', '');
        };
        const mapArr = (arr: { id: string; name: string }[]) =>
          arr.map(el => (
            <option key={el.id} value={el.id}>
              {el.name}
            </option>
          ));

        return (
          <Form className="userPageBlock blockTemplate">
            <span className="blockTitleTemplate">Анкета</span>

            <div
              className={clsx(
                adminSuper ? 'flex flex-wrap' : 'grid grid-cols-2',
                'gap-x-6'
              )}
            >
              {adminSuper ? (
                <MySelect
                  label="Пол"
                  name="sex"
                  classname="crudUserInput"
                  onChange={changeWithResetValues}
                >
                  <option value="" disabled>
                    Выберите пол
                  </option>
                  {genders.map(gender => (
                    <option key={gender.id} value={gender.id}>
                      {gender.name}
                    </option>
                  ))}
                </MySelect>
              ) : (
                <div className="flex justify-between items-center flex-wrap">
                  <label className="Tag text-smena_text-secondary">Пол</label>
                  <span className="Body1 w-[251px]">
                    {genders.find(gender => gender.id === values.sex)?.name}
                  </span>
                </div>
              )}
              {adminSuper ? (
                <SimpleInput
                  divClassName="crudUserInput"
                  type="text"
                  regText
                  value={values.growth}
                  label="Рост (см)"
                  name="growth"
                  onChange={handleChange}
                  placeholder="165"
                  error={touched.growth && errors.growth ? errors.growth : ''}
                />
              ) : (
                <div className="flex justify-between items-center flex-wrap">
                  <label className="Tag text-smena_text-secondary">
                    Рост (см)
                  </label>
                  <span className="Body1 w-[251px]">{values.growth}</span>
                </div>
              )}
              {adminSuper ? (
                <MySelect
                  label="Размер одежды"
                  name="clothingSize"
                  classname="crudUserInput"
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Выберите размер одежды
                  </option>
                  {values.sex === 'male'
                    ? mapArr(CLOTHING_MALE_SIZE)
                    : mapArr(CLOTHING_FEMALE_SIZE)}
                </MySelect>
              ) : (
                <div className="flex justify-between items-center flex-wrap">
                  <label className="Tag text-smena_text-secondary">
                    Размер одежды
                  </label>
                  <span className="Body1 w-[251px]">
                    {values.sex === 'male'
                      ? CLOTHING_MALE_SIZE.find(
                          clothe => clothe.id === values.clothingSize
                        )?.name
                      : CLOTHING_FEMALE_SIZE.find(
                          clothe => clothe.id === values.clothingSize
                        )?.name}
                  </span>
                </div>
              )}
              {adminSuper ? (
                <MySelect
                  label="Размер обуви"
                  name="footSize"
                  classname="crudUserInput"
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Выберите размер обуви
                  </option>
                  {values.sex === 'male'
                    ? mapArr(FOOT_MALE_SIZE)
                    : mapArr(FOOT_FEMALE_SIZE)}
                </MySelect>
              ) : (
                <div className="flex justify-between items-center flex-wrap">
                  <label className="Tag text-smena_text-secondary">
                    Размер обуви
                  </label>
                  <span className="Body1 w-[251px]">{values.footSize}</span>
                </div>
              )}
            </div>
            {adminSuper && (
              <div className="lg:col-span-6 md:col-span-10 col-span-full xs:col-start-1 flex justify-end">
                <button
                  type="submit"
                  className="btn-primary_big"
                  disabled={isSubmitting}
                >
                  Сохранить
                </button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default Questionnaire;
