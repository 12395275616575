import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';
import Home from '../../../Assets/icons/Home';

const Breadcrumbs = ({
  links,
}: {
  links?: { path: string; name: string }[];
}) => {
  return (
    <div className="flex items-center breadcrumbs">
      <Link
        to="/"
        className="breadcrumb flex items-center Tag text-smena_text-secondary"
      >
        <Home />
      </Link>
      {links?.map((link, index) => {
        const isLast = links.length - 1 === index;
        if (isLast) {
          return (
            <span
              className="breadcrumb flex items-center Tag text-smena_text"
              key={nanoid()}
            >
              {link.name}
            </span>
          );
        }
        return (
          <Link
            to={link.path ? link.path : ''}
            className="breadcrumb flex items-center Tag text-smena_text-secondary"
            key={nanoid()}
          >
            {link.name}
          </Link>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
