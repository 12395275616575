import dayjs from 'dayjs';
import { IDifferenceBetweenDaysFn } from '../Interfaces/IDifferenceBetweenDays';

export const daysCount = ({ dateTo, dateFrom }: IDifferenceBetweenDaysFn) => {
  const days: dayjs.Dayjs[] = [];
  const diffDays = dayjs(dateTo).startOf('day').diff(dayjs(dateFrom).startOf('day'), 'days');

  for (let index = 0; index <= diffDays; index++) {
    days.push(dayjs(dateFrom).add(index, 'd').startOf('d'));
  }
  return days;
};
