import { useMemo } from 'react';
import { roles } from 'src/utils/constVariables';
import {
  RegularUserShiftsFragment,
  ShiftStage,
} from '../../../generated/graphql';
import { useStore } from '../../../store';
import { useMeRole } from '../../../hooks/useRole';

const useRefusedShift = ({
  userShifts,
}: {
  userShifts?: RegularUserShiftsFragment[];
}) => {
  const { facilityId, workpostId, superShifts, hiddenCanceledShifts } =
    useStore();

  const isMeSupervisor = useMeRole(roles.Supervisor)

  return useMemo(() => {
    const shifts = (hiddenCanceledShifts ? [] : isMeSupervisor ? superShifts : userShifts) || []
    return shifts.filter(shift => {
      const isStageFits = shift.stage === ShiftStage.Refused;
      const isPositionFits = workpostId ? shift.positionId === workpostId : true;
      const shiftFacilityId = shift.facility?.id;
      const isFacilityFits = facilityId ? shiftFacilityId === facilityId : true;
      return isStageFits && isPositionFits && isFacilityFits;
    })

  },[facilityId, hiddenCanceledShifts, isMeSupervisor, superShifts, userShifts, workpostId])
};

export default useRefusedShift;
