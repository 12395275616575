import { IUser } from 'src/Interfaces/IUser';

export const userLastnameAndInitials = (user?: IUser | null) => {
  if (user) {
    return `${user.lastname} ${user.firstname.charAt(0)}. ${
      user.patronymic ? user.patronymic.charAt(0) + '.' : ''
    }`;
  }
  return null;
};
