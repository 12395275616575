const TimesheetIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93138 3.90814C9.93138 3.17809 10.5232 2.58626 11.2533 2.58626H15.9557V4.90278C15.9557 5.92486 16.7842 6.75341 17.8063 6.75341H20.2421V14.2188C20.2421 14.9489 19.6502 15.5407 18.9202 15.5407V17.127C20.5263 17.127 21.8283 15.8249 21.8283 14.2188V5.96028C21.8283 5.74605 21.7417 5.54094 21.5881 5.3916L17.0708 1H11.2533C9.64714 1 8.34512 2.30202 8.34512 3.90814V4.43555H8.08051C6.47438 4.43555 5.17236 5.73757 5.17236 7.34369V7.87303H4.90814C3.30202 7.87303 2 9.17505 2 10.7812V21.0919C2 22.698 3.30202 24 4.90814 24H12.8394C14.4456 24 15.7476 22.698 15.7476 21.0919V20.2981H16.0118C17.6179 20.2981 18.9199 18.9961 18.9199 17.39V9.32007C18.9199 9.10029 18.8288 8.89036 18.6681 8.74037L14.0579 4.43555H9.93138V3.90814ZM15.7476 18.7119H16.0118C16.7419 18.7119 17.3337 18.1201 17.3337 17.39V10.1132H14.8027C13.7806 10.1132 12.952 9.28464 12.952 8.26256V6.02181H8.08051C7.35045 6.02181 6.75862 6.61363 6.75862 7.34369V7.87303H10.8897L15.501 12.2586C15.6585 12.4083 15.7476 12.6161 15.7476 12.8333V18.7119ZM19.0816 5.16715L17.5419 3.67036V4.90278C17.5419 5.04879 17.6603 5.16715 17.8063 5.16715H19.0816ZM16.1153 8.52694L14.5383 7.0544V8.26256C14.5383 8.40857 14.6567 8.52694 14.8027 8.52694H16.1153ZM3.58626 10.7812C3.58626 10.0511 4.17809 9.45929 4.90814 9.45929H9.77967V11.7758C9.77967 12.7979 10.6082 13.6264 11.6303 13.6264H14.1613V21.0919C14.1613 21.8219 13.5695 22.4137 12.8394 22.4137H4.90814C4.17809 22.4137 3.58626 21.8219 3.58626 21.0919V10.7812ZM12.9696 12.0402L11.3659 10.515V11.7758C11.3659 11.9218 11.4843 12.0402 11.6303 12.0402H12.9696Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.34521 17.655C4.34521 17.2933 4.63847 17 5.00021 17H12.5002C12.862 17 13.1552 17.2933 13.1552 17.655C13.1552 18.0167 12.862 18.31 12.5002 18.31H5.00021C4.63847 18.31 4.34521 18.0167 4.34521 17.655Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.34521 19.7761C4.34521 19.4143 4.63847 19.1211 5.00021 19.1211H12.5002C12.862 19.1211 13.1552 19.4143 13.1552 19.7761C13.1552 20.1378 12.862 20.4311 12.5002 20.4311H5.00021C4.63847 20.4311 4.34521 20.1378 4.34521 19.7761Z"
      />
    </svg>
  );
};

export default TimesheetIcon;
