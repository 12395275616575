import SimpleCheckbox from 'src/components/ui/Checkbox';

interface IFacilityAccess {
  values: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const FacilityAccess: React.FC<IFacilityAccess> = ({
  handleChange,
  values,
}) => {
  return (
    <div className="col-span-full xl:col-span-3 md:col-span-5">
      <div className="px-6 h-fit pb-10 border rounded-lg shadow-smena bg-smena_white">
        <span className="Subtitle1 text-smena_text-secondary py-5 inline-block col-span-full">
          Доступы
        </span>
        <div className="grid grid-rows-3 gap-y-2">
          <SimpleCheckbox
            divClassName="row-span-1 flex items-center"
            label="Активный объект"
            onChange={handleChange}
            checked={values.is_active}
            name="is_active"
          />
          <SimpleCheckbox
            divClassName="row-span-1 flex items-center"
            label="Отображать исполнителям выплаты"
            onChange={handleChange}
            checked={values.is_finance_active}
            name="is_finance_active"
          />
          <SimpleCheckbox
            divClassName="row-span-1 flex items-center"
            label="Отображать доход до модерации"
            onChange={handleChange}
            checked={values.showSalaryBeforeModeration}
            name="showSalaryBeforeModeration"
          />
          <SimpleCheckbox
            divClassName="row-span-1 flex items-center"
            label="Отображать исполнителям бонусы"
            onChange={handleChange}
            checked={values.is_bonus_active}
            name="is_bonus_active"
          />
        </div>
      </div>
    </div>
  );
};

export default FacilityAccess;
