import { Skeleton } from '@mui/material';
import moment from 'moment';
import { UserFullName } from 'src/utils/userFullName';
import { RegularManualUserAccuralFragment, useUserQuery } from '../../../generated/graphql';
import { RU_DATE } from '../../../utils/constVariables';

interface ManualUserAccrualUserInterface {
  user: { __typename?: 'ManualUserAccrual' } & RegularManualUserAccuralFragment;
}

const ManualUserAccrualUser = ({ user }: ManualUserAccrualUserInterface) => {
  const { data, loading } = useUserQuery({
    variables: { userId: user.userId },
  });

  const currentUser = data?.user;

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <div className="grid md:gap-3" style={{ gridTemplateColumns: '300px 150px 200px' }}>
          <div className="p-2 text-left text-sm font-medium">{UserFullName(currentUser)}</div>
          <div className="p-2 text-left text-sm font-medium uppercase tracking-wider">{user.summ}</div>
          <div className="p-2 text-left text-sm font-medium uppercase tracking-wider">
            {moment(user.createdAt).format(RU_DATE)}
          </div>
        </div>
      )}
    </>
  );
};

export default ManualUserAccrualUser;
