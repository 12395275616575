import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';

import {
  PayoutType,
  UnitType,
  useCreateCasingMutation,
  useFacilityCasingQuery,
  useFacilityQuery,
} from '../../../generated/graphql';

import MainLayout from '../../../components/Layout/main';
import SimpleInput from '../../../components/ui/Input';
import SimpleCheckbox from '../../../components/ui/Checkbox';
import SimpleSelect from '../../../components/ui/Select';

import { useStore } from '../../../store';

import { errorToast, successToast } from '../../../utils/notify';
import { payoutTypes } from '../../../utils/lists/payoutTypes';
import { hourlyUnit, units } from '../../../utils/lists/units';
import { errorHandler } from '../../../utils/errorHandler';
import { roles } from '../../../utils/constVariables';
import QuestionMarkIcon from '../../../Assets/icons/QuestionMarkIcon';
import Tooltip from '../../../components/ui/Tooltip';
import Profession from '../../../components/Professions';
import { nanoid } from 'nanoid';
import { isInt } from 'src/utils/isInt';
import clsx from 'clsx';
import { FacilitiesCasingQueryConfigFn } from 'src/utils/graphqlConfigs/facilityCasingsConfig';
import useFacilityHeaderMenu from 'src/hooks/headerMenu/useFacilityHeaderMenu';
import { calendarHasProbationDays } from 'src/utils/lists/calendarDaysIntership';
import useFacilityBreadcrumbs from 'src/hooks/breadcrumbs/useFacilityBreadcrumbs';

const AddProfession: React.FC = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const router = useParams();

  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }

  const { positions, me } = useStore();
  const { data } = useFacilityQuery({
    variables: {
      id: facilityId,
    },
  });
  const { data: dataCasings } = useFacilityCasingQuery({
    variables: {
      facilityId,
    },
  });

  const facilitiesCasingQueryConfig = FacilitiesCasingQueryConfigFn(facilityId);

  const [createCasing] = useCreateCasingMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilitiesCasingQueryConfig],
  });

  const facility = data?.facility;
  const facilityCasings = dataCasings?.facilityCasings;
  const selfEmployed = false;

  const btnRef = useRef<HTMLButtonElement>(null);

  const btnRefLeft = Number(btnRef.current?.offsetLeft);
  const btnRefParantWithScroll = Number(btnRef.current?.parentNode?.parentNode?.parentNode?.parentElement?.scrollLeft);

  const isMeAdminOrSuper = me && [roles.Admin, roles.Supervisor].includes(me.role);
  const isMeAdmin = me && [roles.Admin].includes(me.role);

  const headerMenu = useFacilityHeaderMenu({
    currentLocation: 'Информация',
    facilityId,
    isMeAdmin,
  });

  const breadCrumbs = useFacilityBreadcrumbs({ facilityId });

  return (
    <MainLayout
      title={`Профессии объекта ${facility?.name}`}
      bg="bg-smena_bb-background_base"
      headerMenu={headerMenu}
      breadCrumbs={breadCrumbs}
    >
      <Formik
        initialValues={{
          positionId: '',
          payout: 'HOURLY',
          unit: 'HOUR',
          hourRate: '',
          selfEmployedRate: '',
          selfEmployed,
          intership: false,
          intershipPeriod: 1,
          intershipRate: '',
        }}
        validate={values => {
          let errors: any = {};
          if (!values.positionId) {
            errors.positionId = 'Вы не указали профессию';
          }
          if (!values.payout) {
            errors.payout = 'Вы не указали тип оплаты';
          }
          if (!values.hourRate) {
            errors.hourRate = 'Вы не указали ставку';
          }
          if (Number(values.hourRate) === 0) {
            errors.hourRate = 'Ставка не может быть равна 0';
          }
          if (!/^\d+[.]?\d{1,2}$/.test(values.hourRate)) {
            errors.hourRate = 'Некорректный ввод';
          }
          if (Number(values.hourRate) > 1000) {
            errors.hourRate = 'Ставка не может быть больше 1000';
          }
          if (Number(values.selfEmployedRate) > 1000) {
            errors.selfEmployedRate = 'Бонус не может быть больше 1000';
          }
          return errors;
        }}
        onSubmit={async values => {
          await createCasing({
            variables: {
              input: {
                facilityId,
                payout: values.payout as PayoutType,
                unit: values.unit as UnitType,
                hourRate: isInt(Number(values.hourRate)),
                positionId: values.positionId,
                isSelfEmployed: values.selfEmployed,
                selfEmployedRate: Number(values.selfEmployedRate),
                hasProbation: values.intership,
                probationPeriod: values.intership ? Number(values.intershipPeriod) : null,
                probationRate: values.intership ? Number(values.intershipRate) : null,
              },
            },
          })
            .then(res => {
              if (res.data?.createCasing) {
                successToast('Профессия добавлена');
              }
            })
            .catch(e => {
              if (e.message.includes('Unique constraint failed on the fields: (`facilityId`,`positionId`)')) {
                errorToast('На этом объекте уже существует такая профессия');
              } else errorToast(errorHandler(e));
            });
        }}
      >
        {({ values, touched, errors, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <div className="add_profession border rounded-lg shadow-smena bg-smena_white pb-4 overflow-auto">
              <form onSubmit={handleSubmit}>
                <div
                  className={clsx(
                    'grid border-b px-6 border-smena_gray-30 Subtitle2 text-smena_text-secondary',
                    isMeAdminOrSuper ? 'add_profession__table-edit' : ' add_profession__table-view'
                  )}
                >
                  <span className="pt-3 pb-6 pr-5 mt-3">Название</span>
                  <span className="pt-3 pb-6 pr-5 mt-3">Тип оплаты</span>
                  <span className="pt-3 pb-6 pr-5 mt-3">Ед. измерения</span>
                  <span className="pt-3 pb-6 pr-5 mt-3">Ставка, ₽</span>
                  <span className="pt-3 pl-2 pb-6 pr-5 mt-3 bg-smena_bb-background_base rounded-tl-lg">СЗ</span>
                  <span className="pt-3 pb-6 pr-3 mr-2 mt-3 bg-smena_bb-background_base rounded-tr-lg">
                    Бонус СЗ, ₽
                  </span>
                  <span className="pt-3 pb-6 pr-5 mt-3">Проб. п.</span>
                  <div className="flex gap-x-1 items-center pt-3 pb-6 pr-5 mt-3">
                    <span>Кол-во дней</span>
                    <button
                      className="relative"
                      ref={btnRef}
                      onMouseEnter={() => {
                        setShowTooltip(true);
                      }}
                      onMouseLeave={() => {
                        setShowTooltip(false);
                      }}
                    >
                      <QuestionMarkIcon />
                    </button>
                  </div>
                  <span className="pt-3 pb-6 mt-3">Ставка, ₽</span>
                  {isMeAdminOrSuper && (
                    <span
                      className={clsx(
                        'pt-3 pb-6 mt-3 rounded-t-lg',
                        isMeAdminOrSuper ? 'bg-smena_bb-background_base' : ''
                      )}
                    ></span>
                  )}
                </div>
                {isMeAdminOrSuper && (
                  <div
                    className={clsx(
                      'grid px-6',
                      isMeAdminOrSuper ? 'add_profession__table-edit' : ' add_profession__table-view'
                    )}
                  >
                    <SimpleSelect
                      divClassName="pt-3 pr-5"
                      label=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.positionId}
                      name="positionId"
                      autoComplete="positionId"
                      disabledOption="Профессия"
                      options={positions}
                      error={touched.positionId && errors.positionId ? errors.positionId : ''}
                    />
                    <SimpleSelect
                      divClassName="pt-3 pr-5"
                      label=""
                      onChange={e => {
                        handleChange(e);
                        if (e.target.value === 'PIECEWORK') {
                          setFieldValue('unit', 'PIECE');
                        } else {
                          setFieldValue('unit', 'HOUR');
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.payout}
                      name="payout"
                      autoComplete="payout"
                      options={payoutTypes}
                      error={touched.payout && errors.payout ? errors.payout : ''}
                    />
                    <SimpleSelect
                      divClassName="pt-3 pr-5"
                      label=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.unit}
                      name="unit"
                      autoComplete="unit"
                      options={values.payout === 'HOURLY' ? hourlyUnit : units}
                      error={touched.unit && errors.unit ? errors.unit : ''}
                    />
                    <SimpleInput
                      divClassName="pt-3 pr-5"
                      label=""
                      placeholder="100"
                      onChange={handleChange}
                      float
                      onBlur={handleBlur}
                      value={values.hourRate}
                      type="text"
                      name="hourRate"
                      error={touched.hourRate && errors.hourRate ? errors.hourRate : ''}
                    />

                    <SimpleCheckbox
                      divClassName="pt-5 pr-5 pl-2 bg-smena_bb-background_base"
                      label=""
                      onChange={handleChange}
                      checked={values.selfEmployed}
                      name="selfEmployed"
                    />
                    <SimpleInput
                      divClassName="pt-3 pr-3 mr-2 bg-smena_bb-background_base"
                      label=""
                      placeholder="100"
                      onChange={handleChange}
                      regText={true}
                      onBlur={handleBlur}
                      value={values.selfEmployedRate}
                      type="text"
                      name="selfEmployedRate"
                      error={touched.selfEmployedRate && errors.selfEmployedRate ? errors.selfEmployedRate : ''}
                    />
                    <SimpleCheckbox
                      divClassName="pt-5 pr-5"
                      label=""
                      onChange={handleChange}
                      checked={values.intership}
                      name="intership"
                    />

                    <SimpleSelect
                      divClassName="pt-3 pr-5"
                      label=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.intershipPeriod}
                      disabled={!values.intership}
                      sort={false}
                      name="intershipPeriod"
                      autoComplete="intershipPeriod"
                      options={calendarHasProbationDays}
                    />
                    <SimpleInput
                      divClassName="pt-3 pr-5"
                      label=""
                      placeholder="100"
                      onChange={handleChange}
                      regText={true}
                      onBlur={handleBlur}
                      value={values.intershipRate}
                      disabled={!values.intership}
                      type="text"
                      name="intershipRate"
                    />

                    <div className="flex justify-center bg-smena_bb-background_base pt-3">
                      <button type="submit" disabled={isSubmitting} className="btn-secondary_small">
                        <span>{isSubmitting ? 'Загрузка' : 'Добавить'}</span>
                      </button>
                    </div>
                  </div>
                )}
              </form>
              {facilityCasings?.map(casing => (
                <Profession key={nanoid()} casing={casing} />
              ))}
            </div>
          );
        }}
      </Formik>
      {showTooltip && (
        <Tooltip
          placement="top-center"
          blockStyle={{
            top: btnRef.current?.offsetTop,
            left: btnRefLeft - btnRefParantWithScroll,
            height: 'max-content',
            transform: 'translate(-49%, -110%)',
          }}
          style={{ maxWidth: '240px' }}
          content="Пробный период считается с даты первой назначенной смены. 
          В стажировочном периоде может быть любое количество смен и все они будут считаться стажировочными."
        />
      )}
    </MainLayout>
  );
};

export default AddProfession;
