import React, { useCallback, useEffect, useMemo } from 'react';
import { useStore } from '../../../store';

export const ModerationReportsCheckbox: React.FC<{
  id: string;
  // reportStatus: string;
  noModeratedCount?: number | null;
  workersCount?: number | null;
  shiftCount?: number | null;
}> = ({ id, noModeratedCount, workersCount, shiftCount }) => {
  const [checkbox, setCheckbox] = React.useState(false);
  const { setModerationReports, moderationReports } = useStore();

  const checkboxHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setCheckbox(checked);
      setModerationReports(id);
    },
    [setModerationReports, id]
  );

  const disableCheckbox = useMemo(() => {
    if (
      // reportStatus === 'SENT' ||
      Number(noModeratedCount) > 0 ||
      Number(workersCount) === 0 ||
      Number(shiftCount) === 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [noModeratedCount, workersCount, shiftCount]);

  useEffect(() => {
    if (moderationReports.find(shift => shift === id)) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  }, [moderationReports]); //eslint-disable-line

  return (
    <input
      type="checkbox"
      name="reportId"
      className="checkbox-primary"
      checked={checkbox}
      disabled={disableCheckbox}
      onChange={checkboxHandler}
    />
  );
};
