import Check from '../../../Assets/icons/check';
import { successToast } from '../../../utils/notify';
import SpinnerLoad from '../../ui/Loader';

interface ITimesheetLoader {
  load: boolean;
  resLink: string;
}

const TimesheetLoader: React.FC<ITimesheetLoader> = ({ load, resLink }) => {
  return (
    <>
      {load ? (
        <div className="flex items-end mt-1">
          <div className="flex gap-x-1">
            <SpinnerLoad />
            <span className="Subtitle1 text-smena_text-secondary">
              Формируем отчет...
            </span>
          </div>
        </div>
      ) : resLink ? (
        <div className="flex items-center gap-x-8">
          <div className="flex gap-x-1">
            <span className="text-smena_green-dark">
              <Check />
            </span>
            <span className="Subtitle1 text-smena_text-secondary">
              Отчет готов!
            </span>
          </div>
          <a
            href={resLink}
            onClick={() => {
              successToast('Отчет успешно скачан');
            }}
            className="btn-secondary_small"
          >
            Скачать
          </a>
        </div>
      ) : null}
    </>
  );
};

export default TimesheetLoader;
