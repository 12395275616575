import {
  RegularCasingFragment,
  RegularPositionFragment,
} from '../../generated/graphql';

export const filterPositionsCasing = (
  facilityCasings?: RegularCasingFragment[],
  positions?: RegularPositionFragment[]
) => {
  return facilityCasings?.map(casing =>
    positions?.find(position => position.id === casing.positionId)
  );
};
