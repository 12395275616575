import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  ReviewType,
  useChangePersonalInfoMainMutation,
  useCreateUserByPersonalInfoMutation,
} from '../../../generated/graphql';

import PhoneInput from '../../ui/InputMasks';
import SimpleSelect from '../../ui/Select';
import { useStore } from '../../../store';
import MySelect from '../../ui/Formik/Select';
import { genders } from '../../../utils/lists/gendersList';
import { rejectsList } from '../../../utils/lists/commentsList';
import { errorToast, successToast } from '../../../utils/notify';
import { errorHandler } from '../../../utils/errorHandler';
import { userQueryConfigFn } from '../../../utils/graphqlConfigs/userQueryConfig';
import { userDocumentsQueryConfigFn } from '../../../utils/graphqlConfigs/userDocumentsQueryConfig';
import SimpleInput from '../../ui/Input';
import { getVerificationDate } from '../../../utils/get/getVerificationDate';
import { ISO_DATE, roles, RU_DATE } from '../../../utils/constVariables';
import { ApproveBlock } from './ApproveBlock';
import DatePickerInput from '../../ui/Pickers/DatePickerInput';

const MainInfo = () => {
  const navigate = useNavigate();
  const router = useParams();
  const { cities, countries, userRequisites, userReviews, me, currentUser } = useStore();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const meRole = String(me?.role);

  const userQueryConfig = userQueryConfigFn(userId);
  const userQueryDocumentsConfig = userDocumentsQueryConfigFn(userId);

  const [createUser] = useCreateUserByPersonalInfoMutation();

  const [updateUser] = useChangePersonalInfoMainMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig, userQueryDocumentsConfig],
  });

  const questionnaireUser = currentUser?.questionnaireTable;

  const regFIO = /^[А-яёЁa-zA-Z -]+$/;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        lastname: currentUser?.lastname || '',
        firstname: currentUser?.firstname || '',
        patronymic: currentUser?.patronymic || '',
        phone: currentUser?.phone || '',
        email: currentUser?.email || '',
        role: currentUser?.role || '',
        citizenshipId: currentUser?.citizenship?.id || '',
        sex: questionnaireUser?.sex || '',
        dateOfBirth: userRequisites?.birthDate ? dayjs(userRequisites.birthDate) : null,
        cityId: currentUser?.cityId || '',
      }}
      validate={values => {
        let errors: any = {};

        const emailReg = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!values.lastname) {
          errors.lastname = 'Обязательное поле';
        }
        if (!values.firstname) {
          errors.firstname = 'Обязательное поле';
        }
        if (!values.sex) {
          errors.sex = 'Обязательное поле';
        }
        if (!values.citizenshipId) {
          errors.citizenshipId = 'Обязательное поле';
        }
        if (values.email && !emailReg.test(values.email)) {
          errors.email = 'Неверный формат';
        }
        if (!values.phone || values.phone === '+7 (') {
          errors.phone = 'Обязательное поле';
        } else if (values.phone.length < 18) {
          if (values.phone.length === 10 && /^\S*$/.test(values.phone)) {
          } else {
            errors.phone = 'Неверное количество цифр';
          }
        }
        if (values.role !== roles.Admin) {
          if (!values.cityId) {
            errors.cityId = 'Вы не указали город';
          }
        }
        if (!values.dateOfBirth) {
          errors.dateOfBirth = 'Вы не указали дату рождения';
        }
        return errors;
      }}
      onSubmit={async values => {
        const userInput = {
          lastname: values.lastname.trim(),
          firstname: values.firstname.trim(),
          patronymic: values.patronymic.trim(),
          email: values.email,
          phone: values.phone.length === 18 ? values.phone.replace(/[^0-9]/g, '').substring(1) : values.phone,
          birthDate: dayjs(values.dateOfBirth).format(ISO_DATE),
          cityId: values.cityId,
          citizenshipId: values.citizenshipId,
          sex: values.sex,
        };
        if (userId) {
          return await updateUser({
            variables: {
              input: {
                userId,
                ...userInput,
              },
            },
          })
            .then(() => {
              successToast('Вы успешно изменили данные');
            })
            .catch(e => errorToast(errorHandler(e)));
        }
        await createUser({
          variables: {
            input: {
              ...userInput,
            },
          },
        })
          .then(res => {
            successToast('Вы успешно создали пользователя');
            setTimeout(() => {
              navigate('/users/' + res.data?.createUserByPersonalInfo.id + '/documents');
            }, 2000);
          })
          .catch(e => errorToast(errorHandler(e)));
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur, setFieldValue, isSubmitting }) => {
        return (
          <Form>
            <div className="flex flex-col gap-y-4 ">
              <div className="flex justify-between flex-wrap">
                <div className="flex flex-col gap-y-6">
                  <div className="flex gap-x-5 flex-wrap">
                    <SimpleInput
                      divClassName="crudUserInput"
                      label="Фамилия"
                      placeholder="Фамилия"
                      maxLength={30}
                      onChange={e => {
                        const value = e.target.value;
                        if (regFIO.test(value) || value === '') {
                          handleChange(e);
                        }
                      }}
                      value={values.lastname}
                      type="text"
                      name="lastname"
                      autoComplete="lastname"
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      validation={true}
                      error={touched.lastname && errors.lastname ? errors.lastname : ''}
                    />
                    <SimpleInput
                      divClassName="crudUserInput"
                      label="Имя"
                      placeholder="Имя"
                      maxLength={30}
                      onChange={e => {
                        const value = e.target.value;
                        if (regFIO.test(value) || value === '') {
                          handleChange(e);
                        }
                      }}
                      value={values.firstname}
                      type="text"
                      name="firstname"
                      autoComplete="firstname"
                      validation={true}
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      error={touched.firstname && errors.firstname ? errors.firstname : ''}
                    />
                    <SimpleInput
                      divClassName="crudUserInput"
                      label="Отчество"
                      placeholder="Отчество"
                      maxLength={30}
                      onChange={e => {
                        const value = e.target.value;
                        if (regFIO.test(value) || value === '') {
                          handleChange(e);
                        }
                      }}
                      value={values.patronymic}
                      type="text"
                      name="patronymic"
                      autoComplete="patronymic"
                      validation={true}
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      error={touched.patronymic && errors.patronymic ? errors.patronymic : ''}
                    />
                  </div>
                  <div className="flex gap-x-5 flex-wrap">
                    <SimpleSelect
                      divClassName="crudUserInput"
                      label="Гражданство"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.citizenshipId}
                      name="citizenshipId"
                      autoComplete="citizenshipId"
                      disabledOption="Выберите гражданство"
                      options={countries}
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      error={touched.citizenshipId && errors.citizenshipId ? errors.citizenshipId : ''}
                    />
                    {me && ![roles.ClientManager, roles.ClientDirector].includes(meRole) ? (
                      <MySelect label="Пол" name="sex" classname="crudUserInput" onChange={handleChange}>
                        <option value="" disabled>
                          Выберите пол
                        </option>
                        {genders.map(gender => (
                          <option key={gender.id} value={gender.id}>
                            {gender.name}
                          </option>
                        ))}
                      </MySelect>
                    ) : (
                      <div className="crudUserInput flex flex-col">
                        <label className="Tag text-smena_text-secondary">Пол</label>
                        <span className="Body1">{genders.find(gender => gender.id === values.sex)?.name}</span>
                      </div>
                    )}
                    {me && ![roles.ClientManager, roles.ClientDirector].includes(me.role) ? (
                      <DatePickerInput
                        divClassName="crudUserInput"
                        label="Дата рождения"
                        value={values.dateOfBirth}
                        onChange={dateOfBirth => {
                          setFieldValue('dateOfBirth', dateOfBirth);
                        }}
                        maxDate={dayjs().subtract(18, 'years')}
                      />
                    ) : values.dateOfBirth ? (
                      <div className="flex flex-col gap-x-5">
                        <label htmlFor="dateOfBirth" className="label-primary">
                          Дата рождения
                        </label>
                        <span>{dayjs(values.dateOfBirth).format(RU_DATE)}</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex gap-x-5 flex-wrap">
                    <SimpleInput
                      divClassName="crudUserInput"
                      label="E-mail"
                      placeholder="E-mail"
                      onChange={handleChange}
                      value={values.email}
                      type="text"
                      name="email"
                      autoComplete="email"
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      validation={true}
                      error={touched.email && errors.email ? errors.email : ''}
                    />
                    <div className="crudUserInput flex flex-col">
                      {[roles.ClientManager, roles.ClientDirector].includes(meRole) ? (
                        <label htmlFor="phone" className="Tag text-smena_text-secondary">
                          Телефон
                        </label>
                      ) : (
                        <label htmlFor="phone" className="label-primary">
                          Телефон
                        </label>
                      )}
                      <PhoneInput
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        showTextRole={[roles.ClientManager, roles.ClientDirector]}
                        placeholder="+7 (921) 345 45 45"
                        error={touched.phone ? errors.phone : ''}
                      />
                    </div>
                    {values.role !== roles.Admin && (
                      <SimpleSelect
                        divClassName="crudUserInput"
                        label="Город"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cityId}
                        name="cityId"
                        autoComplete="cityId"
                        disabledOption="Выберите город"
                        showTextRole={[roles.ClientManager, roles.ClientDirector]}
                        options={cities}
                        validation
                        error={touched.cityId && errors.cityId ? errors.cityId : ''}
                      />
                    )}
                  </div>
                </div>
                {userId && (
                  <ApproveBlock
                    reviewType={ReviewType.Person}
                    updatedAtBlock={userRequisites?.updatedAtPersonal}
                    commentsList={rejectsList}
                  />
                )}
              </div>
            </div>
            <div className="flex justify-between items-center save-block flex-wrap mt-5">
              <div className="flex gap-x-1">
                <span className="Caption text-smena_text-secondary">Дата верификации:</span>
                <span className="Caption text-smena_text-secondary">
                  {getVerificationDate([ReviewType.Person], userReviews)}
                </span>
              </div>
              <div className="flex gap-x-2 items-center flex-wrap">
                {userRequisites?.updatedAtPersonal && (
                  <div className="flex gap-x-1">
                    <span className="Caption text-smena_text-secondary">Посл. изменения</span>
                    <span className="Caption text-smena_text-secondary">
                      {dayjs(userRequisites?.updatedAtPersonal).format(RU_DATE)}
                    </span>
                  </div>
                )}

                {me && ![roles.ClientManager, roles.ClientDirector].includes(me.role) && (
                  <button type="submit" className="btn-primary_small" disabled={isSubmitting}>
                    Сохранить
                  </button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MainInfo;
