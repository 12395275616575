import { useCallback, useMemo } from 'react';
import { ShiftStage } from 'src/generated/graphql';
import { ICustomEvent } from 'src/Interfaces/IEvent';
import { useStore } from 'src/store';

const useWorkingShifts = (events?: ICustomEvent[]) => {
  const { facilityId, workpostId } = useStore();

  const filterMarks = useCallback(
    (shift: ICustomEvent) => shift.resource.stage === ShiftStage.Working,
    []
  );
  const filterFacility = useCallback(
    (event: ICustomEvent) => event.resource.facility?.id === facilityId,
    [facilityId]
  );

  const filterFacilityAndPosition = useCallback(
    (event: ICustomEvent) =>
      filterFacility(event) && event.resource.positionId === workpostId,
    [filterFacility, workpostId]
  );

  return useMemo(() => {
    if (facilityId) {
      if (workpostId) {
        return events?.filter(filterMarks).filter(filterFacilityAndPosition);
      }
      return events?.filter(filterMarks).filter(filterFacility);
    }
    return events?.filter(filterMarks);
  }, [
    events,
    facilityId,
    workpostId,
    filterMarks,
    filterFacility,
    filterFacilityAndPosition,
  ]);
};

export default useWorkingShifts;
