import clsx from 'clsx';
import { useCallback } from 'react';
import { useStore } from '../../../../store';
import MainSidebar from '../../../ui/Sidebar';
import NewCustomSchedule from './NewCustomSchedule';
import NewSchedule from './NewSchedule';

const Sidebar = () => {
  const {
    typeSchedule,
    setTypeSchedule,
    createScheduleMenu,
    setCreateScheduleMenu,
    setLunchDuration,
  } = useStore();

  const firstScheduleHandler = useCallback(() => {
    setTypeSchedule(1);
    setLunchDuration(0);
  }, [setTypeSchedule, setLunchDuration]);

  const secondScheduleHandler = useCallback(() => {
    setTypeSchedule(2);
    setLunchDuration(0);
  }, [setTypeSchedule, setLunchDuration]);

  return (
    <MainSidebar
      title="Добавить смены"
      show={createScheduleMenu}
      setShow={setCreateScheduleMenu}
    >
      <div className="flex mb-4 w-full">
        <button
          className={clsx(
            'rounded-l-md border transition-all flex flex-1 justify-center',
            typeSchedule === 1 && 'bg-smena_bb-background'
          )}
          onClick={firstScheduleHandler}
        >
          <span className="Subtitle1 text-smena_text py-1">шаблон графика</span>
        </button>
        <button
          className={clsx(
            'rounded-r-md border transition-all flex flex-1 justify-center',
            typeSchedule === 2 && 'bg-smena_bb-background'
          )}
          onClick={secondScheduleHandler}
        >
          <span className="Subtitle1 text-smena_text py-1">
            кастомный график
          </span>
        </button>
      </div>
      {typeSchedule === 1 && <NewSchedule />}
      {typeSchedule === 2 && <NewCustomSchedule />}
    </MainSidebar>
  );
};

export default Sidebar;
