import { Link } from 'react-router-dom';
import {
  RegularFacilityFragment,
  useChangeFacilityStatusMutation,
} from '../../../generated/graphql';
import Across from '../../../Assets/icons/Across';

import Check from '../../../Assets/icons/check';
import GarbageArrowless from '../../../Assets/icons/GarbageArrowless';
import GarbageArrow from '../../../Assets/icons/GarbageArrow';

interface TableInterface {
  facility?: RegularFacilityFragment;
  columns: {
    Header: string;
    name: string;
    className?: string;
    style?: any;
  }[];
}
const FacilityRow = ({ facility, columns }: TableInterface) => {
  const [changeStatus] = useChangeFacilityStatusMutation();
  return (
    <div
      key={facility?.id}
      className="grid table__row w-fit overflow-x-auto"
      style={{
        gridTemplateColumns: columns
          .map(
            column =>
              'minmax(' +
              column.style.minWidth +
              'px' +
              ', ' +
              column.style.width +
              'px)'
          )
          .join(' '),
        columnGap: 10,
      }}
    >
      <div className={columns[0].className}>
        <Link
          to={`/groups/${facility?.facilityGroupId}`}
          className="Table-small text-primary underline"
        >
          {facility?.facilityGroup?.name}
        </Link>
      </div>
      <div className={columns[1].className}>
        <Link
          to={`/facilities/${facility?.id}`}
          className="Table-small text-primary underline"
        >
          {facility?.name}
        </Link>
      </div>
      <div className={columns[2].className}>{facility?.city?.name}</div>
      <div className={columns[3].className}>{facility?.address}</div>
      <div className={columns[4].className}>{facility?.metro}</div>
      <div className={columns[5].className}>
        {facility?.is_active ? (
          <span className="text-smena_green-dark">
            <Check />
          </span>
        ) : (
          <span>
            <Across color="text-smena_red" />
          </span>
        )}
      </div>
      <div className={columns[6].className}>
        <div className="flex justify-between">
          <div>
            <button
              onClick={() => {
                changeStatus({ variables: { id: facility?.id || '' } });
              }}
            >
              {facility?.is_active ? <GarbageArrow /> : <GarbageArrowless />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilityRow;
