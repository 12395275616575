import React from 'react';

import { useStore } from '../store/index';

import MainLayout from '../components/Layout/main';
import Timesheet from './timesheets';
import PollsReport from './timesheets/PollsReport';
import SmsReport from './timesheets/SmsReport';
import DailyReport from './timesheets/DailyReport';
import { roles } from 'src/utils/constVariables';

const TimesheetPage: React.FC = () => {
  const { me } = useStore();

  return (
    <MainLayout
      title="Отчёты"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[{ path: '/Timesheet', name: 'Отчёты' }]}
    >
      <div className="grid grid-cols-12 gap-x-6 pb-10">
        <Timesheet />
        {me?.role === roles.Admin && (
          <>
            <DailyReport />
            <PollsReport />
            <SmsReport />
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default TimesheetPage;
