export const UploadSimple = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.52813 8.21251C7.38787 8.07191 7.3091 7.88142 7.3091 7.68282C7.3091 7.48422 7.38787 7.29373 7.52813 7.15313L11.4656 3.21563C11.6078 3.0748 11.7999 2.99579 12 2.99579C12.2001 2.99579 12.3922 3.0748 12.5344 3.21563L16.4719 7.15313C16.6121 7.29373 16.6909 7.48422 16.6909 7.68282C16.6909 7.88142 16.6121 8.07191 16.4719 8.21251C16.4021 8.28337 16.3189 8.33965 16.2272 8.37806C16.1354 8.41647 16.037 8.43625 15.9375 8.43625C15.838 8.43625 15.7396 8.41647 15.6478 8.37806C15.5561 8.33965 15.4729 8.28337 15.4031 8.21251L12.75 5.55938V14.25C12.75 14.4489 12.671 14.6397 12.5303 14.7803C12.3897 14.921 12.1989 15 12 15C11.8011 15 11.6103 14.921 11.4697 14.7803C11.329 14.6397 11.25 14.4489 11.25 14.25V5.55938L8.59687 8.21251C8.52709 8.28337 8.4439 8.33965 8.35216 8.37806C8.26042 8.41647 8.16196 8.43625 8.0625 8.43625C7.96304 8.43625 7.86458 8.41647 7.77284 8.37806C7.6811 8.33965 7.59791 8.28337 7.52813 8.21251ZM20.25 13.5C20.0511 13.5 19.8603 13.579 19.7197 13.7197C19.579 13.8603 19.5 14.0511 19.5 14.25V19.5H4.5V14.25C4.5 14.0511 4.42098 13.8603 4.28033 13.7197C4.13968 13.579 3.94891 13.5 3.75 13.5C3.55109 13.5 3.36032 13.579 3.21967 13.7197C3.07902 13.8603 3 14.0511 3 14.25V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V14.25C21 14.0511 20.921 13.8603 20.7803 13.7197C20.6397 13.579 20.4489 13.5 20.25 13.5Z"
        fill="#397DFF"
      />
    </svg>
  );
};
