import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Calendar, momentLocalizer, SlotInfo } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import dayjs from 'dayjs';

import { useUserShiftsLazyQuery } from '../../../generated/graphql';
import { useStore } from '../../../store';

import CustomEvent from '../CustomEvent';
import CustomToolbar from '../CustomToolbar';

import { shiftsClassName } from '../../../utils/shiftsClassName';
import { LegendShifts } from './Legend';
import { ICustomEvent } from 'src/Interfaces/IEvent';

import { ISO_DATE } from 'src/utils/constVariables';
import useSupervisorShifts from './hooks/useSupervisorShifts';
import useTimekeepengShifts from './hooks/useTimekeepengShifts';
import useGoodShifts from './hooks/useGoodShifts';
import useBadShifts from './hooks/useBadShifts';
import useWithoutMarks from './hooks/useWithoutMarks';
import useWorkingShifts from './hooks/useWorkingShifts';
import useEvents from './hooks/useEvents';
import { eventHandlerHelper } from './helper/eventHandler';

const localizer = momentLocalizer(moment);

const ShiftsCalendar = () => {
  const [dateFrom, setDateFrom] = useState(dayjs().startOf('month'));
  const [dateTo, setDateTo] = useState(dayjs().endOf('month'));

  const router = useParams();
  const [params, setParams] = useSearchParams();

  const { setSelectedShifts, selectedShifts, setShowSidebar, showSidebar } = useStore();

  let userId: string = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const [loadData, { data: dataShifts }] = useUserShiftsLazyQuery();

  const userShifts = dataShifts?.userShifts;

  const supervisorShifts = useSupervisorShifts({ userShifts });

  const supervisorTimekeepingShifts = useTimekeepengShifts(supervisorShifts);

  const timekeepingShifts = useTimekeepengShifts(userShifts);

  const events = useEvents({ supervisorTimekeepingShifts, timekeepingShifts });

  const goodShifts = useGoodShifts(events);

  const badShifts = useBadShifts(events);

  const withoutMarks = useWithoutMarks(events);

  const workingShifts = useWorkingShifts(events);

  const mainEventPropGetter = (event: ICustomEvent): React.HTMLAttributes<HTMLDivElement> => {
    const { resource } = event;
    return shiftsClassName(resource);
  };

  const eventHandler = (slotInfo: SlotInfo) => {
    eventHandlerHelper({
      events,
      slotInfo,
      setSelectedShifts,
      setShowSidebar,
      showSidebar,
      selectedShifts,
    });
  };

  const onNavigateHandler = (newDate: Date) => {
    const dateFrom = dayjs(newDate).startOf('month');
    const dateTo = dayjs(newDate).endOf('month');

    setDateFrom(dateFrom);
    setDateTo(dateTo);

    params.set('dateFrom', dateFrom.format(ISO_DATE));
    params.set('dateTo', dateTo.format(ISO_DATE));
    setParams(params);
  };

  const onShowMoreHandler = useCallback(
    (shifts: ICustomEvent[]) => {
      if (showSidebar) {
        setShowSidebar(false);
        setTimeout(() => {
          if (shifts.length > 0) {
            setSelectedShifts(shifts);
            setShowSidebar(true);
          }
        }, 300);
      } else {
        if (shifts.length > 0) {
          setSelectedShifts(shifts);
          setShowSidebar(true);
        }
      }
    },
    [setShowSidebar, setSelectedShifts, showSidebar]
  );

  useEffect(() => {
    loadData({
      variables: {
        input: {
          userId,
          dateFrom: String(dateFrom.valueOf()),
          dateTo: String(dateTo.valueOf()),
        },
      },
    });
  }, [dateFrom, dateTo, loadData, userId]);

  useEffect(() => {
    setShowSidebar(false);
  }, [setShowSidebar]);

  return (
    <div className="shifts__wrapper flex flex-wrap">
      <div className="shifts__calendar">
        <Calendar
          localizer={localizer}
          formats={{ dateFormat: 'D' }}
          length={10}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="month"
          views={['month']}
          messages={{
            showMore: target => `...еще ${target}`,
          }}
          selectable
          onSelectSlot={eventHandler}
          onNavigate={onNavigateHandler}
          eventPropGetter={mainEventPropGetter}
          components={{
            event: CustomEvent,
            toolbar: CustomToolbar,
            month: {
              dateHeader: function dateHeaderFn({ label }) {
                return <div onClick={undefined}>{label}</div>;
              },
            },
          }}
          onShowMore={onShowMoreHandler}
          doShowMoreDrillDown={false}
        />
      </div>
      <LegendShifts
        allShifts={events}
        goodShifts={goodShifts}
        badShifts={badShifts}
        withoutMarks={withoutMarks}
        workingShifts={workingShifts}
      />
    </div>
  );
};

export default ShiftsCalendar;
