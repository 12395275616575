import clsx from 'clsx';
import React from 'react';
import { useStore } from 'src/store';
import { ErrorNotification } from '../../ErrorNotification';

type FormInput = {
  divClassName: string;
  divStyle?: any;
  label: string;
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  onBlur?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  disabledOption?: string;
  value: string | number | undefined;
  options?: any[] | null;
  name: string;
  autoComplete?: string;
  error?: string;
  disabled?: boolean;
  showTextRole?: string[];
};

export const LunchDurationSelect = ({
  divClassName,
  divStyle,
  label,
  required,
  onChange,
  onBlur,
  disabledOption,
  value,
  options,
  name,
  autoComplete,
  error,
  disabled,
  showTextRole,
}: FormInput) => {
  const { me } = useStore();
  return (
    <>
      {me && options && showTextRole?.includes(me.role) ? (
        value ? (
          <div className={clsx(divClassName, 'flex flex-col text-sm')}>
            <span className="label-primary">{label}</span>
            <span>{options.find(option => option.id === value)?.name}</span>
          </div>
        ) : (
          <div className={clsx(divClassName, 'flex flex-col text-sm')}>
            <span className="label-primary">{label}</span>
            <span>Не выбрано</span>
          </div>
        )
      ) : (
        <div className={divClassName + ' inputHeight'} style={divStyle}>
          <label htmlFor={name} className="label-primary">
            {label}
          </label>
          <select
            required={required}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            id={name}
            name={name}
            className={`${
              error ? 'select-error truncate' : 'select-primary truncate'
            }`}
            autoComplete={autoComplete}
            disabled={disabled}
          >
            {disabledOption ? (
              <option value={''} disabled>
                {disabledOption}
              </option>
            ) : null}

            {options?.map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          {error && <ErrorNotification text={error} />}
        </div>
      )}
    </>
  );
};
