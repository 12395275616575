export const scheduleTypes = [
  {
    id: 1,
    name: '2/2',
  },
  {
    id: 6,
    name: '3/3',
  },
  {
    id: 7,
    name: '3/4',
  },
  {
    id: 8,
    name: '4/3',
  },
  {
    id: 2,
    name: '4/2',
  },
  {
    id: 3,
    name: '5/2',
  },
  {
    id: 4,
    name: '6/1',
  },
  {
    id: 5,
    name: '7/0',
  },
];
