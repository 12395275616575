import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Calendar } from 'src/components/Calendar/Shifts';
import MainLayout from 'src/components/Layout/main';
import Loading from 'src/components/ui/Loading';
import { useUserQuery } from 'src/generated/graphql';
import useUserBreadcrumbs from 'src/hooks/breadcrumbs/useUserBreadcrumbs';
import useUserHeaderMenu from 'src/hooks/headerMenu/useUserHeaderMenu';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';
import { UserFullName } from 'src/utils/userFullName';

const ShiftsPage = () => {
  const router = useParams();
  const { setCurrentUser, setIsUserLoading, me } = useStore();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const { data, loading } = useUserQuery({
    variables: {
      userId,
    },
  });

  const user = data?.user;

  const isMeAdminSuper = me && [roles.Admin, roles.Supervisor].includes(me.role);
  const isCurrentUserWorkerOrForeman = user && [roles.Worker, roles.Foreman].includes(user.role);

  const headerMenu = useUserHeaderMenu({
    currentLocation: 'Учет времени',
    userId,
    role: user?.role,
    isMeAdminSuper,
    isCurrentUserWorkerOrForeman,
  });

  const breadCrumbs = useUserBreadcrumbs({ isMeAdminSuper, userId });

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
      setIsUserLoading(false);
    }
    // eslint-disable-next-line
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <MainLayout
      title={'Учет смен ' + UserFullName(user)}
      bg="bg-smena_bb-background_base"
      headerMenu={headerMenu}
      breadCrumbs={breadCrumbs}
    >
      <Calendar />
    </MainLayout>
  );
};

export default ShiftsPage;
