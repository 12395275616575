import dayjs from 'dayjs';
import { RU_DATE } from 'src/utils/constVariables';
import { useStore } from 'src/store';
import { Link } from 'react-router-dom';

interface IActiveScheduleCheckbox {
  id: string;
}

const ActiveScheduleCheckbox: React.FC<IActiveScheduleCheckbox> = ({ id }) => {
  const { editScheduleRequestsIds, scheduleRequests } = useStore();

  const shift = scheduleRequests?.find(request => request.id === id);
  // console.log(scheduleRequests);
  // console.log(shift);
  return (
    <div className="flex items-center gap-x-1 bg-smena_bb-background p-2 Table-small rounded-lg text-primary">
      <input
        type="checkbox"
        className="checkbox-primary"
        defaultChecked
        onClick={() => {
          editScheduleRequestsIds([id]);
        }}
      />
      <Link target="_blank" to={`/requests/${shift?.id}`}>
        {shift?.number}
      </Link>
      <span>·</span>
      <div className="flex w-max">
        {dayjs(shift?.dateFrom).format(RU_DATE)}-{dayjs(shift?.dateTo).format(RU_DATE)}
      </div>
    </div>
  );
};

export default ActiveScheduleCheckbox;
