import useMenuElement from 'src/hooks/useMenuElements';
import { useStore } from 'src/store';
import MenuElement from '../Element';
import SubMenu from '../Submenu';

const MenuElements = () => {
  const { me } = useStore();
  const menuElements = useMenuElement(me?.role);
  return (
    <div className="flex flex-col px-5 md:px-0 gap-y-1 bg-smena_white">
      {menuElements.map(item => {
        if (item.submenu) {
          return <SubMenu key={item.id} item={item} />;
        }
        return <MenuElement key={item.id} item={item} />;
      })}
    </div>
  );
};

export default MenuElements;
