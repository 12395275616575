import dayjs from 'dayjs';
export const setEndDateSchedule = (startDate: Date, endDate: Date) => {
  if (startDate.getDate() !== endDate.getDate()) {
    return dayjs(endDate)
      .set('year', dayjs(startDate).get('year'))
      .set('month', dayjs(startDate).get('month'))
      .set('date', dayjs(startDate).get('date'))
      .toDate();
  }
  return endDate;
};
