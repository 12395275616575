import RequestsIcon from 'src/Assets/icons/RequestsIcon';
import FacilityIcon from '../../../../Assets/icons/menu/facilityIcon';
import GroupsIcon from '../../../../Assets/icons/menu/groupsIcon';
import Home from '../../../../Assets/icons/menu/home';
import PaymentsIcon from '../../../../Assets/icons/menu/payments';
import ProfessionIcon from '../../../../Assets/icons/menu/professionIcon';
import ReferralIcon from '../../../../Assets/icons/menu/refferalIcon';

import TimesheetIcon from '../../../../Assets/icons/menu/timesheetIcon';
import UsersIcon from '../../../../Assets/icons/menu/users';
import VacancyIcon from '../../../../Assets/icons/menu/vacancyIcon';

export const clientMenuItemList = [
  {
    id: 1,
    name: 'Главная',
    url: '/',
    icon: <Home className="group-fill:stroke-smena_white" />,
    submenu: false,
  },
  {
    id: 2,
    name: 'Исполнители',
    url: '/users',
    submenu: false,
    icon: <UsersIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 3,
    name: 'Объекты',
    url: '/facilities',
    submenu: false,
    icon: <FacilityIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 4,
    name: 'Заявки',
    url: '/requests',
    submenu: false,
    icon: <RequestsIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 5,
    name: 'Отчёты',
    url: '/timesheet',
    submenu: false,
    icon: <TimesheetIcon className="group-fill:stroke-smena_white" />,
  },
];

export const superMenuItemList = [
  {
    id: 1,
    name: 'Главная',
    url: '/',
    icon: <Home className="group-fill:stroke-smena_white" />,
    submenu: false,
  },
  {
    id: 2,
    name: 'Пользователи',
    url: '/users',
    submenu: false,
    icon: <UsersIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 3,
    name: 'Заявки',
    url: '/requests',
    submenu: false,
    icon: <RequestsIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 4,
    name: 'Выплаты',
    url: '/payments',
    submenu: true,
    menus: [
      { name: 'Модерация смен', url: '/moderations-period', submenu: false },
      { name: 'Начисления', url: '/payments', submenu: false },
    ],
    icon: <PaymentsIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 5,
    name: 'Объекты',
    url: '/facilities',
    submenu: false,
    icon: <FacilityIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 6,
    name: 'Отчёты',
    url: '/timesheet',
    submenu: false,
    icon: <TimesheetIcon className="group-fill:stroke-smena_white" />,
  },
];

export const menuItemList = [
  {
    id: 1,
    name: 'Главная',
    url: '/',
    submenu: false,
    icon: <Home className="group-hover:text-smena_white" />,
  },
  {
    id: 2,
    name: 'Пользователи',
    url: '/users',
    submenu: false,
    icon: <UsersIcon className="group-hover:stroke-smena_white" />,
  },
  {
    id: 3,
    name: 'Заявки',
    url: '/requests',
    submenu: false,
    icon: <RequestsIcon className="group-fill:stroke-smena_white" />,
  },
  // {
  //   id: 3,
  //   name: 'График',
  //   url: '/schedule',
  //   submenu: true,
  //   menus: [
  //     { name: 'Смена дня', url: '/daily-schedule', submenu: false },
  //     { name: 'График за период', url: '/period-schedule', submenu: false },
  //   ],
  //   icon: <GraphicIcon className="group-hover:text-smena_white" />,
  // },
  {
    id: 4,
    name: 'Выплаты',
    url: '/payments',
    submenu: true,
    menus: [
      { name: 'Модерация смен', url: '/moderations-period', submenu: false },
      { name: 'Начисления', url: '/payments', submenu: false },
    ],
    icon: <PaymentsIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 5,
    name: 'Группы',
    url: '/groups',
    submenu: false,
    icon: <GroupsIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 6,
    name: 'Объекты',
    url: '/facilities',
    submenu: false,
    icon: <FacilityIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 7,
    name: 'Профессии',
    url: '/positions',
    submenu: false,
    icon: <ProfessionIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 8,
    name: 'Вакансии',
    url: '/vacancies',
    submenu: false,
    icon: <VacancyIcon className="group-fill:stroke-smena_white" />,
  },

  {
    id: 9,
    name: 'Отчёты',
    url: '/timesheet',
    submenu: false,
    icon: <TimesheetIcon className="group-fill:stroke-smena_white" />,
  },
  {
    id: 10,
    name: 'Акции',
    url: '/leads',
    submenu: false,
    icon: <ReferralIcon className="group-fill:stroke-smena_white" />,
  },
];
