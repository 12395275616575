import { FilterPeriod } from '../../generated/graphql';

export const getTimePeriodCharts = (period: FilterPeriod) => {
  const periodsRu = [
    { name: 'последний час', value: FilterPeriod.Day },
    { name: 'последний день', value: FilterPeriod.Week },
    { name: 'последнюю неделю', value: FilterPeriod.Month },
    { name: 'последний месяц', value: FilterPeriod.HalfYear },
    { name: 'последние полгода', value: FilterPeriod.Year },
    { name: 'последний год', value: FilterPeriod.AllTime },
  ];
  return periodsRu.find(periodRu => periodRu.value === period)?.name;
};
