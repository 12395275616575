import { roles } from '../constVariables';

export const getRoleRu = (role?: string) => {
  const roleList = [
    {
      role: roles.Newbie,
      roleRu: 'Ньюби',
    },
    {
      role: roles.Worker,
      roleRu: 'Работник',
    },
    {
      role: roles.Foreman,
      roleRu: 'Бригадир',
    },
    {
      role: roles.ClientManager,
      roleRu: 'Менеджер',
    },
    {
      role: roles.ClientDirector,
      roleRu: 'Директор',
    },
    {
      role: roles.Supervisor,
      roleRu: 'Супервайзер',
    },
    {
      role: roles.Admin,
      roleRu: 'Администратор',
    },
  ];

  const response = roleList.find(item => role === item.role)?.roleRu;
  if (response) return response;
  return '';
};
