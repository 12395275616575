import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router';

import { useLoginMutation } from '../../generated/graphql';

import { setTokens } from '../../lib/auth';
import { errorHandler } from '../../utils/errorHandler';
import { useStore } from '../../store';
import { errorToast } from '../../utils/notify';
import { saveLoginData } from './__utils';
import SimpleInput from '../../components/ui/Input';
import EyeHide from '../../Assets/icons/EyeHide';
import EyeShow from '../../Assets/icons/EyeShow';
import { useState } from 'react';

const AdminLogin = () => {
  const [loginMutation] = useLoginMutation();
  const [passwordType, setPasswordType] = useState(true);
  const { phoneNumber } = useStore();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{ phoneNumber, password: '' }}
      onSubmit={async values => {
        await loginMutation({
          variables: {
            data: {
              phone: values.phoneNumber || '',
              password: values.password,
            },
          },
        })
          .then(response => {
            if (response.data?.login.user) {
              const data = response.data?.login;
              saveLoginData({
                phone: values.phoneNumber,
                password: values.password,
                role: data.user.role,
              });
              setTokens({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
              });
              navigate('/');
            }
          })
          .catch(e => {
            errorToast(errorHandler(e));
          });
      }}
    >
      {({ values, handleChange, isSubmitting }) => {
        return (
          <Form className="bg-white shadow w-full rounded-lg p-5 flex flex-col gap-y-3">
            <div className="flex gap-x-2">
              <SimpleInput
                divClassName="w-full"
                label="Пароль"
                placeholder="Введите пароль"
                value={values.password}
                onChange={handleChange}
                type={passwordType ? 'password' : 'text'}
                name="password"
                autoComplete="password"
              />
              <button
                className="flex mt-8"
                type="button"
                onClick={() => {
                  setPasswordType(value => !value);
                }}
              >
                {passwordType ? <EyeHide /> : <EyeShow />}
              </button>
            </div>
            <button type="submit" disabled={values.password.length === 0} className="btn-primary_big w-full">
              {isSubmitting ? 'Загрузка' : 'Войти'}
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdminLogin;
