import React, { useCallback, useEffect, useState } from 'react';

import MainLayout from '../components/Layout/main';
import RightNow from '../components/Charts/RightNow';
import Popup from '../components/ui/Popup';
import { roles } from '../utils/constVariables';
import HomePage from 'src/components/Popup/HomePage';
import { useIsMeAdminOrSupervisor } from 'src/hooks/useIsMeAdminOrSupervisor';
import clsx from 'clsx';
import UserRegistrationChart from '../components/Charts/UsersRegistration';
import Trends from '../components/Charts/Trends';
import { useSetUserBrowserTokenMutation } from '../generated/graphql';
import { errorToast } from '../utils/notify';
import { errorHandler } from '../utils/errorHandler';
import { fetchToken, onMessageListener } from '../utils/firebase';
import { useMeRole } from '../hooks/useRole';

const IndexPage: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const isMeAdminOrSupervisor = useIsMeAdminOrSupervisor();

  const isMeAdmin = useMeRole(roles.Admin);

  const [setUserBrowserToken] = useSetUserBrowserTokenMutation();

  const setTokenHandler = useCallback(
    (token: string) => {
      if (token) {
        setUserBrowserToken({
          variables: {
            token,
          },
        }).catch(err => errorToast(errorHandler(err)));
      }
    },
    [setUserBrowserToken]
  );

  useEffect(() => {
    fetchToken(setTokenHandler);
    onMessageListener()
      .then((payload: any) => {
        new Notification(payload.notification.title, {
          body: payload.notification.body,
        });
      })
      .catch(err => console.log('failed: ', err));
  }, [setTokenHandler]);

  return (
    <>
      <MainLayout title="Обзор">
        <div className="max-w-main-page flex flex-col gap-6">
          <div className="flex justify-end">
            {isMeAdmin && (
              <button
                className="btn-secondary_small w-full xs:w-auto"
                onClick={() => {
                  setShowPopup(true);
                }}
              >
                Пригласить пользователя
              </button>
            )}
          </div>

          <div className={clsx(isMeAdminOrSupervisor ? 'chartsBlock' : '')}>
            <RightNow />
            {isMeAdminOrSupervisor && <UserRegistrationChart />}
          </div>

          <Trends />
        </div>
        {isMeAdmin && (
          <Popup setShowPopup={setShowPopup} showPopup={showPopup}>
            <HomePage setShowPopup={setShowPopup} />
          </Popup>
        )}
      </MainLayout>
    </>
  );
};

export default IndexPage;
