import { Dayjs } from 'dayjs';
import { Moment } from 'moment/moment';

const dateHour = (yourDate: Dayjs | null, time?: Moment | null) => {
  if (time) {
    return yourDate?.set('hour', time?.hour()).set('minute', time?.minute()).valueOf();
  }
  return 0;
};
export default dateHour;
