import { useParams } from 'react-router-dom';
import { useFacilityCasingQuery } from 'src/generated/graphql';
import { changeField } from 'src/utils/changeRequestRowElemet';
import { filterPositionsCasing } from 'src/utils/filters/filterPositionsCasing';
import { changeListOfPositions } from 'src/utils/findChangedElementIndex';
import Across from '../../../Assets/icons/Across';
import { IZayavkaPlanInput } from '../../../Interfaces/IZayavkaPlanInput';
import { useStore } from '../../../store';
import SimpleInput from '../../ui/Input';
import SimpleSelect from '../../ui/Select';

interface INewInterfaceRow {
  list: IZayavkaPlanInput[];
  el: IZayavkaPlanInput;
  setPositionsList: (value: IZayavkaPlanInput[]) => void;
  facilityId: string;
}

const NewRequestRow = ({
  list,
  el,
  setPositionsList,
  facilityId,
}: INewInterfaceRow) => {
  const router = useParams();

  let facilityIdURL: string = '';

  if (typeof router.facilityId === 'string') {
    facilityIdURL = router.facilityId;
  }

  const { data } = useFacilityCasingQuery({
    variables: {
      facilityId: facilityIdURL ? facilityIdURL : facilityId,
    },
  });

  const facilityCasings = data?.facilityCasings;

  const { positions } = useStore();

  return (
    <>
      <div className="flex gap-x-6">
        <div className="grid grid-cols-3 lg:grid-cols-[repeat(4,_1fr)] gap-x-6">
          <SimpleSelect
            divClassName="max-w-[200px]"
            label="Профессия"
            onChange={e => {
              const changedEl = changeField('positionId', el, e.target.value);
              const copyOfList = [...list];
              changeListOfPositions(el.id, copyOfList, changedEl);
              setPositionsList(copyOfList);
            }}
            value={el.positionId}
            name="professionId"
            options={filterPositionsCasing(facilityCasings, positions)}
            validation
            required
            disabledOption="Не выбрано"
            error={el.error?.positionId}
          />
          <SimpleInput
            divClassName="max-w-[200px]"
            label="Количество смен"
            placeholder="5"
            required
            onChange={e => {
              const changedEl = changeField('shiftCount', el, e.target.value);
              const copyOfList = [...list];
              changeListOfPositions(el.id, copyOfList, changedEl);
              if (el.shiftDuration) {
                const changedElCurrentField = changeField(
                  'hours',
                  el,
                  Number(e.target.value) * Number(el.shiftDuration)
                );
                changeListOfPositions(el.id, copyOfList, {
                  ...changedEl,
                  hours: changedElCurrentField.hours,
                });
              }
              setPositionsList(copyOfList);
            }}
            value={el.shiftCount || ''}
            type="text"
            validation
            regText
            name="shiftCount"
            error={el.error?.shiftCount}
          />
          <SimpleInput
            divClassName="max-w-[200px]"
            label="Длительность смены"
            placeholder="8"
            required
            onChange={e => {
              const changedEl = changeField(
                'shiftDuration',
                el,
                e.target.value
              );
              const copyOfList = [...list];
              changeListOfPositions(el.id, copyOfList, changedEl);
              if (el.shiftCount) {
                const changedElCurrentField = changeField(
                  'hours',
                  el,
                  Number(e.target.value) * Number(el.shiftCount)
                );
                changeListOfPositions(el.id, copyOfList, {
                  ...changedEl,
                  hours: changedElCurrentField.hours,
                });
              }
              setPositionsList(copyOfList);
            }}
            value={el.shiftDuration || ''}
            type="text"
            validation
            regText
            name="shiftDuration"
            error={el.error?.shiftDuration}
          />
          <SimpleInput
            divClassName="max-w-[200px]"
            label="Количество часов"
            placeholder="40"
            onChange={e => {
              const changedEl = changeField('hours', el, e.target.value);
              const copyOfList = [...list];
              changeListOfPositions(el.id, copyOfList, changedEl);
              setPositionsList(copyOfList);
            }}
            value={el.hours || ''}
            type="text"
            validation
            regText
            name="hoursCount"
          />
        </div>
        <div className="grid grid-rows-2 lg:grid-rows-1 lg:self-center">
          <button
            className="row-start-2 flex gap-x-1 items-center Button1"
            onClick={() => {
              const copyOfList = [...list];
              changeListOfPositions(el.id, copyOfList);
              setPositionsList(copyOfList);
            }}
          >
            <Across color="text-smena_red" />
            <span className="text-smena_red">Удалить</span>
          </button>
        </div>
      </div>
      <hr />
    </>
  );
};
export default NewRequestRow;
