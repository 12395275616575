import { RegularFacilitiesFragment } from '../../generated/graphql';

export const filterCities = (
  groupId?: string,
  facilities?: RegularFacilitiesFragment[]
) => {
  if (facilities) {
    if (groupId) {
      return facilities
        .filter(facility => facility.facilityGroupId === groupId)
        .map(facility => facility.cityId);
    }
    return facilities.map(facility => facility.cityId);
  }
  return [];
};
