import { RegularUserShiftsFragment } from '../../../../generated/graphql';
import { useMeRole } from '../../../../hooks/useRole';
import { roles } from '../../../../utils/constVariables';
import useSuperFacilities from '../../hooks/useSuperFacilities';
import { useMemo } from 'react';

const useUserShifts = ({ userShifts }: { userShifts?: RegularUserShiftsFragment[] }) => {
  const isMeSupervisor = useMeRole(roles.Supervisor);
  const supervisorFacilities = useSuperFacilities();
  return useMemo(
    () =>
      userShifts?.filter(shift => {
        const isSupervisorCanSeeShift = supervisorFacilities.filter(el => el?.id === shift.facility?.id).length > 0;
        if (isMeSupervisor) {
          if (isSupervisorCanSeeShift) return shift;
          return false;
        }
        return shift;
      }),
    [isMeSupervisor, supervisorFacilities, userShifts]
  );
};

export default useUserShifts;
