import {
  GetUserDocumentsDocument,
  GetUserDocumentsQueryVariables,
} from '../../generated/graphql';

interface IUserDocumentsQueryConfig {
  query: typeof GetUserDocumentsDocument;
  variables: GetUserDocumentsQueryVariables;
}

export const userDocumentsQueryConfigFn = (
  id?: string
): IUserDocumentsQueryConfig => ({
  query: GetUserDocumentsDocument,
  variables: {
    workerId: id || '',
  },
});
