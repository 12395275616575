import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useStore } from '../../../store';
import { ErrorNotification } from '../ErrorNotification';

type FormInput = {
  divClassName: string;
  divStyle?: any;
  label: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  type: string;
  regText?: boolean;
  float?: boolean;
  placeholder?: string;
  name: string;
  autoComplete?: string;
  pattern?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  error?: string;
  disabled?: boolean;
  validation?: boolean;
  showTextRole?: string[];
};

const SimpleInput = ({
  divClassName,
  divStyle,
  label,
  required,
  onChange,
  onBlur,
  placeholder,
  value,
  type,
  regText,
  float,
  name,
  pattern,
  autoComplete,
  min,
  max,
  maxLength,
  error,
  disabled,
  validation,
  showTextRole,
}: FormInput) => {
  const { me } = useStore();
  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (regText) {
        if (/^\d+$/.test(value) || value === '') {
          onChange(e);
        }
        return;
      }
      if (float) {
        if (/^[\d.]+$/.test(value) || value === '') {
          onChange(e);
        }
        return;
      }
      onChange(e);
    },
    [onChange, regText, float]
  );

  return (
    <>
      {me && showTextRole?.includes(me.role) ? (
        value !== null || value !== undefined ? (
          <div className={clsx(divClassName, 'flex flex-col gap-y-0.5')}>
            <span className="Tag text-smena_text-secondary">{label}</span>
            <span className="Body1">{value}</span>
          </div>
        ) : null
      ) : (
        <div
          className={clsx(
            'flex flex-col gap-y-0.5',
            divClassName,
            validation ? 'inputHeight' : ''
          )}
          style={divStyle}
        >
          <label htmlFor={name} className="label-primary" title={label}>
            {label}
          </label>
          <input
            required={required}
            onChange={onChangeHandler}
            onBlur={onBlur}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            type={type}
            name={name}
            id={name}
            pattern={pattern}
            min={min}
            max={max}
            className={error ? 'input-error' : 'input-primary'}
            autoComplete={autoComplete}
            disabled={disabled}
          />
          {error && <ErrorNotification text={error} />}
        </div>
      )}
    </>
  );
};

export default SimpleInput;
