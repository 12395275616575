import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import NewSchedule from '../../../Assets/icons/newSchedule';
import {
  DocumentsStatus,
  RegularUsersFragment,
  useChangeUserStatusMutation,
  useFakeCodeMutation,
  UsersPaginatedDocument,
  UsersPaginatedQueryVariables
} from '../../../generated/graphql';
import { successToast } from '../../../utils/notify';
import { IColumns } from '../../../Interfaces/IColumns';
import { errorHandler } from '../../../utils/errorHandler';
import { getDocumentStatusImg } from '../../../utils/get/getDocumentStatusImg';
import { getRoleRu } from '../../../utils/get/getRoleRu';
import { useStore } from '../../../store';
import Check from '../../../Assets/icons/check';
import Across from '../../../Assets/icons/Across';
import Garbage from '../../../Assets/icons/GarbageArrow';
import GarbageArrowless from '../../../Assets/icons/GarbageArrowless';
import Key from '../../../Assets/icons/key';
import Tooltip from '../../ui/Tooltip';
import { getDocumentStatusRu } from '../../../utils/get/getDocumentStatusRu';
import { roles } from 'src/utils/constVariables';

interface IUsersRow {
  user: RegularUsersFragment;
  columns: IColumns[];
}

const UsersRow: React.FC<IUsersRow> = ({ user, columns }) => {
  const { me, setTypeSchedule, setShowSidebar, setCreateScheduleMenu } = useStore();

  const [showTooltip, setShowTooltip] = useState(false);
  const [fakeCodeMutation] = useFakeCodeMutation();

  const fakeCode = (phone: string) => {
    fakeCodeMutation({
      variables: {
        phone,
      },
    })
      .then(res => successToast(`Код доступа ${res.data?.fakeCode}`))
      .catch(e => errorHandler(e));
  };

  const userQueryConfig: {
    query: typeof UsersPaginatedDocument;
    variables: UsersPaginatedQueryVariables;
  } = {
    query: UsersPaginatedDocument,
    variables: { input: { facilityId: '', page: 1 } },
  };

  const [mutateUserStatus] = useChangeUserStatusMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const toggleUserStatus = useCallback(
    (userId: string, isActive: boolean) => {
      mutateUserStatus({
        variables: {
          input: {
            userId,
            isActive: !isActive,
          },
        },
      });
    },
    [mutateUserStatus]
  );

  const scheduleHandler = useCallback(() => {
    setCreateScheduleMenu(false);
    setShowSidebar(false);
    setTypeSchedule(1);
  }, [setCreateScheduleMenu, setShowSidebar, setTypeSchedule]);

  const meRole = me?.role;

  const userFacilities = () => {
    if (user.role === roles.Worker) {
      return [...new Map(user?.workposts?.map(item => [item['facilityId'], item])).values()].map(workpost => (
        <div key={workpost.facilityId}>
          {me?.role === roles.Admin ? (
            <Link to={`/facilities/${workpost.facilityId}`} className="text-primary underline">
              {workpost.facility?.name}
            </Link>
          ) : (
            <span>{workpost.facility?.name}</span>
          )}
        </div>
      ));
    }

    if (user.role === roles.Foreman) {
      return user.Facility_FOREMAN?.map(facility => (
        <div key={facility.id}>
          {me?.role === roles.Admin ? (
            <Link to={`/facilities/${facility.id}`} className="text-primary underline">
              {facility?.name}
            </Link>
          ) : (
            <span>{facility?.name}</span>
          )}
        </div>
      ));
    }
    if (user.role === roles.Supervisor) {
      return user.Facility_SUPERVISOR?.map(facility => (
        <div key={facility.id}>
          {me?.role === roles.Admin ? (
            <Link to={`/facilities/${facility.id}`} className="text-primary underline">
              {facility?.name}
            </Link>
          ) : (
            <span>{facility?.name}</span>
          )}
        </div>
      ));
    }
    return null;
  };

  return (
    <div
      key={user.id}
      className="grid table__row w-fit"
      style={{
        gridTemplateColumns: columns
          .map(column => 'minmax(' + column.style.minWidth + 'px, ' + column.style.width + 'px)')
          .join(' '),
        columnGap: 10,
      }}
    >
      <div className={columns[0].className}>
        <Link to={`/users/${user.id}`} className="Table-small text-primary hover:underline">
          {user.lastname ? user.lastname : 'Нет информации'}
        </Link>
      </div>
      <div className={columns[1].className}>{user.firstname}</div>
      <div className={columns[2].className}>{user.patronymic}</div>
      <div className={columns[3].className}>
        <button
          className="relative"
          onMouseEnter={() => {
            setShowTooltip(true);
          }}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}
        >
          {getDocumentStatusImg(user.documentsStatus)}
          {user.documentsStatus !== DocumentsStatus.NoDocuments && showTooltip && (
            <Tooltip
              content={getDocumentStatusRu(user.documentsStatus)?.statusRu || ''}
              style={getDocumentStatusRu(user.documentsStatus)?.style}
            />
          )}
        </button>
      </div>
      <div className={columns[4].className}>{getRoleRu(user.role)}</div>
      <div className={columns[5].className}>{user.phone}</div>
      <div className={columns[6].className}>{userFacilities()}</div>
      <div className={columns[7].className}>{user.currentVersion}</div>
      <div className={columns[8].className}>
        {user.isActive ? (
          <span className="text-smena_green-dark">
            <Check />
          </span>
        ) : (
          <span>
            <Across color="text-smena_red" />
          </span>
        )}
      </div>
      <div className={columns[9].className}>
        <div className="flex justify-between">
          {[roles.Worker, roles.Foreman].includes(user.role) ? (
            <Link to={`/users/${user.id}/schedule`} onClick={scheduleHandler}>
              <NewSchedule className="text-smena_text" />
            </Link>
          ) : (
            <div className="cursor-not-allowed">
              <NewSchedule className="text-smena_text-disabled" />
            </div>
          )}

          <div>
            <button className="" onClick={() => toggleUserStatus(user.id, user.isActive)}>
              {user.isActive ? <GarbageArrowless /> : <Garbage />}
            </button>
          </div>
          <div className="w-[24px]">
            {meRole === roles.Admin ? (
              user.role !== roles.Admin ? (
                <button onClick={() => fakeCode(user.phone)}>
                  <Key className="text-smena_text" />
                </button>
              ) : (
                <button disabled className="cursor-not-allowed">
                  <Key className="text-smena_text-disabled" />
                </button>
              )
            ) : (
              <button disabled className="cursor-not-allowed">
                <Key className="text-smena_text-disabled" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UsersRow;
