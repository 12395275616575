import { useState, useCallback, useMemo, useEffect } from 'react';
import Across from '../../../../Assets/icons/Across';

import AcrossSmall from '../../../../Assets/icons/AcrossSmall';
import {
  useAddManagerToFacilityMutation,
  useRemoveManagerFromFacilityGroupMutation,
  useRemoveManagerFromFacilityMutation,
} from '../../../../generated/graphql';
import { useStore } from '../../../../store';
import { errorHandler } from '../../../../utils/errorHandler';
import { filterFacilities } from '../../../../utils/filters/filterFacilities';
import { userQueryConfigFn } from '../../../../utils/graphqlConfigs/userQueryConfig';
import { errorToast, successToast } from '../../../../utils/notify';
import SimpleSelect from '../../../ui/Select';

const ManagerRow = ({ managerGroupId }: { managerGroupId: string }) => {
  const { facilities, groups, currentUser } = useStore();
  const [groupId, setGroupId] = useState(managerGroupId);
  const [facilityId, setFacilityId] = useState('');

  const userQueryConfig = userQueryConfigFn(currentUser?.id);

  const [addManagerToFacility] = useAddManagerToFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const [removeManagerFromFacility] = useRemoveManagerFromFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const [removeManagerFromFacilityGroup] =
    useRemoveManagerFromFacilityGroupMutation({
      awaitRefetchQueries: true,
      refetchQueries: [userQueryConfig],
    });

  const addManagerToFacilityHandler = useCallback(async () => {
    if (currentUser?.id) {
      await addManagerToFacility({
        variables: {
          facilityId,
          userId: currentUser.id,
        },
      })
        .then(() => {
          successToast('Менеджер успешно добавлен в объект');
        })
        .catch(err => errorToast(errorHandler(err)));
    }
  }, [currentUser?.id, facilityId, addManagerToFacility]);

  const removeManagerFromFacilityHandler = useCallback(
    async (facilityId: string) => {
      if (currentUser?.id) {
        await removeManagerFromFacility({
          variables: {
            facilityId,
            userId: currentUser.id,
          },
        })
          .then(() => successToast('Менеджер успешно удален из объекта'))
          .catch(err => errorToast(errorHandler(err)));
      }
    },
    [currentUser?.id, removeManagerFromFacility]
  );

  const removeManagerFromFacilityGroupHandler = useCallback(async () => {
    if (currentUser?.id) {
      await removeManagerFromFacilityGroup({
        variables: {
          groupId: managerGroupId,
          userId: currentUser.id,
        },
      })
        .then(() => successToast('Менеджер успешно удален из группы'))
        .catch(err => errorToast(errorHandler(err)));
    }
  }, [currentUser?.id, managerGroupId, removeManagerFromFacilityGroup]);

  const filteredFacilities = useMemo(
    () => filterFacilities(groupId, facilities),
    [facilities, groupId]
  );

  const groupFacilities = currentUser?.facilitiesManager?.filter(
    facility => facility.facilityGroupId === managerGroupId
  );

  useEffect(() => {
    if (groupFacilities && groupFacilities.length > 0) {
      setFacilityId(groupFacilities[0].id);
    } // eslint-disable-next-line
  }, []);
  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex justify-between">
        <div className="flex gap-x-5">
          <SimpleSelect
            divClassName="w-200px"
            label="Группа"
            onChange={e => {
              setGroupId(e.target.value);
            }}
            value={groupId}
            name="groupId"
            options={groups}
            allOption="Не выбрано"
            disabled
          />
          <SimpleSelect
            divClassName="w-200px"
            label="Объект"
            onChange={e => {
              setFacilityId(e.target.value);
            }}
            value={facilityId}
            name="facilityId"
            options={filteredFacilities}
            allOption="Не выбрано"
          />
          <div className="flex items-end">
            <button
              className="btn-secondary"
              onClick={addManagerToFacilityHandler}
            >
              Добавить
            </button>
          </div>
        </div>
        <div className="flex items-end">
          <button
            className="flex text-smena_red gap-x-2 Button1"
            onClick={removeManagerFromFacilityGroupHandler}
          >
            <Across />
            <span>Удалить</span>
          </button>
        </div>
      </div>
      {groupFacilities?.length !== 0 && (
        <div className="flex gap-x-2">
          {groupFacilities?.map(facility => (
            <div
              className="py-1 pl-3 pr-2 bg-smena_bb-background rounded-lg items-center flex gap-x-1 group hover:bg-smena_red-extra_light"
              key={facility.id}
            >
              <span className="Caption-numbers text-primary group-hover:text-smena_red">
                {facility.name}
              </span>
              <button
                onClick={() => {
                  removeManagerFromFacilityHandler(facility.id);
                }}
              >
                <AcrossSmall color="text-primary group-hover:text-smena_red" />
              </button>
            </div>
          ))}
        </div>
      )}
      <hr />
    </div>
  );
};

export default ManagerRow;
