import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { useFacilityQuery, useGetZayavkyByFacilityQuery } from '../../../generated/graphql';

import MainLayout from '../../../components/Layout/main';
import SimpleSelect from '../../../components/ui/Select';
import RequestCalendarPicker from '../../../components/Request/Calendar';

import { useStore } from '../../../store';
import ScheduleInfo from 'src/components/Request/Schedule/Sidebar/scheduleInfo';
import RequestProfession from 'src/components/Request/Schedule/RequestProfession';
import RequestProfessionDates from 'src/components/Request/Schedule/RequestProfessionDates';
import WorkerToShift from 'src/components/Request/Schedule/Sidebar/workerToShift';
import { ISO_DATE, roles, RU_DATE } from 'src/utils/constVariables';
import useFacilityHeaderMenu from 'src/hooks/headerMenu/useFacilityHeaderMenu';
import useFacilityBreadcrumbs from 'src/hooks/breadcrumbs/useFacilityBreadcrumbs';
import Loading from '../../../components/ui/Loading';
import { useMeRole } from '../../../hooks/useRole';
import { daysCount } from '../../../utils/differenceBetweenDays';
import { useFilterRequestWithSchedule, useUsersAtScheduleRequest } from './hooks';
import ScheduleHeader from '../../../components/Request/Schedule/ScheduleHeader';

const GraphicPage = () => {
  const daysRow = useRef<HTMLDivElement>(null);
  const {
    setScheduleRequestsHeaderCheckbox,
    setScheduleRequestsIds,
    scheduleRequestsIds,
    scheduleRequests,
    setScheduleRequest,
  } = useStore();

  const [params, setParams] = useSearchParams();

  const [date, setDate] = useState<dayjs.Dayjs | null | undefined>(dayjs().startOf('month'));

  const [workerId, setWorkerId] = useState('');
  const [positionId, setPositionId] = useState('');
  const [showShiftSidebar, setShowShiftSidebar] = useState(false);
  const [showGraphicSidebar, setShowGraphicSidebar] = useState(false);

  const isMeAdmin = useMeRole(roles.Admin);

  const router = useParams();

  const dateParam = params.get('date');

  let facilityIdURL = '';

  if (typeof router.facilityId === 'string') {
    facilityIdURL = router.facilityId;
  }

  const { data } = useFacilityQuery({
    variables: {
      id: facilityIdURL,
    },
  });

  const facilityName = data?.facility.name;

  const title = useMemo(() => {
    if (facilityName) {
      return `Объект ${facilityName}`;
    }
    return '';
  }, [facilityName]);

  const handlePositionIdChange = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setWorkerId('');
    setPositionId(value);
  };

  const handleWorkerIdChange = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setPositionId('');
    setWorkerId(value);
  };

  const days = useMemo(
    () =>
      daysCount({
        dateFrom: date?.startOf('month').format(ISO_DATE),
        dateTo: date?.endOf('month').format(ISO_DATE),
      }),
    [date]
  );

  const { data: graphicData, loading } = useGetZayavkyByFacilityQuery({
    variables: {
      facilityId: facilityIdURL,
      month: dayjs(date).format(ISO_DATE),
    },
  });

  const { usersOptions, positionOptions } = useUsersAtScheduleRequest();

  const filterRequestWithSchedule = useFilterRequestWithSchedule({ positionId, workerId });

  const headerMenu = useFacilityHeaderMenu({
    currentLocation: 'График',
    facilityId: facilityIdURL,
    isMeAdmin,
  });

  const breadCrumbs = useFacilityBreadcrumbs({ facilityId: facilityIdURL });

  useEffect(() => {
    if (scheduleRequests && scheduleRequests.length) {
      setScheduleRequestsIds(scheduleRequests.map(request => request.id));
    }
  }, [scheduleRequests, setScheduleRequestsHeaderCheckbox, setScheduleRequestsIds]);

  useEffect(() => {
    setScheduleRequestsHeaderCheckbox(Boolean(scheduleRequestsIds.length));
  }, [scheduleRequestsIds.length, setScheduleRequestsHeaderCheckbox]);

  useEffect(() => {
    if (dateParam) {
      setDate(dayjs(dateParam));
    }
  }, [dateParam]);

  useEffect(() => {
    daysRow.current?.parentElement?.scroll({ left: 0 });
  }, [date]);

  useEffect(() => {
    const copyOfData = graphicData?.getZayavkyByFacility ? [...graphicData?.getZayavkyByFacility] : [];
    const sortCopyOfData = copyOfData.sort((a, b) => a.number - b.number);
    setScheduleRequest(sortCopyOfData);
  }, [graphicData?.getZayavkyByFacility, setScheduleRequest]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <MainLayout title={title} bg="bg-smena_bb-background_base" headerMenu={headerMenu} breadCrumbs={breadCrumbs}>
        <div className="grid grid-cols-12 gap-6">
          <ScheduleHeader />
          <div className="xl:col-span-9 col-span-full p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-6">
            <div className="flex justify-between items-center">
              <h3 className="Subtitle1 text-smena_text-secondary">Графики смен</h3>
              <div className="flex gap-x-5 items-center">
                <div className="flex gap-x-6">
                  <SimpleSelect
                    divClassName="w-200px"
                    label=""
                    onChange={handleWorkerIdChange}
                    value={workerId}
                    name="workerId"
                    options={usersOptions}
                    allOption="Все исполнители"
                  />
                  <SimpleSelect
                    divClassName="w-200px"
                    label=""
                    onChange={handlePositionIdChange}
                    value={positionId}
                    name="groupId"
                    options={positionOptions}
                    allOption="Все профессии"
                  />
                </div>
                <RequestCalendarPicker date={date} setDate={setDate} params={params} setParams={setParams} />
              </div>
            </div>

            <ScrollSync>
              <div className="requestGraphic">
                <div className="border-r border-smena_gray-30 ">
                  <div className="border-b requestScheduleProfessionsRows sticky top-0 z-20 bg-smena_white">
                    <span className="Tag pl-1">Профессии</span>
                  </div>
                  <ScrollSyncPane>
                    <div className="requestScheduleProfessions scrollbar-hide">
                      {filterRequestWithSchedule?.map((requestSchedule, _, array) => (
                        <RequestProfession
                          key={requestSchedule?.id}
                          requestSchedule={requestSchedule}
                          allScheduleRequests={array}
                          setShowEditGraphicSidebar={setShowGraphicSidebar}
                        />
                      ))}
                    </div>
                  </ScrollSyncPane>
                </div>

                <div>
                  <ScrollSyncPane>
                    <div
                      className={clsx(
                        'gap-x-1 requestScheduleProfessionsRows border-b sticky top-0 z-20 bg-smena_white w-full overflow-x-auto scrollbar-hide'
                      )}
                      style={{
                        gridTemplateColumns: `repeat(${days.length}, 80px)`,
                      }}
                    >
                      {days.map(day => (
                        <div className="flex justify-center items-center Tag" key={day.valueOf()}>
                          <span
                            className={clsx(
                              'px-2',
                              day.format(RU_DATE) === dayjs().format(RU_DATE) &&
                                'bg-primary rounded-lg text-smena_white'
                            )}
                          >
                            {dayjs(day).format('D, dd')}
                          </span>
                        </div>
                      ))}
                    </div>
                  </ScrollSyncPane>
                  <ScrollSyncPane>
                    <div className="requestScheduleDates">
                      {filterRequestWithSchedule?.map(requestSchedule => (
                        <RequestProfessionDates
                          key={requestSchedule?.id}
                          requestSchedule={requestSchedule}
                          days={days}
                          setShowShiftSidebar={setShowShiftSidebar}
                        />
                      ))}
                    </div>
                  </ScrollSyncPane>
                </div>
              </div>
            </ScrollSync>
          </div>
        </div>
      </MainLayout>
      <ScheduleInfo showGraphicSidebar={showGraphicSidebar} setShowGraphicSidebar={setShowGraphicSidebar} />
      <WorkerToShift showShiftSidebar={showShiftSidebar} setShowShiftSidebar={setShowShiftSidebar} />
    </>
  );
};

export default GraphicPage;
