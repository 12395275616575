import moment from 'moment';
import { Link } from 'react-router-dom';

import { RegularLeadOutputFragment } from '../../generated/graphql';
import { useStore } from '../../store';
import Across from '../../Assets/icons/Across';

import Check from '../../Assets/icons/check';
import Edit from '../../Assets/icons/edit';
import NewSchedule from '../../Assets/icons/newSchedule';
import { UserFullName } from 'src/utils/userFullName';
import { RU_DATE } from '../../utils/constVariables';

interface TableInterface {
  users?: RegularLeadOutputFragment[];
  columns: {
    className?: string;
    style?: any;
  }[];
}

const LeadsTable = ({ users, columns }: TableInterface) => {
  const { setTypeSchedule } = useStore();

  const showShifts = (status: string) => {
    return status === 'ACCEPTED';
  };

  const leadStatus = (status: string) => {
    if (status === 'ACCEPTED') {
      return (
        <span className="bg-smena_green-mark rounded-full Table-small text-smena_white" style={{ padding: '2px 8px' }}>
          работает
        </span>
      );
    }
    if (status === 'REJECTED') {
      return (
        <span className="bg-smena_gray-30 rounded-full Table-small text-smena_gray-60" style={{ padding: '2px 8px' }}>
          отказ
        </span>
      );
    }
    if (status === 'PENDING') {
      return (
        <span
          className="bg-smena_bb-background rounded-full Table-small text-primary-dark"
          style={{ padding: '2px 8px' }}
        >
          в ожидании
        </span>
      );
    }
  };

  const scheduleHandler = () => setTypeSchedule(1);
  return (
    <>
      {users?.map(user => (
        <div
          key={user.id}
          className="grid table__row w-fit overflow-x-auto"
          style={{
            gridTemplateColumns: columns
              .map(column => 'minmax(' + column.style.minWidth + 'px, ' + column.style.width + 'px)')
              .join(' '),
            columnGap: 10,
          }}
        >
          <div className={columns[0].className}>{moment(user.createdAt).format(RU_DATE)}</div>
          <div className={columns[1].className}>{UserFullName(user.user?.referrer)}</div>
          <div className={columns[2].className}>{user.user?.referrer?.phone}</div>
          <div className={columns[3].className}>{UserFullName(user.user)}</div>
          <div className={columns[4].className}>{user.user?.phone}</div>
          <div className={columns[5].className}>
            {user.isProcessed ? (
              <span className="text-smena_green-dark">
                <Check />
              </span>
            ) : (
              <span>
                <Across color="text-smena_red" />
              </span>
            )}
          </div>
          <div className={columns[6].className}>{leadStatus(user.status)}</div>
          <div className={columns[7].className}>
            {showShifts(user.status) ? (
              <>
                <div className="">
                  <span className="Table-small text-smena_green-dark ">{user.countShiftsGood}/</span>
                  <span className="Table-small text-smena_text">{user.countShifts}</span>
                </div>
                <div>
                  <span className="Table-small text-smena_red underline">+ {user.countShiftsBad} плохих</span>
                </div>
              </>
            ) : null}
          </div>
          <div className={columns[8].className}>
            <div className="flex items-center">
              <Link to={`/leads/${user.id}`} className="text-smena_text mr-4 transform hover:scale-110">
                <Edit />
              </Link>
              {showShifts(user.status) ? (
                <Link to={`/users/${user.user?.referrer?.id}/schedule`} onClick={scheduleHandler}>
                  <NewSchedule />
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default LeadsTable;
