import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import ReactCodeInput from 'react-code-input';
import { useNavigate } from 'react-router-dom';

import { useGetAuthOptionMutation, useLoginByCodeMutation } from '../../generated/graphql';

import { setTokens } from '../../lib/auth';
import { useStore } from '../../store';
import { errorToast } from '../../utils/notify';
import { errorHandler } from '../../utils/errorHandler';
import { saveLoginData } from './__utils';

const CodeLogin = () => {
  const { phoneNumber } = useStore();

  const [code, setCode] = useState('');
  const [seconds, setSeconds] = useState<number>(60);

  const navigate = useNavigate();

  const [loginTestMutation] = useLoginByCodeMutation();
  const [getAuthOption] = useGetAuthOptionMutation();

  const codeHandler = (e: any) => {
    setCode(e);
  };

  let timer: NodeJS.Timeout;

  useEffect(() => {
    if (seconds > 0) {
      // eslint-disable-next-line
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    //eslint-disable-next-line
  }, [seconds]);

  const sendAgain = async () => {
    setSeconds(60);
    await getAuthOption({
      variables: {
        phone: phoneNumber || '',
      },
    }).catch(e => errorToast(errorHandler(e)));
  };

  return (
    <Formik
      initialValues={{ phoneNumber }}
      onSubmit={async values => {
        await loginTestMutation({
          variables: {
            input: {
              phone: values.phoneNumber || '',
              code,
            },
          },
        })
          .then(response => {
            clearTimeout(timer);
            if (response.data?.loginByCode) {
              const data = response.data?.loginByCode;
              saveLoginData({ phone: values.phoneNumber, code, role: data.user.role });
              setTokens({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
              });
              navigate('/');
            }
          })
          .catch(e => errorToast(errorHandler(e)));
      }}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} className="bg-white shadow w-80 rounded-lg p-5">
            <div className="mb-3">
              <label className="label-primary mb-2">Введите код из СМС</label>
              <ReactCodeInput
                type="number"
                name="code"
                inputMode="numeric"
                fields={4}
                className="codeInput"
                value={code}
                onChange={codeHandler}
              />
            </div>

            <button type="submit" disabled={code.length < 4} className="btn-primary_big w-full">
              {isSubmitting ? 'Загрузка' : 'Войти'}
            </button>
            <div className="mt-3">
              {seconds !== 0 && (
                <span className="font-semibold text-sm text-smena_gray-50 pb-1">
                  Запросить новый код через {seconds > 0 && seconds}
                </span>
              )}
              {seconds === 0 && (
                <span
                  className="cursor-pointer text-primary-light font-semibold text-sm hover:text-primary"
                  onClick={sendAgain}
                >
                  Запросить новый код
                </span>
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CodeLogin;
