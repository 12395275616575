import { useState } from 'react';
import { Formik } from 'formik';

import { useGetAuthOptionMutation } from '../../generated/graphql';

import { useStore } from '../../store';
import { errorHandler } from '../../utils/errorHandler';
import PhoneInput from '../../components/ui/InputMasks';
import { useNavigate } from 'react-router-dom';
import { errorToast } from '../../utils/notify';

interface Error {
  phone?: string;
}
interface setLoginPage {
  setLoginPage: (login: string) => void;
}

const Login = ({ setLoginPage }: setLoginPage) => {
  const [agree, setAgree] = useState(false);

  const { setPhoneNumber } = useStore();

  const navigate = useNavigate();

  const [getAuthOption] = useGetAuthOptionMutation();

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  return (
    <div className="bg-smena_white shadow-smena rounded-lg px-5 py-7">
      <Formik
        initialValues={{ phone: '' }}
        validate={values => {
          const errors: Error = {};
          if (!values.phone) {
            errors.phone = 'Обязательное поле';
          }
          return errors;
        }}
        onSubmit={async values => {
          await getAuthOption({
            variables: {
              phone: values.phone.replace(/[^0-9]/g, '').substring(1),
            },
          })
            .then(response => {
              if (
                response.data?.getAuthOption.applicationType === 'dashboard'
              ) {
                if (response.data.getAuthOption.loginMethod === 'password') {
                  setPhoneNumber(
                    values.phone.replace(/[^0-9]/g, '').substring(1)
                  );
                  setLoginPage('password');
                } else {
                  setPhoneNumber(
                    values.phone.replace(/[^0-9]/g, '').substring(1)
                  );
                  setLoginPage('code');
                }
              } else {
                navigate('/wrong-way');
              }
            })
            .catch(e => {
              errorToast(errorHandler(e));
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
              <div>
                <label className="label-primary">Телефон</label>
                <PhoneInput
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={touched.phone ? errors.phone : ''}
                  placeholder="Введите ваш телефон"
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="agree"
                  id="agree"
                  className="checkbox-primary"
                  onChange={checkboxHandler}
                  checked={agree}
                />
                <label
                  htmlFor="agree"
                  className="font-semibold text-sm text-smena_gray-60"
                >
                  Согласен с условием
                  <a
                    href="/offer"
                    className="font-semibold text-sm text-primary ml-1"
                    target="_blank"
                  >
                    оферты
                  </a>
                </label>
              </div>
              <button
                type="submit"
                disabled={!agree}
                className="btn-primary_big w-full"
              >
                {isSubmitting ? 'Загрузка' : 'Далее'}
              </button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Login;
