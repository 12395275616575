import { IZayavkaPlanInput } from 'src/Interfaces/IZayavkaPlanInput';

export const changeListOfPositions = (
  id: string,
  list: IZayavkaPlanInput[],
  changedEl?: IZayavkaPlanInput
) => {
  const findChangedElementIndex = list.findIndex(listEl => listEl.id === id);
  if (changedEl) {
    return list.splice(findChangedElementIndex, 1, changedEl);
  }
  return list.splice(findChangedElementIndex, 1);
};
