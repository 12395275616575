import { useMemo } from 'react';
import { roles } from 'src/utils/constVariables';
import { RegularUserShiftsFragment } from '../../../generated/graphql';
import { useStore } from '../../../store';
import { goodStages } from '../../../utils/lists/goodStages';
import { useMeRole } from '../../../hooks/useRole';

interface IUseGoodShifts {
  superFacilities?: any[];
  shiftsWithoutCanceled?: RegularUserShiftsFragment[];
  userShifts?: RegularUserShiftsFragment[];
}

const filterNotProbation = (shift: RegularUserShiftsFragment) => !shift.isProbation;

const useAcceptedShifts = ({ userShifts }: IUseGoodShifts) => {
  const { facilityId, workpostId, superShifts } = useStore();

  const superShiftsNotProbation = superShifts?.filter(filterNotProbation);

  const isMeSupervisor = useMeRole(roles.Supervisor);

  return useMemo(() => {
    const shifts = (isMeSupervisor ? superShiftsNotProbation : userShifts) || [];
    return shifts.filter(shift => {
      const isStageFits = goodStages.includes(shift.stage);
      const isPositionFits = workpostId ? shift.positionId === workpostId : true;
      const shiftFacilityId = shift.facility?.id;
      const isFacilityFits = facilityId ? shiftFacilityId === facilityId : true;

      return isStageFits && isPositionFits && isFacilityFits;
    });
  }, [facilityId, isMeSupervisor, superShiftsNotProbation, userShifts, workpostId]);
};

export default useAcceptedShifts;
