import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { RegularZayavkaScheduleFragment, ShiftStage } from 'src/generated/graphql';
import { roles } from 'src/utils/constVariables';
import { useRequestShift } from '../../hooks/useRequestShift';
import { useMeRole } from '../../../../hooks/useRole';
import { useStore } from '../../../../store';

interface IRequestProfessionDay {
  day: dayjs.Dayjs;
  requestSchedule: RegularZayavkaScheduleFragment;
}

const RequestProfessionDay: React.FC<IRequestProfessionDay> = ({ day, requestSchedule }) => {
  const {
    setShowEditRequestShiftSidebar,
    setShowCreateRequestShiftSidebar,
    setViewRequestShiftSidebar,
    setRequestShift,
    setRequestDayAtRow,
    setRequestSchedule,
  } = useStore();

  const dayRequestShift = useMemo(
    () =>
      requestSchedule.zayavkaShift?.find(shift => dayjs(shift.dateFrom).format('DD.MM.YY') === day.format('DD.MM.YY')),
    [day, requestSchedule.zayavkaShift]
  );

  const { requestShift, content, className } = useRequestShift({
    requestShift: dayRequestShift,
    day,
    requestScheduleId: requestSchedule.id,
  });

  const isMeSupervisor = useMeRole(roles.Supervisor);

  const onClick = useCallback(() => {
    setRequestSchedule(requestSchedule);
    setRequestDayAtRow({ day, positionId: requestSchedule.positionId, requestScheduleId: requestSchedule.id });
    if (requestShift) {
      setRequestShift(requestShift);
      if (requestShift.shift?.stage === ShiftStage.Accepted) {
        setViewRequestShiftSidebar(true);
      } else {
        setShowEditRequestShiftSidebar(true);
      }
    } else {
      if (!isMeSupervisor) {
        setShowCreateRequestShiftSidebar(true);
      }
    }
  }, [
    day,
    isMeSupervisor,
    requestSchedule,
    requestShift,
    setRequestDayAtRow,
    setRequestSchedule,
    setRequestShift,
    setShowCreateRequestShiftSidebar,
    setShowEditRequestShiftSidebar,
    setViewRequestShiftSidebar,
  ]);

  return (
    <button className={clsx('text-center rounded Tag', className)} type="button" onClick={onClick}>
      {content}
    </button>
  );
};

export default RequestProfessionDay;
