import dayjs from 'dayjs';
import { nanoid } from 'nanoid';

import { RichUserFragment } from 'src/generated/graphql';
import { ICalendarOnSelectSlotHandler } from 'src/Interfaces/ICalendarOnSelectSlotHandler';
import { ICustomEvent } from 'src/Interfaces/IEvent';
import { errorToast } from 'src/utils/notify';
import { shiftExist } from 'src/utils/scheduleFns';
import { setTime } from 'src/utils/scheduleTime';

interface IOnSelectSlotHandler extends ICalendarOnSelectSlotHandler {
  dateFrom: dayjs.Dayjs;
  dateTo: dayjs.Dayjs;
  userId: string;
  workpostId: string;
  mainEvents: ICustomEvent[];
  customScheduleEvents: ICustomEvent[];
  typeSchedule: number;
  scheduleEvent: ICustomEvent | null;
  addCustomScheduleEvents: (obj: ICustomEvent) => void;
  lunchDuration: number;
  showSelectedShiftsAtSidebarFn: (start: Date) => void;
  me?: RichUserFragment;
}

const onSelectSlotHandler = ({
  start,
  end,
  dateFrom,
  dateTo,
  userId,
  workpostId,
  mainEvents,
  customScheduleEvents,
  typeSchedule,
  scheduleEvent,
  me,
  addCustomScheduleEvents,
  lunchDuration,
  showSelectedShiftsAtSidebarFn,
}: IOnSelectSlotHandler) => {
  const startCellAndCurrentTime = dayjs(start)
    .set('hours', dayjs().get('hours'))
    .set('minutes', dayjs().get('minutes'));
  const sameDate = startCellAndCurrentTime.isSame(dayjs(scheduleEvent?.start), 'date');
  const term = sameDate
    ? dayjs(scheduleEvent?.start).isBefore(dayjs())
    : startCellAndCurrentTime.isBefore(dayjs(scheduleEvent?.start));
  if (typeSchedule === 2) {
    if (
      !dayjs(start).isSame(dayjs(end).subtract(1, 'day')) ||
      dayjs(start).isBefore(dayjs(dateFrom)) ||
      dayjs(start).isAfter(dayjs(dateTo)) ||
      term
    ) {
      return null;
    }
    if (scheduleEvent) {
      const {
        start: startEvent,
        end: endEvent,
        resource: { facility },
      } = scheduleEvent;

      const FullYearDate = new Date().setFullYear(
        start.getFullYear(),
        start.getMonth(),
        start.getDate()
      );

      const startDate = new Date(FullYearDate);
      const endDate = new Date(FullYearDate);

      setTime(startDate, startEvent);
      setTime(endDate, endEvent);

      const dateEndNextDay = dayjs(endDate)
        .set('date', end.getDate())
        .set('month', end.getMonth());

      const currentDayShift =
        startEvent.getHours() * 60 + startEvent.getMinutes() <
        endEvent.getHours() * 60 + endEvent.getMinutes();

      const newEvent: ICustomEvent = {
        ...scheduleEvent,
        title: `${dayjs(Number(startEvent)).format('HH:mm')} - ${dayjs(
          Number(endEvent)
        ).format('HH:mm')}`,
        start: startDate,
        end: endDate,
        resource: {
          id: nanoid(),
          userId,
          authorId: me?.id || '',
          dateFrom: String(Number(startDate)),
          dateEnd: currentDayShift
            ? String(Number(endDate))
            : String(Number(dateEndNextDay)),
          stage: 'CUSTOM',
          positionId: workpostId,
          facility: {
            id: facility?.id || '',
          },
          lunchDuration,
          isProbation: false,
        },
      };

      if (
        shiftExist(newEvent, customScheduleEvents).length ||
        shiftExist(newEvent, mainEvents).length
      ) {
        return errorToast('Нельзя ставить несколько смен на один день');
      }
      addCustomScheduleEvents(newEvent);
    } else {
      return errorToast('Сначала примените шаблон');
    }
  } else {
    showSelectedShiftsAtSidebarFn(start);
  }
};

export default onSelectSlotHandler;
