import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useFacilitiesPaginatedLazyQuery } from '../../../generated/graphql';

import MainLayout from '../../../components/Layout/main';
import { facilitiesGroupColumns } from '../../../components/Table/columns';
import TableHeader from '../../../components/Table/header';
import FacilitiesGroupFilter from '../../../components/Table/Filters/FacilitiesGroupFilter';
import SkeletonLayout from '../../../components/Table/Skeleton';
import FacilitiesGroupTable from '../../../components/Table/FacilitiesGroupTable';
import { useStore } from '../../../store';
import { activeHandler } from '../../../utils/isActiveHandler';
import { groupName } from '../../../utils/filters/filtersName';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { maxTableWidth } from 'src/utils/maxTableWidth';

const FacilitiesGroupPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [isActive, setIsActive] = useState<boolean | string>('');
  const [cityId, setCityId] = useState('');
  const [params] = useSearchParams();

  const { groups } = useStore();

  const router = useParams();
  const location = useLocation();

  let groupId = '';

  if (typeof router.groupId === 'string') {
    groupId = router.groupId;
  }

  const [loadData, { data: dataFacilities, loading: loadingFacilities }] =
    useFacilitiesPaginatedLazyQuery();

  const facilitiesPaginated = dataFacilities?.facilitiesPaginated;
  const facilities = facilitiesPaginated?.facilities;

  let searchParam = params.get('search');
  let activeParam = params.get('active');
  let cityParam = params.get('cityId');

  const mutationInput = {
    groupId,
    page: 1,
    cityId,
    isActive: activeHandler(isActive),
    searchQuery: search,
    sortBy,
    sortWay,
  };

  const returnData = () => {
    return loadData({
      variables: {
        input: {
          ...mutationInput,
        },
      },
    });
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (searchParam) {
      setSearch(searchParam);
    } else {
      setSearch('');
    }
    if (activeParam) {
      setIsActive(activeParam);
    } else {
      setIsActive('');
    }
    if (cityParam) {
      setCityId(cityParam);
    } else {
      setCityId('');
    }
    loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
          cityId: cityParam ? cityParam : '',
          isActive: activeParam
            ? activeHandler(activeParam)
            : activeHandler(''),
        },
      },
    });

    // eslint-disable-next-line
  }, [page, search, sortWay, sortBy, location]);

  return (
    <MainLayout
      title={`Объекты группы ${groupName(groups, groupId)}`}
      bg="bg-smena_bb-background_base"
      headerMenu={[
        { name: 'Информация', link: '/groups/' + groupId },
        {
          name: 'Объекты группы',
          link: location.pathname,
        },
      ]}
      breadCrumbs={[
        { path: '/groups', name: 'Группы' },
        { path: '/groups/' + groupId, name: 'Страница группы' },
      ]}
    >
      <div
        style={{
          maxWidth: maxTableWidth(facilitiesGroupColumns) + 'px',
        }}
      >
        <FacilitiesGroupFilter
          returnData={returnData}
          setSearch={setSearch}
          setPage={setPage}
          setIsActive={setIsActive}
          setCityId={setCityId}
          isActive={isActive}
          cityId={cityId}
        />
        <ScrollSync>
          <div className="flex flex-col shadow-smena rounded-lg">
            <TableHeader
              dataLength={facilities?.length}
              columns={facilitiesGroupColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />
            {loadingFacilities || !facilities ? (
              <SkeletonLayout columns={facilitiesGroupColumns} />
            ) : facilities.length > 0 ? (
              <ScrollSyncPane>
                <div className="main-table">
                  <FacilitiesGroupTable
                    facilities={facilities}
                    columns={facilitiesGroupColumns}
                  />
                </div>
              </ScrollSyncPane>
            ) : (
              <div className="w-full flex justify-center my-5">
                <h3 className="text-smena_text Body1">
                  По вашему запросу <span className="font-bold">{search}</span>{' '}
                  ничего не нашлось
                </h3>
              </div>
            )}
          </div>
        </ScrollSync>
        <div className="py-5">
          {facilitiesPaginated?.pages! > 1 ? (
            <Pagination
              shape="rounded"
              count={facilitiesPaginated ? facilitiesPaginated.pages : 10}
              page={page}
              onChange={handleChange}
            />
          ) : null}
        </div>
      </div>
    </MainLayout>
  );
};

export default FacilitiesGroupPage;
