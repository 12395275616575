import _ from 'lodash';
import moment, { Moment } from 'moment';
import { ICustomEvent } from 'src/Interfaces/IEvent';
import { IOption } from 'src/Interfaces/IOption';
import create, { StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  GetModerationReportQuery,
  RegularCityFragment,
  RegularCountryFragment,
  RegularDocumentFragment,
  RegularDocumentReviewsFragment,
  RegularFacilitiesFragment,
  RegularGroupFragment,
  RegularMedicalAttestationFragment,
  RegularModerationReportTableFragment,
  RegularModerationShiftFragment,
  RegularPositionFragment,
  RegularRequisiteFragment,
  RegularUserFragment,
  RegularUserIntersectFragment,
  RegularUserRatingFragment,
  RegularUserShiftsFragment,
  RegularZayavkaFragment,
  RegularZayavkaProgressFragment,
  RegularZayavkaScheduleFragment,
  RegularZayavkaShiftFragment,
  ResolveReportUserModel,
  RichUserFragment,
} from '../generated/graphql';

import { TRegularManualAccrual } from '../Interfaces/TRegularManualAccurl';
import dayjs from 'dayjs';

export type StoreSlice<T extends object> = (set: StoreApi<T>['setState'], get: StoreApi<T>['getState']) => T;

type ScheduleEvent = {
  scheduleEvent: ICustomEvent | null;
  setScheduleEvent: (value: ICustomEvent) => void;
};

const ScheduleEventSlice: StoreSlice<ScheduleEvent> = set => ({
  scheduleEvent: null,
  setScheduleEvent: scheduleEvent => set({ scheduleEvent }),
});

type Schedule = {
  typeSchedule: number;
  setTypeSchedule: (value: number) => void;
};

const ScheduleTypeSlice: StoreSlice<Schedule> = set => ({
  typeSchedule: 1,
  setTypeSchedule: typeSchedule => set({ typeSchedule }),
});

type CustomScheduleEvents = {
  customScheduleEvents: ICustomEvent[];
  addCustomScheduleEvents: (obj: ICustomEvent) => void;
  setCustomScheduleEvents: (customScheduleEvents: ICustomEvent[]) => void;
};

export const CustomScheduleEventsSlice: StoreSlice<CustomScheduleEvents> = set => ({
  customScheduleEvents: [],
  addCustomScheduleEvents: obj =>
    set(state => ({
      customScheduleEvents: [...state.customScheduleEvents, obj],
    })),
  setCustomScheduleEvents: (customScheduleEvents: ICustomEvent[] | []) => set({ customScheduleEvents }),
});

type LunchDuration = {
  lunchDuration: number;
  setLunchDuration: (lunchDuration: number) => void;
};

export const LunchDurationSlice: StoreSlice<LunchDuration> = set => ({
  lunchDuration: 0,
  setLunchDuration: lunchDuration => set({ lunchDuration }),
});

type ScheduleTime = {
  startScheduleTime: Moment | null;
  setStartScheduleTime: (setStartScheduleTime: Moment | null) => void;
  endScheduleTime: Moment | null;
  setEndScheduleTime: (endScheduleTime: Moment | null) => void;
};

export const ScheduleTimeSlice: StoreSlice<ScheduleTime> = set => ({
  startScheduleTime: moment(),
  setStartScheduleTime: startScheduleTime => set({ startScheduleTime }),
  endScheduleTime: moment(),
  setEndScheduleTime: endScheduleTime => set({ endScheduleTime }),
});

type FacilityId = {
  facilityId: string;
  setFacilityId: (facilityId: string) => void;
  facilityIdError: boolean;
  setFacilityIdError: (facilityIdError: boolean) => void;
};

export const FacilityIdSlice: StoreSlice<FacilityId> = set => ({
  facilityId: '',
  setFacilityId: facilityId => set({ facilityId }),
  facilityIdError: false,
  setFacilityIdError: facilityIdError => set({ facilityIdError }),
});

type WorkpostId = {
  workpostId: string;
  setWorkpostId: (workpost: string) => void;
  workpostIdError: boolean;
  setWorkpostIdError: (workpostError: boolean) => void;
};

export const WorkpostIdSlice: StoreSlice<WorkpostId> = set => ({
  workpostId: '',
  setWorkpostId: workpostId => set({ workpostId }),
  workpostIdError: false,
  setWorkpostIdError: workpostIdError => set({ workpostIdError }),
});

type HiddenCanceledShifts = {
  hiddenCanceledShifts: boolean;
  setHiddenCanceledShifts: (hiddenCanceledShifts: boolean) => void;
};

export const HiddenCanceledShiftsSlice: StoreSlice<HiddenCanceledShifts> = set => ({
  hiddenCanceledShifts: false,
  setHiddenCanceledShifts: hiddenCanceledShifts => set({ hiddenCanceledShifts }),
});

type PaymentsCheckboxes = {
  paymentCheckbox: boolean;
  paymentCheckboxes: number;
  setPaymentCheckbox: (paymentCheckbox: boolean) => void;
  setPaymentCheckboxes: (paymentCheckboxes: number) => void;
};

export const PaymentsCheckboxesSlice: StoreSlice<PaymentsCheckboxes> = set => ({
  paymentCheckbox: true,
  paymentCheckboxes: 0,
  setPaymentCheckbox: paymentCheckbox => set({ paymentCheckbox }),
  setPaymentCheckboxes: paymentCheckboxes => set({ paymentCheckboxes }),
});

type PhoneNumber = {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
};

export const PhoneNumberSlice: StoreSlice<PhoneNumber> = set => ({
  phoneNumber: '',
  setPhoneNumber: phoneNumber => set({ phoneNumber }),
});

type MeShortly = {
  me?: RichUserFragment;
  setMe: (me?: RichUserFragment) => void;
};

export const MeShortlySlice: StoreSlice<MeShortly> = set => ({
  me: undefined,
  setMe: me => set({ me }),
});

type CurrentUser = {
  currentUser?: RegularUserRatingFragment;
  setCurrentUser: (currentUser?: RegularUserRatingFragment) => void;
  isUserLoading: boolean;
  setIsUserLoading: (isLoading: boolean) => void;
};

export const UserTypeSlice: StoreSlice<CurrentUser> = set => ({
  currentUser: undefined,
  setCurrentUser: currentUser => set({ currentUser }),
  isUserLoading: true,
  setIsUserLoading: isUserLoading => set({ isUserLoading }),
});

type ManualAccural = {
  manualAccural?: TRegularManualAccrual;
  setManualAccural: (data?: TRegularManualAccrual) => void;
};

export const ManualAccuralSlice: StoreSlice<ManualAccural> = set => ({
  manualAccural: undefined,
  setManualAccural: manualAccural => set({ manualAccural }),
});

type ManualAccuralUserIds = {
  manualAccuralUserIds: string[];
  setManualAccuralUserIds: (manualAccuralUserIds: string[]) => void;
};

export const ManualAccuralUserIdsSlice: StoreSlice<ManualAccuralUserIds> = set => ({
  manualAccuralUserIds: [],
  setManualAccuralUserIds: (manualAccuralUserIds: string[]) => set({ manualAccuralUserIds }),
});

type DisabledUsersIds = {
  disabledUsersIds: string[];
  setDisabledUsersIds: (disabledUsersIds: string[]) => void;
};

export const DisabledUsersIdsSlice: StoreSlice<DisabledUsersIds> = set => ({
  disabledUsersIds: [],
  setDisabledUsersIds: (disabledUsersIds: string[]) => set({ disabledUsersIds }),
});

type UserShifts = {
  selectedShifts?: ICustomEvent[];
  setSelectedShifts: (selectedShifts?: ICustomEvent[]) => void;
  selectedSchedule?: ICustomEvent[];
  setSelectedSchedule: (selectedSchedule?: ICustomEvent[]) => void;
};

export const UserShiftsSlice: StoreSlice<UserShifts> = set => ({
  selectedShifts: undefined,
  setSelectedShifts: selectedShifts => set({ selectedShifts }),
  selectedSchedule: undefined,
  setSelectedSchedule: selectedSchedule => set({ selectedSchedule }),
});

type Super = {
  superShifts?: RegularUserShiftsFragment[];
  setSuperShifts: (superShifts?: RegularUserShiftsFragment[]) => void;
  nonSuperShifts?: RegularUserShiftsFragment[];
  setNonSuperShifts: (nonSuperShifts?: RegularUserShiftsFragment[]) => void;
};

export const SuperSlice: StoreSlice<Super> = set => ({
  superShifts: undefined,
  setSuperShifts: superShifts => set({ superShifts }),
  nonSuperShifts: undefined,
  setNonSuperShifts: nonSuperShifts => set({ nonSuperShifts }),
});

type ShowSidebar = {
  showSidebar: boolean;
  setShowSidebar: (sidebar: boolean) => void;
};

export const ShowSidebarSlice: StoreSlice<ShowSidebar> = set => ({
  showSidebar: false,
  setShowSidebar: showSidebar => set({ showSidebar }),
});

type UploadFile = {
  files: FileList[] | Blob[] | [];
  setFiles: (file: FileList[] | Blob[] | []) => void;
};

export const UploadFileSlice: StoreSlice<UploadFile> = set => ({
  files: [],
  setFiles: files => set({ files }),
});

type Menu = {
  menu: boolean;
  setMenu: (menu: boolean) => void;
  hover: boolean;
  setHover: (menu: boolean) => void;
  subMenuActive: boolean;
  setSubMenuActive: (subMenuActive: boolean) => void;
  tooltip: boolean;
  setTooltip: (tooltip: boolean) => void;
};

export const MenuSlice: StoreSlice<Menu> = set => ({
  menu: false,
  setMenu: menu => set({ menu }),
  hover: false,
  setHover: hover => set({ hover }),
  subMenuActive: false,
  setSubMenuActive: subMenuActive => set({ subMenuActive }),
  tooltip: false,
  setTooltip: tooltip => set({ tooltip }),
});

type CreateScheduleSidebar = {
  createScheduleMenu: boolean;
  setCreateScheduleMenu: (menu: boolean) => void;
};

export const CreateScheduleSidebarSlice: StoreSlice<CreateScheduleSidebar> = set => ({
  createScheduleMenu: false,
  setCreateScheduleMenu: createScheduleMenu => set({ createScheduleMenu }),
});

type Countries = {
  countries?: RegularCountryFragment[];
  countriesLoading: boolean;
  setCountries: (countries?: RegularCountryFragment[]) => void;
  setCountriesLoading: (countriesLoading: boolean) => void;
};

export const CountriesSlice: StoreSlice<Countries> = set => ({
  countries: undefined,
  countriesLoading: true,
  setCountries: countries => set({ countries }),
  setCountriesLoading: countriesLoading => set({ countriesLoading }),
});

type Cities = {
  cities?: RegularCityFragment[];
  citiesLoading: boolean;
  setCities: (groups?: RegularCityFragment[]) => void;
  setCitiesLoading: (citiesLoading: boolean) => void;
};

export const CitiesSlice: StoreSlice<Cities> = set => ({
  cities: undefined,
  citiesLoading: true,
  setCities: cities => set({ cities }),
  setCitiesLoading: citiesLoading => set({ citiesLoading }),
});

type FacilityGroups = {
  groups?: RegularGroupFragment[];
  groupsLoading: boolean;
  setGroups: (groups?: RegularGroupFragment[]) => void;
  setGroupsLoading: (groupsLoading: boolean) => void;
};

export const FacilityGroupsSlice: StoreSlice<FacilityGroups> = set => ({
  groups: undefined,
  groupsLoading: true,
  setGroups: groups => set({ groups }),
  setGroupsLoading: groupsLoading => set({ groupsLoading }),
});

type Facilities = {
  facilities?: RegularFacilitiesFragment[];
  facilitiesLoading: boolean;
  setFacilities: (facilities?: RegularFacilitiesFragment[]) => void;
  setFacilitiesLoading: (facilitiesLoading: boolean) => void;
};

export const FacilitiesSlice: StoreSlice<Facilities> = set => ({
  facilities: undefined,
  facilitiesLoading: true,
  setFacilities: facilities => set({ facilities }),
  setFacilitiesLoading: facilitiesLoading => set({ facilitiesLoading }),
});

type Positions = {
  positions?: RegularPositionFragment[];
  positionsLoading: boolean;
  setPositions: (positions?: RegularPositionFragment[]) => void;
  setPositionsLoading: (positionsLoading: boolean) => void;
};

export const PositionsSlice: StoreSlice<Positions> = set => ({
  positions: undefined,
  positionsLoading: true,
  setPositions: positions => set({ positions }),
  setPositionsLoading: positionsLoading => set({ positionsLoading }),
});

type PeriodDays = {
  days?: Moment[];
  setDays: (days?: Moment[]) => void;
};

export const PeriodDaysSlice: StoreSlice<PeriodDays> = set => ({
  days: undefined,
  setDays: days => set({ days }),
});

type PeriodShiftsInfo = {
  info?: {
    dayNum?: any;
    shifts?: RegularModerationShiftFragment[];
  };
  userInfo?: {
    userFio: { id?: string; firstname?: string; lastname?: string };
    position?: { id?: string; name?: string };
  };
  setPeriodShiftsInfo: (
    info: {
      dayNum: any;
      shifts: RegularModerationShiftFragment[];
    },
    userInfo: {
      userFio: { id?: string; firstname?: string; lastname?: string };
      position?: { id?: string; name?: string };
    }
  ) => void;
};

export const PeriodShiftsInfoSlice: StoreSlice<PeriodShiftsInfo> = set => ({
  info: undefined,
  userInfo: undefined,
  setPeriodShiftsInfo: (info, userInfo) => set({ info, userInfo }),
});

type GetModerationReport = {
  moderationReport?: GetModerationReportQuery;
  setModerationReport: (moderationReport?: GetModerationReportQuery) => void;
};

export const GetModerationReportSlice: StoreSlice<GetModerationReport> = set => ({
  moderationReport: undefined,
  setModerationReport: moderationReport => set({ moderationReport }),
});

type ModerationReports = {
  moderationReports: string[];
  setModerationReports: (shift: string) => void;
  setModerationReportsInWork: (shift: string[]) => void;
  moderationReportsHeaderCheckbox: boolean;
  setModerationReportsHeaderCheckbox: (moderationsReportsHeaderCheckbox: boolean) => void;
};

export const ModerationReportsSlice: StoreSlice<ModerationReports> = set => ({
  moderationReports: [],
  setModerationReports: shift =>
    set(state => {
      if (state.moderationReports.find(el => el === shift)) {
        return {
          moderationReports: [...state.moderationReports.filter(el => el !== shift)],
        };
      } else {
        return {
          moderationReports: [...state.moderationReports, shift],
        };
      }
    }),
  setModerationReportsInWork: shifts => set({ moderationReports: shifts }),
  moderationReportsHeaderCheckbox: false,
  setModerationReportsHeaderCheckbox: moderationReportsHeaderCheckbox => set({ moderationReportsHeaderCheckbox }),
});

type SplitButton = {
  selectedIndex: number;
  setSelectedIndex: (selectedIndex: number) => void;
};

export const SplitButtonSlice: StoreSlice<SplitButton> = set => ({
  selectedIndex: 0,
  setSelectedIndex: selectedIndex => set({ selectedIndex }),
});

type UpdateModerationReports = {
  updateModerationsReports?: RegularModerationReportTableFragment[];
  setUpdateModerationsReports: (row: RegularModerationReportTableFragment) => void;
  setUpdateModerationsReportsToModeration: (rows?: RegularModerationReportTableFragment[]) => void;
  updateModerationReportHeaderCheckbox: boolean;
  setUpdateModerationReportHeaderCheckbox: (moderationsReportsHeaderCheckbox: boolean) => void;
};

export const UpdateModerationReportsSlice: StoreSlice<UpdateModerationReports> = set => ({
  updateModerationsReports: [],
  setUpdateModerationsReports: row =>
    set(state => {
      if (state.updateModerationsReports) {
        if (state.updateModerationsReports?.find(el => _.isEqual(row, el))) {
          return {
            updateModerationsReports: [
              ...state.updateModerationsReports.filter(el => {
                return !_.isEqual(el, row);
              }),
            ],
          };
        } else {
          return {
            updateModerationsReports: [...state.updateModerationsReports, row],
          };
        }
      } else {
        return { updateModerationsReports: [] };
      }
    }),
  setUpdateModerationsReportsToModeration: rows => set({ updateModerationsReports: rows }),
  updateModerationReportHeaderCheckbox: false,
  setUpdateModerationReportHeaderCheckbox: updateModerationReportHeaderCheckbox =>
    set({ updateModerationReportHeaderCheckbox }),
});

type SetCurrentModerationRow = {
  currentModerationRow?: RegularModerationReportTableFragment;
  setCurrentModerationRow: (currentModerationRow: RegularModerationReportTableFragment) => void;
};

export const SetCurrentModerationRowSlice: StoreSlice<SetCurrentModerationRow> = set => ({
  currentModerationRow: undefined,
  setCurrentModerationRow: currentModerationRow => set({ currentModerationRow }),
});

type AddUser = {
  addedusers: RegularModerationReportTableFragment[];
  addUser: (user: RegularModerationReportTableFragment) => void;
};

export const AddUserSlice: StoreSlice<AddUser> = set => ({
  addedusers: [],
  addUser: user =>
    set(state => ({
      addedusers: [...state.addedusers, user],
    })),
});

type UserDocuments = {
  userDocuments?: RegularDocumentFragment[];
  userReviews?: RegularDocumentReviewsFragment[];
  userMedicalAttestation?: RegularMedicalAttestationFragment[];
  userRequisites?: RegularRequisiteFragment | null;
  setUserDocuments: (userDocuments?: RegularDocumentFragment[]) => void;
  setUserReviews: (userReviews?: RegularDocumentReviewsFragment[]) => void;
  setMedicalAttestation: (userMedicalAttestation?: RegularMedicalAttestationFragment[]) => void;
  setUserRequisites: (userRequisites?: RegularRequisiteFragment | null) => void;
  setDocument: (userDocument: RegularDocumentFragment) => void;
  setUserReview: (userReviews: RegularDocumentReviewsFragment) => void;
  removeDocument: (type: string) => void;
};

const UserDocumentsSlice: StoreSlice<UserDocuments> = set => ({
  userDocuments: undefined,
  userReviews: undefined,
  userMedicalAttestation: undefined,
  userRequisites: undefined,
  setUserDocuments: userDocuments => set({ userDocuments }),
  setUserReviews: userReviews => set({ userReviews }),
  setMedicalAttestation: userMedicalAttestation => set({ userMedicalAttestation }),
  setUserRequisites: userRequisites => set({ userRequisites }),
  setDocument: userDocument =>
    set(state => {
      if (!state.userDocuments) return state;
      return {
        userDocuments: [...state.userDocuments?.filter(document => document.type !== userDocument.type), userDocument],
      };
    }),
  setUserReview: userReview =>
    set(state => {
      if (!state.userReviews) return state;
      return {
        userReviews: [...state.userReviews?.filter(review => review.type !== userReview.type), userReview],
      };
    }),
  removeDocument: type =>
    set(state => {
      if (!state.userDocuments) return state;
      return {
        userDocuments: [...state.userDocuments?.filter(document => document.type !== type)],
      };
    }),
});

type TrendsFacilityAndGroup = {
  trendsFacilityId: string;
  trendsGroupId: string;
  setTrendsFacilityId: (trendsFacilityId?: string) => void;
  setTrendsGroupId: (trendsGroupId?: string) => void;
};

const TrendsFacilityAndGroupSlice: StoreSlice<TrendsFacilityAndGroup> = set => ({
  trendsFacilityId: 'all',
  trendsGroupId: 'all',
  setTrendsFacilityId: trendsFacilityId => set({ trendsFacilityId }),
  setTrendsGroupId: trendsGroupId => set({ trendsGroupId }),
});

type GetUsersByFacilityAndPosition = {
  usersByFacilityAndPosition?: RegularUserFragment[];
  setUsersByFacilityAndPosition: (usersByFacilityAndPosition?: RegularUserFragment[]) => void;
};

const GetUsersByFacilityAndPositionSlice: StoreSlice<GetUsersByFacilityAndPosition> = set => ({
  usersByFacilityAndPosition: undefined,
  setUsersByFacilityAndPosition: usersByFacilityAndPosition => set({ usersByFacilityAndPosition }),
});

type ResolveModerationData = {
  resolveModerationData?: ResolveReportUserModel[];
  resolveModerationPosition: string[];
  setResolveModerationData: (resolveModerationData?: ResolveReportUserModel[]) => void;
  setResolveModerationPosition: (resolveModerationPosition?: string[]) => void;
  setResolveModerationDataElement: (resolveModerationDataElement: ResolveReportUserModel) => void;
};

const ResolveModerationDataSlice: StoreSlice<ResolveModerationData> = set => ({
  resolveModerationData: undefined,
  resolveModerationPosition: [],
  setResolveModerationData: resolveModerationData => set({ resolveModerationData }),
  setResolveModerationPosition: resolveModerationPosition => set({ resolveModerationPosition }),
  setResolveModerationDataElement: resolveModerationDataElement =>
    set(state => {
      if (!state.resolveModerationData) return state;
      // return {
      //   resolveModerationData: [
      //     ...state.resolveModerationData.filter(
      //       el => el.id !== resolveModerationDataElement.id
      //     ),
      //     resolveModerationDataElement,
      //   ],
      // };
      const copyArr = [...state.resolveModerationData];
      const elIndex = state.resolveModerationData.findIndex(el => el.id === resolveModerationDataElement.id);
      copyArr.splice(elIndex, 1, resolveModerationDataElement);

      return { resolveModerationData: copyArr };
    }),
});

interface IRequestDayAtRow {
  day: dayjs.Dayjs;
  positionId: string;
  requestScheduleId: string;
}

type RequestData = {
  request?: RegularZayavkaProgressFragment;
  setRequest: (request?: RegularZayavkaProgressFragment) => void;
  requestSchedule?: RegularZayavkaScheduleFragment | null;
  setRequestSchedule: (requestSchedule?: RegularZayavkaScheduleFragment | null) => void;
  showEditRequestShift: boolean;
  setShowEditRequestShiftSidebar: (showEditRequestShift: boolean) => void;
  showCreateRequestShiftSidebar: boolean;
  setShowCreateRequestShiftSidebar: (showCreateRequestShiftSidebar: boolean) => void;
  showViewRequestShiftSidebar: boolean;
  setViewRequestShiftSidebar: (showViewRequestShiftSidebar: boolean) => void;
  requestShift?: RegularZayavkaShiftFragment | null;
  setRequestShift: (requestShift?: RegularZayavkaShiftFragment | null) => void;
  requestDayAtRow?: IRequestDayAtRow;
  setRequestDayAtRow: (cell?: IRequestDayAtRow) => void;
};

const RequestDataSlice: StoreSlice<RequestData> = set => ({
  request: undefined,
  setRequest: request => set({ request }),
  requestSchedule: undefined,
  setRequestSchedule: requestSchedule => set({ requestSchedule }),
  showEditRequestShift: false,
  setShowEditRequestShiftSidebar: showEditRequestShift => set({ showEditRequestShift }),
  showCreateRequestShiftSidebar: false,
  setShowCreateRequestShiftSidebar: showCreateRequestShiftSidebar => set({ showCreateRequestShiftSidebar }),
  showViewRequestShiftSidebar: false,
  setViewRequestShiftSidebar: showViewRequestShiftSidebar => set({ showViewRequestShiftSidebar }),
  requestShift: undefined,
  setRequestShift: requestShift => set({ requestShift }),
  requestDayAtRow: undefined,
  setRequestDayAtRow: requestDayAtRow => set({ requestDayAtRow }),
});

type ScheduleRequestData = {
  scheduleRequests?: RegularZayavkaFragment[];
  setScheduleRequest: (scheduleRequest?: RegularZayavkaFragment[]) => void;
  userIntersect?: RegularUserIntersectFragment;
  setUserIntersect: (userIntersect?: RegularUserIntersectFragment) => void;
  scheduleRequestsHeaderCheckbox: boolean;
  setScheduleRequestsHeaderCheckbox: (scheduleRequestsHeaderCheckbox: boolean) => void;
  scheduleRequestsIds: string[];
  setScheduleRequestsIds: (ids: string[]) => void;
  editScheduleRequestsIds: (ids: string[]) => void;
  graphicPositions: IOption[];
  setGraphicPositions: (graphicPositions: IOption[]) => void;
};

const ScheduleRequestDataSlice: StoreSlice<ScheduleRequestData> = set => ({
  scheduleRequests: undefined,
  setScheduleRequest: scheduleRequest => set({ scheduleRequests: scheduleRequest }),
  userIntersect: undefined,
  setUserIntersect: userIntersect => set({ userIntersect }),
  scheduleRequestsHeaderCheckbox: false,
  setScheduleRequestsHeaderCheckbox: scheduleRequestsHeaderCheckbox => set({ scheduleRequestsHeaderCheckbox }),
  scheduleRequestsIds: [],
  setScheduleRequestsIds: scheduleRequestsIds => set({ scheduleRequestsIds }),
  editScheduleRequestsIds: scheduleRequestsIds =>
    set(state => {
      const finalArr = scheduleRequestsIds.map(scheduleRequestsId => {
        if (state.scheduleRequestsIds.includes(scheduleRequestsId)) {
          return state.scheduleRequestsIds.filter(id => id !== scheduleRequestsId);
        }
        return [...state.scheduleRequestsIds, scheduleRequestsId];
      });
      return { scheduleRequestsIds: [...new Set(finalArr.flat())] };
    }),
  graphicPositions: [],
  setGraphicPositions: graphicPositions => set({ graphicPositions }),
});

type EditSchedule = {
  scheduleId: string;
  setScheduleId: (scheduleId: string) => void;
  shiftId: string;
  setShiftId: (shiftId: string) => void;
};

const EditScheduleSlice: StoreSlice<EditSchedule> = set => ({
  scheduleId: '',
  setScheduleId: scheduleId => set({ scheduleId }),
  shiftId: '',
  setShiftId: shiftId => set({ shiftId }),
});

//map
type System = {
  system: string;
  setSystem: (system: string) => void;
  cluster: boolean;
  setCluster: (cluster: boolean) => void;
  profession: string;
  setProfession: (profession: string) => void;
};

export const SystemSlice: StoreSlice<System> = set => ({
  system: '',
  setSystem: system => set({ system }),
  cluster: false,
  setCluster: cluster => set({ cluster }),
  profession: '',
  setProfession: profession => set({ profession }),
});

const createRootSlice = (set: StoreApi<any>['setState'], get: StoreApi<any>['getState']) => ({
  ...ScheduleEventSlice(set, get),
  ...ScheduleTypeSlice(set, get),
  ...CustomScheduleEventsSlice(set, get),
  ...LunchDurationSlice(set, get),
  ...ScheduleTimeSlice(set, get),
  // ...ShiftsSlice(set, get),
  ...FacilityIdSlice(set, get),
  ...WorkpostIdSlice(set, get),
  ...HiddenCanceledShiftsSlice(set, get),
  ...PaymentsCheckboxesSlice(set, get),
  ...PhoneNumberSlice(set, get),
  ...MeShortlySlice(set, get),
  ...UserTypeSlice(set, get),
  ...ManualAccuralSlice(set, get),
  ...ManualAccuralUserIdsSlice(set, get),
  ...DisabledUsersIdsSlice(set, get),
  ...UserShiftsSlice(set, get),
  ...SuperSlice(set, get),
  ...ShowSidebarSlice(set, get),
  ...UploadFileSlice(set, get),
  ...MenuSlice(set, get),
  ...CreateScheduleSidebarSlice(set, get),
  ...CountriesSlice(set, get),
  ...CitiesSlice(set, get),
  ...FacilityGroupsSlice(set, get),
  ...FacilitiesSlice(set, get),
  ...PositionsSlice(set, get),
  ...PeriodDaysSlice(set, get),
  ...PeriodShiftsInfoSlice(set, get),
  ...GetModerationReportSlice(set, get),
  ...ModerationReportsSlice(set, get),
  ...SplitButtonSlice(set, get),
  ...UpdateModerationReportsSlice(set, get),
  ...SetCurrentModerationRowSlice(set, get),
  ...AddUserSlice(set, get),
  ...UserDocumentsSlice(set, get),
  ...TrendsFacilityAndGroupSlice(set, get),
  ...GetUsersByFacilityAndPositionSlice(set, get),
  ...ResolveModerationDataSlice(set, get),
  ...RequestDataSlice(set, get),
  ...ScheduleRequestDataSlice(set, get),
  ...EditScheduleSlice(set, get),
  ...SystemSlice(set, get),
});

type State = ScheduleEvent &
  Schedule &
  CustomScheduleEvents &
  LunchDuration &
  ScheduleTime &
  // Shifts &
  FacilityId &
  WorkpostId &
  HiddenCanceledShifts &
  PaymentsCheckboxes &
  PhoneNumber &
  MeShortly &
  CurrentUser &
  ManualAccural &
  ManualAccuralUserIds &
  DisabledUsersIds &
  UserShifts &
  Super &
  ShowSidebar &
  UploadFile &
  Menu &
  CreateScheduleSidebar &
  Countries &
  Cities &
  FacilityGroups &
  Facilities &
  Positions &
  PeriodDays &
  PeriodShiftsInfo &
  GetModerationReport &
  ModerationReports &
  SplitButton &
  UpdateModerationReports &
  SetCurrentModerationRow &
  AddUser &
  UserDocuments &
  TrendsFacilityAndGroup &
  GetUsersByFacilityAndPosition &
  ResolveModerationData &
  RequestData &
  ScheduleRequestData &
  EditSchedule &
  System;

export const useStore = create<State>()(devtools(createRootSlice, { name: 'dashboard-store' }));
