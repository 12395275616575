import dayjs from 'dayjs';
import { Moment } from 'moment';
import * as React from 'react';
import { ConfigProvider, TimePicker } from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import clsx from 'clsx';

import { ErrorNotification } from '../ErrorNotification';
import TimePickerClock from '../../../Assets/icons/TimePickerClock';
import { useCallback } from 'react';

interface Props {
  value: Moment | null;
  onChange: (time: Moment | null) => void;
  format?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  popupClassName?: string;
  minuteStep?: number;
  error?: {
    bool?: boolean;
    text?: string;
  };
}

const TimePickerInput: React.FC<Props> = ({
  onChange,
  value,
  placeholder = dayjs().format('HH:mm'),
  error,
  className,
  popupClassName,
  label,
  disabled,
  format = 'HH:mm',
  minuteStep = 5,
}) => {
  const onTimePickerChange = useCallback(
    (time: moment.Moment | null) => {
      onChange(time);
    },
    [onChange]
  );
  return (
    <div className={className}>
      {Boolean(label) && (
        <label htmlFor="date" className="label-primary">
          {label}
        </label>
      )}
      <ConfigProvider locale={locale}>
        <TimePicker
          onChange={onTimePickerChange}
          format={format}
          value={value}
          disabled={disabled}
          minuteStep={minuteStep}
          className={clsx('custom__picker w-full', error?.bool ? 'border-smena_red' : 'border-smena_gray-40')}
          suffixIcon={<TimePickerClock />}
          placeholder={placeholder}
          popupClassName={popupClassName}
          showNow={false}
          onSelect={onChange}
        />
      </ConfigProvider>
      {error?.bool && <ErrorNotification text={error.text} />}
    </div>
  );
};

export default TimePickerInput;
