import clsx from 'clsx';
import { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import { useStore } from '../../../store';
import { getPaySystem } from '../../../utils/get/getPaySystem';
import { ErrorNotification } from '../ErrorNotification';

type InputProps = {
  name: string;
  onChange: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  onBlur?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  value: string | undefined;
  placeholder: string;
  className?: string;
  error?: string | boolean;
  showTextRole?: string[];
};
const digit = /[0-9]/;

const PhoneInput = ({
  name,
  onChange,
  onBlur,
  value,
  placeholder,
  error,
  showTextRole,
}: InputProps) => {
  const { me } = useStore();
  return (
    <>
      {me && showTextRole?.includes(me.role) ? (
        value ? (
          <span className="Body1">{value}</span>
        ) : null
      ) : (
        <>
          <InputMask
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            mask="+7 (999) 999-99-99"
            maskPlaceholder=""
            className={clsx(error ? 'input-error' : 'input-primary')}
          >
            <input type="tel" />
          </InputMask>
          {error && <ErrorNotification text={String(error)} />}
        </>
      )}
    </>
  );
};

const innMask = [
  digit,
  digit,
  digit,
  ' ',
  digit,
  digit,
  digit,
  ' ',
  digit,
  digit,
  digit,
  ' ',
  digit,
  digit,
  digit,
];

export const ItnInput = ({
  name,
  onChange,
  onBlur,
  value,
  placeholder,
  error,
  showTextRole,
}: InputProps) => {
  const { me } = useStore();
  return (
    <>
      {me && showTextRole?.includes(me.role) ? (
        value ? (
          <span>{value}</span>
        ) : null
      ) : (
        <>
          <InputMask
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            maskPlaceholder=""
            mask={innMask}
            className={clsx(error ? 'input-error' : 'input-primary')}
          >
            <input type="text" />
          </InputMask>
          {error && <ErrorNotification text={String(error)} />}
        </>
      )}
    </>
  );
};

const cardNumberMask = [
  digit,
  digit,
  digit,
  digit,
  ' ',
  digit,
  digit,
  digit,
  digit,
  ' ',
  digit,
  digit,
  digit,
  digit,
  ' ',
  digit,
  digit,
  digit,
  digit,
];

export const CardNumberInput = ({
  name,
  onChange,
  onBlur,
  value,
  placeholder,
  error,
  showTextRole,
}: InputProps) => {
  const { me } = useStore();
  return (
    <>
      {me && showTextRole?.includes(me.role) ? (
        value ? (
          <>
            <span>{value}</span>
            <span className="absolute top-3 right-2 transform -translate-y-1/2">
              {value && getPaySystem(value)}
            </span>
          </>
        ) : null
      ) : (
        <>
          <InputMask
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            maskPlaceholder=""
            mask={cardNumberMask}
            className={clsx(
              error ? 'border border-smena_red' : 'border-0',
              'cardInputData w-full'
            )}
          >
            <input type="text" />
          </InputMask>
          {error && <ErrorNotification text={String(error)} />}
          <span className="absolute top-4 right-2 transform -translate-y-1/2">
            {value && getPaySystem(value)}
          </span>
        </>
      )}
    </>
  );
};

const cardDateMask = [digit, digit, '/', digit, digit];

export const CardDateInput = ({
  name,
  onChange,
  onBlur,
  value,
  placeholder,
  error,
  showTextRole,
}: InputProps) => {
  const { me } = useStore();
  return (
    <>
      {me && showTextRole?.includes(me.role) ? (
        value ? (
          <span>{String(value)}</span>
        ) : null
      ) : (
        <>
          <InputMask
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            maskPlaceholder=""
            mask={cardDateMask}
            className={clsx(
              error ? 'border border-smena_red' : 'border-0',
              'cardInputData w-24'
            )}
          >
            <input type="text" />
          </InputMask>
          {error && <ErrorNotification text={String(error)} />}
        </>
      )}
    </>
  );
};

// const growthMask = [/[1-2]/, /[0-9]/, /[0-9]/];

// export const GrowthInput = ({
//   name,
//   onChange,
//   onBlur,
//   value,
//   placeholder,
//   error,
//   showTextRole,
// }: InputProps) => {
//   const { me } = useStore();

//   return (
//     <>
//       {me && showTextRole?.includes(me.role) ? (
//         value ? (
//           <span>{value}</span>
//         ) : null
//       ) : (
//         <>
//           <InputMask
//             name={name}
//             onChange={onChange}
//             onBlur={onBlur}
//             value={value}
//             placeholder={placeholder}
//             maskPlaceholder=""
//             mask={growthMask}
//             className={`Body2 ${error ? 'input-error' : 'input-primary'}`}
//           >
//             <input type="text" />
//           </InputMask>
//           {error && <ErrorNotification text={String(error)} />}
//         </>
//       )}
//     </>
//   );
// };

export default PhoneInput;
