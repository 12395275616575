import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import locale from 'antd/lib/locale/ru_RU';
import { ConfigProvider, DatePicker } from 'antd';

import Across from '../../../Assets/icons/Across';
import Magnifier from '../../../Assets/icons/magnifier';
import moment from 'moment';
import SimpleSelect from '../../ui/Select';
import Filter from './Filter';
import { getStatusRu } from '../../../utils/get/getLeadStatus';
import DatePickerIcon from '../../../Assets/icons/DatePickerIcon';
import { useStore } from '../../../store';
// import { filterFacilities } from '../../../utils/filters/filterFacilities';
import Plus from '../../../Assets/icons/plus';
import { facilityName, groupName } from 'src/utils/filters/filtersName';
import { requestsStatuses } from 'src/utils/lists/requestsStatuses';
import { filterFacilities } from 'src/utils/filters/filterFacilities';
import { RU_DATE } from '../../../utils/constVariables';

const { RangePicker } = DatePicker;

const RequestsFilters = ({
  returnData,
  facilityId,
  setFacilityId,
  groupId,
  setGroupId,
  value,
  status,
  setSearch,
  setStatus,
  setPage,
  setValue,
}: {
  facilityId: string;
  setFacilityId: (param: string) => void;
  value: moment.Moment[] | null;
  status: string;
  groupId: string;
  setGroupId: (value: string) => void;
  returnData: () => void;
  setSearch: (param: string) => void;
  setStatus: (param: string) => void;
  setPage: (param: number) => void;
  setValue: (param: moment.Moment[] | null) => void;
}) => {
  const { facilities, groups } = useStore();
  const [filter, setFilter] = useState(false);
  const [params, setParams] = useSearchParams();

  let statusParams = params.get('status');
  let groupParam = params.get('groupId');
  let facilityParam = params.get('facilityId');
  let dateFrom = params.get('dateFrom');
  let dateTo = params.get('dateTo');

  let paramsCount = 0;

  const paramCounter = (name: string) => {
    if (params.has(name)) {
      return paramsCount++;
    }
  };

  paramCounter('status');
  paramCounter('groupId');
  paramCounter('facilityId');

  if (params.has('dateFrom') || params.has('dateTo')) {
    paramsCount++;
  }

  const handleChangeSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
    if (value !== '') {
      params.set('search', value);
      setParams(params);
    } else {
      params.delete('search');
      setParams(params);
    }
    setPage(1);
  };

  const filteredFacilities = useMemo(() => filterFacilities(groupId, facilities), [facilities, groupId]);

  const debounceOnChange = debounce(handleChangeSearch, 600);

  const removeParam = (param: string) => {
    if (params.has(param)) {
      params.delete(param);
    }
  };

  const addParams = (paramName: string, param: string) => {
    params.set(paramName, param);
  };

  useEffect(() => {
    if (params.has('dateFrom') && params.has('dateTo')) {
      setValue([moment(dateFrom), moment(dateTo)]);
      setParams(params);
    } else {
      setValue(null);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="filter-bar justify-between flex xs:flex-row flex-col gap-y-2">
      <div className="flex flex-col">
        <div className={`flex relative ${paramsCount ? 'mb-2' : ''}`}>
          <button
            type="button"
            className="btn-stroke mr-2 flex gap-x-1 items-center"
            onClick={() => {
              setFilter(value => !value);
            }}
          >
            Фильтр
            {paramsCount ? (
              <span
                className="Button3 text-primary rounded-full bg-primary bg-opacity-20"
                style={{
                  padding: '0px 7.5px',
                }}
              >
                {paramsCount}
              </span>
            ) : null}
          </button>
          <div className="relative w-64">
            <span className="absolute top-0 left-0 transform translate-x-2/4 translate-y-2/4">
              <Magnifier />
            </span>
            <input
              className="input-primary pl-8"
              onChange={debounceOnChange}
              type="search"
              name="search"
              id="search"
              placeholder="Введите номер заявки"
            />
          </div>
          {filter && (
            <div className="filter__popup flex flex-col gap-y-3">
              <span
                className="absolute top-0 right-0 cursor-pointer"
                style={{ padding: '15px 15px 0 0' }}
                onClick={() => {
                  setFilter(value => !value);
                }}
              >
                <Across color={'text-smena_gray-90'} />
              </span>
              <span className="H4 text-smena_text">Фильтры</span>

              <div>
                <label className="label-primary">Период</label>
                <ConfigProvider locale={locale}>
                  <RangePicker
                    size="middle"
                    separator={<span>→</span>}
                    placeholder={['Начало', 'Конец']}
                    className="custom__picker"
                    suffixIcon={<DatePickerIcon opacity="opacity-100" />}
                    value={value && [value[0], value[1]]}
                    format={RU_DATE}
                    onChange={(newValue: any) => {
                      setValue(newValue);
                    }}
                  />
                </ConfigProvider>
              </div>

              <SimpleSelect
                divClassName=""
                label="Статус"
                onChange={({ target: { value } }) => {
                  setStatus(value);
                }}
                value={status}
                name="status"
                autoComplete="status"
                options={[{ id: '', name: 'Все' }, ...requestsStatuses]}
              />
              <SimpleSelect
                divClassName=""
                label="Группа"
                onChange={({ target: { value } }) => {
                  setGroupId(value);
                  setFacilityId('');
                }}
                value={groupId}
                name="groupId"
                allOption="Все объекты"
                autoComplete="groupId"
                options={groups}
              />
              <SimpleSelect
                divClassName=""
                label="Объект"
                onChange={({ target: { value } }) => {
                  setFacilityId(value);
                }}
                value={facilityId}
                name="facilityId"
                allOption="Все объекты"
                autoComplete="facilityId"
                options={filteredFacilities}
              />
              <div className="flex gap-x-5">
                <button
                  className="btn-primary"
                  onClick={() => {
                    if (status === '') {
                      removeParam('status');
                    } else {
                      addParams('status', status);
                    }
                    if (groupId === '') {
                      removeParam('groupId');
                    } else {
                      addParams('groupId', groupId);
                    }
                    if (facilityId === '') {
                      removeParam('facilityId');
                    } else {
                      addParams('facilityId', facilityId);
                    }
                    if (value) {
                      params.set('dateFrom', String(moment(value[0]).format('YYYY-MM-DD')));
                      params.set('dateTo', String(moment(value[1]).format('YYYY-MM-DD')));
                    } else {
                      if (params.has('dateFrom') && params.has('dateTo')) {
                        params.delete('dateFrom');
                        params.delete('dateTo');
                      }
                    }
                    setParams(params);
                    setFilter(false);
                    returnData();
                  }}
                >
                  Применить
                </button>
                <button
                  className="btn-stroke xs:w-auto w-full"
                  onClick={() => {
                    setValue(null);
                    params.delete('status');
                    params.delete('groupId');
                    params.delete('facilityId');
                    params.delete('dateFrom');
                    params.delete('dateTo');
                    setParams(params);
                    setFilter(false);
                    setPage(1);
                  }}
                >
                  Сбросить
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="flex">
          {(dateFrom || dateTo) && (
            <Filter
              content={moment(dateFrom).format(RU_DATE) + ' - ' + moment(dateTo).format(RU_DATE)}
              clickHandler={() => {
                setValue(null);
                params.delete('dateFrom');
                params.delete('dateTo');
                setParams(params);
                setPage(1);
              }}
            />
          )}

          {statusParams && (
            <Filter
              content={getStatusRu(status)}
              clickHandler={() => {
                setStatus('');
                params.delete('status');
                setParams(params);
                setPage(1);
              }}
            />
          )}
          {groupParam && (
            <Filter
              content={groupName(groups, groupParam)}
              clickHandler={() => {
                setGroupId('');
                params.delete('groupId');
                setParams(params);
                setPage(1);
              }}
            />
          )}
          {facilityParam && (
            <Filter
              content={facilityName(facilities, facilityParam)}
              clickHandler={() => {
                setFacilityId('');
                params.delete('facilityId');
                setParams(params);
                setPage(1);
              }}
            />
          )}
        </div>
      </div>
      <div className="flex self-start">
        <Link to="create">
          <button type="button" className="btn-primary_big flex items-center gap-x-2">
            <span className="inline-flex">
              <Plus className="text-smena_white" />
            </span>
            Новая заявка
          </button>
        </Link>
      </div>
    </div>
  );
};

export default RequestsFilters;
