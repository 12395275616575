import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { isDisabled } from 'src/utils/isDisabledUserTabs';

interface IUseUserHeaderMenu {
  [key: string]: any;
}

const useUserHeaderMenu = ({
  currentLocation,
  isMeAdminSuper,
  isCurrentUserWorkerOrForeman,
  ...rest
}: IUseUserHeaderMenu) => {
  const { userId, role } = rest;
  const location = useLocation();
  const [params] = useSearchParams();

  const dateFrom = params.get('dateFrom');
  const dateTo = params.get('dateTo');

  const userURL = '/users/' + userId;
  const dateParams =
    dateFrom && dateTo ? `?dateFrom=${dateFrom}&dateTo=${dateTo}` : '';

  return useMemo(() => {
    const array = [
      {
        name: 'Информация',
        link: currentLocation === 'Информация' ? location.pathname : userURL,
        disabled: isDisabled(userId, role, true),
      },
      {
        name: 'Документы',
        link:
          currentLocation === 'Документы'
            ? location.pathname
            : userURL + '/documents',
        disabled: isDisabled(userId, role),
      },
    ];
    if (isCurrentUserWorkerOrForeman) {
      array.push(
        {
          name: 'Анкета',
          link:
            currentLocation === 'Анкета'
              ? location.pathname
              : userURL + '/questionnaire',
          disabled: isDisabled(userId, role, true),
        },
        {
          name: 'График',
          link:
            currentLocation === 'График'
              ? location.pathname
              : userURL + '/schedule' + dateParams,
          disabled: isDisabled(userId, role),
        }
      );
      if (isMeAdminSuper) {
        array.push({
          name: 'Учет времени',
          link:
            currentLocation === 'Учет времени'
              ? location.pathname
              : userURL + '/shifts' + dateParams,
          disabled: isDisabled(userId, role),
        });
      }
    }
    return array;
  }, [
    isMeAdminSuper,
    currentLocation,
    location.pathname,
    role,
    userId,
    dateParams,
    userURL,
    isCurrentUserWorkerOrForeman,
  ]);
};

export default useUserHeaderMenu;
