import {
  RegularModerationReportTableFragment,
  RegularZayavkaScheduleFragment,
} from '../../../../generated/graphql';

export const usersRowDouble = (
  userId?: string,
  moderationReportTable?: RegularModerationReportTableFragment[]
) => moderationReportTable?.filter(row => row.user.id === userId);

export const professionDobbleRow = (
  array?: (RegularZayavkaScheduleFragment | null | undefined)[] | null,
  id?: string
) => array?.filter(row => row?.positionId === id);

export const borderBottom = (
  userId?: string,
  positionId?: string,
  array?: any[]
) => {
  const users = usersRowDouble(userId, array);
  const userIndex = users?.findIndex(row => row.position.id === positionId);
  if (users && users.length > 1) {
    if (userIndex === users.length - 1) {
      return 'border-b-0.5 border-smena_gray-40';
    } else {
      return '';
    }
  } else {
    return 'border-b-0.5 border-smena_gray-40';
  }
};
