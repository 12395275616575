import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { useDeleteZayavkaShiftByClientMutation, useUpdateZayavkaShiftByClientMutation } from 'src/generated/graphql';

import { errorToast, successToast } from 'src/utils/notify';
import { errorHandler } from 'src/utils/errorHandler';
import { useParams } from 'react-router-dom';
import { zayavkaConfigFn } from 'src/utils/graphqlConfigs/zayavkaConfig';
import { LunchDurationSelect } from 'src/components/ui/Select/LunchDurationSelect';
import { groupName, positionName } from 'src/utils/filters/filtersName';
import { useStore } from 'src/store';
import { auchanLunchDurationTypes, getLunchDuration, lunchDurationTypes } from 'src/utils/lists/lunchDurationTypes';
import { timeDifference } from 'src/utils/timeDifference';
import { lunchDurationHandle } from 'src/utils/lunchDuration';
import { roles, RU_DATE, TIME_DATE } from 'src/utils/constVariables';
import TimePickerInput from '../../../ui/Pickers/TimePicker';
import moment from 'moment';
import MainSidebar from '../../../ui/Sidebar';
import { useMeRole } from '../../../../hooks/useRole';

const EditShiftSidebar: React.FC = () => {
  const {
    request,
    groups,
    positions,
    setShowEditRequestShiftSidebar,
    showEditRequestShift,
    requestShift,
    requestSchedule,
  } = useStore();

  const [editShiftStartTime, setEditShiftStartTime] = useState<moment.Moment | null>(null);
  const [editShiftEndTime, setEditShiftEndTime] = useState<moment.Moment | null>(null);
  const [lunchDuration, setLunchDuration] = useState(0);

  const isMeSupervisor = useMeRole(roles.Supervisor);

  const currentDate = dayjs(requestShift?.dateFrom);

  const router = useParams();
  let requestIdURL = '';

  if (typeof router.requestId === 'string') {
    requestIdURL = router.requestId;
  }
  const zayavkaConfig = zayavkaConfigFn(requestIdURL);
  const zayavkaRefetch = {
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfig],
  };

  const [updateZayavkaShiftByClient] = useUpdateZayavkaShiftByClientMutation(zayavkaRefetch);
  const [deleteZayavkaShiftByClient] = useDeleteZayavkaShiftByClientMutation(zayavkaRefetch);

  const facilityGroup = useMemo(
    () => groupName(groups, request?.facility?.facilityGroupId),
    [groups, request?.facility?.facilityGroupId]
  );

  const title = useMemo(() => {
    if (requestShift) {
      const { newDateEnd, newDateFrom, newLunchDuration, isDeleted } = requestShift;
      if (newDateEnd || newDateFrom || newLunchDuration || isDeleted) {
        return 'Изменения в смене';
      }
      return 'Редактировать смену';
    }
    return '';
  }, [requestShift]);

  const updateRequestShiftByClientHandler = () => {
    if (requestShift) {
      updateZayavkaShiftByClient({
        variables: {
          id: requestShift.id,
          input: {
            lunchDuration: 0,
            dateFrom: editShiftStartTime
              ?.set('date', currentDate.get('date'))
              .set('month', currentDate.get('month'))
              .set('year', currentDate.get('year')),
            dateEnd: editShiftEndTime
              ?.set('date', currentDate.get('date'))
              .set('month', currentDate.get('month'))
              .set('year', currentDate.get('year')),
          },
        },
      })
        .then(() => successToast('Смена обновлена'))
        .catch(e => errorToast(errorHandler(e)));
    }
  };

  const deleteRequestShiftByClientHandler = async () => {
    if (requestShift) {
      await deleteZayavkaShiftByClient({
        variables: {
          id: requestShift?.id,
        },
      })
        .then(() => successToast('Смена удалена'))
        .catch(e => errorToast(errorHandler(e)));
    }
  };

  const lunchOptions = useMemo(
    () => (facilityGroup === 'Ашан' ? auchanLunchDurationTypes : lunchDurationTypes),
    [facilityGroup]
  );

  const shiftDeleted = requestShift?.isDeleted;

  useEffect(() => {
    if (requestShift?.newDateFrom) {
      setEditShiftStartTime(moment(requestShift?.newDateFrom));
    } else {
      setEditShiftStartTime(moment(requestShift?.dateFrom));
    }
    if (requestShift?.newDateEnd) {
      setEditShiftEndTime(moment(requestShift?.newDateEnd));
    } else {
      setEditShiftEndTime(moment(requestShift?.dateEnd));
    }
    if (requestShift?.newLunchDuration) {
      setLunchDuration(requestShift?.newLunchDuration);
    } else {
      setLunchDuration(Number(requestShift?.lunchDuration));
    }
  }, [
    requestShift?.dateEnd,
    requestShift?.dateFrom,
    requestShift?.lunchDuration,
    requestShift?.newDateEnd,
    requestShift?.newDateFrom,
    requestShift?.newLunchDuration,
  ]);

  return (
    <MainSidebar title={title} show={showEditRequestShift} setShow={setShowEditRequestShiftSidebar}>
      <div className="flex flex-col gap-y-6">
        <div className="Subtitle1 flex gap-x-1">
          <span>График смены:</span>
          <span className="text-smena_text-secondary">
            {dayjs(requestSchedule?.dateFrom).format(RU_DATE)} – {dayjs(requestSchedule?.dateTo).format(RU_DATE)}
          </span>
        </div>
        <div className="flex flex-col gap-y-1">
          <span className="H4">{positionName(positions, requestShift?.positionId)}</span>
          <div className="flex gap-x-2 Subtitle2 text-smena_text-secondary">
            <span>{dayjs(requestShift?.dateFrom).format(RU_DATE)}</span>
            <span>·</span>
            <span>
              {dayjs(requestShift?.dateFrom).format(TIME_DATE)} – {dayjs(requestShift?.dateEnd).format(TIME_DATE)}
            </span>
            <span>·</span>
            <span>{getLunchDuration(requestShift?.lunchDuration)}</span>
          </div>
        </div>
        <hr className="bg-smena_gray-30" />

        {!isMeSupervisor && !shiftDeleted ? (
          <>
            <div className="grid grid-cols-2 gap-x-6">
              <TimePickerInput
                //check
                label="Начало смены"
                className="col-span-1 inputHeight"
                value={editShiftStartTime}
                onChange={timeValue => {
                  setEditShiftStartTime(timeValue);
                  if (facilityGroup === 'Ашан') {
                    setLunchDuration(lunchDurationHandle(timeDifference(timeValue, editShiftEndTime)));
                  }
                }}
                placeholder={dayjs().format('HH:mm')}
              />
              <TimePickerInput
                //check
                label="Конец смены"
                className="col-span-1 inputHeight"
                value={editShiftEndTime}
                onChange={timeValue => {
                  setEditShiftEndTime(timeValue);
                  if (facilityGroup === 'Ашан') {
                    setLunchDuration(lunchDurationHandle(timeDifference(editShiftStartTime, timeValue)));
                  }
                }}
              />
              <LunchDurationSelect
                divClassName="col-span-1"
                label="Обед"
                required
                onChange={({ target: { value } }) => {
                  if (facilityGroup !== 'Ашан') {
                    setLunchDuration(Number(value));
                  }
                }}
                value={lunchDuration}
                name="lunchDuration"
                autoComplete="lunchDuration"
                options={lunchOptions}
                disabled={facilityGroup === 'Ашан'}
              />
            </div>
            <div className="flex justify-end gap-x-6">
              <button className="btn-stroke_reject" onClick={deleteRequestShiftByClientHandler}>
                Удалить
              </button>
              <button className="btn-primary" onClick={updateRequestShiftByClientHandler}>
                Сохранить
              </button>
            </div>
          </>
        ) : (
          <div className="grid grid-cols-2 gap-x-6 gap-y-2">
            <div className="col-span-1 flex flex-col">
              <label className="Subtitle2">Начало смены</label>
              {requestShift?.newDateFrom ? (
                <>
                  <span className="text-smena_text-secondary line-through Body2">
                    {dayjs(requestShift?.dateFrom).format('HH:mm')}
                  </span>
                  <span className="text-primary Body2">{dayjs(requestShift?.newDateFrom).format('HH:mm')}</span>
                </>
              ) : shiftDeleted ? (
                <span className="line-through text-smena_text-secondary Body2">
                  {dayjs(requestShift?.dateFrom).format('HH:mm')}
                </span>
              ) : (
                <span className="text-primary Body2">{dayjs(requestShift?.dateFrom).format('HH:mm')}</span>
              )}
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="Subtitle2">Конец смены</label>
              {requestShift?.newDateEnd ? (
                <>
                  <span className="text-smena_text-secondary line-through Body2">
                    {dayjs(requestShift?.dateEnd).format('HH:mm')}
                  </span>
                  <span className="text-primary Body2">{dayjs(requestShift?.newDateEnd).format('HH:mm')}</span>
                </>
              ) : shiftDeleted ? (
                <span className="line-through text-smena_text-secondary Body2">
                  {dayjs(requestShift?.dateEnd).format('HH:mm')}
                </span>
              ) : (
                <span className="text-primary Body2">{dayjs(requestShift?.dateEnd).format('HH:mm')}</span>
              )}
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="Subtitle2">Обед</label>
              <span className="line-through text-smena_text-secondary Body2">
                {lunchOptions.find(option => option.id === lunchDuration)?.name}
              </span>
            </div>
          </div>
        )}
        {shiftDeleted ? <div className="Subtitle1 text-smena_red">Смена отменена</div> : null}
      </div>
    </MainSidebar>
  );
};

export default EditShiftSidebar;
