import { useCallback, useMemo, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { useChangeScheduleMutation, useFacilityQuery } from '../../../../../generated/graphql';

import { useStore } from '../../../../../store';

import SimpleSelect from '../../../../ui/Select';
import { errorHandler } from '../../../../../utils/errorHandler';
import { scheduleTypes } from '../../../../../utils/lists/scheduleTypes';
import { auchanLunchDurationTypes, lunchDurationTypes } from '../../../../../utils/lists/lunchDurationTypes';
import { lunchDurationHandle } from '../../../../../utils/lunchDuration';
import { timeDifference } from '../../../../../utils/timeDifference';
import { errorToast, successToast } from '../../../../../utils/notify';
import { LunchDurationSelect } from '../../../../ui/Select/LunchDurationSelect';
import { groupName } from '../../../../../utils/filters/filtersName';
import { userShiftsQueryConfigFn } from 'src/utils/graphqlConfigs/userShiftsConfig';
import dateHour from './helpers/dateHour';
import { shiftEndDateFn } from './helpers/shiftEndDate';
import DatePickerInput from '../../../../ui/Pickers/DatePickerInput';
import moment, { Moment } from 'moment';
import TimePickerInput from '../../../../ui/Pickers/TimePicker';

const NewSchedule = () => {
  const [dateStart, setDateStart] = useState<Dayjs | null>(dayjs());
  const [dateEnd, setDateEnd] = useState<Dayjs | null>(dayjs());
  const [selectedTime, setSelectedTime] = useState<Moment | null>(moment());
  const [selectedEndTime, setSelectedEndTime] = useState<Moment | null>(moment().add(30, 'm'));
  const [selectedType, setSelectedType] = useState(1);

  const {
    currentUser,
    lunchDuration,
    setLunchDuration,
    facilityId,
    setFacilityIdError,
    workpostId,
    setWorkpostIdError,
    groups,
    setStartScheduleTime,
    setEndScheduleTime,
  } = useStore();

  const { data } = useFacilityQuery({
    variables: {
      id: facilityId || '',
    },
  });

  const facilityGroup = groupName(groups, data?.facility.facilityGroupId);

  const userShiftsQueryConfig = userShiftsQueryConfigFn(
    String(dateStart?.startOf('month').valueOf()),
    String(dateEnd?.endOf('month').valueOf()),
    currentUser?.id
  );

  const [changeScheduleMutation] = useChangeScheduleMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userShiftsQueryConfig],
  });

  const onChangeDateStart = useCallback(
    (selectedTime: Moment | null) => {
      setSelectedTime(selectedTime);
      setStartScheduleTime(selectedTime);
      if (facilityGroup === 'Ашан') {
        setLunchDuration(lunchDurationHandle(timeDifference(selectedTime, selectedEndTime)));
      }
    },
    [facilityGroup, selectedEndTime, setLunchDuration, setStartScheduleTime]
  );

  const onChangeDateEnd = useCallback(
    (selectedEndTime: Moment | null) => {
      setSelectedEndTime(selectedEndTime);
      setEndScheduleTime(selectedEndTime);
      if (facilityGroup === 'Ашан') {
        setLunchDuration(lunchDurationHandle(timeDifference(selectedTime, selectedEndTime)));
      }
    },
    [facilityGroup, selectedTime, setLunchDuration, setEndScheduleTime]
  );

  const dateEndVariable = useMemo(
    () =>
      Number(selectedEndTime) < Number(selectedTime)
        ? String(dayjs(dateHour(dateEnd, selectedEndTime)).add(1, 'day').valueOf())
        : String(dateHour(dateEnd, selectedEndTime)),
    [selectedEndTime, selectedTime, dateEnd]
  );

  const options = useMemo(
    () => (facilityGroup === 'Ашан' ? auchanLunchDurationTypes : lunchDurationTypes),
    [facilityGroup]
  );

  const onChangeSchedule = useCallback(({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(Number(value));
  }, []);

  const onChangeLunchDuration = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
      if (facilityGroup !== 'Ашан') {
        setLunchDuration(Number(value));
      }
    },
    [setLunchDuration, facilityGroup]
  );
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (dateStart?.isToday() && selectedTime?.isBefore(moment())) {
      return errorToast('Смена не может начинаться в прошлом');
    }
    if (dateEnd?.startOf('day').diff(dateStart?.startOf('day')) === 0) {
      if (selectedEndTime && selectedTime) {
        if (selectedEndTime.diff(selectedTime) > 0 && selectedEndTime.diff(selectedTime) < 1800000) {
          return errorToast('Смена не может быть меньше 30 минут');
        }
      }
    }

    if (!facilityId) {
      return setFacilityIdError(true);
    }
    if (!workpostId) {
      return setWorkpostIdError(true);
    }
    setWorkpostIdError(false);
    setFacilityIdError(false);

    if (selectedTime === null || selectedEndTime === null) {
      return errorToast('Выберите время');
    }

    const values = {
      type: selectedType,
      shiftEndDate: String(shiftEndDateFn(selectedTime, selectedEndTime, dateStart)),
      dateStart: String(dateHour(dateStart, selectedTime)),
      dateEnd: dateEndVariable,
      facilityId,
      positionId: workpostId,
      userId: currentUser?.id || '',
      lunchDuration,
    };
    changeScheduleMutation({
      variables: {
        input: values,
      },
    })
      .then(() => successToast('Вы успешно предложили график'))
      .catch(e => {
        errorToast(`Произошла ошибка: ${errorHandler(e)}`);
      });
  };

  return (
    <form onSubmit={onSubmit} className="grid grid-cols-4 gap-x-5">
      <SimpleSelect
        divClassName="col-span-2"
        label="График"
        required
        onChange={onChangeSchedule}
        value={selectedType}
        name="type"
        autoComplete="type"
        disabledOption="Выберите график"
        options={scheduleTypes}
      />

      <LunchDurationSelect
        divClassName="col-span-2"
        label="Обед"
        required
        onChange={onChangeLunchDuration}
        value={lunchDuration}
        name="lunchDuration"
        autoComplete="lunchDuration"
        options={options}
        disabled={facilityGroup === 'Ашан'}
      />

      <TimePickerInput
        label="Время начала смены"
        className="col-span-2 inputHeight"
        popupClassName="fixed"
        value={selectedTime}
        onChange={onChangeDateStart}
      />
      <TimePickerInput
        label="Время конца смены"
        className="col-span-2 inputHeight"
        popupClassName="fixed"
        value={selectedEndTime}
        onChange={onChangeDateEnd}
      />
      <DatePickerInput
        // check
        label="Дата начала"
        divClassName="col-span-2 inputHeight"
        popupClassName="fixed"
        value={dateStart}
        onChange={dateStart => {
          setDateStart(dateStart);
          if (dayjs(dateStart).valueOf() > dayjs(dateEnd).valueOf()) {
            setDateEnd(dateStart);
          }
        }}
        minDate={dayjs().subtract(1, 'day')}
        error={{ bool: !Boolean(dateStart), text: 'Обязательное поле' }}
      />
      <DatePickerInput
        // check
        label="Дата конца"
        divClassName="col-span-2 inputHeight"
        popupClassName="fixed"
        value={dateEnd}
        onChange={dateEnd => setDateEnd(dateEnd)}
        minDate={dateStart || undefined}
        error={{ bool: !Boolean(dateEnd), text: 'Обязательное поле' }}
      />
      <div className="col-span-full flex justify-end mt-7">
        <button type="submit" className="btn-primary_small">
          Предложить график
        </button>
      </div>
    </form>
  );
};

export default NewSchedule;
