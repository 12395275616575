import { MasterCard } from '../../Assets/icons/MasterCard';
import { Mir } from '../../Assets/icons/MIR';
import { UnionPay } from '../../Assets/icons/UnionPay';
import { Visa } from '../../Assets/icons/Visa';

export const getPaySystem = (firstNumber: string) => {
  const number = firstNumber.split('').filter(el => el !== ' ');

  if (
    Number(number.slice(0, 4).join('')) >= 2200 &&
    2204 >= Number(number.slice(0, 4).join(''))
  ) {
    return <Mir />;
  }
  if (
    (Number(number.slice(0, 4).join('')) >= 2221 &&
      Number(number.slice(0, 4).join('')) <= 2720) ||
    (Number(number.slice(0, 2).join('')) >= 51 &&
      Number(number.slice(0, 2).join('')) <= 55)
  ) {
    return <MasterCard />;
  }
  if (number[0] === '4') {
    return <Visa />;
  }
  if (number[0] === '62') {
    return <UnionPay />;
  }
};
