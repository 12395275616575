import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '../components/ui/Logo';

export default function Custom404() {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-gray-02">
      <Helmet>
        <title>Страница не найдена</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <Link to="/">
        <Logo />
      </Link>
      <h1 className="mb-4">Мы не смогли найти вашу страницу</h1>
    </div>
  );
}
