import CalendarMain from './main';
import CalendarSidebar from './Sidebar';
import CalendarHeader from '../CalendarHeader';
import ShiftsSidebar from './ShiftsSidebar';

const Calendar = () => {
  return (
    <>
      <CalendarHeader />
      <CalendarMain />
      <CalendarSidebar />
      <ShiftsSidebar />
    </>
  );
};

export default Calendar;
