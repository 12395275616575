import clsx from 'clsx';
import { Icons } from 'src/Interfaces/IIcons';

export const LikeIcon: React.FC<Icons> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0862 1.59386C10.2467 1.23273 10.6048 1 11 1C12.0609 1 13.0783 1.42143 13.8284 2.17157C14.5786 2.92172 15 3.93913 15 5V8H19.6547C20.0874 7.99579 20.516 8.08531 20.911 8.26243C21.3079 8.44044 21.6614 8.70269 21.9469 9.03098C22.2323 9.35927 22.443 9.74573 22.5641 10.1636C22.6853 10.5814 22.7141 11.0206 22.6486 11.4506L21.2687 20.4499C21.2687 20.4502 21.2687 20.4497 21.2687 20.4499C21.1601 21.165 20.7966 21.8175 20.2456 22.2859C19.6958 22.7532 18.9961 23.0067 18.2748 23H4C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7957 1 20V13C1 12.2044 1.31607 11.4413 1.87868 10.8787C2.44129 10.3161 3.20435 10 4 10H6.35013L10.0862 1.59386ZM6 12H4C3.73478 12 3.48043 12.1054 3.29289 12.2929C3.10536 12.4804 3 12.7348 3 13V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H6V12ZM8 21V11.2122L11.6078 3.0946C11.9092 3.19075 12.1864 3.35794 12.4142 3.58579C12.7893 3.96086 13 4.46957 13 5V9C13 9.55228 13.4477 10 14 10H19.66L19.6719 9.99993C19.8168 9.9982 19.9604 10.028 20.0926 10.0873C20.2248 10.1466 20.3426 10.234 20.4377 10.3433C20.5328 10.4527 20.6029 10.5814 20.6433 10.7206C20.6836 10.8598 20.6932 11.0061 20.6714 11.1494L19.2913 20.1501C19.2551 20.3885 19.134 20.6059 18.9503 20.7621C18.7665 20.9183 18.5325 21.0028 18.2913 21.0001L8 21Z"
        // fill="#393B55"
      />
    </svg>
  );
};
