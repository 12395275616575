import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ScrollSyncPane } from 'react-scroll-sync';
import dayjs from 'dayjs';

import Across from 'src/Assets/icons/Across';
import ThreeDots from 'src/Assets/icons/ThreeDots';
import { professionDobbleRow } from 'src/components/ModerationReports/ModerationReport/EmployerPeriod/utils';
import Popup from 'src/components/ui/Popup';
import { RegularZayavkaScheduleFragment, useDeleteWorkerFromZayavkaScheduleMutation } from 'src/generated/graphql';
import OutsideClick from 'src/hooks/useOutsideClick';
import { useStore } from 'src/store';
import { ISO_DATE, roles } from 'src/utils/constVariables';
import { errorHandler } from 'src/utils/errorHandler';
import { positionName } from 'src/utils/filters/filtersName';
import { getZayavkyByFacilityConfigFn } from 'src/utils/graphqlConfigs/getZayavkyByFacilityConfig';
import { errorToast, successToast } from 'src/utils/notify';

interface IRequestProfession {
  requestSchedule?: RegularZayavkaScheduleFragment | null;
  allScheduleRequests: (RegularZayavkaScheduleFragment | null | undefined)[];
  setShowEditGraphicSidebar: (value: boolean) => void;
}

const RequestProfession: React.FC<IRequestProfession> = ({
  requestSchedule,
  allScheduleRequests,
  setShowEditGraphicSidebar,
}) => {
  const { positions, setRequestSchedule, me } = useStore();
  const [editGraphic, setEditGraphic] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const router = useParams();

  const [params] = useSearchParams();
  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }

  const meSupervisor = me?.role === roles.Supervisor;
  const meAdmin = me?.role === roles.Admin;

  const monthParam = params.get('date');

  const month = monthParam || dayjs().format(ISO_DATE);

  const btnRef = useRef<HTMLButtonElement>(null);

  const zayavkaConfig = getZayavkyByFacilityConfigFn(facilityId, month);

  const [deleteWorkerFromZayavkaSchedule] = useDeleteWorkerFromZayavkaScheduleMutation({
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfig],
  });

  const deleteZayavkaScheduleHandler = async () => {
    if (requestSchedule) {
      await deleteWorkerFromZayavkaSchedule({
        variables: {
          id: requestSchedule.id,
        },
      })
        .then(() => successToast('График освобожден'))
        .catch(e => errorToast(errorHandler(e)));
    }
  };

  const professionName = () => {
    const double = professionDobbleRow(allScheduleRequests, requestSchedule?.positionId);
    if (double && double.length > 0) {
      if (double[0] && double[0].id === requestSchedule?.id) {
        return positionName(positions, requestSchedule.positionId);
      }
    }
    return '';
  };

  return (
    <>
      <ScrollSyncPane>
        <div className={clsx('requestScheduleProfessionsRows border-b group')}>
          <div className="flex items-center justify-between">
            <span className="Caption-small pl-1">{professionName()}</span>
            {(meSupervisor || meAdmin) && (
              <button
                className="mr-2"
                onClick={() => {
                  setEditGraphic(value => !value);
                }}
                ref={btnRef}
              >
                <ThreeDots className="text-smena_text-disabled group-hover:text-smena_text" />
              </button>
            )}
          </div>
        </div>
      </ScrollSyncPane>
      {editGraphic && (
        <OutsideClick
          show={editGraphic}
          setShow={setEditGraphic}
          className="absolute top-full transform translate-x-full translate-y-1 bg-smena_white flex flex-col shadow-smena rounded-lg z-10"
          style={{
            width: 'max-content',
            height: 'max-content',
            top:
              Number(btnRef.current?.offsetTop) -
              Number(btnRef.current?.parentElement?.parentElement?.parentElement?.scrollTop) +
              'px',
            left: Number(btnRef.current?.offsetLeft) - 155 + 'px',
          }}
        >
          <button
            className="px-3 py-2 Body1 text-left"
            onClick={() => {
              setEditGraphic(false);
              setShowEditGraphicSidebar(true);
              setRequestSchedule(requestSchedule);
            }}
          >
            Назначить
          </button>
          <button className="px-3 py-2 Body1 text-left text-smena_red" onClick={deleteZayavkaScheduleHandler}>
            Освободить график
          </button>
        </OutsideClick>
      )}
      {showPopup && (
        <Popup setShowPopup={setShowPopup} showPopup={showPopup}>
          <div className="flex flex-col bg-smena_white rounded-lg shadow-smena p-6 gap-y-6">
            <div className="flex justify-between">
              <h4 className="H4">Снять исполнителя со смены</h4>
              <button>
                <Across />
              </button>
            </div>
            <div className="Body1">Вы уверены, что хотите снять исполнителя со смены?</div>
            <div className="flex justify-end gap-x-6">
              <button className="btn-stroke" type="button" onClick={() => setShowPopup(false)}>
                Отмена
              </button>
              <button className="btn-reject_secondary" type="button" onClick={deleteZayavkaScheduleHandler}>
                Снять
              </button>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default RequestProfession;
