import { ShiftStage } from 'src/generated/graphql';

export const statusBg = (stage: ShiftStage | string) => {
  if (stage === ShiftStage.Ineligible) {
    return {
      className: 'ineligible_schedule',
    };
  }
  if (stage === ShiftStage.Refused) {
    return {
      className: 'refused_schedule',
    };
  } else if (stage === 'CUSTOM') {
    return {
      className: 'custom_schedule',
    };
  } else if (
    stage === ShiftStage.Accepted ||
    stage === ShiftStage.Working ||
    stage === ShiftStage.Finished ||
    stage === ShiftStage.Wasted
  ) {
    return {
      className: 'confirmed',
    };
  } else {
    return {
      className: '',
    };
  }
};
