import { useEffect } from 'react';

import { useStore } from '../../../store';
import PaymentCheckbox from './checkbox';
import PaymentsHeader from './header';
import Payment from './payment';

const PaymentsTable = () => {
  const {
    manualAccural,
    setPaymentCheckboxes,
    setManualAccuralUserIds,
    setDisabledUsersIds,
  } = useStore();

  const workpostUsers = [
    ...new Map(
      manualAccural?.facility?.WorkPosts?.map(item => [item['userId'], item])
    ).values(),
  ];

  workpostUsers.sort((a, b) => {
    if (a.user?.firstname! < b.user?.firstname!) return -1;
    if (a.user?.firstname! > b.user?.firstname!) return 1;
    return 0;
  });

  const usersId: string[] = [];

  const disabledUsersArray = [
    ...new Map(
      manualAccural?.manualUserAccruals?.map(item => [item['userId'], item])
    ).values(),
  ];

  const disabledUsersIds = disabledUsersArray.map(ids => ids.userId);

  useEffect(() => {
    setDisabledUsersIds(disabledUsersIds);
    setPaymentCheckboxes(workpostUsers.length! - disabledUsersArray.length);
    workpostUsers.forEach(x => {
      usersId.push(x.userId);
    });

    setManualAccuralUserIds(usersId);
    if (disabledUsersArray.length) {
      //flat
      const remove = disabledUsersIds
        .map(disabledId => usersId.filter(userId => userId === disabledId))
        .reduce((acc, val) => acc.concat(val), []);
      setManualAccuralUserIds(usersId.filter(id => !remove.includes(id)));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-smena_white border-b border-smena_gray-30 rounded-t-lg">
      <PaymentsHeader
        pays={workpostUsers.length! - disabledUsersArray.length}
      />
      {workpostUsers.map(workpost => (
        <div
          key={workpost.userId}
          className={`grid payments-row ${
            disabledUsersIds.find(id => workpost.userId === id)
              ? 'opacity-50'
              : ''
          }`}
          style={{ gridTemplateColumns: '50px 1fr' }}
        >
          <PaymentCheckbox userId={workpost.userId} />
          <Payment user={workpost.user} />
        </div>
      ))}
    </div>
  );
};

export default PaymentsTable;
