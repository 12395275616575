import React from 'react';

import { useStore } from '../store';

import MainLayout from '../components/Layout/main';
import PaymentsTable from '../components/Table/Payments';
import PaymentsFilter from '../components/Table/Payments/filter';
import { useSendManualAccuralsMutation } from '../generated/graphql';
import ManualUserAccruals from '../components/Table/Payments/manualUserAccruals';
import { errorToast, successToast } from '../utils/notify';
import { errorHandler } from '../utils/errorHandler';

const PaymentsPage: React.FC = () => {
  const { manualAccural, manualAccuralUserIds } = useStore();
  const [sendManual] = useSendManualAccuralsMutation();

  const submitHandler = async () => {
    await sendManual({
      variables: {
        input: {
          accrualId: manualAccural ? manualAccural.id : '',
          userIds: manualAccuralUserIds,
        },
      },
    })
      .then(e => {
        if (e.data?.sendManualAccurals) {
          successToast('Выплата успешно произведена');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  return (
    <MainLayout
      title="Начисления"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[{ path: '/payments', name: 'Начисления' }]}
    >
      <div className="mt-5 pb-10 flex flex-col gap-y-5">
        <PaymentsFilter />
        {manualAccural && (
          <>
            <PaymentsTable />
            <div>
              <button
                className="btn-primary_big my-4 mb-8"
                style={
                  manualAccuralUserIds.length === 0
                    ? { cursor: 'not-allowed' }
                    : {}
                }
                disabled={manualAccuralUserIds.length === 0}
                onClick={() => {
                  submitHandler();
                }}
              >
                Выплатить
              </button>
            </div>

            {manualAccural.manualUserAccruals?.length! > 0 && (
              <ManualUserAccruals />
            )}
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default PaymentsPage;
