export const RotateLeftArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3648 18.3656C16.6757 20.0521 14.3862 20.9994 11.9992 20.9994C9.61222 20.9994 7.32277 20.0521 5.63359 18.3656C5.49333 18.225 5.41457 18.0345 5.41457 17.8359C5.41457 17.6373 5.49333 17.4468 5.63359 17.3062C5.70257 17.2356 5.78497 17.1796 5.87593 17.1413C5.96689 17.103 6.06459 17.0832 6.16328 17.0832C6.26197 17.0832 6.35967 17.103 6.45064 17.1413C6.5416 17.1796 6.62399 17.2356 6.69297 17.3062C7.74219 18.3543 9.07858 19.0678 10.5332 19.3565C11.9878 19.6452 13.4954 19.4962 14.8654 18.9283C16.2353 18.3604 17.4062 17.3991 18.2299 16.1659C19.0537 14.9327 19.4933 13.483 19.4933 12C19.4933 10.517 19.0537 9.06725 18.2299 7.83406C17.4062 6.60088 16.2353 5.63958 14.8654 5.07167C13.4954 4.50377 11.9878 4.35476 10.5332 4.64347C9.07858 4.93219 7.74219 5.64566 6.69297 6.69372L4.78984 8.59685H7.48047C7.67938 8.59685 7.87015 8.67587 8.0108 8.81652C8.15145 8.95717 8.23047 9.14794 8.23047 9.34685C8.23047 9.54576 8.15145 9.73653 8.0108 9.87718C7.87015 10.0178 7.67938 10.0968 7.48047 10.0968H2.98047C2.78156 10.0968 2.59079 10.0178 2.45014 9.87718C2.30949 9.73653 2.23047 9.54576 2.23047 9.34685V4.84685C2.23047 4.64794 2.30949 4.45717 2.45014 4.31652C2.59079 4.17587 2.78156 4.09685 2.98047 4.09685C3.17938 4.09685 3.37015 4.17587 3.5108 4.31652C3.65145 4.45717 3.73047 4.64794 3.73047 4.84685V7.53747L5.63359 5.63435C7.32186 3.94608 9.61165 2.99762 11.9992 2.99762C14.3868 2.99762 16.6766 3.94608 18.3648 5.63435C20.0531 7.32262 21.0016 9.6124 21.0016 12C21.0016 14.3875 20.0531 16.6773 18.3648 18.3656Z"
        fill="#393B55"
      />
    </svg>
  );
};
