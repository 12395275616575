import React, { useMemo } from 'react';
import { UnitType } from 'src/generated/graphql';

import { IRowStats } from '../../../../Interfaces/IRow';
import { useStore } from '../../../../store';
import { isInt } from '../../../../utils/isInt';
import { borderBottom } from './utils';

export const RowStats: React.FC<IRowStats> = ({ row }) => {
  const { moderationReport } = useStore();
  const {
    planHours,
    planValue,
    factHours,
    factValue,
    unit,
    user,
    position,
    sum,
    shiftsCount,
    penaltySum,
    probationSum,
  } = row;

  const planColumn = useMemo(() => {
    if (unit === UnitType.Hour) {
      return planHours;
    }
    return planValue;
  }, [unit, planHours, planValue]);

  const factColumn = useMemo(() => {
    if (unit === UnitType.Hour) {
      return factHours;
    }
    return factValue;
  }, [unit, factHours, factValue]);

  const unitSymbol = useMemo(() => {
    switch (unit) {
      case UnitType.Hour:
        return 'ч.';
      case UnitType.Piece:
        return 'шт.';
      case UnitType.Point:
        return '%';
      default:
        return '';
    }
  }, [unit]);

  return (
    <div
      className={`table__stats w-fit p-1 h-7 ${borderBottom(
        user.id,
        position.id,
        moderationReport?.getModerationReport.rows
      )}`}
    >
      <span>{shiftsCount}</span>
      <div className="flex">
        <span className="text-smena_green-dark">{row.shiftsToPayment}</span>
      </div>
      <span>
        {isInt(factColumn)} {unitSymbol}
      </span>
      <span>
        {isInt(planColumn)} {unitSymbol}
      </span>
      <span>{penaltySum}</span>
      <span>{isInt(probationSum)}</span>
      <span>{sum}</span>
    </div>
  );
};
