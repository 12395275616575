import { allTypes, facilityTypes, groupTypes } from '../../../utils/constVariables';
import { MetricTypes, RegularDashBoardStatisticModelFragment } from '../../../generated/graphql';
import { chain, last, sumBy } from 'lodash';
import dayjs from 'dayjs';

interface FacilityAndGroup {
  facilityId: string;
  groupId: string;
}

export const chartTypes = ({ facilityId, groupId }: FacilityAndGroup) => {
  if (facilityId !== 'all') {
    return facilityTypes;
  }
  if (groupId !== 'all') {
    return groupTypes;
  }
  return allTypes;
};

export const getChartLengthHandler = ({
  getMetrics,
  metricName,
}: {
  getMetrics?: RegularDashBoardStatisticModelFragment[] | null;
  metricName: MetricTypes;
}) => {
  if (getMetrics) {
    const getMetric = chain(getMetrics).find({ metricType: metricName }).value();
    const filterMetric = getMetric?.data.filter(
      el => dayjs(el.createdAt).valueOf() >= dayjs().startOf('day').valueOf()
    );
    return sumBy(filterMetric, 'value');
  }
  return 0;
};

export const getChartValue = ({
  facilityId,
  groupId,
  getChartLength,
  facilityMetricType,
  facilityGroupMetricType,
  allMetricType,
}: {
  facilityId: string;
  groupId: string;
  getChartLength: (metricType: MetricTypes) => number;
  facilityMetricType: MetricTypes;
  facilityGroupMetricType: MetricTypes;
  allMetricType: MetricTypes;
}) => {
  if (facilityId !== 'all') {
    return getChartLength(facilityMetricType);
  }
  if (groupId !== 'all') {
    return getChartLength(facilityGroupMetricType);
  }
  return getChartLength(allMetricType);
};

export const arrMetricTypesHandler = ({ facilityId, groupId }: FacilityAndGroup) => {
  if (facilityId !== 'all') {
    return [MetricTypes.CountForemanFacility, MetricTypes.CountSupervisorFacility, MetricTypes.CountWorkerFacility];
  }
  if (groupId !== 'all') {
    return [
      MetricTypes.CountForemanFacilityGroup,
      MetricTypes.CountSupervisorFacilityGroup,
      MetricTypes.CountWorkerFacilityGroup,
    ];
  }
  return [MetricTypes.CountForeman, MetricTypes.CountSupervisor, MetricTypes.CountWorker];
};

export const getAllUsersChartLengthHandler = ({
  arrMetricTypes,
  getMetrics,
}: {
  arrMetricTypes: MetricTypes[];
  getMetrics?: RegularDashBoardStatisticModelFragment[] | null;
}) => {
  return chain(getMetrics)
    .filter(el => arrMetricTypes.includes(el.metricType))
    .map(el => last(el.data)?.value)
    .sum()
    .value();
};
