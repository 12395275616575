import { Link } from 'react-router-dom';
import { IManagemetn } from '../../../Interfaces/IManagemetn';
import { useStore } from '../../../store';
import { roles } from '../../../utils/constVariables';
import Manager from './Manager';
import { ManagamentWorkpost } from './Workpost';

const Management = ({
  workposts,
  userFacilitiesWorkpost,
  removeWorkpost,
}: IManagemetn) => {
  const { me } = useStore();
  if (userFacilitiesWorkpost && me) {
    const canRemove = [roles.Admin, roles.Supervisor].includes(me.role);
    const { facility } = userFacilitiesWorkpost;
    return (
      <>
        <hr />
        <div className="flex flex-col justify-center gap-y-3">
          <div>
            <Link
              to={facility?.id ? '/facilities/' + facility?.id : ''}
              className="Subtitle1 text-primary hover:underline"
              target="_blank"
            >
              {facility?.name}
            </Link>
          </div>
          <div className="grid xs:grid-cols-3 xs:grid-rows-none grid-rows-3 xs:gap-x-5 gap-y-5">
            <div className="xs:col-span-1 row-span-full flex flex-wrap gap-1 h-fit">
              {workposts
                ?.filter(workpost => workpost.facility?.id === facility?.id)
                .map(workpost => {
                  return (
                    <ManagamentWorkpost
                      key={workpost.positionId}
                      workpost={workpost}
                      removeWorkpost={removeWorkpost}
                      canRemove={canRemove}
                    />
                  );
                })}
            </div>
            <Manager role="Бригадир" arrayOf={facility?.foremen} />
            <Manager role="Супервайзер" arrayOf={facility?.supervisors} />
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default Management;
