import { MetricTypes } from '../../generated/graphql';

export const RU_DATE = 'DD.MM.YYYY';
export const TIME_DATE = 'HH:mm';
export const ISO_DATE = 'YYYY-MM-DD';
export const regFacilityName = /^[а-яА-ЯёЁa-zA-Z0-9 ,]+$/;

export const groupTypes = [
  MetricTypes.ShiftsAcceptedFacilityGroup,
  MetricTypes.ShiftWorkersFacilityGroup,
  MetricTypes.LatecomersFacilityGroup,
  MetricTypes.UserCountFacilityGroup,
];
export const isLocal = process.env.NODE_ENV === 'development';
export const isTest = window.location.origin.includes('localhost') || window.location.origin.includes('test');
export const isProd = !isTest;
export const facilityTypes = [
  MetricTypes.ShiftsAcceptedFacility,
  MetricTypes.ShiftWorkersFacility,
  MetricTypes.LatecomersFacility,
  MetricTypes.UserCountFacility,
];
export const allTypes = [
  MetricTypes.ShiftsAcceptedAll,
  MetricTypes.ShiftWorkersAll,
  MetricTypes.LatecomersAll,
  MetricTypes.UserCountAll,
];

export const roles = {
  Admin: 'ADMIN',
  Supervisor: 'SUPERVISOR',
  ClientDirector: 'CLIENT_DIRECTOR',
  ClientManager: 'CLIENT_MANAGER',
  Foreman: 'FOREMAN',
  Worker: 'WORKER',
  Newbie: 'NEWBIE',
};
