import {
  RegularModerationReportTableDayFragment,
  ShiftStage,
} from 'src/generated/graphql';

export const moderationShift = (
  day: RegularModerationReportTableDayFragment
) => {
  const { shifts } = day;
  const shiftsPerDay = shifts.length;

  const moderationDays = shifts.filter(shift => shift.moderation !== null);

  if (shiftsPerDay === 0) {
    return { shift: null, bg: 'border-smena_gray-40' };
  }

  if (day.shifts.filter(shift => shift.isProbation).length) {
    if (
      day.shifts.filter(shift => shift.stage === ShiftStage.Ineligible).length
    ) {
      return {
        shift: shifts[0],
        bg: 'bg-smena_red-extra_light',
      };
    }
    return {
      shift: shifts[0],
      bg: 'bg-smena_purple-light',
    };
  }

  if (day.dayValue) {
    return {
      shift: { ...shifts[0], value: day.dayValue },
      bg: shifts[0].moderation
        ? shifts[0].moderation.toPayment
          ? 'bg-smena_green-extra_light'
          : 'bg-smena_red-extra_light'
        : 'bg-smena_yellow-extra_light',
    };
  }

  if (
    shifts.filter(shift => shift.stage === ShiftStage.Finished).length ===
    shifts.length
  ) {
    if (
      shifts.filter(
        shift =>
          shift.moderation !== null && shift.moderation?.toPayment === false
      ).length > 0
    ) {
      return { shift: shifts[0], bg: 'bg-smena_red-extra_light' };
    }
    return { shift: shifts[0], bg: 'bg-smena_green-extra_light' };
  }

  if (moderationDays.length > 0) {
    const nonModeration = shifts.find(shift => shift.moderation === null);
    const modertaionPayment = moderationDays.find(
      day => day.moderation?.toPayment
    );
    if (nonModeration) {
      if (nonModeration.stage === ShiftStage.Finished) {
        return { shift: nonModeration, bg: 'bg-smena_green-extra_light' };
      }
      if (nonModeration.stage === ShiftStage.Refused) {
        return { shift: nonModeration, bg: 'bg-smena_gray-30' };
      }
      return { shift: nonModeration, bg: 'bg-smena_yellow-extra_light' };
    }
    if (modertaionPayment) {
      return {
        shift: modertaionPayment,
        bg: 'bg-smena_green-extra_light',
      };
    }
    return { shift: moderationDays[0], bg: 'bg-smena_red-extra_light' };
  }
  if (
    shifts.filter(shift => shift.stage === ShiftStage.Refused).length ===
    shifts.length
  ) {
    return { shift: shifts[0], bg: 'bg-smena_gray-30' };
  }
  return { shift: shifts[0], bg: 'bg-smena_yellow-extra_light' };
};
