import clsx from 'clsx';
import dayjs from 'dayjs';
import { LikeIcon } from 'src/Assets/icons/LikeIcon';
import { useStore } from 'src/store';
import {
  facilityGroupName,
  facilityName,
  positionName,
} from 'src/utils/filters/filtersName';
import { getRoleRu } from 'src/utils/get/getRoleRu';

const ReviewElement = ({ review }: any) => {
  const { facilities, positions } = useStore();
  return (
    <>
      <h3 className="Caption text-smena_text-secondary">
        {dayjs(review.createdAt).format('DD.MM.YYYY HH:mm')}
      </h3>
      <div className="flex justify-between">
        <div className="flex gap-x-1 Subtitle1">
          <span>{`${review.author.firstname} ${review.author.lastname}`}</span>
          <span className="text-smena_text-secondary">
            ({`${getRoleRu(review.author.role)}`})
          </span>
        </div>
        <div
          className={clsx(
            'flex gap-x-2 px-2 py-1 rounded-lg',
            review.isGood
              ? 'text-smena_green-dark bg-smena_green-extra_light'
              : 'text-smena_red bg-smena_red-extra_light'
          )}
        >
          {review.isGood ? (
            <>
              <span>
                <LikeIcon />
              </span>
              <span>Рекомендует</span>
            </>
          ) : (
            <>
              <span className="transform rotate-180">
                <LikeIcon />
              </span>
              <span>Не рекомендует</span>
            </>
          )}
        </div>
      </div>
      <div className="flex gap-x-1 Body1 text-smena_text-secondary">
        <span>{facilityGroupName(facilities, review.facilityId)}</span>
        <span>·</span>
        <span>{facilityName(facilities, review.facilityId)}</span>
        <span>·</span>
        <span>{positionName(positions, review.positionId)}</span>
      </div>
      <div className="Body1">{review.text}</div>
    </>
  );
};

export default ReviewElement;
