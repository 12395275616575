import { ZayavkaChangesStatus, ZayavkaStage } from '../../../../generated/graphql';
import { useStore } from '../../../../store';
import { useMemo } from 'react';

export const useChangesByStatus = ({ status }: { status: ZayavkaChangesStatus[] }) => {
  const { request } = useStore();
  return useMemo(() => {
    return Boolean(request?.zayavkaChanges?.filter(change => status.includes(change.stage))?.length);
  }, [status, request?.zayavkaChanges]);
};

export const useRequestStatus = ({ status }: { status: ZayavkaStage[] }) => {
  const { request } = useStore();

  return useMemo(() => {
    if (!request) return false;
    return status.includes(request.status);
  }, [status, request]);
};
