import { RejectReason } from '../../generated/graphql';

export const photoRejectsList = [
  { id: RejectReason.PoorQuality, name: 'Низкое качество фото' },
  { id: RejectReason.NoFace, name: 'Не видно лицо' },
  { id: RejectReason.DocumentExpired, name: 'Срок действия документа истек' },
  {
    id: RejectReason.SelfieDoesntMatch,
    name: 'Селфи не соответствует фото паспорта',
  },
  { id: RejectReason.IncorrectData, name: 'Некорректные данные' },
  { id: RejectReason.None, name: 'Другое' },
];
export const photoWithoutSelfieRejectsList = [
  { id: RejectReason.PoorQuality, name: 'Низкое качество фото' },
  { id: RejectReason.NoFace, name: 'Не видно лицо' },
  { id: RejectReason.DocumentExpired, name: 'Срок действия документа истек' },
  { id: RejectReason.IncorrectData, name: 'Некорректные данные' },
  { id: RejectReason.None, name: 'Другое' },
];
export const photoWithoutFaceRejectsList = [
  { id: RejectReason.PoorQuality, name: 'Низкое качество фото' },
  { id: RejectReason.DocumentExpired, name: 'Срок действия документа истек' },
  { id: RejectReason.IncorrectData, name: 'Некорректные данные' },
  { id: RejectReason.None, name: 'Другое' },
];
export const rejectsList = [
  { id: RejectReason.IncorrectData, name: 'Некорректные данные' },
  { id: RejectReason.None, name: 'Другое' },
];
