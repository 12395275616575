import { useMemo } from 'react';
import { RegularUserShiftsFragment, ShiftStage } from '../../../generated/graphql';
import { useStore } from '../../../store';

const useNotAcceptedByWorkerShifts = ({
  shiftsWithoutCanceled,
  notProbationUserShift,
}: {
  shiftsWithoutCanceled?: RegularUserShiftsFragment[];
  notProbationUserShift?: RegularUserShiftsFragment[];
}) => {
  const { hiddenCanceledShifts, facilityId, workpostId } = useStore();

  return useMemo(() => {
    const shifts = (hiddenCanceledShifts ? shiftsWithoutCanceled : notProbationUserShift) || []
    return shifts.filter(shift => {
      const isStageFitsAndUserIsnAuthor = shift.stage === ShiftStage.New && shift.authorId !== shift.userId;
      const isPositionFits = workpostId ? shift.positionId === workpostId : true;
      const shiftFacilityId = shift.facility?.id;
      const isFacilityFits = facilityId ? shiftFacilityId === facilityId : true;
      return isStageFitsAndUserIsnAuthor && isPositionFits && isFacilityFits;
    })

  },[facilityId, hiddenCanceledShifts, notProbationUserShift, shiftsWithoutCanceled, workpostId])
};

export default useNotAcceptedByWorkerShifts;
