import { useCallback, useState } from 'react';

import AcrossSmall from '../../../../Assets/icons/AcrossSmall';
import {
  useAddDirectorToFacilityGroupMutation,
  useRemoveDirectorFromFacilityGroupMutation,
} from '../../../../generated/graphql';
import { useStore } from '../../../../store';
import { errorHandler } from '../../../../utils/errorHandler';
import { userQueryConfigFn } from '../../../../utils/graphqlConfigs/userQueryConfig';
import { errorToast, successToast } from '../../../../utils/notify';
import SimpleSelect from '../../../ui/Select';

const MainInfoAddDirectorToGroup = () => {
  const [directorGroupId, setDirectorGroupId] = useState('');

  const { currentUser, groups } = useStore();

  const userQueryConfig = userQueryConfigFn(currentUser?.id);

  const [addDirectorToFacilityGroup] = useAddDirectorToFacilityGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const [removeDirectorFromFacilityGroup] =
    useRemoveDirectorFromFacilityGroupMutation({
      awaitRefetchQueries: true,
      refetchQueries: [userQueryConfig],
    });

  const addDirectorToFacilityGroupHandler = useCallback(async () => {
    if (currentUser?.id) {
      await addDirectorToFacilityGroup({
        variables: {
          groupId: directorGroupId,
          userId: currentUser.id,
        },
      })
        .then(() => successToast('Директор успешно добавлен'))
        .catch(err => errorToast(errorHandler(err)));
    }
  }, [currentUser?.id, directorGroupId, addDirectorToFacilityGroup]);

  const removeDirectorFromFacilityGroupHandler = useCallback(
    async (groupId: string) => {
      await removeDirectorFromFacilityGroup({
        variables: {
          groupId,
          userId: currentUser?.id || '',
        },
      })
        .then(() => successToast('Директор успешно удален'))
        .catch(err => errorToast(errorHandler(err)));
    },
    [currentUser?.id, removeDirectorFromFacilityGroup]
  );

  return (
    <div className="userPageBlock blockTemplate">
      <span className="Subtitle1 text-smena_text-secondary">
        Ответственность в группах как директора
      </span>
      <div className="flex gap-x-5">
        <SimpleSelect
          divClassName="w-200px"
          label="Группа"
          onChange={e => {
            setDirectorGroupId(e.target.value);
          }}
          value={directorGroupId}
          name="directorGroupId"
          options={groups}
          allOption="Не выбрано"
        />
        <div className="flex items-end">
          <button
            className="btn-secondary"
            onClick={addDirectorToFacilityGroupHandler}
          >
            Добавить
          </button>
        </div>
      </div>
      <div className="flex gap-x-2">
        {currentUser?.groupsDirector?.map(group => (
          <div
            className="py-1 pl-3 pr-2 bg-smena_bb-background rounded-lg items-center flex gap-x-1 group hover:bg-smena_red-extra_light"
            key={group.id}
          >
            <span className="Caption-numbers text-primary group-hover:text-smena_red">
              {group.name}
            </span>
            <button
              onClick={() => {
                removeDirectorFromFacilityGroupHandler(group.id);
              }}
            >
              <AcrossSmall color="text-primary group-hover:text-smena_red" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainInfoAddDirectorToGroup;
