import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ScrollSyncPane } from 'react-scroll-sync';

import ThreeDots from 'src/Assets/icons/ThreeDots';
import { RegularZayavkaScheduleFragment, useDeleteZayavkaScheduleMutation } from 'src/generated/graphql';
import OutsideClick from 'src/hooks/useOutsideClick';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';
import { errorHandler } from 'src/utils/errorHandler';
import { positionName } from 'src/utils/filters/filtersName';
import { zayavkaConfigFn } from 'src/utils/graphqlConfigs/zayavkaConfig';
import { errorToast, successToast } from 'src/utils/notify';
import { useMeRole } from '../../../../hooks/useRole';
import dayjs from 'dayjs';

interface IRequestProfession {
  requestSchedule: RegularZayavkaScheduleFragment;
  setShowEditGraphicSidebar: (value: boolean) => void;
  setShowCreateGraphicSidebar: (value: boolean) => void;
}

const RequestProfession: React.FC<IRequestProfession> = ({
  requestSchedule,
  setShowEditGraphicSidebar,
  setShowCreateGraphicSidebar,
}) => {
  const { positions, setRequestSchedule, request, me } = useStore();
  const [editGraphic, setEditGraphic] = useState(false);

  const router = useParams();
  let requestIdURL = '';

  if (typeof router.requestId === 'string') {
    requestIdURL = router.requestId;
  }

  const isMeSupervisor = useMeRole(roles.Supervisor);
  const isMeAuthor = request?.authorId === me?.id;

  const zayavkaConfig = zayavkaConfigFn(requestIdURL);

  const [deleteZayavkaSchedule] = useDeleteZayavkaScheduleMutation({
    variables: {
      id: requestSchedule?.id || '',
    },
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfig],
  });

  const deleteZayavkaScheduleHandler = () => {
    deleteZayavkaSchedule()
      .then(() => successToast('График удален'))
      .catch(e => errorToast(errorHandler(e)))
      .finally(() => setEditGraphic(false));
  };

  const professionName = useMemo(
    () => positionName(positions, requestSchedule.positionId),
    [positions, requestSchedule.positionId]
  );

  const schedule = useMemo(() => {
    return dayjs(requestSchedule.dateFrom).format('DD.MM') + ' – ' + dayjs(requestSchedule.dateTo).format('DD.MM');
  }, [requestSchedule.dateFrom, requestSchedule.dateTo]);

  return (
    <ScrollSyncPane>
      <div className={clsx('requestRows border-b group requestRow')}>
        <div className="flex items-center justify-between relative">
          <div className="pl-1 flex gap-x-2 Caption-small">
            <span>{professionName}</span>
            <span className="text-smena_text-secondary">{schedule}</span>
          </div>
          <button
            className="mr-2"
            onClick={() => {
              setEditGraphic(value => !value);
            }}
          >
            <ThreeDots className="text-smena_text-disabled group-hover:text-smena_text" />
          </button>
          {editGraphic && (
            <OutsideClick
              show={editGraphic}
              setShow={setEditGraphic}
              className="absolute top-full right-6 transform translate-x-full translate-y-1 bg-smena_white flex flex-col shadow-smena rounded-lg z-10"
            >
              {isMeSupervisor && !isMeAuthor ? (
                <button
                  className="px-3 py-2 Body1 text-left"
                  onClick={() => {
                    setShowCreateGraphicSidebar(false);
                    setEditGraphic(false);
                    setShowEditGraphicSidebar(true);
                    setRequestSchedule(requestSchedule);
                  }}
                >
                  Посмотреть график
                </button>
              ) : (
                <>
                  <button
                    className="px-3 py-2 Body1 text-left"
                    onClick={() => {
                      setShowCreateGraphicSidebar(false);
                      setEditGraphic(false);
                      setShowEditGraphicSidebar(true);
                      setRequestSchedule(requestSchedule);
                    }}
                  >
                    Редактировать
                  </button>
                  <button className="px-3 py-2 Body1 text-left text-smena_red" onClick={deleteZayavkaScheduleHandler}>
                    Удалить
                  </button>
                </>
              )}
            </OutsideClick>
          )}
        </div>
      </div>
    </ScrollSyncPane>
  );
};

export default RequestProfession;
