import {
  ModerationReportsPaginatedDocument,
  ModerationReportsPaginatedQueryVariables,
} from '../../generated/graphql';

interface IRepoetsQueryConfig {
  query: typeof ModerationReportsPaginatedDocument;
  variables: ModerationReportsPaginatedQueryVariables;
}

export const reportsQueryConfig: () => IRepoetsQueryConfig = () => ({
  query: ModerationReportsPaginatedDocument,
  variables: {
    input: {},
  },
});
