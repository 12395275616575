import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import ResponsibleUser from '../../../components/facility/ResponsibleUsers';
import AutocompleteBlock from '../../../components/ui/AutocompleteBlock';
import { useAddManagerToFacilityMutation, useManagersByGroupQuery } from '../../../generated/graphql';
import { IOption } from '../../../Interfaces/IOption';
import { IClient } from '../../../Interfaces/IClient';
import { useStore } from '../../../store';
import { roles } from '../../../utils/constVariables';
import { errorHandler } from '../../../utils/errorHandler';
import { facilityConfigFn } from '../../../utils/graphqlConfigs/facilityConfig';
import { errorToast, successToast } from '../../../utils/notify';

interface IFacilityManagersDirectors {
  groupId?: string | null;
  clientDirectors?: IClient[] | null;
  clientManagers?: IClient[] | null;
}

const FacilityManagersDirectors: React.FC<IFacilityManagersDirectors> = ({
  groupId,
  clientDirectors,
  clientManagers,
}) => {
  const router = useParams();

  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }
  const { me } = useStore();

  const [manager, setManager] = useState<IOption | null>(null);

  const adminSuper = me && [roles.Admin, roles.Supervisor].includes(me.role);
  const adminSuperDirector = me && [roles.Admin, roles.Supervisor, roles.ClientDirector].includes(me.role);

  const { data: dataManagers } = useManagersByGroupQuery({
    variables: {
      groupId: groupId || '',
    },
  });

  const managers = dataManagers?.managersByGroup;

  const managerOptions = managers?.map(manager => {
    const { firstname, lastname, id } = manager;
    return {
      name: lastname + ' ' + firstname,
      id,
    };
  });

  const facilityConfig = facilityConfigFn(facilityId);

  const [addManagerToFacility] = useAddManagerToFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilityConfig],
  });

  const addManagerToFacilityHandler = useCallback(
    async (userId: string) => {
      await addManagerToFacility({
        variables: {
          facilityId,
          userId,
        },
      })
        .then(() => {
          successToast('Менеджер успешно добавлен');
        })
        .catch(err => errorToast(errorHandler(err)));
    },
    [facilityId, addManagerToFacility]
  );

  return (
    <div className="facilityPageBlock blockTemplate">
      <div className="grid grid-cols-8 gap-x-5">
        <span className="Subtitle1 text-smena_text-secondary inline-block col-span-full">Представители</span>
        <div className="col-span-full grid grid-cols-8 gap-x-5 pb-10 mb-5 border-b border-smena_gray-30">
          <div className="sm:col-span-4 col-span-8">
            <div
              className="grid gap-x-2 items-baseline"
              style={{
                gridTemplateColumns: 'max-content 1fr',
              }}
            >
              <span className="Subtitle2">Директор</span>
              <div>
                {clientDirectors?.length ? (
                  clientDirectors.map(clientDirector => (
                    <ResponsibleUser key={clientDirector.id} user={clientDirector} canRemove={false} />
                  ))
                ) : (
                  <span className="Body2 text-smena_text-secondary">Не назначен</span>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-4 col-span-8">
            <div
              className="grid gap-x-2 items-baseline"
              style={{
                gridTemplateColumns: 'max-content 1fr',
              }}
            >
              <span className="Subtitle2">Менеджер</span>
              <div>
                {clientManagers?.length ? (
                  clientManagers.map(clientManager => (
                    <ResponsibleUser key={clientManager.id} user={clientManager} canRemove={adminSuper} />
                  ))
                ) : (
                  <span className="Body2 text-smena_text-secondary">Не назначен</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {adminSuperDirector && (
          <AutocompleteBlock
            id="managerId"
            label="Менеджеры"
            value={manager}
            onChangeHandler={value => setManager(value)}
            options={managerOptions}
            buttonHandler={() => {
              if (manager) {
                addManagerToFacilityHandler(manager.id);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
export default FacilityManagersDirectors;
