import { useState } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';

import { useCreatePollsReportMutation } from '../../generated/graphql';
import { errorHandler } from '../../utils/errorHandler';
import { months } from '../../utils/lists/months';
import { errorToast } from '../../utils/notify';
import TimesheetLoader from '../../components/Timesheets/Loader';

const PollsReport = () => {
  const [load, setLoad] = useState(false);
  const [resLink, setResLink] = useState('');

  const [createPollsReport] = useCreatePollsReportMutation();

  return (
    <div className="xs:col-span-4 xs:col-start-1 col-span-full h-fit mt-6 pb-5 px-6 gap-y-5 border rounded-lg shadow-smena bg-smena_white flex flex-col">
      <span className="Subtitle1 text-smena_text-secondary pt-5">
        Новые пользователи, отклики, подписки
      </span>
      <Formik
        initialValues={{
          month: 0,
        }}
        onSubmit={async values => {
          setLoad(true);
          toast.info('Создаем отчет...', {
            theme: 'colored',
            type: 'info',
            toastId: 'infoToast',
          });
          createPollsReport({
            variables: {
              input: {
                month: Number(values.month),
              },
            },
          })
            .then(async response => {
              if (response.data?.createPollsReport) {
                setLoad(false);
                const filePath = response.data.createPollsReport.filePath;
                const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
                setResLink(url);
                toast.dismiss('infoToast');
              }
            })
            .catch(e => {
              setLoad(false);
              toast.dismiss('infoToast');
              setTimeout(() => {
                errorToast(errorHandler(e));
              }, 1000);
            });
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => {
          return (
            <>
              <form
                onSubmit={handleSubmit}
                className="flex xs:flex-row flex-col xs:items-end gap-x-5 flex-wrap"
              >
                <div className="crudUserInput inputHeight">
                  <label htmlFor="month" className="label-primary">
                    Месяц
                  </label>
                  <select
                    required
                    onChange={e => {
                      handleChange(e);
                      setResLink('');
                    }}
                    onBlur={handleBlur}
                    value={values.month}
                    id="month"
                    name="month"
                    autoComplete="month"
                    className="select-primary"
                  >
                    {months?.map(m => {
                      return (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="paddingForSelects">
                  <button
                    type="submit"
                    disabled={load || Boolean(resLink)}
                    className="btn-secondary_big"
                  >
                    Сформировать
                  </button>
                </div>
              </form>
              <TimesheetLoader load={load} resLink={resLink} />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default PollsReport;
