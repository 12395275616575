import { useCallback, useState } from 'react';
import dayjs from 'dayjs';

import { ModerationStatus, useCreateShiftInModerationMutation } from '../../../../../generated/graphql';

import { useStore } from '../../../../../store';

import SelectArrow from '../../../../../Assets/icons/SelectArrow';
import SimpleInput from '../../../../../components/ui/Input';
import { LunchDurationSelect } from '../../../../ui/Select/LunchDurationSelect';
import { groupName } from '../../../../../utils/filters/filtersName';
import { auchanLunchDurationTypes, lunchDurationTypes } from '../../../../../utils/lists/lunchDurationTypes';
import { lunchDurationHandle } from '../../../../../utils/lunchDuration';
import { timeDifference } from '../../../../../utils/timeDifference';

import { errorToast, successToast } from '../../../../../utils/notify';
import { moderationReportConfigFn } from '../../../../../utils/graphqlConfigs/moderationReportConfig';
import ShiftAproveBlock from './ShiftAproveBlock';
import { errorHandler } from '../../../../../utils/errorHandler';
import TimePickerInput from '../../../../ui/Pickers/TimePicker';
import moment from 'moment';

export const CreateShifts = () => {
  const [show, setShow] = useState(true);
  const [startTime, setStartTime] = useState<moment.Moment | null>(null);
  const [endTime, setEndTime] = useState<moment.Moment | null>(null);
  const [shiftDuration, setShiftDuration] = useState<moment.Moment | null>(null);
  const [lunchDuration, setLunchDuration] = useState(0);
  const [hourRate, setHourRate] = useState(0);
  const [toPayment, setToPayment] = useState(true);
  const [value, setValue] = useState(0);
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [showComment, setShowComment] = useState(false);

  const { moderationReport, userInfo, info, groups, currentModerationRow } = useStore();

  const unit = currentModerationRow?.unit;

  const Hour = unit === 'HOUR';
  const Piece = unit === 'PIECE';

  const facility = moderationReport?.getModerationReport.report.facility;

  const radioHandler = useCallback((value: string) => {
    setToPayment(Boolean(value));
  }, []);

  const moderationReportConfig = moderationReportConfigFn(moderationReport?.getModerationReport.report.id);

  const moderationStatus = moderationReport?.getModerationReport.report.status;

  const sendStatus = moderationStatus === ModerationStatus.Sent;

  const [createShift] = useCreateShiftInModerationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [moderationReportConfig],
  });

  const currentDate = useCallback(
    (time: moment.Moment | null) => {
      const current = dayjs(info?.dayNum);
      return String(
        time?.set('year', current.year()).set('month', current.month()).set('date', current.date()).valueOf()
      );
    },
    [info]
  );

  const getMinutes = useCallback((date: moment.Moment | null) => {
    if (date) {
      return date.get('hours') * 60 + date.get('minutes');
    }
    return 0;
  }, []);

  return (
    <div className={`border-b border-smena_gray-30 ${show ? 'pb-5' : ''}`}>
      <div
        className="flex justify-between py-5"
        onClick={() => {
          setShow(!show);
        }}
      >
        <span className="Button1">Добавить новую смену</span>
        <div>
          <span className={`inline-flex transform transition-transform ${show && 'rotate-180'}`}>
            <SelectArrow className="text-smena_text" />
          </span>
        </div>
      </div>

      {show && (
        <div className="flex flex-col gap-y-2">
          <div className="sidebar__shift grid-cols-3 gap-x-2">
            <TimePickerInput
              //check
              label="Начало смены"
              className="col-span-1 inputHeight"
              value={startTime}
              onChange={startTime => {
                setStartTime(startTime);
                if (groupName(groups, facility?.facilityGroupId) === 'Ашан') {
                  setLunchDuration(lunchDurationHandle(timeDifference(startTime, endTime)));
                }
              }}
            />
            <TimePickerInput
              //check
              label="Конец смены"
              className="col-span-1 inputHeight"
              popupClassName="fixed"
              value={endTime}
              onChange={endTime => {
                setEndTime(endTime);
                if (groupName(groups, facility?.facilityGroupId) === 'Ашан') {
                  setLunchDuration(lunchDurationHandle(timeDifference(startTime, endTime)));
                }
              }}
            />
            <LunchDurationSelect
              divClassName="col-span-1"
              label="Обед"
              required
              onChange={e => {
                if (groupName(groups, facility?.facilityGroupId) !== 'Ашан') {
                  setLunchDuration(Number(e.target.value));
                }
              }}
              value={lunchDuration}
              name="lunchDuration"
              autoComplete="lunchDuration"
              options={
                groupName(groups, facility?.facilityGroupId) === 'Ашан' ? auchanLunchDurationTypes : lunchDurationTypes
              }
              disabled={groupName(groups, facility?.facilityGroupId) === 'Ашан'}
            />
          </div>
          <div className="sidebar__shift grid-cols-2 gap-x-2">
            <span className="col-span-2 Caption text-smena_text-secondary mb-2">
              {Hour ? 'Почасовая оплата' : Piece ? 'Сдельная оплата, заказы' : 'Сдельная оплата, проценты'}
            </span>
            {Hour ? (
              <TimePickerInput
                //check
                label="Количество часов"
                className="col-span-1 inputHeight"
                value={shiftDuration}
                onChange={shiftDuration => {
                  setShiftDuration(shiftDuration);
                }}
                placeholder={startTime?.format('HH:mm')}
              />
            ) : (
              <div className="col-span-1 inputHeight">
                <SimpleInput
                  divClassName="col-span-1"
                  label={Piece ? 'Выполнено заказов' : 'Процент выполнения'}
                  onChange={({ target: { value } }) => {
                    if (/^\d+$/.test(value) || value === '') {
                      setValue(Number(value));
                    }
                  }}
                  onBlur={undefined}
                  value={value}
                  type="text"
                  name="value"
                />
              </div>
            )}
            <SimpleInput
              divClassName="col-span-1"
              label={Hour ? 'Ставка, ₽/час' : Piece ? 'Ставка, ₽/шт.' : 'Ставка, ₽/%'}
              onChange={({ target: { value } }) => {
                if (/^\d+$/.test(value) || value === '') {
                  setHourRate(Number(value));
                }
              }}
              // regText={true}
              onBlur={undefined}
              value={hourRate}
              type="text"
              name="hourRate"
            />
          </div>
          <ShiftAproveBlock
            toPayment={toPayment}
            sendStatus={sendStatus}
            radioHandler={radioHandler}
            comment={comment}
            setComment={setComment}
            showComment={showComment}
            setShowComment={setShowComment}
            reason={reason}
            setReason={setReason}
          />
          <div className="grid grid-cols-4 items-center">
            <div className="col-span-1 col-start-4 self-end">
              <button
                type="button"
                className="btn-primary_small mt-6"
                onClick={() => {
                  createShift({
                    variables: {
                      moderation: {
                        cause: reason,
                        comment,
                        duration: getMinutes(shiftDuration),
                        toPayment,
                        hourRate,
                        value,
                      },
                      shift: {
                        dateFrom: currentDate(startTime),
                        shiftEndDate:
                          Number(endTime) < Number(startTime)
                            ? String(
                                dayjs(Number(currentDate(endTime)))
                                  .add(1, 'day')
                                  .valueOf()
                              )
                            : currentDate(endTime),
                        facilityId: facility?.id || '',
                        lunchDuration,
                        positionId: userInfo?.position?.id || '',
                        duration: String(timeDifference(startTime, endTime)),
                        userId: userInfo?.userFio.id,
                      },
                    },
                  })
                    .then(e => {
                      if (e.data?.createShiftInModeration) {
                        successToast('Смена успешно создана');
                      }
                    })
                    .catch(e => {
                      errorToast(errorHandler(e));
                    });
                }}
              >
                Добавить
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
