export const getStatus = (status: string): string | undefined => {
  const statusList = [
    {
      status: 'NEW',
      statusRu: 'Новая, ожидает подтверждения',
    },
    {
      status: 'ACCEPTED',
      statusRu: 'Подтверждена, запланирована',
    },
    {
      status: 'WORKING',
      statusRu: 'В работе',
    },
    {
      status: 'FINISHED',
      statusRu: 'Завершена',
    },
    {
      status: 'WASTED',
      statusRu: 'Не выполнена',
    },
    {
      status: 'INELIGIBLE',
      statusRu: 'Отклонена',
    },
    {
      status: 'REFUSED',
      statusRu: 'Отменена',
    },
  ];

  return statusList.find(item => status === item.status)?.statusRu;
};
