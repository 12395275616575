import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import GarbageArrowless from 'src/Assets/icons/GarbageArrowless';
import PaperAirPlane from 'src/Assets/icons/PaperAirPlane';
import PencilIcon from 'src/Assets/icons/PencilIcon';
import Tooltip from 'src/components/ui/Tooltip';
import { ZayavkaChangesStatus, ZayavkaStage, ZayavkaToCancelStatus } from 'src/generated/graphql';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';

interface IStatusIcon {
  request?: {
    status: ZayavkaStage;
    zayavkaChanges?: { authorId: string; stage: ZayavkaChangesStatus }[] | null;
    toCancel: ZayavkaToCancelStatus;
  };
}
const StatusIcon: React.FC<IStatusIcon> = ({ request }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { me } = useStore();
  const meManager = me?.role === roles.ClientManager;
  const meDirector = me?.role === roles.ClientDirector;
  const meSupervisor = me?.role === roles.Supervisor;

  const showPlaneChanges = request?.zayavkaChanges?.filter(
    change => change.stage === ZayavkaChangesStatus.Waiting || change.stage === ZayavkaChangesStatus.Ineligible
  );

  const changesByStatus = useCallback(
    (status: ZayavkaChangesStatus) => request?.zayavkaChanges?.filter(change => change.stage === status),
    [request?.zayavkaChanges]
  );

  const zayavkaStatus = (status: ZayavkaStage) => request?.status === status;

  const myChanges = useMemo(
    () => request?.zayavkaChanges?.find(change => change.authorId === me?.id),
    [request?.zayavkaChanges, me?.id]
  );
  const showPlaneChangesEl = (status: ZayavkaChangesStatus) => {
    return showPlaneChanges?.find(change => change.stage === status);
  };

  const showPlane =
    (meManager &&
      zayavkaStatus(ZayavkaStage.Draft) &&
      (myChanges?.stage === ZayavkaChangesStatus.Waiting || showPlaneChangesEl(ZayavkaChangesStatus.Ineligible))) ||
    (meDirector && request?.status === ZayavkaStage.Draft && Boolean(showPlaneChanges?.length));

  const showPencil = meSupervisor
    ? request?.status === ZayavkaStage.Working &&
      (Boolean(changesByStatus(ZayavkaChangesStatus.New)?.length) ||
        Boolean(changesByStatus(ZayavkaChangesStatus.Refused)?.length))
    : request?.status === ZayavkaStage.Working && Boolean(request?.zayavkaChanges?.length);

  const showGarbage =
    ((meManager || meDirector) && request?.toCancel === ZayavkaToCancelStatus.Waiting) ||
    request?.toCancel === ZayavkaToCancelStatus.Ineligible;

  const content = () => {
    if (showGarbage) {
      if (request?.toCancel === ZayavkaToCancelStatus.Waiting) {
        return {
          text: 'Отмена ожидает подтверждение',
          textColor: 'text-smena_orange',
          icon: 'bg-smena_orange-extra_light',
          blockBg: 'bg-smena_orange-extra_light',
          blockBorder: 'border-smena_orange-extra_light',
          statusShowTooltip: true,
        };
      }
      if (request?.toCancel === ZayavkaToCancelStatus.Ineligible) {
        return {
          text: 'Отмена отклонена',
          textColor: 'text-smena_red',
          icon: 'bg-smena_red-extra_light',
          blockBg: 'bg-smena_red-extra_light',
          blockBorder: 'border-smena_red-extra_light',
          statusShowTooltip: true,
        };
      }
    }
    if (showPlane) {
      if (myChanges?.stage === ZayavkaChangesStatus.Waiting || showPlaneChangesEl(ZayavkaChangesStatus.Waiting)) {
        return {
          text: 'Ожидает подтверждение',
          textColor: 'text-smena_orange',
          icon: 'border border-smena_orange',
          blockBg: 'bg-smena_yellow-extra_light',
          blockBorder: 'border-smena_yellow-extra_light',
          statusShowTooltip: true,
        };
      }
      if (myChanges?.stage === ZayavkaChangesStatus.Ineligible || showPlaneChangesEl(ZayavkaChangesStatus.Ineligible)) {
        return {
          text: 'Отклонено',
          textColor: 'text-smena_red',
          icon: 'border border-smena_red',
          blockBg: 'bg-smena_red-extra_light',
          blockBorder: 'border-smena_red-extra_light',
          statusShowTooltip: true,
        };
      }
    }
    if (showPencil) {
      if (Boolean(changesByStatus(ZayavkaChangesStatus.Waiting)?.length)) {
        return {
          text: 'Изменение ожидает подтверждение',
          textColor: 'text-smena_orange',
          icon: 'bg-smena_orange-extra_light',
          blockBg: 'bg-smena_orange-extra_light',
          blockBorder: 'border-smena_orange-extra_light',
          statusShowTooltip: true,
        };
      }
      if (Boolean(changesByStatus(ZayavkaChangesStatus.Draft)?.length)) {
        return {
          text: '',
          textColor: '',
          icon: 'border border-smena_text-secondary',
          blockBg: '',
          blockBorder: '',
          statusShowTooltip: false,
        };
      }
      if (Boolean(changesByStatus(ZayavkaChangesStatus.Refused)?.length)) {
        return {
          text: meSupervisor ? 'Изменение отклонено' : 'Изменение отклонено супервайзером',
          textColor: 'text-smena_red',
          icon: 'bg-smena_red-extra_light',
          blockBg: 'bg-smena_red-extra_light',
          blockBorder: 'border-smena_red-extra_light',
          statusShowTooltip: true,
        };
      }
      if (Boolean(changesByStatus(ZayavkaChangesStatus.Ineligible)?.length)) {
        return {
          text: 'Изменение отклонено',
          textColor: 'text-smena_red',
          icon: 'bg-smena_red-extra_light',
          blockBg: 'bg-smena_red-extra_light',
          blockBorder: 'border-smena_red-extra_light',
          statusShowTooltip: true,
        };
      }
      if (Boolean(changesByStatus(ZayavkaChangesStatus.New)?.length)) {
        return {
          text: meSupervisor ? 'Новое изменение' : 'Изменение отправлено',
          textColor: 'text-primary',
          icon: 'bg-smena_bb-background',
          blockBg: 'bg-smena_bb-background',
          blockBorder: 'border-smena_bb-background',
          statusShowTooltip: true,
        };
      }
    }
    return {
      text: '',
      textColor: '',
      icon: '',
      blockBg: '',
      blockBorder: '',
      statusShowTooltip: false,
    };
  };

  // <GarbageArrowless className={content().textColor} />
  const { blockBg, blockBorder, icon, text, textColor, statusShowTooltip } = content();
  return (
    <>
      {(showPlane || showPencil || showGarbage) && (
        <button
          className="relative flex"
          onMouseEnter={() => {
            setShowTooltip(true);
          }}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}
        >
          <span className={clsx('w-6 h-6 rounded-full flex justify-center items-center', icon)}>
            {showGarbage ? (
              <GarbageArrowless className={textColor} />
            ) : showPlane ? (
              <PaperAirPlane className={textColor} />
            ) : showPencil ? (
              <PencilIcon className={textColor} />
            ) : null}
          </span>
          {showTooltip && statusShowTooltip && (
            <Tooltip content={text} blockClassName={blockBg + ' ' + blockBorder} spanClassName={textColor} />
          )}
        </button>
      )}
    </>
  );
};

export default StatusIcon;
