import { useState } from 'react';

import NotificationBell from '../../Assets/icons/NotificationBell';
import { useNotificationsQuery } from '../../generated/graphql';
import Profile from './Profile';
import NotificationSidebar from './Sidebar';

const NotificationBlock = () => {
  const [showNotificationSidebar, setShowNotificationSidebar] = useState(false);
  const { data: notificationData } = useNotificationsQuery();
  const notifications = notificationData?.notifications;

  const unReadNotifications = notifications?.filter(
    notification => !notification.isRead
  );
  return (
    <>
      <div className="flex gap-x-4 items-center">
        <button
          className="relative"
          onClick={() => {
            if (notifications?.length) {
              setShowNotificationSidebar(value => !value);
            }
          }}
        >
          <NotificationBell />
          <span className="absolute top-0 right-0 Button2 text-smena_white h-5 w-5 bg-smena_red flex justify-center items-center rounded-full">
            {unReadNotifications?.length}
          </span>
        </button>
        <Profile />
      </div>
      <NotificationSidebar
        setShowNotificationSidebar={setShowNotificationSidebar}
        showNotificationSidebar={showNotificationSidebar}
        notifications={notifications}
      />
    </>
  );
};

export default NotificationBlock;
