import { useMemo } from 'react';

interface IUseFacilityBreadcrums {
  facilityId: string;
}

const useFacilityBreadcrumbs = ({ facilityId }: IUseFacilityBreadcrums) => {
  return useMemo(
    () => [
      { path: '/facilities', name: 'Объекты' },
      facilityId
        ? { path: '/facilities/' + facilityId, name: 'Страница объекта' }
        : { path: '/facilities/create', name: 'Новый объект' },
    ],
    [facilityId]
  );
};

export default useFacilityBreadcrumbs;
