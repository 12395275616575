import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import 'dayjs/locale/ru';

import App from './pages/routes';
import reportWebVitals from './reportWebVitals';
import { initializeApollo } from './lib/apolloClient';

import './styles/ant.css';
import './styles/globals.css';
import './styles/readOnlyPages.css';
import './styles/pdf.css';
import { isProd } from './utils/constVariables';

const client = initializeApollo();

if (isProd) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
    tracesSampler: () => (process.env.NODE_ENV === 'production' ? 1 : 0.2),
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
      <ToastContainer
        transition={Slide}
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  </ApolloProvider>
);

reportWebVitals();
