import { useMemo } from 'react';
import { RegularUserShiftsFragment } from 'src/generated/graphql';
import useSuperFacilities from '../../hooks/useSuperFacilities';

interface IUseSupervisorShifts {
  userShifts?: RegularUserShiftsFragment[];
}

const useSupervisorShifts = ({ userShifts }: IUseSupervisorShifts) => {
  const supervisorFacilities = useSuperFacilities();
  return useMemo(
    () => userShifts?.filter(shift => supervisorFacilities.find(superFac => superFac?.id === shift?.facility?.id)),
    [userShifts, supervisorFacilities]
  );
};

export default useSupervisorShifts;
