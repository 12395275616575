import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useCreateZayavkaShiftByClientMutation } from 'src/generated/graphql';
import { zayavkaConfigFn } from 'src/utils/graphqlConfigs/zayavkaConfig';
import { errorToast, successToast } from 'src/utils/notify';
import { errorHandler } from 'src/utils/errorHandler';
import { LunchDurationSelect } from 'src/components/ui/Select/LunchDurationSelect';
import { useStore } from 'src/store';
import { groupName, positionName } from 'src/utils/filters/filtersName';
import { auchanLunchDurationTypes, lunchDurationTypes } from 'src/utils/lists/lunchDurationTypes';
import { timeDifference } from 'src/utils/timeDifference';
import { lunchDurationHandle } from 'src/utils/lunchDuration';
import TimePickerInput from '../../../ui/Pickers/TimePicker';
import moment from 'moment/moment';
import MainSidebar from 'src/components/ui/Sidebar';
import { RU_DATE } from '../../../../utils/constVariables';

const NewShiftSidebar: React.FC = () => {
  const {
    positions,
    request,
    groups,
    showCreateRequestShiftSidebar,
    setShowCreateRequestShiftSidebar,
    requestSchedule,
    requestDayAtRow,
  } = useStore();
  const router = useParams();
  let requestIdURL = '';

  const day = dayjs(requestDayAtRow?.day);

  if (typeof router.requestId === 'string') {
    requestIdURL = router.requestId;
  }

  const [shiftStartTime, setShiftStartTime] = useState<moment.Moment | null>(null);
  const [shiftEndTime, setShiftEndTime] = useState<moment.Moment | null>(null);
  const [lunchDuration, setLunchDuration] = useState(0);

  const zayavkaConfig = zayavkaConfigFn(requestIdURL);

  const zayavkaRefetch = {
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfig],
  };

  const [createZayavkaShiftByClient] = useCreateZayavkaShiftByClientMutation(zayavkaRefetch);

  const createZayavkaShiftByClientHandler = async () => {
    await createZayavkaShiftByClient({
      variables: {
        zayavkaScheduleId: String(requestSchedule?.id),
        input: {
          lunchDuration,
          dateFrom: shiftStartTime
            ?.set('date', day.get('date'))
            .set('month', day.get('month'))
            .set('year', day.get('year')),
          dateEnd: shiftEndTime
            ?.set('date', day.get('date'))
            .set('month', day.get('month'))
            .set('year', day.get('year')),
        },
      },
    })
      .then(() => successToast('Смена добавлена'))
      .catch(e => errorToast(errorHandler(e)));
  };

  const facilityGroup = useMemo(
    () => groupName(groups, request?.facility?.facilityGroupId),
    [groups, request?.facility?.facilityGroupId]
  );

  return (
    <MainSidebar title="Новая смена" show={showCreateRequestShiftSidebar} setShow={setShowCreateRequestShiftSidebar}>
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-1">
          <span className="H4">{positionName(positions, requestDayAtRow?.positionId)}</span>
          <span className="Subtitle2 text-smena_text-secondary">{dayjs(requestDayAtRow?.day).format(RU_DATE)}</span>
        </div>
        <hr className="bg-smena_gray-30" />
        <div className="grid grid-cols-2 gap-x-6">
          <TimePickerInput
            //check
            label="Начало смены"
            className="col-span-1 inputHeight"
            value={shiftStartTime}
            onChange={timeValue => {
              setShiftStartTime(timeValue);
              if (facilityGroup === 'Ашан') {
                setLunchDuration(lunchDurationHandle(timeDifference(timeValue, shiftEndTime)));
              }
            }}
          />
          <TimePickerInput
            //check
            label="Конец смены"
            className="col-span-1 inputHeight"
            value={shiftEndTime}
            onChange={timeValue => {
              setShiftEndTime(timeValue);
              if (facilityGroup === 'Ашан') {
                setLunchDuration(lunchDurationHandle(timeDifference(shiftStartTime, timeValue)));
              }
            }}
          />
          <LunchDurationSelect
            divClassName="col-span-1"
            label="Обед"
            required
            onChange={e => {
              if (facilityGroup !== 'Ашан') {
                setLunchDuration(Number(e.target.value));
              }
            }}
            value={lunchDuration}
            name="lunchDuration"
            autoComplete="lunchDuration"
            options={facilityGroup === 'Ашан' ? auchanLunchDurationTypes : lunchDurationTypes}
            disabled={facilityGroup === 'Ашан'}
          />
        </div>
        <div className="flex justify-end gap-x-6">
          <button className="btn-primary" onClick={createZayavkaShiftByClientHandler}>
            Создать
          </button>
        </div>
      </div>
    </MainSidebar>
  );
};

export default NewShiftSidebar;
