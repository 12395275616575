import React from 'react';
import { GoolePlay } from '../Assets/icons/googlePlay';

const WIP: React.FC = () => {
  return (
    <div className="bg-gray-02 min-h-screen flex flex-col justify-center items-center">
      <div className="mb-3">
        Для входа в ваш личный кабинет, используйте мобильное приложение.
      </div>
      <a
        href="https://play.google.com/store/apps/details?id=com.ecsapp"
        target="_blank"
        rel="noopener noreferrer"
        className="mb-3 text-primary hover:underline w-1/12"
      >
        <GoolePlay />
      </a>
    </div>
  );
};

export default WIP;
