import { useMemo } from 'react';
import { useStore } from '../../../store';
import ManualUserAccrualUser from './manualUserAccrualUser';

const ManualUserAccruals = () => {
  const { manualAccural } = useStore();
  const columns = useMemo(
    () => [
      { id: 1, name: 'ФИО' },
      { id: 2, name: 'Выплачено' },
      { id: 3, name: 'Дата выплаты' },
    ],
    []
  );

  return (
    <div className="pb-10">
      <div
        className="grid md:gap-3"
        style={{ gridTemplateColumns: '300px 150px 200px' }}
      >
        {columns.map(column => (
          <div
            key={column.id}
            className="p-2 text-left text-xs font-medium text-smena_gray-50 uppercase tracking-wider"
          >
            {column.name}
          </div>
        ))}
      </div>
      {manualAccural?.manualUserAccruals?.map(manualUserAccrual => (
        <ManualUserAccrualUser
          key={manualUserAccrual.id}
          user={manualUserAccrual}
        />
      ))}
    </div>
  );
};

export default ManualUserAccruals;
