import { useCallback, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { FilterPeriod, MetricTypes, useGetMetricsQuery } from '../../../../generated/graphql';

import { useStore } from '../../../../store';
import SimpleSelect from '../../../ui/Select';
import { ITooltipStatus } from '../../../../Interfaces/ITooltipStatus';
import { getTimePeriodCharts } from '../../../../utils/get/getTimeePeriodCharts';
import { chartTimes } from '../../../../utils/lists/chartTimes';
import { tickFormatterHandlerFn } from '../../../../utils/charts/tickFormatter';
import { labelFormatterHandler } from '../../../../utils/charts/getLabelFormaterCharts';
import { chartClick, chartHover } from '../../../../utils/charts/chartHandlers';
import { lastTimeLength } from '../../../../utils/charts/lastTimeLength';
import { intervalChart } from '../../../../utils/charts/intervalChart';
import { useGetMetrics, useTypes } from '../../hooks';
import { allValue } from '../helpers';

const WorkerOnShiftChart = () => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  const [time, setTime] = useState(FilterPeriod.Day);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });

  const types = useTypes({
    facility: MetricTypes.ShiftWorkersFacility,
    group: MetricTypes.ShiftWorkersFacilityGroup,
    all: MetricTypes.ShiftWorkersAll,
  });

  const { data } = useGetMetricsQuery({
    variables: {
      input: {
        facilityId: allValue(trendsFacilityId),
        facilityGroupId: allValue(trendsGroupId),
        filterPeriod: time,
        types,
      },
    },
  });

  const handleTimeChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setTime(value as FilterPeriod);
  }, []);

  const res = useGetMetrics({
    getMetrics: data?.getMetrics,
    facilityMetricType: MetricTypes.ShiftWorkersFacility,
    facilityGroupMetricType: MetricTypes.ShiftWorkersFacilityGroup,
    allMetricType: MetricTypes.ShiftWorkersAll,
  });

  const tickFormatterHandler = useCallback(
    (value: any) => {
      if (value && value !== 'auto') {
        return tickFormatterHandlerFn(value, time);
      }
      return '0';
    },
    [time]
  );

  return (
    <div className="px-5">
      <div className="flex justify-between items-center">
        <span className="Subtitle1 text-smena_text-secondary">Исполнители на смене</span>
        <div className="flex">
          <SimpleSelect
            divClassName="chart-filter"
            label=""
            onChange={handleTimeChange}
            value={time}
            sort={false}
            name="time"
            options={chartTimes}
          />
        </div>
      </div>
      <div className="flex gap-x-1 items-end mt-5 mb-2">
        <span className="ChartNumber text-smena_text">{lastTimeLength(time, res)}</span>
        <span className="Caption text-smena_text-secondary">за {getTimePeriodCharts(time)}</span>
      </div>
      <div className="chartTrendBlock">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={res}
            margin={{ right: 27, bottom: 5 }}
            onClick={state => {
              if (state !== null) {
                if (tooltipStatus.barId === state.activeTooltipIndex) {
                  setTooltipStatus(chartHover(state));
                } else {
                  setTooltipStatus(chartClick(state));
                }
              }
            }}
          >
            <XAxis
              dataKey={res ? 'createdAt' : undefined}
              interval={intervalChart(time)}
              tickFormatter={tickFormatterHandler}
              height={30}
              angle={-25}
              tickMargin={10}
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              trigger={tooltipStatus.status}
              cursor={false}
              labelFormatter={(label: string) => {
                return labelFormatterHandler({ label, time });
              }}
              formatter={(value: string) => {
                return [value, 'Исполнители на смене'];
              }}
            />
            <Area type="monotone" dataKey="value" stroke="#2CD7B8" fill="#2CD7B840" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default WorkerOnShiftChart;
