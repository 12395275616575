import useMenuElement from 'src/hooks/useMenuElements';
import { useStore } from 'src/store';
import MenuElementMobile from '../Element/MobileElement';
import MobileSubMenu from '../Submenu/MobileSubMenu';

const MobileMenuElements = () => {
  const { me } = useStore();
  const menuElements = useMenuElement(me?.role);
  return (
    <>
      {menuElements.map(item => {
        if (item.submenu) {
          return <MobileSubMenu key={item.id} item={item} />;
        }
        return <MenuElementMobile key={item.id} item={item} />;
      })}
    </>
  );
};

export default MobileMenuElements;
