import dayjs from 'dayjs';
import { FilterPeriod } from '../../generated/graphql';
import { RU_DATE } from '../constVariables';

export const labelFormatterHandler = ({ label, time }: { label: string; time: FilterPeriod }) => {
  switch (time) {
    case FilterPeriod.Day:
      return dayjs(Number(label)).format('HH:mm');
    case FilterPeriod.Week:
      return dayjs(Number(label)).format('DD MMMM');
    case FilterPeriod.Month:
      return dayjs(Number(label)).format('DD MMMM');
    case FilterPeriod.HalfYear:
      return dayjs(Number(label)).format('MMMM YYYY');
    case FilterPeriod.Year:
      return dayjs(Number(label)).format('MMMM YYYY');
    default:
      return dayjs(Number(label)).format(RU_DATE);
  }
};
