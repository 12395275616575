import {
  FacilityGroupDocument,
  FacilityGroupQueryVariables,
} from '../../generated/graphql';

interface IGroupsQueryConfigFn {
  query: typeof FacilityGroupDocument;
  variables: FacilityGroupQueryVariables;
}

export const groupQueryConfigFn = (id?: string): IGroupsQueryConfigFn => ({
  query: FacilityGroupDocument,
  variables: {
    id: id || '',
  },
});
