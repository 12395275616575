import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useFacilityLazyQuery } from '../../generated/graphql';

import { useStore } from '../../store';

import MainLayout from '../../components/Layout/main';
import FacilityForemenSupervisors from './FacilityResponsibility/ForemanSupervisor';
import FacilityManagersDirectors from './FacilityResponsibility/ManagersDirectors';
import { roles } from 'src/utils/constVariables';
import useFacilityHeaderMenu from 'src/hooks/headerMenu/useFacilityHeaderMenu';
import useFacilityBreadcrumbs from 'src/hooks/breadcrumbs/useFacilityBreadcrumbs';
import FacilityPenalty from 'src/components/facility/FacilityPenalty';
import useFacility from './hooks/useFacility';
import MainInfoFacility from 'src/components/facility/MainInfo';
import FacilityAccess from 'src/components/facility/FacilityAccess';
import FacilityMapAndCoordinates from 'src/components/facility/MapAndCoordinates';

const UpdateFacility: React.FC = () => {
  const [address, setAddress] = useState({ city: '', address: '' });

  const { me, cities } = useStore();

  const [param] = useSearchParams();

  const router = useParams();

  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }

  let groupId = param.get('groupId');

  const [loadData, { data, loading }] = useFacilityLazyQuery({
    variables: {
      id: facilityId,
    },
  });

  const facility = data?.facility;

  const clientDirectors = facility?.facilityGroup?.clientDirectors;
  const clientManagers = facility?.clientManagers;

  const title = () => {
    if (groupId) {
      return 'Новый объект группы';
    }
    if (facility?.id) {
      return `Изменить объект ${facility?.name}`;
    }
    return 'Создать объект';
  };

  const isMeAdmin = me && [roles.Admin].includes(me.role);
  const isMeAdminOrSuper = me && [roles.Admin, roles.Supervisor].includes(me.role);

  const headerMenu = useFacilityHeaderMenu({
    currentLocation: 'Информация',
    facilityId,
    isMeAdmin,
  });

  const breadCrumbs = useFacilityBreadcrumbs({ facilityId });

  const { initialValues, validate, onSubmit } = useFacility({
    facility,
    groupId,
    facilityId,
  });

  useEffect(() => {
    if (facility?.cityId || facility?.address) {
      setAddress(fullAddress => ({
        ...fullAddress,
        city: String(facility.city?.name),
        address: String(facility.address),
      }));
    }
  }, [setAddress, facility, cities]);

  useEffect(() => {
    loadData({ variables: { id: facilityId } });
  }, [facilityId, loadData]);

  if (loading) {
    return null;
  }

  return (
    <MainLayout title={title()} bg="bg-smena_bb-background_base" headerMenu={headerMenu} breadCrumbs={breadCrumbs}>
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, isSubmitting }) => {
          return (
            <Form className="grid grid-cols-12 md:gap-x-6 gap-y-6">
              <MainInfoFacility
                handleChange={handleChange}
                errors={errors}
                setAddress={setAddress}
                touched={touched}
                values={values}
              />
              {me?.role === roles.Admin && <FacilityAccess handleChange={handleChange} values={values} />}
              <FacilityMapAndCoordinates
                address={address}
                errors={errors}
                handleChange={handleChange}
                touched={touched}
                values={values}
              />
              <FacilityManagersDirectors
                groupId={facility?.facilityGroupId}
                clientManagers={clientManagers}
                clientDirectors={clientDirectors}
              />
              <FacilityForemenSupervisors
                facilitySupervisors={facility?.supervisors}
                facilityForemen={facility?.foremen}
              />
              {isMeAdminOrSuper && <FacilityPenalty penalty={facility?.penalty} />}

              {isMeAdminOrSuper && (
                <div className="facilityPageBlock justify-self-end">
                  <button type="submit" disabled={isSubmitting} className="btn-primary_big">
                    <span>{isSubmitting ? 'Загрузка' : facility?.id ? 'Изменить' : 'Создать'}</span>
                  </button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdateFacility;
