import dayjs from 'dayjs';
import dateHour from './dateHour';
import moment, { Moment } from 'moment';

export const shiftEndDateFn = (timeStart: Moment | null, timeEnd: Moment | null, dateStart: dayjs.Dayjs | null) => {
  if (moment(timeEnd).valueOf() - moment(timeStart).valueOf() <= 0) {
    return dayjs(dateHour(dateStart, timeEnd)).add(1, 'day').valueOf();
  }
  return dateHour(dateStart, timeEnd);
};
