import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { errorHandler } from '../../utils/errorHandler';
import { errorToast } from '../../utils/notify';
import { filterFacilities } from '../../utils/filters/filterFacilities';

import {
  useCreateTimesheetByMonthAndFacilityGroupMutation,
  useCreateTimesheetByMonthAndFacilityMutation,
} from '../../generated/graphql';

import SimpleSelect from '../../components/ui/Select';

import { useStore } from '../../store';
import TimesheetLoader from '../../components/Timesheets/Loader';
import { ISO_DATE } from 'src/utils/constVariables';
import { filterCities } from 'src/utils/filters/filterCities';
import uniqueArray from 'src/hooks/uniqueArray';
import DatePickerInput from '../../components/ui/Pickers/DatePickerInput';

const Timesheet = () => {
  const [load, setLoad] = useState(false);
  const [resLink, setResLink] = useState('');

  const { groups, facilities, cities } = useStore();

  const [createTimesheet] = useCreateTimesheetByMonthAndFacilityMutation();
  const [createGroupTimesheet] = useCreateTimesheetByMonthAndFacilityGroupMutation();

  const onSubmit = useCallback(
    (values: any) => {
      setLoad(true);
      const facilityResult = {
        facilityId: values.facility,
        startOfMonth: values.date?.format(ISO_DATE),
      };
      const groupResult = {
        facilityGroupId: values.group,
        cityId: values.city === 'all' ? '' : values.city,
        startOfMonth: values.date?.format(ISO_DATE),
      };

      toast.info('Создаем отчет...', {
        theme: 'colored',
        type: 'info',
        toastId: 'infoToast',
      });
      if (!values.facility || values.facility === 'all') {
        createGroupTimesheet({
          variables: {
            input: groupResult,
          },
        })
          .then(response => {
            if (response.data?.createTimesheetByMonthAndFacilityGroup) {
              setLoad(false);
              const filePath = response.data.createTimesheetByMonthAndFacilityGroup.filePath;
              const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
              setResLink(url);
              toast.dismiss('infoToast');
            }
          })
          .catch(e => {
            setLoad(false);
            errorToast(errorHandler(e));
            toast.dismiss('infoToast');
            setTimeout(() => {
              errorToast(errorHandler(e));
            }, 1000);
          });
      } else {
        createTimesheet({
          variables: {
            input: facilityResult,
          },
        })
          .then(response => {
            if (response.data?.createTimesheetByMonthAndFacility) {
              setLoad(false);
              const filePath = response.data.createTimesheetByMonthAndFacility.filePath;
              const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
              setResLink(url);
              toast.dismiss('infoToast');
            }
          })
          .catch(e => {
            setLoad(false);
            errorToast(errorHandler(e));
            toast.dismiss('infoToast');
            setTimeout(() => {
              errorToast(errorHandler(e));
            }, 1000);
          });
      }
    },
    [createTimesheet, createGroupTimesheet]
  );

  return (
    <div className="xs:col-span-8 col-span-full p-5 gap-y-5 border rounded-lg shadow-smena bg-smena_white flex flex-col">
      <span className="Subtitle1 text-smena_text-secondary">Табель</span>
      <Formik
        initialValues={{
          group: '',
          city: 'all',
          facility: '',
          date: null,
        }}
        validate={values => {
          let errors: any = {};

          if (!values.facility && !values.group) {
            errors.group = 'Обязательное поле';
          }
          if (!values.date && !isNaN(Number(values.date))) {
            errors.date = 'Обязательное поле';
          }
          if (isNaN(Number(values.date))) {
            errors.date = 'Неверный формат ввода даты';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, touched, errors, handleChange, handleBlur }) => {
          const filteredFacilities = filterFacilities(values.group, facilities);
          const filteredCities = filterCities(values.group, facilities)
            .map(facility => cities?.find(city => city.id === facility))
            .filter(Boolean);

          const uniqueCities = uniqueArray({
            array: filteredCities,
            field: 'id',
          });
          return (
            <>
              <Form className="flex xs:flex-row flex-col xs:items-end gap-x-5 flex-wrap">
                <SimpleSelect
                  divClassName="w-[206px]"
                  label="Группа"
                  onChange={e => {
                    handleChange(e);
                    setResLink('');
                    setFieldValue('city', 'all');
                    if (filteredFacilities?.length === 0) {
                      setFieldValue('facility', '');
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.group}
                  name="group"
                  autoComplete="group"
                  disabledOption="Группа"
                  options={groups}
                  validation
                  error={touched.group && errors.group ? errors.group : ''}
                />
                <SimpleSelect
                  divClassName="w-[206px]"
                  label="Город"
                  onChange={e => {
                    handleChange(e);
                    setResLink('');
                    setFieldValue('facility', '');
                  }}
                  onBlur={handleBlur}
                  value={values.city}
                  name="city"
                  autoComplete="city"
                  allOption="Все города"
                  options={uniqueCities}
                  validation
                  error={touched.city && errors.city ? errors.city : ''}
                />

                <SimpleSelect
                  divClassName="w-[206px]"
                  label="Объект"
                  disabled={values.city !== 'all' || filteredFacilities?.length === 0}
                  onChange={e => {
                    handleChange(e);
                    setResLink('');
                  }}
                  onBlur={handleBlur}
                  value={values.facility}
                  name="facility"
                  autoComplete="facility"
                  // disabledOption="Выберите объект"
                  allOption="Все объекты"
                  options={filteredFacilities}
                  validation
                  error={touched.facility && errors.facility ? errors.facility : ''}
                />
                <DatePickerInput
                  //check
                  divClassName="w-[206px] inputHeight"
                  label="Год и месяц"
                  format="MMM YYYY"
                  value={values.date}
                  placeholder={dayjs().format('MMM YYYY')}
                  onChange={newValue => {
                    setFieldValue('date', dayjs(newValue?.startOf('month')).startOf('month'));
                    setResLink('');
                  }}
                  minDate={dayjs('2020-01')}
                  maxDate={dayjs()}
                  picker="month"
                  error={{ bool: Boolean(touched.date && errors.date), text: errors.date }}
                />
                <div className="paddingForSelects">
                  <button
                    type="submit"
                    disabled={load || Boolean(resLink) || filteredFacilities?.length === 0}
                    className="btn-secondary_big"
                  >
                    Сформировать
                  </button>
                </div>
              </Form>
              <TimesheetLoader load={load} resLink={resLink} />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Timesheet;
