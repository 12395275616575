import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckCircle from 'src/Assets/icons/checkCircle';

import Tooltip from 'src/components/ui/Tooltip';
import { useSetSettingMutation } from 'src/generated/graphql';
import convertPhone from 'src/hooks/convertPhone';
import { removeTokens } from 'src/lib/auth';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';
import { getRoleRu } from 'src/utils/get/getRoleRu';
import { errorToast, successToast } from 'src/utils/notify';
import { UserNameSurename } from 'src/utils/userFullName';
import { userInitials } from 'src/utils/userInitials';
import CopyIcon from '../../../Assets/icons/CopyIcon';
import OutsideClick from '../../../hooks/useOutsideClick';

const Profile = () => {
  const { me } = useStore();
  const [showPopup, setShowPopup] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [version, setVersion] = useState('');

  const [setSettingMutation] = useSetSettingMutation();

  const apolloClient = useApolloClient();

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    setShowPopup(value => !value);
  }, []);

  const onCopyClick = useCallback(() => {
    if (!me) return;
    navigator.clipboard.writeText(me.id);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  }, [me]);

  const onClickLogout = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      removeTokens();
      navigate('/login');
      localStorage.removeItem('rightNowChartFacilityGroup');
      localStorage.removeItem('rightNowChartFacilityId');
      localStorage.removeItem('trendsFacilityGroupId');
      localStorage.removeItem('trendsFacilityId');
      apolloClient.clearStore();
    },
    [apolloClient, navigate]
  );

  const buttonHandler = useCallback(() => {
    if (version) {
      if (!new RegExp('[^0-9.]').test(version)) {
        return setSettingMutation({
          variables: {
            value: version,
            name: 'version',
          },
        })
          .then(response => {
            if (response.data?.setSetting) {
              successToast('Версия успешно изменена');
            }
          })
          .catch(() => {
            errorToast('Произошла ошибка');
          });
      } else {
        errorToast('Некорректно введена версия');
      }
    }
  }, [setSettingMutation, version]);

  const inputHandler = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setVersion(value);
    },
    [setVersion]
  );

  const phoneNumber = convertPhone(me?.phone);

  useEffect(() => {
    if (me && me.maxVersion) {
      setVersion(me.maxVersion);
    }
  }, [me]);

  return (
    <div className="relative">
      <div
        className="h-12 w-12 rounded-full bg-smena_bb-background Subtitle1 flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <span className="uppercase">{userInitials(me)}</span>
      </div>
      {showPopup && (
        <OutsideClick setShow={setShowPopup} show={showPopup}>
          <div className="bg-smena_white absolute top-full translate-y-2 right-0 p-6 flex flex-col gap-y-10 shadow-smena rounded-lg z-40">
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1">
                <span className="Subtitle1 text-smena_text">{UserNameSurename(me)}</span>
                <span className="Body1 text-smena_text-secondary">{getRoleRu(me?.role)}</span>
              </div>
              <div className="flex flex-col">
                <span className="Tag text-smena_text-secondary">Телефон</span>
                <span className="Body1 text-smena_text">{phoneNumber}</span>
              </div>
              <div className="flex items-center gap-x-2">
                <div className="flex flex-col">
                  <span className="Tag text-smena_text-secondary">Идентификатор</span>
                  <span className="Body1 text-smena_text">{me?.id}</span>
                </div>
                <button type="button" className="relative" onClick={onCopyClick}>
                  {showTooltip && <Tooltip content="Скопировано" placement="top-center" />}
                  <CopyIcon />
                </button>
              </div>
              {me?.role === roles.Admin && (
                <div className="flex gap-x-3">
                  <div className="flex flex-col w-full">
                    <span className="Tag text-smena_text-secondary">Идентификатор</span>
                    <input className="input-primary" type="text" value={version} onChange={inputHandler} />
                  </div>
                  <button className="self-end mb-1" onClick={buttonHandler}>
                    <CheckCircle className="text-primary" />
                  </button>
                </div>
              )}
            </div>
            <button className="btn-primary w-full" type="button" onClick={onClickLogout}>
              Выйти
            </button>
          </div>
        </OutsideClick>
      )}
    </div>
  );
};

export default Profile;
