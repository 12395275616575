import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { ResolveReportUserModel } from '../../../../../generated/graphql';
import Row from './Row';
interface IErrorRow {
  arr: ResolveReportUserModel[];
  text: string;
  color: string;
}
export const ErrorRow = ({ arr, color, text }: IErrorRow) => {
  return (
    <>
      <div className="border-t border-b  border-smena_gray-30 flex items-center gap-x-7 p-4">
        <span className={clsx(color, 'w-2 h-2 rounded-full')}></span>
        <span className="Body2 text-smena_text ">{text}</span>
      </div>
      {arr.map(row => {
        return <Row key={nanoid()} row={row} />;
      })}
    </>
  );
};
