import { Icons } from '../../Interfaces/IIcons';
const ModerationResolvePencil: React.FC<Icons> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'fill-current ' + className}
    >
      <path
        d="M16.875 16.25H9.00781L15.4453 9.82035L17.5 7.75785C17.6167 7.64235 17.7093 7.50485 17.7725 7.35332C17.8358 7.20179 17.8683 7.03923 17.8683 6.87504C17.8683 6.71085 17.8358 6.54829 17.7725 6.39676C17.7093 6.24523 17.6167 6.10774 17.5 5.99223L14.0078 2.50004C13.7725 2.26816 13.4554 2.13818 13.125 2.13818C12.7946 2.13818 12.4775 2.26816 12.2422 2.50004L2.86719 11.875C2.7507 11.9906 2.65828 12.1282 2.59525 12.2797C2.53223 12.4312 2.49985 12.5937 2.5 12.7579V16.25C2.5 16.5816 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.875C17.0408 17.5 17.1997 17.4342 17.3169 17.317C17.4342 17.1998 17.5 17.0408 17.5 16.875C17.5 16.7093 17.4342 16.5503 17.3169 16.4331C17.1997 16.3159 17.0408 16.25 16.875 16.25ZM13.125 3.38285L16.6172 6.87504L15 8.49223L11.5078 5.00004L13.125 3.38285ZM3.75 12.7579L10.625 5.88285L14.1172 9.37504L7.24219 16.25H3.75V12.7579Z"
        // fill="#393B55"
      />
    </svg>
  );
};
export default ModerationResolvePencil;
