import dayjs from 'dayjs';
import { useMemo } from 'react';

import { RegularUserRatingFragment } from 'src/generated/graphql';
import { Spoiler } from '../../../ui/Spoiler';
import GradeElement from './GradeBlock';
import ReviewElement from './ReviewElement';

interface IReviewBlock {
  user?: RegularUserRatingFragment;
}

const ReviewBlock: React.FC<IReviewBlock> = ({ user }) => {
  const reviewAndGrade = useMemo(() => {
    if (user?.userReview && user.userGrade) {
      return [
        ...user.userReview?.map(review => ({
          createdAt: review.createdAt,
          type: 'REVIEW',
          el: review,
        })),
        ...user.userGrade?.map(grade => ({
          createdAt: grade.createdAt,
          type: 'GRADE',
          el: grade,
        })),
      ].sort(
        (a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
      );
    }
  }, [user?.userGrade, user?.userReview]);

  return (
    <Spoiler
      className="userPageBlock blockTemplate"
      header={
        <div className="flex justify-between">
          <h2 className="blockTitleTemplate">Отзывы</h2>
        </div>
      }
    >
      <>
        {reviewAndGrade?.map(el => {
          if (el.type === 'REVIEW') {
            return <ReviewElement key={el.el.id} review={el.el} />;
          }
          return <GradeElement key={el.el.id} grade={el.el} />;
        })}
      </>
    </Spoiler>
  );
};
export default ReviewBlock;
