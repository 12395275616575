import {
  useAcceptChangesBySupervisorMutation,
  useAcceptZayavkaByDirectorMutation,
  useAcceptZayavkaBySupervisorMutation,
  useCancelZayavkaMutation,
  useMoveZayavkaToDirectorMutation,
  useSendChangesToDirectorMutation,
  useSendChangesToSupervisorMutation,
  ZayavkaChangesStatus,
  ZayavkaStage,
  ZayavkaToCancelStatus,
} from '../../../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { useStore } from '../../../../store';
import { useMeRole } from '../../../../hooks/useRole';
import { roles } from '../../../../utils/constVariables';
import { errorToast, successToast } from '../../../../utils/notify';
import { errorHandler } from '../../../../utils/errorHandler';
import { useChangesByStatus, useRequestStatus } from './index';
import { useParams } from 'react-router-dom';
import { zayavkaConfigFn } from '../../../../utils/graphqlConfigs/zayavkaConfig';

export const useAcceptByManager = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Draft] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Draft] });
  return changes && requestStatus;
};

export const useAcceptChangesByManager = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Draft] });
  return changes && requestStatus;
};

export const useAcceptByDirector = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Draft] });
  const changes = useChangesByStatus({
    status: [ZayavkaChangesStatus.Waiting, ZayavkaChangesStatus.Draft, ZayavkaChangesStatus.Accepted],
  });
  return changes && requestStatus;
};

export const useAcceptChangesByDirector = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working, ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Waiting, ZayavkaChangesStatus.Draft] });
  return changes && requestStatus;
};

export const useAcceptBySupervisor = () => {
  // const { me, request } = useStore();
  // const isMeSupervisor = useMeRole(roles.Supervisor);
  // const isSupervisorAuthorRequest = isMeSupervisor && me?.id === request?.authorId;
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Accepted] });
  // (isMeSupervisor ? isSupervisorAuthorRequest : true)
  return changes && requestStatus;
};

export const useAcceptChangesBySupervisor = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working, ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.New] });
  return changes && requestStatus;
};

export const useSendButton = () => {
  const router = useParams();

  let requestURL = '';

  if (typeof router.requestId === 'string') {
    requestURL = router.requestId;
  }

  const requestsConfig = {
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfigFn(requestURL)],
  };

  const [moveZayavkaToDirector, { loading: loadingMoveZayavkaToDirector }] =
    useMoveZayavkaToDirectorMutation(requestsConfig);
  const [acceptZayavkaByDirector, { loading: loadingAcceptZayavkaByDirector }] =
    useAcceptZayavkaByDirectorMutation(requestsConfig);
  const [acceptZayavkaBySupervisor, { loading: loadingAcceptZayavkaBySupervisor }] =
    useAcceptZayavkaBySupervisorMutation(requestsConfig);
  const [sendChangesToDirector, { loading: loadingSendChangesToDirector }] =
    useSendChangesToDirectorMutation(requestsConfig);
  const [cancelZayavka, { loading: loadingCancelZayavka }] = useCancelZayavkaMutation(requestsConfig);
  const [sendChangesToSupervisor, { loading: loadingSendChangesToSupervisor }] =
    useSendChangesToSupervisorMutation(requestsConfig);
  const [acceptChangesBySupervisorMutation, { loading: loadingAcceptChangesBySupervisor }] =
    useAcceptChangesBySupervisorMutation(requestsConfig);

  const loading =
    loadingMoveZayavkaToDirector ||
    loadingAcceptZayavkaByDirector ||
    loadingAcceptZayavkaBySupervisor ||
    loadingSendChangesToDirector ||
    loadingCancelZayavka ||
    loadingSendChangesToSupervisor ||
    loadingAcceptChangesBySupervisor;

  const { request } = useStore();
  const isMeManager = useMeRole(roles.ClientManager);
  const isMeDirector = useMeRole(roles.ClientDirector);
  const isMeSupervisor = useMeRole(roles.Supervisor);
  const isMeAdmin = useMeRole(roles.Admin);

  const acceptByManager = useAcceptByManager();
  const acceptChangesByManager = useAcceptChangesByManager();
  const acceptByDirector = useAcceptByDirector();
  const acceptChangesByDirector = useAcceptChangesByDirector();
  const acceptCancelByDirector = request?.toCancel === ZayavkaToCancelStatus.Waiting;
  const acceptBySupervisor = useAcceptBySupervisor();
  const acceptChangesBySupervisor = useAcceptChangesBySupervisor();

  const managerCondition = !acceptCancelByDirector && (acceptByManager || acceptChangesByManager);
  const directorCondition = acceptByDirector || acceptChangesByDirector || acceptCancelByDirector;
  const supervisorCondition = acceptBySupervisor || acceptChangesBySupervisor;

  const showSendButton = useMemo(() => {
    return (
      (isMeManager && managerCondition) ||
      (isMeDirector && directorCondition) ||
      (isMeSupervisor && supervisorCondition)
    );
  }, [directorCondition, isMeDirector, isMeManager, isMeSupervisor, managerCondition, supervisorCondition]);

  const sendButtonText = useMemo(() => {
    if (isMeManager || isMeDirector) {
      if (acceptCancelByDirector) {
        return 'Отменить';
      }
      if (acceptChangesByManager || acceptChangesByDirector) {
        return 'Отправить изменения';
      }
      return 'Отправить заявку';
    }
    if (isMeSupervisor) {
      if (request?.status === ZayavkaStage.New) {
        return 'Взять в работу';
      }
      if (acceptChangesBySupervisor) {
        return 'Принять изменения';
      }
      return 'Отправить заявку';
    }
  }, [
    acceptCancelByDirector,
    acceptChangesByDirector,
    acceptChangesByManager,
    acceptChangesBySupervisor,
    isMeDirector,
    isMeManager,
    isMeSupervisor,
    request?.status,
  ]);

  const sendRequest = useCallback(() => {
    if (request) {
      const variables = {
        variables: {
          id: request.id,
        },
      };
      if (isMeManager) {
        if (acceptChangesByManager) {
          return sendChangesToDirector(variables)
            .then(() => {
              successToast('Изменения отправлены на проверку директору');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        return moveZayavkaToDirector(variables)
          .then(() => {
            successToast('Заявка отправлена на проверку директору');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      if (isMeDirector || isMeAdmin) {
        if (acceptCancelByDirector) {
          return cancelZayavka(variables)
            .then(() => {
              successToast('Заявка отклонена');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        if (acceptChangesByDirector) {
          return sendChangesToSupervisor(variables)
            .then(() => {
              successToast('Изменения отправлены супервайзеру');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        return acceptZayavkaByDirector(variables)
          .then(() => {
            successToast('Заявка отправлена');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      if (isMeSupervisor) {
        if (request.status === ZayavkaStage.New) {
          return acceptZayavkaBySupervisor(variables)
            .then(() => {
              successToast('Заявка принята в работу');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        if (acceptChangesBySupervisor) {
          return acceptChangesBySupervisorMutation(variables)
            .then(() => {
              successToast('Изменения приняты');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        return acceptZayavkaBySupervisor(variables)
          .then(() => {
            successToast('Заявка принята в работу');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
    }
  }, [
    acceptCancelByDirector,
    acceptChangesByDirector,
    acceptChangesByManager,
    acceptChangesBySupervisor,
    acceptChangesBySupervisorMutation,
    acceptZayavkaByDirector,
    acceptZayavkaBySupervisor,
    cancelZayavka,
    isMeAdmin,
    isMeDirector,
    isMeManager,
    isMeSupervisor,
    moveZayavkaToDirector,
    sendChangesToDirector,
    sendChangesToSupervisor,
    request,
  ]);

  return { showSendButton, sendButtonText, sendRequest, loading };
};
