import { useCallback, useMemo } from 'react';

import { ICustomEvent } from 'src/Interfaces/IEvent';
import { useStore } from 'src/store';

const useWithoutMarks = (events?: ICustomEvent[]) => {
  const { facilityId, workpostId } = useStore();

  const filterMarks = useCallback(
    (shift: ICustomEvent) => Number(shift.resource.marks?.length) < 2,
    []
  );
  const filterFacility = useCallback(
    (event: ICustomEvent) => event.resource.facility?.id === facilityId,
    [facilityId]
  );

  const filterFacilityAndPosition = useCallback(
    (event: ICustomEvent) =>
      filterFacility(event) && event.resource.positionId === workpostId,
    [filterFacility, workpostId]
  );

  return useMemo(() => {
    if (facilityId) {
      if (workpostId) {
        return events?.filter(filterMarks).filter(filterFacilityAndPosition);
      }
      return events?.filter(filterMarks).filter(filterFacility);
    }
    return events?.filter(filterMarks);
  }, [
    events,
    facilityId,
    workpostId,
    filterMarks,
    filterFacility,
    filterFacilityAndPosition,
  ]);
};

export default useWithoutMarks;
