import { Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { maxTableWidth } from 'src/utils/maxTableWidth';

import MainLayout from '../components/Layout/main';
import { vacanciesColumns } from '../components/Table/columns';
import VacancyFilter from '../components/Table/Filters/VacancyFilter';
import TableHeader from '../components/Table/header';
import SkeletonLayout from '../components/Table/Skeleton';
import VacanciesTable from '../components/Table/VacanciesTable';
import { useVacanciesPaginatedLazyQuery } from '../generated/graphql';
import { activeHandler } from '../utils/isActiveHandler';

const VacanciesPage = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [groupId, setGroupId] = useState('');
  const [facilityId, setFacilityId] = useState('');
  const [cityId, setCityId] = useState('');
  const [period, setPeriod] = useState('');
  const [schedule, setSchedule] = useState<string[]>([]);
  const [isActive, setIsActive] = useState<boolean | string>('');
  const [params] = useSearchParams();

  const [loadData, { data: dataVacancies, loading: loadingVacancies }] =
    useVacanciesPaginatedLazyQuery();

  const vacanciesPaginated = dataVacancies?.vacanciesPaginated;
  const vacancies = vacanciesPaginated?.vacancies;

  let groupParam = params.get('groupId');
  let facilityParam = params.get('facilityId');
  let cityParam = params.get('cityId');
  let periodParam = params.get('period');
  let scheduleParam = params.get('schedule');
  let activeParam = params.get('active');
  let searchParam = params.get('search');

  const location = useLocation();

  const mutationInput = {
    page: 1,
    searchQuery: search,
    sortBy,
    sortWay,
    groupId,
    facilityId,
    cityId,
    period,
    schedule,
    isActive: activeHandler(isActive),
  };

  const returnData = () => {
    return loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
        },
      },
    });
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (groupParam) {
      setGroupId(groupParam);
    } else {
      setGroupId('');
    }
    if (facilityParam) {
      setFacilityId(facilityParam);
    } else {
      setFacilityId('');
    }
    if (cityParam) {
      setCityId(cityParam);
    } else {
      setCityId('');
    }
    if (periodParam) {
      setPeriod(periodParam);
    } else {
      setPeriod('');
    }
    if (activeParam) {
      setIsActive(activeParam);
    } else {
      setIsActive('');
    }
    if (scheduleParam) {
      setSchedule(scheduleParam.split(', '));
    } else {
      setSchedule([]);
    }
    if (searchParam) {
      setSearch(searchParam);
    } else {
      setSearch('');
    }
    loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
          groupId: groupParam || '',
          facilityId: facilityParam || '',
          cityId: cityParam || '',
          period: periodParam || '',
          schedule: scheduleParam ? scheduleParam.split(', ') : [],
          isActive: activeParam ? activeHandler(activeParam) : null,
        },
      },
    });

    // eslint-disable-next-line
  }, [page, search, sortBy, sortWay, location]);

  const breadCrumbs = () => {
    if (facilityParam) {
      return [
        {
          path: '/facilities/' + facilityParam,
          name: 'Страница объекта',
        },
        { path: '/vacancies', name: 'Вакансии' },
      ];
    } else {
      return [{ path: '/vacancies', name: 'Вакансии' }];
    }
  };

  return (
    <MainLayout
      title="Вакансии"
      bg="bg-smena_bb-background_base"
      breadCrumbs={breadCrumbs()}
    >
      <div
        style={{
          maxWidth: maxTableWidth(vacanciesColumns) + 'px',
        }}
      >
        <VacancyFilter
          returnData={returnData}
          setSearch={setSearch}
          setGroupId={setGroupId}
          setFacilityId={setFacilityId}
          setCityId={setCityId}
          setPeriod={setPeriod}
          setSchedule={setSchedule}
          setIsActive={setIsActive}
          setPage={setPage}
          groupId={groupId}
          facilityId={facilityId}
          cityId={cityId}
          period={period}
          schedule={schedule}
          isActive={isActive}
        />
        <ScrollSync>
          <div className="flex flex-col shadow-smena rounded-lg">
            <TableHeader
              dataLength={vacancies?.length}
              columns={vacanciesColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />
            {loadingVacancies || !vacancies ? (
              <SkeletonLayout columns={vacanciesColumns} />
            ) : vacancies.length > 0 ? (
              <ScrollSyncPane>
                <div className="main-table">
                  <VacanciesTable
                    vacancies={vacancies}
                    columns={vacanciesColumns}
                  />
                </div>
              </ScrollSyncPane>
            ) : (
              <div className="w-full flex justify-center my-5">
                <h3 className="text-smena_text Body1">
                  По вашему запросу <span className="font-bold">{search}</span>{' '}
                  ничего не нашлось
                </h3>
              </div>
            )}
          </div>
        </ScrollSync>
        <div className="py-5">
          {vacanciesPaginated?.pages! > 1 ? (
            <Pagination
              shape="rounded"
              count={vacanciesPaginated ? vacanciesPaginated.pages : 10}
              page={page}
              onChange={handleChange}
            />
          ) : null}
        </div>
      </div>
    </MainLayout>
  );
};

export default VacanciesPage;
