import { ZayavkaStage } from 'src/generated/graphql';

export const getRequestStatusRu = (status?: ZayavkaStage) => {
  const statuses = [
    {
      statusEnum: ZayavkaStage.Blank,
      clientStatus: 'Черновик',
      supervisorStatus: 'Черновик',
    },
    {
      statusEnum: ZayavkaStage.Canceled,
      clientStatus: 'Отменена',
      supervisorStatus: 'Отменена',
    },
    {
      statusEnum: ZayavkaStage.Draft,
      clientStatus: 'Не отправлена',
      supervisorStatus: 'Не отправлена',
    },
    {
      statusEnum: ZayavkaStage.Finished,
      clientStatus: 'Выполнена',
      supervisorStatus: 'Выполнена',
    },
    {
      statusEnum: ZayavkaStage.New,
      clientStatus: 'Отправлена',
      supervisorStatus: 'Новая',
    },
    {
      statusEnum: ZayavkaStage.Refused,
      clientStatus: 'Отклонена',
      supervisorStatus: 'Отклонена',
    },
    {
      statusEnum: ZayavkaStage.Working,
      clientStatus: 'В работе',
      supervisorStatus: 'В работе',
    },
  ];
  return statuses.find(statusEl => statusEl.statusEnum === status);
};
