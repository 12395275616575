import { useNavigate } from 'react-router-dom';
import {
  FacilitiesDocument,
  FacilitiesQueryVariables,
  RegularFacilityFragment,
  useCreateFacilityMutation,
  useUpdateFacilityMutation,
} from 'src/generated/graphql';
import { errorHandler } from 'src/utils/errorHandler';
import { facilityConfigFn } from 'src/utils/graphqlConfigs/facilityConfig';
import { errorToast, successToast } from 'src/utils/notify';
import { regFacilityName } from '../../../utils/constVariables';

interface IUseFacility {
  facilityId: string;
  facility?: RegularFacilityFragment;
  groupId: string | null;
}

const useFacility = ({ facility, groupId, facilityId }: IUseFacility) => {
  const navigate = useNavigate();

  const facilityConfig = facilityConfigFn(facilityId);

  const facilitiesConfig: {
    query: typeof FacilitiesDocument;
    variables: FacilitiesQueryVariables;
  } = {
    query: FacilitiesDocument,
    variables: {},
  };

  const [updateFacility] = useUpdateFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilityConfig],
  });

  const [createFacility] = useCreateFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilitiesConfig],
  });

  const initialValues = {
    group: groupId || facility?.facilityGroupId || '',
    name: facility?.name || '',
    cityId: facility?.cityId || '',
    externalId: facility?.externalId || '',
    metro: facility?.metro || '',
    address: facility?.address || '',
    latitude: facility?.latitude || '',
    longitude: facility?.longitude || '',
    radius: facility?.radius || 0,
    manager: { id: '', name: '' },
    is_active: facility?.is_active || false,
    is_bonus_active: facility?.is_bonus_active || false,
    showSalaryBeforeModeration: facility?.showSalaryBeforeModeration || false,
    is_finance_active: facility?.is_finance_active || false,
    intership: false,
    intershipPositionId: '',
  };
  const validate = (values: typeof initialValues) => {
    let errors: any = {};
    if (!values.group) {
      errors.group = 'Вы не указали группу ';
    }
    if (!values.name) {
      errors.name = 'Обязательное поле';
    }
    if (values.name && !regFacilityName.test(values.name)) {
      errors.name = 'Поле может содержать только буквы русского или латинского алфавитов, цифры, дефис, пробел';
    }
    if (!values.cityId) {
      errors.cityId = 'Вы не указали город';
    }
    if (!values.address) {
      errors.address = 'Обязательное поле';
    }
    if (!values.metro) {
      errors.metro = 'Обязательное поле';
    }
    if (!values.latitude) {
      errors.latitude = 'Обязательное поле';
    }
    if (values.latitude && !/(^-?[0-9]{2}[.]{1}\b\d{4,15}\b)/.test(values.latitude)) {
      errors.latitude = 'Вы неправильно ввели широту';
    }
    if (!/(^-?[0-9]{2,3}[.]{1}\b\d{4,15}\b)/.test(values.longitude)) {
      errors.longitude = 'Вы неправильно ввели долготу';
    }
    if (!values.longitude) {
      errors.longitude = 'Обязательное поле';
    }
    if (!values.radius) {
      errors.radius = 'Обязательное поле';
    }
    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const commonFacilityValues = {
      facilityGroupId: values.group,
      name: values.name.trim(),
      cityId: values.cityId,
      externalId: values.externalId,
      metro: values.metro.trim(),
      address: values.address.trim(),
      latitude: values.latitude.trim(),
      longitude: values.longitude.trim(),
      radius: Number(values.radius),
      is_active: values.is_active,
      is_bonus_active: values.is_bonus_active,
      showSalaryBeforeModeration: values.showSalaryBeforeModeration,
      is_finance_active: values.is_finance_active,
    };
    if (facilityId) {
      updateFacility({
        variables: {
          input: {
            ...commonFacilityValues,
            id: facilityId,
          },
        },
      })
        .then(response => {
          if (response.data?.updateFacility) {
            successToast('Объект изменен');
          }
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    } else {
      createFacility({
        variables: {
          input: {
            ...commonFacilityValues,
          },
        },
      })
        .then(response => {
          if (response.data?.createFacility) {
            successToast('Объект создан');
            setTimeout(() => {
              navigate(`/facilities/${response.data?.createFacility.id}`);
            }, 2000);
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    }
  };
  return { initialValues, validate, onSubmit };
};

export default useFacility;
