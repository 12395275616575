import { toast } from 'react-toastify';

export const errorToast = (message: string) =>
  toast.error(message, {
    theme: 'colored',
  });

export const successToast = (message: string) =>
  toast.success(message, {
    theme: 'colored',
  });

export const infoToast = (message: string) =>
  toast.info(message, {
    theme: 'colored',
  });
