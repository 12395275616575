import React from 'react';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import MainLayout from '../../components/Layout/main';
import SimpleInput from '../../components/ui/Input';
import SimpleCheckbox from '../../components/ui/Checkbox';

import {
  PositionsDocument,
  PositionsQueryVariables,
  useCreatePositionMutation,
  usePositionQuery,
  useUpdatePositionMutation,
} from '../../generated/graphql';

import { errorToast, successToast } from '../../utils/notify';
import { errorHandler } from '../../utils/errorHandler';

const UpdatePosition: React.FC = () => {
  const router = useParams();
  const navigate = useNavigate();
  let positionId: string = '';

  if (typeof router.positionId === 'string') {
    positionId = router.positionId;
  }

  const positionsQueryConfig: {
    query: typeof PositionsDocument;
    variables: PositionsQueryVariables;
  } = {
    query: PositionsDocument,
    variables: {},
  };

  const [createPosition] = useCreatePositionMutation({
    awaitRefetchQueries: true,
    refetchQueries: [positionsQueryConfig],
  });

  const [updatePosition] = useUpdatePositionMutation({
    awaitRefetchQueries: true,
    refetchQueries: [positionsQueryConfig],
  });

  const { data: dataPosition } = usePositionQuery({
    variables: { id: positionId },
  });

  const position = dataPosition?.getPosition;

  const title = () => {
    if (positionId) {
      return `Редактировать профессию ${position?.name}`;
    } else {
      return 'Создать профессию';
    }
  };

  return (
    <MainLayout
      title={title()}
      bg="bg-smena_bb-background_base"
      breadCrumbs={[
        { path: '/positions', name: 'Профессии' },
        positionId
          ? { path: '/positions' + positionId, name: 'Редактировать профессию' }
          : { path: '/positions/create', name: 'Новая профессия' },
      ]}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: position?.name ? position.name : '',
          isSelfEmployed: position?.isSelfEmployed
            ? position?.isSelfEmployed
            : false,
          externalId: position?.externalId ? position?.externalId : '',
        }}
        validate={values => {
          let errors: any = {};
          if (!values.name) {
            errors.name = 'Обязательное поле';
          }
          return errors;
        }}
        onSubmit={async values => {
          const variablesPosition = {
            name: values.name.trim(),
            isSelfEmployed: values.isSelfEmployed,
            externalId: values.externalId.trim(),
          };
          if (positionId) {
            await updatePosition({
              variables: {
                input: {
                  id: positionId,
                  ...variablesPosition,
                },
              },
            })
              .then(response => {
                if (response.data?.updatePosition) {
                  successToast('Профессия обновлена');
                }
              })
              .catch(e => errorToast(errorHandler(e)));
          } else {
            await createPosition({
              variables: {
                input: {
                  ...variablesPosition,
                },
              },
            })
              .then(response => {
                if (response.data?.createPosition) {
                  successToast('Профессия создана');
                  setTimeout(() => {
                    navigate(`/positions/${response.data?.createPosition.id}`);
                  }, 2000);
                }
              })
              .catch(e => {
                errorToast(e.message);
              });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col"
              style={{
                maxWidth: '570px',
              }}
            >
              <div className="px-6 mt-6 pb-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5">
                <span className="Subtitle1 text-smena_text-secondary pt-5">
                  Основная информация
                </span>
                <div className="flex gap-5 flex-wrap">
                  <SimpleInput
                    divClassName="crudPositionInput"
                    label="Название профессии"
                    placeholder="Курьер"
                    required={false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    type="text"
                    name="name"
                    error={touched.name && errors.name ? errors.name : ''}
                  />
                  <SimpleInput
                    divClassName="crudPositionInput"
                    label="ERP ID"
                    placeholder="213"
                    regText={true}
                    required={false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.externalId}
                    type="text"
                    name="externalId"
                  />
                </div>
              </div>
              <div
                className="px-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5"
                style={{
                  marginTop: '10px',
                  paddingBottom: '23px',
                }}
              >
                <span className="Subtitle1 text-smena_text-secondary pt-5">
                  Самозанятость
                </span>
                <div className="">
                  <SimpleCheckbox
                    divClassName="flex items-center"
                    label="Доступна самозанятость?"
                    required={false}
                    onChange={handleChange}
                    checked={values.isSelfEmployed}
                    name="isSelfEmployed"
                  />
                </div>
              </div>
              <div className="flex mt-5 self-end">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary_big"
                >
                  <span>
                    {isSubmitting
                      ? 'Загрузка'
                      : positionId
                      ? 'Сохранить'
                      : 'Создать'}
                  </span>
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdatePosition;
