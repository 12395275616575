export const getStatusRu = (status: string): string | undefined => {
  const statusList = [
    {
      status: 'IN_WORK',
      statusRu: 'В работе',
    },
    {
      status: 'SENT',
      statusRu: 'Отправлен',
    },
  ];

  return statusList.find(item => status === item.status)?.statusRu;
};
