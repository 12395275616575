import { ISwitch } from '../../../Interfaces/ISwitch';

export const Switch: React.FC<ISwitch> = ({ name, checked, onChange }) => {
  return (
    <label className="switch_btn">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span className="slider"></span>
    </label>
  );
};
