import clsx from 'clsx';
import { ICustomEvent } from 'src/Interfaces/IEvent';

interface ILegendRow {
  text: string;
  bgColor: string;
  array?: ICustomEvent[];
}

const LegendRow: React.FC<ILegendRow> = ({ text, bgColor, array }) => {
  return (
    <div className="flex items-center gap-x-2">
      <span className={clsx('dot-legend', bgColor)}></span>
      <span className="Caption-numbers text-smena_text mr-2 w-4">
        {array?.length || 0}
      </span>
      <span className="Table-small text-smena_text-secondary">{text}</span>
    </div>
  );
};

export default LegendRow;
