import { NavLink } from 'react-router-dom';

import { MenuItem } from 'src/types';
import { getNavLinkClassNameMobile } from 'src/utils/get/getNavLinkClassname';

const MenuElementMobile = ({ item }: { item: MenuItem }) => {
  const { id, url, name, icon } = item;

  return (
    <NavLink
      to={url}
      key={id}
      className={({ isActive }) => getNavLinkClassNameMobile(isActive, name)}
      end={name === 'Главная'}
    >
      <div className="relative">{icon}</div>
      <span className="ml-[15px]">{name}</span>
    </NavLink>
  );
};

export default MenuElementMobile;
