import { useMemo } from 'react';
import { RegularCasingFragment } from 'src/generated/graphql';

import { useStore } from 'src/store';

const useFacilityCasingsPositions = (
  facilityCasings?: RegularCasingFragment[]
) => {
  const { positions } = useStore();
  return useMemo(
    () =>
      facilityCasings?.map(el => ({
        ...positions?.find(pos => pos.id === el.positionId),
        facilityId: el.facilityId,
      })),
    [positions, facilityCasings]
  );
};

export default useFacilityCasingsPositions;
