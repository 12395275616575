export const getSexRu = (sex?: string) => {
  const sexArr = [
    {
      sex: 'male',
      sexRu: 'Мужчина',
    },
    {
      sex: 'female',
      sexRu: 'Женщина',
    },
  ];
  return sexArr.find(sexEl => sexEl.sex === sex)?.sexRu;
};
