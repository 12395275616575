import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Slide, ToastContainer } from 'react-toastify';
import AdminLogin from './AdminLogin';
import CodeLogin from './CodeLogin';
import Login from './Login';
import Logo from '../../components/ui/Logo';
import { FastLogin } from './__fastLogin';

const LoginPage = () => {
  const [loginPage, setLoginPage] = useState('login');
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
        <title>Авторизация</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <div className="min-h-screen bg-smena_bb-background_base flex items-center">
        <div className="mx-auto md:w-full md:max-w-sm flex flex-col gap-y-5">
          <div className={'flex justify-between items-center'}>
            <Logo />
            <FastLogin />
          </div>
          {loginPage === 'login' && <Login setLoginPage={setLoginPage} />}
          {loginPage === 'password' && <AdminLogin />}
          {loginPage === 'code' && <CodeLogin />}
        </div>
        <ToastContainer
          transition={Slide}
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default LoginPage;
