import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import Across from '../../../Assets/icons/Across';
import OutsideClick from '../../../hooks/useOutsideClick';

interface Sidebar {
  title: string;
  children: ReactNode;
  show: boolean;
  setShow: (show: boolean) => void;
  onClose?: () => void;
}

const MainSidebar: FC<Sidebar> = ({ children, show, setShow, title, onClose }) => {
  return (
    <OutsideClick
      show={show}
      setShow={setShow}
      className={clsx('shiftsScheduleSidebar shadow-smena', show ? 'right-0' : '-right-full invisible')}
    >
      <span
        className="w-auto h-auto cursor-pointer flex justify-end relative right-0"
        onClick={() => {
          setShow(false);
          if (onClose) {
            onClose();
          }
        }}
      >
        <Across color="text-smena_text" />
      </span>
      <h3 className="H3 mb-6">{title}</h3>
      {children}
    </OutsideClick>
  );
};

export default MainSidebar;
