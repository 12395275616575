import { IconProps } from '../../types';

const DatePickerIcon = ({
  color = 'text-smena_gray-40',
  opacity = 'opacity-40',
}: IconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${color + ' ' + opacity}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8 1C6.24183 1 6.6 1.35817 6.6 1.8V2.6H11.4V1.8C11.4 1.35817 11.7582 1 12.2 1C12.6418 1 13 1.35817 13 1.8V2.6H14.6C15.9255 2.6 17 3.67452 17 5V14.6C17 15.9255 15.9255 17 14.6 17H3.4C2.07452 17 1 15.9255 1 14.6V5C1 3.67452 2.07452 2.6 3.4 2.6H5V1.8C5 1.35817 5.35817 1 5.8 1ZM5 4.2H3.4C2.95817 4.2 2.6 4.55817 2.6 5V14.6C2.6 15.0418 2.95817 15.4 3.4 15.4H14.6C15.0418 15.4 15.4 15.0418 15.4 14.6V5C15.4 4.55817 15.0418 4.2 14.6 4.2H13V5C13 5.44183 12.6418 5.8 12.2 5.8C11.7582 5.8 11.4 5.44183 11.4 5V4.2H6.6V5C6.6 5.44183 6.24183 5.8 5.8 5.8C5.35817 5.8 5 5.44183 5 5V4.2ZM4.2 8.2C4.2 7.75817 4.55817 7.4 5 7.4H13C13.4418 7.4 13.8 7.75817 13.8 8.2C13.8 8.64183 13.4418 9 13 9H5C4.55817 9 4.2 8.64183 4.2 8.2Z"
      />
    </svg>
  );
};

export default DatePickerIcon;
