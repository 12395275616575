import { FilterPeriod } from '../../generated/graphql';

export const chartTimes = [
  { name: 'День', id: FilterPeriod.Day },
  { name: 'Неделя', id: FilterPeriod.Week },
  { name: 'Месяц', id: FilterPeriod.Month },
  { name: 'Полгода', id: FilterPeriod.HalfYear },
  { name: 'Год', id: FilterPeriod.Year },
  { name: 'Все время', id: FilterPeriod.AllTime },
];
