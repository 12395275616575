import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useGetUserDocumentsLazyQuery, useUserLazyQuery } from '../../../../generated/graphql';
import Loading from '../../../../components/ui/Loading';
import MainLayout from '../../../../components/Layout/main';
import { PassportPage } from '../../../../components/users/Documents/PassportPage';
import { MedCardPage } from '../../../../components/users/Documents/MedCard';
import { NalogInfoPage } from '../../../../components/users/Documents/NalogInfo';
import { PayInfoPage } from '../../../../components/users/Documents/PayInfo';
import { Spoiler } from '../../../../components/ui/Spoiler';
import { useStore } from '../../../../store';
import MainInfo from '../../../../components/users/Documents/MainInfo';
import { roles } from '../../../../utils/constVariables';
import { UserNameSurename } from 'src/utils/userFullName';
import useUserHeaderMenu from 'src/hooks/headerMenu/useUserHeaderMenu';
import useUserBreadcrumbs from 'src/hooks/breadcrumbs/useUserBreadcrumbs';

export const DocumentsPage = () => {
  const { setUserDocuments, setUserReviews, setUserRequisites, setCurrentUser, setMedicalAttestation, me } = useStore();

  const router = useParams();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const [loadUserDocuments, { data: userDocuments }] = useGetUserDocumentsLazyQuery({
    variables: {
      workerId: userId,
    },
  });

  const [loadUser, { data, loading }] = useUserLazyQuery({
    variables: {
      userId,
    },
  });

  const user = data?.user;

  const canView = useMemo(() => {
    if (user?.role) {
      return [roles.Foreman, roles.Worker, roles.Newbie].includes(user.role);
    }
    return false;
  }, [user?.role]);

  useEffect(() => {
    if (userId) {
      loadUserDocuments();
      loadUser();
    }
  }, [userId, loadUser, loadUserDocuments]);

  useEffect(() => {
    if (userId) {
      setUserDocuments(userDocuments?.getUserDocuments.documents);
    }
  }, [userDocuments?.getUserDocuments.documents, setUserDocuments, userId]);

  useEffect(() => {
    if (userId) {
      setUserReviews(userDocuments?.getUserDocuments.reviews);
    }
  }, [userDocuments?.getUserDocuments.reviews, setUserReviews, userId]);

  useEffect(() => {
    if (userId) {
      setMedicalAttestation(userDocuments?.getUserDocuments.medicalAttestations);
    }
  }, [userDocuments?.getUserDocuments.medicalAttestations, setMedicalAttestation, userId]);

  useEffect(() => {
    if (userId) {
      setUserRequisites(userDocuments?.getUserDocuments.requisite);
    }
  }, [userDocuments?.getUserDocuments.requisite, setUserRequisites, userId]);

  useEffect(() => {
    if (userId) {
      setCurrentUser(user);
    }
  }, [setCurrentUser, user, userId]);

  const isMeAdminSuper = me && [roles.Admin, roles.Supervisor].includes(me.role);
  const isCurrentUserWorkerOrForeman = user && [roles.Worker, roles.Foreman].includes(user.role);

  const headerMenu = useUserHeaderMenu({
    currentLocation: 'Документы',
    userId,
    role: user?.role,
    isMeAdminSuper,
    isCurrentUserWorkerOrForeman,
  });

  const breadCrumbs = useUserBreadcrumbs({ isMeAdminSuper, userId });

  const crudUserTitle = () => {
    if (user) {
      if (isMeAdminSuper) {
        return 'Документы пользователя ' + UserNameSurename(user);
      }
      return 'Документы исполнителя ' + UserNameSurename(user);
    }
    return 'Документы исполнителя';
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <MainLayout
      title={crudUserTitle()}
      bg="bg-smena_bb-background_base"
      headerMenu={headerMenu}
      breadCrumbs={breadCrumbs}
    >
      <div className="documents flex flex-col gap-y-6">
        <Spoiler
          className="flex flex-col gap-x-6 main-pages p-6 rounded-lg shadow-smena bg-smena_white"
          header={<span className="Subtitle1 text-smena_text-secondary inline-block">Персональные данные</span>}
        >
          <MainInfo />
        </Spoiler>
        {canView && (
          <>
            <Spoiler
              className="flex flex-col gap-x-6 main-pages p-5 rounded-lg shadow-smena bg-smena_white"
              header={<span className="Subtitle1 text-smena_text-secondary inline-block">Паспорт</span>}
            >
              <PassportPage />
            </Spoiler>
            <Spoiler
              className="flex flex-col gap-x-6 main-pages p-5 rounded-lg shadow-smena bg-smena_white"
              header={<span className="Subtitle1 text-smena_text-secondary inline-block">Медицинская книжка</span>}
            >
              <MedCardPage />
            </Spoiler>
            <Spoiler
              className="flex flex-col gap-x-6 main-pages p-5 rounded-lg shadow-smena bg-smena_white"
              header={<span className="Subtitle1 text-smena_text-secondary inline-block">ИНН и самозанятость</span>}
            >
              <NalogInfoPage />
            </Spoiler>
            <Spoiler
              className="flex flex-col gap-x-6 main-pages p-5 rounded-lg shadow-smena bg-smena_white"
              header={<span className="Subtitle1 text-smena_text-secondary inline-block">Платежная информация</span>}
            >
              <PayInfoPage />
            </Spoiler>
          </>
        )}
      </div>
    </MainLayout>
  );
};
