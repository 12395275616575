import clsx from 'clsx';
import { Icons } from '../../Interfaces/IIcons';

const ChevronDown: React.FC<Icons> = ({ color, className }) => {
  return (
    <svg
      height="16px"
      width="16px"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke={color}
      className={clsx('stroke-current', className)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );
};

export default ChevronDown;
