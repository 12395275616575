import { roles } from '../constVariables';

export const roleArray = [
  { value: roles.Worker, name: 'Работник' },
  { value: roles.Foreman, name: 'Бригадир' },
  { value: roles.ClientManager, name: 'Менеджер' },
  { value: roles.ClientDirector, name: 'Директор' },
  { value: roles.Supervisor, name: 'Супервайзер' },
  { value: roles.Admin, name: 'Администратор' },
];

export const allRoleArray = [
  { value: roles.Newbie, name: 'Ньюби' },
  { value: roles.Worker, name: 'Работник' },
  { value: roles.Foreman, name: 'Бригадир' },
  { value: roles.ClientManager, name: 'Менеджер' },
  { value: roles.ClientDirector, name: 'Директор' },
  { value: roles.Supervisor, name: 'Супервайзер' },
  { value: roles.Admin, name: 'Администратор' },
];

export const roleWithNewbie = [
  { value: roles.Newbie, name: 'Ньюби' },
  { value: roles.Worker, name: 'Работник' },
  { value: roles.Foreman, name: 'Бригадир' },
  { value: roles.Supervisor, name: 'Супервайзер' },
  { value: roles.ClientManager, name: 'Менеджер' },
  { value: roles.ClientDirector, name: 'Директор' },
];

export const superNewbie = [
  { value: roles.Newbie, name: 'Ньюби' },
  { value: roles.Worker, name: 'Работник' },
  { value: roles.Foreman, name: 'Бригадир' },
  { value: roles.Supervisor, name: 'Супервайзер' },
  { value: roles.ClientManager, name: 'Менеджер' },
  { value: roles.ClientDirector, name: 'Директор' },
];

export const superWithoutNewbie = [
  { value: roles.Worker, name: 'Работник' },
  { value: roles.Foreman, name: 'Бригадир' },
  { value: roles.Supervisor, name: 'Супервайзер' },
  { value: roles.ClientManager, name: 'Менеджер' },
  { value: roles.ClientDirector, name: 'Директор' },
];

export const clientUsersRole = [
  { value: roles.Worker, name: 'Работник' },
  { value: roles.Foreman, name: 'Бригадир' },
];
