import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { ConfigProvider, DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';

import { useCreateManualAccuralByPeriodMutation } from '../../../generated/graphql';
import SelfEmployer from '../../../Assets/icons/selfEmployer';
import { useStore } from '../../../store';
import SimpleSelect from '../../ui/Select';
import { errorToast } from '../../../utils/notify';
import DatePickerIcon from '../../../Assets/icons/DatePickerIcon';
import { errorHandler } from '../../../utils/errorHandler';
import { RU_DATE } from '../../../utils/constVariables';

const { RangePicker } = DatePicker;

const PaymentsFilter = () => {
  const [facility, setFacility] = useState('');
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<moment.Moment[]>([]);
  const { manualAccural, setManualAccural, facilities, facilitiesLoading } = useStore();
  const [searchParams, setSerachParams] = useSearchParams();

  let searchParametr = searchParams.get('facilityId');
  let dateFromParam = searchParams.get('dateFrom');
  let dateToParam = searchParams.get('dateTo');

  const [createAccuralByPeriod] = useCreateManualAccuralByPeriodMutation();

  const clickHandler = () => {
    if (!facility) {
      return errorToast('Выберите объект');
    }
    if (!value[0] || !value[1]) {
      return errorToast('Выберите период');
    }
    setLoading(true);
    createAccuralByPeriod({
      variables: {
        dateFrom: String(value[0]?.valueOf()),
        dateTo: String(value[1]?.valueOf()),
        facilityId: facility,
      },
    })
      .then(e => {
        if (e.data?.createManualAccuralByPeriod) {
          setManualAccural(e.data.createManualAccuralByPeriod);
          setLoading(false);
          searchParams.set('facilityId', facility);
          searchParams.set('dateFrom', String(moment(value[0]).format('YYYY-MM-DD')));
          searchParams.set('dateTo', String(moment(value[1]).format('YYYY-MM-DD')));
          setSerachParams(searchParams);
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  const selectFacilityHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFacility(e.target.value);
  };

  useEffect(() => {
    if (searchParametr) {
      setFacility(searchParametr);
    }
    if (searchParams.has('dateFrom') && searchParams.has('dateTo')) {
      setValue([moment(dateFromParam), moment(dateToParam)]);
    }
  }, [dateFromParam, dateToParam, searchParametr, searchParams]);

  const disabledDate = (current: moment.Moment) => {
    return !(
      current.date() === moment(current).startOf('month').date() ||
      current.date() === moment(current).startOf('month').add(2, 'week').date() ||
      current.date() === moment(current).endOf('month').date()
    );
  };

  return (
    <div className="flex justify-between rounded-t-lg bg-smena_white border-b border-smena_gray-30 p-3">
      <div className="flex flex-col">
        {!manualAccural && <h3 className="mb-10 leading-6 text-smena_gray-90">Создать выплаты для объекта:</h3>}

        {manualAccural && <h3 className="mb-2 leading-6 text-smena_gray-90">Выплаты для объекта:</h3>}
        <div className="flex">
          <div>
            {facilitiesLoading ? (
              <Skeleton width={250} height={40} />
            ) : (
              <SimpleSelect
                divClassName="crudUserMulty"
                label="Объект"
                required
                onChange={selectFacilityHandler}
                value={facility}
                name="facility"
                autoComplete="cityId"
                disabledOption="Выберите объект"
                options={facilities}
              />
            )}
          </div>
          <div className="ml-4 flex">
            {/* {!manualAccural && ( */}
            <div>
              <label className="label-primary">Период</label>

              <ConfigProvider locale={locale}>
                <RangePicker
                  size="middle"
                  separator={<span>→</span>}
                  placeholder={['Начало', 'Конец']}
                  className="custom__picker"
                  suffixIcon={<DatePickerIcon opacity="opacity-100" />}
                  ranges={{
                    'Предыдущий период': [
                      moment().date() > 15
                        ? moment().startOf('month').subtract(1, 'd').startOf('month').add(2, 'w')
                        : moment().startOf('month').subtract(1, 'd').startOf('month'),
                      moment().date() > 15
                        ? moment().startOf('month').subtract(1, 'd')
                        : moment().startOf('month').subtract(1, 'd').startOf('month').add(2, 'w'),
                    ],
                    'Текущий период': [
                      moment().date() > 15
                        ? moment().startOf('month')
                        : moment().startOf('month').subtract(1, 'd').startOf('month').add(2, 'w'),
                      moment().date() > 15
                        ? moment().startOf('month').add(2, 'w')
                        : moment().startOf('month').subtract(1, 'd'),
                    ],
                  }}
                  disabledDate={disabledDate}
                  format={RU_DATE}
                  value={value && [value[0], value[1]]}
                  onChange={(newValue: any) => {
                    setValue(newValue);
                  }}
                />
              </ConfigProvider>
            </div>
            {/* )} */}
            {/* {manualAccural && (
              <div className="mb-8">
                <span className="mr-2">Период:</span>
                <span>
                  {moment(value[0]).format('DD.MM.YYYY')} -{' '}
                  {moment(value[1]).format('DD.MM.YYYY')}
                </span>
              </div>
            )} */}

            {/* {!manualAccural && ( */}
            <div className="self-end">
              <button className="btn-primary_big ml-5" onClick={clickHandler} disabled={loading}>
                открыть
              </button>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
      {manualAccural && (
        <div className="grid gap-2" style={{ gridTemplateColumns: '40px auto' }}>
          <span className="opacity-50">250</span>
          <span className="text-sm text-smena_black pb-1 flex">Нередактируемое поле</span>
          <span>250</span>
          <span className="text-sm text-smena_black pb-1 flex">Редактируемое поле</span>
          <span>
            <SelfEmployer />
          </span>
          <span className="text-sm text-smena_black pb-1 flex">Cамозанятый</span>
          <input type="checkbox" checked disabled className="checkbox-primary" />
          <span className="text-sm text-smena_black pb-1 flex">Исполнителю совершена выплата</span>
        </div>
      )}
    </div>
  );
};

export default PaymentsFilter;
