export const MasterCard = () => {
  return (
    <svg
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4116_254841)">
        <path
          d="M11.3945 3.50446H16.506V12.4955H11.3945V3.50446Z"
          fill="#FF5F00"
        />
        <path
          d="M11.721 7.99999C11.721 6.17322 12.5972 4.55289 13.944 3.50444C12.9542 2.74197 11.7048 2.28131 10.3417 2.28131C7.11248 2.28131 4.5 4.83879 4.5 7.99999C4.5 11.1611 7.11248 13.7187 10.3417 13.7187C11.7047 13.7187 12.9541 13.258 13.944 12.4955C12.5972 11.4629 11.721 9.82677 11.721 7.99999Z"
          fill="#EB001B"
        />
        <path
          d="M23.4049 7.99999C23.4049 11.1611 20.7924 13.7187 17.5633 13.7187C16.2002 13.7187 14.9508 13.258 13.9609 12.4955C15.324 11.4471 16.184 9.82677 16.184 7.99999C16.184 6.17322 15.3077 4.55289 13.9609 3.50444C14.9507 2.74197 16.2002 2.28131 17.5633 2.28131C20.7924 2.28131 23.405 4.85471 23.405 7.99999H23.4049Z"
          fill="#F79E1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_4116_254841">
          <rect
            width="19"
            height="12"
            fill="white"
            transform="translate(4.5 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
