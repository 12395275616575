export const NewDocuments = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" stroke="#548EFF" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C12.5523 4 13 4.44772 13 5V12.0509L16.5 15C16.9128 15.3669 16.95 15.999 16.583 16.4118C16.2161 16.8246 15.5841 16.8617 15.1713 16.4948L11 12.9491V5C11 4.44772 11.4477 4 12 4Z"
        fill="#548EFF"
      />
    </svg>
  );
};
