import { useLocation } from 'react-router-dom';
import { Skeleton } from '@mui/material';

interface SkeletonLayoutInterface {
  columns?: {
    name: string;
    className: string;
    style?: any;
  }[];
  rows?: number;
}

const SkeletonLayout = ({ columns, rows = 15 }: SkeletonLayoutInterface) => {
  let location = useLocation();
  let rowsArray: number[] = [];
  for (let i = 0; i < rows; i++) {
    rowsArray.push(i);
  }
  const groupsItems = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  return (
    <>
      {location.pathname !== '/groups' ? (
        <div className="main-table">
          {rowsArray.map(el => (
            <div
              key={el}
              className="grid table__row"
              style={{
                gridTemplateColumns: columns
                  ?.map(
                    column =>
                      'minmax(' +
                      column.style.minWidth +
                      'px' +
                      ', ' +
                      column.style.width +
                      'px)'
                  )
                  .join(' '),
                columnGap: 10,
              }}
            >
              {columns?.map(column => (
                <div
                  key={column.name}
                  className={column.className}
                  style={column.style}
                >
                  <Skeleton />
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-6">
          {groupsItems.map(item => (
            <div
              key={item}
              className="col-span-3 p-5 flex justify-between items-center shadow-smena rounded-lg bg-smena_white"
            >
              <Skeleton style={{ width: '100%' }} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SkeletonLayout;
