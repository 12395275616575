export const getNavLinkClassname = (
  isActive: boolean,
  name: string,
  menu: boolean,
  hover: boolean
) => {
  const marginLinks = ['Главная', 'Выплаты', 'Вакансии'];
  const menuHover = menu || hover;
  const menuHoverClassName = menuHover
    ? 'nav-link_active'
    : 'nav-link_active_hidden';

  const menuHoverClassNameMargin = menuHover
    ? 'nav-link_active_margin'
    : 'nav-link_active_margin_hidden';

  if (marginLinks.includes(name) && isActive) {
    return menuHoverClassNameMargin;
  }
  if (isActive) {
    return menuHoverClassName;
  }
  if (marginLinks.includes(name)) {
    return menuHover
      ? 'nav-link_margin text-smena_text'
      : 'nav-link_margin_hidden';
  }
  return menuHover ? 'nav-link text-smena_text' : 'nav-link_hidden';
};

export const getNavLinkClassNameMobile = (isActive: boolean, name: string) => {
  const marginLinks = ['Главная', 'Выплаты', 'Вакансии'];

  if (marginLinks.includes(name) && isActive) {
    return 'nav-link_active_margin';
  }
  if (isActive) {
    return 'nav-link_active';
  }
  if (marginLinks.includes(name)) {
    return 'nav-link_margin text-smena_text';
  }
  return 'nav-link text-smena_text';
};
