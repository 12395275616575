export const statusBg = (stage: string) => {
  if (stage === 'FINISHED') {
    return {
      className: 'confirmed',
    };
  }
  if (stage === 'WORKING') {
    return {
      className: 'working_shifts',
    };
  }
  if (stage === 'ACCEPTED') {
    return {
      className: 'without_marks',
    };
  }
  if (stage === 'WASTED') {
    return {
      className: 'rejected_shift',
    };
  } else {
    return {
      className: '',
    };
  }
};
