const FakeArrow = () => {
  return (
    <></>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   fill="none"
    //   viewBox="0 0 20 20"
    //   height="20px"
    //   width="20px"
    //   style={{ position: 'absolute', right: '10px', top: '13px' }}
    // >
    //   <path
    //     stroke="#6b7280"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //     strokeWidth="1.5"
    //     d="M6 8l4 4 4-4"
    //   />
    // </svg>
  );
};

export default FakeArrow;
