import { useStore } from '../store';

import ClientUsersPage from '../components/users/ClientUsers';

import { roles } from '../utils/constVariables';
import OurUsersPage from 'src/components/users/OurUsers';

const UsersPage = () => {
  const { me } = useStore();

  return (
    <>
      {me ? (
        [roles.Admin, roles.Supervisor].includes(me.role) ? (
          <OurUsersPage />
        ) : [roles.ClientDirector, roles.ClientManager].includes(me.role) ? (
          <ClientUsersPage />
        ) : (
          <></>
        )
      ) : (
        <OurUsersPage />
      )}
    </>
  );
};

export default UsersPage;
