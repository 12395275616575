import { useCallback, useEffect, useMemo } from 'react';
import Across from '../../../../Assets/icons/Across';
import {
  useFinishResolveReportUserMutation,
  useGetUsersByFacilityAndPositionQuery,
} from '../../../../generated/graphql';
import { useStore } from '../../../../store';
import { errorHandler } from '../../../../utils/errorHandler';
import { moderationReportConfigFn } from '../../../../utils/graphqlConfigs/moderationReportConfig';
import { errorToast, successToast } from '../../../../utils/notify';
import { ErrorRow } from './Rows';
import Popup from 'src/components/ui/Popup';

export const ImportErrors = ({
  setShowImportErrorPopup,
  showImportErrorPopup,
}: {
  showImportErrorPopup: boolean;
  setShowImportErrorPopup: (value: boolean) => void;
}) => {
  const { resolveModerationData, moderationReport, resolveModerationPosition, setUsersByFacilityAndPosition } =
    useStore();

  const { data } = useGetUsersByFacilityAndPositionQuery({
    variables: {
      facilityId: moderationReport?.getModerationReport.report.facility?.id || '',
      positions: resolveModerationPosition,
    },
  });

  const moderationReportConfig = moderationReportConfigFn(moderationReport?.getModerationReport.report.id);

  const [finishResolveReportUser, { loading }] = useFinishResolveReportUserMutation({
    variables: {
      reportId: moderationReport?.getModerationReport.report.id || '',
    },
    awaitRefetchQueries: true,
    refetchQueries: [moderationReportConfig],
  });

  const worngRows = useMemo(
    () =>
      resolveModerationData?.filter(
        resolveModerationElement =>
          resolveModerationElement.userId === null && resolveModerationElement.disable === false
      ),
    [resolveModerationData]
  );

  const correctRows = useMemo(
    () =>
      resolveModerationData?.filter(
        resolveModerationElement =>
          resolveModerationElement.userId !== null && resolveModerationElement.disable === false
      ),
    [resolveModerationData]
  );

  const excludedRows = useMemo(
    () => resolveModerationData?.filter(resolveModerationElement => resolveModerationElement.disable),
    [resolveModerationData]
  );

  const handleSave = useCallback(() => {
    finishResolveReportUser()
      .then(() => successToast('Данные сохранены'))
      .catch(error => {
        errorToast(errorHandler(error));
      })
      .finally(() => {
        setShowImportErrorPopup(false);
      });
  }, [finishResolveReportUser, setShowImportErrorPopup]);

  useEffect(() => {
    setUsersByFacilityAndPosition(data?.getUsersByFacilityAndPosition);
  }, [data?.getUsersByFacilityAndPosition, setUsersByFacilityAndPosition, showImportErrorPopup]);

  return (
    <Popup setShowPopup={setShowImportErrorPopup} showPopup={showImportErrorPopup}>
      <div className="ozon__import-error">
        <div className="flex flex-col gap-y-5 mb-5">
          <div className="flex justify-between">
            <h3 className="H3 text-smena_primary">Сопоставление исполнителей</h3>
            <button
              onClick={() => {
                setShowImportErrorPopup(false);
              }}
            >
              <Across />
            </button>
          </div>
          <div className="Body2 text-smena_text-secondary">
            Чтобы завершить импорт, необходимо решить все конфликты.
          </div>
          <div className="flex gap-8">
            <span className="Body2 text-smena_text-primary">Всего импортировано: {resolveModerationData?.length}</span>
            <span className="Body2 text-smena_text-primary">Конфликтов: {worngRows?.length}</span>
            <span className="Body2 text-smena_text-primary">Решено: {correctRows?.length}</span>
            <span className="Body2 text-smena_text-primary">Удалено: {excludedRows?.length}</span>
          </div>
        </div>

        <div className="ozon__import-error_table px-2 py-4">
          <span className="Table-H1 text-smena_text-secondary">Исполнитель</span>
          <span className="Table-H1 text-smena_text-secondary">Результат</span>
          <span className="Table-H1 text-smena_text-secondary">Действия</span>
        </div>
        <div className="ozon__import-error_table_block">
          {worngRows && worngRows.length > 0 && (
            <ErrorRow arr={worngRows} color="bg-smena_yellow-mark" text="Конфликты" />
          )}
          {correctRows && correctRows.length > 0 && (
            <ErrorRow arr={correctRows} color="bg-smena_green-mark" text="Решенные" />
          )}
          {excludedRows && excludedRows.length > 0 && (
            <ErrorRow arr={excludedRows} color="bg-smena_red-mark" text="Удаленные" />
          )}
        </div>

        <div className="flex justify-end mt-5">
          <button
            className="btn-primary_small"
            disabled={(worngRows && worngRows.length > 0) || loading}
            onClick={handleSave}
          >
            Сохранить
          </button>
        </div>
      </div>
    </Popup>
  );
};
