export const PartlyAcceptedDocuments = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9984 5.3999C12.606 5.3999 13.0984 5.66256 13.0984 5.98657V13.6132C13.0984 13.9372 12.606 14.1999 11.9984 14.1999C11.3909 14.1999 10.8984 13.9372 10.8984 13.6132V5.98657C10.8984 5.66256 11.3909 5.3999 11.9984 5.3999Z"
        fill="#F5BD14"
      />
      <circle cx="11.9984" cy="17.4999" r="1.1" fill="#F5BD14" />
      <circle cx="12" cy="12" r="10" stroke="#F5BD14" strokeWidth="2" />
    </svg>
  );
};
