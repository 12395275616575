import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { ModerationStatus, PayoutType, useGetModerationReportQuery } from '../../../generated/graphql';

import { useStore } from '../../../store';

import Plus from '../../../Assets/icons/plus';

import MainLayout from '../../../components/Layout/main';
import Loading from '../../../components/ui/Loading';
import { ReportFacilityFilter } from '../../../components/Table/Filters/ReportFacilityFilter';
import { AddEmployerPopup } from '../../../components/ModerationReports/ModerationReport/AddEmployerPopup';
import Sidebar from '../../../components/ModerationReports/ModerationReport/Sidebar';
import { RowDays } from '../../../components/ModerationReports/ModerationReport/EmployerPeriod/RowDays';
import { RowStats } from '../../../components/ModerationReports/ModerationReport/EmployerPeriod/RowStats';
import { RowName } from '../../../components/ModerationReports/ModerationReport/EmployerPeriod/RowName';
import { ImportErrors } from '../../../components/ModerationReports/ModerationReport/ImportErrors';
import OzonBlock from '../../../components/ModerationReports/ModerationReport/OzonModerationReportBlock';

import { groupName } from '../../../utils/filters/filtersName';
import { getPeriodRu } from '../../../utils/get/getPeriodRu';
import { daysCount } from '../../../utils/differenceBetweenDays';

export const UpdateModerationReport = () => {
  const [showImportErrorPopup, setShowImportErrorPopup] = useState(false);
  const [minus, setMinus] = useState(false);
  const [addEmployer, setAddEmployer] = useState(false);

  const router = useParams();

  const {
    addedusers,
    updateModerationReportHeaderCheckbox,
    setUpdateModerationReportHeaderCheckbox,
    setUpdateModerationsReportsToModeration,
    updateModerationsReports,
    setShowSidebar,
    setModerationReport,
    groups,
    showSidebar,
  } = useStore();

  let periodId: string = '';

  if (typeof router.periodId === 'string') {
    periodId = router.periodId;
  }

  const { data, loading } = useGetModerationReportQuery({
    variables: { id: periodId },
  });

  const moderation = data?.getModerationReport;
  const report = moderation?.report;
  const rows = moderation?.rows;

  const isItOzonGroup = useMemo(
    () => groupName(groups, report?.facility?.facilityGroupId) === 'OZON',
    [groups, report?.facility?.facilityGroupId]
  );
  const isItPryamikomGroup = useMemo(
    () => groupName(groups, report?.facility?.facilityGroupId) === 'Прямиком',
    [groups, report?.facility?.facilityGroupId]
  );
  const isItGoodGroup = isItOzonGroup || isItPryamikomGroup;

  const days = useMemo(
    () => daysCount({ dateFrom: report?.dateStart, dateTo: report?.dateEnd }),
    [report?.dateEnd, report?.dateStart]
  );

  const title = useMemo(() => {
    return (
      report?.facility?.name +
      ', ' +
      dayjs(report?.dateStart).format('DD.MM.YY') +
      '–' +
      dayjs(report?.dateEnd).format('DD.MM.YY') +
      ', ' +
      getPeriodRu(report?.periodType)
    );
  }, [report?.dateEnd, report?.dateStart, report?.facility?.name, report?.periodType]);

  const addEmployerBlock = useRef<HTMLDivElement>(null);

  const rowsToModeration = useMemo(
    () =>
      rows
        ?.filter(
          row =>
            row.days.filter(
              day =>
                day.shifts.filter(shift => {
                  const nonModeration = shift.moderation === null;
                  const piceworkAndImport =
                    shift.payout === PayoutType.Piecework && Boolean(moderation?.report.isImportFromFile);
                  if (piceworkAndImport) {
                    return nonModeration && piceworkAndImport;
                  }
                  return nonModeration;
                }).length > 0
            ).length > 0
        )
        .flat(),
    [moderation?.report.isImportFromFile, rows]
  );

  const checkboxHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setUpdateModerationReportHeaderCheckbox(checked);
      if (rowsToModeration) {
        if (checked) {
          setUpdateModerationsReportsToModeration(rowsToModeration);
        } else {
          setUpdateModerationsReportsToModeration([]);
        }
      }
    },
    [rowsToModeration, setUpdateModerationReportHeaderCheckbox, setUpdateModerationsReportsToModeration]
  );

  const checkboxDisabled = useCallback(() => {
    if (rows?.length) {
      const shiftsCount = rows?.map(row => row.shiftsCount)?.reduce((prev, curr) => prev + curr);
      if (shiftsCount === 0 || rowsToModeration?.length === 0) {
        return true;
      }
    }
    return true;
  }, [rows, rowsToModeration]);

  const width = useMemo(() => {
    switch (getPeriodRu(report?.periodType)) {
      case 'Месяц':
        return isItGoodGroup ? 'minmax(100px, 365px)' : 'minmax(100px, 640px)';
      case 'Две недели':
        return isItGoodGroup ? 'minmax(100px, 365px)' : 'minmax(100px, 465px)';
      case 'Неделя':
        return isItGoodGroup ? 'minmax(100px, 285px)' : 'minmax(100px, 285px)';
      default:
        return 'minmax(min-content, 540px)';
    }
  }, [isItGoodGroup, report?.periodType]);

  useEffect(() => setShowSidebar(false), [setShowSidebar]);

  useEffect(() => {
    setModerationReport(data);
    setUpdateModerationsReportsToModeration([]);
    setUpdateModerationReportHeaderCheckbox(false);
  }, [data, setModerationReport, setUpdateModerationReportHeaderCheckbox, setUpdateModerationsReportsToModeration]);

  useEffect(() => {
    if (updateModerationsReports?.length === 0) {
      setUpdateModerationReportHeaderCheckbox(false);
    } else {
      setUpdateModerationReportHeaderCheckbox(true);
      setMinus(true);
    }
    rowsToModeration?.forEach(row => {
      if (
        updateModerationsReports &&
        updateModerationsReports.includes(row) &&
        rowsToModeration.length === updateModerationsReports.length
      ) {
        setUpdateModerationReportHeaderCheckbox(true);
        setMinus(false);
      } else {
        setMinus(true);
      }
    });
  }, [updateModerationsReports, rowsToModeration, setUpdateModerationReportHeaderCheckbox]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <MainLayout
        title={title}
        bg="bg-smena_bb-background_base"
        breadCrumbs={[
          { path: '/moderations-period', name: 'Модерация смен' },
          { path: '/moderations-period/' + periodId, name: 'Период' },
        ]}
      >
        <div className="flex flex-col gap-y-5 w-full max-w-max">
          <ReportFacilityFilter periodId={periodId} report={report} />
          <div className="flex flex-wrap 2lg:flex-nowrap gap-x-5">
            <div className="period flex flex-col shadow-smena px-4" ref={addEmployerBlock}>
              <div className="mt-1 mb-4 Subtitle1">{getPeriodRu(report?.periodType)}</div>
              <div
                className="grid mb-4"
                style={{
                  gridTemplateColumns: `minmax(290px, auto) ${width} minmax(250px, auto)`,
                }}
              >
                <div>
                  <div className="period__header flex w-full bg-smena_white moderation__header">
                    <input
                      type="checkbox"
                      id="pay"
                      className={`checkbox-primary ${minus ? 'minus' : ''}`}
                      checked={updateModerationReportHeaderCheckbox}
                      disabled={checkboxDisabled()}
                      onChange={checkboxHandler}
                    />
                    <span className="pl-3">К оплате</span>
                  </div>
                  <div className="overflow-auto">
                    <div
                      className="shadow-smena"
                      style={{
                        width: 'calc(100% - 3px)',
                      }}
                    >
                      <div className="flex flex-col">
                        {rows?.map(row => (
                          <RowName key={row.user.id + row.position.id} row={row} />
                        ))}
                        {addedusers.length > 0 &&
                          addedusers.map(row => <RowName key={row.user.id + row.position.id} row={row} />)}
                      </div>
                    </div>
                  </div>
                </div>
                <ScrollSync>
                  <div>
                    <ScrollSyncPane>
                      <div
                        className="row-days__days-num moderation__header p-1"
                        style={{
                          gridTemplateColumns: `repeat(${days?.length}, 1fr)`,
                          columnGap: '5px',
                        }}
                      >
                        {days?.map(day => (
                          <div className="period__text Caption-numbers" key={day.valueOf()}>
                            {day.format('DD')}
                          </div>
                        ))}
                      </div>
                    </ScrollSyncPane>
                    <ScrollSyncPane>
                      <div className="overflow-x-auto">
                        {rows?.map(row => (
                          <RowDays key={row.user.id + row.position.id} row={row} days={days} />
                        ))}
                        {addedusers.length > 0 &&
                          addedusers.map(row => <RowDays key={row.user.id + row.position.id} days={days} row={row} />)}
                      </div>
                    </ScrollSyncPane>
                  </div>
                </ScrollSync>

                <div className="overflow-auto">
                  <div className="table__stats bg-smena_white moderation__header pl-2 w-fit">
                    <span>Смен</span>
                    <span>К оплате</span>
                    <span>Факт</span>
                    <span>План</span>
                    <span>Удерж.</span>
                    <span>Проб. п, ₽</span>
                    <span>Всего, ₽</span>
                  </div>
                  <div className="flex justify-end w-fit overflow-auto">
                    <div
                      className="shadow-smena"
                      style={{
                        width: 'calc(100% - 3px)',
                      }}
                    >
                      {rows?.map(row => {
                        return <RowStats row={row} key={row.user.id + row.position.id} days={days} />;
                      })}
                      {addedusers.length > 0 &&
                        addedusers.map(row => <RowStats key={row.user.id + row.position.id} days={days} row={row} />)}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  className={clsx(
                    'flex items-center gap-x-2 ml-3 my-6 Button1',
                    showSidebar || moderation?.report.status === ModerationStatus.Sent
                      ? 'text-smena_text-disabled'
                      : 'text-primary'
                  )}
                  disabled={showSidebar || moderation?.report.status === ModerationStatus.Sent}
                  onClick={() => {
                    setAddEmployer(!addEmployer);
                  }}
                >
                  <span>
                    <Plus
                      className={
                        showSidebar || moderation?.report.status === ModerationStatus.Sent
                          ? 'text-smena_text-disabled'
                          : 'text-primary'
                      }
                    />
                  </span>
                  <span className="Button3">добавить исполнителя</span>
                </button>
              </div>
              {addEmployer && (
                <>
                  <div
                    className="overlay"
                    onClick={() => {
                      setAddEmployer(false);
                    }}
                  ></div>
                  <div
                    className="shadow-smena add_employer"
                    style={{
                      width: showSidebar ? '800px' : '1050px',
                      left: addEmployerBlock.current?.offsetLeft,
                      top:
                        Number(addEmployerBlock.current?.offsetHeight) > 1000
                          ? Number(addEmployerBlock.current?.offsetHeight) - Number(addEmployerBlock.current?.offsetTop)
                          : Number(addEmployerBlock.current?.offsetTop),
                    }}
                  >
                    <AddEmployerPopup hide={setAddEmployer} />
                  </div>
                </>
              )}
            </div>
            {isItGoodGroup && <OzonBlock setImportError={setShowImportErrorPopup} />}
          </div>
        </div>
      </MainLayout>
      <Sidebar />
      {showImportErrorPopup && (
        <ImportErrors showImportErrorPopup={showImportErrorPopup} setShowImportErrorPopup={setShowImportErrorPopup} />
      )}
    </>
  );
};
