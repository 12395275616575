import clsx from 'clsx';
import { ErrorNotification } from 'src/components/ui/ErrorNotification';
import { useStore } from 'src/store';
import { sortObj } from 'src/utils/sortObject';

interface IPositionSelect {
  workpostIdError: boolean;
  workpostId: string;
  selectWorkpostHandler: ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => void;
  positionsFromCasings?: any[];
}

const PositionSelect: React.FC<IPositionSelect> = ({
  selectWorkpostHandler,
  workpostId,
  workpostIdError,
  positionsFromCasings,
}) => {
  const { currentUser } = useStore();
  return (
    <div className="inputHeight crudUserInput">
      <label htmlFor="facility" className="label-primary">
        Должность
      </label>
      <select
        name="workpost"
        className={clsx(workpostIdError ? 'select-error' : 'select-primary')}
        value={workpostId || ''}
        onChange={selectWorkpostHandler}
      >
        {positionsFromCasings?.length === 1 ? (
          <option
            key={positionsFromCasings[0].id}
            value={positionsFromCasings[0].id}
          >
            {positionsFromCasings[0].name}
          </option>
        ) : (
          <>
            <option value="" disabled>
              Выберите должность
            </option>
            {currentUser?.workposts &&
              positionsFromCasings &&
              sortObj(positionsFromCasings).map(position => (
                <option key={position.id} value={position.id}>
                  {position.name}
                </option>
              ))}
          </>
        )}
      </select>
      {workpostIdError && <ErrorNotification text="Выберите должность" />}
    </div>
  );
};

export default PositionSelect;
