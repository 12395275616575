import React, { ReactNode } from 'react';
import OutsideClick from '../../../hooks/useOutsideClick';
import clsx from 'clsx';

interface IPopup {
  children?: ReactNode;
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
  top?: string;
}

const Popup: React.FC<IPopup> = ({ children, setShowPopup, showPopup }) => {
  return (
    <>
      <div
        className={clsx(
          'top-0 left-0',
          showPopup ? 'fixed z-30 w-full h-full bg-smena_gray-90 opacity-20' : 'absolute'
        )}
      ></div>
      <OutsideClick setShow={setShowPopup} show={showPopup}>
        <div
          className={clsx(
            'fixed left-1/2 z-30 -translate-x-1/2 h-auto transition-all duration-300',
            showPopup ? 'top-[50px]' : '-top-[399px]'
          )}
        >
          <div className="flex items-center justify-center">{children}</div>
        </div>
      </OutsideClick>
    </>
  );
};

export default Popup;
