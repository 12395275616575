import { RegularZayavkaShiftFragment, ShiftStage } from '../../../generated/graphql';
import { useMemo } from 'react';
import { useStore } from '../../../store';
import dayjs from 'dayjs';

interface Props {
  requestShift?: RegularZayavkaShiftFragment | null;
}
interface Props2 extends Props {
  day?: dayjs.Dayjs;
  requestScheduleId?: string;
}

export const useBackgroundAndBorder = ({ requestShift }: Props) => {
  const { requestShift: requestShiftStore } = useStore();

  const border = requestShiftStore?.id === requestShift?.id ? 'border-2' : 'border-0';
  return useMemo(() => {
    if (requestShift?.shift) {
      if ([ShiftStage.Ineligible, ShiftStage.Refused, ShiftStage.Wasted].includes(requestShift.shift.stage)) {
        return {
          backgroundColor: 'bg-smena_red-extra_light',
          border: border + ' border-smena_red',
        };
      }
      if (requestShift.shift.isProbation) {
        return {
          backgroundColor: 'bg-smena_purple-light',
          border: border + ' border-smena_purple',
        };
      }
      switch (requestShift?.shift?.stage) {
        case ShiftStage.New:
          return {
            backgroundColor: 'bg-smena_orange-extra_light',
            border: border + ' border-smena_orange',
          };
        case ShiftStage.Accepted:
          return {
            backgroundColor: 'bg-smena_green-extra_light',
            border: border + ' border-smena_green',
          };

        default:
          return {
            backgroundColor: 'bg-smena_bb-background',
            border: border + ' border-primary-light',
          };
      }
    }
    return {
      backgroundColor: 'bg-smena_bb-background',
      border: border + ' border-primary-light',
    };
  }, [border, requestShift?.shift]);
};

export const useCellClassName = ({ requestShift }: Props) => {
  const { requestShift: requestShiftStore } = useStore();

  const border = requestShiftStore?.id === requestShift?.id ? 'border-2' : 'border-0';
  const backgroundAndBorder = useBackgroundAndBorder({ requestShift });
  return useMemo(() => {
    if (requestShift?.isDeleted) {
      return border + ' bg-smena_gray-5 line-through';
    }
    if (requestShift?.newDateFrom && requestShift?.newDateEnd) {
      return border + ' bg-smena_bb-background text-primary';
    }
    if (requestShift?.shift?.stage) {
      return `${backgroundAndBorder.backgroundColor} ${backgroundAndBorder.border}`;
    }

    return border + ' border-smena_bb-border bg-smena_bb-background';
  }, [
    backgroundAndBorder.backgroundColor,
    backgroundAndBorder.border,
    border,
    requestShift?.isDeleted,
    requestShift?.newDateEnd,
    requestShift?.newDateFrom,
    requestShift?.shift?.stage,
  ]);
};

export const useRequestShift = ({ requestShift, day, requestScheduleId }: Props2) => {
  const { requestDayAtRow } = useStore();
  const cellClassName = useCellClassName({ requestShift });

  const isSameDay = dayjs(day).valueOf() === dayjs(requestDayAtRow?.day).valueOf();
  const isSameRequestScheduleId = requestDayAtRow?.requestScheduleId === requestScheduleId;

  const emptyCellBorder = isSameDay && isSameRequestScheduleId ? 'border-2' : 'border-0.5';

  return useMemo(() => {
    if (requestShift) {
      const isPastShift = requestShift.newDateFrom
        ? dayjs(requestShift.newDateFrom).valueOf() < dayjs().valueOf()
          ? ' opacity-60'
          : ''
        : dayjs(requestShift.dateFrom).valueOf() < dayjs().valueOf()
        ? ' opacity-60'
        : '';
      const className = cellClassName + isPastShift;

      return {
        requestShift,
        content:
          requestShift.newDateFrom && requestShift.newDateEnd
            ? dayjs(requestShift?.newDateFrom).format('HH:mm') + ' - ' + dayjs(requestShift?.newDateEnd).format('HH:mm')
            : dayjs(requestShift?.dateFrom).format('HH:mm') + ' - ' + dayjs(requestShift?.dateEnd).format('HH:mm'),
        className,
      };
    }
    return {
      requestShift: null,
      content: null,
      className: emptyCellBorder + ' border-smena_gray-5 bg-smena_white',
    };
  }, [cellClassName, emptyCellBorder, requestShift]);
};
