import { DocumentsStatus } from '../../generated/graphql';

export const getDocumentStatusRu = (status: DocumentsStatus) => {
  const statuses = [
    {
      status: DocumentsStatus.Accepted,
      statusRu: 'Документы приняты',
      style: {
        maxWidth: '155px',
      },
    },
    {
      status: DocumentsStatus.InProgress,
      statusRu: 'Документы ожидают проверки',
      style: {
        maxWidth: '145px',
      },
    },
    {
      status: DocumentsStatus.PartlyAccepted,
      statusRu: 'Документы приняты частично',
      style: {
        maxWidth: '140px',
      },
    },
    {
      status: DocumentsStatus.Rejected,
      statusRu: 'Документы отклонены',
      style: {
        maxWidth: '95px',
      },
    },
  ];
  return statuses.find(statusEl => statusEl.status === status);
};
