import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useStore } from '../../../../store';
import MainSidebar from '../../../ui/Sidebar';
import CustomEvent from './CustomEvent';
import { ShiftStage } from '../../../../generated/graphql';

const ShiftsSidebar = () => {
  const { showSidebar, setShowSidebar, selectedSchedule, hiddenCanceledShifts } = useStore();

  const title = useMemo(
    () => `Смены
  ${selectedSchedule?.length ? dayjs(selectedSchedule[0].start).format('DD MMMM') : ''}`,
    [selectedSchedule]
  );
  return (
    <MainSidebar title={title} show={showSidebar} setShow={setShowSidebar}>
      {selectedSchedule
        ?.filter(shift => {
          if (hiddenCanceledShifts) {
            return ![ShiftStage.Refused, ShiftStage.Ineligible].includes(shift.resource.stage as ShiftStage);
          }
          return shift;
        })
        .map(shift => (
          <CustomEvent key={shift.resource?.id} event={shift} />
        ))}
    </MainSidebar>
  );
};

export default ShiftsSidebar;
