import { RegularUserShiftsFragment, ShiftStage } from 'src/generated/graphql';
import { setEndDateSchedule } from 'src/utils/setEndDateSchedule';
import dayjs from 'dayjs';
import { ICustomEvent } from 'src/Interfaces/IEvent';
import { useMemo } from 'react';

const useScheduleMainEvents = (userShifts?: RegularUserShiftsFragment[]) => {
  return useMemo(() => {
    const mainEvents: ICustomEvent[] = [];
    const mainEventsWithoutCanceled: ICustomEvent[] = [];
    if (userShifts) {
      userShifts.forEach(shift => {
        const {
          id,
          authorId,
          userId,
          dateFrom,
          dateEnd,
          facility,
          positionId,
          lunchDuration,
          comment,
          stage,
          rejectedById,
          isProbation,
          scheduleId,
        } = shift;

        const event = {
          title: `${dayjs(Number(shift.dateFrom)).format('HH:mm')}-${
            shift.dateEnd ? dayjs(Number(shift.dateEnd)).format('HH:mm') : ' –:–'
          }`,
          start: dayjs(Number(shift.dateFrom)).toDate(),
          end: setEndDateSchedule(dayjs(Number(shift.dateFrom)).toDate(), dayjs(Number(shift.dateEnd)).toDate()),
          resource: {
            id,
            scheduleId,
            authorId: authorId || '',
            userId: userId || '',
            dateFrom,
            dateEnd: dateEnd || '',
            facility,
            positionId: positionId || '',
            lunchDuration,
            comment,
            stage,
            rejectedById: rejectedById || '',
            isProbation,
          },
        };
        if (shift.stage !== ShiftStage.Refused && shift.stage !== ShiftStage.Ineligible) {
          mainEventsWithoutCanceled.push(event);
        }
        mainEvents.push(event);
      });
    }
    return {
      mainEvents,
      mainEventsWithoutCanceled,
    };
  }, [userShifts]);
};

export default useScheduleMainEvents;
