import dayjs from 'dayjs';
import { useStore } from '../../../../../store';
import { getDistance } from '../../../../../utils/get/getDistance';
import { durationFn } from '../../../../../utils/durationFn';
import { showTime } from '../../../../../utils/showTime';
import { ICustomEvent } from '../../../../../Interfaces/IEvent';
import clsx from 'clsx';
import { shiftsClassName } from 'src/utils/shiftsClassName';

const CustomEvent = ({ event }: { event: ICustomEvent }) => {
  const { positions } = useStore();
  const {
    resource: {
      id,
      facility,
      marks,
      dateFrom,
      dateEnd,
      positionId,
      lunchDuration,
    },
  } = event;
  return (
    <div className="flex flex-col mb-10 p-4 border rounded-lg shadow-smena bg-smena_white gap-y-2">
      <div className="flex items-center Caption-numbers text-smena_text gap-x-1">
        <span>{facility?.name};</span>
        <span>
          {positions?.find(position => position.id === positionId)?.name}
        </span>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-x-[5px]">
          <div
            className={clsx(
              'flex justify-center items-center rounded-md w-[80px] h-[17px]',
              shiftsClassName(event.resource).className
            )}
          >
            {marks && marks.length ? (
              <span className="Tag text-smena_gray-80">
                {dayjs(Number(marks[0].time)).format('HH:mm')}
                {' – '}
                {marks[1] ? (
                  <span className="text-smena_gray">
                    {dayjs(Number(marks[marks.length - 1].time)).format(
                      'HH:mm'
                    )}
                  </span>
                ) : (
                  <span>—:—</span>
                )}
              </span>
            ) : (
              <span>—:—</span>
            )}
          </div>
          <span className="ml-1 Tag text-smena_text-secondary">по факту</span>
        </div>
        <div className="flex">
          <div className="flex items-center  Tag text-smena_text-secondary">
            <span
              style={{ width: '80px', marginRight: '5px' }}
              className="flex justify-center items-center"
            >
              {dayjs(Number(dateFrom)).format('HH:mm') + ' – '}
              {dateEnd ? dayjs(Number(dateEnd)).format('HH:mm') : '—:—'}
            </span>
            <span className="ml-1">по графику</span>
          </div>
        </div>
      </div>

      {marks && marks.length > 0 && (
        <div>
          {marks.map((mark: any) => {
            return (
              <div
                key={mark.time}
                className="grid"
                style={{
                  gridTemplateColumns: '86px 144px',
                  columnGap: '10px',
                }}
              >
                <div
                  className="col-span-1 grid"
                  style={{ gridTemplateColumns: '55px 31px' }}
                >
                  <span className="Caption text-smena_text">
                    {mark.type === 'CHECKOUT' ? 'Чекаут: ' : 'Чекин:'}
                  </span>
                  <span
                    className={`Caption-numbers ${
                      mark.statusMessage ? 'text-smena_red' : 'text-smena_text'
                    }`}
                  >
                    {dayjs(Number(mark.time)).format('HH:mm')}
                  </span>
                </div>
                <div
                  className="col-span-1 grid"
                  style={{
                    gridTemplateColumns: '77px max-content',
                    columnGap: '5px',
                  }}
                >
                  <span className="Caption text-smena_text">Расстояние:</span>
                  <span
                    className={`Caption-numbers ${
                      mark.statusMessage ? 'text-smena_red' : 'text-smena_text'
                    }`}
                  >
                    {getDistance(mark.distance)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="items-center mt-2 Tag text-smena_text-helper gap-x-1">
        <span>Обед: </span>
        <span>
          {lunchDuration ? showTime(durationFn(lunchDuration)) : 'нет'}
        </span>
      </div>
      <div className="flex items-center Tag text-smena_text-helper gap-x-1">
        <span>id смены: </span>
        <span>{id}</span>
      </div>
      <div className="flex items-center Tag text-smena_text-helper gap-x-1">
        <span>Координаты объекта: </span>
        <span>
          {facility?.latitude}
          {'; '}
          {facility?.longitude}
        </span>
      </div>
    </div>
  );
};

export default CustomEvent;
