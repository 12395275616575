import Check from 'src/Assets/icons/check';
import { ZayavkaStage } from 'src/generated/graphql';
import { useSendButton } from './hooks/sendHooks';
import { useStore } from '../../../store';

const SendButton: React.FC = () => {
  const { request } = useStore();

  const { sendButtonText, showSendButton, sendRequest, loading } = useSendButton();

  return (
    <>
      {showSendButton ? (
        <button
          className="btn-primary"
          type="button"
          onClick={sendRequest}
          disabled={request?.zayavkaSchedule?.length === 0 || loading}
        >
          {sendButtonText}
        </button>
      ) : request?.status === ZayavkaStage.New ? (
        <div className="flex gap-x-1">
          <span className="text-smena_green-dark">
            <Check />
          </span>
          <span className="Body1">Заявка отправлена</span>
        </div>
      ) : null}
    </>
  );
};

export default SendButton;
