import React, { useEffect, useMemo, useState } from 'react';
import { Pagination } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useFacilitiesPaginatedLazyQuery } from '../generated/graphql';

import MainLayout from '../components/Layout/main';
import { facilitiesClientColumns } from '../components/Table/columns';
import TableHeader from '../components/Table/header';
import SkeletonLayout from '../components/Table/Skeleton';

import FacilitiesFilter from '../components/Table/Filters/FacilitiesFilter';
import { activeHandler } from '../utils/isActiveHandler';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { maxTableWidth } from 'src/utils/maxTableWidth';
import FacilitiesClientRow from 'src/components/Table/Row/FacilitiesClientRow';

const ClientFacilitiesPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [isActive, setIsActive] = useState<boolean | string>('');
  const [groupId, setGroupId] = useState('');
  const [cityId, setCityId] = useState('');
  const [params] = useSearchParams();

  const location = useLocation();

  const [loadData, { data: dataFacilities, loading: loadingFacilities }] = useFacilitiesPaginatedLazyQuery();

  const facilities = dataFacilities?.facilitiesPaginated.facilities;
  const facilitiesPaginated = dataFacilities?.facilitiesPaginated;

  let searchParam = params.get('search');
  let cityParam = params.get('cityId');
  let activeParam = params.get('active');

  const mutationInput = useMemo(
    () => ({
      groupId,
      page: 1,
      cityId,
      isActive: activeHandler(isActive),
      searchQuery: searchParam || '',
      sortBy,
      sortWay,
    }),
    [cityId, groupId, isActive, searchParam, sortBy, sortWay]
  );

  const returnData = () => {
    return loadData({
      variables: {
        input: mutationInput,
      },
    });
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (cityParam) {
      setCityId(cityParam);
    } else {
      setCityId('');
    }

    if (activeParam) {
      setIsActive(activeParam);
    } else {
      setIsActive('');
    }

    loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
          cityId: cityParam ? cityParam : '',
          isActive: activeParam ? activeHandler(activeParam) : activeHandler(''),
        },
      },
    });
  }, [page, sortWay, sortBy, location, cityParam, activeParam, loadData, mutationInput]);

  return (
    <MainLayout
      title="Объекты"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[{ path: '/facilities', name: 'Объекты' }]}
    >
      <div
        style={{
          maxWidth: maxTableWidth(facilitiesClientColumns) + 'px',
        }}
      >
        <FacilitiesFilter
          returnData={returnData}
          setPage={setPage}
          groupId={groupId}
          setGroupId={setGroupId}
          setIsActive={setIsActive}
          setCityId={setCityId}
          isActive={isActive}
          cityId={cityId}
        />
        <ScrollSync>
          <div className="flex flex-col shadow-smena rounded-lg">
            <TableHeader
              dataLength={facilities?.length}
              columns={facilitiesClientColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />
            {loadingFacilities || !facilities ? (
              <SkeletonLayout columns={facilitiesClientColumns} />
            ) : facilities.length > 0 ? (
              <ScrollSyncPane>
                <div className="main-table">
                  {facilities?.map(facility => (
                    <FacilitiesClientRow facility={facility} columns={facilitiesClientColumns} />
                  ))}
                </div>
              </ScrollSyncPane>
            ) : (
              <div className="w-full flex justify-center my-5">
                <h3 className="text-smena_text Body1">
                  По вашему запросу <span className="font-bold">{searchParam}</span> ничего не нашлось
                </h3>
              </div>
            )}
          </div>
        </ScrollSync>
        <div className="py-5">
          {facilitiesPaginated?.pages! > 1 ? (
            <Pagination
              shape="rounded"
              count={facilitiesPaginated ? facilitiesPaginated.pages : 10}
              page={page}
              onChange={handleChange}
            />
          ) : null}
        </div>
      </div>
    </MainLayout>
  );
};

export default ClientFacilitiesPage;
