export const Visa = () => {
  return (
    <svg
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5928 5.10246L8.97451 10.7135H7.2664L5.97793 6.23537C5.89981 5.96021 5.83193 5.85895 5.59422 5.7426C5.20566 5.55298 4.56401 5.37565 4 5.26533L4.03804 5.10246H6.78809C7.13805 5.10246 7.45318 5.31179 7.53333 5.67425L8.21412 8.92147L9.89489 5.10246H11.5928ZM13.9019 5.10246L12.5659 10.7135H10.9498L12.286 5.10246H13.9019ZM18.2856 8.88154C18.2923 7.40091 16.0066 7.31874 16.0215 6.65719C16.027 6.45607 16.2402 6.24196 16.707 6.18723C16.9383 6.16035 17.5771 6.13895 18.3007 6.43846L18.5838 5.24786C18.195 5.12154 17.6947 5 17.0723 5C15.4748 5 14.3511 5.76225 14.3421 6.8546C14.3316 7.6626 15.1448 8.11291 15.7563 8.38204C16.3861 8.65684 16.5974 8.83347 16.5945 9.07909C16.5899 9.45558 16.0914 9.62225 15.6279 9.62842C14.8152 9.64 14.3441 9.43109 13.9682 9.27382L13.6749 10.5039C14.053 10.6595 14.7498 10.7947 15.4715 10.8016C17.1698 10.8016 18.2806 10.0481 18.2856 8.88154ZM22.5039 10.7135H23.998L22.6927 5.10246H21.3142C21.0033 5.10246 20.742 5.26456 20.626 5.51375L18.2013 10.7135H19.8982L20.2348 9.87537H22.3081L22.5039 10.7135ZM20.7002 8.72554L21.551 6.61839L22.0398 8.72554H20.7002Z"
        fill="#1A1F71"
      />
    </svg>
  );
};
