import { IZayavkaPlanInput } from 'src/Interfaces/IZayavkaPlanInput';

export const changeField = (
  field: keyof IZayavkaPlanInput,
  el: IZayavkaPlanInput,
  value:
    | string
    | number
    | { shiftCount?: string; shiftDuration?: string; positionId?: string }
) => ({
  ...el,
  [field]: value,
});
