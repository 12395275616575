import React, { useCallback, useEffect, useState } from 'react';
import UsersIcon from '../../Assets/icons/menu/users';
import MainSidebar from '../../components/ui/Sidebar';
import { getLogins, IFastLogin } from './__utils';
import { FastLoginItem } from './__fastLoginItem';
import { isProd } from '../../utils/constVariables';

export function FastLogin() {
  const [show, setShow] = useState(false);
  const [logins, setLogins] = useState<IFastLogin[]>([]);
  const onClick = useCallback(() => {
    setShow(prevState => !prevState);
  }, []);

  useEffect(() => {
    setLogins(getLogins());
  }, []);

  if (isProd) return <></>;
  return (
    <div>
      <div onClick={onClick} className="hover:scale-105 transition-all cursor-pointer">
        <UsersIcon className={''} />
      </div>
      <MainSidebar title={'Логины'} show={show} setShow={setShow}>
        <div className={'flex flex-col gap-y-2'}>
          {logins.map(entry => (
            <FastLoginItem key={entry.phone} entry={entry} />
          ))}
        </div>
      </MainSidebar>
    </div>
  );
}
