import Across from '../../../../Assets/icons/Across';

const Filter = ({
  content,
  clickHandler,
}: {
  content: string | undefined;
  clickHandler: () => void;
}) => {
  return (
    <div className="mr-2 Caption text-smena_gray-80 px-2 py-1 rounded-lg bg-smena_bb-background flex items-center">
      <span className="mr-1">{content}</span>
      <span className="cursor-pointer" onClick={clickHandler}>
        <Across color="text-primary-light" />
      </span>
    </div>
  );
};

export default Filter;
