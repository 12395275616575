import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ConfigProvider, DatePicker } from 'antd';
import locale from 'antd/lib/locale/ru_RU';

import MainLayout from '../../components/Layout/main';
import SimpleSelect from '../../components/ui/Select';

import { useStore } from '../../store';
import { PeriodType, useCreateModerationReportMutation } from '../../generated/graphql';

import { errorToast, infoToast, successToast } from '../../utils/notify';
import { errorHandler } from '../../utils/errorHandler';
import { periodShift } from '../../utils/lists/periodType';
import { getPeriodRu } from '../../utils/get/getPeriodRu';
import { filterFacilities } from '../../utils/filters/filterFacilities';
import DatePickerIcon from '../../Assets/icons/DatePickerIcon';
import { reportsQueryConfig } from '../../utils/graphqlConfigs/moderationReports';
import { sortObj } from '../../utils/sortObject';
import { RU_DATE } from '../../utils/constVariables';

const { RangePicker } = DatePicker;

interface IValue {
  [key: string]: {
    required: boolean;
    value: string | undefined;
    touched: boolean;
    error: string;
  };
}

const CreateRepot = () => {
  const { groups, facilities } = useStore();
  const [values, setValues] = useState<IValue>({
    group: { required: false, value: '', touched: false, error: '' },
    facility: { required: true, value: '', touched: false, error: '' },
    period: { required: true, value: undefined, touched: false, error: '' },
  });

  const navigate = useNavigate();

  const [date, setDate] = useState<moment.Moment[] | null>(null);
  const [calendarValue, setCalendarValue] = useState<any>();
  const [loading, setLoading] = useState(false);

  const filteredFacilities = useMemo(() => {
    if (values.group.value) {
      return filterFacilities(values.group.value, facilities);
    }
    if (facilities) {
      return sortObj(facilities);
    }
    return [];
  }, [facilities, values.group.value]);

  const errorThrow = (state: IValue) => {
    const errorFields = Object.values(state).filter(value => !Boolean(value.value) && value.required);

    for (const key in state) {
      if (!Boolean(state[key].value) && state[key].required) {
        setValues(values => ({
          ...values,
          [key]: { ...values[key], touched: true, error: 'Обязательное поле' },
        }));
      } else {
        setValues(values => ({
          ...values,
          [key]: { ...values[key], touched: true, error: '' },
        }));
      }
    }
    return errorFields.length > 0;
  };

  const [createReport] = useCreateModerationReportMutation({
    awaitRefetchQueries: true,
    refetchQueries: [reportsQueryConfig()],
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name) {
      setValues(values => ({
        ...values,
        [name]: { ...values[name], value, touched: true, error: '' },
      }));
    } else {
      setValues(values => ({
        ...values,
        [name]: { ...values[name], value },
      }));
    }
  }, []);

  const disabledDate = (current: moment.Moment) => {
    const period = values.period.value;

    const startof = (date: moment.Moment) => {
      return date.startOf('day').valueOf();
    };

    if (date && date.length > 0) {
      return true;
    }

    if (!calendarValue || calendarValue.length === 0) {
      if (period === 'WEEK') {
        return current > moment().endOf('day').subtract(6, 'day');
      } else if (period === 'TWO_WEEK') {
        return current > moment().endOf('day').subtract(13, 'day');
      } else if (period === 'MONTH') {
        return startof(current) > moment().subtract(1, 'month').startOf('month').valueOf();
      }
    }

    const firstDate = moment(calendarValue[0]);
    const lastDate = moment(calendarValue[1]);

    if (period === 'WEEK') {
      if (firstDate.isValid()) {
        return startof(current) !== startof(firstDate.add(1, 'week').subtract(1, 'd'));
      } else if (lastDate.isValid()) {
        return startof(current) !== startof(lastDate.subtract(1, 'week').add(1, 'd'));
      } else {
        return current && current < moment().endOf('day');
      }
    } else if (period === 'TWO_WEEK') {
      if (firstDate.isValid()) {
        return startof(current) !== startof(firstDate.add(2, 'week').subtract(1, 'd'));
      } else if (lastDate.isValid()) {
        return startof(current) !== startof(lastDate.subtract(2, 'week').add(1, 'd'));
      } else return false;
    } else if (period === 'MONTH') {
      if (firstDate.isValid()) {
        return startof(current) !== startof(firstDate.add(1, 'month').subtract(1, 'd'));
      } else if (lastDate.isValid()) {
        return startof(current) !== startof(lastDate.subtract(1, 'month'));
      } else return false;
    } else {
      return false;
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setCalendarValue([]);
    }
  };

  return (
    <MainLayout
      title="Новый период"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[
        { path: '/moderations-period', name: 'Модерация смен' },
        { path: '/moderations-periods/create', name: 'Период' },
      ]}
    >
      <form
        className="bg-smena_white w-full flex gap-x-6 mt-5 rounded-lg shadow-smena pt-2 px-3"
        onSubmit={async e => {
          e.preventDefault();
          if (errorThrow(values)) {
            return;
          }
          if (date) {
            if (values.period.value === 'MONTH' && date[0].date() !== 1) {
              return infoToast('Табель на Месяц может начинаться только с первого числа');
            }
            const mutationVariabels = {
              dateStart: date[0].startOf('day').toISOString(),
              dateEnd: date[1].startOf('day').toISOString(),
              facilityId: values.facility.value || '',
              periodType: values.period.value as PeriodType,
            };
            setLoading(true);
            await createReport({
              variables: {
                input: {
                  ...mutationVariabels,
                },
              },
            })
              .then(response => {
                if (response.data?.createModerationReport) {
                  successToast('Период создан');
                  setTimeout(() => {
                    navigate(`/moderations-period/${response.data?.createModerationReport.id}`);
                  }, 2000);
                }
              })
              .catch(e => {
                errorToast(errorHandler(e));
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            return infoToast('Выберите дату');
          }
        }}
      >
        <SimpleSelect
          divClassName="crudUserMulty"
          label="Группа"
          onChange={handleChange}
          value={values.group.value}
          name="group"
          disabledOption="Все группы"
          options={groups}
        />
        <SimpleSelect
          divClassName="crudUserMulty"
          label="Объект"
          onChange={handleChange}
          value={values.facility.value}
          name="facility"
          disabledOption="Все объекты"
          options={filteredFacilities}
          onBlur={({ target: { name } }) => {
            if (values.facility.value) {
              setValues(values => ({
                ...values,
                [name]: {
                  ...values[name],
                  touched: true,
                  error: '',
                },
              }));
            } else {
              setValues(values => ({
                ...values,
                [name]: {
                  ...values[name],
                  touched: true,
                  error: 'Обязательное поле',
                },
              }));
            }
          }}
          error={values.facility.touched && values.facility.error ? values.facility.error : ''}
        />
        <div className="input__small inputHeight">
          <label htmlFor="period" className="label-primary">
            Тип периода
          </label>
          <select
            name="period"
            id="period"
            className={`${
              values.period.touched && values.period.error ? 'select-error truncate' : 'select-primary truncate'
            }`}
            value={values.period.value}
            onChange={e => {
              setDate(null);
              if (e.target.value !== 'Все периоды') {
                handleChange(e);
              } else {
                setValues(values => ({
                  ...values,
                  period: { ...values.period, value: undefined },
                }));
              }
            }}
            onBlur={({ target: { name } }) => {
              if (values.period.value) {
                setValues(values => ({
                  ...values,
                  [name]: {
                    ...values[name],
                    touched: true,
                    error: '',
                  },
                }));
              } else {
                setValues(values => ({
                  ...values,
                  [name]: {
                    ...values[name],
                    touched: true,
                    error: 'Обязательное поле',
                  },
                }));
              }
            }}
          >
            <option value={undefined}>Все периоды</option>
            {periodShift.map(period => (
              <option key={period} value={period}>
                {getPeriodRu(period)}
              </option>
            ))}
          </select>
          {values.period.touched && values.period.error && (
            <span className="Caption-small text-smena_red pt-1 mb-1">{values.period.error}</span>
          )}
        </div>
        <div
          className="input__period"
          onClick={() => {
            if (values.period.value === undefined) {
              setValues(values => ({
                ...values,
                period: {
                  ...values.period,
                  error: 'Обязательное поле',
                  touched: true,
                },
              }));
            } else {
              setValues(values => ({
                ...values,
                period: {
                  ...values.period,
                  error: '',
                  touched: true,
                },
              }));
            }
          }}
        >
          <label className="label-primary">Период</label>
          <ConfigProvider locale={locale}>
            <RangePicker
              size="middle"
              separator={<span>→</span>}
              disabled={values.period.value === undefined}
              placeholder={['Начало', 'Конец']}
              value={date && [date[0], date[1]]}
              defaultValue={[moment(), moment()]}
              format={RU_DATE}
              className="custom__picker"
              suffixIcon={<DatePickerIcon opacity="opacity-100" />}
              disabledDate={disabledDate}
              onOpenChange={onOpenChange}
              onCalendarChange={val => {
                setCalendarValue(val);
              }}
              onChange={(newValue: any) => {
                setDate(newValue);
              }}
            />
          </ConfigProvider>
        </div>
        <div className="flex items-center">
          <button className="btn-secondary_small" type="submit" disabled={loading}>
            Создать
          </button>
        </div>
      </form>
    </MainLayout>
  );
};

export default CreateRepot;
