import { useCallback, useMemo } from 'react';
import { useStore } from '../../../../store';
import { UpdateShifts } from './DailyShifts/update';
import { CreateShifts } from './DailyShifts/create';
import MainSidebar from '../../../../components/ui/Sidebar';
import { groupName } from '../../../../utils/filters/filtersName';
import { ShiftStage } from '../../../../generated/graphql';
import RefusedShift from './DailyShifts/refused';
import { InternShifts } from './DailyShifts/intern';
import { RU_DATE } from '../../../../utils/constVariables';
import dayjs from 'dayjs';

const Sidebar = () => {
  const { info, userInfo, groups, showSidebar, setShowSidebar, moderationReport } = useStore();

  const facility = moderationReport?.getModerationReport.report.facility;

  const title = useCallback((dayNum?: any) => dayjs(dayNum).format(RU_DATE), []);

  const userRow = useMemo(
    () =>
      moderationReport?.getModerationReport.rows
        .find(report => report.user.id === userInfo?.userFio.id && report.position.id === userInfo?.position?.id)
        ?.days.find(day => day.dayNum === info?.dayNum),
    [moderationReport?.getModerationReport.rows, userInfo, info]
  );

  // Сделать массив новых смен и итерировать по нему компонент createShifts.
  // По нажатию на кнопку добавлять в массив элемент

  return (
    <MainSidebar title={title(info?.dayNum)} show={showSidebar} setShow={setShowSidebar}>
      <div className="relative" style={{ height: 'max-content' }}>
        <div className="mb-5 flex flex-col gap-x-1">
          <div className="flex gap-x-1 H4">
            <span>{userInfo?.userFio.lastname}</span>
            <span>{userInfo?.userFio.firstname}</span>
          </div>
          <div className="flex gap-x-1 Subtitle2">
            <span>{groupName(groups, facility?.facilityGroupId)}</span>
            <span>·</span>
            <span className="truncate" title={facility?.name}>
              {facility?.name}
            </span>
            <span>·</span>
            <span className="truncate" title={userInfo?.position?.name}>
              {userInfo?.position?.name}
            </span>
          </div>
        </div>
        {userRow?.shifts && userRow?.shifts.length > 0 ? (
          userRow.shifts.map((shift, index) => {
            if (userRow.dayValue) {
              shift = { ...shift, value: userRow.dayValue };
            }
            if (shift.isProbation) {
              return <InternShifts key={shift.id} shift={shift} index={index} />;
            }
            if (shift.stage !== ShiftStage.Refused) {
              return <UpdateShifts key={shift.id} shift={shift} index={index} />;
            }
            return <RefusedShift key={shift.id} shift={shift} index={index} />;
          })
        ) : (
          <CreateShifts />
        )}
      </div>
    </MainSidebar>
  );
};

export default Sidebar;
