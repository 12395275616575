export const genders = [
  {
    id: 'female',
    name: 'Женщина',
  },
  {
    id: 'male',
    name: 'Мужчина',
  },
  // {
  //   id: 'nonbinary',
  //   name: 'Я небинарная личность',
  // },
];
