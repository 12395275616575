import clsx from 'clsx';
import { Icons } from 'src/Interfaces/IIcons';

const CheckCircle: React.FC<Icons> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.92472 5.92472 1 12 1C18.0753 1 23 5.92472 23 12C23 18.0753 18.0753 23 12 23C5.92472 23 1 18.0753 1 12ZM12 3C7.02928 3 3 7.02928 3 12C3 16.9707 7.02928 21 12 21C16.9707 21 21 16.9707 21 12C21 7.02928 16.9707 3 12 3Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.169 8.75671C16.5795 9.12617 16.6128 9.75846 16.2433 10.169L11.7433 15.169C11.5653 15.3668 11.3158 15.4854 11.05 15.4988C10.7843 15.5121 10.5242 15.4189 10.3273 15.2399L7.82729 12.9669C7.41865 12.5954 7.38858 11.9629 7.76011 11.5543C8.13164 11.1457 8.76409 11.1156 9.17272 11.4871L10.9291 13.084L14.7567 8.83104C15.1262 8.42053 15.7585 8.38726 16.169 8.75671Z"
      />
    </svg>

    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={clsx('fill-current', className)}
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M3 12C3 7.02931 7.02931 3 12 3C16.9707 3 21 7.02931 21 12C21 16.9707 16.9707 21 12 21C7.02931 21 3 16.9707 3 12ZM12 4.63636C7.93305 4.63636 4.63636 7.93305 4.63636 12C4.63636 16.0669 7.93305 19.3636 12 19.3636C16.0669 19.3636 19.3636 16.0669 19.3636 12C19.3636 7.93305 16.0669 4.63636 12 4.63636Z"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M15.411 9.3464C15.7468 9.64869 15.7741 10.166 15.4718 10.5019L11.79 14.5928C11.6443 14.7546 11.4402 14.8517 11.2228 14.8626C11.0053 14.8735 10.7925 14.7973 10.6314 14.6508L8.58596 12.7911C8.25163 12.4871 8.22702 11.9697 8.531 11.6353C8.83498 11.301 9.35244 11.2764 9.68677 11.5804L11.1238 12.8869L14.2555 9.40722C14.5578 9.07135 15.0751 9.04412 15.411 9.3464Z"
    //   />
    // </svg>
  );
};

export default CheckCircle;
