import { useMemo } from 'react';
import { RegularWorkpostsFragment } from 'src/generated/graphql';

const useFacilityCasingsAtCalendarHeader = (
  facilityId: string,
  facilityCasingsPositions?: any[],
  workpostsPositions?: RegularWorkpostsFragment[] | null
) => {
  return useMemo(
    () =>
      workpostsPositions
        ?.map(workpost => {
          if (facilityId) {
            return facilityCasingsPositions?.filter(
              facilityCasing =>
                facilityCasing.id === workpost.position?.id &&
                facilityCasing.facilityId === workpost.facilityId
            );
          }
          return facilityCasingsPositions;
        })
        .flat(),
    [workpostsPositions, facilityCasingsPositions, facilityId]
  );
};

export default useFacilityCasingsAtCalendarHeader;
