import clsx from 'clsx';
import { useField } from 'formik';

const MySelect = ({ label, classname, ...props }: any) => {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;
  return (
    <div className={classname + ' inputHeight'}>
      <label htmlFor={props.id || props.name} className="label-primary">
        {label}
      </label>
      <select
        className={clsx(
          'truncate',
          showError ? 'select-error' : 'select-primary'
        )}
        {...field}
        {...props}
      />
      {showError ? <span className="error-message">{meta.error}</span> : null}
    </div>
  );
};

export default MySelect;
