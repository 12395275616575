import {
  useDeclineCancelZayavkaByDirectorMutation,
  useDeclineChangesByDirectorMutation,
  useDeclineChangesBySupervisorMutation,
  useDeclineZayavkaByDirectorMutation,
  useDeclineZayavkaBySupervisorMutation,
  ZayavkaChangesStatus,
  ZayavkaStage,
  ZayavkaToCancelStatus,
} from '../../../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { useStore } from '../../../../store';
import { useMeRole } from '../../../../hooks/useRole';
import { roles } from '../../../../utils/constVariables';
import { errorToast, successToast } from '../../../../utils/notify';
import { errorHandler } from '../../../../utils/errorHandler';
import { useChangesByStatus, useRequestStatus } from './index';
import { useParams } from 'react-router-dom';
import { zayavkaConfigFn } from '../../../../utils/graphqlConfigs/zayavkaConfig';

export const useDeclineByDirector = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Draft] });
  const changes = useChangesByStatus({
    status: [ZayavkaChangesStatus.Waiting],
  });
  return changes && requestStatus;
};

export const useDeclineChangesByDirector = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working, ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Waiting, ZayavkaChangesStatus.Draft] });
  return changes && requestStatus;
};

export const useDeclineBySupervisor = () => {
  // const { me, request } = useStore();
  // const isMeSupervisor = useMeRole(roles.Supervisor);
  // const isSupervisorAuthorRequest = isMeSupervisor && me?.id === request?.authorId;
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.Accepted] });
  // (isMeSupervisor ? isSupervisorAuthorRequest : true)
  return changes && requestStatus;
};

export const useDeclineChangesBySupervisor = () => {
  const requestStatus = useRequestStatus({ status: [ZayavkaStage.Working, ZayavkaStage.New] });
  const changes = useChangesByStatus({ status: [ZayavkaChangesStatus.New] });
  return changes && requestStatus;
};

export const useDeclineButton = () => {
  const router = useParams();
  let requestURL = '';

  if (typeof router.requestId === 'string') {
    requestURL = router.requestId;
  }

  const requestsConfig = {
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfigFn(requestURL)],
  };

  const { request } = useStore();
  const isMeDirector = useMeRole(roles.ClientDirector);
  const isMeSupervisor = useMeRole(roles.Supervisor);

  const [declineZayavkaByDirector, { loading: loadingDeclineZayavkaByDirector }] =
    useDeclineZayavkaByDirectorMutation(requestsConfig);
  const [declineZayavkaBySupervisor, { loading: loadingDeclineZayavkaBySupervisor }] =
    useDeclineZayavkaBySupervisorMutation(requestsConfig);
  const [declineChangesByDirectorMutation, { loading: loadingDeclineChangesByDirector }] =
    useDeclineChangesByDirectorMutation(requestsConfig);
  const [declineChangesBySupervisorMutation, { loading: loadingDeclineChangesBySupervisor }] =
    useDeclineChangesBySupervisorMutation(requestsConfig);
  const [declineCancelZayavkaByDirector, { loading: loadingDeclineCancelZayavkaByDirector }] =
    useDeclineCancelZayavkaByDirectorMutation(requestsConfig);

  const loading =
    loadingDeclineZayavkaByDirector ||
    loadingDeclineZayavkaBySupervisor ||
    loadingDeclineChangesByDirector ||
    loadingDeclineChangesBySupervisor ||
    loadingDeclineCancelZayavkaByDirector;

  const declineByDirector = useDeclineByDirector();
  const declineChangesByDirector = useDeclineChangesByDirector();
  const declineCancelByDirector = request?.toCancel === ZayavkaToCancelStatus.Waiting;
  const declineBySupervisor = useDeclineBySupervisor();
  const declineChangesBySupervisor = useDeclineChangesBySupervisor();

  const directorCondition = declineByDirector || declineChangesByDirector || declineCancelByDirector;
  const supervisorCondition = declineBySupervisor || declineChangesBySupervisor;

  const showDeclineButton = useMemo(() => {
    return (isMeDirector && directorCondition) || (isMeSupervisor && supervisorCondition);
  }, [directorCondition, isMeDirector, isMeSupervisor, supervisorCondition]);

  const requestStatus = useRequestStatus({ status: [ZayavkaStage.New] });
  const declineButtonText = useMemo(() => {
    if (requestStatus) {
      return 'Отклонить';
    }
    if (declineChangesByDirector || declineChangesBySupervisor) {
      return 'Отклонить изменения';
    }
    return 'Отклонить';
  }, [declineChangesByDirector, declineChangesBySupervisor, requestStatus]);

  const declineButtonHandler = useCallback(() => {
    if (request) {
      const variables = {
        variables: {
          id: request.id,
        },
      };
      if (isMeDirector) {
        if (declineCancelByDirector) {
          return declineCancelZayavkaByDirector(variables)
            .then(() => {
              successToast('Изменения отклонены');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        if (declineChangesByDirector) {
          return declineChangesByDirectorMutation(variables)
            .then(() => {
              successToast('Изменения отклонены');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        return declineZayavkaByDirector(variables)
          .then(() => {
            successToast('Заявка отклонена');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      if (isMeSupervisor) {
        if (declineChangesBySupervisor) {
          return declineChangesBySupervisorMutation(variables)
            .then(() => {
              successToast('Изменения отклонены');
            })
            .catch(error => errorToast(errorHandler(error)));
        }
        return declineZayavkaBySupervisor(variables)
          .then(() => {
            successToast('Заявка отклонена');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
    }
  }, [
    declineCancelByDirector,
    declineCancelZayavkaByDirector,
    declineChangesByDirector,
    declineChangesByDirectorMutation,
    declineChangesBySupervisor,
    declineChangesBySupervisorMutation,
    declineZayavkaByDirector,
    declineZayavkaBySupervisor,
    isMeDirector,
    isMeSupervisor,
    request,
  ]);

  return { showDeclineButton, declineButtonText, declineButtonHandler, loading };
};
