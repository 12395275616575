import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import SimpleInput from '../../ui/Input';
import { IPhotoPopup } from '../../../Interfaces/IPhotoPopup';
import { PhotoPopup } from './Popup';
import { PhotoBlock } from './PhotoBlock';
import { ApproveBlock } from './ApproveBlock';
import { DocumentType, ReviewType, useChangePersonalInfoMedicalMutation } from '../../../generated/graphql';
import { useStore } from '../../../store';
import { errorToast, successToast } from '../../../utils/notify';
import { errorHandler } from '../../../utils/errorHandler';
import { photoWithoutFaceRejectsList } from '../../../utils/lists/commentsList';
import { getVerificationDate } from '../../../utils/get/getVerificationDate';
import { userDocumentsQueryConfigFn } from '../../../utils/graphqlConfigs/userDocumentsQueryConfig';
import { ISO_DATE, roles, RU_DATE } from '../../../utils/constVariables';
import SelectArrow from '../../../Assets/icons/SelectArrow';
import DatePickerInput from '../../ui/Pickers/DatePickerInput';

export const MedCardPage = () => {
  const router = useParams();

  const { userRequisites, userReviews, userMedicalAttestation, me } = useStore();

  const [popup, setPopup] = useState<IPhotoPopup>();
  const [showPopup, setShowPopup] = useState(false);
  const [showAttestation, setShowAttestation] = useState(false);

  const firstPhotoRef = useRef<HTMLInputElement>(null);
  const secondPhotoRef = useRef<HTMLInputElement>(null);

  const firstPhotoBlockRef = useRef<HTMLInputElement>(null);
  const secondPhotoBlockRef = useRef<HTMLInputElement>(null);

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }
  const userQueryDocumentsConfig = userDocumentsQueryConfigFn(userId);

  const [changeMedical] = useChangePersonalInfoMedicalMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryDocumentsConfig],
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        medNumber: userRequisites?.medicalNumber || '',
        startAttestation: userRequisites?.medicalAttestationStart
          ? dayjs(userRequisites?.medicalAttestationStart)
          : null,
        endAttestation: userRequisites?.medicalAttestationEnd ? dayjs(userRequisites?.medicalAttestationEnd) : null,
      }}
      validate={values => {
        let errors: any = {};
        if (values.medNumber.length < 8) {
          errors.medNumber = 'В поле Номер должно быть 8 символов';
        }
        if (!dayjs(values.startAttestation).isValid()) {
          errors.startAttestation = 'Дата начала введена некорректно';
        }
        if (!dayjs(values.endAttestation).isValid()) {
          errors.endAttestation = 'Дата окончания введена некорректно';
        }
        if (dayjs(values.startAttestation).isAfter(dayjs().add(1, 'day').endOf('day'))) {
          errors.startAttestation = 'Дата начала не может начинатся в будущем';
        }
        if (dayjs(values.startAttestation).isAfter(dayjs(values.endAttestation))) {
          errors.endAttestation = 'Дата окончания не может быть раньше даты начала';
        }
        if (values.medNumber.length < 8) {
          errors.medNumber = 'В поле Номер должно быть 8 символов';
        }
        return errors;
      }}
      onSubmit={values => {
        changeMedical({
          variables: {
            input: {
              userId,
              medicalNumber: values.medNumber,
              medicalAttestationStart: dayjs(values.startAttestation).isValid()
                ? dayjs(values.startAttestation).format(ISO_DATE)
                : undefined,
              medicalAttestationEnd: dayjs(values.endAttestation).isValid()
                ? dayjs(values.endAttestation).format(ISO_DATE)
                : undefined,
            },
          },
        })
          .then(() => successToast('Данные медицинской книжки успешно обновлены'))
          .catch(err => errorToast(errorHandler(err)));
      }}
    >
      {({ values, touched, errors, handleChange, isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <div className="flex flex-col gap-y-4 pb-5 border-b border-smena_gray-5">
              <span className="Subtitle2 text-smena_text-secondary">Страницы 2 и 3</span>
              <div className="flex flex-wrap">
                <div className="flex flex-col border-r border-smena_gray-5 pr-5 gap-y-6 data-block">
                  <div className="flex gap-x-5">
                    <SimpleInput
                      divClassName="number"
                      label="Номер"
                      placeholder="00000000"
                      maxLength={8}
                      onChange={handleChange}
                      value={values.medNumber}
                      regText={true}
                      type="text"
                      name="medNumber"
                      autoComplete="medNumber"
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      validation={true}
                      error={touched.medNumber && errors.medNumber ? errors.medNumber : ''}
                    />
                  </div>
                </div>
                <PhotoBlock
                  name="firstPhoto"
                  blockType={DocumentType.Page2_3}
                  inputPhotoRef={firstPhotoRef}
                  inputPhotoBlockRef={firstPhotoBlockRef}
                  setPopup={setPopup}
                  setShowPopup={setShowPopup}
                />
                <ApproveBlock
                  reviewType={ReviewType.Page2_3}
                  updatedAtBlock={userRequisites?.updatedAtMedical}
                  commentsList={photoWithoutFaceRejectsList}
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-4 py-5">
              <span className="Subtitle2 text-smena_text-secondary">Страницы 28 и 29</span>
              <div className="flex flex-wrap">
                <div className="flex flex-col border-r border-smena_gray-5 pr-5 gap-y-3 data-block">
                  <button
                    className="Subtitle2 flex items-center gap-x-2"
                    type="button"
                    onClick={() => {
                      setShowAttestation(value => !value);
                    }}
                  >
                    История периодов аттестации
                    <span className={`inline-flex transform transition-transform ${showAttestation && 'rotate-180'}`}>
                      <SelectArrow className="text-smena_text" />
                    </span>
                  </button>
                  {showAttestation && (
                    <div className="flex gap-x-5">
                      <div className="flex flex-col gap-y-2 w-full">
                        <div className="Caption text-smena_text-secondary">Начало</div>
                        {userMedicalAttestation?.map(el => (
                          <span key={el.id} className="Table-small text-smena_text">
                            {dayjs(el.attestationStart).format(RU_DATE)}
                          </span>
                        ))}
                      </div>

                      <div className="flex flex-col gap-y-2 w-full">
                        <div className="Caption text-smena_text-secondary">Конец</div>
                        {userMedicalAttestation?.map(el => (
                          <span key={el.id} className="Table-small text-smena_text">
                            {dayjs(el.attestationEnd).format(RU_DATE)}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  {me && ![roles.ClientManager, roles.ClientDirector].includes(me.role) && (
                    <>
                      <div className="Caption text-smena_text-secondary">Текущий период</div>
                      <div className="flex gap-x-5">
                        <DatePickerInput
                          divClassName="crudUserInput inputHeight"
                          value={values.startAttestation}
                          onChange={startAttestation => {
                            setFieldValue('startAttestation', startAttestation);
                          }}
                          maxDate={dayjs().add(1, 'day')}
                          error={{
                            bool: Boolean(touched.startAttestation && errors.startAttestation),
                            text: errors.startAttestation,
                          }}
                        />
                        <DatePickerInput
                          divClassName="crudUserInput inputHeight"
                          value={values.endAttestation}
                          onChange={endAttestation => {
                            setFieldValue('endAttestation', endAttestation);
                          }}
                          error={{
                            bool: Boolean(touched.endAttestation && errors.endAttestation),
                            text: errors.endAttestation,
                          }}
                        />
                        {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                        {/*  <div className="flex flex-col">*/}
                        {/*    <DatePicker*/}
                        {/*      value={values.startAttestation}*/}
                        {/*      components={{*/}
                        {/*        OpenPickerIcon: DatePickerIcon,*/}
                        {/*      }}*/}
                        {/*      onChange={startAttestation => {*/}
                        {/*        setFieldValue('startAttestation', startAttestation);*/}
                        {/*      }}*/}
                        {/*      mask="__.__.____"*/}
                        {/*      maxDate={dayjs().add(1, 'day').toDate()}*/}
                        {/*      renderInput={params => (*/}
                        {/*        <TextField*/}
                        {/*          {...params}*/}
                        {/*          error={touched.startAttestation && Boolean(errors.startAttestation)}*/}
                        {/*          inputProps={{*/}
                        {/*            ...params.inputProps,*/}
                        {/*            placeholder: 'ДД.ММ.ГГГГ',*/}
                        {/*          }}*/}
                        {/*        />*/}
                        {/*      )}*/}
                        {/*    />*/}
                        {/*    {touched.startAttestation && errors.startAttestation && (*/}
                        {/*      <ErrorNotification text={errors.startAttestation} />*/}
                        {/*    )}*/}
                        {/*  </div>*/}
                        {/*</LocalizationProvider>*/}

                        {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                        {/*  <div className="flex flex-col">*/}
                        {/*    <DatePicker*/}
                        {/*      value={values.endAttestation}*/}
                        {/*      components={{*/}
                        {/*        OpenPickerIcon: DatePickerIcon,*/}
                        {/*      }}*/}
                        {/*      onChange={endAttestation => {*/}
                        {/*        setFieldValue('endAttestation', endAttestation);*/}
                        {/*      }}*/}
                        {/*      mask="__.__.____"*/}
                        {/*      renderInput={params => (*/}
                        {/*        <TextField*/}
                        {/*          {...params}*/}
                        {/*          error={touched.endAttestation && Boolean(errors.endAttestation)}*/}
                        {/*          inputProps={{*/}
                        {/*            ...params.inputProps,*/}
                        {/*            placeholder: 'ДД.ММ.ГГГГ',*/}
                        {/*          }}*/}
                        {/*        />*/}
                        {/*      )}*/}
                        {/*    />*/}
                        {/*    {touched.endAttestation && errors.endAttestation && (*/}
                        {/*      <ErrorNotification text={errors.endAttestation} />*/}
                        {/*    )}*/}
                        {/*  </div>*/}
                        {/*</LocalizationProvider>*/}
                      </div>
                    </>
                  )}
                </div>
                <PhotoBlock
                  name="secondPhoto"
                  blockType={DocumentType.Page28_29}
                  inputPhotoRef={secondPhotoRef}
                  inputPhotoBlockRef={secondPhotoBlockRef}
                  setPopup={setPopup}
                  setShowPopup={setShowPopup}
                />
                <ApproveBlock
                  reviewType={ReviewType.Page28_29}
                  updatedAtBlock={userRequisites?.updatedAtMedical}
                  commentsList={photoWithoutFaceRejectsList}
                />
              </div>
            </div>
            <div className="flex justify-between items-center save-block flex-wrap">
              <div className="flex gap-x-1">
                <span className="Caption text-smena_text-secondary">Дата верификации:</span>
                <span className="Caption text-smena_text-secondary">
                  {getVerificationDate([ReviewType.Page2_3, ReviewType.Page28_29], userReviews)}
                </span>
              </div>
              <div className="flex gap-x-2 items-center flex-wrap">
                {userRequisites?.updatedAtMedical && (
                  <div className="flex gap-x-1">
                    <span className="Caption text-smena_text-secondary">Посл. изменения</span>
                    <span className="Caption text-smena_text-secondary">
                      {dayjs(userRequisites?.updatedAtMedical).format(RU_DATE)}
                    </span>
                  </div>
                )}
                {me && ![roles.ClientManager, roles.ClientDirector].includes(me.role) && (
                  <button type="submit" className="btn-primary_small" disabled={isSubmitting}>
                    Сохранить
                  </button>
                )}
              </div>
            </div>
            {showPopup && popup ? (
              <PhotoPopup popup={popup.popup} place={popup.place} setShowPopup={setShowPopup} />
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};
