import { Link } from 'react-router-dom';
import { RegularFacilityFragment } from '../../../generated/graphql';
import Across from '../../../Assets/icons/Across';

import Check from '../../../Assets/icons/check';

interface TableInterface {
  facility: RegularFacilityFragment;
  columns: {
    Header: string;
    name: string;
    className?: string;
    style?: any;
  }[];
}
const FacilitiesClientRow = ({ facility, columns }: TableInterface) => {
  return (
    <div
      key={facility.id}
      className="grid table__row w-fit overflow-x-auto gap-x-[10px]"
      style={{
        gridTemplateColumns: columns
          .map(column => 'minmax(' + column.style.minWidth + 'px, ' + column.style.width + 'px)')
          .join(' '),
      }}
    >
      <div className={columns[0].className}>
        <Link to={`/facilities/${facility.id}`} className="Table-small text-primary underline">
          {facility.name}
        </Link>
      </div>
      <div className={columns[1].className}>{facility.city?.name}</div>
      <div className={columns[2].className}>{facility.address}</div>
      <div className={columns[3].className}>{facility.metro}</div>
      <div className={columns[4].className}>
        {facility.is_active ? (
          <span className="text-smena_green-dark">
            <Check />
          </span>
        ) : (
          <span>
            <Across color="text-smena_red" />
          </span>
        )}
      </div>
    </div>
  );
};

export default FacilitiesClientRow;
