export const Mir = () => {
  return (
    <svg
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15291 4.86773C6.33373 4.86648 6.87112 4.81036 7.09875 5.70108C7.25208 6.30108 7.49633 7.28391 7.8315 8.64956H7.968C8.32744 7.20979 8.57436 6.22697 8.70875 5.70108C8.93875 4.80108 9.51375 4.86775 9.74375 4.86775L11.5183 4.86775V11.2677H9.70963V7.49612H9.58835L8.58013 11.2677H7.21937L6.21115 7.49332H6.08987V11.2677H4.28125V4.86775L6.15291 4.86773ZM14.1154 4.86775V8.64218H14.2597L15.4861 5.53849C15.7242 4.92085 16.2316 4.86775 16.2316 4.86775H17.9818V11.2677H16.1354V7.49332H15.9911L14.7887 10.597C14.5507 11.2119 14.0192 11.2677 14.0192 11.2677H12.269V4.86775H14.1154ZM24.3637 7.90907C24.1062 8.75527 23.2974 9.36129 22.4019 9.36129H20.4656V11.2677H18.7098V7.90907H24.3637Z"
        fill="#0F754E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4861 4.86774H18.6172C18.7093 6.29259 19.7676 7.51236 20.8632 7.51236H24.4852C24.6943 6.32814 23.9747 4.86774 22.4861 4.86774Z"
        fill="url(#paint0_linear_4116_254846)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4116_254846"
          x1="24.5208"
          y1="6.53132"
          x2="18.6172"
          y2="6.53132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F5CD7" />
          <stop offset="1" stopColor="#02AEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
