import { useMemo } from 'react';
import { useStore } from 'src/store';
import uniqueArray from '../../../hooks/uniqueArray';

const useWorkpostFacilities = () => {
  const { currentUser } = useStore();
  return useMemo(() => uniqueArray({ array: currentUser?.workposts, field: 'facilityId' }), [currentUser?.workposts]);
};

export default useWorkpostFacilities;
