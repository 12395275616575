import {
  RegularCityFragment,
  RegularFacilitiesFragment,
  RegularGroupFragment,
  RegularPositionFragment,
} from '../../generated/graphql';

export const cityName = (
  cities?: RegularCityFragment[],
  cityId?: string | null
) => {
  return cities?.find(city => city.id === cityId)?.name;
};

export const facilityName = (
  facilities?: RegularFacilitiesFragment[],
  facilityId?: string | null
) => {
  const facility = facilities?.find(facility => facility.id === facilityId);
  if (facility) return facility.name;
  return '';
};

export const facilityGroupName = (
  facilities?: RegularFacilitiesFragment[],
  facilityId?: string | null
) => {
  const facility = facilities?.find(facility => facility.id === facilityId);
  if (facility) return facility.facilityGroup?.name;
  return '';
};

export const positionName = (
  positions?: RegularPositionFragment[],
  positionId?: string | null
) => {
  const position = positions?.find(position => position.id === positionId);
  if (position) return position.name;
  return '';
};

export const groupName = (
  groups?: RegularGroupFragment[],
  groupId?: string | null
) => {
  const group = groups?.find(group => group.id === groupId);
  if (group) return group.name;
  else return '';
};
