import React from 'react';
import {
  YMaps,
  Map,
  // SearchControl,
  YMapsApi,
  withYMaps,
  Placemark,
} from 'react-yandex-maps';

interface IFacilityMap {
  fullAddress: { city: string; address: string };
}

export const FacilityMap: React.FC<IFacilityMap> = React.memo(
  ({ fullAddress: { city, address } }) => {
    const PositionedMap = React.memo(({ ymaps }: YMapsApi) => {
      const [coords, setCoords] = React.useState([59.928036, 30.341975]);
      const [loadedCoords, setLoading] = React.useState(false);

      const onLoad = React.useCallback((ymaps: YMapsApi) => {
        ymaps.geocode(city + ' ' + address).then((res: any) => {
          setCoords(res.geoObjects.get(0).geometry.getCoordinates());
          setLoading(true);
        });
      }, []);

      React.useEffect(() => {
        onLoad(ymaps);
      }, []); //eslint-disable-line

      return loadedCoords ? (
        <div className="w-full h-full overflow-hidden">
          <div className="flex gap-x-2 bg-smena_white rounded-lg px-2 absolute top-0 right-0 z-10">
            {coords.map(coord => (
              <span key={coord}>{coord}</span>
            ))}
          </div>
          <Map
            state={{
              center: coords,
              zoom: 10,
            }}
            width="100%"
            height="100%"
          >
            <Placemark
              geometry={coords}
              options={{
                preset: 'islands#dotIcon',
              }}
            />
          </Map>
        </div>
      ) : null;
    });

    const ConnectedMap = React.useMemo(() => {
      return withYMaps(PositionedMap, true, ['geocode']);
    }, [PositionedMap]);
    return (
      <YMaps query={{ apikey: 'fcd7cc21-b247-4517-a738-762a39510111' }}>
        <ConnectedMap />
      </YMaps>
    );
  }
);
