import { Link } from 'react-router-dom';
import { RegularGroupFragment } from '../../generated/graphql';
import { useStore } from '../../store';

interface TableInterface {
  groups?: RegularGroupFragment[];
  columns?: {
    className?: string;
    style?: any;
  }[];
}

const GroupsTable = ({ groups }: TableInterface) => {
  const { facilities } = useStore();

  return (
    <div className="grid grid-cols-12 gap-6 pb-5">
      {groups?.map(group => {
        const facInGroup = facilities?.filter(
          fac => fac.facilityGroupId === group.id
        );
        const imagePath = group.logo?.path
          ? process.env.REACT_APP_PUBLIC_DOMAIN + '/' + group.logo?.path
          : false;
        return (
          <Link
            key={group.id}
            className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-full p-5 flex justify-between items-center 
            shadow-smena rounded-lg bg-smena_white border border-transparent hover:border-primary"
            to={`/groups/${group.id}`}
          >
            <div className="flex flex-col groupContent">
              <span className="H3 groupName">{group.name}</span>
              <div className="Caption text-smena_text-secondary">
                Объектов:
                <span className="text-smena_text ml-1">
                  {facInGroup?.length}
                </span>
              </div>
            </div>
            <div className="groupImage rounded-full bg-smena_bb-background_light object-cover overflow-hidden">
              {imagePath && (
                <img
                  src={imagePath}
                  alt=""
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default GroupsTable;
