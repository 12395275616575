import { useStore } from '../store';

import { roles } from '../utils/constVariables';
import ClientFacilitiesPage from './clientFacilities';

import OurFacilitiesPage from '../components/facility/OurFacilities';
import UsersPage from './users';

const FacilitiesPage = () => {
  const { me } = useStore();

  return (
    <>
      {me ? (
        [roles.Admin, roles.Supervisor].includes(me.role) ? (
          <OurFacilitiesPage />
        ) : [roles.ClientDirector, roles.ClientManager].includes(me.role) ? (
          <ClientFacilitiesPage />
        ) : (
          <></>
        )
      ) : (
        <UsersPage />
      )}
    </>
  );
};

export default FacilitiesPage;
