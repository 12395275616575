import { useCallback, useMemo } from 'react';
import { ICustomEvent } from 'src/Interfaces/IEvent';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';

const useEvents = ({
  supervisorTimekeepingShifts,
  timekeepingShifts,
}: {
  supervisorTimekeepingShifts?: ICustomEvent[];
  timekeepingShifts?: ICustomEvent[];
}) => {
  const { me, facilityId, workpostId } = useStore();

  const filterFacility = useCallback(
    (event: ICustomEvent) => event.resource.facility?.id === facilityId,
    [facilityId]
  );

  const filterFacilityAndPosition = useCallback(
    (event: ICustomEvent) =>
      filterFacility(event) && event.resource.positionId === workpostId,
    [filterFacility, workpostId]
  );

  return useMemo(() => {
    const events =
      me?.role === roles.Supervisor
        ? supervisorTimekeepingShifts
        : timekeepingShifts;

    if (facilityId) {
      if (workpostId) {
        return events?.filter(filterFacilityAndPosition);
      }
      return events?.filter(filterFacility);
    }
    return events;
  }, [
    me?.role,
    facilityId,
    workpostId,
    filterFacility,
    filterFacilityAndPosition,
    timekeepingShifts,
    supervisorTimekeepingShifts,
  ]);
};

export default useEvents;
