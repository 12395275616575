import { useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { RegularZayavkaScheduleFragment } from 'src/generated/graphql';

import { useStore } from 'src/store';
import { IUser } from 'src/Interfaces/IUser';
import { useRequestShift } from '../../hooks/useRequestShift';

interface IRequestProfessionDay {
  day: dayjs.Dayjs;
  requestSchedule?: RegularZayavkaScheduleFragment | null;
  setShowShiftSidebar: (value: boolean) => void;
}

const RequestProfessionDay: React.FC<IRequestProfessionDay> = ({ day, requestSchedule, setShowShiftSidebar }) => {
  const { setRequestShift, setUserIntersect } = useStore();

  const requestShift = useMemo(
    () =>
      requestSchedule?.zayavkaShift?.find(shift => dayjs(shift.dateFrom).format('DD.MM.YY') === day.format('DD.MM.YY')),
    [day, requestSchedule?.zayavkaShift]
  );

  const { className, content } = useRequestShift({ requestShift, day, requestScheduleId: requestSchedule?.id });

  const cellRef = useRef<HTMLButtonElement>(null);

  const userName = (user?: IUser | null) => {
    if (user) {
      if (user.lastname.length > 6) {
        return `${user.lastname.slice(0, 5)}... ${user.firstname.charAt(0)}. ${
          user.patronymic ? user.patronymic.charAt(0) + '.' : ''
        }`;
      }
      return `${user.lastname} ${user.firstname.charAt(0)}. ${user.patronymic ? user.patronymic.charAt(0) + '.' : ''}`;
    }
  };

  return (
    <button
      className={clsx('flex flex-col justify-center items-center rounded Tag', className)}
      ref={cellRef}
      type="button"
      onClick={() => {
        // setRequestDayAtRow({ day, positionId: requestSchedule.positionId, requestScheduleId: requestSchedule.id });
        if (requestShift) {
          setRequestShift(requestShift);
          setShowShiftSidebar(true);
          setUserIntersect(undefined);
        }
      }}
    >
      <span>{requestShift ? userName(requestShift?.shift?.user) || '-' : null}</span>
      <span>{content}</span>
    </button>
  );
};

export default RequestProfessionDay;
