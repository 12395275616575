import dayjs from 'dayjs';

export const shiftTitle = (marks: { time: string }[] | undefined | null) => {
  const timeString = (time: string) => dayjs(Number(time)).format('HH:mm');
  if (marks) {
    if (marks.length > 0) {
      if (marks[1]) {
        return (
          timeString(marks[0].time) +
          '–' +
          timeString(marks[marks.length - 1].time)
        );
      }
      return timeString(marks[0].time) + ' – —:—';
    }
    return '—:—';
  }
  return '—:—';
};
