import React from 'react';

import { useFacilityGroupsQuery } from '../generated/graphql';

import MainLayout from '../components/Layout/main';
import SkeletonLayout from '../components/Table/Skeleton';
import GroupsFilters from '../components/Table/Filters/GroupsFilters';
import GroupsTable from '../components/Table/GroupsTable';

import { sortObj } from '../utils/sortObject';

const GroupsPage: React.FC = () => {
  // const [search, setSearch] = useState('');
  const { data, loading } = useFacilityGroupsQuery();

  const groups = data?.facilityGroups
    ? sortObj(data?.facilityGroups)
    : data?.facilityGroups;

  return (
    <MainLayout
      title="Группы"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[{ path: '/groups', name: 'Группы' }]}
    >
      <GroupsFilters
      // setSearch={setSearch}
      />

      {loading ? <SkeletonLayout /> : <GroupsTable groups={groups} />}
    </MainLayout>
  );
};

export default GroupsPage;
