import clsx from 'clsx';
import { RU_DATE } from '../../../utils/constVariables';
import dayjs from 'dayjs';
import React from 'react';

const RequestDay = ({ day }: { day: dayjs.Dayjs }) => {
  return (
    <div className="flex justify-center items-center">
      <span
        className={clsx(
          'px-2 Tag',
          day.format(RU_DATE) === dayjs().format(RU_DATE) && 'bg-primary rounded-lg text-smena_white'
        )}
      >
        {dayjs(day).format('D, dd')}
      </span>
    </div>
  );
};

export default RequestDay;
