import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Pagination } from '@mui/material';

import { useZayavkiPaginatedLazyQuery } from 'src/generated/graphql';
import MainLayout from 'src/components/Layout/main';
import { requestsColumns } from 'src/components/Table/columns';
import RequestsFilters from 'src/components/Table/Filters/RequestsFilter';
import SkeletonLayout from 'src/components/Table/Skeleton';
import RequestTableRow from 'src/components/Table/Row/RequestTableRow';
import TableHeader from 'src/components/Table/header';
import { errorToast } from 'src/utils/notify';
import { ScrollSyncPane, ScrollSync } from 'react-scroll-sync';
import { maxTableWidth } from 'src/utils/maxTableWidth';

const RequestsPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [value, setValue] = useState<moment.Moment[] | null>(null);
  const [facilityId, setFacilityId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [params] = useSearchParams();

  const [loadData, { data, loading }] = useZayavkiPaginatedLazyQuery();

  const requestsPaginated = data?.zayavkiPaginated;
  const requests = requestsPaginated?.zayavky;

  let statusParams = params.get('status');
  let dateFrom = params.get('dateFrom');
  let dateTo = params.get('dateTo');

  const location = useLocation();

  const mutationInput = {
    page: 1,
    searchQuery: Number(search) ? search : '',
    status,
    sortBy,
    sortWay,
    dateFrom,
    dateTo,
    facilityId,
    groupId,
  };

  const returnData = () => {
    return loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
          dateFrom: value && String(moment(value[0]).valueOf()),
          dateTo: value && String(moment(value[1]).valueOf()),
        },
      },
    });
  };

  const handleChangePage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (statusParams) {
      setStatus(statusParams);
    } else {
      setStatus('');
    }
    if (dateFrom && dateTo) {
      setValue([moment(dateFrom), moment(dateTo)]);
    } else {
      setValue(null);
    }

    if (Number(search) || search === '') {
      loadData({
        variables: {
          input: {
            ...mutationInput,
            dateFrom: dateFrom ? String(moment(dateFrom).valueOf()) : null,
            dateTo: dateTo ? String(moment(dateTo).valueOf()) : null,
            page,
          },
        },
      });
    } else {
      errorToast('Заявки с таким номером не найдено');
    }
    // eslint-disable-next-line
  }, [page, sortBy, sortWay, location]);

  return (
    <MainLayout title="Заявки" bg="bg-smena_bb-background_base" breadCrumbs={[{ path: '/requests', name: 'Заявки' }]}>
      <ScrollSync>
        <div
          style={{
            maxWidth: maxTableWidth(requestsColumns) + 'px',
          }}
        >
          <RequestsFilters
            returnData={returnData}
            value={value}
            status={status}
            facilityId={facilityId}
            setFacilityId={setFacilityId}
            groupId={groupId}
            setGroupId={setGroupId}
            setStatus={setStatus}
            setValue={setValue}
            setSearch={setSearch}
            setPage={setPage}
          />
          <div className="flex flex-col shadow-smena rounded-lg">
            <TableHeader
              dataLength={requests?.length}
              columns={requestsColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />
            {loading || !requests ? (
              <SkeletonLayout columns={requestsColumns} />
            ) : requests.length > 0 ? (
              <ScrollSyncPane>
                <div className="main-table">
                  {requests?.map(request => (
                    <RequestTableRow key={request.id} request={request} columns={requestsColumns} />
                  ))}
                </div>
              </ScrollSyncPane>
            ) : (
              <div className="w-full flex justify-center my-5">
                <h3 className="text-smena_text Body1">
                  По вашему запросу <span className="font-bold">{search}</span> ничего не нашлось
                </h3>
              </div>
            )}
          </div>
          <div className="py-5">
            {requestsPaginated?.pages !== 0 && (
              <Pagination
                shape="rounded"
                count={requestsPaginated ? requestsPaginated.pages : 10}
                page={page}
                onChange={handleChangePage}
              />
            )}
          </div>
        </div>
      </ScrollSync>
    </MainLayout>
  );
};

export default RequestsPage;
