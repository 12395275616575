import { Link } from 'react-router-dom';
import { useRef } from 'react';
import clsx from 'clsx';

import { useStore } from '../../../store';

import HideArrowMenu from '../../../Assets/icons/menu/hideArrowMenu';
import Logo from '../../ui/Logo';
// import UserPanel from '../../UserPanel';
import MenuElements from './Elements';

const Menu = () => {
  const {
    menu,
    setMenu,
    hover,
    setHover,
    setSubMenuActive,
    subMenuActive,
    setTooltip,
  } = useStore();

  const menuHandler = () => {
    setMenu(!menu);
    setSubMenuActive(false);
    if (menu) {
      setTooltip(false);
      setHover(false);
    }
  };

  const timer = useRef<any>(null);
  const menuHover = menu || hover;

  return (
    <div
      className={clsx(
        'side-menu md:block hidden',
        menuHover ? 'show-menu' : 'hidden-menu'
      )}
      onMouseEnter={() => {
        timer.current = setTimeout(() => {
          setHover(true);
        }, 400);
      }}
      onMouseLeave={() => {
        clearTimeout(timer.current);
        setHover(false);
        if (subMenuActive) {
          setSubMenuActive(!subMenuActive);
        }
      }}
    >
      <div className="side-menu__content">
        <div
          className={clsx(
            'flex flex-col gap-y-11',
            !menuHover ? 'items-center' : ''
          )}
        >
          <div
            className={clsx(
              'flex items-center xs:h-7 h-12 w-full xs:w-auto  bg-smena_white z-20 xs:relative sticky top-0',
              menuHover ? 'justify-between' : 'justify-center'
            )}
          >
            {menuHover ? (
              <Link to="/" className="w-full h-max">
                <Logo />
              </Link>
            ) : null}
            <span
              className={`cursor-pointer transition-transform transform ${
                menuHover ? 'rotate-180' : ''
              }`}
              onClick={menuHandler}
            >
              <HideArrowMenu />
            </span>
          </div>

          <MenuElements />
        </div>
        {/* {menuHover && <UserPanel show={menuHover} />} */}
      </div>
    </div>
  );
};

export default Menu;
