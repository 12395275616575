import clsx from 'clsx';
import { useState } from 'react';
import dayjs from 'dayjs';

import SelectArrow from 'src/Assets/icons/SelectArrow';
import { RegularUserIntersectFragment } from 'src/generated/graphql';
import { useStore } from 'src/store';
import { positionName } from 'src/utils/filters/filtersName';
import { userLastnameAndInitials } from 'src/utils/userLastnameAndInitials';
import { useParams } from 'react-router-dom';
import { RU_DATE } from '../../../../utils/constVariables';

interface IUserAtSidebar {
  user: RegularUserIntersectFragment;
}

const UserAtSidebar: React.FC<IUserAtSidebar> = ({ user }) => {
  const { positions, userIntersect, setUserIntersect } = useStore();

  const router = useParams();

  let facilityIdURL = '';

  if (typeof router.facilityId === 'string') {
    facilityIdURL = router.facilityId;
  }

  const [showConflicts, setShowConflicts] = useState(false);

  const userIntersectOnThisObj = user.intersect?.filter(intersect => intersect.facilityId === facilityIdURL);
  const userIntersectNotOnThisObj = user.intersect?.filter(intersect => intersect.facilityId !== facilityIdURL);

  return (
    <>
      <div
        className={clsx(
          user.id === userIntersect?.id && '!bg-smena_bb-background',
          'graphicSearchUserTable p-4 cursor-pointer odd:bg-smena_white even:bg-smena_gray-2'
        )}
        onClick={() => {
          if (!user.intersect?.length) {
            setUserIntersect(user);
          } else {
            setShowConflicts(value => !value);
          }
        }}
      >
        <span className="Table-small">{userLastnameAndInitials(user)}</span>
        {user.intersect && user.intersect.length > 0 ? (
          <div className="Table-small text-right flex">
            <span>Занят (смен: {user.intersect?.length})</span>
            <span className={clsx('inline-flex transform transition-transform', showConflicts && 'rotate-180')}>
              <SelectArrow className="text-smena_text" />
            </span>
          </div>
        ) : (
          <span className="Table-small">Свободен</span>
        )}
      </div>
      {showConflicts && (
        <div className="px-4 pt-2 pb-1 flex flex-col gap-y-1">
          {Boolean(userIntersectOnThisObj?.length) ? (
            <>
              <span className="Table-small text-smena_text">На этом объекте</span>
              {userIntersectOnThisObj?.map(el => (
                <div className="flex gap-x-1 Body2 text-smena_text-secondary">
                  <span>{positionName(positions, el.positionId)}</span>
                  <span>·</span>
                  <span>{dayjs(Number(el.dateFrom)).format(RU_DATE)}</span>
                  <span>·</span>
                  <span>
                    {dayjs(Number(el.dateFrom)).format('HH:mm')} – {dayjs(Number(el.dateEnd)).format('HH:mm')}
                  </span>
                </div>
              ))}
            </>
          ) : null}
          {Boolean(userIntersectNotOnThisObj?.length) ? (
            <>
              <span className="Table-small text-smena_text">На другом объекте</span>
              {userIntersectNotOnThisObj?.map(el => (
                <div className="flex gap-1 Body2 text-smena_text-secondary">
                  <span>{positionName(positions, el.positionId)}</span>
                  <span>·</span>
                  <span>{dayjs(Number(el.dateFrom)).format(RU_DATE)}</span>
                  <span>·</span>
                  <span>
                    {dayjs(Number(el.dateFrom)).format('HH:mm')} – {dayjs(Number(el.dateEnd)).format('HH:mm')}
                  </span>
                </div>
              ))}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export default UserAtSidebar;
