import ShiftsSidebar from './Sidebar';
import ShiftsCalendar from './main';
import CalendarHeader from '../CalendarHeader';

export const Calendar = () => {
  return (
    <>
      <CalendarHeader />
      <div className="shifts">
        <ShiftsCalendar />
        <ShiftsSidebar />
      </div>
    </>
  );
};
