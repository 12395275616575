import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  DocumentStatus,
  RejectReason,
  useSetReviewStatusMutation,
} from '../../../../generated/graphql';

import { useStore } from '../../../../store';
import { errorHandler } from '../../../../utils/errorHandler';
import { errorToast, successToast } from '../../../../utils/notify';
import SimpleSelect from '../../../ui/Select';

import { IApproveBlock } from '../../../../Interfaces/IApproveBlock';
import { roles } from '../../../../utils/constVariables';
import useAproveBlock from './useAproveBlock';

export const ApproveBlock: React.FC<IApproveBlock> = ({
  reviewType,
  updatedAtBlock,
  commentsList,
}) => {
  const [approve, setApprove] = useState<DocumentStatus | null>(null);
  const [rejectReason, setRejectReason] = useState<RejectReason | undefined>(
    undefined
  );
  const router = useParams();

  const { userReviews, setUserReview, me } = useStore();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }
  const showComment = useCallback((approve: DocumentStatus | null) => {
    if (approve !== null) {
      if (approve === DocumentStatus.Rejected) {
        return true;
      }
      return false;
    }
    return false;
  }, []);

  const canAprove = useAproveBlock(reviewType);

  const [setReviewStatus] = useSetReviewStatusMutation();
  const radioClickHandler = useCallback(
    (status: DocumentStatus, rejectReason: RejectReason | undefined) => {
      setReviewStatus({
        variables: {
          input: {
            type: reviewType,
            userId,
            rejectReason:
              status === DocumentStatus.Rejected
                ? rejectReason
                : RejectReason.None,
            status,
          },
        },
      })
        .then(res => {
          if (res.data?.setReviewStatus) {
            setUserReview(res.data.setReviewStatus);
            if (status === DocumentStatus.Accepted) {
              successToast('Статус изменен');
            }
          }
        })
        .catch(err => errorToast(errorHandler(err)));
    },
    [reviewType, userId, setReviewStatus, setUserReview]
  );

  useEffect(() => {
    const review = userReviews?.find(review => review.type === reviewType);
    if (!review) {
      setApprove(null);
      setRejectReason(undefined);
    } else {
      if (dayjs(updatedAtBlock).valueOf() > dayjs(review.updatedAt).valueOf()) {
        setApprove(null);
      } else {
        setApprove(review.status);
      }
      setRejectReason(review.rejectReason);
    }
  }, [userReviews, updatedAtBlock]); //eslint-disable-line

  if (me && [roles.ClientManager, roles.ClientDirector].includes(me.role)) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-1 pl-5 border-l border-smena_gray-5 approve-block">
      <span className="Subtitle2">Проверка данных</span>
      <label
        className={clsx(
          'approve-radio',
          approve === DocumentStatus.Accepted &&
            'bg-smena_green-extra_light border-smena_green'
        )}
      >
        <input
          type="radio"
          className="approve_radio approve_approve"
          name={reviewType}
          checked={approve === DocumentStatus.Accepted}
          disabled={canAprove}
          onChange={() => {
            setApprove(DocumentStatus.Accepted);
            radioClickHandler(DocumentStatus.Accepted, rejectReason);
          }}
        />
        Одобрить
      </label>
      <label
        className={clsx(
          'approve-radio',
          showComment(approve) && 'bg-smena_red-extra_light border-smena_red'
        )}
      >
        <input
          type="radio"
          className="approve_radio approve_reject"
          name={reviewType}
          disabled={canAprove}
          checked={approve === DocumentStatus.Rejected}
          onChange={() => {
            setApprove(DocumentStatus.Rejected);
            radioClickHandler(DocumentStatus.Rejected, rejectReason);
          }}
        />
        Отклонить
      </label>
      {showComment(approve) && (
        <div>
          <SimpleSelect
            divClassName=""
            label="Причина"
            onChange={({ target: { value } }) => {
              setRejectReason(value as RejectReason);
              radioClickHandler(DocumentStatus.Rejected, value as RejectReason);
              successToast('Статус изменен');
            }}
            disabled={canAprove}
            value={rejectReason}
            name="rejectReason"
            autoComplete="rejectReason"
            disabledOption="Выберите причину "
            options={commentsList}
            validation={true}
          />
        </div>
      )}
    </div>
  );
};
