import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import SimpleInput from '../../ui/Input';
import { CardDateInput, CardNumberInput } from '../../ui/InputMasks';
import { ApproveBlock } from './ApproveBlock';
import { ReviewType, useChangePersonalInfoPaymentMutation } from '../../../generated/graphql';
import { useStore } from '../../../store';
import { errorToast, successToast } from '../../../utils/notify';
import { errorHandler } from '../../../utils/errorHandler';
import { rejectsList } from '../../../utils/lists/commentsList';
import { getVerificationDate } from '../../../utils/get/getVerificationDate';
import { userDocumentsQueryConfigFn } from '../../../utils/graphqlConfigs/userDocumentsQueryConfig';
import { roles, RU_DATE } from '../../../utils/constVariables';
import { ErrorNotification } from 'src/components/ui/ErrorNotification';
import clsx from 'clsx';
import { validateCardNumber, validateKs, validateRs } from './validateDocuments';

export const PayInfoPage = () => {
  const router = useParams();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const userQueryDocumentsConfig = userDocumentsQueryConfigFn(userId);
  const { userRequisites, userReviews, me } = useStore();

  const [changePayment] = useChangePersonalInfoPaymentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryDocumentsConfig],
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        cardNumber: userRequisites?.cardNumber || '',
        cardDate: dayjs(userRequisites?.cardExpiryDate).format('MM/YY') || dayjs().format('MM/YY'),
        cardHolder: userRequisites?.cardName || '',
        accountNumber: userRequisites?.accountNumber || '',
        bank: userRequisites?.bankName || '',
        bik: userRequisites?.bik || '',
        correspondentNumber: userRequisites?.correspondentAccount || '',
      }}
      validate={values => {
        let errors: any = {};
        if (!values.cardNumber) {
          errors.cardNumber = 'Обязательное поле';
        }
        if (values.cardNumber && !/^\d\d\d\d \d\d\d\d \d\d\d\d \d\d\d\d$/.test(values.cardNumber)) {
          errors.cardNumber = 'В поле Номер карты должно быть 16 символов';
        }
        if (!validateCardNumber(values.cardNumber)) {
          errors.cardNumber = 'Введен неверный номер карты';
        }
        if (values.cardDate) {
          if (!/^\d\d[/]\d\d$/.test(values.cardDate)) {
            errors.cardDate = 'Неверный формат ввода';
          }
          const currentDate = dayjs(dayjs('01/' + values.cardDate).format('DD/MM/YY'));
          if (currentDate.endOf('M').diff(dayjs()) < 0) {
            errors.cardDate = 'Введен неверный срок действия карты';
          }
        }
        if (!values.cardHolder) {
          errors.cardHolder = 'Обязательное поле';
        }
        if (values.accountNumber.length < 20) {
          errors.accountNumber = 'В поле Номер счета должно быть 20 символов';
        }
        if (!values.bank) {
          errors.bank = 'Обязательное поле';
        }
        if (values.bik.length < 9) {
          errors.bik = 'В поле БИК должно быть 9 символов';
        }
        if (values.correspondentNumber.length < 20) {
          errors.correspondentNumber = 'В поле корр. счет должно быть 20 символов';
        }
        if (validateRs(values.accountNumber, values.bik)) {
          errors.accountNumber = 'Введен неверный номер счета';
        }
        if (validateKs(values.correspondentNumber, values.bik)) {
          errors.correspondentNumber = 'Введен неверный корр. счет';
        }
        return errors;
      }}
      onSubmit={values => {
        changePayment({
          variables: {
            input: {
              userId,
              accountNumber: values.accountNumber,
              bankName: values.bank,
              bik: values.bik,
              cardExpiryDate: dayjs(dayjs('01/' + values.cardDate).format('DD/MM/YY')).toDate(),
              cardName: values.cardHolder,
              cardNumber: values.cardNumber,
              correspondentAccount: values.correspondentNumber,
            },
          },
        })
          .then(() => successToast('Данные карты изменены'))
          .catch(err => errorToast(errorHandler(err)));
      }}
    >
      {({ values, touched, errors, handleChange, isSubmitting }) => {
        return (
          <Form>
            <div className="flex pb-5 flex-wrap">
              <div className="flex flex-col gap-y-4 pr-5 border-r border-smena_gray-5 card-block">
                <span className="Subtitle2">Данные карты</span>
                <div className="flex flex-col gap-y-5 shadow-smena rounded-lg p-5">
                  <div className="flex flex-col relative">
                    <CardNumberInput
                      name="cardNumber"
                      onChange={handleChange}
                      value={values.cardNumber}
                      placeholder="0000 0000 0000 0000"
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      error={touched.cardNumber && errors.cardNumber ? errors.cardNumber : ''}
                    />
                  </div>
                  <div className="flex flex-col">
                    <CardDateInput
                      name="cardDate"
                      onChange={handleChange}
                      value={values.cardDate}
                      placeholder="мм/гг"
                      showTextRole={[roles.ClientManager, roles.ClientDirector]}
                      error={touched.cardDate && errors.cardDate ? errors.cardDate : ''}
                    />
                  </div>
                  {me && ![roles.ClientManager, roles.ClientDirector].includes(me.role) ? (
                    <div>
                      <input
                        type="text"
                        className={clsx(
                          errors.cardHolder ? 'border border-smena_red' : 'border-0',
                          'cardInputData w-full'
                        )}
                        value={values.cardHolder}
                        maxLength={30}
                        onChange={e => {
                          const value = e.target.value;
                          if (/[A-Za-z -]+$/.test(value) || value === '') {
                            handleChange(e);
                          }
                        }}
                        name="cardHolder"
                        placeholder="Ivanov Ivan"
                      />
                      {errors.cardHolder && <ErrorNotification text={errors.cardHolder} />}
                    </div>
                  ) : (
                    <span className="Body2 text-smena_text-secondary">{values.cardHolder}</span>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-y-4 px-5 bank-block">
                <div className="flex gap-x-5 flex-wrap">
                  <SimpleInput
                    divClassName="w-[230px]"
                    label="Номер счета"
                    placeholder="0000000000000000000"
                    maxLength={20}
                    onChange={handleChange}
                    value={values.accountNumber}
                    regText
                    type="text"
                    name="accountNumber"
                    autoComplete="accountNumber"
                    showTextRole={[roles.ClientManager, roles.ClientDirector]}
                    validation
                    error={touched.accountNumber && errors.accountNumber ? errors.accountNumber : ''}
                  />
                  <SimpleInput
                    divClassName="w-[230px]"
                    label="Банк"
                    placeholder="Название банка"
                    onChange={handleChange}
                    value={values.bank}
                    type="text"
                    name="bank"
                    autoComplete="bank"
                    validation
                    showTextRole={[roles.ClientManager, roles.ClientDirector]}
                    error={touched.bank && errors.bank ? errors.bank : ''}
                  />
                </div>
                <div className="flex gap-x-5 flex-wrap">
                  <SimpleInput
                    divClassName="w-[140px]"
                    label="БИК"
                    placeholder="0000000000"
                    maxLength={9}
                    onChange={handleChange}
                    value={values.bik}
                    regText={true}
                    type="text"
                    name="bik"
                    autoComplete="bik"
                    validation
                    showTextRole={[roles.ClientManager, roles.ClientDirector]}
                    error={touched.bik && errors.bik ? errors.bik : ''}
                  />
                  <SimpleInput
                    divClassName="w-[230px]"
                    label="Корр. счет"
                    placeholder="0000000000000000000"
                    maxLength={20}
                    onChange={handleChange}
                    value={values.correspondentNumber}
                    regText
                    type="text"
                    name="correspondentNumber"
                    autoComplete="correspondentNumber"
                    validation={true}
                    showTextRole={[roles.ClientManager, roles.ClientDirector]}
                    error={touched.correspondentNumber && errors.correspondentNumber ? errors.correspondentNumber : ''}
                  />
                </div>
              </div>
              <ApproveBlock
                reviewType={ReviewType.PaymentInfo}
                updatedAtBlock={userRequisites?.updatedAtPayment}
                commentsList={rejectsList}
              />
            </div>

            <div className="flex justify-between items-center save-block flex-wrap">
              <div className="flex gap-x-1">
                <span className="Caption text-smena_text-secondary">Дата верификации:</span>
                <span className="Caption text-smena_text-secondary">
                  {getVerificationDate([ReviewType.PaymentInfo], userReviews)}
                </span>
              </div>
              <div className="flex gap-x-2 items-center flex-wrap">
                {userRequisites?.updatedAtPayment && (
                  <div className="flex gap-x-1">
                    <span className="Caption text-smena_text-secondary">Посл. изменения</span>
                    <span className="Caption text-smena_text-secondary">
                      {dayjs(userRequisites?.updatedAtPayment).format(RU_DATE)}
                    </span>
                  </div>
                )}
                {me && ![roles.ClientManager, roles.ClientDirector].includes(me.role) && (
                  <button type="submit" className="btn-primary_small" disabled={isSubmitting}>
                    Сохранить
                  </button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
