type UserAutoRating = {
  diligence: number;
  discipline: number;
  documents: number;
  penalization: number;
  performance: number;
};

export const autoRateSummary = (
  userAutoRating: UserAutoRating | null | undefined
) => {
  const undefinedCheck = (value: number | null | undefined) => {
    if (value) return value;
    return 0;
  };
  const sumOfRates =
    (undefinedCheck(userAutoRating?.diligence) * 33 +
      1 +
      undefinedCheck(userAutoRating?.discipline) * 33 +
      1 +
      undefinedCheck(userAutoRating?.penalization) * 33 +
      1 +
      undefinedCheck(userAutoRating?.performance) * 33 +
      1) /
    4;

  const documentRate = undefinedCheck(userAutoRating?.documents) ? 100 : 0;

  return (sumOfRates + documentRate) / 2;
};

export const autoRateColor = (rate?: number) => {
  if (rate) {
    if (rate < 3) {
      return 'text-smena_red';
    }
    if (rate < 4) {
      return 'text-smena_yellow-dark';
    }
    if (rate <= 5) {
      return 'text-smena_green-dark';
    }
  }
  return '';
};
