import { statusBg } from './shiftsBgStatus';

export const shiftsClassName = (resource: {
  stage: string;
  marks?: any[] | null;
  isProbation: boolean;
}) => {
  const { marks, stage, isProbation } = resource;
  if (isProbation) {
    return { className: 'intership_shift' };
  }
  if (marks && marks.length > 0) {
    if (marks.length === 1) {
      if (stage === 'WORKING') {
        return { className: 'working_shifts' };
      } else {
        return { className: 'without_marks' };
      }
    } else {
      return { className: statusBg(stage).className };
    }
  } else {
    return { className: 'without_marks' };
  }
};
