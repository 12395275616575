import dayjs from 'dayjs';

import { DocumentStatus, RegularDocumentReviewsFragment, ReviewType } from '../../generated/graphql';
import { RU_DATE } from '../constVariables';

export const getVerificationDate = (reviewType: ReviewType[], userReviews?: RegularDocumentReviewsFragment[]) => {
  const dates = reviewType.map(el => {
    const updatedDate = userReviews?.find(
      review => review.type === el && review.status === DocumentStatus.Accepted
    )?.updatedAt;
    if (updatedDate) {
      return dayjs(updatedDate).valueOf();
    }
    return 0;
  });
  if (dates.find(date => date === 0) === 0) {
    return '–';
  }
  const lastUpdate = Math.max(...dates);

  return dayjs(lastUpdate).format(RU_DATE);
};
