import dayjs from 'dayjs';
import { Spoiler } from 'src/components/ui/Spoiler';
import { RegularUserRatingFragment } from 'src/generated/graphql';
import { useStore } from 'src/store';
import {
  facilityGroupName,
  facilityName,
  positionName,
} from 'src/utils/filters/filtersName';
import { RU_DATE } from 'src/utils/constVariables';
import { getRoleRu } from 'src/utils/get/getRoleRu';
import clsx from 'clsx';

interface IHistoryBlock {
  user?: RegularUserRatingFragment;
}
const HistoryBlock: React.FC<IHistoryBlock> = ({ user }) => {
  const { facilities, positions } = useStore();

  const userExperienceArray = user?.userExperience
    ? [...user.userExperience].sort((a, b) => {
        if (a && b) {
          if (
            facilityName(facilities, a.facilityId) <
            facilityName(facilities, b.facilityId)
          ) {
            return -1;
          }
          if (
            facilityName(facilities, a.facilityId) >
            facilityName(facilities, b.facilityId)
          ) {
            return 1;
          }
        }
        return 0;
      })
    : null;

  return (
    <Spoiler
      className="userPageBlock blockTemplate"
      header={
        <div className="flex justify-between">
          <h2 className="blockTitleTemplate">Опыт</h2>
        </div>
      }
    >
      <>
        {userExperienceArray?.map(expirience => {
          const logoPath = expirience?.facility?.facilityGroup?.logo?.path;
          const imagePath = logoPath
            ? process.env.REACT_APP_PUBLIC_DOMAIN + '/' + logoPath
            : false;
          const firstElOfArrayFn = () => {
            const sameFacilityElements = user?.userExperience?.filter(
              expirienceEl => expirienceEl.facilityId === expirience.facilityId
            );
            const sameFacilityElementsIndex = sameFacilityElements?.findIndex(
              expirienceEl =>
                expirienceEl.facilityId === expirience.facilityId &&
                expirienceEl.positionId === expirience.positionId
            );

            return {
              sameFacilityElements,
              sameFacilityElementsIndex,
            };
          };

          const { sameFacilityElements, sameFacilityElementsIndex } =
            firstElOfArrayFn();

          const addPaddingAndBorder =
            sameFacilityElements &&
            sameFacilityElementsIndex &&
            sameFacilityElements.length > 1 &&
            sameFacilityElements[sameFacilityElements.length - 1] ===
              sameFacilityElements[sameFacilityElementsIndex];

          return (
            <div
              className={clsx(
                'flex flex-col gap-y-3',
                addPaddingAndBorder ? 'pb-6 border-b border-smena_gray-5' : ''
              )}
              key={expirience.id}
            >
              {sameFacilityElementsIndex === 0 ? (
                <div className="flex items-center gap-x-1 Body1 text-smena_text-secondary">
                  {imagePath && (
                    <img
                      className="h-4 w-4 rounded-full"
                      src={imagePath}
                      alt="logo"
                    />
                  )}
                  <span>
                    {facilityGroupName(facilities, expirience.facilityId)}
                  </span>
                  <span>·</span>
                  <span>{facilityName(facilities, expirience.facilityId)}</span>
                </div>
              ) : null}
              <div className="flex flex-col gap-y-3">
                <div>
                  <div className="Caption1 text-smena_text-secondary">
                    {dayjs(expirience.assignedAt).format(RU_DATE)} –{' '}
                    {expirience.unassignAt
                      ? dayjs(expirience.unassignAt).format(RU_DATE)
                      : 'настоящее время'}
                  </div>
                  <div className="Subtitle1 text-smena_text flex gap-x-1">
                    {getRoleRu(user?.role)}
                    <span className="text-smena_text-secondary">
                      ({positionName(positions, expirience.positionId)})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    </Spoiler>
  );
};
export default HistoryBlock;
