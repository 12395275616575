import { Moment } from 'moment';

export const timeDifference = (start: Moment | null, end: Moment | null) => {
  if (start && end) {
    if (end.get('hours') < start.get('hours')) {
      return 24 * 60 - (start.get('hours') * 60 + start.get('minutes')) + end.get('hours') * 60 + end.get('minutes');
    }
    return end.get('hours') * 60 + end.get('minutes') - (start.get('hours') * 60 + start.get('minutes'));
  }
  return 0;
};
