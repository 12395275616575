import { Link, useParams } from 'react-router-dom';
import Across from '../../../Assets/icons/Across';
import {
  useRemoveDirectorFromFacilityGroupMutation,
  useRemoveForemanFromFacilityMutation,
  useRemoveManagerFromFacilityMutation,
  useRemoveSupervisorFromFacilityMutation,
} from '../../../generated/graphql';
import { IClient } from '../../../Interfaces/IClient';
import { roles } from '../../../utils/constVariables';
import { errorHandler } from '../../../utils/errorHandler';
import { facilityConfigFn } from '../../../utils/graphqlConfigs/facilityConfig';
import { groupQueryConfigFn } from '../../../utils/graphqlConfigs/groupConfig';
import { errorToast, infoToast, successToast } from '../../../utils/notify';

interface IResponsibleUser {
  user: IClient;
  canRemove?: boolean;
}

const ResponsibleUser: React.FC<IResponsibleUser> = ({ user, canRemove = true }) => {
  const router = useParams();
  let facilityId = '';
  let groupId = '';

  const { lastname, firstname, id, role } = user;

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }

  if (typeof router.groupId === 'string') {
    groupId = router.groupId;
  }
  const facilityConfig = facilityConfigFn(facilityId);
  const groupConfig = groupQueryConfigFn(groupId);

  const [removeSupervisor] = useRemoveSupervisorFromFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilityConfig],
  });

  const [removeForeman] = useRemoveForemanFromFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilityConfig],
  });

  const [removeManagerFromFacility] = useRemoveManagerFromFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilityConfig],
  });

  const [removeDirectorFromFacilityGroup] = useRemoveDirectorFromFacilityGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [groupConfig],
  });

  const removeSupervisorHandler = async (userId: string) => {
    await removeSupervisor({
      variables: {
        facilityId,
        userId,
      },
    })
      .then(() => successToast('Супервайзер удален'))
      .catch(e => errorToast(errorHandler(e)));
  };

  const removeForemanHandler = async (id: string) => {
    await removeForeman({
      variables: {
        facilityId,
        userId: id,
      },
    })
      .then(response => {
        if (response.data?.removeForemanFromFacility) {
          infoToast('Бригадир удален');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  const removeManagerFromFacilityHandler = async (userId: string) => {
    await removeManagerFromFacility({
      variables: {
        facilityId,
        userId,
      },
    })
      .then(() => successToast('Менеджер успешно удален'))
      .catch(err => errorToast(errorHandler(err)));
  };

  const removeDirectorFromFacilityGroupHandler = async (userId: string) => {
    await removeDirectorFromFacilityGroup({
      variables: {
        groupId,
        userId,
      },
    })
      .then(() => successToast('Директор успешно удален'))
      .catch(err => errorToast(errorHandler(err)));
  };

  return (
    <div
      className="grid grid-cols-2 mb-2 items-center h-6"
      style={{
        gridTemplateColumns: '1fr 60px',
      }}
    >
      <Link to={`/users/${id}`} className="col-auto Body2 text-primary">
        {lastname + ' ' + firstname}
      </Link>
      {canRemove && (
        <button
          className="auto-cols-max flex justify-center"
          type="button"
          onClick={() => {
            if (role === roles.Supervisor) {
              removeSupervisorHandler(id);
            } else if (role === roles.Foreman) {
              removeForemanHandler(id);
            } else if (role === roles.ClientManager) {
              removeManagerFromFacilityHandler(id);
            } else if (role === roles.ClientDirector) {
              removeDirectorFromFacilityGroupHandler(id);
            }
          }}
        >
          <Across color="text-smena_red" />
        </button>
      )}
    </div>
  );
};
export default ResponsibleUser;
