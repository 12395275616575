import { useMemo } from 'react';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';
import useWorkpostFacilities from './useWorkpostFacilities';
import { useMeRole } from '../../../hooks/useRole';

const useSuperFacilities = () => {
  const { me } = useStore();
  const isMeSupervisor = useMeRole(roles.Supervisor);
  const workpostFacilities = useWorkpostFacilities();
  return useMemo(() => {
    if (isMeSupervisor) {
      return workpostFacilities
        .map(workpost => me?.Facility_SUPERVISOR?.filter(facility => workpost.facilityId === facility.id))
        .flat();
    }
    return [];
  }, [isMeSupervisor, me?.Facility_SUPERVISOR, workpostFacilities]);
};

export default useSuperFacilities;
