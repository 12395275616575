import parsePhoneNumberFromString, { parsePhoneNumber } from 'libphonenumber-js';
import { useCallback, useState } from 'react';
import Across from 'src/Assets/icons/Across';
import PhoneInput from 'src/components/ui/PhoneMask';
import { useSendDownloadSmsMutation } from 'src/generated/graphql';
import { errorHandler } from 'src/utils/errorHandler';
import { errorToast, successToast } from 'src/utils/notify';

interface IHomePage {
  setShowPopup: (value: boolean) => void;
}

const HomePage: React.FC<IHomePage> = ({ setShowPopup }) => {
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [sendDownloadSmsMutation] = useSendDownloadSmsMutation();

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
    if (phone.length === 0) {
      setError('');
    }
  };

  const submitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (phone.length === 0) {
        return setError('Введите номер');
      }
      if (phone.indexOf('_') !== -1) {
        return setError('Неверное количество цифр');
      }
      const phoneFromMask = parsePhoneNumberFromString(phone)?.nationalNumber;
      if (parsePhoneNumber('8' + phoneFromMask, 'RU').isValid()) {
        sendDownloadSmsMutation({
          variables: {
            phone: phone.replace(/[^0-9]/g, '').substring(1),
          },
        })
          .then(response => {
            if (response) {
              setError('');
              return successToast('Сообщение успешно отправлено!');
            }
          })
          .catch(e => errorToast(errorHandler(e)));
      } else {
        setError('Некорректный номер телефона');
      }
    },
    [phone, sendDownloadSmsMutation]
  );

  const onClose = useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);
  return (
    <form className="main-page-form" onSubmit={submitForm}>
      <div className="flex justify-end">
        <button className="flex justify-end" type="button" onClick={onClose}>
          <Across />
        </button>
      </div>
      <div className="flex flex-col gap-y-4">
        <h3 className="H3">Пригласить пользователя</h3>
        <h4 className="Body1 text-smena_text-secondary w-[305px]">
          Введите номер телефона пользователя, которого хотите пригласить.
        </h4>
        <div className="flex justify-between">
          <div className="w-250px">
            <PhoneInput
              name="phone"
              onChange={handlePhone}
              value={phone}
              placeholder="+7 (921) 345 45 45"
              error={phone.length > 4 && error}
            />
          </div>
          <div>
            <button className="btn-primary_big" type="submit">
              Отправить
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default HomePage;
