import { RegularUserShiftsFragment, ShiftStage } from '../generated/graphql';
import { statusBg } from './scheduleBgStatus';

export const scheduleClassName = (
  facilityId: string,
  workpost: string,
  resource: {
    stage: ShiftStage | string;
    id: string;
    authorId: string;
    userId: string;
    facility?: { id: string } | null;
    positionId: string;
    isProbation: boolean;
  },
  nonSuperShifts?: RegularUserShiftsFragment[],
  supervisor?: boolean
) => {
  const { id, userId, authorId, stage, positionId, facility, isProbation } =
    resource;

  const nonSuperShift = nonSuperShifts?.find(shift => shift.id === id);

  const newStage = () => {
    if (authorId !== userId) {
      return {
        className: 'wait_worker_schedule',
      };
    } else {
      return {
        className: 'wait_foreman_schedule',
      };
    }
  };

  const shiftDoesntBelongToCondition = () => {
    if (isProbation) {
      if (stage === ShiftStage.Ineligible) {
        return {
          className: 'ineligible_schedule',
        };
      }
      if (stage === ShiftStage.Refused) {
        return {
          className: 'refused_schedule',
        };
      }
      return {
        className: 'intership_shift',
      };
    }
    if (stage === ShiftStage.New) {
      return {
        className: newStage().className,
      };
    }
    return {
      className: statusBg(stage).className,
    };
  };

  const notSupepNoFacility = (facility: boolean) => {
    if (facility) {
      return {
        className: shiftDoesntBelongToCondition().className,
      };
    }
    return {
      className: 'nonsuper_schedule other_facility',
    };
  };

  if (supervisor && nonSuperShift) {
    if (!facility || facility?.id !== facilityId) {
      return notSupepNoFacility(false);
    }
    return notSupepNoFacility(true);
  }

  if (!facilityId) {
    return shiftDoesntBelongToCondition();
  }
  if (facility?.id === facilityId) {
    if (workpost) {
      if (positionId === workpost) {
        return shiftDoesntBelongToCondition();
      }
    } else {
      return shiftDoesntBelongToCondition();
    }
  }
  return {
    className: shiftDoesntBelongToCondition().className + ' other_facility',
  };
};
