import { Navigate, Outlet } from 'react-router-dom';

import { useMeShortlyQuery } from '../generated/graphql';
import { IProtectedRoutes } from '../Interfaces/IProtectedRoutes';

const ProtectedRoutes = ({ allowedRoles }: IProtectedRoutes) => {
  const { data: dataMe, loading } = useMeShortlyQuery();
  const user = dataMe?.meShortly;

  return loading ? null : user ? (
    allowedRoles.includes(user.role) ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace />
    )
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoutes;
