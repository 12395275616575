import { useCallback, useMemo, useState } from 'react';
import AcrossSmall from '../../../Assets/icons/AcrossSmall';
import {
  useRemoveForemanFromFacilityMutation,
  useAddSupervisorToFacilityMutation,
  useRemoveSupervisorFromFacilityMutation,
  useAddForemanToFacilityMutation,
} from '../../../generated/graphql';
import { useStore } from '../../../store';
import { errorHandler } from '../../../utils/errorHandler';
import { filterFacilities } from '../../../utils/filters/filterFacilities';
import { errorToast, infoToast, successToast } from '../../../utils/notify';
import SimpleSelect from '../../../components/ui/Select';
import { Skeleton } from '@mui/material';
import { userQueryConfigFn } from '../../../utils/graphqlConfigs/userQueryConfig';
import { roles } from '../../../utils/constVariables';

const FacilityResponsibility = () => {
  const { groups, facilities, facilitiesLoading, currentUser, me } = useStore();

  const [managerGroup, setManagerGroup] = useState('');
  const [managerFacility, setManagerFacility] = useState('');

  const handleChange = useCallback(
    ({ target: { value, name } }: React.ChangeEvent<HTMLSelectElement>) => {
      switch (name) {
        case 'managerGroup':
          setManagerGroup(value);
          setManagerFacility('');
          break;
        case 'managerFacility':
          setManagerFacility(value);
          break;
      }
    },
    []
  );

  const filteredManagerFacilities = useMemo(
    () => filterFacilities(managerGroup, facilities),
    [facilities, managerGroup]
  );

  const userQueryConfig = userQueryConfigFn(currentUser?.id);

  const [addForeman] = useAddForemanToFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const [addSupervisor] = useAddSupervisorToFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const [removeForeman] = useRemoveForemanFromFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const [removeSupervisor] = useRemoveSupervisorFromFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const addForemanHandler = useCallback(
    async (facilityId: string) => {
      await addForeman({
        variables: {
          facilityId,
          userId: currentUser?.id || '',
        },
      })
        .then(response => {
          if (response.data?.addForemanToFacility) {
            successToast('Бригадир прикреплен');
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    },
    [currentUser?.id, addForeman]
  );
  const addSupervisorHandler = useCallback(
    async (facilityId: string) => {
      await addSupervisor({
        variables: {
          facilityId,
          userId: currentUser?.id || '',
        },
      })
        .then(response => {
          if (response.data?.addSupervisorToFacility) {
            successToast('Супервайзер прикреплен');
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    },
    [currentUser?.id, addSupervisor]
  );

  const removeForemanHandler = useCallback(
    async (facilityId: string) => {
      await removeForeman({
        variables: {
          facilityId,
          userId: currentUser?.id || '',
        },
      })
        .then(() => infoToast('Бригадир удален'))
        .catch(e => errorToast(errorHandler(e)));
    },
    [currentUser?.id, removeForeman]
  );
  const removeSupervisorHandler = useCallback(
    async (facilityId: string) => {
      await removeSupervisor({
        variables: {
          facilityId,
          userId: currentUser?.id || '',
        },
      })
        .then(() => successToast('Супервайзер удален'))
        .catch(e => errorToast(errorHandler(e)));
    },
    [currentUser?.id, removeSupervisor]
  );

  const userFacilities = useCallback(
    (user?: {
      role: string;
      Facility_FOREMAN?: any[] | null;
      Facility_SUPERVISOR?: any[] | null;
    }) => {
      if (user?.role === roles.Foreman) {
        return user?.Facility_FOREMAN;
      }
      if (user?.role === roles.Supervisor) {
        return user?.Facility_SUPERVISOR;
      }
    },
    []
  );
  const currentUserFacilities = userFacilities(currentUser);

  const removeFacilityByRole = useCallback(
    (facilityId: string, role?: string) => {
      switch (role) {
        case roles.Foreman:
          removeForemanHandler(facilityId);
          break;
        case roles.Supervisor:
          removeSupervisorHandler(facilityId);
          break;
        default:
          break;
      }
    },
    [removeForemanHandler, removeSupervisorHandler]
  );
  const addFacilityByRole = useCallback(
    (facilityId: string, role?: string) => {
      if (!facilityId) {
        return errorToast('Выберите объект');
      }
      switch (role) {
        case roles.Foreman:
          return addForemanHandler(facilityId);
        case roles.Supervisor:
          return addSupervisorHandler(facilityId);
        default:
          break;
      }
    },
    [addForemanHandler, addSupervisorHandler]
  );

  if (!currentUser) {
    return null;
  }

  const role =
    currentUser.role === roles.Foreman
      ? 'бригадира'
      : currentUser.role === roles.Supervisor
      ? 'супервайзера'
      : '';

  return (
    <div className="userPageBlock blockTemplate">
      <span className="blockTitleTemplate">
        Ответственность на объектах как {role}
      </span>
      {me && [roles.Admin].includes(me.role) && (
        <>
          <div className="flex md:items-end gap-x-5 md:flex-nowrap flex-wrap">
            <SimpleSelect
              divClassName="crudUserMulty"
              label="Группа"
              onChange={handleChange}
              value={managerGroup}
              name="managerGroup"
              autoComplete="managerGroup"
              disabledOption="Не выбрана"
              options={groups}
              validation={true}
            />
            {facilitiesLoading ? (
              <div className="inputHeight flex items-center">
                <Skeleton
                  className="crudUserMulty"
                  height="32px"
                  sx={{ transform: 'none' }}
                />
              </div>
            ) : (
              <SimpleSelect
                divClassName="crudUserMulty"
                label="Объект"
                onChange={handleChange}
                value={managerFacility}
                name="managerFacility"
                autoComplete="managerFacility"
                disabledOption="Не выбран"
                options={filteredManagerFacilities}
                validation={true}
              />
            )}
            <div className="paddingForSelects">
              <button
                className="btn-secondary_small w-full"
                onClick={() =>
                  addFacilityByRole(managerFacility, currentUser?.role)
                }
                type="button"
              >
                <span>Добавить</span>
              </button>
            </div>
          </div>
          <hr />
        </>
      )}
      <div className="bg-smena_white flex flex-col">
        {currentUserFacilities && currentUserFacilities.length > 0 && (
          <div className="flex gap-1 flex-wrap pb-5">
            {userFacilities(currentUser)?.map(
              (facility: { id: string; name: string }) => {
                const canEdit =
                  me?.role === roles.Admin ||
                  me?.Facility_SUPERVISOR?.find(
                    superFacility => superFacility.id === facility.id
                  );
                return (
                  <div
                    key={facility.id}
                    className="py-1 pl-3 pr-2 bg-smena_bb-background rounded-lg flex items-center gap-x-1 group hover:bg-smena_red-extra_light"
                  >
                    <span className="Caption-numbers text-primary group-hover:text-smena_red">
                      {facility.name}
                    </span>
                    {canEdit && (
                      <button
                        onClick={() => {
                          removeFacilityByRole(facility.id, currentUser?.role);
                        }}
                      >
                        <AcrossSmall color="text-primary group-hover:text-smena_red" />
                      </button>
                    )}
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FacilityResponsibility;
