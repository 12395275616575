import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
// import Magnifier from 'src/Assets/icons/magnifier';
import MainSidebar from 'src/components/ui/Sidebar';
import {
  useAssignZayavkaShiftMutation,
  useDeleteWorkerFromZayavkaShiftMutation,
  useGetWorkersForZayavkaShiftLazyQuery,
  useGetZayavkaLazyQuery,
} from 'src/generated/graphql';
import { useStore } from 'src/store';
import { ISO_DATE, roles, RU_DATE } from 'src/utils/constVariables';
import { errorHandler } from 'src/utils/errorHandler';
import { positionName } from 'src/utils/filters/filtersName';
import { getZayavkyByFacilityConfigFn } from 'src/utils/graphqlConfigs/getZayavkyByFacilityConfig';
import { errorToast, successToast } from 'src/utils/notify';
import { userLastnameAndInitials } from 'src/utils/userLastnameAndInitials';
import UserAtSidebar from '../UserAtSidebar';
import { useMeRole } from '../../../../hooks/useRole';
// import { chain } from 'lodash';
import { sortedUsersFn } from './helpers';

interface IWorkerToShift {
  showShiftSidebar: boolean;
  setShowShiftSidebar: (value: boolean) => void;
}

const WorkerToShift: React.FC<IWorkerToShift> = ({ showShiftSidebar, setShowShiftSidebar }) => {
  const {
    requestShift,
    setRequestShift,
    positions,
    userIntersect,
    setUserIntersect,
    // scheduleRequests,
    // setScheduleRequest,
  } = useStore();

  const router = useParams();
  const [params] = useSearchParams();
  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }

  const monthParam = params.get('date');

  const month = monthParam || dayjs().format(ISO_DATE);

  const [loadData, { data }] = useGetZayavkaLazyQuery();

  const currentZayavka = data?.getZayavka;

  const isMeSupervisor = useMeRole(roles.Supervisor);
  const isMeAdmin = useMeRole(roles.Admin);

  const [loadUserIntersectData, { data: usersIntersectData }] = useGetWorkersForZayavkaShiftLazyQuery();

  const users = usersIntersectData?.getWorkersForZayavkaShift;

  const title = `Смена ${dayjs(requestShift?.dateFrom).format(RU_DATE)}`;

  const zayavkaConfig = getZayavkyByFacilityConfigFn(facilityId, month);

  const [assignZayavkaShift, { loading: loadingAssignZayavkaShift }] = useAssignZayavkaShiftMutation();

  const [deleteWorkerFromZayavkaShift, { loading: loadingDeleteWorkerFromZayavkaShift }] =
    useDeleteWorkerFromZayavkaShiftMutation({
      awaitRefetchQueries: true,
      refetchQueries: [zayavkaConfig],
    });

  const deleteWorkerFromZayavkaShiftHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (requestShift) {
      await deleteWorkerFromZayavkaShift({
        variables: {
          id: requestShift.id,
        },
      })
        .then(() => {
          successToast('Пользователь удален из смены');
          setShowShiftSidebar(false);
          setUserIntersect(undefined);
        })
        .catch(error => {
          errorToast(errorHandler(error));
        });
    }
  };

  // const copyOfScheduleRequest = useMemo(
  //   () => chain(scheduleRequests).map('zayavkaSchedule').flatten().map('zayavkaShift').flatten().value(),
  //   [scheduleRequests]
  // );

  const assignZayavkaShiftHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (requestShift) {
      if (userIntersect) {
        assignZayavkaShift({
          variables: {
            workerId: userIntersect.id,
            zayavkaShiftId: requestShift.id,
          },
        })
          .then(() => {
            // const requestShift = response.data?.assignZayavkaShift;
            // const requestOfShift = chain(scheduleRequests).find({ id: requestShift?.zayavkaId });
            // const scheduleRequestWithResponse = copyOfScheduleRequest?.map(request => {
            //   if (request.id === requestShift?.id) {
            //     return requestShift;
            //   }
            //   return request;
            // });

            // setScheduleRequest(scheduleRequestWithResponse);
            successToast('Пользователь назначен на смену');
            setShowShiftSidebar(false);
            setUserIntersect(undefined);
          })
          .catch(error => errorToast(errorHandler(error)));
      } else {
        errorToast('Выберите исполнителя');
      }
    }
  };

  useEffect(() => {
    if (requestShift?.zayavkaId) {
      loadData({
        variables: {
          id: requestShift.zayavkaId,
        },
      });
    }
  }, [loadData, requestShift?.zayavkaId]);

  useEffect(() => {
    if (requestShift?.id) {
      loadUserIntersectData({
        variables: {
          zayavkaShiftId: requestShift.id,
        },
      });
    }
  }, [loadUserIntersectData, requestShift?.id]);

  const sortedUsers = useMemo(() => sortedUsersFn(users), [users]);

  return (
    <MainSidebar
      title={title}
      show={showShiftSidebar}
      setShow={setShowShiftSidebar}
      onClose={() => {
        setRequestShift(undefined);
      }}
    >
      <form className="flex flex-col gap-y-2 mb-5" onSubmit={assignZayavkaShiftHandler}>
        <div className="flex gap-x-1 Subtitle1">
          <span>Номер заявки:</span>
          <Link to={`/requests/${requestShift?.zayavkaId}`} className="text-primary">
            {currentZayavka?.number}
          </Link>
        </div>
        <div className="flex flex-col gap-y-3">
          <h4 className="H4">{positionName(positions, requestShift?.positionId)}</h4>
          <div className="flex gap-x-1 Subtitle2">
            <span>{dayjs(requestShift?.dateFrom).format(RU_DATE)}</span>
            <span>·</span>
            <span>
              {dayjs(requestShift?.dateFrom).format('HH:mm')} - {dayjs(requestShift?.dateEnd).format('HH:mm')}
            </span>
          </div>
          {!requestShift?.shiftId ? (
            <>
              {/* <div className="relative">
                <span className="absolute top-0 left-0 transform translate-x-2/4 translate-y-2/4">
                  <Magnifier />
                </span>
                <input
                  className="input-primary pl-8"
                  onChange={() => {}}
                  type="search"
                  name="search"
                  id="search"
                  placeholder="Поиск"
                />
              </div> */}

              <div className="border-b border-smena_gray-30">
                <div className="graphicSearchUserTable p-4 border-t border-b border-smena_gray-30">
                  <span className="Table-H1 text-smena_text-secondary">Исполнитель</span>
                  <span className="Table-H1 text-smena_text-secondary">Занятость</span>
                </div>

                {sortedUsers?.map(user => (
                  <UserAtSidebar key={user.id} user={user} />
                ))}
              </div>
            </>
          ) : (
            <div className="Subtitle1 flex gap-x-1">
              <span>Исполнитель:</span>
              <Link to={`/users/${requestShift.shift?.user?.id}`} className="text-primary">
                {userLastnameAndInitials(requestShift.shift?.user)}
              </Link>
            </div>
          )}
        </div>

        {isMeSupervisor || isMeAdmin ? (
          !requestShift?.shiftId ? (
            <div className="flex justify-end mt-6">
              <button className="btn-primary" type="submit" disabled={loadingAssignZayavkaShift}>
                Назначить на смену
              </button>
            </div>
          ) : (
            <div className="flex justify-end mt-6">
              <button
                className="btn-reject_secondary"
                type="button"
                onClick={deleteWorkerFromZayavkaShiftHandler}
                disabled={loadingDeleteWorkerFromZayavkaShift}
              >
                Снять со смены
              </button>
            </div>
          )
        ) : null}
      </form>
    </MainSidebar>
  );
};

export default WorkerToShift;
