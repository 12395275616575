import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';

import DatePickerIcon from '../../../Assets/icons/DatePickerIcon';
import CalendarLeftArrow from 'src/Assets/icons/CalendarLeftArrow';
import CalendarRightArrow from 'src/Assets/icons/CalendarRightArrow';
import DatePickerInput from '../../ui/Pickers/DatePickerInput';

const CustomToolbar = (toolbar: ToolbarProps) => {
  const calendarIcon = useRef<HTMLButtonElement>(null);
  const [value, setValue] = useState<dayjs.Dayjs | null>(null);
  const [openCalendar, setOpenCalendar] = useState(false);

  const [params] = useSearchParams();

  let dateFromParam = params.get('dateFrom');
  let dateToParam = params.get('dateTo');

  const goToNext = useCallback(() => {
    toolbar.onNavigate('DATE', dayjs(toolbar.date).add(1, 'month').toDate());
  }, [toolbar]);

  const goToBack = useCallback(() => {
    toolbar.onNavigate('DATE', dayjs(toolbar.date).subtract(1, 'month').toDate());
  }, [toolbar]);

  const calendarHandler = useCallback(() => {
    setOpenCalendar(openCalendar => !openCalendar);
  }, [setOpenCalendar]);

  useEffect(() => {
    if (dateFromParam || dateToParam) {
      toolbar.onNavigate('DATE', dayjs(dateFromParam).toDate());
    }
    //eslint-disable-next-line
  }, [dateFromParam, dateToParam]);

  return (
    <div className="w-full">
      <div className="flex items-center gap-x-2">
        <button onClick={goToBack}>
          <CalendarLeftArrow />
        </button>
        <div className="text-smena_text text-center w-[140px]">{dayjs(toolbar.date).format('MMMM YYYY')}</div>
        <div className="flex items-center">
          <button onClick={calendarHandler} ref={calendarIcon}>
            <DatePickerIcon color="text-primary" opacity="opacity-100" />
          </button>
          <div
            className={clsx('absolute top-0 left-0 w-full h-full', openCalendar ? '' : 'pointer-events-none')}
            onClick={() => {
              if (openCalendar) {
                calendarHandler();
              }
            }}
          ></div>
          <div
            className={clsx('absolute opacity-0 invisible')}
            style={{
              top: calendarIcon.current?.offsetTop,
              left: calendarIcon.current?.offsetLeft,
            }}
          >
            <DatePickerInput
              //check
              value={value}
              placeholder="За весь период"
              onChange={newDate => {
                setValue(newDate);
                toolbar.onNavigate('DATE', newDate?.toDate());
              }}
              minDate={dayjs('2020-01-01')}
              maxDate={dayjs()}
              open={openCalendar}
              picker="month"
            />
          </div>
        </div>

        <button onClick={goToNext}>
          <CalendarRightArrow />
        </button>
      </div>
    </div>
  );
};

export default CustomToolbar;
