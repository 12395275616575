export const reasons = [
  {
    id: 'техническая проблема приложения',
    name: 'техническая проблема приложения',
  },
  {
    id: 'техническая проблема пользователя',
    name: 'техническая проблема пользователя',
  },
  {
    id: 'проблема доступа в интернет',
    name: 'проблема доступа в интернет',
  },
  {
    id: 'ошибка исполнителя',
    name: 'ошибка исполнителя',
  },
  {
    id: 'ошибка супервайзера/бригадира',
    name: 'ошибка супервайзера/бригадира',
  },
  {
    id: 'Не прошёл пробный период',
    name: 'Не прошёл пробный период',
  },
];
