import { useMemo } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import SimpleCheckbox from '../../ui/Checkbox';
import { ItnInput } from '../../ui/InputMasks';
import { ApproveBlock } from './ApproveBlock';
import {
  DocumentStatus,
  ReviewType,
  SelfEmployedStatus,
  useChangePersonalInfoTaxMutation,
} from '../../../generated/graphql';
import { useStore } from '../../../store';
import { errorToast, successToast } from '../../../utils/notify';
import { errorHandler } from '../../../utils/errorHandler';
import { rejectsList } from '../../../utils/lists/commentsList';
import { getVerificationDate } from '../../../utils/get/getVerificationDate';
import { userDocumentsQueryConfigFn } from '../../../utils/graphqlConfigs/userDocumentsQueryConfig';
import { roles, RU_DATE } from '../../../utils/constVariables';
import SelfEmployedCheck from '../../../Assets/icons/SelfEmployedCheck';
import { validateInn } from './validateDocuments';
import DatePickerInput from '../../ui/Pickers/DatePickerInput';

export const NalogInfoPage = () => {
  const router = useParams();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const { userRequisites, userReviews, countries, currentUser, me } = useStore();

  const replceSpace = (itn: string | null) => {
    if (itn !== null) {
      return itn.replace(/\s/g, '');
    }
    return '';
  };

  const userQueryDocumentsConfig = userDocumentsQueryConfigFn(userId);

  const [changeTax] = useChangePersonalInfoTaxMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryDocumentsConfig],
  });

  const reviewAprove = useMemo(() => {
    const result = userReviews?.find(review => review.type === ReviewType.Inn)?.status;
    if (!result) return DocumentStatus.Rejected as DocumentStatus;
    return result;
  }, [userReviews]);

  if (!me) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        inn: userRequisites?.inn ? userRequisites?.inn.replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ') : '',
        isSelfEmployed: userRequisites?.selfEmployed === SelfEmployedStatus.Accepted,
        selfEmployedDate: userRequisites?.selfEmployedDate ? dayjs(userRequisites?.selfEmployedDate) : null,
      }}
      validate={values => {
        const errors: any = {};
        if (!values.inn) {
          errors.inn = 'Поле ИНН - обязательное поле';
        }
        if (values.inn && !/^\d\d\d \d\d\d \d\d\d \d\d\d$/.test(values.inn)) {
          errors.inn = 'В поле Инн должно быть 12 символов';
        }
        if (validateInn(values.inn)) {
          errors.inn = 'Введен неверный ИНН';
        }
        if (values.isSelfEmployed && !values.selfEmployedDate) {
          errors.selfEmployedDate = 'Поле Дата постановки на учет - обязательное поле';
        }

        return errors;
      }}
      onSubmit={values => {
        const selfEmployed = values.isSelfEmployed ? SelfEmployedStatus.Accepted : SelfEmployedStatus.None;
        changeTax({
          variables: {
            input: {
              userId,
              inn: replceSpace(values.inn),
              selfEmployed,
              selfEmployedDate: values.selfEmployedDate,
            },
          },
        })
          .then(res => {
            if (res.data?.changePersonalInfoTax) {
              successToast('Налоговая информация обновлена');
            }
          })
          .catch(err => errorToast(errorHandler(err)));
      }}
    >
      {({ values, touched, errors, handleChange, isSubmitting, handleSubmit, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-y-4 pb-5">
              <div className="flex justify-between flex-wrap">
                <div className="flex flex-col gap-y-6">
                  {[roles.ClientManager, roles.ClientDirector].includes(me.role) && (
                    <div className="flex gap-x-2">
                      <SelfEmployedCheck />
                      <span>Самозанятый</span>
                    </div>
                  )}
                  <div className="flex gap-x-5">
                    <div className="crudUserInput">
                      <label htmlFor="itn" className="label-primary">
                        ИНН
                      </label>
                      <ItnInput
                        name="inn"
                        onChange={handleChange}
                        value={values.inn}
                        placeholder="000 000 000 000"
                        showTextRole={[roles.ClientManager, roles.ClientDirector]}
                        error={touched.inn && errors.inn ? errors.inn : ''}
                      />
                    </div>
                  </div>
                  {![roles.ClientManager, roles.ClientDirector].includes(me.role) ? (
                    <div className="flex gap-x-5 h-10">
                      <SimpleCheckbox
                        divClassName="flex items-center"
                        label="Есть самозанятость"
                        required={false}
                        onChange={handleChange}
                        checked={values.isSelfEmployed}
                        name="isSelfEmployed"
                        disabled={
                          !countries?.find(country => country.id === currentUser?.citizenship?.id)?.isSelfEmployed
                        }
                      />
                      {values.isSelfEmployed && reviewAprove !== DocumentStatus.Accepted ? (
                        <span className="Body2 text-smena_text-secondary" style={{ width: '315px' }}>
                          Необходимо проверить наличие самозанятости и дату постановки на учет в ERP
                        </span>
                      ) : null}
                    </div>
                  ) : null}

                  {/*Fix*/}

                  <div className="flex flex-col gap-x-5 w-[180px]">
                    <label htmlFor="selfEmployedDate" className="label-primary">
                      Дата постановки на учет
                    </label>
                    {![roles.ClientManager, roles.ClientDirector].includes(me.role) ? (
                      <DatePickerInput
                        divClassName="get-date"
                        disabled={!values.isSelfEmployed}
                        value={values.selfEmployedDate}
                        onChange={selfEmployedDate => {
                          setFieldValue('selfEmployedDate', selfEmployedDate);
                        }}
                        maxDate={dayjs()}
                        placeholder={dayjs().format(RU_DATE)}
                        error={{
                          bool: Boolean(touched.selfEmployedDate && errors.selfEmployedDate),
                          text: errors.selfEmployedDate,
                        }}
                      />
                    ) : (
                      <span>{values.selfEmployedDate ? dayjs(values.selfEmployedDate).format(RU_DATE) : '-'}</span>
                    )}
                    <div className="selfEmployedDate">
                      {!countries?.find(country => country.id === currentUser?.citizenship?.id)?.isSelfEmployed ? (
                        <div className="Caption text-smena_text-secondary mt-1">
                          Для гражданства
                          <span className="mx-1">
                            {countries?.find(country => country.id === currentUser?.citizenship?.id)?.name}
                          </span>
                          недоступна самозанятость
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <ApproveBlock
                  reviewType={ReviewType.Inn}
                  updatedAtBlock={userRequisites?.updatedAtTax}
                  commentsList={rejectsList}
                />
              </div>
            </div>
            <div className="flex justify-between items-center save-block flex-wrap">
              <div className="flex gap-x-1">
                <span className="Caption text-smena_text-secondary">Дата верификации:</span>
                <span className="Caption text-smena_text-secondary">
                  {getVerificationDate([ReviewType.Inn], userReviews)}
                </span>
              </div>
              <div className="flex gap-x-2 items-center flex-wrap">
                {userRequisites?.updatedAtTax && (
                  <div className="flex gap-x-1">
                    <span className="Caption text-smena_text-secondary">Посл. изменения</span>
                    <span className="Caption text-smena_text-secondary">
                      {dayjs(userRequisites?.updatedAtTax).format(RU_DATE)}
                    </span>
                  </div>
                )}
                {me && ![roles.ClientManager, roles.ClientDirector].includes(me.role) && (
                  <button type="submit" className="btn-primary_small" disabled={isSubmitting}>
                    Сохранить
                  </button>
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
