import dayjs from 'dayjs';

export const sortObj = (positions: any[]) => {
  const sortPosition = positions.map(a => {
    return { ...a };
  });

  let collator = new Intl.Collator();

  return sortPosition?.sort((a, b) => {
    return collator.compare(a.name, b.name);
  });
};

export const sortChartsData = (res: any[] | undefined) => {
  return res
    ?.map(a => ({ ...a, createdAt: dayjs(a.createdAt).valueOf() }))
    .sort((a, b) => Number(new Date(a.createdAt)) - Number(new Date(b.createdAt)));
};
