const Offer = () => {
  return (
    <>
      <div className="c7">
        <p className="c4">
          <span className="c2">Оферта ООО «БП Диджитал»</span>
        </p>
        <p className="c4">
          <span className="c2">на заключение Агентского договора</span>
        </p>
        <p className="c0">
          <span className="c2">&nbsp;</span>
        </p>
        <p className="c0 c3">
          <span className="c2">
            ООО «БП Диджитал», именуемое в дальнейшем «Агент», в лице
            генерального директора Куркчи Михаила Александровича, действующего
            на основании Устава, выражает намерение заключить Агентский договор
            с «Принципалами» на условиях настоящей оферты:
          </span>
        </p>
        <p className="c0">
          <span className="c2">ОСНОВНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ.</span>
        </p>
        <p className="c0">
          <span className="c2">
            Заявка – направленная Принципалом в адрес Агента заявка на
            выполнение определенного объема работ (услуг) в интересах
            Принципала, направляемая посредством электронной площадки –
            приложения «Smena»;
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Самозанятое лицо – гражданин, реализующий свои товары, работы,
            услуги и применяющий специальный налоговый режим «Налог на
            профессиональный доход», предусмотренный ФЗ №422-ФЗ от 27.11.2018
            года.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Гражданин РФ – физическое лицо, являющееся гражданином Российской
            Федерации и не применяющее специальный налоговый «Налог на
            профессиональный доход».
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Иностранный гражданин - физическое лицо, не являющееся гражданином
            Российской Федерации и не применяющее специальный налоговый «Налог
            на профессиональный доход».
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            Работы (услуги) - виды деятельности, в которых само выполнение
            работы считается основным результатом деятельности и подлежит оплате
            в зависимости от объема работ (услуг), продолжительности их
            исполнения (оказания).
          </span>
        </p>
        <p className="c1">
          <span className="c2">&nbsp;</span>
        </p>
        <p className="c1">
          <span className="c2">
            Приложение «Smena» (электронная площадка) – программно-аппаратный
            комплекс, функционирующий посредством сети «Интернет», позволяющий
            заказчикам размещать заявки на выполнение работ (оказание услуг) для
            удовлетворения своих производственных потребностей и осуществлять
            поиск исполнителей для выполнения работ (оказания услуг).
          </span>
        </p>
        <p className="c1 c5">
          <span className="c2"></span>
        </p>
        <p className="c0">
          <span className="c2">
            Оператор электронной площадки – компания обеспечивающая
            функционирование электронной площадки, принимающая заявки на
            выполнение работ (оказание услуг) и осуществляющая поиск
            исполнителей работ. Оператором электронной площадки выступает Агент.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Партнер – организация осуществляющая выполнение работ (оказание
            услуг) в интересах Принципала, на основании договоров, заключаемых с
            Агентом, действующим в интересах Принципала.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Лицевой счет – счет на электронной площадке, открываемый Заказчику
            (Принципалу) для обеспечения возможности размещения заказов на
            выполнение работ (оказания услуг) и используемый для осуществления
            расчетов между Принципалом, Партнерами, Самозанятыми лицами,
            Гражданами РФ и Иностранными гражданами.
          </span>
        </p>
        <p className="c0">
          <span className="c2">ПРЕДМЕТ ДОГОВОРА.</span>
        </p>
        <ul className="list">
          <li>
            1.1. Агент принимает на себя обязательство за вознаграждение
            оказывать Принципалу услуги электронной площадки, имеющие
            организационный, информационный, технологический характер, в том
            числе совершать по поручению Принципала юридически-значимые действия
            от имени и за счет Принципала.
          </li>
          <li>
            1.2. Услуги, оказываемые по настоящему договору включают в себя:
          </li>
          <li>
            <ul>
              <li>
                1.2.1. Прием, обработка и размещение заявок Принципала на
                выполнение определенного объема работ (услуг);
              </li>
              <li>
                1.2.2. Поиск Партнеров, Самозанятых лиц, Граждан РФ и
                Иностранных граждан, способных удовлетворить потребность
                Принципала в работах (услугах) посредством привлечения к
                процессу выполнения работ (оказания услуг).
              </li>
              <li>
                1.2.3. Подписание Агентом договора с Партнером в интересах
                Принципала, на выполнение работ (оказание услуг).
              </li>
              <li>
                1.2.4. Производство расчетов по выполненным работам (оказанным
                услугам) между Принципалом, Партнерами, Самозанятыми лицами,
                Гражданами РФ и Иностранными гражданами.
              </li>
              <li>
                1.2.5. Информационное и техническое обеспечение взаимодействия
                между Принципалом, Партнерами, Самозанятыми лицами, Гражданами
                РФ и Иностранными гражданами.
              </li>
            </ul>
          </li>
          <li>
            1.3. Агент действует в интересах Принципала разумно и осмотрительно,
            совершая все необходимые действия для исполнения заявки Принципала,
            но при этом Агентом не гарантируется 100% исполнение заявки
            Принципала.
          </li>
          <li>
            1.4. Права на электронную площадку принадлежат Агенту. Положения
            настоящего договора не влекут передачу (отчуждение) исключительных
            прав на электронную площадку или её элементы Принципалу, Партнеру
            или иным лицам.
          </li>
        </ul>
        <ul className="list">
          <li>2. ПРАВА И ОБЯЗАННОСТИ СТОРОН.</li>
          <li>
            <ul>
              <li>2.1. Обязанности Агента:</li>
              <li>
                <ul>
                  <li>
                    2.1.1. Принимать Заявки Принципала посредством электронной
                    площадки.
                  </li>
                  <li>
                    2.1.2. Размещать на электронной площадке лот на выполнение
                    работ в интересах Принципала, осуществлять поиск Партнеров,
                    Самозанятых лиц, Граждан РФ и Иностранных граждан,
                    соответствующих и способных исполнить заявку Принципала.
                  </li>
                  <li>
                    2.1.3. Заключать от имени Принципала договор на выполнение
                    работ (оказание услуг), подписывать от имени Принципала
                    договоры на выполнение работ, акты оказанных услуг, иные
                    документы, необходимые для исполнения настоящего договора.
                  </li>
                  <li>
                    2.1.4. Предоставлять Принципалу отчет агента, документ акт
                    оказанных услуг по агентскому договору.
                  </li>
                  <li>
                    2.1.5. Агент обязан поддерживать электронную площадку в
                    работоспособном состоянии, использовать схемы электронного
                    обмена информацией и оформления документов, развивать
                    сервисы электронной площадки.
                  </li>
                  <li>
                    Сделки на электронной площадке заключаются путем обмена
                    электронными документами по правилам электронной площадки.
                  </li>
                  <li>
                    2.1.6. Агент обеспечивает сохранность документов на
                    электронной площадке, обеспечивает в пределах, установленных
                    правилами электронной площадки, доступность для Принципала
                    документов.
                  </li>
                  <li>
                    2.1.7. Агент обязан организовать учет расчетов между
                    Принципалом и Партнерами, Самозанятыми лицами, Гражданами РФ
                    и Иностранными гражданами при выставлении заявки, обеспечить
                    своевременное перечисление средств, уплаченных Принципалом.
                  </li>
                  <li>
                    2.1.8. Агент резервирует суммы на лицевом счете Принципала,
                    относящиеся к претензионным ситуациям: при возникновении
                    разногласий между Принципалом и его контрагентом при приемке
                    товаров, работ, услуг по количеству и качеству, при
                    заявлениях об уклонении от приемки, в других претензионных
                    случаях в соответствии с правилами электронной площадки.
                  </li>
                </ul>
              </li>
              <li>2.2. Обязанности Принципала: </li>
              <li>
                <ul>
                  <li>
                    2.2.1. Предоставить сведения о сотрудниках, уполномоченных
                    Принципалом на осуществление действий на электронной
                    площадке.
                  </li>
                  <li>2.2.2. Оплачивать Агентское вознаграждение.</li>
                  <li>
                    2.2.3. При размещении заявки на выполнение работ (оказание
                    услуг), произвести пополнение лицевого счета на сумму,
                    достаточную для осуществления расчетов по выполненным
                    работам (оказанным услугам), а также на выплату агентского
                    вознаграждения.
                  </li>
                  <li>
                    2.2.4. Принципал обязан соблюдать правила электронной
                    площадки, соблюдать требования, предъявляемые ему как
                    налогоплательщику, иные требования публичного порядка.
                    Принципал обязан выполнять обязанности, вытекающие из всех
                    сделанных им юридически значимых заверений, в частности,
                    обязан воздерживаться от использования электронной площадки
                    с целью получения необоснованной налоговой выгоды,
                    реализации той или иной схемы налогового мошенничества,
                    недобросовестного поведения, уклонения от налогообложения,
                    нарушения статьи 54.1 Налогового кодекса РФ, совершения
                    мнимых и фиктивных сделок, создания фиктивного
                    документооборота, прикрытия серой занятости, дробления
                    бизнеса.
                  </li>
                  <li>
                    2.2.5. Принципал в течение 5 дней, с даты получения отчета
                    Агента и Акта оказанных услуг, обязан их рассмотреть и
                    предоставить экземпляр подписанного акта либо мотивированный
                    отказ от его подписания. В случае, если в установленный срок
                    мотивированный отказ от подписания акта не представлен, акт
                    считается принятым без замечаний.
                  </li>
                  <li>
                    2.2.6. Принципал обязан не предоставлять возможность
                    использования личных кабинетов в мобильном приложении, а
                    также соответствующих технических устройств, а также своей
                    электронной подписи третьим лицам.
                  </li>
                  <li>
                    2.2.7. Принципал обязан самостоятельно принимать решения о
                    целесообразности заключения сделок на электронной площадке с
                    контрагентами, самостоятельно нести ответственность за
                    принятие таких решений, не предъявлять к Агенту претензии в
                    связи с нарушением контрагентами своих обязательств или
                    претензий, связанных с наступлением иных негативных для
                    Принципала событий.
                  </li>
                  <li>
                    2.2.8. Принципал обязан своевременно предоставлять
                    актуальные сведения, необходимые оператору для формирования
                    электронных документов, реестров, журналов, архивов в
                    соответствии с правилами электронной площадки.
                  </li>
                </ul>
              </li>
              <li>2.3. Права Агента:</li>
              <li>
                <ul>
                  <li>
                    2.3.1. Агент имеет право на своевременное получения суммы
                    агентского вознаграждения;
                  </li>
                  <li>
                    2.3.2. Агент вправе не приступать к исполнению договора при
                    отсутствии на лицевом счете Принципала денежных средств,
                    необходимых для оплаты работ (услуг), указанных в заявке
                    Принципала.
                  </li>
                  <li>
                    2.3.3. Агент вправе приостановить оказание услуг при наличии
                    задолженности (отрицательный баланс лицевого счета)
                    Принципала.
                  </li>
                  <li>
                    2.3.4. Агент вправе модифицировать или выпускать обновления
                    программного обеспечения и онлайн-сервиса, добавлять новые
                    свойства или функциональные возможности программного
                    обеспечения и онлайн-сервиса, повышающие его
                    работоспособность или иным способом улучшающие его
                    характеристики.
                  </li>
                </ul>
              </li>
              <li>2.4. Права Принципала. </li>
              <li>
                <ul>
                  <li>
                    2.4.1. Принципал вправе получать доступ к данным
                    статистического учета по функционированию электронной
                    площадки.
                  </li>
                  <li>
                    2.4.2. Принципал вправе требовать надлежащего исполнения
                    Агентом обязанностей, предусмотренных настоящим договором.
                  </li>
                  <li>
                    2.4.3. Принципал вправе, желая приобрести и приобретая
                    товары, работы, услуги у самозанятых, размещать на
                    электронной площадке соответствующую информацию.
                  </li>
                  <li>
                    2.4.4. Принципал вправе знакомиться с информацией,
                    размещаемой на электронной площадке его потенциальными
                    контрагентами (самозанятыми), предназначенной этими лицами
                    для свободного ознакомления заказчикам.
                  </li>
                  <li>
                    2.4.5. Принципал вправе заключать на электронной площадке
                    сделки (договоры, рамочные договоры, подавать заявки,
                    совершать иные сделки).
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>3. ОТДЕЛЬНЫЕ ПОЛОЖЕНИЯ.</li>
          <li>
            <ul>
              <li>
                3.1 Право собственности на результат работ (оказанных услуг)
                Партнером переходит непосредственно Принципалу и никогда не
                переходит к Агенту.
              </li>
              <li>
                3.2. Агент не несет ответственности за инструктаж, обучение,
                профессиональную подготовку самозанятых, за обеспечение техники
                безопасности при выполнении работ, услуг, продаже товаров
                самозанятых.
              </li>
              <li>
                3.3. Агент и Принципал не являются работодателем Самозанятых
                лиц, Граждан РФ и Иностранных граждан. Настоящий договор не
                является договором о предоставлении заемного труда, о
                предоставлении аутсорсинговых, рекрутинговых услуг, услуг по
                организованному найму; результатом исполнения настоящего
                договора оператором ни в коем случае не становится заключение
                трудовых договоров Принципалом с самозанятыми.
              </li>
              <li>
                3.4. Агент не отвечает за качество, количество, сроки выполнения
                товаров, услуг, работ, реализуемых самозанятыми.
              </li>
              <li>
                3.5. Агент не отвечает за достоверность информации,
                представленной самозанятыми при постановке на учет в налоговом
                органе в порядке, установленном федеральным законом от
                27.11.2018 № 422-ФЗ, информации, представленной самозанятыми
                иным органам Российской Федерации и ЕАЭС, предоставленной
                Принципалу при использовании электронной площадки.
              </li>
            </ul>
          </li>
          <li>4. ПОРЯДОК РАСЧЕТОВ.</li>
          <li>
            <ul>
              <li>
                4.1. Размер агентского вознаграждения составляет 10% от
                стоимости работ (услуг) выполненных в интересах Принципала, в
                том числе НДС. Стоимость работ (услуг) подлежащих выполнению
                определяется заявкой Принципала.
              </li>
              <li>
                4.2. Агентское вознаграждение списывается Агентом с лицевого
                счет Принципала в течение 2 дней, с даты подписания Принципалом
                акта оказанных услуг по агентскому договору.
              </li>
              <li>
                4.3. Акт оказанных услуг по агентскому договору направляется
                Агентом Принципалу посредством электронной площадки. К акт
                прилагается отчет Агента. Акт и отчет должны быть рассмотрены
                Принципалом в течение 5 дней, с даты получения, в указанный срок
                Принципал подписывает акт либо направляет мотивированный отказ
                от подписания акта. При не направлении мотивированного отказа от
                подписания акта, услуги считаются оказанными, акт подписанным.
              </li>
              <li>
                4.4. Расчеты по выполненным работам, оказанным услугам между
                Принципалом и Партнером производится путем списания денежных
                средств с лицевого счета Принципала на электронной площадке.
                Списание денежных средств производится по факту подписания акта
                выполненных работ (оказанных услуг) между Принципалом и
                Партнером. От имени Принципала акт может подписываться Агентом.
              </li>
            </ul>
          </li>
          <li>5. ОТВЕТСТВЕННОСТЬ СТОРОН.</li>
          <li>
            <ul>
              <li>
                5.1. За нарушение предусмотренных Договором обязательств Стороны
                несут ответственность в соответствии с действующим
                законодательством РФ.
              </li>
              <li>
                5.2. В случае просрочки перечисления Агенту причитающихся ему
                денежных сумм в счет вознаграждения, не удержанного Агентом
                согласно, Принципал обязан уплатить Агенту штрафную неустойки в
                размере 0,1% за каждый день просрочки, но не более 10% от суммы
                задолженности.
              </li>
              <li>
                5.3. В случае нарушения сроков перечисления Партнеру денежных
                средств, полученных от Принципала, Агент обязан уплатить
                Принципалу штрафную неустойку в размере 0,1% от несвоевременно
                перечисленных средств за каждый день просрочки, но не более 10%
                от несвоевременно исполненного обязательства.
              </li>
              <li>
                5.4. Агент не отвечает также за какие-либо косвенные (непрямые)
                убытки, упущенную выгоду или возможный ущерб Принципала и (или)
                третьих лиц в результате использования онлайн-сервиса, возникшие
                в том числе в результате:
              </li>
              <ul>
                <li>1) сбоев в работе онлайн-сервиса;</li>
                <li>
                  2) неправомерных действий пользователей сети Интернет,
                  направленных на нарушение информационной безопасности или
                  нормального функционирования сайта и онлайн-сервиса;
                </li>
                <li>
                  3) отсутствия (невозможности установления, прекращения и пр.)
                  Интернет-соединения между сервером.
                </li>
                <li>
                  4) проведения государственными и муниципальными органами, а
                  также иными организациями мероприятий в рамках
                  оперативно-розыскных мероприятий;
                </li>
                <li>
                  5) установления государственного регулирования (или
                  регулирования иными организациями) хозяйственной деятельности
                  коммерческих организаций в сети Интернет и (или) установления
                  указанными субъектами разовых ограничений, затрудняющих или
                  делающих невозможным исполнение настоящего договора;{' '}
                </li>
                <li>
                  6) других случаев, связанных с действиями (бездействием)
                  пользователей сети Интернет и (или) других субъектов,
                  направленными на ухудшение общей ситуации с использованием
                  сети Интернет и (или) компьютерного оборудования,
                  существовавшей на момент заключения настоящего договора.
                </li>
              </ul>
            </ul>
          </li>
          <li>6. ПОРЯДОК РАСТОРЖЕНИЯ ДОГОВОРА. </li>
          <li>
            <ul>
              <li>
                6.1. Стороны вправе в любое время изменить либо расторгнуть
                настоящий договор по взаимному соглашению.
              </li>
              <li>
                6.2. В случае, если имеются принятые к исполнению и не
                исполненные Принципалом на основании заключенных на электронной
                площадке сделок денежные обязательства, а также имеются
                незавершенные расчеты между ним и его контрагентами, в т. ч.
                оператором, расторжение настоящего договора в одностороннем
                порядке не допускается.
              </li>
              <li>
                В остальных случаях любая из сторон вправе расторгнуть настоящий
                договор на будущее, заявив об этом другой стороне через
                электронную площадку за тридцать дней.
              </li>
              <li>
                6.3. Агент вправе потребовать расторжения договора в
                одностороннем порядке в случае нарушения Принципалом правил
                электронной площадки.
              </li>
            </ul>
          </li>
          <li>7. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ.</li>
          <li>
            <ul>
              <li>
                7.1. Споры, возникающие при исполнении настоящего договора,
                подлежат разрешению путем переговоров, с соблюдением
                обязательного претензионного порядка разрешения споров. Срок
                ответа на претензию, 15 дней, с даты ее получения стороной.
              </li>
              <li>
                7.2. При не разрешении спора в досудебном порядке, спор
                рассматривается в Арбитражном суде г. Москвы.
              </li>
            </ul>
          </li>
          <li>8. СРОК ДЕЙСТВИЯ ДОГОВОРА.</li>
          <li>
            <ul>
              <li>
                8.1. Договор вступает в силу с момента его подписания и
                действует до конца календарного года включительно.
              </li>
              <li>
                8.2. Настоящий договор считается пролонгированным каждый раз на
                12 календарных месяцев, если ни одна из сторон не заявит об
                отказе от его пролонгации не позднее чем за 1 месяц до даты
                окончания срока действия договора.
              </li>
            </ul>
          </li>
          <li>9. ПРОЧИЕ УСЛОВИЯ.</li>
          <li>
            <ul>
              <li>9.1. Настоящий договор может быть заключен:</li>
              <li>
                а) в письменной форме на бумажном носителе единым документом с
                приложением печатей сторон и совершением подписей уполномоченных
                представителей сторон в порядке, установленном гражданским
                законодательством и применяемыми в Российской Федерации
                стандартами делопроизводства;
              </li>
              <li>
                б) путем обмена электронными документами, передаваемыми с
                помощью электронной площадки, а также смс-сообщений с мобильного
                телефона Принципала, используемого им для доступа к сервисам
                электронной площадки. При этом данный обмен позволяет достоверно
                установить, что документ исходит от стороны по договору.
              </li>
              <li>
                9.2. В следующих случаях Принципал может использовать
                квалифицированную (усиленную квалифицированную) электронную
                подпись:
              </li>
              <li>а) при регистрации на электронной площадке;</li>
              <li>
                б) при подписании договора с Агентом, Партнером (всех
                электронных документов, относящихся к оформлению договора, его
                изменению, пролонгации, досрочному прекращению).
              </li>
              <li>
                9.3. Учетная запись Принципала формируется как совокупность
                логина и пароля. Логин и пароль Принципала являются аналогом
                собственноручной подписи и не передаются оператору.
              </li>
              <li>
                9.4. Электронная подпись в соответствии с указанным федеральным
                законом от 6.04.2011 № 63-ФЗ – это информация в электронной
                форме, которая присоединена к другой информации в электронной
                форме (подписываемой информации) или иным образом связана с
                такой информацией, и которая используется для определения лица,
                подписывающего информацию. К совершению заказчиком действий,
                указанных на сайте или в мобильном приложении в качестве
                действий, необходимых для использования функциональной
                возможности электронной площадки с использованием электронной
                подписи, относятся: переход по ссылке на сайте / нажатие кнопок
                с соответствующей функциональной возможностью / ввод SMS-ключа в
                предложенную экранную форму, ввод SMS-ключа в мобильном
                приложении, совпадающего с отправленным самозанятому SMS-ключом
                (при наличии) и прочее.
              </li>
              <li>
                9.5. Электронный документ электронной площадки – взаимосвязанный
                набор электронных записей, создаваемый̆ и хранимый с помощью
                электронной площадки, в том числе: документы первичного
                обращения Принципала к оператору (об оказании услуг по
                постановке на учет, о согласии с правилами электронной
                площадки), договоры оператора с клиентом, договоры самозанятых с
                их контрагентами (рамочные договоры), заявки, планы, генеральные
                задания, чеки, акты, документы о перечислении налога, чеки
                онлайн-кассы оператора на оплату его услуг, отчеты оператора,
                иные документы в соответствии с правилами электронной площадки,
                относящиеся к выражению намерения (волеизъявления) Принципала и
                его контрагентов на установление, исполнение, прекращение
                договоров на электронной площадке, получение или отказ от
                получения сервисов электронной площадки.
              </li>
              <li>
                9.6. SMS-ключ – известная Принципалу (и только ему)
                последовательность алфавитно-цифровых символов, полученная на
                его мобильный номер телефона от оператора электронной площадки.
              </li>
              <li>
                9.7. Совершение Принципалом действий, указанных на сайте или в
                мобильном приложении в качестве действий, необходимых для
                использования функций (сервисов) электронной площадки,
                признаются действиями, совершенными лично Принципалом, а также
                являются действиями, совершенными с использованием простой
                электронной подписи и считаются однозначным выражением согласия
                Принципала на получение соответствующих функций (сервисов),
                совершение сделок, принятие решений, дачу ответов на
                предложенные вопросы. Определение Принципала, использующего
                простую электронную подпись, осуществляется оператором после
                успешной аутентификации. Электронный документ считается
                подписанным простой электронной подписью при одновременном
                соблюдении следующих условий:
              </li>
              <li>
                <ul>
                  <li>
                    а) определен пользователь - Принципал, использующий простую
                    электронную подпись;
                  </li>
                  <li>
                    б) установлен факт совершения пользователем - Принципалом
                    действий, указанных на сайте или в мобильном приложении в
                    качестве действий, необходимых для использования
                    функциональной возможности онлайн-сервиса.
                  </li>
                </ul>
              </li>
              <li>
                9.8. Стороны признают используемые ими по настоящему договору в
                соответствии с правилами электронной площадки системы
                телекоммуникаций, обработки и хранения информации достаточными
                для обеспечения надежной и эффективной работы при приеме,
                передаче, обработке и хранении информации, а систему защиты
                информации, обеспечивающую разграничение доступа, шифрование,
                достаточной для защиты от несанкционированного доступа,
                подтверждения авторства и подлинности информации, содержащейся в
                получаемых электронных документах, и разбора спорных ситуаций.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Offer;
