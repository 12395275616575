import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

interface IUseFacilityHeaderMenu {
  [key: string]: any;
}

const useFacilityHeaderMenu = ({
  currentLocation,
  isMeAdmin,
  ...rest
}: IUseFacilityHeaderMenu) => {
  const { facilityId } = rest;
  const location = useLocation();
  return useMemo(() => {
    const array = [
      {
        name: 'Информация',
        link:
          currentLocation === 'Анкета'
            ? location.pathname
            : '/facilities/' + facilityId,
        disabled: !Boolean(facilityId),
      },
      {
        name: 'Заявки',
        link:
          currentLocation === 'Заявки'
            ? location.pathname
            : '/facilities/' + facilityId + '/requests',
        disabled: !Boolean(facilityId),
      },
      {
        name: 'График',
        link:
          currentLocation === 'Заявки'
            ? location.pathname
            : '/facilities/' + facilityId + '/schedule',
        disabled: !Boolean(facilityId),
      },
      {
        name: 'Профессии объекта',
        link:
          currentLocation === 'Заявки'
            ? location.pathname
            : '/facilities/' + facilityId + '/add-profession',
        disabled: !Boolean(facilityId),
      },
    ];
    if (isMeAdmin) {
      array.push({
        name: 'Вакансии объекта',
        link:
          currentLocation === 'Заявки'
            ? location.pathname
            : '/facilities/' + facilityId + '/vacancies',
        disabled: !Boolean(facilityId),
      });
    }
    return array;
  }, [isMeAdmin, facilityId, currentLocation, location.pathname]);
};

export default useFacilityHeaderMenu;
