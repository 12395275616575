export const RouteRightArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7693 4.84688V9.34688C21.7693 9.54579 21.6903 9.73656 21.5497 9.87721C21.409 10.0179 21.2182 10.0969 21.0193 10.0969H16.5193C16.3204 10.0969 16.1297 10.0179 15.989 9.87721C15.8483 9.73656 15.7693 9.54579 15.7693 9.34688C15.7693 9.14797 15.8483 8.9572 15.989 8.81655C16.1297 8.6759 16.3204 8.59688 16.5193 8.59688H19.21L17.3068 6.69375C16.2576 5.64569 14.9212 4.93222 13.4666 4.6435C12.012 4.35479 10.5044 4.5038 9.13442 5.07171C7.76446 5.63961 6.59362 6.60091 5.76988 7.83409C4.94613 9.06728 4.50648 10.517 4.50648 12C4.50648 13.483 4.94613 14.9327 5.76988 16.1659C6.59362 17.3991 7.76446 18.3604 9.13442 18.9283C10.5044 19.4962 12.012 19.6452 13.4666 19.3565C14.9212 19.0678 16.2576 18.3543 17.3068 17.3063C17.3758 17.2357 17.4582 17.1796 17.5492 17.1413C17.6401 17.103 17.7378 17.0833 17.8365 17.0833C17.9352 17.0833 18.0329 17.103 18.1239 17.1413C18.2148 17.1796 18.2972 17.2357 18.3662 17.3063C18.5065 17.4469 18.5852 17.6373 18.5852 17.8359C18.5852 18.0345 18.5065 18.225 18.3662 18.3656C17.1073 19.6238 15.5037 20.4805 13.758 20.8274C12.0123 21.1743 10.2029 20.9958 8.55867 20.3145C6.91441 19.6331 5.50908 18.4796 4.52035 16.9996C3.53163 15.5197 3.00391 13.7798 3.00391 12C3.00391 10.2202 3.53163 8.4803 4.52035 7.00036C5.50908 5.52042 6.91441 4.36687 8.55867 3.68554C10.2029 3.00421 12.0123 2.82571 13.758 3.17259C15.5037 3.51948 17.1073 4.37618 18.3662 5.63438L20.2693 7.5375V4.84688C20.2693 4.64797 20.3483 4.4572 20.489 4.31655C20.6297 4.1759 20.8204 4.09688 21.0193 4.09688C21.2182 4.09688 21.409 4.1759 21.5497 4.31655C21.6903 4.4572 21.7693 4.64797 21.7693 4.84688Z"
        fill="#393B55"
      />
    </svg>
  );
};
