import { Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useVacanciesPaginatedLazyQuery } from '../../../generated/graphql';

import MainLayout from '../../../components/Layout/main';
import { facilityVacanciesColumns } from '../../../components/Table/columns';
import FacilityVacanciesTable from '../../../components/Table/FacilityVacanciesTable';
import FacilityVacanciesFilter from '../../../components/Table/Filters/FacilityVacansies';
import TableHeader from '../../../components/Table/header';
import SkeletonLayout from '../../../components/Table/Skeleton';

import { activeHandler } from '../../../utils/isActiveHandler';

import { useStore } from '../../../store';
import { facilityName } from '../../../utils/filters/filtersName';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { maxTableWidth } from 'src/utils/maxTableWidth';
import useFacilityHeaderMenu from 'src/hooks/headerMenu/useFacilityHeaderMenu';
import { roles } from 'src/utils/constVariables';
import useFacilityBreadcrumbs from 'src/hooks/breadcrumbs/useFacilityBreadcrumbs';

const FacilityVacanciesPage = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [cityId, setCityId] = useState('');
  const [period, setPeriod] = useState('');
  const [schedule, setSchedule] = useState<string[]>([]);
  const [isActive, setIsActive] = useState<boolean | string>('');

  const [params] = useSearchParams();

  const router = useParams();

  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }

  const [loadData, { data: dataVacancies, loading: loadingVacancies }] = useVacanciesPaginatedLazyQuery();

  const vacanciesPaginated = dataVacancies?.vacanciesPaginated;
  const vacancies = vacanciesPaginated?.vacancies;
  const { facilities, me } = useStore();

  let cityParam = params.get('cityId');
  let periodParam = params.get('period');
  let scheduleParam = params.get('schedule');
  let activeParam = params.get('active');
  let searchParam = params.get('search');

  const location = useLocation();

  const mutationInput = {
    page: 1,
    searchQuery: search,
    sortBy,
    sortWay,
    groupId: '',
    facilityId,
    cityId,
    period,
    schedule,
    isActive: activeHandler(isActive),
  };

  const returnData = () => {
    return loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
        },
      },
    });
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const isMeAdmin = me && [roles.Admin].includes(me.role);

  const headerMenu = useFacilityHeaderMenu({
    currentLocation: 'Вакансии объекта',
    facilityId,
    isMeAdmin,
  });

  const breadCrumbs = useFacilityBreadcrumbs({ facilityId });

  useEffect(() => {
    if (cityParam) {
      setCityId(cityParam);
    } else {
      setCityId('');
    }
    if (periodParam) {
      setPeriod(periodParam);
    } else {
      setPeriod('');
    }
    if (activeParam) {
      setIsActive(activeParam);
    } else {
      setIsActive('');
    }
    if (scheduleParam) {
      setSchedule(scheduleParam.split(', '));
    } else {
      setSchedule([]);
    }
    if (searchParam) {
      setSearch(searchParam);
    } else {
      setSearch('');
    }
    loadData({
      variables: {
        input: {
          ...mutationInput,
          cityId: cityParam ? cityParam : '',
          period: periodParam ? periodParam : '',
          schedule: scheduleParam ? scheduleParam.split(', ') : [],
          isActive: activeParam ? activeHandler(activeParam) : null,
        },
      },
    });

    // eslint-disable-next-line
  }, [page, search, sortBy, sortWay, location]);

  return (
    <MainLayout
      title={`Вакансии объекта ${facilityName(facilities, facilityId)}`}
      bg="bg-smena_bb-background_base"
      headerMenu={headerMenu}
      breadCrumbs={breadCrumbs}
    >
      <div
        style={{
          maxWidth: maxTableWidth(facilityVacanciesColumns) + 'px',
        }}
      >
        <FacilityVacanciesFilter
          returnData={returnData}
          setSearch={setSearch}
          setCityId={setCityId}
          setPeriod={setPeriod}
          setSchedule={setSchedule}
          setIsActive={setIsActive}
          setPage={setPage}
          cityId={cityId}
          period={period}
          schedule={schedule}
          isActive={isActive}
          facilityId={facilityId}
        />
        <ScrollSync>
          <div className="flex flex-col shadow-smena rounded-lg">
            <TableHeader
              dataLength={vacancies?.length}
              columns={facilityVacanciesColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />
            {loadingVacancies || !vacancies ? (
              <SkeletonLayout columns={facilityVacanciesColumns} />
            ) : vacancies.length > 0 ? (
              <ScrollSyncPane>
                <div className="main-table">
                  <FacilityVacanciesTable vacancies={vacancies} columns={facilityVacanciesColumns} />
                </div>
              </ScrollSyncPane>
            ) : (
              <div className="w-full flex justify-center my-5">
                <h3 className="text-smena_text Body1">
                  По вашему запросу <span className="font-bold">{search}</span> ничего не нашлось
                </h3>
              </div>
            )}
          </div>
        </ScrollSync>
        <div className="py-5">
          {vacanciesPaginated?.pages! > 1 ? (
            <Pagination
              shape="rounded"
              count={vacanciesPaginated ? vacanciesPaginated.pages : 10}
              page={page}
              onChange={handleChange}
            />
          ) : null}
        </div>
      </div>
    </MainLayout>
  );
};

export default FacilityVacanciesPage;
