import { useCallback } from 'react';
import clsx from 'clsx';

import { RegularModerationReportTableDayFragment } from '../../../../generated/graphql';

import { useStore } from '../../../../store';

import { IRowDays } from '../../../../Interfaces/IRow';
import { borderBottom } from './utils';
import { durationFn } from '../../../../utils/durationFn';
import { timeObject } from '../../../../utils/timeObject';
import { isInt } from '../../../../utils/isInt';
import { moderationShift } from 'src/utils/moderationShift';

export const RowDays: React.FC<IRowDays> = ({ row, days }) => {
  const { moderationReport, setPeriodShiftsInfo, userInfo, info, setShowSidebar, setCurrentModerationRow } = useStore();

  const user = {
    userFio: {
      id: row?.user.id,
      firstname: row?.user.firstname,
      lastname: row?.user.lastname,
    },
    position: {
      id: row?.position.id,
      name: row?.position.name,
    },
  };

  const cellBorder = useCallback(
    (day: RegularModerationReportTableDayFragment) => {
      const currentDay = day.dayNum === info?.dayNum;
      const currentUser = userInfo?.userFio.id === row.user.id;
      const currentPosition = userInfo?.position?.id === row.position.id;
      const colors = ['yellow', 'red', 'green', 'gray', 'purple'];

      if (currentDay && currentUser && currentPosition) {
        return {
          show: true,
          color: colors.find(currentColor => moderationShift(day).bg.includes(currentColor)),
        };
      } else {
        return {
          show: false,
          color: '',
        };
      }
    },
    [info?.dayNum, userInfo, row]
  );

  const cellValue = useCallback((shift: any) => {
    const { durationFact, durationPlan, moderation, payout, value } = shift;
    const timeFn = (duration: number) => timeObject(durationFn(Number(Math.round(duration))));
    switch (payout) {
      case 'HOURLY':
        return moderation ? (durationFact ? timeFn(durationFact) : timeFn(durationPlan)) : timeFn(durationPlan);
      case 'PIECEWORK':
        return moderation ? isInt(moderation.value) : isInt(value);
      default:
        return isInt(value);
    }
  }, []);

  return (
    <div
      className={`grid p-1 h-7 ${borderBottom(
        row?.user.id,
        row?.position.id,
        moderationReport?.getModerationReport.rows
      )}`}
      style={{
        gridTemplateColumns: `repeat(${days?.length}, 1fr)`,
        columnGap: '5px',
        width: 'fit-content',
      }}
    >
      {row.days.map(day => {
        const { shifts } = day;
        return (
          <button
            type="button"
            className={clsx(
              'period__text Tag rounded relative',
              cellBorder(day).show ? `border-2 ${cellBorder(day).color}-border` : 'border-0.5',
              moderationShift(day).bg
            )}
            onClick={() => {
              setShowSidebar(true);
              setCurrentModerationRow(row);
              setPeriodShiftsInfo(day, user);
            }}
            key={day.dayNum}
          >
            <span>{shifts.length > 0 && cellValue(moderationShift(day).shift)}</span>
            {shifts.length > 1 && (
              <span
                className={clsx('employer__mark', cellBorder(day).show ? 'right-0 -top-2px' : '-top-px right-px')}
              ></span>
            )}
          </button>
        );
      })}
    </div>
  );
};
