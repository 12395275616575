import dayjs from 'dayjs';
import clsx from 'clsx';
import { ScrollSyncPane } from 'react-scroll-sync';

import { RegularZayavkaScheduleFragment } from 'src/generated/graphql';

import RequestProfessionDay from './days';

interface IRequestProfessionDates {
  requestSchedule: RegularZayavkaScheduleFragment;
  days: dayjs.Dayjs[];
  setShowEditGraphicSidebar: (value: boolean) => void;
}
const RequestProfessionDates: React.FC<IRequestProfessionDates> = ({ requestSchedule, days }) => {
  return (
    <ScrollSyncPane>
      <div
        className={clsx('gap-x-1 requestRows border-b requestRow w-fit')}
        style={{
          gridTemplateColumns: `repeat(${days.length}, 80px)`,
        }}
      >
        {days.map(day => (
          <RequestProfessionDay key={day.valueOf()} day={day} requestSchedule={requestSchedule} />
        ))}
      </div>
    </ScrollSyncPane>
  );
};

export default RequestProfessionDates;
