import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Pagination } from '@mui/material';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { useLeadsLazyQuery } from '../generated/graphql';

import MainLayout from '../components/Layout/main';
import { leadColumns } from '../components/Table/columns';
import SkeletonLayout from '../components/Table/Skeleton';
import LeadsTable from '../components/Table/LeadsTable';
import TableHeader from '../components/Table/header';
import LeadsFilters from '../components/Table/Filters/LeadsFilters';
import { maxTableWidth } from 'src/utils/maxTableWidth';

const LeadsPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [value, setValue] = useState<moment.Moment[] | null>(null);
  const [params] = useSearchParams();

  const [loadData, { data, loading }] = useLeadsLazyQuery();

  const leadsPagination = data?.leads;
  const leads = leadsPagination?.leads;

  let statusParams = params.get('status');
  let dateFrom = params.get('dateFrom');
  let dateTo = params.get('dateTo');

  const location = useLocation();

  const mutationInput = {
    page: 1,
    searchQuery: search,
    status,
    sortBy,
    sortWay,
    dateFrom,
    dateTo,
  };

  const returnData = () => {
    return loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
          dateFrom: value && String(moment(value[0]).valueOf()),
          dateTo: value && String(moment(value[1]).valueOf()),
        },
      },
    });
  };

  const handleChangePage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (statusParams) {
      setStatus(statusParams);
    } else {
      setStatus('');
    }
    if (dateFrom && dateTo) {
      setValue([moment(dateFrom), moment(dateTo)]);
    } else {
      setValue(null);
    }

    loadData({
      variables: {
        input: {
          ...mutationInput,
          dateFrom: dateFrom ? String(moment(dateFrom).valueOf()) : null,
          dateTo: dateTo ? String(moment(dateTo).valueOf()) : null,
          page,
          searchQuery: search,
          status: statusParams || '',
        },
      },
    });

    // eslint-disable-next-line
  }, [page, search, sortBy, sortWay, location]);

  return (
    <MainLayout
      title="Акции. Реферальная программа"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[{ path: '/leads', name: 'Акции' }]}
    >
      <div
        style={{
          maxWidth: maxTableWidth(leadColumns) + 'px',
        }}
      >
        <LeadsFilters
          returnData={returnData}
          value={value}
          status={status}
          setStatus={setStatus}
          setValue={setValue}
          setSearch={setSearch}
          setPage={setPage}
        />
        <ScrollSync>
          <div className="flex flex-col shadow-smena rounded-lg">
            <TableHeader
              dataLength={leads?.length}
              columns={leadColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />
            {loading || !leads ? (
              <SkeletonLayout columns={leadColumns} />
            ) : leads.length > 0 ? (
              <ScrollSyncPane>
                <div className="main-table">
                  <LeadsTable users={leads} columns={leadColumns} />
                </div>
              </ScrollSyncPane>
            ) : (
              <div className="w-full flex justify-center my-5">
                <h3 className="text-smena_text Body1">
                  По вашему запросу <span className="font-bold">{search}</span> ничего не нашлось
                </h3>
              </div>
            )}
          </div>
        </ScrollSync>
        <div className="py-5">
          {leadsPagination?.pages! > 1 && (
            <Pagination
              shape="rounded"
              count={leadsPagination ? leadsPagination.pages : 10}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default LeadsPage;
