import {
  GetModerationReportDocument,
  GetModerationReportQueryVariables,
} from '../../generated/graphql';

interface IModerationReportConfig {
  query: typeof GetModerationReportDocument;
  variables: GetModerationReportQueryVariables;
}

export const moderationReportConfigFn = (
  id?: string
): IModerationReportConfig => ({
  query: GetModerationReportDocument,
  variables: {
    id: id || '',
  },
});
