import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { chartClick, chartHover } from '../../utils/charts/chartHandlers';
import { ITooltipStatus } from '../../Interfaces/ITooltipStatus';
import { useStore } from '../../store';
import { useMemo } from 'react';
import { MetricTypes, RegularDashBoardStatisticModelFragment } from '../../generated/graphql';
import { sortChartsData } from '../../utils/sortObject';
import { findData } from '../../utils/charts/findData';

interface Props {
  state: CategoricalChartState;
  tooltipStatus: { status?: string; barId?: number };
}

interface IOnMouseMove extends Props {
  setFocusBar: React.Dispatch<React.SetStateAction<number | undefined>>;
}

interface IOnClick extends IOnMouseMove {
  setTooltipStatus: React.Dispatch<React.SetStateAction<ITooltipStatus>>;
}

export const onMouseMoveHandler = ({ state, tooltipStatus, setFocusBar }: IOnMouseMove) => {
  if (state !== null) {
    if (tooltipStatus.status === 'hover') {
      if (state.isTooltipActive) {
        setFocusBar(state.activeTooltipIndex);
      } else {
        setFocusBar(undefined);
      }
    }
  }
};

export const onClickHandler = ({ state, tooltipStatus, setFocusBar, setTooltipStatus }: IOnClick) => {
  if (state !== null) {
    if (tooltipStatus.barId === state.activeTooltipIndex) {
      setTooltipStatus(chartHover(state));
    } else {
      setTooltipStatus(chartClick(state));
    }
    setFocusBar(state.activeTooltipIndex);
  }
};

export const useTypes = ({ facility, group, all }: { facility: MetricTypes; group: MetricTypes; all: MetricTypes }) => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  return useMemo(() => {
    if (trendsFacilityId !== 'all') {
      return [facility];
    }
    if (trendsGroupId !== 'all') {
      return [group];
    }
    return [all];
  }, [all, facility, group, trendsFacilityId, trendsGroupId]);
};

export const useGetMetrics = ({
  getMetrics,
  facilityMetricType,
  facilityGroupMetricType,
  allMetricType,
}: {
  getMetrics?: RegularDashBoardStatisticModelFragment[] | null;
  facilityMetricType: MetricTypes;
  facilityGroupMetricType: MetricTypes;
  allMetricType: MetricTypes;
}) => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  return useMemo(() => {
    if (getMetrics) {
      if (trendsFacilityId !== 'all') {
        return sortChartsData(findData(facilityMetricType, getMetrics));
      }
      if (trendsGroupId !== 'all') {
        return sortChartsData(findData(facilityGroupMetricType, getMetrics));
      }
      return sortChartsData(findData(allMetricType, getMetrics));
    }
  }, [allMetricType, facilityGroupMetricType, facilityMetricType, getMetrics, trendsFacilityId, trendsGroupId]);
};
