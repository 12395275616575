// import jwt from 'jsonwebtoken';
import { gqlQuery } from '../utils/gqlQuery';
import { promiseTimeout } from '../utils/promiseTimeout';

const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';

export const getTokens = () => ({
  accessToken: localStorage.getItem(ACCESS_TOKEN) || '',
  refreshToken: localStorage.getItem(REFRESH_TOKEN) || '',
});

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const setTokens = ({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken: string;
}) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

export const removeTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

// export const accessTokenIsValid = (token: string) => {
//   const payload: any = jwt.decode(token);
//   const expUnix = payload.exp * 1000;
//   const nowUnix = new Date().getTime();
//   return expUnix - nowUnix > 0;
// };

const REFRESH_QUERY = `
  query refreshToken($token: String!) {
    refreshToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`;

const refreshQuery = async (refreshToken: string) => {
  const query = await gqlQuery(
    `${process.env.REACT_APP_PUBLIC_DOMAIN}/graphql`,
    REFRESH_QUERY,
    {
      refreshToken,
    }
  );

  if (query.errors) {
    console.error(query.errors);
    return;
  }

  return query.data.refresh;
};

class TokenRefresh {
  private fetching = false;
  private fetchStart = false;
  private refreshToken = '';
  private queue: any = [];

  constructor() {
    this.runFetchInterval();
  }

  public async refresh(refreshToken: string) {
    if (!this.fetching) {
      this.fetching = true;
      this.refreshToken = refreshToken;
    }

    return new Promise(resolve => this.queue.push(resolve));
  }

  private async runFetchInterval() {
    setInterval(async () => {
      if (!this.fetchStart && this.fetching && this.refreshToken) {
        this.fetchStart = true;

        const refreshData = await refreshQuery(this.refreshToken);

        this.queue.forEach((resolve: (arg0: any) => any) =>
          resolve(refreshData)
        );
        this.fetchStart = false;
        this.fetching = false;
        this.refreshToken = '';
      }
    }, 500);
  }
}

const tokenRefresh = new TokenRefresh();

const refresh = async (refreshToken: string) => {
  const refreshData: any = await tokenRefresh.refresh(refreshToken);

  if (refreshData) {
    setTokens(refreshData);
    return refreshData.accessToken;
  }

  removeTokens();
  return '';
};

//For what?
export const getAccessTokenAsync = async () => {
  const { accessToken, refreshToken } = getTokens();
  if (!accessToken) {
    return accessToken;
  }

  if (!refreshToken) {
    removeTokens();
    return '';
  }

  return promiseTimeout(3000, refresh(refreshToken))
    .then(newToken => newToken)
    .catch(() => {
      removeTokens();
      return '';
    });
};
