import { useMemo } from 'react';
import { RegularUserShiftsFragment, ShiftStage } from 'src/generated/graphql';

const useShiftsWithoutCanceled = (userShifts?: RegularUserShiftsFragment[]) =>
  useMemo(
    () =>
      userShifts?.filter(shift => {
        const { stage, isProbation } = shift;
        return (
          ![ShiftStage.Refused, ShiftStage.Ineligible].includes(stage) &&
          !isProbation
        );
      }),
    [userShifts]
  );

export default useShiftsWithoutCanceled;
