import { useCallback, useMemo, useState } from 'react';

import { FilterPeriod, MetricTypes, useGetMetricsQuery } from '../../../generated/graphql';
import { getTimePeriodCharts } from '../../../utils/get/getTimeePeriodCharts';
import { chartTimes } from '../../../utils/lists/chartTimes';
import SimpleSelect from '../../ui/Select';
import { sortChartsData } from '../../../utils/sortObject';
import { lastTimeLength } from '../../../utils/charts/lastTimeLength';
import { ITooltipStatus } from '../../../Interfaces/ITooltipStatus';
import CustomBarChart from '../CustomBarChart';
import { findData } from '../../../utils/charts/findData';

const UserRegistrationChart = () => {
  const [time, setTime] = useState<FilterPeriod>(FilterPeriod.Day);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });
  const [focusBar, setFocusBar] = useState<undefined | number>(undefined);

  const { data } = useGetMetricsQuery({
    variables: {
      input: {
        filterPeriod: time,
        types: [MetricTypes.Registrations],
      },
    },
  });

  const handleTimeChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setTime(value as FilterPeriod);
  }, []);

  const res = useMemo(() => {
    if (data?.getMetrics) {
      return sortChartsData(findData(MetricTypes.Registrations, data?.getMetrics));
    }
  }, [data?.getMetrics]);

  const sortedData = useMemo(() => sortChartsData(res), [res]);
  return (
    <div className="shadow-smena rounded-lg p-5 col-span-1">
      <div className="flex justify-between items-center">
        <span className="Subtitle1 text-smena_text-secondary">Регистрации пользователей</span>
        <div className="flex">
          <SimpleSelect
            divClassName="chart-filter"
            label=""
            onChange={handleTimeChange}
            value={time}
            sort={false}
            name="time"
            options={chartTimes}
          />
        </div>
      </div>
      <div className="flex gap-x-1 items-end mt-5 mb-2">
        <span className="ChartNumber text-smena_text">{lastTimeLength(time, sortedData)}</span>
        <span className="Caption text-smena_text-secondary">за {getTimePeriodCharts(time)}</span>
      </div>
      <div className="chartBlock">
        <CustomBarChart
          setFocusBar={setFocusBar}
          fillColorOpacity="#CCDEFF"
          fillColor="#397DFF"
          tooltipStatus={tooltipStatus}
          setTooltipStatus={setTooltipStatus}
          formatter="Регистраций:"
          focusBar={focusBar}
          res={res}
          time={time}
        />
      </div>
    </div>
  );
};
export default UserRegistrationChart;
