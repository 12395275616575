import { isInt } from '../isInt';
import { timeObject2 } from '../timeObject';

interface ITooltipContent {
  duration: { hours: number; minutes: number };
  moderation: any;
  value?: number | null;
  shiftHourly: boolean;
  unitSymbol: string;
  fact: boolean;
  shiftResultValue?: number | null;
}
export const tooltipContentFn = ({
  duration,
  moderation,
  value,
  shiftHourly,
  unitSymbol,
  fact,
  shiftResultValue,
}: ITooltipContent) => {
  if (shiftHourly) {
    return `${timeObject2(duration).hours}:${
      timeObject2(duration).minutes
    } ч. по ${fact ? 'факту' : 'плану'}`;
  } else {
    const fact = moderation ? (value ? true : false) : false;
    return `${fact ? isInt(shiftResultValue) : isInt(value)}${unitSymbol} по ${
      fact ? 'факту' : 'плану'
    }`;
  }
};
