import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router';
import dayjs from 'dayjs';

import {
  useUpdateLeadMutation,
  useLeadQuery,
  LeadsDocument,
  LeadsQueryVariables,
  LeadStatus,
} from '../../generated/graphql';

import MainLayout from '../../components/Layout/main';
import SimpleCheckbox from '../../components/ui/Checkbox';
import { errorToast, successToast } from '../../utils/notify';
import { errorHandler } from '../../utils/errorHandler';
import { UserFullName } from 'src/utils/userFullName';
import DatePickerInput from '../../components/ui/Pickers/DatePickerInput';

const UpdateLead: React.FC = () => {
  const router = useParams();
  let leadId = '';

  if (typeof router.leadId === 'string') {
    leadId = router.leadId;
  }

  const leadQueryConfig: {
    query: typeof LeadsDocument;
    variables: LeadsQueryVariables;
  } = {
    query: LeadsDocument,
    variables: {
      input: {
        dateFrom: String(dayjs().subtract(1, 'year').startOf('year').valueOf()),
        dateTo: String(dayjs().endOf('month').valueOf()),
        page: 1,
        searchQuery: '',
        sortBy: null,
        sortWay: null,
      },
    },
  };

  const { data } = useLeadQuery({
    variables: {
      id: leadId,
    },
  });

  const lead = data?.lead;
  const title = useMemo(() => {
    if (lead) {
      return UserFullName(lead.user);
    }
    return undefined;
  }, [lead]);

  const [updateLeadMutation, { loading }] = useUpdateLeadMutation({
    awaitRefetchQueries: true,
    refetchQueries: [leadQueryConfig],
  });

  return (
    <MainLayout
      title={`Лид ${title}`}
      bg="bg-smena_bb-background_base"
      breadCrumbs={[
        { path: '/leads', name: 'Акции' },
        { path: '/leads' + leadId, name: 'Обработка лида' },
      ]}
    >
      <Formik
        enableReinitialize
        initialValues={{
          date: lead?.date ? dayjs(lead?.date) : dayjs(),
          id: lead?.id || '',
          isProcessed: Boolean(lead?.isProcessed),
          status: lead?.status
            ? lead?.status === LeadStatus.Pending
              ? LeadStatus.Pending
              : lead?.status === LeadStatus.Accepted
              ? LeadStatus.Accepted
              : LeadStatus.Rejected
            : '',
        }}
        onSubmit={values => {
          updateLeadMutation({
            variables: {
              input: { ...values, date: values.date?.valueOf().toString() },
            },
          })
            .then(response => {
              if (response.data?.updateLead) {
                successToast('Вы успешно внесли изменения');
              }
            })
            .catch(e => errorToast(errorHandler(e)));
        }}
      >
        {({ values, handleChange, setFieldValue, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="grid xs:grid-cols-12 grid-cols-3 gap-x-6">
              <div className="xs:col-span-7 col-span-3 gap-y-5 p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col">
                <span className="Subtitle1 text-smena_text-secondary pt-5">Информация:</span>
                <div className="flex xs:flex-row flex-col gap-5">
                  <div className="vacancyInput inputHeight">
                    <label htmlFor="status" className="label-primary">
                      Статус
                    </label>
                    <select
                      name="status"
                      className="select-primary"
                      value={values.status}
                      onChange={handleChange}
                      disabled={!values.isProcessed}
                    >
                      <option value="PENDING">В ожидании</option>
                      <option value="ACCEPTED">Вышел на работу</option>
                      <option value="REJECTED">Отказал</option>
                    </select>
                  </div>
                  <DatePickerInput
                    //check
                    label="Дата обработки"
                    value={values.date}
                    placeholder="За весь период"
                    onChange={updateDate => setFieldValue('date', updateDate)}
                  />
                  <SimpleCheckbox
                    divClassName="flex items-center"
                    checked={values.isProcessed}
                    label="Обработан"
                    name="isProcessed"
                    onChange={e => {
                      handleChange(e);
                      return values.isProcessed ? setFieldValue('status', LeadStatus.Pending) : '';
                    }}
                    required={false}
                  />
                </div>
                <div className="flex items-center gap-x-1">
                  <span className="Subtitle2 text-smena_text">Кто привел:</span>
                  <span className="Body2 text-smena_text">{UserFullName(lead?.user?.referrer)}</span>
                </div>
              </div>
              <div className="col-start-1 xs:col-span-7 col-span-3 justify-self-end pb-5">
                <button type="submit" disabled={loading} className="btn-primary_big mt-5">
                  <span>{loading ? 'Загрузка' : 'Сохранить'}</span>
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdateLead;
