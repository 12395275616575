import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { Progress } from 'antd';
import dayjs from 'dayjs';

import Across from 'src/Assets/icons/Across';
import Popup from 'src/components/ui/Popup';
import {
  RegularZayavkaPlanFragment,
  RegularZayavkaProgressFragment,
  useCancelZayavkaByManagerMutation,
  useCancelZayavkaMutation,
  ZayavkaStage,
} from 'src/generated/graphql';
import { useStore } from 'src/store';
import { roles, RU_DATE } from 'src/utils/constVariables';
import { errorHandler } from 'src/utils/errorHandler';
import { zayavkyConfigFn } from 'src/utils/graphqlConfigs/zayavkiConfig';
import { errorToast, successToast } from 'src/utils/notify';
import RequestStatus from 'src/components/Request/Status';
import Edit from 'src/Assets/icons/edit';
import GarbageArrowless from 'src/Assets/icons/GarbageArrowless';
import GarbageArrow from 'src/Assets/icons/GarbageArrow';
// import { percentColor, progressPercent } from '../../../utils/progressPercentHelper';

interface IRequestTableRow {
  request: RegularZayavkaProgressFragment;
  columns: {
    className?: string;
    style?: any;
  }[];
}

const RequestTableRow: React.FC<IRequestTableRow> = ({ request, columns }) => {
  const [showCancelPopup, setShowCancelPopup] = useState(false);

  const { positions, facilities, me } = useStore();

  const router = useParams();
  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }

  const requestConfig = zayavkyConfigFn(facilityId);

  const [cancelZayavka, { loading: loadingCancelZayavka }] = useCancelZayavkaMutation({
    awaitRefetchQueries: true,
    refetchQueries: [requestConfig],
  });

  const { id, dateTo, dateFrom, number, status } = request;

  const requestPositions = request.plans
    .map((requestPlan: RegularZayavkaPlanFragment, index: number) => {
      if (index < 3) {
        return positions?.find(position => position.id === requestPlan.positionId)?.name;
      }
      return null;
    })
    .filter(Boolean);

  const counter = () => {
    if (request.plans.length > 3) {
      return request.plans.length - 3;
    }
    return null;
  };

  const [cancelZayavkaByManager, { loading: loadingCancelZayavkaByManager }] = useCancelZayavkaByManagerMutation({
    variables: {
      id,
    },
  });

  const cancelZayavkaByManagerHandler = () => {
    cancelZayavkaByManager()
      .then(() => {
        successToast('Заявка заявка предложена к отклонению');
      })
      .catch(error => errorToast(errorHandler(error)))
      .finally(() => {
        setShowCancelPopup(false);
      });
  };

  const cancelZayavkaHandler = (id: string) => {
    cancelZayavka({
      variables: {
        id,
      },
    })
      .then(() => {
        successToast('Заявка отклонена');
      })
      .catch(error => errorToast(errorHandler(error)))
      .finally(() => setShowCancelPopup(false));
  };

  const requestFacility = (facilityId: string) => {
    return facilities?.find(facility => facility.id === facilityId);
  };

  const meManager = me?.role === roles.ClientManager;

  // const shiftsProgress = progressPercent(request.shiftsPlanCount, request.shiftsScheduleCount);
  // const shiftsStroke = percentColor(shiftsProgress);
  // const hoursProgress = progressPercent(request.hoursPlanCount, request.hoursScheduleCount);
  // const hoursStroke = percentColor(hoursProgress);

  return (
    <React.Fragment>
      <div
        className="grid table__row w-fit overflow-x-auto"
        style={{
          gridTemplateColumns: columns
            .map(column => 'minmax(' + column.style.minWidth + 'px, ' + column.style.width + 'px)')
            .join(' '),
          columnGap: 10,
        }}
        key={id}
      >
        <div className={columns[0].className}>
          <Link to={id}>{number}</Link>
        </div>
        <div className={columns[1].className}>
          {dayjs(dateFrom).format(RU_DATE) + ' - ' + dayjs(dateTo).format(RU_DATE)}
        </div>
        <div className={columns[2].className}>{requestFacility(request.facilityId)?.facilityGroup?.name}</div>
        <div className={columns[3].className}>{requestFacility(request.facilityId)?.name}</div>
        <div className={columns[4].className}>
          <span>{`${requestPositions.join(', ')}${counter() ? ', ' : ''}`}</span>
          <span className="text-primary">{counter() ? `+${counter()}` : ''}</span>
        </div>
        {/*<div className={columns[5].className}>*/}
        {/*  <Progress*/}
        {/*    type="circle"*/}
        {/*    percent={shiftsProgress}*/}
        {/*    width={16}*/}
        {/*    showInfo={false}*/}
        {/*    strokeWidth={10}*/}
        {/*    trailColor="#E0E0E0"*/}
        {/*    strokeColor={shiftsStroke}*/}
        {/*  />*/}
        {/*  <div>*/}
        {/*    <span>{request.shiftsScheduleCount}</span>*/}
        {/*    <span>/</span>*/}
        {/*    <span>{request.shiftsPlanCount}</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className={columns[6].className}>*/}
        {/*  <Progress*/}
        {/*    type="circle"*/}
        {/*    percent={hoursProgress}*/}
        {/*    width={16}*/}
        {/*    showInfo={false}*/}
        {/*    strokeWidth={10}*/}
        {/*    trailColor="#E0E0E0"*/}
        {/*    strokeColor={hoursStroke}*/}
        {/*  />*/}
        {/*  <div>*/}
        {/*    <span>{request.hoursScheduleCount}</span>*/}
        {/*    <span>/</span>*/}
        {/*    <span>{request.hoursPlanCount}</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={columns[5].className}>
          <RequestStatus request={request} />
        </div>
        <div className={columns[6].className}>
          <div className="flex items-center">
            <Link to={`${id}/edit`} className="text-smena_text mr-4 transform hover:scale-110">
              <Edit />
            </Link>
            <button
              onClick={() => {
                setShowCancelPopup(true);
              }}
            >
              {status === ZayavkaStage.Canceled ? <GarbageArrow /> : <GarbageArrowless />}
            </button>
          </div>
        </div>
      </div>
      {showCancelPopup && (
        <Popup setShowPopup={setShowCancelPopup} showPopup={showCancelPopup}>
          <div
            className="rounded-lg bg-smena_white flex flex-col gap-y-6 p-6 w-[400px] h-"
            style={{ width: '400px', height: '185px' }}
          >
            <div className="flex justify-between">
              <h4 className="H4">Отменить заявку?</h4>
              <button onClick={() => setShowCancelPopup(false)}>
                <Across />
              </button>
            </div>
            <div>Вы уверены, что хотите отменить заявку</div>
            <div className="flex items-center justify-end gap-x-6">
              <button className="btn-stroke" onClick={() => setShowCancelPopup(false)}>
                Назад
              </button>
              <button
                className="btn-primary"
                disabled={loadingCancelZayavka || loadingCancelZayavkaByManager}
                onClick={() => {
                  if (meManager) {
                    cancelZayavkaByManagerHandler();
                  } else {
                    cancelZayavkaHandler(id);
                  }
                }}
              >
                Отменить
              </button>
            </div>
          </div>
        </Popup>
      )}
    </React.Fragment>
  );
};
export default RequestTableRow;
