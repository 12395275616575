import clsx from 'clsx';

import SelectArrow from '../../../../../../Assets/icons/SelectArrow';
import { reasons } from '../../../../../../utils/lists/reasons';
import SimpleSelect from '../../../../../../components/ui/Select';

interface IShiftApproveBlock {
  toPayment: boolean;
  sendStatus: boolean;
  radioHandler: (value: string) => void;
  comment: string;
  setComment: (value: string) => void;
  showComment: boolean;
  setShowComment: (value: boolean) => void;
  reason: string;
  setReason: (value: string) => void;
}

const ShiftApproveBlock: React.FC<IShiftApproveBlock> = ({
  toPayment,
  radioHandler,
  sendStatus,
  comment,
  setComment,
  showComment,
  setShowComment,
  reason,
  setReason,
}) => {
  return (
    <div className="sidebar__shift gap-y-1">
      <span className="Caption">Статус смены</span>
      <label
        className={`radio-block ${
          toPayment ? 'bg-smena_green-extra_light border-smena_green-mark' : ''
        }`}
      >
        <input
          type="radio"
          name="status"
          className="approve_radio approve_approve"
          id="toPay"
          value="pay"
          disabled={sendStatus}
          checked={toPayment}
          onChange={({ target: { value } }) => {
            radioHandler(value);
          }}
        />
        К оплате
      </label>
      <label
        className={`radio-block ${
          !toPayment &&
          'bg-smena_red-extra_light border-smena_red shift__radio-bad'
        }`}
      >
        <input
          type="radio"
          name="status"
          id="nonpay"
          className="approve_radio approve_reject"
          disabled={sendStatus}
          value=""
          checked={toPayment === false}
          onChange={({ target: { value } }) => {
            radioHandler(value);
          }}
        />
        Без оплаты
      </label>
      <div className="col-span-4">
        <div
          className="flex items-center gap-x-2 cursor-pointer mt-3"
          onClick={() => {
            setShowComment(!showComment);
          }}
        >
          <span className="Caption-numbers text-primary">Комментарий</span>
          <span
            className={clsx(
              'transform transition-transform',
              showComment && 'rotate-180'
            )}
          >
            <SelectArrow className="text-primary" />
          </span>
        </div>

        {showComment && (
          <div className="flex flex-col gap-y-5">
            <SimpleSelect
              divClassName=""
              label="Причина редактирования"
              onChange={({ target: { value } }) => setReason(value)}
              value={reason}
              name="reasons"
              disabled={sendStatus}
              disabledOption={'Все причины'}
              options={reasons}
            />
            <div>
              <label htmlFor="comment" className="label-primary">
                Комментарий (если нужно)
              </label>
              <textarea
                className="textarea-primary"
                name="comment"
                id="comment"
                placeholder="Оставьте комментарий"
                disabled={sendStatus}
                value={comment}
                onChange={({ target: { value } }) => {
                  setComment(value);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShiftApproveBlock;
