import dayjs from 'dayjs';

import MainSidebar from 'src/components/ui/Sidebar';
import { useStore } from 'src/store';
// import Magnifier from 'src/Assets/icons/magnifier';
import { ISO_DATE, RU_DATE } from 'src/utils/constVariables';
import { positionName } from 'src/utils/filters/filtersName';
import { scheduleTypes } from 'src/utils/lists/scheduleTypes';
import {
  useAssignZayavkaScheduleMutation,
  useDeleteWorkerFromZayavkaScheduleMutation,
  useGetZayavkaLazyQuery,
  useGetWorkersForZayavkaScheduleLazyQuery,
} from 'src/generated/graphql';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { successToast, errorToast } from 'src/utils/notify';
import { errorHandler } from 'src/utils/errorHandler';
import UserAtSidebar from '../UserAtSidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Popup from 'src/components/ui/Popup';
import Across from 'src/Assets/icons/Across';
import { userLastnameAndInitials } from 'src/utils/userLastnameAndInitials';
import { nanoid } from 'nanoid';
import { getZayavkyByFacilityConfigFn } from 'src/utils/graphqlConfigs/getZayavkyByFacilityConfig';

interface ICreateNewSchedule {
  showGraphicSidebar: boolean;
  setShowGraphicSidebar: (value: boolean) => void;
}

const ScheduleInfo: React.FC<ICreateNewSchedule> = ({ showGraphicSidebar, setShowGraphicSidebar }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { requestSchedule, positions, userIntersect } = useStore();

  const router = useParams();

  const [params] = useSearchParams();
  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }

  const monthParam = params.get('date');

  const month = monthParam || dayjs().format(ISO_DATE);

  const [loadData, { data }] = useGetZayavkaLazyQuery();

  const currentZayavka = data?.getZayavka;

  const [loadUserIntersectData, { data: usersIntersectData }] = useGetWorkersForZayavkaScheduleLazyQuery();

  const users = usersIntersectData?.getWorkersForZayavkaSchedule;

  const title = `График ${dayjs(requestSchedule?.dateFrom).format(RU_DATE)} - ${dayjs(requestSchedule?.dateTo).format(
    RU_DATE
  )}`;

  const zayavkaConfig = getZayavkyByFacilityConfigFn(facilityId, month);

  const [assignZayavkaSchedule] = useAssignZayavkaScheduleMutation({
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfig],
  });
  const [deleteWorkerFromZayavkaSchedule] = useDeleteWorkerFromZayavkaScheduleMutation({
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfig],
  });

  const scheduleHasWorkers = useMemo(() => {
    return requestSchedule?.zayavkaShift?.filter(shift => shift.shiftId);
  }, [requestSchedule]);

  const closePopupHandler = useCallback(() => {
    setShowPopup(false);
  }, []);

  const deleteWorkerFromZayavkaScheduleHadnler = useCallback(() => {
    if (requestSchedule?.id) {
      deleteWorkerFromZayavkaSchedule({
        variables: {
          id: requestSchedule.id,
        },
      })
        .then(() => successToast('Все исполнители удалены из графика'))
        .catch(error => {
          errorToast(errorHandler(error));
        })
        .finally(closePopupHandler);
    }
  }, [closePopupHandler, deleteWorkerFromZayavkaSchedule, requestSchedule?.id]);

  const assignZayavkaScheduleHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (requestSchedule) {
      if (userIntersect) {
        return await assignZayavkaSchedule({
          variables: {
            workerId: userIntersect.id,
            zayavkaScheduleId: requestSchedule.id,
          },
        })
          .then(() => successToast('Пользователь назначен на график'))
          .catch(error => errorToast(errorHandler(error)));
      }
      return errorToast('Выберите исполнителя');
    }
  };

  const uniqueUsers = useMemo(
    () => [...new Map(scheduleHasWorkers?.map(worker => [worker.shift?.userId, worker])).values()],
    [scheduleHasWorkers]
  );

  useEffect(() => {
    if (requestSchedule?.zayavkaId) {
      loadData({
        variables: {
          id: requestSchedule.zayavkaId,
        },
      });
    }
  }, [loadData, requestSchedule?.zayavkaId]);

  useEffect(() => {
    if (requestSchedule?.id) {
      loadUserIntersectData({
        variables: {
          zayavkaScheduleId: requestSchedule.id,
        },
      });
    }
  }, [loadUserIntersectData, requestSchedule?.id]);

  return (
    <>
      <MainSidebar title={title} show={showGraphicSidebar} setShow={setShowGraphicSidebar}>
        <form className="flex flex-col gap-y-2" onSubmit={assignZayavkaScheduleHandler}>
          <div className="flex gap-x-1 Subtitle1">
            <span>Номер заявки:</span>
            <Link to={`/requests/${currentZayavka?.id}`} className="text-primary">
              {currentZayavka?.number}
            </Link>
          </div>
          <div className="flex flex-col gap-y-6">
            <h4 className="H4">{positionName(positions, requestSchedule?.positionId)}</h4>
            <div className="flex gap-x-1 Subtitle2">
              <span>{scheduleTypes.find(type => type.id === requestSchedule?.dayInterleaving)?.name}</span>
              <span>·</span>
              <span>
                {dayjs(requestSchedule?.dateFrom).format('HH:mm')} – {dayjs(requestSchedule?.dateTo).format('HH:mm')}
              </span>
            </div>
            {/* {!Boolean(uniqueUsers.length) ? (
              <div className="relative">
                <span className="absolute top-0 left-0 transform translate-x-2/4 translate-y-2/4">
                  <Magnifier />
                </span>
                <input
                  className="input-primary pl-8"
                  onChange={() => {}}
                  type="search"
                  name="search"
                  id="search"
                  placeholder="Поиск"
                />
              </div>
            ) : (
              
            )} */}
            <div className="grid scheduleHasWorkers gap-x-1">
              <span className="Subtitle1">Исполнители:</span>
              <div className="col-span-1">
                {uniqueUsers.length ? (
                  uniqueUsers.map((worker, index, array) => (
                    <React.Fragment key={nanoid()}>
                      <Link className="Subtitle1 text-primary" to={`/users/${worker.shift?.userId}`}>
                        {userLastnameAndInitials(worker.shift?.user)}
                      </Link>
                      {index !== array.length - 1 && <span className="Subtitle1 text-primary">, </span>}
                    </React.Fragment>
                  ))
                ) : (
                  <span className="Subtitle1 text-smena_text-secondary">отсутствуют</span>
                )}
              </div>
            </div>
            {!uniqueUsers.length && users && users.length ? (
              <div className="border-b border-smena_gray-30">
                <div className="graphicSerchUserTable p-4 border-t border-b border-smena_gray-30">
                  <span className="Table-H1 text-smena_text-secondary">Исполнитель</span>
                  <span className="Table-H1 text-smena_text-secondary">Занятость</span>
                </div>
                {users?.map(user => (
                  <UserAtSidebar key={user.id} user={user} />
                ))}
              </div>
            ) : null}

            <div className="flex flex-col gap-y-2 mb-5">
              {!Boolean(uniqueUsers.length) ? (
                <div className="flex justify-end">
                  <button className="btn-primary" type="submit">
                    Назначить на весь график
                  </button>
                </div>
              ) : (
                <div className="flex justify-end">
                  <button className="btn-reject_secondary" type="button" onClick={() => setShowPopup(true)}>
                    Освободить весь график
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      </MainSidebar>
      {showPopup && (
        <Popup setShowPopup={setShowPopup} showPopup={showPopup}>
          <div className="flex flex-col bg-smena_white rounded-lg shadow-smena p-6 gap-y-6">
            <div className="flex justify-between">
              <h4 className="H4">Снять со всего графика</h4>
              <button onClick={closePopupHandler}>
                <Across />
              </button>
            </div>
            <div className="Body1">Вы уверены, что хотите снять исполнителя со всего графика?</div>
            <div className="flex justify-end gap-x-6">
              <button className="btn-stroke" type="button" onClick={closePopupHandler}>
                Отмена
              </button>
              <button className="btn-reject" type="button" onClick={deleteWorkerFromZayavkaScheduleHadnler}>
                Снять
              </button>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ScheduleInfo;
