import dayjs from 'dayjs';
import { IDifferenceBetweenDaysFn } from '../../Interfaces/IDifferenceBetweenDays';

export const requestDateFn = ({ dateTo, dateFrom }: IDifferenceBetweenDaysFn) => {
  if (dayjs(dateFrom).get('month') !== dayjs(dateTo).get('month')) {
    return `${dayjs(dateFrom).format('MMMM YYYY')} - ${dayjs(dateTo).format('MMMM YYYY')}`;
  }
  return `${dayjs(dateFrom).format('MMMM YYYY')}`;
};

export const breadcrumbsFn = ({ facilityURL, requestIdURL }: { requestIdURL: string; facilityURL: string }) => {
  if (facilityURL) {
    return [
      { path: '/facilities', name: 'Объекты' },
      { path: `/facilities/${facilityURL}`, name: 'Объект' },
      {
        path: `/facilities/${facilityURL}/requests/${requestIdURL}`,
        name: 'Заявка',
      },
    ];
  }
  return [
    { path: '/requests', name: 'Заявки' },
    { path: `/request/${requestIdURL}`, name: 'Заявка' },
  ];
};

export const requestBreadcrumbs = ({ facilityIdURL, requestId }: { facilityIdURL: string; requestId: string }) => {
  if (facilityIdURL) {
    return [
      { path: '/facilities', name: 'Объекты' },
      { path: '/facilities/' + facilityIdURL, name: 'Страница объекта' },
      requestId
        ? {
            path: '/facilities/' + facilityIdURL + '/edit' + requestId,
            name: 'Заявка',
          }
        : {
            path: '/facilities/' + facilityIdURL + '/create',
            name: 'Новая заявка',
          },
    ];
  }
  return [
    {
      path: '/requests',
      name: 'Заявки',
    },
    requestId
      ? {
          path: '/requests/' + requestId + '/edit',
          name: ' Редактирование заявки',
        }
      : {
          path: '/requests/create',
          name: 'Новая заявка',
        },
  ];
};
