export const GoolePlay = () => {
  return (
    <svg
      version="1.1"
      id="Livello_1"
      x="0px"
      y="0px"
      viewBox="0 0 5435.8 1604"
      className="googlePlay"
    >
      <path
        className="st0"
        d="M5234.4,1604h-5033C90.4,1604,0,1513.6,0,1403.5v-1203C0,90,90.4,0,201.4,0h5033c110.9,0,201.4,90,201.4,200.5
	v1203C5435.8,1513.6,5345.3,1604,5234.4,1604z"
      />
      <path
        className="st1"
        d="M5234.4,32.1c93.1,0,169.3,75.7,169.3,168.4v1203c0,92.7-75.7,168.4-169.3,168.4h-5033
	c-93.1,0-169.3-75.7-169.3-168.4v-1203c0-92.7,75.7-168.4,169.3-168.4C201.4,32.1,5234.4,32.1,5234.4,32.1z M5234.4,0h-5033
	C90.4,0,0,90.4,0,200.5v1203C0,1514,90.4,1604,201.4,1604h5033c110.9,0,201.4-90,201.4-200.5v-1203C5435.8,90.4,5345.3,0,5234.4,0z"
      />
      <path
        className="st2"
        d="M2863.6,530.6c-36.3,0-66.9-12.7-91.1-37.7c-24-24.3-37.4-57.8-36.8-92c0-36.5,12.4-67.4,36.8-91.9
	c24.1-25,54.7-37.7,91-37.7c35.9,0,66.5,12.7,91.1,37.7c24.4,25.3,36.8,56.2,36.8,91.9c-0.4,36.6-12.8,67.5-36.8,91.9
	C2930.5,518,2899.9,530.6,2863.6,530.6z M1783.5,530.6c-35.5,0-66.3-12.5-91.5-37.2c-25-24.6-37.7-55.7-37.7-92.4
	s12.7-67.8,37.7-92.4c24.7-24.7,55.5-37.2,91.5-37.2c17.6,0,34.7,3.5,51.1,10.6c16.1,6.9,29.2,16.3,38.9,27.8l2.4,2.9l-27.1,26.6
	l-2.8-3.3c-15.3-18.2-35.8-27.1-62.9-27.1c-24.2,0-45.3,8.6-62.7,25.6c-17.5,17.1-26.4,39.5-26.4,66.6s8.9,49.5,26.4,66.6
	c17.4,17,38.5,25.6,62.7,25.6c25.8,0,47.5-8.6,64.4-25.6c10-10,16.2-24,18.4-41.7h-86.9v-37.4h124.2l0.5,3.4
	c0.9,6.3,1.8,12.8,1.8,18.8c0,34.5-10.4,62.4-31,83C1851.1,518.2,1820.5,530.6,1783.5,530.6z M3219.6,525.3h-38.3L3064,337.6l1,33.8
	v153.8h-38.3V276.7h43.7l1.2,1.9l110.3,176.8l-1-33.7V276.7h38.7V525.3z M2575.8,525.3H2537V314.1h-67.3v-37.4H2643v37.4h-67.3
	V525.3z M2438.1,525.3h-38.7V276.7h38.7V525.3z M2220.6,525.3h-38.7V314.1h-67.3v-37.4h173.3v37.4h-67.3V525.3z M2090.1,524.9
	h-148.4V276.7h148.4v37.4h-109.6v68.2h98.9v37h-98.9v68.2h109.6V524.9z M2800.9,467.2c17.3,17.3,38.3,26,62.7,26
	c25.1,0,45.6-8.5,62.7-26c17-17,25.6-39.3,25.6-66.2s-8.6-49.3-25.5-66.2c-17.3-17.3-38.4-26-62.7-26c-25.1,0-45.6,8.5-62.6,26
	c-17,17-25.6,39.3-25.6,66.2S2784,450.3,2800.9,467.2L2800.9,467.2z"
      />
      <path
        className="st3"
        d="M2732.1,872.4c-94.5,0-171.1,71.7-171.1,170.6c0,98,77.1,170.6,171.1,170.6c94.5,0,171.1-72.2,171.1-170.6
	C2903.2,944.1,2826.6,872.4,2732.1,872.4z M2732.1,1146c-51.7,0-96.2-42.8-96.2-103.4c0-61.5,44.6-103.4,96.2-103.4
	c51.7,0,96.2,41.9,96.2,103.4C2828.4,1103.6,2783.8,1146,2732.1,1146z M2358.8,872.4c-94.5,0-171.1,71.7-171.1,170.6
	c0,98,77.1,170.6,171.1,170.6c94.5,0,171.1-72.2,171.1-170.6C2529.9,944.1,2453.2,872.4,2358.8,872.4z M2358.8,1146
	c-51.7,0-96.2-42.8-96.2-103.4c0-61.5,44.6-103.4,96.2-103.4c51.7,0,96.2,41.9,96.2,103.4C2455,1103.6,2410.5,1146,2358.8,1146z
	 M1914.6,924.5v72.2h173.3c-5.3,40.5-18.7,70.4-39.2,90.9c-25.4,25.4-64.6,53-133.7,53c-106.5,0-189.8-86-189.8-192.5
	s83.3-192.5,189.8-192.5c57.5,0,99.4,22.7,130.5,51.7l51.2-51.2c-43.2-41.4-100.7-73.1-181.3-73.1c-146.1,0-268.7,119-268.7,264.7
	c0,146.1,122.5,264.7,268.7,264.7c78.9,0,138.1-25.8,184.9-74.4c47.7-47.7,62.8-115,62.8-169.3c0-16.9-1.3-32.1-4-45h-244.6
	C1914.6,923.6,1914.6,924.5,1914.6,924.5z M3731.5,980.7c-14.3-38.3-57.5-108.7-146.1-108.7c-87.8,0-160.8,69.1-160.8,170.6
	c0,95.8,72.2,170.6,169.3,170.6c78,0,123.4-47.7,142.1-75.7l-57.9-38.8c-19.2,28.5-45.9,47.2-83.8,47.2c-38.3,0-65.1-17.4-82.9-51.7
	l228.1-94.5C3739.5,999.8,3731.5,980.7,3731.5,980.7z M3498.9,1037.7c-1.8-65.9,51.2-99.4,89.1-99.4c29.9,0,54.8,14.7,63.3,36.1
	L3498.9,1037.7z M3313.6,1203h74.9V701.8h-74.9V1203z M3190.6,910.3h-2.7c-16.9-20.1-49-38.3-90-38.3
	c-85.1,0-163.5,74.9-163.5,171.1c0,95.8,78,169.8,163.5,169.8c40.5,0,73.1-18.3,90-38.8h2.7v24.5c0,65.1-34.8,100.2-90.9,100.2
	c-45.9,0-74.4-33-86-60.6l-65.1,27.2c18.7,45,68.6,100.7,151,100.7c87.8,0,162.2-51.7,162.2-177.8V882.2h-70.8v28.1
	C3191.1,910.3,3190.6,910.3,3190.6,910.3z M3104.6,1146c-51.7,0-94.9-43.2-94.9-102.9c0-60.2,43.2-103.8,94.9-103.8
	c51.2,0,90.9,44.1,90.9,103.8C3196,1102.8,3155.9,1146,3104.6,1146z M4082.2,701.8h-179.1V1203h74.9v-189.8h104.3
	c82.9,0,164.4-60.1,164.4-155.5S4165.5,701.8,4082.2,701.8z M4084.4,943.2h-106.5v-172h106.5c56.1,0,87.8,46.3,87.8,86
	C4172.2,896.5,4140.1,943.2,4084.4,943.2z M4546.9,871.5c-54.4,0-110.5,24.1-133.7,76.6l66.4,27.6c14.3-27.6,40.5-37,68.2-37
	c38.8,0,78,23.2,78.9,64.6v5.3c-13.4-7.6-42.8-19.2-78-19.2c-71.7,0-144.4,39.2-144.4,112.7c0,67.3,58.8,110.5,124.3,110.5
	c50.3,0,78-22.7,95.3-49h2.7v38.8h72.2v-192C4698.8,921,4632.4,871.5,4546.9,871.5z M4537.5,1146c-24.5,0-58.8-12-58.8-42.8
	c0-38.8,42.8-53.5,79.3-53.5c33,0,48.6,7.1,68.2,16.9C4620.8,1111.6,4582.8,1145.6,4537.5,1146z M4962.2,882.2l-86,217.4h-2.7
	l-89.1-217.4h-80.6l133.7,303.9l-76.2,168.9h78L5045,882.2C5045,882.2,4962.2,882.2,4962.2,882.2z M4288,1203h74.9V701.8H4288V1203z
	"
      />
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="1682.1077"
        y1="1339.4783"
        x2="1624.2924"
        y2="1309.6345"
        gradientTransform="matrix(11.64 0 0 -22.55 -18705.5957 30554.3691)"
      >
        <stop offset="0" style={{ stopColor: '#00A0FF' }} />
        <stop offset="6.569999e-03" style={{ stopColor: '#00A1FF' }} />
        <stop offset="0.2601" style={{ stopColor: '#00BEFF' }} />
        <stop offset="0.5122" style={{ stopColor: '#00D2FF' }} />
        <stop offset="0.7604" style={{ stopColor: '#00DFFF' }} />
        <stop offset="1" style={{ stopColor: '#00E3FF' }} />
      </linearGradient>
      <path
        className="st4"
        d="M418.4,302.1c-11.6,12.5-18.3,31.6-18.3,56.6v886.7c0,25,6.7,44.1,18.7,56.1l3.1,2.7l496.8-496.8v-11.1
	L421.5,299.4C421.5,299.4,418.4,302.1,418.4,302.1z"
      />
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="1712.6624"
        y1="1274.8376"
        x2="1606.5613"
        y2="1274.8376"
        gradientTransform="matrix(9.145 0 0 -7.7 -14305.5381 10618.251)"
      >
        <stop offset="0" style={{ stopColor: '#FFE000' }} />
        <stop offset="0.4087" style={{ stopColor: '#FFBD00' }} />
        <stop offset="0.7754" style={{ stopColor: '#FFA500' }} />
        <stop offset="1" style={{ stopColor: '#FF9C00' }} />
      </linearGradient>
      <path
        className="st5"
        d="M1084,973.5L918.3,807.8v-11.6L1084,630.5l3.6,2.2l196,111.4c56.1,31.6,56.1,83.8,0,115.8l-196,111.4
	C1087.6,971.3,1084,973.5,1084,973.5z"
      />
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="1707.4414"
        y1="1290.0475"
        x2="1646.682"
        y2="1211.2225"
        gradientTransform="matrix(15.02 0 0 -11.5775 -24650.2285 15829.6484)"
      >
        <stop offset="0" style={{ stopColor: '#FF3A44' }} />
        <stop offset="1" style={{ stopColor: '#C31162' }} />
      </linearGradient>
      <path
        className="st6"
        d="M1087.6,971.3L918.3,802l-499.9,499.9c18.3,19.6,49,21.8,83.3,2.7L1087.6,971.3"
      />
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1="1660.6357"
        y1="1365.6676"
        x2="1687.767"
        y2="1330.4501"
        gradientTransform="matrix(15.02 0 0 -11.5715 -24650.2285 15809.9922)"
      >
        <stop offset="0" style={{ stopColor: '#32A071' }} />
        <stop offset="6.850000e-02" style={{ stopColor: '#2DA771' }} />
        <stop offset="0.4762" style={{ stopColor: '#15CF74' }} />
        <stop offset="0.8009" style={{ stopColor: '#06E775' }} />
        <stop offset="1" style={{ stopColor: '#00F076' }} />
      </linearGradient>
      <path
        className="st7"
        d="M1087.6,632.7L501.7,299.9c-34.3-19.6-65.1-16.9-83.3,2.7L918.3,802L1087.6,632.7z"
      />
      <path
        className="st8"
        d="M1084,967.7l-581.9,330.6c-32.5,18.7-61.5,17.4-80.2,0.4l-3.1,3.1l3.1,2.7c18.7,16.9,47.7,18.3,80.2-0.4
	L1088,971.3C1088,971.3,1084,967.7,1084,967.7z"
      />
      <path
        className="st9"
        d="M1283.6,854.1l-200.1,113.6l3.6,3.6l196-111.4c28.1-16,41.9-37,41.9-57.9C1323.3,821.2,1309,839.4,1283.6,854.1
	z"
      />
      <path
        className="st10"
        d="M501.7,305.7l781.9,444.2c25.4,14.3,39.7,33,41.9,52.1c0-20.9-13.8-41.9-41.9-57.9L501.7,299.9
	c-56.1-32.1-101.6-5.3-101.6,58.8v5.8C400.1,300.3,445.6,274,501.7,305.7z"
      />
    </svg>
  );
};
