import {
  UserShiftsDocument,
  UserShiftsQueryVariables,
} from '../../generated/graphql';

interface IUserShiftsQueryConfig {
  query: typeof UserShiftsDocument;
  variables: UserShiftsQueryVariables;
}

export const userShiftsQueryConfigFn = (
  dateFrom: string,
  dateTo: string,
  userId?: string
): IUserShiftsQueryConfig => ({
  query: UserShiftsDocument,
  variables: {
    input: {
      userId,
      dateFrom,
      dateTo,
    },
  },
});
