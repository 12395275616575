import clsx from 'clsx';

const Tooltip = ({
  content,
  style,
  placement,
  blockStyle,
  blockClassName,
  spanClassName,
}: {
  content: string;
  blockStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  placement?: string;
  blockClassName?: string;
  spanClassName?: string;
}) => {
  const placementFn = (placement: string) => {
    if (placement === 'bottom-left') {
      return 'absolute top-full left-0 transform rounded-lg translate-y-3 bg-smena_bb-background_light tooltip tooltip_bottom-left';
    }
    if (placement === 'top-left') {
      return 'absolute bottom-full left-0 transform rounded-lg -translate-y-2 bg-smena_bb-background_light tooltip tooltip_top-left';
    }
    if (placement === 'top-center') {
      return 'absolute bottom-full transform rounded-lg left-1 -translate-x-2/4 -translate-y-2 bg-smena_bb-background_light tooltip tooltip_top-center';
    }
  };

  return (
    <div
      className={clsx(
        placement
          ? placementFn(placement)
          : 'absolute top-2/4 left-full transform translate-x-5 -translate-y-2/4 rounded-lg tooltip tooltip_usual',
        blockClassName
          ? blockClassName
          : 'bg-smena_bb-background_light border-smena_bb-background_light'
      )}
      style={blockStyle}
    >
      <span
        className={clsx(
          'p-3 Table-small w-full',
          spanClassName ? spanClassName : 'text-smena_text'
        )}
        style={style}
      >
        {content}
      </span>
    </div>
  );
};

export default Tooltip;
