import clsx from 'clsx';
import React from 'react';
import { useStore } from '../../../store';
import { sortObj } from '../../../utils/sortObject';
import { ErrorNotification } from '../ErrorNotification';

type FormInput = {
  divClassName: string;
  divStyle?: any;
  label: string;
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  onBlur?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  sort?: boolean;
  allOption?: string;
  disabledOption?: string;
  value: string | number | undefined;
  options?: any[] | null;
  name: string;
  autoComplete?: string;
  error?: any;
  disabled?: boolean;
  validation?: boolean;
  showTextRole?: string[];
};

const SimpleSelect = ({
  divClassName,
  divStyle,
  label,
  required,
  onChange,
  sort = true,
  onBlur,
  allOption,
  disabledOption,
  value,
  options,
  name,
  autoComplete,
  error,
  disabled,
  validation,
  showTextRole,
}: FormInput) => {
  const { me } = useStore();

  const finalOptions = options ? (sort ? sortObj(options) : options) : null;
  return (
    <>
      {me && options && showTextRole?.includes(me.role) ? (
        value ? (
          <div className={clsx(divClassName, 'flex flex-col text-sm')}>
            <span className="Tag text-smena_text-secondary">{label}</span>
            <span className="Body1">{options.find(option => option.id === value)?.name}</span>
          </div>
        ) : (
          <div className={clsx(divClassName, 'flex flex-col text-sm')}>
            <span className="Tag text-smena_text-secondary">{label}</span>
            <span className="Body1">Не выбрано</span>
          </div>
        )
      ) : (
        <div
          className={clsx('flex flex-col gap-y-0.5', divClassName, validation ? 'inputHeight' : '')}
          style={divStyle}
        >
          <label htmlFor={name} className="label-primary">
            {label}
          </label>
          <select
            required={required}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            id={name}
            name={name}
            className={clsx(error ? 'select-error truncate' : 'select-primary truncate')}
            autoComplete={autoComplete}
            disabled={disabled}
          >
            {disabledOption ? (
              <option value="" disabled>
                {disabledOption}
              </option>
            ) : null}
            {allOption ? <option value="all">{allOption}</option> : null}

            {finalOptions?.map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          {error && <ErrorNotification text={error} />}
        </div>
      )}
    </>
  );
};

export default SimpleSelect;
