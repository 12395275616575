const HomeIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0683 1.23753C11.7516 0.920824 11.2382 0.920824 10.9214 1.23753L2.23753 9.92145C1.92082 10.2382 1.92082 10.7516 2.23753 11.0683C2.55423 11.385 3.06772 11.385 3.38442 11.0683L3.62706 10.8257V18.0305C3.62706 19.4381 4.76818 20.5793 6.17584 20.5793H16.6027C18.0103 20.5793 19.1514 19.4381 19.1514 18.0305V10.6144L19.6156 11.0786C19.9323 11.3953 20.4458 11.3953 20.7625 11.0786C21.0792 10.7618 21.0792 10.2484 20.7625 9.93166L12.0683 1.23753ZM11.4949 2.95787L5.0173 9.43546V18.0305C5.0173 18.6703 5.53599 19.189 6.17584 19.189H8.25586V15.2489C8.25586 14.3531 8.98203 13.627 9.87781 13.627H12.89C13.7858 13.627 14.512 14.3531 14.512 15.2489V19.189H16.6027C17.2425 19.189 17.7612 18.6703 17.7612 18.0305V9.22418L11.4949 2.95787ZM9.87781 15.0172C9.74984 15.0172 9.6461 15.1209 9.6461 15.2489L9.6461 19.1879H13.1217V15.2489C13.1217 15.1209 13.018 15.0172 12.89 15.0172H9.87781Z"
      />
    </svg>
  );
};

export default HomeIcon;
