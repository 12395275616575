import { Skeleton } from 'antd';
export const MainInfoLoader = () => {
  return (
    <div className="md:col-span-7 col-span-full mt-6 pb-6 px-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5">
      <div className="flex justify-between items-center mt-5">
        <Skeleton.Button
          active={true}
          size="small"
          shape="round"
          block={true}
        />
        <Skeleton.Button
          active={true}
          size="small"
          shape="round"
          block={true}
        />
      </div>

      <Skeleton active={true} />
    </div>
  );
};
