import dayjs from 'dayjs';
import clsx from 'clsx';

import { RegularZayavkaScheduleFragment } from 'src/generated/graphql';
import RequestProfessionDay from './days';

interface IRequestProfessionDates {
  requestSchedule?: RegularZayavkaScheduleFragment | null;
  days: dayjs.Dayjs[];
  setShowShiftSidebar: (value: boolean) => void;
}
const RequestProfessionDates: React.FC<IRequestProfessionDates> = ({ requestSchedule, days, setShowShiftSidebar }) => {
  return (
    <div
      className={clsx('gap-x-1 requestScheduleProfessionsRows border-b requestRow w-fit')}
      style={{
        gridTemplateColumns: `repeat(${days.length}, 80px)`,
      }}
    >
      {days.map(day => (
        <RequestProfessionDay
          key={day.valueOf()}
          day={day}
          requestSchedule={requestSchedule}
          setShowShiftSidebar={setShowShiftSidebar}
        />
      ))}
    </div>
  );
};

export default RequestProfessionDates;
