import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useUserQuery } from '../../../../generated/graphql';

import { useStore } from '../../../../store';

import MainLayout from '../../../../components/Layout/main';
import ScheduleCalendar from '../../../../components/Calendar/Schedule';
import Loading from '../../../../components/ui/Loading';
import NotFound from '../../../../components/Calendar/Schedule/NotFound';
import { roles } from '../../../../utils/constVariables';
import { UserFullName } from 'src/utils/userFullName';
import useUserHeaderMenu from 'src/hooks/headerMenu/useUserHeaderMenu';
import useUserBreadcrumbs from 'src/hooks/breadcrumbs/useUserBreadcrumbs';

const SchedulePage: React.FC = () => {
  const router = useParams();

  const { setCurrentUser, setIsUserLoading, me } = useStore();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const { data, loading } = useUserQuery({
    variables: {
      userId,
    },
  });

  const user = data?.user;

  const isMeAdminSuper = me && [roles.Admin, roles.Supervisor].includes(me.role);
  const isCurrentUserWorkerOrForeman = user && [roles.Worker, roles.Foreman].includes(user.role);

  const headerMenu = useUserHeaderMenu({
    currentLocation: 'График',
    userId,
    role: user?.role,
    isMeAdminSuper,
    isCurrentUserWorkerOrForeman,
  });

  const breadCrumbs = useUserBreadcrumbs({ isMeAdminSuper, userId });

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
      setIsUserLoading(false);
    }
    // eslint-disable-next-line
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <MainLayout
      title={'График ' + UserFullName(user)}
      bg="bg-smena_bb-background_base"
      headerMenu={headerMenu}
      breadCrumbs={breadCrumbs}
    >
      {user?.workposts?.length === 0 ? isMeAdminSuper && <NotFound /> : <ScheduleCalendar />}
    </MainLayout>
  );
};

export default SchedulePage;
