import { useMemo } from 'react';
import { roles } from 'src/utils/constVariables';
import {
  RegularUserShiftsFragment,
  ShiftStage,
} from '../../../generated/graphql';
import { useStore } from '../../../store';
import { useMeRole } from '../../../hooks/useRole';

const useProbationShifts = ({
  userShifts,
}: {
  userShifts?: RegularUserShiftsFragment[];
}) => {
  const { facilityId, workpostId, superShifts } = useStore();

  const isMeSupervisor = useMeRole(roles.Supervisor)

  return useMemo(() => {
    const shifts = (isMeSupervisor ? superShifts : userShifts) || []
    return shifts.filter(shift => {
      const isStageFits = shift.isProbation &&
        ![ShiftStage.Ineligible, ShiftStage.Refused].includes(shift.stage);
      const isPositionFits = workpostId ? shift.positionId === workpostId : true;
      const shiftFacilityId = shift.facility?.id;
      const isFacilityFits = facilityId ? shiftFacilityId === facilityId : true;
      return isStageFits && isPositionFits && isFacilityFits;
    })

  },[facilityId, isMeSupervisor, superShifts, userShifts, workpostId])
};

export default useProbationShifts;
