export const getDistance = (distance: number) => {
  if (distance > 0) {
    if (distance > 1000) {
      return `${(distance / 1000).toFixed(1)} км`;
    }
    return `${distance} м`;
  } else {
    return '0 м';
  }
};
