const convertPhone = (phoneNumber?: string) => {
  return `${
    '+7 (' +
    phoneNumber?.substring(0, 3) +
    ') ' +
    phoneNumber?.substring(3, 6) +
    ' ' +
    phoneNumber?.substring(6, 8) +
    ' ' +
    phoneNumber?.substring(8, 10)
  }`;
};

export default convertPhone;
