import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Pagination } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import {
  ModerationStatus,
  PeriodType,
  useModerationReportsPaginatedLazyQuery,
} from '../generated/graphql';

import MainLayout from '../components/Layout/main';
import TableHeader from '../components/Table/header';
import SkeletonLayout from '../components/Table/Skeleton';
import ModerationReportsFilter from '../components/Table/Filters/ModerationReportsFilter';
import { moderationReportColumns } from '../components/Table/columns';

import { useStore } from '../store';
import ModerationReportRow from '../components/Table/Row/ModerationReportRow';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { maxTableWidth } from 'src/utils/maxTableWidth';

const ModerationsReportsPage = () => {
  const [minus, setMinus] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [groupId, setGroupId] = useState('');
  const [facilityId, setFacilityId] = useState('');
  const [period, setPeriod] = useState<PeriodType | undefined>(undefined);
  const [status, setStatus] = useState<ModerationStatus | undefined>(undefined);
  const [rangeDates, setRangeDates] = useState<moment.Moment[] | null>(null);

  const [params] = useSearchParams();
  const location = useLocation();

  const {
    moderationReports,
    setModerationReportsInWork,
    moderationReportsHeaderCheckbox,
    setModerationReportsHeaderCheckbox,
  } = useStore();

  const [loadData, { data: dataReports, loading: loadingReports }] =
    useModerationReportsPaginatedLazyQuery();

  const mutationInput = {
    page: 1,
    dateStart: rangeDates && rangeDates[0],
    dateEnd: rangeDates && rangeDates[1],
    sortBy,
    sortWay,
    groupId,
    facilityId,
    periodType: period as PeriodType,
    status: status as ModerationStatus,
  };

  const returnData = () => {
    return loadData({
      variables: {
        input: {
          ...mutationInput,
        },
      },
    });
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setModerationReportsInWork([]);
  };

  const reportsPaginated = dataReports?.moderationReportsPaginated;
  const reports = reportsPaginated?.reports;

  let dateFromParam = params.get('dateFrom');
  let dateToParam = params.get('dateTo');
  let groupIdParam = params.get('groupId');
  let facilityIdParam = params.get('facilityId');
  let periodParam = params.get('period') as PeriodType;
  let statusParam = params.get('status') as ModerationStatus;

  const allShiftsInWork = useMemo(
    () =>
      reports
        ?.filter(
          report =>
            report.status === 'IN_WORK' &&
            Number(report.noModerated) === 0 &&
            Number(report.workersCount) > 0 &&
            Number(report.shiftCount) > 0
        )
        .map(report => report.id),
    [reports, page, location] // eslint-disable-line
  );

  const checkboxHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setModerationReportsHeaderCheckbox(checked);
      if (allShiftsInWork) {
        if (checked) {
          setModerationReportsInWork(allShiftsInWork);
        } else {
          setModerationReportsInWork([]);
        }
      }
    },
    [allShiftsInWork] //eslint-disable-line
  );

  useEffect(() => {
    if (moderationReports.length === 0) {
      setModerationReportsHeaderCheckbox(false);
    } else {
      setModerationReportsHeaderCheckbox(true);
      setMinus(true);
    }
    allShiftsInWork?.forEach(shift => {
      if (
        moderationReports.includes(shift) &&
        allShiftsInWork.length === moderationReports.length
      ) {
        setModerationReportsHeaderCheckbox(true);
        setMinus(false);
      } else {
        setMinus(true);
      }
    });
  }, [moderationReports, allShiftsInWork]); //eslint-disable-line

  useEffect(() => {
    loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
          dateStart: dateFromParam ? moment(dateFromParam).toDate() : null,
          dateEnd: dateToParam ? moment(dateToParam).toDate() : null,
          facilityId: facilityIdParam || '',
          groupId: groupIdParam || '',
          periodType: periodParam ? (periodParam as PeriodType) : undefined,
          status: statusParam ? (statusParam as ModerationStatus) : undefined,
        },
      },
    });
    setModerationReportsInWork([]);
    // eslint-disable-next-line
  }, [page, sortBy, sortWay, location]);

  const moderationReportsHeader = [
    {
      Header: (
        <input
          type="checkbox"
          name="reportId"
          className={`checkbox-primary ${minus ? 'minus' : ''}`}
          checked={moderationReportsHeaderCheckbox}
          onChange={checkboxHandler}
        />
      ),
      header_className: 'table__header pl-4',
      name: 'checkbox',
      sorted: false,
      className: 'table__content pl-4',
      style: {
        width: 30,
        minWidth: 30,
      },
    },
    ...moderationReportColumns,
  ];

  return (
    <MainLayout
      title="Модерация смен"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[{ path: '/moderation-period', name: 'Модерация смен' }]}
    >
      <div
        style={{
          maxWidth: maxTableWidth(moderationReportsHeader) + 'px',
        }}
      >
        <ModerationReportsFilter
          returnData={returnData}
          groupId={groupId}
          setGroupId={setGroupId}
          facilityId={facilityId}
          setFacilityId={setFacilityId}
          period={period}
          setPeriod={setPeriod}
          status={status}
          setStatus={setStatus}
          rangeDates={rangeDates}
          setRangeDates={setRangeDates}
        />
        <ScrollSync>
          <div className="flex flex-col shadow-smena rounded-lg">
            <TableHeader
              dataLength={reports?.length}
              columns={moderationReportsHeader}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />
            {loadingReports || !reports ? (
              <SkeletonLayout columns={moderationReportsHeader} />
            ) : reports.length > 0 ? (
              <ScrollSyncPane>
                <div className="main-table">
                  {reports.map(report => (
                    <ModerationReportRow
                      report={report}
                      columns={moderationReportsHeader}
                      key={report.id}
                    />
                  ))}
                </div>
              </ScrollSyncPane>
            ) : (
              <div className="w-full flex justify-center my-5">
                <h3 className="text-smena_text Body1">
                  По вашему запросу ничего не нашлось
                </h3>
              </div>
            )}
          </div>
        </ScrollSync>
        <div className="py-5">
          {reportsPaginated?.pages && reportsPaginated?.pages > 1 && (
            <Pagination
              shape="rounded"
              count={reportsPaginated ? reportsPaginated.pages : 10}
              page={page}
              onChange={handleChange}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ModerationsReportsPage;
