import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useFacilityCasingLazyQuery, useFacilityLazyQuery } from '../../../generated/graphql';

import { useStore } from '../../../store';

import { lunchDurationHandle } from '../../../utils/lunchDuration';

import { timeDifference } from '../../../utils/timeDifference';

import SimpleCheckbox from '../../ui/Checkbox';
import { roles } from '../../../utils/constVariables';
import useWorkpostFacilities from '../hooks/useWorkpostFacilities';
import useSupervisorWorkposts from '../hooks/useSupervisorWorkposts';
import useFacilityCasingsPositions from '../hooks/useFacilityCasingsPositions';
import useFacilityCasingsAtCalendarHeader from '../hooks/useFacilityCasingsAtCalendarHeader';
import FacilitySelect from './Selects/FacilitySelect';
import PositionSelect from './Selects/PositionSelect';
import { useIsMeAdminOrSupervisor } from 'src/hooks/useIsMeAdminOrSupervisor';

const CalendarHeader = () => {
  const {
    currentUser,
    me,
    facilityId,
    setFacilityId,
    facilityIdError,
    setFacilityIdError,
    workpostId,
    setWorkpostId,
    workpostIdError,
    setWorkpostIdError,
    hiddenCanceledShifts,
    setHiddenCanceledShifts,
    setLunchDuration,
    startScheduleTime,
    endScheduleTime,
    setShowSidebar,
    setCreateScheduleMenu,
  } = useStore();

  const location = useLocation();

  const [loadFacilityData, { data }] = useFacilityLazyQuery({
    variables: {
      id: facilityId,
    },
  });

  const schedulePage = useMemo(() => location.pathname.includes('schedule'), [location]);

  const isMeAdminOrSupervisor = useIsMeAdminOrSupervisor();

  const [loadData, { data: facilityCasingsData }] = useFacilityCasingLazyQuery();

  const facility = data?.facility;

  const workpostsPositions = currentUser?.workposts;

  const workpostsFacilities = useWorkpostFacilities();

  const supervisorWorkposts = useSupervisorWorkposts(workpostsFacilities);

  const facilityCasingsPositions = useFacilityCasingsPositions(facilityCasingsData?.facilityCasings);

  const facilityCasings = useFacilityCasingsAtCalendarHeader(facilityId, facilityCasingsPositions, workpostsPositions);

  const positionsFromCasings = useMemo(
    () => facilityCasingsPositions?.filter(position => facilityCasings?.find(casing => position.id === casing?.id)),
    [facilityCasingsPositions, facilityCasings]
  );

  const selectFacilityHandler = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
      setFacilityId(value);
      loadData({
        variables: { facilityId: value },
      });
      setWorkpostId('');
      if (value) {
        setFacilityIdError(false);
      }
    },
    [loadData, setFacilityId, setWorkpostId, setFacilityIdError]
  );

  const selectWorkpostHandler = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
      setWorkpostId(value);
      if (value) {
        setWorkpostIdError(false);
      }
    },
    [setWorkpostId, setWorkpostIdError]
  );

  const hiddenCancaledHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setHiddenCanceledShifts(checked);
    },
    [setHiddenCanceledShifts]
  );

  const workposts = useMemo(
    () => (me?.role === roles.Supervisor && supervisorWorkposts ? supervisorWorkposts : workpostsFacilities),
    [supervisorWorkposts, workpostsFacilities, me?.role]
  );

  useEffect(() => {
    setLunchDuration(0);
    setFacilityId('');
    setWorkpostId('');
    setHiddenCanceledShifts(false);
    setFacilityIdError(false);
    setWorkpostIdError(false);
  }, [setFacilityId, setFacilityIdError, setHiddenCanceledShifts, setLunchDuration, setWorkpostId, setWorkpostIdError]);

  useEffect(() => {
    if (facility?.facilityGroup?.name === 'Ашан') {
      setLunchDuration(lunchDurationHandle(timeDifference(startScheduleTime, endScheduleTime)));
    }
  }, [endScheduleTime, facility?.facilityGroup?.name, setLunchDuration, startScheduleTime]);

  useEffect(() => {
    if (workposts.length === 1) {
      const firtsFacilityId = workposts[0].facilityId;
      setFacilityId(firtsFacilityId);
      loadData({
        variables: { facilityId: firtsFacilityId },
      });
    }
  }, [currentUser, loadData, me, setFacilityId, workposts]);

  useEffect(() => {
    if (!facilityId) return;

    loadFacilityData({
      variables: {
        id: facilityId,
      },
    });
  }, [loadFacilityData, facilityId]);

  useEffect(() => {
    if (positionsFromCasings && positionsFromCasings.length === 1) {
      const firstPositionId = positionsFromCasings[0].id;
      if (firstPositionId) {
        setWorkpostId(firstPositionId);
      }
    }
  }, [facilityCasingsData, positionsFromCasings, setWorkpostId]);

  return (
    <div className="flex items-center justify-between filter-bar schedule flex-wrap">
      <div className="flex flex-wrap gap-x-2">
        <FacilitySelect
          facilityId={facilityId}
          facilityIdError={facilityIdError}
          selectFacilityHandler={selectFacilityHandler}
          workpostsFacilities={workpostsFacilities}
          supervisorWorkposts={supervisorWorkposts}
        />
        <PositionSelect
          selectWorkpostHandler={selectWorkpostHandler}
          workpostId={workpostId}
          workpostIdError={workpostIdError}
          positionsFromCasings={positionsFromCasings}
        />
        {schedulePage && (
          <SimpleCheckbox
            divClassName="flex items-center ml-5"
            label="Скрыть отмененные и отклоненные"
            onChange={hiddenCancaledHandler}
            checked={hiddenCanceledShifts}
            name={'hiddenCanceledShifts'}
          />
        )}
      </div>
      {schedulePage && isMeAdminOrSupervisor && (
        <button
          type="button"
          className="btn-primary md:my-0 my-5"
          onClick={() => {
            setCreateScheduleMenu(true);
            setShowSidebar(false);
          }}
        >
          Добавить смены
        </button>
      )}
    </div>
  );
};

export default CalendarHeader;
