import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import MainSidebar from 'src/components/ui/Sidebar';
import { useCreateZayavkaScheduleMutation } from 'src/generated/graphql';
import { zayavkaConfigFn } from 'src/utils/graphqlConfigs/zayavkaConfig';
import { useStore } from 'src/store';
import { errorToast, successToast } from 'src/utils/notify';
import { errorHandler } from 'src/utils/errorHandler';
import SimpleSelect from 'src/components/ui/Select';
import { scheduleTypes } from 'src/utils/lists/scheduleTypes';
import { LunchDurationSelect } from 'src/components/ui/Select/LunchDurationSelect';
import { auchanLunchDurationTypes, lunchDurationTypes } from 'src/utils/lists/lunchDurationTypes';
import { groupName, positionName } from 'src/utils/filters/filtersName';
import { timeDifference } from 'src/utils/timeDifference';
import { lunchDurationHandle } from 'src/utils/lunchDuration';
import DatePickerInput from '../../../ui/Pickers/DatePickerInput';

import TimePickerInput from '../../../ui/Pickers/TimePicker';
import moment from 'moment';
import { RU_DATE } from '../../../../utils/constVariables';
import uniqueArray from '../../../../hooks/uniqueArray';

interface ICreateNewSchedule {
  showCreateGraphicSidebar: boolean;
  setShowCreateGraphicSidebar: (value: boolean) => void;
}

const CreateNewSchedule: React.FC<ICreateNewSchedule> = ({ showCreateGraphicSidebar, setShowCreateGraphicSidebar }) => {
  const { groups, positions, request, facilities } = useStore();
  const router = useParams();
  let requestIdURL = '';

  if (typeof router.requestId === 'string') {
    requestIdURL = router.requestId;
  }

  const requestPositions = useMemo(
    () =>
      uniqueArray({
        field: 'id',
        array: request?.plans.map(plan => ({
          id: plan.positionId,
          name: positionName(positions, plan.positionId),
        })),
      }),
    [positions, request?.plans]
  );

  const [positionId, setPositionId] = useState('');
  const [shiftStartTime, setShiftStartTime] = useState<moment.Moment | null>(null);
  const [shiftEndTime, setShiftEndTime] = useState<moment.Moment | null>(null);
  const [shiftStartDate, setShiftStartDate] = useState<dayjs.Dayjs | null>(dayjs(request?.dateFrom) || null);
  const [shiftEndDate, setShiftEndDate] = useState<dayjs.Dayjs | null>(dayjs(request?.dateTo) || null);
  const [lunchDuration, setLunchDuration] = useState(0);
  const [scheduleType, setScheduleType] = useState(1);
  const [errors, setErrors] = useState<any>({});

  const zayavkaConfig = zayavkaConfigFn(requestIdURL);

  const zayavkaRefetch = {
    awaitRefetchQueries: true,
    refetchQueries: [zayavkaConfig],
  };

  const [createZayavkaSchedule] = useCreateZayavkaScheduleMutation(zayavkaRefetch);

  let errorCounter = 0;
  const errorStateHandler = (field: string | dayjs.Dayjs | moment.Moment | null, name: string) => {
    if (!field) {
      setErrors((value: any) => ({
        ...value,
        [name]: 'Обязательное поле',
      }));
      errorCounter += 1;
    } else {
      setErrors((value: any) => ({
        ...value,
        [name]: '',
      }));
    }
  };

  const createZayavkaScheduleHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    errorStateHandler(positionId, 'positionId');
    errorStateHandler(shiftStartTime, 'shiftStartTime');
    errorStateHandler(shiftEndTime, 'shiftEndTime');
    errorStateHandler(shiftStartDate, 'shiftStartDate');
    errorStateHandler(shiftEndDate, 'shiftEndDate');

    if (errorCounter > 0) {
      return;
    }
    createZayavkaSchedule({
      variables: {
        input: {
          dateStart: shiftStartDate
            ?.set('hours', moment(shiftStartTime).get('hours'))
            .set('minutes', moment(shiftStartTime).get('minutes')),
          dateEnd: shiftEndDate
            ?.set('hours', moment(shiftEndTime).get('hours'))
            .set('minutes', moment(shiftEndTime).get('minutes')),
          facilityId: request?.facilityId || '',
          positionId,
          zayavkaId: requestIdURL,
          lunchDuration,
          dayInterleaving: scheduleType,
          shiftEndDate: shiftStartDate
            ?.set('hours', moment(shiftEndTime).get('hours'))
            .set('minutes', moment(shiftEndTime).get('minutes')),
        },
      },
    })
      .then(() => successToast('График создан'))
      .catch(e => errorToast(errorHandler(e)))
      .finally(() => setShowCreateGraphicSidebar(false));
  };

  const facilityGroup = useMemo(
    () => groupName(groups, facilities?.find(el => el.id === request?.facilityId)?.facilityGroupId),
    [facilities, groups, request?.facilityId]
  );

  useEffect(() => {
    if (request) {
      setPositionId(request?.plans[0].positionId);
      setShiftStartDate(dayjs(request?.dateFrom));
      setShiftEndDate(dayjs(request?.dateTo));
    }
  }, [request]);

  return (
    <MainSidebar title="Создать график" show={showCreateGraphicSidebar} setShow={setShowCreateGraphicSidebar}>
      <form className="relative h-max" onSubmit={createZayavkaScheduleHandler}>
        <div className="mb-5 flex flex-col gap-x-8">
          <div className="grid grid-cols-2 gap-x-6">
            <SimpleSelect
              divClassName="col-span-1"
              label="Профессия"
              onChange={e => {
                setPositionId(e.target.value);
              }}
              value={positionId}
              name="positionId"
              validation
              options={requestPositions}
              disabledOption="Не выбрано"
              error={errors.positionId}
            />
            <SimpleSelect
              divClassName="col-span-1"
              label="График"
              required
              onChange={e => {
                setScheduleType(Number(e.target.value));
              }}
              value={scheduleType}
              validation
              name="type"
              disabledOption="Выберите график"
              options={scheduleTypes}
            />
          </div>
          <div className="grid grid-cols-2 gap-x-6">
            <TimePickerInput
              //check
              label="Начало смены"
              className="col-span-1 inputHeight"
              popupClassName="fixed"
              value={shiftStartTime}
              onChange={timeValue => {
                setShiftStartTime(timeValue);
                if (facilityGroup === 'Ашан') {
                  setLunchDuration(lunchDurationHandle(timeDifference(timeValue, shiftEndTime)));
                }
              }}
              error={{ bool: Boolean(errors.shiftStartTime), text: errors.shiftStartTime }}
            />
            <TimePickerInput
              //check
              label="Конец смены"
              className="col-span-1 inputHeight"
              popupClassName="fixed"
              value={shiftEndTime}
              onChange={timeValue => {
                setShiftEndTime(timeValue);
                if (facilityGroup === 'Ашан') {
                  setLunchDuration(lunchDurationHandle(timeDifference(shiftStartTime, timeValue)));
                }
              }}
              error={{ bool: Boolean(errors.shiftEndTime), text: errors.shiftEndTime }}
            />
          </div>
          <div className="grid grid-cols-2 gap-x-6">
            <DatePickerInput
              //check
              label="Дата начала"
              divClassName="col-span-1 inputHeight"
              popupClassName="fixed"
              value={shiftStartDate}
              onChange={selectedDate => setShiftStartDate(selectedDate)}
              minDate={dayjs(request?.dateFrom)}
              maxDate={dayjs(request?.dateTo)}
              placeholder={dayjs().startOf('month').format(RU_DATE)}
              error={{ bool: Boolean(errors.shiftStartDate), text: errors.shiftStartDate }}
            />
            <DatePickerInput
              //check
              label="Дата конца"
              divClassName="col-span-1 inputHeight"
              popupClassName="fixed"
              value={shiftEndDate}
              onChange={selectedDate => setShiftEndDate(selectedDate)}
              minDate={dayjs(request?.dateFrom)}
              maxDate={dayjs(request?.dateTo)}
              placeholder={dayjs().startOf('month').format(RU_DATE)}
              error={{ bool: Boolean(errors.shiftEndDate), text: errors.shiftEndDate }}
            />
          </div>
          <div className="grid grid-cols-2 gap-x-6">
            <LunchDurationSelect
              divClassName="col-span-1"
              label="Обед"
              required
              onChange={e => {
                if (facilityGroup !== 'Ашан') {
                  setLunchDuration(Number(e.target.value));
                }
              }}
              value={lunchDuration}
              name="lunchDuration"
              autoComplete="lunchDuration"
              options={facilityGroup === 'Ашан' ? auchanLunchDurationTypes : lunchDurationTypes}
              disabled={facilityGroup === 'Ашан'}
            />
          </div>
          <div>
            <button className="btn-primary" type="submit">
              Создать
            </button>
          </div>
        </div>
      </form>
    </MainSidebar>
  );
};

export default CreateNewSchedule;
