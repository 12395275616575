interface IGetUserFIO {
  name: string;
  lastname: string;
  patronymic?: string | null;
}
export const getUserFIO = ({ lastname, name, patronymic }: IGetUserFIO) => {
  return `${lastname} ${name.charAt(0).toUpperCase()}. ${
    patronymic ? patronymic.charAt(0).toUpperCase() + '.' : ''
  }`;
};
