import { ScrollSyncPane } from 'react-scroll-sync';
import { Link } from 'react-router-dom';

import { useStore } from '../../store';
import Across from '../../Assets/icons/Across';
import Check from '../../Assets/icons/check';
interface TableInterface {
  columns: {
    className?: string;
    style?: any;
  }[];
}

const PositionsTable = ({ columns }: TableInterface) => {
  const { positions } = useStore();
  return (
    <ScrollSyncPane>
      <div className="main-table">
        {positions?.map(position => (
          <div
            key={position.id}
            className="grid table__row w-fit overflow-x-auto"
            style={{
              gridTemplateColumns: columns
                .map(
                  column =>
                    'minmax(' +
                    column.style.minWidth +
                    'px' +
                    ', ' +
                    column.style.width +
                    'px)'
                )
                .join(' '),
              columnGap: 10,
            }}
          >
            <div className={columns[0].className}>
              <Link to={`/positions/${position.id}`}>{position.name}</Link>
            </div>

            <div className={columns[1].className}>
              {position.isSelfEmployed ? (
                <span className="text-smena_green-dark">
                  <Check />
                </span>
              ) : (
                <span>
                  <Across color="text-smena_red" />
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </ScrollSyncPane>
  );
};

export default PositionsTable;
