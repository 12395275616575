import clsx from 'clsx';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import MainSidebar from 'src/components/ui/Sidebar';
import {
  RegularNotificationFragment,
  useReadAllNotificationMutation,
  useReadNotificationMutation,
  NotificationsDocument,
  NotificationsQueryVariables,
} from 'src/generated/graphql';

interface INotificationSidebar {
  showNotificationSidebar: boolean;
  setShowNotificationSidebar: (showNotificationSidebar: boolean) => void;
  notifications?: RegularNotificationFragment[];
}

const NotificationSidebar: React.FC<INotificationSidebar> = ({
  showNotificationSidebar,
  setShowNotificationSidebar,
  notifications,
}) => {
  const notificationsConfig: {
    query: typeof NotificationsDocument;
    variables: NotificationsQueryVariables;
  } = {
    query: NotificationsDocument,
    variables: {},
  };

  const [readNotification] = useReadNotificationMutation();
  const [readAllNotification] = useReadAllNotificationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [notificationsConfig],
  });
  return (
    <MainSidebar
      title="Уведомления"
      show={showNotificationSidebar}
      setShow={setShowNotificationSidebar}
    >
      <div className="flex justify-end">
        <button
          className="Button3 text-primary"
          onClick={async () => {
            await readAllNotification();
          }}
        >
          Прочитать все
        </button>
      </div>
      {/* <h4 className="H4">{dayjs().format('DD MMMM, YYYY')}</h4> */}
      {notifications?.map(notifiaction => (
        <div className="flex gap-x-2 items-baseline" key={notifiaction.id}>
          {!notifiaction.isRead && (
            <span
              className="rounded-full wait_worker_schedule"
              style={{ minHeight: '8px', minWidth: '8px' }}
            ></span>
          )}
          <div
            className={clsx(
              'flex flex-col border-b border-smena_gray-30 py-2',
              notifiaction.isRead && 'ml-4'
            )}
          >
            <div className="flex flex-col Subtitle1 text-smena_gray-100">
              <span>{notifiaction.title}</span>
              <span>{notifiaction.body}</span>
              <div className="flex gap-x-1 text-smena_text-helper Body2">
                <span>
                  {dayjs(Number(notifiaction.date)).format('DD MMM, YYYY')}
                </span>
                <span>·</span>
                <span>{dayjs(Number(notifiaction.date)).format('HH:mm')}</span>
              </div>
            </div>
            <div className="flex justify-end">
              <Link
                to={`/${notifiaction.route}/${notifiaction.routeId}`}
                className="Button3 text-primary p-1"
                onClick={() => {
                  readNotification({
                    variables: {
                      id: notifiaction.id,
                    },
                  });
                }}
              >
                Подробнее
              </Link>
            </div>
          </div>
        </div>
      ))}
    </MainSidebar>
  );
};

export default NotificationSidebar;
