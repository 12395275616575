import dayjs from 'dayjs';
import { ICustomEvent } from 'src/Interfaces/IEvent';

export const shiftExist = (obj: ICustomEvent, arr: ICustomEvent[]) => {
  const date = (date: Date) => dayjs(Number(date)).millisecond(0).valueOf();
  return arr.filter(
    event =>
      date(event.start) === date(obj.start) && date(event.end) === date(obj.end)
  );
};
