import { MapPlaceModel, MapProfessionModel } from '../generated/graphql';
// import { getColor } from './getColor';

export const parsedData = (system: string, data?: MapPlaceModel[]) => {
  function swap(a: number, b: number, arr?: any[]): any[] {
    if (arr) {
      arr[a] = arr.splice(b, 1, arr[a])[0];
      return arr;
    }
    return [];
  }
  return data
    ?.filter(el => el.point)
    .map(facility => {
      const bodyContent = (positions?: MapProfessionModel[] | null) => {
        return `<table cellpadding="3">
                  <tr>
                  <th colspan="5">${facility.name}</th>
                  </tr>
                  <tr>
                    <th style="margin-right: 10px" align="left">Должность:</th>
                    <th align="left">App:</th>
                    <th align="left">зона 4км:</th>
                    <th align="left">зона 10км:</th>
                    <th align="left">город:</th>
                  </tr>
                  ${positions?.map(position => {
                    return `<tr>
                        <td>${position.name}</td>
                        <td>
                          Smena:
                          <br />
                          ERP:
                        </td>
                        <td>
                          ${position.smena.in4km}
                          <br />
                          ${position.erp.in4km}
                        </td>
                        <td>
                          ${position.smena.in10km}
                          <br />
                          ${position.erp.in10km}
                        </td>
                        <td>
                          ${position.smena.inCity}
                          <br />
                          ${position.erp.inCity}
                        </td>
                      </tr>`;
                  })}
                </table>`;
      };

      const checkColor = () => {
        const positions = facility.positions?.filter(
          pos => pos[system as keyof MapProfessionModel]
        )[0];

        //@ts-ignore
        if (system === '' || positions === undefined) {
          return 'rgb(192,192,192)';
        }

        const mainColor = 240;

        //@ts-ignore
        const systemVar: MapMetrikaModel = positions[system];
        const red = systemVar.in4km
          ? 255 - (systemVar.in4km / 6) * 255 > 255
            ? mainColor
            : 255 - (systemVar.in4km / 6) * 255
          : mainColor;
        const green = systemVar.in4km
          ? mainColor
          : systemVar.in10km
          ? (systemVar.in10km / 6) * 255 > mainColor
            ? mainColor
            : (systemVar.in10km / 6) * 255
          : 0;

        return `rgb(${red}, ${green}, 0)`;
      };

      return {
        geometry: {
          type: 'Point',
          coordinates: swap(0, 1, facility.point?.split(' ').map(Number)),
        },
        properties: {
          balloonContentHeader: facility.shopName,
          balloonContentBody: bodyContent(facility.positions),
        },
        options: {
          preset: 'islands#dotIcon',
          iconColor: checkColor(),
          // iconLayout: 'default#image',
          // iconImageHref: SmenaLogo,
          // iconImageSize: [30, 30],
          // iconImageOffset: [-15, -15],
          // Defining a hotspot on top of the image.
          // preset: 'islands#circleDotIcon',
        },
      };
    });
};
