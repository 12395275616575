import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  PayoutType,
  RegularModerationShiftFragment,
  ShiftStage,
} from '../../../../../../generated/graphql';
import { tooltipContentFn } from '../../../../../../utils/get/getTooltipContentShiftSidebar';
import { isInt } from '../../../../../../utils/isInt';
import { shiftStatus } from '../../../../../../utils/shiftStatus';
import { timeObject2 } from '../../../../../../utils/timeObject';
import { unitSymbolFn } from '../../../../../../utils/unitSymbol';
import Tooltip from '../../../../../ui/Tooltip';

interface IShiftTitle {
  shift: RegularModerationShiftFragment;
  duration: {
    hours: number;
    minutes: number;
  };
}

const ShiftTitle: React.FC<IShiftTitle> = ({ shift, duration }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const shiftHourly = shift.payout === PayoutType.Hourly;

  const { moderation, durationFact, stage, value, unit, dateFrom, dateEnd } =
    shift;

  const unitSymbol = unitSymbolFn(unit);

  const fact = moderation
    ? durationFact
      ? true
      : false
    : stage === ShiftStage.Finished
    ? true
    : false;

  const resultValue = moderation ? shift.moderation?.value : shift.value;

  const tooltipContent = tooltipContentFn({
    duration,
    moderation,
    value,
    shiftHourly,
    unitSymbol,
    fact,
    shiftResultValue: resultValue,
  });

  return (
    <div className="flex gap-x-2 cursor-pointer items-center">
      <div className="Button1">
        <span className="mr-1">Смена</span>
        <span>{dayjs(Number(dateFrom)).format('HH:mm')} – </span>
        <span>{dayjs(Number(dateEnd)).format('HH:mm')}</span>
      </div>
      <div
        className={`shiftModerationStatus ${shiftStatus(shift).bg} relative`}
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
      >
        <span className="hoursCount text-smena_text">
          {shiftHourly
            ? timeObject2(duration).hours + ':' + timeObject2(duration).minutes
            : isInt(resultValue)}
        </span>
        <span className={clsx(shiftStatus(shift).separator)}>|</span>
        <span className="Body2 text-smena_text">{shiftStatus(shift).text}</span>
        {showTooltip && (
          <Tooltip content={tooltipContent} placement="top-center" />
        )}
      </div>
    </div>
  );
};

export default ShiftTitle;
