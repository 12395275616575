import { ConfigProvider } from 'antd';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import locale from 'antd/lib/locale/ru_RU';
import DatePickerIcon from '../../../Assets/icons/DatePickerIcon';
import { RangePickerProps as BaseRangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { ErrorNotification } from '../ErrorNotification';
import { RU_DATE } from '../../../utils/constVariables';

export const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

interface Props {
  divClassName?: string;
  popupClassName?: string;
  label?: string;
  format?: string;
  value?: Dayjs | null;
  onChange: (date: Dayjs | null, dateString: string) => void;
  disabled?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  placeholder?: string;
  open?: boolean;
  error?: {
    bool?: boolean;
    text?: string;
  };
}

const DatePickerInput: React.FC<Props> = ({
  divClassName,
  popupClassName,
  label,
  value,
  format = RU_DATE,
  onChange,
  disabled,
  maxDate,
  minDate,
  error,
  placeholder = 'ДД.ММ.ГГГГ',
  picker,
  open,
}) => {
  const disabledDate: BaseRangePickerProps<Dayjs>['disabledDate'] = useCallback(
    (current: Dayjs) => {
      if (maxDate && minDate) {
        return current && (current > maxDate || current < minDate);
      }
      if (maxDate) {
        return current && current > maxDate;
      }
      if (minDate) {
        return current && current < minDate;
      }
      return false;
    },
    [maxDate, minDate]
  );

  const defaultPickerValue = useMemo(() => {
    if (value) return value;
    // if (maxDate) return maxDate;
    // if (minDate) return minDate;
  }, [value]);

  return (
    <div className={divClassName}>
      {Boolean(label) && (
        <label htmlFor="date" className="label-primary">
          {label}
        </label>
      )}
      <ConfigProvider locale={locale}>
        <DatePicker
          value={value}
          onChange={onChange}
          format={format}
          suffixIcon={<DatePickerIcon opacity="opacity-100" />}
          defaultPickerValue={defaultPickerValue}
          disabled={disabled}
          className={clsx('custom__picker w-full', error?.bool ? 'border-smena_red' : 'border-smena_gray-40')}
          disabledDate={disabledDate}
          placeholder={placeholder}
          popupClassName={popupClassName}
          picker={picker}
          open={open}
        />
      </ConfigProvider>
      {error?.bool && <ErrorNotification text={error.text} />}
    </div>
  );
};

export default DatePickerInput;
