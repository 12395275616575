import { useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import clsx from 'clsx';

import {
  useCreateWorkPostMutation,
  useFacilityCasingLazyQuery,
  useRemoveWorkPostMutation,
} from '../../../generated/graphql';
import { useStore } from '../../../store';

import Management from '../Managament';
import SimpleSelect from '../../ui/Select';
import { errorHandler } from '../../../utils/errorHandler';
import { errorToast, infoToast, successToast } from '../../../utils/notify';
import { filterFacilities } from '../../../utils/filters/filterFacilities';
import { userQueryConfigFn } from '../../../utils/graphqlConfigs/userQueryConfig';
import { FacilityAndProfessionLoading } from '../FacilityResponsibility/FacilityAndProfessionLoading';
import { roles } from '../../../utils/constVariables';

const FacilityAndProfession = () => {
  const [facilityId, setFacilityId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [positionId, setPositionId] = useState('');
  const [facilityIdError, setFacilityIdErr] = useState('');
  const [positionIdError, setPositionIdErr] = useState('');

  const { facilities, facilitiesLoading, groups, positions, currentUser, me } =
    useStore();

  const [loadFacilityCasingsData, { data: facilityCasingsData, loading }] =
    useFacilityCasingLazyQuery();

  const userQueryConfig = userQueryConfigFn(currentUser?.id);

  const [createWorkpost] = useCreateWorkPostMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const [removeWorkpost] = useRemoveWorkPostMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userQueryConfig],
  });

  const workposts = currentUser?.workposts;
  const meRole = me?.role;

  const positionsCasings = useMemo(
    () =>
      facilityCasingsData?.facilityCasings.map(cas =>
        positions?.find(pos => pos.id === cas.positionId)
      ),
    [facilityCasingsData, positions]
  );

  const userFacilitiesWorkposts = useMemo(
    () => [
      ...new Map(
        facilities
          ?.map(facility =>
            currentUser?.workposts?.filter(
              workpost => facility.id === workpost.facilityId
            )
          )
          .filter(facility => {
            if (facility && facility?.length > 0) {
              return facility;
            }
            return null;
          })
          .flat()
          .map(item => [item!['facilityId'], item])
      ).values(),
    ],
    [currentUser, facilities]
  );

  const filteredFacilities = useMemo(
    () => filterFacilities(groupId, facilities),
    [groupId, facilities]
  );

  const handleChange = useCallback(
    ({ target: { value, name } }: React.ChangeEvent<HTMLSelectElement>) => {
      switch (name) {
        case 'groupId':
          setGroupId(value);
          setFacilityId('');
          break;
        case 'facilityId':
          setFacilityId(value);
          loadFacilityCasingsData({
            variables: { facilityId: value },
          });
          setPositionId('');
          break;
        case 'positionId':
          setPositionId(value);
          break;
      }
    },
    [loadFacilityCasingsData]
  );

  const createWorkpostHandler = async () => {
    if (!facilityId || !positionId) {
      if (!facilityId) {
        setFacilityIdErr('Обязательное поле');
      }
      if (!positionId) {
        setPositionIdErr('Обязательное поле');
      }
      return;
    }
    setFacilityIdErr('');
    setPositionIdErr('');
    await createWorkpost({
      variables: {
        userId: currentUser?.id || '',
        facilityId,
        positionId,
      },
    })
      .then(() => successToast('Связь с объектом создана'))
      .catch(e => errorToast(errorHandler(e)));
  };

  const removeWorkpostHandler = async (
    facilityId: string,
    positionId: string
  ) => {
    await removeWorkpost({
      variables: {
        userId: currentUser?.id || '',
        facilityId,
        positionId,
      },
    })
      .then(() => infoToast('Связь с объектом удалена'))
      .catch(err => errorToast(errorHandler(err)));
  };

  const canSeeCurrentRole =
    meRole && [roles.Admin, roles.Supervisor].includes(meRole);
  const canSeeCurrentUser =
    currentUser && [roles.Worker, roles.Foreman].includes(currentUser.role);

  if (loading || facilitiesLoading) {
    return <FacilityAndProfessionLoading />;
  }

  return (
    <>
      {canSeeCurrentUser &&
        (userFacilitiesWorkposts.length !== 0 || canSeeCurrentRole) && (
          <div className="userPageBlock blockTemplate">
            <span className="blockTitleTemplate">
              Объекты и должности как исполнителя
            </span>
            <div
              className={clsx(
                userFacilitiesWorkposts &&
                  userFacilitiesWorkposts?.length > 0 &&
                  'border-smena_gray-30 flex flex-col gap-y-2'
              )}
            >
              {canSeeCurrentRole && (
                <div className="flex md:items-end gap-x-5 md:flex-nowrap flex-wrap">
                  <SimpleSelect
                    divClassName="crudUserMulty"
                    label="Группа"
                    onChange={handleChange}
                    value={groupId}
                    name="groupId"
                    autoComplete="groupId"
                    disabledOption="Не выбрана"
                    validation={true}
                    options={groups}
                  />
                  <SimpleSelect
                    divClassName="crudUserMulty"
                    label="Объект"
                    onChange={handleChange}
                    value={facilityId}
                    name="facilityId"
                    autoComplete="facilityId"
                    disabledOption="Не выбран"
                    options={filteredFacilities}
                    validation={true}
                    error={facilityIdError}
                  />

                  <SimpleSelect
                    divClassName="crudUserMulty"
                    label="Должность"
                    onChange={handleChange}
                    value={positionId}
                    name="positionId"
                    autoComplete="positionId"
                    disabledOption="Не выбран"
                    options={positionsCasings}
                    validation={true}
                    error={positionIdError}
                  />

                  <div className="paddingForSelects flex items-end md:items-start">
                    <button
                      className="btn-secondary_small w-full"
                      onClick={createWorkpostHandler}
                      type="button"
                    >
                      <span>Добавить</span>
                    </button>
                  </div>
                </div>
              )}
              {userFacilitiesWorkposts?.map(userFacilitiesWorkpost => (
                <Management
                  key={nanoid()}
                  userFacilitiesWorkpost={userFacilitiesWorkpost}
                  workposts={workposts}
                  removeWorkpost={removeWorkpostHandler}
                />
              ))}
            </div>
          </div>
        )}
    </>
  );
};
export default FacilityAndProfession;
