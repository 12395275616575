import {
  GetZayavkaDocument,
  GetZayavkaQueryVariables,
} from '../../generated/graphql';

interface IZayavkaConfig {
  query: typeof GetZayavkaDocument;
  variables: GetZayavkaQueryVariables;
}

export const zayavkaConfigFn = (id: string): IZayavkaConfig => ({
  query: GetZayavkaDocument,
  variables: {
    id,
  },
});
