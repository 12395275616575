import { AcceptedDocuments } from '../../Assets/icons/AcceptedDocuments';
// import { EmptyDocuments } from '../../Assets/icons/EmptyDocuments';
import { NewDocuments } from '../../Assets/icons/NewDocuments';
import { PartlyAcceptedDocuments } from '../../Assets/icons/PartlyAcceptedDocuments';
import { RejectedDocuments } from '../../Assets/icons/RejectedDocuments';
import { DocumentsStatus } from '../../generated/graphql';

export const getDocumentStatusImg = (status: DocumentsStatus) => {
  switch (status) {
    case DocumentsStatus.Accepted:
      return <AcceptedDocuments />;
    case DocumentsStatus.InProgress:
      return <NewDocuments />;
    case DocumentsStatus.NoDocuments:
      return;
    case DocumentsStatus.PartlyAccepted:
      return <PartlyAcceptedDocuments />;
    case DocumentsStatus.Rejected:
      return <RejectedDocuments />;
  }
};
