import { ZayavkaStage } from 'src/generated/graphql';

export const getRequestClassName = (status?: ZayavkaStage) => {
  const statuses = [
    {
      statusEnum: ZayavkaStage.Blank,
      className:
        'bg-smena_white border border-smena_text-secondary text-smena_text-secondary',
    },
    {
      statusEnum: ZayavkaStage.Canceled,
      className:
        'bg-smena_white border border-smena_text-secondary text-smena_text-secondary',
    },
    {
      statusEnum: ZayavkaStage.Draft,
      className: 'bg-smena_white border border-primary-dark text-primary',
    },
    {
      statusEnum: ZayavkaStage.Finished,
      className: 'bg-smena_gray-30 text-smena_text-secondary',
    },
    {
      statusEnum: ZayavkaStage.New,
      className: 'bg-smena_bb-background text-primary',
    },
    {
      statusEnum: ZayavkaStage.Refused,
      className: 'bg-smena_red-extra_light text-smena_red',
    },
    {
      statusEnum: ZayavkaStage.Working,
      className: 'bg-smena_green-extra_light text-smena_green-dark',
    },
  ];
  return statuses.find(statusEl => statusEl.statusEnum === status)?.className;
};
