import { useState } from 'react';
import { debounce } from 'lodash';

import { useUsersPaginatedQuery } from '../../../../generated/graphql';

import Across from '../../../../Assets/icons/Across';
import Magnifier from '../../../../Assets/icons/magnifier';

import { searchUserUpdateModeration } from '../../../Table/columns';
import TableHeader from '../../../Table/header';
import { UsersTable } from './Users';

export const AddEmployerPopup = ({
  hide,
}: {
  hide: (hide: boolean) => void;
}) => {
  const [search, setSearch] = useState('');

  const { data: dataUsers, loading } = useUsersPaginatedQuery({
    variables: { input: { searchQuery: search } },
  });

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const debounceOnChange = debounce(handleChangeSearch, 600);

  const users = dataUsers?.usersPaginated.users;

  return (
    <div className="h-full p-5">
      <div className="flex justify-end">
        <span className="cursor-pointer" onClick={() => hide(false)}>
          <Across color="text-smena_text" />
        </span>
      </div>

      <h3 className="H3 text-smena_text">Поиск исполнителя</h3>
      <div className="relative w-[300px] mt-5">
        <span className="absolute top-0 left-0 transform translate-x-2/4 translate-y-2/4">
          <Magnifier />
        </span>
        <input
          className="input-primary pl-8"
          onChange={debounceOnChange}
          type="search"
          name="search"
          id="search"
          placeholder="ФИО или телефон исполнителя "
        />
      </div>
      {search ? (
        dataUsers?.usersPaginated.users.length! > 0 ? (
          <>
            <TableHeader
              columns={searchUserUpdateModeration}
              setSortBy={() => {}}
              setSortWay={() => {}}
            />
            <div className="overflow-y-auto max-w-[2500px]">
              {users?.map(user => {
                return (
                  <UsersTable
                    key={user.id}
                    user={user}
                    columns={searchUserUpdateModeration}
                    hide={hide}
                  />
                );
              })}
            </div>
          </>
        ) : loading ? null : (
          <div className="search__user">
            <div className="Subtitle1 text-smena_text-secondary">
              Исполнитель не найден
            </div>
            <div className="Body2 text-smena_text-secondary text-center w-[300px]">
              Попробуйте ввести другие данные.
            </div>
          </div>
        )
      ) : (
        <div className="search__user">
          <div className="Subtitle1 text-smena_text-secondary">
            Поиск исполнителя
          </div>
          <div className="Body2 text-smena_text-secondary text-center w-[300px]">
            Чтобы найти исполнителя, введите его ФИО, или номер телефона.
          </div>
        </div>
      )}
    </div>
  );
};
