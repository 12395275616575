import React, { useEffect, useState, useRef } from 'react';
import { Pagination } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import {
  DocumentsStatus,
  useUsersPaginatedLazyQuery,
} from '../../../generated/graphql';

import MainLayout from '../../../components/Layout/main';
import TableHeader from '../../../components/Table/header';
import { usersColumns } from '../../../components/Table/columns';
import SkeletonLayout from '../../../components/Table/Skeleton';
import UsersFilters from '../../../components/Table/Filters/UsersFilters';
import { activeHandler } from '../../../utils/isActiveHandler';
import UsersRow from '../../../components/Table/Row/UsersRow';
import { maxTableWidth } from 'src/utils/maxTableWidth';

const OurUsersPage = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [facilityId, setFacilityId] = useState('');
  const [role, setRole] = useState('');
  const [isActive, setIsActive] = useState<boolean | string>('');
  const [documentsStatus, setDocumentsStatus] = useState<
    string | DocumentsStatus
  >('');
  const [params] = useSearchParams();

  const [loadData, { data: dataUsers, loading: loadingUsers }] =
    useUsersPaginatedLazyQuery();

  const mutationInput = {
    page: 1,
    searchQuery: search,
    sortBy,
    sortWay,
    facilityId,
    documentsStatus: documentsStatus as DocumentsStatus,
    isActive: activeHandler(isActive),
    role,
  };

  const returnData = () => {
    return loadData({
      variables: {
        input: {
          ...mutationInput,
        },
      },
    });
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const userPaginated = dataUsers?.usersPaginated;
  const users = userPaginated?.users;

  let facilityIdParam = params.get('facilityId');
  let roleParam = params.get('role');
  let documentStatusParam = params.get('documentStatus');
  let activeParam = params.get('active');
  let searchParam = params.get('search');

  const location = useLocation();

  const userTableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (facilityIdParam) {
      setFacilityId(facilityIdParam);
    } else {
      setFacilityId('');
    }
    if (roleParam) {
      setRole(roleParam);
    } else {
      setRole('');
    }
    if (documentStatusParam) {
      setDocumentsStatus(documentStatusParam);
    } else {
      setDocumentsStatus('');
    }
    if (activeParam) {
      setIsActive(activeParam);
    } else {
      setIsActive('');
    }
    if (searchParam) {
      setSearch(searchParam);
    } else {
      setSearch('');
    }
    loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
          facilityId: facilityIdParam || '',
          role: roleParam || '',
          documentsStatus:
            (documentStatusParam as DocumentsStatus) || undefined,
          isActive: activeParam
            ? activeHandler(activeParam)
            : activeHandler(''),
        },
      },
    });
    // eslint-disable-next-line
  }, [sortBy, sortWay, location, page]);

  return (
    <MainLayout
      title="Пользователи"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[{ path: '/users', name: 'Пользователи' }]}
    >
      <div style={{ maxWidth: maxTableWidth(usersColumns) + 'px' }}>
        <UsersFilters
          returnData={returnData}
          setSearch={setSearch}
          setFacilityId={setFacilityId}
          setRole={setRole}
          setPage={setPage}
          facilityId={facilityId}
          documentStatus={documentsStatus}
          setDocumentStatus={setDocumentsStatus}
          role={role}
          isActive={isActive}
          setIsActive={setIsActive}
        />
        <ScrollSync>
          <div className="flex flex-col shadow-smena rounded-lg">
            <TableHeader
              dataLength={users?.length}
              columns={usersColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />
            {loadingUsers || !users ? (
              <SkeletonLayout columns={usersColumns} />
            ) : users.length > 0 ? (
              <ScrollSyncPane>
                <div className="main-table overflow-x-auto" ref={userTableRef}>
                  {users?.map(user => (
                    <UsersRow
                      key={user.id}
                      user={user}
                      columns={usersColumns}
                    />
                  ))}
                </div>
              </ScrollSyncPane>
            ) : (
              <div className="w-full flex justify-center my-5">
                <h3 className="text-smena_text Body1">
                  По вашему запросу <span className="font-bold">{search}</span>{' '}
                  ничего не нашлось
                </h3>
              </div>
            )}
          </div>
        </ScrollSync>
        <div className="py-5">
          {userPaginated?.pages! > 1 ? (
            <Pagination
              shape="rounded"
              count={userPaginated ? userPaginated.pages : 10}
              page={page}
              onChange={handleChange}
            />
          ) : null}
        </div>
      </div>
    </MainLayout>
  );
};

export default OurUsersPage;
