import { Link } from 'react-router-dom';

import {
  RegularVacancyFragment,
  useChangeVacancyStatusMutation,
} from '../../generated/graphql';

import { getPeriod } from '../../utils/get/getPeriod';

import Across from '../../Assets/icons/Across';
import Check from '../../Assets/icons/check';
import Garbage from '../../Assets/icons/GarbageArrow';
import GarbageArrowless from '../../Assets/icons/GarbageArrowless';
import { payoutTypes } from '../../utils/lists/payoutTypes';
import { allUnits } from '../../utils/lists/units';

interface TableInterface {
  vacancies?: RegularVacancyFragment[];
  columns: {
    Header: string;
    name: string;
    className?: string;
    style?: any;
  }[];
}
const FacilityVacanciesTable = ({ vacancies, columns }: TableInterface) => {
  const [changeStatus] = useChangeVacancyStatusMutation();

  return (
    <div>
      {vacancies?.map(vacancy => {
        const vacancyTime = (time: string) => {
          return time.split(', ').length > 1
            ? time.split(', ').map(el => <div key={el}>{el}</div>)
            : time;
        };
        return (
          <div
            key={vacancy.id}
            className="grid table__row w-fit overflow-x-auto"
            style={{
              gridTemplateColumns: columns
                .map(
                  column =>
                    'minmax(' +
                    column.style.minWidth +
                    'px' +
                    ', ' +
                    column.style.width +
                    'px)'
                )
                .join(' '),
              columnGap: 10,
            }}
          >
            <div className={columns[0].className}>
              <Link
                to={`/vacancies/${vacancy.id}`}
                className="Table-small text-primary underline"
              >
                {vacancy.position?.name}
              </Link>
            </div>
            <div className={columns[1].className}>
              {vacancyTime(vacancy.timeStart)}
            </div>
            <div className={columns[2].className}>
              {vacancyTime(vacancy.timeEnd)}
            </div>
            <div className={columns[3].className}>
              {getPeriod(vacancy.period)}
            </div>
            <div className={columns[4].className}>{vacancy.schedule}</div>
            <div className={columns[5].className}>{vacancy.section}</div>
            <div className={columns[6].className}>
              {
                payoutTypes.find(payoutType => payoutType.id === vacancy.payout)
                  ?.name
              }
            </div>
            <div className={columns[7].className}>
              {allUnits.find(unit => vacancy.unit === unit.id)?.name}
            </div>
            <div className={columns[8].className}>{vacancy.hourRate} ₽</div>
            <div className={columns[9].className}>
              {vacancy.is_active ? (
                <span className="text-smena_green-dark">
                  <Check />
                </span>
              ) : (
                <span>
                  <Across color="text-smena_red" />
                </span>
              )}
            </div>
            <div className={columns[10].className}>
              <div className="flex justify-between">
                <div>
                  <button
                    className=""
                    onClick={() =>
                      changeStatus({ variables: { id: vacancy.id } })
                    }
                  >
                    {vacancy.is_active ? <Garbage /> : <GarbageArrowless />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FacilityVacanciesTable;
