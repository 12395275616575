import { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';

import {
  RegularModerationReportFragment,
  useDownloadModerationReportMutation,
} from '../../../generated/graphql';
import { ModerationReportsCheckbox } from '../../ModerationReports/ModerationReportsCheckbox';
import { shiftPeriodStatus } from '../../../utils/get/getShiftPeriodStatus';
import { DownloadIcon } from '../../../Assets/icons/download';
import { errorHandler } from '../../../utils/errorHandler';
import { errorToast, successToast } from '../../../utils/notify';
import SpinnerLoad from '../../ui/Loader';
import Check from '../../../Assets/icons/check';
import { getStatusRu } from '../../../utils/get/getStatusRu';
import { toast } from 'react-toastify';
import { IColumns } from '../../../Interfaces/IColumns';

interface IModerationReportRow {
  report: RegularModerationReportFragment;
  columns: IColumns[];
}

const ModerationReportRow: React.FC<IModerationReportRow> = ({
  report,
  columns,
}) => {
  const [downloadModerationReport] = useDownloadModerationReportMutation();
  const [load, setLoad] = useState(false);
  const [resLink, setResLink] = useState('');

  const downloadXLSHandler = async (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    toast.info('Создаем отчет...', {
      theme: 'colored',
      type: 'info',
      toastId: 'infoToast',
    });
    setLoad(true);
    await downloadModerationReport({
      variables: {
        id,
      },
    })
      .then(response => {
        if (response.data?.downloadModerationReport) {
          setLoad(false);
          const filePath = response.data.downloadModerationReport.filePath;
          const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
          setResLink(url);
          toast.dismiss('infoToast');
        }
      })
      .catch(e => {
        setLoad(false);
        toast.dismiss('infoToast');
        setTimeout(() => {
          errorToast(errorHandler(e));
        }, 1000);
      });
  };
  return (
    <div
      key={report.id}
      className="grid table__row w-fit overflow-x-auto"
      style={{
        gridTemplateColumns: columns
          .map(
            column =>
              'minmax(' +
              column.style.minWidth +
              'px' +
              ', ' +
              column.style.width +
              'px)'
          )
          .join(' '),
        columnGap: 10,
      }}
    >
      <div className={columns[0].className}>
        <ModerationReportsCheckbox
          id={report.id}
          noModeratedCount={report.noModerated}
          workersCount={report.workersCount}
          shiftCount={report.shiftCount}
          // reportStatus={report.status}
        />
      </div>
      <div className={columns[1].className}>
        <Link
          to={`/moderations-period/${report.id}`}
          className="Table-small text-primary hover:underline"
        >
          {`${dayjs(report.dateStart).format('DD.MM.YY')}-${dayjs(
            report.dateEnd
          ).format('DD.MM.YY')}`}
        </Link>
      </div>
      <div className={columns[2].className}>
        <span
          // to={`/groups/${report.facility?.facilityGroup?.id}`}
          className="Table-small text-smena_text"
        >
          {report.facility?.facilityGroup?.name}
        </span>
      </div>
      <div className={columns[3].className}>
        <span
          // to={`/facilities/${report.facility?.id}`}
          className="Table-small text-smena_text"
        >
          {report.facility?.name}
        </span>
      </div>
      <div className={columns[4].className}>{report.workersCount}</div>
      <div className={columns[5].className}>
        {dayjs(report.updatedAt).format('DD.MM.YY')}
      </div>
      <div className={columns[6].className}>
        <span
          className={`moderation__status ${shiftPeriodStatus(
            getStatusRu(report.status)
          )}`}
        >
          {getStatusRu(report.status)}
        </span>
      </div>
      <div className={columns[7].className}>
        {report.statusDate
          ? dayjs(report.statusDate).format('DD.MM.YY')
          : dayjs(report.createdAt).format('DD.MM.YY')}
      </div>
      <div className={columns[8].className}>
        <div className="flex items-center">
          {resLink ? (
            <a
              href={resLink}
              onClick={() => successToast('Отчет успешно скачан')}
            >
              <DownloadIcon />
            </a>
          ) : (
            <button
              className={clsx(
                report.status === 'IN_WORK' || load
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              )}
              disabled={report.status === 'IN_WORK' || load}
              onClick={e => downloadXLSHandler(e, report.id)}
            >
              <DownloadIcon />
            </button>
          )}

          {load ? (
            <SpinnerLoad />
          ) : resLink ? (
            <span className="text-smena_green-dark">
              <Check />
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default ModerationReportRow;
