import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import ActiveScheduleCheckbox from '../ActiveScheduleCheckbox';
import TableHeader from '../../../Table/header';
import ScheduleRequestElement from '../../../Table/ScheduleRequestElement';
import ScheduleSpoiler from '../ScheduleSpoiler';
import { useStore } from '../../../../store';
import { scheduleRequestsColumns } from '../../../Table/columns';
import { useActiveRequestSchedule } from '../../../../pages/facilities/schedule/hooks';

const ScheduleHeader = () => {
  const { scheduleRequestsIds, scheduleRequests, scheduleRequestsHeaderCheckbox } = useStore();
  const [showActive, setShowActive] = useState(true);
  const [showSpoiler, setShowSpoiler] = useState(false);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [minus, setMinus] = useState(false);

  const copyOfScheduleRequests = scheduleRequests ? [...scheduleRequests] : [];

  const { checkboxHandler } = useActiveRequestSchedule();

  const scheduleRequestsColumnsChanged = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            name="reportId"
            className={clsx('checkbox-primary', minus ? 'minus' : '')}
            checked={scheduleRequestsHeaderCheckbox}
            onChange={checkboxHandler}
          />
        ),
        header_className: 'table__header pl-4',
        name: 'checkbox',
        sorted: false,
        className: 'table__content pl-4',
        style: {
          width: 30,
          minWidth: 30,
        },
      },
      ...scheduleRequestsColumns,
    ],
    [checkboxHandler, minus, scheduleRequestsHeaderCheckbox]
  );

  useEffect(() => {
    const value = scheduleRequestsIds.length ? scheduleRequestsIds.length !== scheduleRequests?.length : false;
    setMinus(value);
  }, [scheduleRequestsIds, scheduleRequests]);

  return (
    <ScheduleSpoiler
      show={showSpoiler}
      setShow={setShowSpoiler}
      setShowActive={setShowActive}
      header={
        <div className="px-5">
          <div
            className={clsx(
              'bg-smena_white flex sm:overflow-visible overflow-x-scroll cursor-default',
              showSpoiler && 'border-b border-smena_gray-30'
            )}
          >
            <button
              className={clsx(
                'menu__element Subtitle1 text-smena_text-secondary',
                showSpoiler && showActive && 'menu__element-active'
              )}
              onClick={() => {
                setShowActive(true);
              }}
            >
              <span className="menu__link">Активные заявки</span>
            </button>
            {/*{showSpoiler && (*/}
            {/*  <button*/}
            {/*    className={clsx(*/}
            {/*      'menu__element Subtitle1 text-smena_text-secondary',*/}
            {/*      !showActive && 'menu__element-active'*/}
            {/*    )}*/}
            {/*    onClick={() => {*/}
            {/*      setShowActive(false);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <span className="menu__link">Закрытые заявки</span>*/}
            {/*  </button>*/}
            {/*)}*/}
          </div>

          {!showSpoiler && (
            <div className="flex gap-x-2 overflow-x-auto mt-4">
              {scheduleRequestsIds.map(id => (
                <ActiveScheduleCheckbox key={id} id={id} />
              ))}
            </div>
          )}
        </div>
      }
      className="xl:col-span-9 col-span-full p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col"
    >
      <div className="flex">
        <div className="flex flex-col w-full">
          <TableHeader
            columns={scheduleRequestsColumnsChanged}
            setSortBy={setSortBy}
            setSortWay={setSortWay}
            sortBy={sortBy}
            sortWay={sortWay}
          />
          <div className="scheduleRequestsTable">
            {copyOfScheduleRequests.map(request => (
              <ScheduleRequestElement key={request.id} request={request} columns={scheduleRequestsColumnsChanged} />
            ))}
          </div>
        </div>
      </div>
    </ScheduleSpoiler>
  );
};

export default ScheduleHeader;
