import React, { useCallback, useEffect, useState } from 'react';

import { Switch } from 'src/components/ui/Switch';
import {
  RegularPenaltyFragment,
  useChangeFacilityPenaltyMutation,
  useRemoveFacilityPenaltyMutation,
} from 'src/generated/graphql';
import SimpleInput from 'src/components/ui/Input';
import { useParams } from 'react-router-dom';
import { facilityConfigFn } from 'src/utils/graphqlConfigs/facilityConfig';
import { errorToast, successToast } from 'src/utils/notify';
import { errorHandler } from 'src/utils/errorHandler';

import TimePickerInput from '../../ui/Pickers/TimePicker';
import moment from 'moment/moment';

interface IFacilityPenalty {
  penalty?: RegularPenaltyFragment[] | null;
}

const FacilityPenalty: React.FC<IFacilityPenalty> = ({ penalty }) => {
  const router = useParams();

  let facilityId = '';

  if (typeof router.facilityId === 'string') {
    facilityId = router.facilityId;
  }
  const [penaltySwitch, setPenaltySwitch] = useState(false);
  const [penaltyTime, setPenaltyTime] = useState<moment.Moment | null>(null);
  const [penaltyCost, setPenaltyCost] = useState('');
  const [penaltyCostError, setPenaltyCostError] = useState('');

  const facilityConfig = facilityConfigFn(facilityId);

  const [changeFacilityPenalty] = useChangeFacilityPenaltyMutation(
    Boolean(facilityId)
      ? {
          awaitRefetchQueries: true,
          refetchQueries: [facilityConfig],
        }
      : {}
  );
  const [removeFacilityPenalty] = useRemoveFacilityPenaltyMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilityConfig],
  });

  const changePenalty = useCallback(
    async (applyTimeInMinutes: number, cost: number, facilityId: string) => {
      await changeFacilityPenalty({
        variables: {
          applyTimeInMinutes,
          cost,
          facilityId,
        },
      })
        .then(e => {
          if (e.data?.changeFacilityPenalty) {
            successToast('Удержание обновлено');
          }
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    },
    [changeFacilityPenalty]
  );

  const removePenalty = useCallback(
    async (facilityId: string) => {
      await removeFacilityPenalty({
        variables: {
          facilityId,
        },
      })
        .then(e => {
          if (e.data?.removeFacilityPenalty) {
            successToast('Удержание удалено');
          }
        })
        .catch(() => {
          errorToast('Произошла ошибка при удалении удержания');
        });
    },
    [removeFacilityPenalty]
  );

  const handleError = () => {
    if (Number(penaltyCost) > 100000 || Number(penaltyCost) < 1) {
      setPenaltyCostError('Допустимый размер удержания от 1 до 100.000 рублей');
    } else {
      setPenaltyCostError('');
    }
  };

  const handlePenalty = async () => {
    handleError();
    if (penaltySwitch) {
      if (penaltyTime && penaltyCost) {
        await changePenalty(moment(penaltyTime).get('hours') * 60, Number(penaltyCost), facilityId);
      }
    } else {
      await removePenalty(facilityId);
    }
  };

  useEffect(() => {
    if (penalty && penalty.length > 0) {
      setPenaltySwitch(true);
      setPenaltyCost(String(penalty[0].cost));
      if (penalty[0].applyTimeInMinutes) {
        setPenaltyTime(moment().set('hour', penalty[0].applyTimeInMinutes / 60));
      }
    }
  }, [penalty]);
  return (
    <div className="facilityPageBlock blockTemplate">
      <div className="grid grid-cols-8 gap-5 gap">
        <div className="flex gap-x-5 items-center col-span-full">
          <span className="Subtitle1 text-smena_text-secondary">Удержание при отмене смены</span>
          <Switch
            name="penaltySwitch"
            checked={penaltySwitch}
            onChange={({ target: { checked } }) => {
              setPenaltySwitch(checked);
            }}
          />
        </div>
        {penaltySwitch && (
          <>
            <span className="col-span-full block Body2 text-smena_text-secondary">
              Задайте время до начала смены, после которого будет назначено удержание за отмену смены.
            </span>
            <TimePickerInput
              //check
              label="Порог срабатывания"
              className="md:col-span-2 sm:col-span-4 col-span-full"
              value={penaltyTime}
              format="HH"
              onChange={value => {
                setPenaltyTime(value);
              }}
              placeholder={penaltyTime?.format('HH:mm')}
            />
            <SimpleInput
              divClassName="md:col-span-2 sm:col-span-4 col-span-full"
              placeholder="1000"
              label="Сумма удержания, ₽"
              onChange={e => {
                const value = e.target.value;
                if (/^\d+$/.test(value) || value === '') {
                  setPenaltyCost(value);
                }
              }}
              value={penaltyCost}
              type="text"
              name="penaltyCost"
              validation={true}
              error={penaltyCostError}
            />
            <div className="col-span-2 pt-6">
              <button className="btn-primary_small" type="button" onClick={handlePenalty}>
                Сохранить
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FacilityPenalty;
