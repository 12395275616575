import { Document, Page, pdfjs } from 'react-pdf';
import AlfaPDF from '../../../Assets/PDF/Registratsiya_v_AlfaBank_1.pdf';
// import 'react-pdf/dist/Page/AnnotationLayer.css';

export const Alfa = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return (
    <Document file={AlfaPDF} onLoadError={console.error}>
      {[1, 2, 3].map((page, index) => (
        <Page pageNumber={page} key={index} />
      ))}
    </Document>
  );
};
