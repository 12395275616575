import { useEffect, useRef } from 'react';

/**
 * Hook that alerts clicks outside the passed ref
 */
const useOutsideClick = (ref: React.RefObject<HTMLDivElement>, show: boolean, setShow: (value: boolean) => void) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: { target: any; path?: any }) => {
      const antComponent =
        event.target.className &&
        typeof event.target.className.includes !== 'undefined' &&
        event.target.className.includes('ant');
      if (ref.current && !ref.current.contains(event.target) && !antComponent && show) {
        setShow(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShow, show]);
};

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideClick({
  children,
  show,
  setShow,
  className,
  style,
}: {
  children: React.ReactNode;
  show: boolean;
  setShow: (value: boolean) => void;
  className?: string;
  style?: React.CSSProperties;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick(wrapperRef, show, setShow);

  return (
    <div ref={wrapperRef} className={className} style={style}>
      {children}
    </div>
  );
}
