// import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import Plus from '../../../Assets/icons/plus';

const GroupsFilters = () => {
  return (
    <div className="filter-bar">
      <Link to="/groups/create">
        <button
          type="button"
          className="btn-primary_big flex items-center gap-x-2"
        >
          <Plus className="text-smena_white" />
          <span className="Button1">Новая группа</span>
        </button>
      </Link>
    </div>
  );
};

export default GroupsFilters;
