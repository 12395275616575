import { ReviewType, SelfEmployedStatus } from 'src/generated/graphql';
import { useStore } from 'src/store';

const useAproveBlock = (type: ReviewType) => {
  const { currentUser, userRequisites } = useStore();

  switch (type) {
    case ReviewType.Person:
      return (
        Boolean(!currentUser?.lastname) ||
        Boolean(!currentUser?.firstname) ||
        Boolean(!currentUser?.cityId) ||
        Boolean(!currentUser?.citizenship?.id)
      );
    case ReviewType.MainPage:
      return (
        Boolean(!userRequisites?.passportSeries) ||
        Boolean(!userRequisites?.passportNumber) ||
        Boolean(!userRequisites?.passportIssuedDate) ||
        Boolean(!userRequisites?.passportIssuedBy)
      );
    case ReviewType.Registration:
      return Boolean(!userRequisites?.passportRegistrationAddress);
    case ReviewType.Page2_3:
      return Boolean(!userRequisites?.medicalNumber);
    case ReviewType.Page28_29:
      return (
        Boolean(!userRequisites?.medicalAttestationStart) &&
        Boolean(!userRequisites?.medicalAttestationEnd)
      );
    case ReviewType.Inn:
      if (userRequisites?.selfEmployed === SelfEmployedStatus.Accepted) {
        return (
          Boolean(!userRequisites?.inn) ||
          Boolean(!userRequisites?.selfEmployedDate)
        );
      }

      return Boolean(!userRequisites?.inn);
    case ReviewType.PaymentInfo:
      return (
        Boolean(!userRequisites?.cardNumber) ||
        Boolean(!userRequisites?.cardExpiryDate) ||
        Boolean(!userRequisites?.cardName) ||
        Boolean(!userRequisites?.accountNumber) ||
        Boolean(!userRequisites?.bankName) ||
        Boolean(!userRequisites?.bik) ||
        Boolean(!userRequisites?.correspondentAccount)
      );
    default:
      return false;
  }
};

export default useAproveBlock;
