import { Link } from 'react-router-dom';
// import { Progress } from 'antd';
import dayjs from 'dayjs';

import { useStore } from 'src/store';
import { RegularZayavkaFragment, RegularZayavkaPlanFragment } from 'src/generated/graphql';

import { RU_DATE } from 'src/utils/constVariables';
// import { reduceZayavkaField } from 'src/utils/reduceZayavkaField';
import { RowCheckbox } from '../Request/Schedule/RowCheckbox';

interface TableInterface {
  columns: {
    className?: string;
    style?: any;
  }[];
  request: RegularZayavkaFragment;
}

const ScheduleRequestElement = ({ columns, request }: TableInterface) => {
  const { positions } = useStore();
  const requestPositions = request.plans
    .map((requestPlan: RegularZayavkaPlanFragment, index: number) => {
      if (index < 3) {
        return positions?.find(position => position.id === requestPlan.positionId)?.name;
      } else {
        return null;
      }
    })
    .filter((el: any) => el);

  const counter = () => {
    if (request.plans.length > 3) {
      return request.plans.length - 3;
    }
    return null;
  };
  return (
    <div
      key={request.id}
      className="grid table__row w-full xs:overflow-x-auto overflow-x-scroll"
      style={{
        gridTemplateColumns: columns
          .map(column => 'minmax(' + column.style.minWidth + 'px, ' + column.style.width + 'px)')
          .join(' '),
        columnGap: 10,
      }}
    >
      <div className={columns[0].className}>
        <RowCheckbox id={request.id} />
      </div>
      <div className={columns[1].className}>
        <Link to={`/requests/${request.id}`}>{request.number}</Link>
      </div>
      <div className={columns[2].className}>
        {dayjs(request.dateFrom).format(RU_DATE) + ' - ' + dayjs(request.dateTo).format(RU_DATE)}
      </div>
      <div className={columns[3].className}>
        <span>{`${requestPositions.join(', ')}${counter() ? ', ' : ''}`}</span>
        <span className="text-primary">{counter() ? `+${counter()}` : ''}</span>
      </div>
      {/* <div className={columns[4].className}>
              <Progress
                type="circle"
                percent={(0 / reduceZayavkaField('shiftCount')) * 100}
                width={16}
                trailColor="#E0E0E0"
                showInfo={false}
                strokeWidth={10}
                strokeColor={
                  0 === reduceZayavkaField('shiftCount') ? '#397DFF' : '#F9CA3E'
                }
              />
              <div>
                <span>{0}</span>
                <span>/</span>
                <span>{reduceZayavkaField('shiftCount')}</span>
              </div>
            </div>
            <div className={columns[5].className}>
              <Progress
                type="circle"
                percent={(0 / reduceZayavkaField('hours')) * 100}
                width={16}
                trailColor="#E0E0E0"
                showInfo={false}
                strokeWidth={10}
                strokeColor={
                  0 === reduceZayavkaField('hours') ? '#397DFF' : '#F9CA3E'
                }
              />
              <div>
                <span>{0}</span>
                <span>/</span>
                <span>{reduceZayavkaField('hours')}</span>
              </div>
            </div> */}
    </div>
  );
};

export default ScheduleRequestElement;
