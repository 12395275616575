import { useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { useCreateDailyReportMutation } from '../../generated/graphql';
import { errorHandler } from '../../utils/errorHandler';
import { errorToast } from '../../utils/notify';
import TimesheetLoader from '../../components/Timesheets/Loader';
import { ISO_DATE, RU_DATE } from 'src/utils/constVariables';
import DatePickerInput from '../../components/ui/Pickers/DatePickerInput';

const DailyReport = () => {
  const [load, setLoad] = useState(false);
  const [resLink, setResLink] = useState('');
  const [createDailyReport] = useCreateDailyReportMutation();
  return (
    <div className="xs:col-span-4 col-span-full xs:col-start-1 h-fit mt-6 pb-5 px-6 gap-y-5 border rounded-lg shadow-smena bg-smena_white flex flex-col">
      <h2 className="Subtitle1 text-smena_text-secondary pt-5">Ежедневный отчет об отметках</h2>

      <Formik
        enableReinitialize
        initialValues={{
          date: null,
        }}
        validate={values => {
          let errors: any = {};
          if (values.date === null) {
            errors.date = 'Выберите дату';
          }
          return errors;
        }}
        onSubmit={async values => {
          setLoad(true);

          toast.info('Создаем отчет...', {
            theme: 'colored',
            type: 'info',
            toastId: 'infoToast',
          });

          const date = values.date as unknown as dayjs.Dayjs;

          createDailyReport({
            variables: {
              input: {
                date: date.format(ISO_DATE),
              },
            },
          })
            .then(response => {
              if (response.data?.createDailyReport) {
                setLoad(false);
                const filePath = response.data.createDailyReport.filePath;
                const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
                setResLink(url);
                toast.dismiss('infoToast');
              }
            })
            .catch(e => {
              setLoad(false);
              errorToast(errorHandler(e));
              setTimeout(() => {
                toast.dismiss('infoToast');
              }, 1000);
            });
        }}
      >
        {({ handleSubmit, errors, values, setFieldValue }) => {
          return (
            <>
              <form onSubmit={handleSubmit} className="flex xs:flex-row flex-col xs:items-end gap-x-5 flex-wrap">
                <DatePickerInput
                  // check
                  divClassName="crudUserInput inputHeight"
                  label="Дата"
                  value={values.date}
                  placeholder={dayjs().format(RU_DATE)}
                  onChange={selectedDate => {
                    setFieldValue('date', selectedDate);
                    setResLink('');
                  }}
                  maxDate={dayjs()}
                  error={{ bool: Boolean(errors.date), text: errors.date }}
                />
                <div className="paddingForSelects">
                  <button type="submit" disabled={load || Boolean(resLink)} className="btn-secondary_big">
                    Сформировать
                  </button>
                </div>
              </form>
              <TimesheetLoader load={load} resLink={resLink} />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default DailyReport;
