import React, { useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import Down from '../../Assets/icons/sort/down';
import Up from '../../Assets/icons/sort/up';

interface ITableHeader {
  columns: {
    Header: JSX.Element | string;
    header_className?: string;
    name: string;
    sorted: boolean;
    className?: string;
    style?: any;
  }[];
  dataLength?: number;
  sortBy?: string | null;
  sortWay?: string | null;
  setSortBy: (sortBy: string) => void;
  setSortWay: (sortBy: string) => void;
}

const TableHeader: React.FC<ITableHeader> = ({ dataLength, columns, sortBy, setSortBy, setSortWay }) => {
  const [way, setWay] = useState(0);
  const [clicked, setClicked] = useState('');

  const color = (icon: string, column: { name: string }) => {
    const thisColumn = sortBy === column.name;
    if (thisColumn) {
      if (way === 1) {
        if (icon === 'up') {
          return 'text-smena_text-disabled';
        }
        return 'text-smena_text-helper';
      }
      if (way === 2) {
        if (icon === 'up') {
          return 'text-smena_text-helper';
        }
        return 'text-smena_text-disabled';
      }
      return 'text-smena_text-disabled';
    }
    return 'text-smena_text-disabled';
  };

  return (
    <ScrollSyncPane>
      <div
        className="grid rounded-t-lg bg-smena_white border-b border-smena_gray-30 z-20 overflow-x-auto scrollbar-hide"
        style={{
          gridTemplateColumns: columns
            .map(column => 'minmax(' + column.style.minWidth + 'px, ' + column.style.width + 'px)')
            .join(' '),
          columnGap: 10,
          paddingRight: dataLength && dataLength > 8 ? '15px' : '0px',
        }}
      >
        {columns.map(column => {
          return (
            <div
              key={column.name}
              className={column.header_className + `${column.sorted ? ' cursor-pointer' : ''}`}
              id={column.name}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (column.sorted) {
                  setClicked(column.name);
                  setSortBy((e.target as Element).id);
                  if (clicked !== (e.target as Element).id) {
                    setWay(1);
                    setSortWay('desc');
                  } else {
                    if (way === 0) {
                      setWay(1);
                      setSortWay('desc');
                    }
                    if (way === 1) {
                      setWay(2);
                      setSortWay('asc');
                    }
                    if (way === 2) {
                      setWay(0);
                      setSortBy('updatedAt');
                    }
                  }
                }
              }}
            >
              {typeof column.Header === 'string' ? (
                <span className="pointer-events-none">{column.Header}</span>
              ) : (
                column.Header
              )}
              {column.sorted && (
                <span className="pointer-events-none ml-2 flex flex-col gap-y-1">
                  <Up color={color('up', column)} />
                  <Down color={color('down', column)} />
                </span>
              )}
            </div>
          );
        })}
      </div>
    </ScrollSyncPane>
  );
};

export default TableHeader;
