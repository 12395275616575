import {
  GetZayavkyByFacilityDocument,
  GetZayavkyByFacilityQueryVariables,
} from '../../generated/graphql';

interface IGetZayavkyByFacilityConfig {
  query: typeof GetZayavkyByFacilityDocument;
  variables: GetZayavkyByFacilityQueryVariables;
}

export const getZayavkyByFacilityConfigFn = (
  facilityId: string,
  month: string
): IGetZayavkyByFacilityConfig => ({
  query: GetZayavkyByFacilityDocument,
  variables: {
    facilityId,
    month,
  },
});
