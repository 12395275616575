import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMeAdminOrSupervisor } from 'src/hooks/useIsMeAdminOrSupervisor';
import { FilterPeriod, MetricTypes, useGetMetricsLazyQuery } from '../../../generated/graphql';
import { useStore } from '../../../store';
import { filterFacilities } from '../../../utils/filters/filterFacilities';
import SimpleSelect from '../../ui/Select';
import ColoredCircle from './ColoredCircle';
import {
  arrMetricTypesHandler,
  chartTypes,
  getAllUsersChartLengthHandler,
  getChartLengthHandler,
  getChartValue,
} from './helpers';
import { useMeRole } from '../../../hooks/useRole';
import { roles } from '../../../utils/constVariables';

const RightNow = () => {
  const { facilities, groups } = useStore();
  const [facilityId, setFacilityId] = useState('all');
  const [groupId, setGroupId] = useState('all');

  const isMeAdminOrSupervisor = useIsMeAdminOrSupervisor();
  const isMeSupervisor = useMeRole(roles.Supervisor);
  const isMeDirector = useMeRole(roles.ClientDirector);
  const isMeManager = useMeRole(roles.ClientManager);

  const filteredFacilities = useMemo(() => filterFacilities(groupId, facilities), [facilities, groupId]);

  const types = useMemo(() => chartTypes({ facilityId, groupId }), [facilityId, groupId]);

  const values = useMemo(
    () => ({ facilityId, facilityGroupId: groupId, filterPeriod: FilterPeriod.Day, types }),
    [facilityId, groupId, types]
  );

  const [lazyLoadMetrics, { data }] = useGetMetricsLazyQuery({
    variables: {
      input: values,
    },
  });

  const getChartLength = useCallback(
    (metricName: MetricTypes) => getChartLengthHandler({ getMetrics: data?.getMetrics, metricName }),
    [data?.getMetrics]
  );

  const acceptedShifts = useMemo(
    () =>
      getChartValue({
        facilityId,
        groupId,
        getChartLength,
        facilityMetricType: MetricTypes.ShiftsAcceptedFacility,
        facilityGroupMetricType: MetricTypes.ShiftsAcceptedFacilityGroup,
        allMetricType: MetricTypes.ShiftsAcceptedAll,
      }),
    [facilityId, getChartLength, groupId]
  );

  const usersAtShift = useMemo(
    () =>
      getChartValue({
        facilityId,
        groupId,
        getChartLength,
        facilityMetricType: MetricTypes.ShiftWorkersFacility,
        facilityGroupMetricType: MetricTypes.ShiftWorkersFacilityGroup,
        allMetricType: MetricTypes.ShiftWorkersAll,
      }),
    [facilityId, getChartLength, groupId]
  );
  const latecomers = useMemo(
    () =>
      getChartValue({
        facilityId,
        groupId,
        getChartLength,
        facilityMetricType: MetricTypes.LatecomersFacility,
        facilityGroupMetricType: MetricTypes.LatecomersFacilityGroup,
        allMetricType: MetricTypes.LatecomersAll,
      }),
    [facilityId, getChartLength, groupId]
  );

  const arrMetricTypes = useMemo(() => arrMetricTypesHandler({ facilityId, groupId }), [facilityId, groupId]);

  const getAllUsersChartLength = useMemo(
    () => getAllUsersChartLengthHandler({ arrMetricTypes, getMetrics: data?.getMetrics }),
    [arrMetricTypes, data?.getMetrics]
  );

  const handleFacilityChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    if (!value) {
      setFacilityId('all');
      localStorage.setItem('rightNowChartFacilityId', 'undefined');
    } else {
      setFacilityId(value);
      localStorage.setItem('rightNowChartFacilityId', value);
    }
  }, []);

  const handleGroupChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    if (!value) {
      setGroupId('all');
      localStorage.setItem('rightNowChartFacilityGroup', 'undefined');
    } else {
      setGroupId(value);
      localStorage.setItem('rightNowChartFacilityGroup', value);
    }
    localStorage.setItem('rightNowChartFacilityId', 'undefined');
    setFacilityId('all');
  }, []);

  useEffect(() => {
    const localStorageFacility = localStorage.getItem('rightNowChartFacilityId');
    const localStorageGroup = localStorage.getItem('rightNowChartFacilityGroup');
    if (localStorageFacility) {
      if (localStorageFacility === 'undefined') {
        setFacilityId('all');
      } else {
        setFacilityId(localStorageFacility);
      }
    }
    if (localStorageGroup) {
      if (localStorageGroup === 'undefined') {
        setGroupId('all');
      } else {
        setGroupId(localStorageGroup);
      }
    }

    lazyLoadMetrics();
  }, [lazyLoadMetrics]);

  useEffect(() => {
    if (groups) {
      if (isMeSupervisor || isMeManager || isMeDirector) {
        if (groupId === 'all') setGroupId(groups[0].id);
      }
    }
  }, [groupId, groups, isMeDirector, isMeManager, isMeSupervisor]);

  useEffect(() => {
    if (facilities) {
      if (isMeSupervisor || isMeManager) {
        if (facilityId === 'all') setFacilityId(facilities[0].id);
      }
    }
  }, [facilities, facilityId, isMeManager, isMeSupervisor]);

  return (
    <div className="flex flex-col gap-y-5 shadow-smena rounded-lg p-5">
      <div className="flex justify-between items-center flex-wrap">
        <span className="Subtitle1 text-smena_text-secondary">Сегодня</span>
        <div className="flex gap-x-5">
          <SimpleSelect
            divClassName="chart-filter"
            label=""
            onChange={handleGroupChange}
            value={groupId}
            name="groupId"
            options={groups}
            allOption={isMeSupervisor || isMeManager || isMeDirector ? undefined : 'Все группы'}
          />
          <SimpleSelect
            divClassName="chart-filter"
            label=""
            onChange={handleFacilityChange}
            value={facilityId}
            name="facilityId"
            options={filteredFacilities}
            allOption={isMeSupervisor || isMeManager ? undefined : 'Все объекты'}
          />
        </div>
      </div>
      <div
        className={clsx(
          'grid h-full',
          isMeAdminOrSupervisor ? 'grid-cols-2 gap-2' : 'gap-y-2 gap-x-10 grid-cols-[repeat(3,_max-content)]'
        )}
      >
        <div className="col-span-1 gap-y-2">
          <span className="Subtitle2 text-smena_text-secondary">Подтвержденные смены</span>
          <div className="flex items-center gap-x-2">
            <ColoredCircle color="bg-smena_purple" />
            <span className="ChartBigNumber text-smena_text">{acceptedShifts}</span>
          </div>
        </div>
        <div className="col-span-1 gap-y-2">
          <span className="Subtitle2 text-smena_text-secondary">Отработанное количество часов</span>
          <div className="flex items-center gap-x-2">
            <ColoredCircle color="bg-smena_teal" />
            <span className="ChartBigNumber text-smena_text">{usersAtShift}</span>
          </div>
        </div>
        <div className="col-span-1 gap-y-2">
          <span className="Subtitle2 text-smena_text-secondary">Опоздания</span>
          <div className="flex items-center gap-x-2">
            <ColoredCircle color="bg-smena_gold" />
            <span className="ChartBigNumber text-smena_text">{latecomers}</span>
          </div>
        </div>
        {isMeAdminOrSupervisor && (
          <div className="col-span-1 gap-y-2">
            <span className="Subtitle2 text-smena_text-secondary">Пользователи</span>
            <div className="flex items-center gap-x-2">
              <ColoredCircle color="bg-smena_pink" />
              <span className="ChartBigNumber text-smena_text">{getAllUsersChartLength}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightNow;
