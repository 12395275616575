import { useCallback } from 'react';
import { useStore } from '../../store';

export const FilterSystem = () => {
  const { system, setSystem, cluster, setCluster, profession, setProfession } =
    useStore();
  const changeSystem = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => setSystem(e.target.value),
    [] //eslint-disable-line
  );
  const changeProfession = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => setProfession(e.target.value),
    [] //eslint-disable-line
  );
  const changeCluster = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setCluster(e.target.checked),
    [] //eslint-disable-line
  );

  const positions = [
    'Грузчик',
    'Кассир',
    'Пекарь',
    'Продавец-Кассир',
    'Сборщик заказов',
    'Уборщик',
    'Работник торгового зала',
    'Работник склада',
    'Комплектовщик',
    'Работник кассы самообслуживания',
    'Промоутер',
  ];

  return (
    <div className="absolute z-50 top-0 left-0 m-5 p-3 flex gap-x-5 items-center bg-smena_white rounded-lg">
      <select
        name="system"
        className="select-primary Caption"
        value={system}
        onChange={changeSystem}
      >
        <option value="">Все системы</option>
        <option value="smena">Smena</option>
        <option value="erp">ERP</option>
      </select>
      <div className="flex items-center justify-center">
        <input
          type="checkbox"
          name="cluster"
          id="cluster"
          className="checkbox-primary"
          checked={cluster}
          onChange={changeCluster}
        />
        <label htmlFor="cluster" className="labep-primary">
          Кластер
        </label>
      </div>
      <select
        name="system"
        className="select-primary Caption"
        value={profession}
        onChange={changeProfession}
      >
        <option value="">Все профессии</option>
        {positions?.map(position => (
          <option key={position} value={position}>
            {position}
          </option>
        ))}
      </select>
    </div>
  );
};
