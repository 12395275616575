import SimpleInput from 'src/components/ui/Input';
import { FacilityMap } from 'src/pages/facilities/map';
import { roles } from 'src/utils/constVariables';

interface IFacilityMapAndCoordinates {
  values: any;
  touched: any;
  errors: any;
  address: { city: string; address: string };
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const FacilityMapAndCoordinates: React.FC<IFacilityMapAndCoordinates> = ({
  address,
  handleChange,
  values,
  touched,
  errors,
}) => {
  return (
    <div className="facilityPageBlock rounded-lg shadow-smena w-full bg-smena_white">
      {address.address || address.city ? (
        <div className="h-52 w-full relative">
          <FacilityMap fullAddress={address} />
        </div>
      ) : null}
      <div className="col-span-full grid grid-cols-8 gap-x-5 gap-y-7 p-5">
        <SimpleInput
          divClassName="sm:col-span-3 col-span-4"
          placeholder="Введите широту"
          label="Широта"
          onChange={e => {
            const value = e.target.value;
            if (/^[\d/.-]+$/.test(value) || value === '') {
              handleChange(e);
            }
          }}
          value={values.latitude}
          type="text"
          name="latitude"
          validation
          showTextRole={[roles.ClientDirector, roles.ClientManager]}
          error={touched.latitude && errors.latitude ? errors.latitude : ''}
        />

        <SimpleInput
          divClassName="sm:col-span-3 col-span-4"
          placeholder="Введите долготу"
          label="Долгота"
          onChange={e => {
            const value = e.target.value;
            if (/^[\d/.-]+$/.test(value) || value === '') {
              handleChange(e);
            }
          }}
          value={values.longitude}
          type="text"
          name="longitude"
          validation
          showTextRole={[roles.ClientDirector, roles.ClientManager]}
          error={touched.longitude && errors.longitude ? errors.longitude : ''}
        />
        <SimpleInput
          divClassName="sm:col-span-2 col-span-4"
          placeholder="Введите радиус"
          label="Радиус (м.)"
          onChange={handleChange}
          value={values.radius}
          type="text"
          name="radius"
          validation
          regText
          showTextRole={[roles.ClientDirector, roles.ClientManager]}
          error={touched.radius && errors.radius ? errors.radius : ''}
        />
      </div>
    </div>
  );
};

export default FacilityMapAndCoordinates;
