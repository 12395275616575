import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { groupName } from 'src/utils/filters/filtersName';
import { userLastnameAndInitials } from 'src/utils/userLastnameAndInitials';
import { PayoutType } from '../../../../generated/graphql';

import { useStore } from '../../../../store';
import { borderBottom, usersRowDouble } from './utils';
import { IRowName } from '../../../../Interfaces/IRow';

export const RowName: React.FC<IRowName> = ({ row }) => {
  const [checkbox, setCheckbox] = useState(false);

  const { moderationReport, updateModerationsReports, setUpdateModerationsReports, groups } = useStore();

  const checkboxHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setCheckbox(checked);
      setUpdateModerationsReports(row);
    },
    [moderationReport] //eslint-disable-line
  );

  const FIO = useCallback(
    (
      user?: {
        id: string;
        firstname: string;
        lastname: string;
        patronymic?: string | null | undefined;
      },
      positionId?: string
    ) => {
      const userIndex = usersRowDouble(user?.id, moderationReport?.getModerationReport.rows)?.findIndex(
        row => row.position.id === positionId
      );

      if (userIndex === 0) {
        return userLastnameAndInitials(user);
      } else {
        return '';
      }
    },
    [moderationReport]
  );

  const userPositions = useCallback((position?: string) => {
    if (position?.includes('Работник торгового зала')) {
      return 'РТЗ';
    }
    if (Number(position?.length) > 18) {
      const arrayOfWords = Number(position?.split(' ').length);
      if (arrayOfWords > 1) {
        return position
          ?.split(' ')
          .map(word => {
            return word.length > 18 / arrayOfWords ? word.substring(0, 18 / arrayOfWords - 2) + '.' : word;
          })
          .join(' ');
      }
      return position?.substring(0, 18);
    }
    return position;
  }, []);

  const pieceworkRow = useMemo(() => {
    return row.days.filter(day => day.shifts.filter(shift => shift.payout === PayoutType.Piecework).length);
  }, [row.days]);

  const ozon = groupName(groups, moderationReport?.getModerationReport.report?.facility?.facilityGroupId) === 'OZON';

  const pryamikom =
    groupName(groups, moderationReport?.getModerationReport.report?.facility?.facilityGroupId) === 'Прямиком';

  const goodGroup = ozon || pryamikom;

  const cantModerateWithoutImport =
    goodGroup && pieceworkRow.length > 0 && Boolean(!moderationReport?.getModerationReport.report.isImportFromFile);

  const disableCheckbox = useCallback(() => {
    const noModeratioCount = row?.days.filter(day => day.shifts.find(shift => shift.moderation === null)).length;

    if (!row?.user.isActive || row.shiftsCount === 0 || noModeratioCount === 0 || cantModerateWithoutImport) {
      return true;
    }
  }, [row, cantModerateWithoutImport]);

  useEffect(() => {
    if (
      updateModerationsReports?.find(
        report => report.user.id === row?.user.id && report.position.id === row.position.id
      )
    ) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  }, [updateModerationsReports]); //eslint-disable-line

  return (
    <div
      className={`p-1 ml-2 h-7 ${borderBottom(
        row?.user.id,
        row?.position.id,
        moderationReport?.getModerationReport.rows
      )}`}
    >
      <div className="period__fio flex items-center">
        <input
          type="checkbox"
          id="pay"
          className="checkbox-primary"
          checked={checkbox}
          disabled={disableCheckbox()}
          onChange={checkboxHandler}
        />
        <div className="flex justify-between w-full">
          <Link to={`/users/${row?.user.id}`} className="text-primary hover:underline">
            {FIO(row?.user, row?.position.id)}
          </Link>
          <span className="Caption-small text-smena_text-secondary">{userPositions(row?.position.name)}</span>
        </div>
      </div>
    </div>
  );
};
