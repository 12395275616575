import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import SelectArrow from '../../../../../Assets/icons/SelectArrow';
import {
  RegularModerationShiftFragment,
  ShiftPenaltyStatus,
  useCreateShiftPenaltyMutation,
  useUpdateShiftPenaltyStatusMutation,
} from '../../../../../generated/graphql';
import { durationFn } from '../../../../../utils/durationFn';
import SimpleSelect from '../../../../../components/ui/Select';
import { penaltyReasons } from '../../../../../utils/lists/penaltyReasons';
import { useStore } from '../../../../../store';
import SimpleInput from '../../../../ui/Input';
import { errorToast, infoToast, successToast } from '../../../../../utils/notify';
import { moderationReportConfigFn } from '../../../../../utils/graphqlConfigs/moderationReportConfig';
import { errorHandler } from '../../../../../utils/errorHandler';
import ShiftTitle from './ShiftTitle';
import ShiftDateStartEnd from './ShiftDateStartEnd';
import { RU_DATE } from '../../../../../utils/constVariables';

const RefusedShift: React.FC<{
  shift: RegularModerationShiftFragment;
  index: number;
}> = ({ shift, index }) => {
  const { durationPlan, id, penalties } = shift;

  const { moderationReport } = useStore();

  const moderationReportConfig = moderationReportConfigFn(moderationReport?.getModerationReport.report.id);

  const [updateShiftPenalty] = useUpdateShiftPenaltyStatusMutation({
    awaitRefetchQueries: true,
    refetchQueries: [moderationReportConfig],
  });

  const [createShiftPenalty] = useCreateShiftPenaltyMutation({
    awaitRefetchQueries: true,
    refetchQueries: [moderationReportConfig],
  });

  const facilityPenalty = moderationReport?.getModerationReport.report.facility?.penalty;

  const isShitfPenalty = penalties && penalties.length > 0 ? penalties : null;
  const isFacilityPenalty = facilityPenalty && facilityPenalty.length > 0 ? facilityPenalty : null;

  const costResult = isShitfPenalty ? isShitfPenalty[0].cost : isFacilityPenalty ? isFacilityPenalty[0].cost : '';

  const [aprove, setAprove] = useState(isShitfPenalty ? isShitfPenalty[0].status : '');
  const [reason, setReason] = useState<undefined | null | string>(isShitfPenalty ? isShitfPenalty[0].cause : '');
  const [cost, setCost] = useState(costResult || '');
  const [comment, setComment] = useState({
    show: false,
    value: shift.comment || '',
  });
  const [show, setShow] = useState(index === 0);

  const duration = useMemo(() => durationFn(durationPlan), [durationPlan]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (aprove === ShiftPenaltyStatus.Rejected && !comment) {
        return errorToast('Комментарий обязательное поле');
      }

      const input = {
        shiftPenaltyStatus: aprove as ShiftPenaltyStatus,
        cause: reason,
        comment: comment.value,
        cost: Number(cost),
      };
      if (aprove === ShiftPenaltyStatus.Rejected && !reason) {
        return infoToast('Укажите причину отмены удержания');
      }

      if (facilityPenalty && facilityPenalty.length > 0 && penalties) {
        return await updateShiftPenalty({
          variables: {
            input: {
              id: penalties[0].id,
              ...input,
            },
          },
        })
          .then(e => {
            if (e.data?.updateShiftPenaltyStatus) {
              successToast('Удержание обновлено');
            }
          })
          .catch(e => errorToast(errorHandler(e)));
      }
      await createShiftPenalty({
        variables: {
          input: {
            shiftId: id,
            ...input,
          },
        },
      })
        .then(e => {
          if (e.data?.createShiftPenalty) {
            successToast('Удержание создано');
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    },
    [aprove, id, reason, cost, comment, facilityPenalty, createShiftPenalty, updateShiftPenalty, penalties]
  );

  return (
    <form className={clsx(show ? 'pb-5' : '')} onSubmit={handleSubmit}>
      <div
        className="flex justify-between py-5"
        onClick={() => {
          setShow(!show);
        }}
      >
        <ShiftTitle shift={shift} duration={duration} />
        <div>
          <span className={`inline-flex transform transition-transform ${show && 'rotate-180'}`}>
            <SelectArrow className="text-smena_text" />
          </span>
        </div>
      </div>
      {show && (
        <div className="flex flex-col gap-y-2">
          <ShiftDateStartEnd shift={shift} />
          <div className="sidebar__shift grid-cols-2 gap-y-1">
            <span className="Caption col-span-4">Статус удержания</span>
            <label
              className={clsx(
                'radio-block',
                aprove === ShiftPenaltyStatus.Approved && 'border-primary bg-smena_bb-background'
              )}
            >
              <input
                type="radio"
                name="Approved"
                className="aprove_radio aprove_penalty"
                value={ShiftPenaltyStatus.Approved}
                checked={aprove === ShiftPenaltyStatus.Approved}
                onChange={({ target: { value } }) => {
                  setAprove(value);
                }}
              />
              Подтвердить удержание
            </label>
            <label
              className={clsx(
                'radio-block',
                aprove === ShiftPenaltyStatus.Rejected && 'border-primary bg-smena_bb-background'
              )}
            >
              <input
                type="radio"
                name="Rejected"
                className="aprove_radio aprove_penalty"
                value={ShiftPenaltyStatus.Rejected}
                checked={aprove === ShiftPenaltyStatus.Rejected}
                onChange={({ target: { value } }) => {
                  setAprove(value);
                }}
              />
              Отменить удержание
            </label>
            {aprove === ShiftPenaltyStatus.Rejected && (
              <div className="col-span-4">
                <div
                  className="flex items-center gap-x-2 cursor-pointer mt-3"
                  onClick={() => {
                    setComment(values => ({ ...values, show: !comment.show }));
                  }}
                >
                  <span className="Caption-numbers text-primary">Причина отмены удержания</span>
                  <span className={`transform transition-transform ${comment.show && 'rotate-180'}`}>
                    <SelectArrow className="text-primary" />
                  </span>
                </div>

                {comment.show && (
                  <div className="flex flex-col gap-y-5">
                    <SimpleSelect
                      divClassName=""
                      label="Статус удержания"
                      onChange={({ target: { value } }) => setReason(value)}
                      value={reason || ''}
                      name="reasons"
                      disabledOption={'Все причины'}
                      options={penaltyReasons}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="sidebar__shift gap-y-1">
            <div className="flex items-end gap-x-1">
              <span className="Caption col-span-4 text-smena_text-secondary">Время отмены:</span>
              <span className="Subtitle2 col-span-4">
                {isShitfPenalty && dayjs(isShitfPenalty[0].createdAt).format('DD.MM.YYYY; HH:mm')}
              </span>
            </div>
            <div className="flex items-end gap-x-1 mb-4">
              <span className="Caption col-span-4 text-smena_text-secondary">Удержание объекта:</span>
              <span className="Subtitle2 col-span-4">{isFacilityPenalty && isFacilityPenalty[0].cost} ₽</span>
            </div>
            <div className="col-span-full grid grid-cols-2">
              <div className="flex flex-col">
                <span className="label-primary">Удержание, ₽</span>
                <span className="Table-small text-smena_text-secondary mt-2">{costResult} ₽</span>
              </div>

              <div className="flex flex-col">
                <SimpleInput
                  divClassName="col-span-1"
                  label="Ред. удержание"
                  onChange={e => setCost(e.target.value)}
                  value={cost}
                  type="text"
                  name="cost"
                  placeholder="2000"
                  regText={true}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center">
            <div className="col-span-2 Caption text-smena_text-secondary flex gap-x-1">
              <span>Посл. изменения</span>
              <span>{dayjs(shift.updatedAt).format(RU_DATE)}</span>
            </div>
            <div className="col-span-1 col-start-4 self-end">
              <button type="submit" className="btn-primary_small mt-6">
                Сохранить
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default RefusedShift;
