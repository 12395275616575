export const validateInn = (inn: string) => {
  const splitInn = inn.split('').filter(el => el !== ' ');

  const checkDigit = function (inn: string[], coefficients: number[]) {
    let n = 0;
    for (const i in coefficients) {
      n += coefficients[i] * Number(inn[i]);
    }
    return (n % 11) % 10;
  };

  const n11 = checkDigit(splitInn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
  const n12 = checkDigit(splitInn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);

  if (n11 === parseInt(splitInn[10]) && n12 === parseInt(splitInn[11])) {
    return false;
  }
  return true;
};

export const validateRs = (rs: string, bik: string) => {
  const bikRs = bik.toString().slice(-3) + rs;
  let checksum = 0;
  const coefficients = [
    7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1,
  ];
  for (let i in coefficients) {
    checksum += coefficients[i] * (Number(bikRs[i]) % 10);
  }
  if (checksum % 10 === 0) {
    return false;
  }
  return true;
};
export const validateKs = (ks: string, bik: string) => {
  const bikKs = '0' + bik.toString().slice(4, 6) + ks;
  let checksum = 0;
  var coefficients = [
    7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1,
  ];
  for (let i in coefficients) {
    checksum += coefficients[i] * (Number(bikKs[i]) % 10);
  }
  if (checksum % 10 === 0) {
    return false;
  }
  return true;
};

export const validateCardNumber = (value: string) => {
  let ch = 0;
  const num = String(value).replace(/\D/g, '');

  const isOdd = num.length % 2 !== 0;

  if ('' === num) return false;

  for (let i = 0; i < num.length; i++) {
    let n = parseInt(num[i], 10);
    ch += (isOdd || 0) === i % 2 && 9 < (n *= 2) ? n - 9 : n;
  }

  return 0 === ch % 10;
};
