import { useMemo } from 'react';
import {
  ApolloClient,
  // createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
// import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
// import { getAccessTokenAsync } from './auth';
import { createUploadLink } from 'apollo-upload-client';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

interface Definintion {
  kind: string;
  operation?: string;
}

function createApolloClient() {
  const httpUploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_PUBLIC_DOMAIN}/graphql`, // Server URL (must be absolute)
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const accessToken = localStorage.getItem('accessToken');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  return new ApolloClient({
    // ssrMode: typeof window === 'undefined',
    link:
      typeof window === 'undefined'
        ? authLink.concat(httpUploadLink)
        : split(
            ({ query }) => {
              const { kind, operation }: Definintion = getMainDefinition(query);
              return (
                kind === 'OperationDefinition' && operation === 'subscription'
              );
            },
            new WebSocketLink({
              uri: `${process.env.REACT_APP_PUBLIC_WSS_DOMAIN}/graphql`,
              options: {
                reconnect: true,
                reconnectionAttempts: 2,
                connectionParams: () => {
                  const ACCESS_TOKEN = 'accessToken';
                  const accessToken = localStorage.getItem(ACCESS_TOKEN);
                  return { accessToken };
                },
              },
            }),
            authLink.concat(httpUploadLink)
          ),
    cache: new InMemoryCache({
      dataIdFromObject: () => undefined,
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState || {}, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: { cache: { extract: () => any } },
  pageProps: { props: { [x: string]: any } }
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: { [x: string]: any }) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
