import { InputBase, MenuItem, Select } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import FakeArrow from '../../Assets/icons/fakeArrow';

import MainLayout from '../../components/Layout/main';
import SimpleCheckbox from '../../components/ui/Checkbox';
import SimpleInput from '../../components/ui/Input';
import SimpleSelect from '../../components/ui/Select';
import { ErrorNotification } from '../../components/ui/ErrorNotification';

import {
  PayoutType,
  UnitType,
  useCreateVacancyMutation,
  useUpdateVacancyMutation,
  useVacancyQuery,
  VacanciesDocument,
  VacanciesQueryVariables,
} from '../../generated/graphql';

import { useStore } from '../../store';

import { filterFacilities } from '../../utils/filters/filterFacilities';
import { errorToast, successToast } from '../../utils/notify';
import { schedules } from '../../utils/lists/schedule';
import { payoutTypes } from '../../utils/lists/payoutTypes';
import { hourlyUnit, units } from '../../utils/lists/units';
import { errorHandler } from '../../utils/errorHandler';
import { isInt } from '../../utils/isInt';

const UpdateVacancy = () => {
  const router = useParams();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  let facilityIdParams = params.get('facilityId');

  let vacancyId = '';
  let facilityId = '';

  if (facilityIdParams) {
    facilityId = facilityIdParams;
  }

  if (typeof router.vacancyId === 'string') {
    vacancyId = router.vacancyId;
  }

  const { groups, groupsLoading, facilities, facilitiesLoading, positions } = useStore();

  const { data } = useVacancyQuery({
    variables: { vacancyId },
  });

  const vacancyQueryConfig: {
    query: typeof VacanciesDocument;
    variables: VacanciesQueryVariables;
  } = {
    query: VacanciesDocument,
    variables: {
      facilityId: data?.vacancy.facilityId,
    },
  };
  const vacancy = data?.vacancy;

  const [createVacancy] = useCreateVacancyMutation({
    awaitRefetchQueries: true,
    refetchQueries: [vacancyQueryConfig],
  });

  const [updateVacancy] = useUpdateVacancyMutation();

  const title = () => {
    if (vacancy?.id) {
      return `Редактировать вакансию ${vacancy?.position?.name}`;
    } else {
      return 'Новая вакансия';
    }
  };

  return (
    <MainLayout
      title={title()}
      bg="bg-smena_bb-background_base"
      breadCrumbs={[
        { path: '/vacancies', name: 'Вакансии' },
        vacancyId
          ? { path: '/vacancies/' + vacancyId, name: 'Редактировать вакансию' }
          : { path: '/vacancies/create', name: 'Новая вакансия' },
      ]}
    >
      <Formik
        enableReinitialize
        initialValues={{
          facilityId: facilityId || vacancy?.facilityId || '',
          group: vacancy?.facility?.facilityGroupId || '',
          schedule: vacancy?.schedule.split(', ') || [],
          positionId: vacancy?.positionId || '',
          timeStart: vacancy?.timeStart || '',
          timeEnd: vacancy?.timeEnd || '',
          period: vacancy?.period || '',
          section: vacancy?.section || '',
          payout: vacancy?.payout || 'HOURLY',
          unit: vacancy?.unit || 'HOUR',
          hourRate: String(isInt(vacancy?.hourRate)) || '0',
          isActive: vacancy?.is_active || false,
        }}
        validate={values => {
          let errors: any = {};

          if (!values.facilityId) {
            errors.facilityId = 'Вы не указали объект';
          }

          if (!values.positionId) {
            errors.positionId = 'Вы не указали должность';
          }

          if (!values.timeStart) {
            errors.timeStart = 'Вы не указали время начала';
          } else {
            if (
              values.timeStart.split(', ').filter(el => !/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(el)).length >
              0
            ) {
              errors.timeStart = 'Укажите время формате 00:00';
            }
          }

          if (!values.timeEnd) {
            errors.timeEnd = 'Вы не указали время окончания';
          } else if (
            values.timeEnd.split(', ').filter(el => !/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(el)).length > 0
          ) {
            errors.timeEnd = 'Укажите время формате 00:00';
          }

          if (!values.period) {
            errors.period = 'Вы не указали период';
          }

          if (values.schedule.length === 0) {
            errors.schedule = 'Выберите значение графика';
          }

          if (!values.hourRate) {
            errors.hourRate = 'Вы не указали ставку';
          }

          if (Number(values.hourRate) === 0) {
            errors.hourRate = 'Ставка не может быть равна 0';
          }
          if (Number(values.hourRate) >= 1000) {
            errors.hourRate = 'Ставка не может быть больше 1000';
          }

          return errors;
        }}
        onSubmit={values => {
          const {
            facilityId,
            positionId,
            timeStart,
            timeEnd,
            period,
            section,
            hourRate,
            schedule,
            isActive,
            payout,
            unit,
          } = values;

          const vacancyVariables = {
            facilityId,
            positionId,
            timeStart: timeStart.trim(),
            timeEnd: timeEnd.trim(),
            period: period.toUpperCase(),
            schedule: schedule.join(', '),
            section: section.trim(),
            payout: payout as PayoutType,
            unit: unit as UnitType,
            hourRate: parseFloat(hourRate),
            isActive: isActive,
          };
          if (vacancy?.id) {
            updateVacancy({
              variables: {
                id: vacancyId,
                input: {
                  ...vacancyVariables,
                },
              },
            })
              .then(e => {
                if (e.data?.updateVacancy) {
                  successToast('Вакансия изменена');
                }
              })
              .catch(e => {
                if (JSON.stringify(e).includes('Int cannot represent non 32-bit signed integer value')) {
                  errorToast('Вы ввели слишком большое число в поле Ставка');
                } else errorToast(errorHandler(e));
              });
          } else {
            createVacancy({
              variables: {
                input: {
                  ...vacancyVariables,
                },
              },
            })
              .then(e => {
                if (e.data?.createVacancy) {
                  successToast('Вакансия создана');
                  setTimeout(() => {
                    navigate(`/vacancies/${e.data?.createVacancy.id}`);
                  }, 2000);
                }
              })
              .catch(e => {
                if (JSON.stringify(e).includes('Int cannot represent non 32-bit signed integer value')) {
                  errorToast('Вы ввели слишком большое число в поле Ставка');
                } else errorToast(errorHandler(e));
              });
          }
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, isSubmitting, setFieldValue }) => {
          const filteredFacilities = filterFacilities(values.group, facilities);

          const isAllSelected = schedules.length > 0 && values.schedule.length === schedules.length;
          return (
            <Form className="grid grid-cols-12 gap-y-5">
              <div className="vacancyPageBlock blockTemplate">
                <span className="Subtitle1 text-smena_text-secondary">Основная информация</span>
                <div className="flex flex-col gap-y-4">
                  <div className="flex gap-5 flex-wrap">
                    <SimpleSelect
                      divClassName="vacancyInputLong"
                      label="Группа"
                      onChange={e => {
                        setFieldValue('facilityId', '');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.group}
                      name="group"
                      autoComplete="group"
                      disabledOption="Не выбрана"
                      options={groups}
                    />
                    <SimpleSelect
                      divClassName="vacancyInput260"
                      label="Объект"
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.facilityId}
                      name="facilityId"
                      autoComplete="facilityId"
                      disabledOption="Не выбран"
                      options={facilitiesLoading || groupsLoading ? [] : filteredFacilities}
                      validation={true}
                      error={touched.facilityId ? errors.facilityId : ''}
                    />
                    <SimpleInput
                      divClassName="input230"
                      label="Отдел или секция"
                      required={false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.section}
                      type="text"
                      name="section"
                    />
                  </div>
                  <div className="flex gap-5 flex-wrap">
                    <SimpleSelect
                      divClassName="vacancyInputLong"
                      label="Должность"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.positionId}
                      name="positionId"
                      autoComplete="positionId"
                      disabledOption="Не выбран"
                      options={positions}
                      validation={true}
                      error={touched.positionId ? errors.positionId : ''}
                    />

                    <SimpleSelect
                      divClassName="vacancyInput180"
                      label="Тип оплаты"
                      onChange={e => {
                        handleChange(e);
                        if (e.target.value === 'PIECEWORK') {
                          setFieldValue('unit', 'PIECE');
                        } else {
                          setFieldValue('unit', 'HOUR');
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.payout}
                      name="payout"
                      autoComplete="payout"
                      options={payoutTypes}
                      validation={true}
                      error={touched.payout ? errors.payout : ''}
                    />
                    <SimpleSelect
                      divClassName="vacancyInput180"
                      label="Ед. измерения"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.unit}
                      name="unit"
                      autoComplete="unit"
                      options={values.payout === 'HOURLY' ? hourlyUnit : units}
                      validation={true}
                      error={touched.unit ? errors.unit : ''}
                    />

                    <SimpleInput
                      divClassName="vacancyInput110"
                      label="Ставка, ₽"
                      required={false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      float
                      value={values.hourRate}
                      type="text"
                      name="hourRate"
                      error={touched.hourRate ? errors.hourRate : ''}
                    />
                  </div>
                  <div className="flex gap-5 flex-wrap">
                    <div className="vacancyInputLong inputHeight">
                      <label htmlFor="schedule" className="label-primary pb-1">
                        График
                      </label>
                      <Select
                        id="schedule"
                        multiple
                        displayEmpty
                        IconComponent={FakeArrow}
                        value={values.schedule}
                        error={Boolean(touched.schedule && errors.schedule)}
                        renderValue={selected => {
                          if (selected.length === 0) {
                            return <span>Все графики</span>;
                          }
                          return selected.join(', ');
                        }}
                        onChange={event => {
                          const value = event.target.value;
                          if (value[value.length - 1] === 'all') {
                            setFieldValue('schedule', schedules);
                          } else {
                            setFieldValue('schedule', typeof value === 'string' ? value.split(',') : value);
                          }
                        }}
                        input={<InputBase className="multySelect" />}
                      >
                        <MenuItem value="all" className={isAllSelected ? 'Mui-selected' : ''}>
                          <span>Все графики</span>
                        </MenuItem>
                        {schedules.map(type => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.schedule && errors.schedule && typeof errors.schedule === 'string' && (
                        <ErrorNotification text={errors.schedule} />
                      )}
                    </div>
                    <SimpleInput
                      divClassName="vacancyInput180"
                      label="Время начала смены"
                      required={false}
                      onChange={e => {
                        const { value } = e.target;
                        if (/^[\d:, ]+$/.test(value) || value === '') {
                          setFieldValue('timeStart', value.replace(/(,)(\d)/, '$1 $2'));
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.timeStart}
                      type="text"
                      name="timeStart"
                      placeholder="06:00, 07:30"
                      validation={true}
                      error={touched.timeStart ? errors.timeStart : ''}
                    />

                    <SimpleInput
                      divClassName="vacancyInput180"
                      label="Время окончания смены"
                      placeholder="20:00, 21:30"
                      required={false}
                      onChange={e => {
                        const { value } = e.target;
                        if (/^[\d:, ]+$/.test(value) || value === '') {
                          setFieldValue('timeEnd', value.replace(/(,)(\d)/, '$1 $2'));
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.timeEnd}
                      type="text"
                      name="timeEnd"
                      validation={true}
                      error={touched.timeEnd ? errors.timeEnd : ''}
                    />

                    <div className="vacancyInput110 inputHeight">
                      <label htmlFor="period" className="label-primary">
                        Период
                      </label>
                      <select
                        required={false}
                        className={touched.period && errors.period ? 'select-error' : 'select-primary'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.period}
                        id="period"
                        name="period"
                      >
                        <option value="" disabled>
                          День/Ночь
                        </option>
                        <option value="DAY">День</option>
                        <option value="NIGHT">Ночь</option>
                      </select>
                      {touched.period && errors.period && <ErrorNotification text={errors.period} />}
                    </div>
                  </div>
                  <div className="flex">
                    <SimpleCheckbox
                      divClassName="flex items-center"
                      label="Активная"
                      required={false}
                      onChange={handleChange}
                      checked={values.isActive}
                      name="isActive"
                    />
                  </div>
                </div>
              </div>

              <div className="vacancyPageBlock justify-self-end">
                <button type="submit" disabled={isSubmitting} className="btn-primary_big">
                  <span>{isSubmitting ? 'Загрузка' : vacancy?.id ? 'Изменить' : 'Создать'}</span>
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdateVacancy;
