import { UserDocument, UserQueryVariables } from '../../generated/graphql';

interface IUserQueryConfig {
  query: typeof UserDocument;
  variables: UserQueryVariables;
}

export const userQueryConfigFn = (id?: string): IUserQueryConfig => ({
  query: UserDocument,
  variables: {
    userId: id || '',
  },
});
