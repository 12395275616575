import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import clsx from 'clsx';

import Plus from '../../Assets/icons/plus';

import MainLayout from '../../components/Layout/main';
import { useGetZayavkaQuery } from '../../generated/graphql';
import { useStore } from '../../store';

import Loading from 'src/components/ui/Loading';

import RequestProfession from 'src/components/Request/EditRequest/RequestProfession';
import RequestProfessionDates from 'src/components/Request/EditRequest/RequestProfessionDates';
import CreateNewSchedule from 'src/components/Request/EditRequest/Sidebar/createNewSchedule';
import EditSchedule from 'src/components/Request/EditRequest/Sidebar/editSchedule';
// import RequestPlan from './RequestPlan';
import { breadcrumbsFn, requestDateFn } from './helpers';
import { useCanEditRequest, useRequestsSchedule } from './hooks';
import RequestInformation from '../../components/Request/Information';
import RequestDay from '../../components/Request/Days';
import EditShiftSidebar from '../../components/Request/EditRequest/Sidebar/EditShiftSidebar';
import ViewShiftSidebar from '../../components/Request/EditRequest/Sidebar/ViewShiftSidebar';
import NewShiftSidebar from '../../components/Request/EditRequest/Sidebar/NewShiftSidebar';
import { daysCount } from '../../utils/differenceBetweenDays';

const EditRequest = () => {
  const { setRequest } = useStore();

  const [showEditGraphicSidebar, setShowEditGraphicSidebar] = useState(false);
  const [showCreateGraphicSidebar, setShowCreateGraphicSidebar] = useState(false);

  const router = useParams();

  let requestIdURL = '';

  if (typeof router.requestId === 'string') {
    requestIdURL = router.requestId;
  }
  let facilityURL = '';

  if (typeof router.facilityId === 'string') {
    facilityURL = router.facilityId;
  }

  const { data: requestData, loading: requestLoading } = useGetZayavkaQuery({
    variables: {
      id: requestIdURL,
    },
  });

  const request = requestData?.getZayavka;

  const days = useMemo(
    () => daysCount({ dateFrom: request?.dateFrom, dateTo: request?.dateTo }),
    [request?.dateFrom, request?.dateTo]
  );

  const title = useMemo(() => `Заявка №${request?.number}`, [request?.number]);

  const requestDate = useMemo(
    () => requestDateFn({ dateFrom: request?.dateFrom, dateTo: request?.dateTo }),
    [request?.dateFrom, request?.dateTo]
  );

  const requestsSchedule = useRequestsSchedule();

  const canEditRequest = useCanEditRequest();

  // const planShifts = useMemo(
  //   () =>
  //     request?.plans.map(el => ({
  //       positionId: el.positionId,
  //       shifts: requestsSchedule
  //         ?.filter(schedule => schedule?.positionId === el.positionId)
  //         .map(shift => shift.zayavkaShift)
  //         .flat(),
  //     })),
  //   [requestsSchedule, request?.plans]
  // );

  const breadcrumbs = useMemo(() => breadcrumbsFn({ facilityURL, requestIdURL }), [facilityURL, requestIdURL]);

  useEffect(() => {
    if (request) {
      setRequest(request);
    }
  }, [request, setRequest]);

  if (requestLoading) {
    return <Loading />;
  }

  return (
    <>
      <MainLayout title={title} bg="bg-smena_bb-background_base" breadCrumbs={breadcrumbs}>
        <div className="grid grid-cols-12 gap-6">
          <div className="xl:col-span-9 col-span-full p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-6">
            <h3 className="Subtitle1 text-smena_text-secondary">Информация</h3>
            <RequestInformation request={request} />

            {/*<hr className="border-smena_gray-30" />*/}
            {/*<div className="flex gap-x-6">*/}
            {/*  {request?.plans.map(plan => (*/}
            {/*    <RequestPlan key={plan.id} plan={plan} planShifts={planShifts} />*/}
            {/*  ))}*/}
            {/*</div>*/}
          </div>
          <div className="xl:col-span-9 col-span-full pb-6 p-5 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-6">
            <div className="flex items-center justify-between">
              <h3 className="Subtitle1 text-smena_text-secondary">Планирование заявки</h3>
              <span>{requestDate}</span>
            </div>
            {request?.zayavkaSchedule?.length === 0 ? (
              <div className="flex flex-col items-center gap-y-2 py-4">
                <div className="Subtitle2 text-smena_text-secondary">В заявке пока нет графиков</div>
                <div className="Body2 text-smena_text-secondary w-56 text-center">
                  Чтобы добавить график, нажмите «Добавить график смен»
                </div>
              </div>
            ) : (
              <div className="requestGraphic">
                <div>
                  <div className="border-b py-0.5 pl-1 flex">
                    <span className="Tag">Профессии</span>
                  </div>
                  {requestsSchedule?.map(requestSchedule => (
                    <RequestProfession
                      key={requestSchedule.id}
                      requestSchedule={requestSchedule}
                      setShowEditGraphicSidebar={setShowEditGraphicSidebar}
                      setShowCreateGraphicSidebar={setShowCreateGraphicSidebar}
                    />
                  ))}
                </div>
                <ScrollSync>
                  <div className="overflow-auto max-w-max">
                    <ScrollSyncPane>
                      <div
                        className={clsx(
                          'gap-x-1 grid grid-flow-col py-0.5 border-b sticky top-0 z-20 bg-smena_white w-fit'
                        )}
                        style={{
                          gridTemplateColumns: `repeat(${days.length}, 80px)`,
                        }}
                      >
                        {days.map(day => (
                          <RequestDay day={day} key={day.valueOf()} />
                        ))}
                      </div>
                    </ScrollSyncPane>
                    {requestsSchedule?.map(requestSchedule => (
                      <RequestProfessionDates
                        key={requestSchedule.id}
                        requestSchedule={requestSchedule}
                        days={days}
                        setShowEditGraphicSidebar={setShowEditGraphicSidebar}
                      />
                    ))}
                  </div>
                </ScrollSync>
              </div>
            )}

            {canEditRequest && (
              <div>
                <button
                  className="flex items-center gap-x-2 Button1 text-primary"
                  onClick={() => {
                    setShowCreateGraphicSidebar(value => !value);
                    setShowEditGraphicSidebar(false);
                  }}
                >
                  <span>
                    <Plus className="text-primary" />
                  </span>
                  <span>Добавить график смен</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </MainLayout>
      <CreateNewSchedule
        showCreateGraphicSidebar={showCreateGraphicSidebar}
        setShowCreateGraphicSidebar={setShowCreateGraphicSidebar}
      />
      <EditSchedule
        showEditGraphicSidebar={showEditGraphicSidebar}
        setShowEditGraphicSidebar={setShowEditGraphicSidebar}
      />
      <EditShiftSidebar />
      <ViewShiftSidebar />
      <NewShiftSidebar />
    </>
  );
};

export default EditRequest;
