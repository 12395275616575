import { useMemo } from 'react';
import { useStore } from 'src/store';
import { roles } from 'src/utils/constVariables';

export const useIsMeAdminOrSupervisor = () => {
  const { me } = useStore();
  return useMemo(() => {
    if (me) return [roles.Admin, roles.Supervisor].includes(me?.role);
    return false;
  }, [me]);
};
