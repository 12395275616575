import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Pagination } from '@mui/material';

import { useZayavkiPaginatedLazyQuery } from '../../../generated/graphql';
import MainLayout from '../../../components/Layout/main';
import { facilityRequestsColumns } from '../../../components/Table/columns';
import SkeletonLayout from '../../../components/Table/Skeleton';
import TableHeader from '../../../components/Table/header';
import RequestsFacilityFilter from 'src/components/Table/Filters/RequestsFacilityFilter';
import RequestFacilityTableRow from 'src/components/Table/Row/RequestFacilityTableRow';
import { roles } from 'src/utils/constVariables';
import { maxTableWidth } from 'src/utils/maxTableWidth';
import useFacilityHeaderMenu from 'src/hooks/headerMenu/useFacilityHeaderMenu';
import useFacilityBreadcrumbs from 'src/hooks/breadcrumbs/useFacilityBreadcrumbs';
import { useMeRole } from '../../../hooks/useRole';

const FacilityRequestsPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);
  const [value, setValue] = useState<moment.Moment[] | null>(null);
  const [params] = useSearchParams();

  const [loadData, { data, loading }] = useZayavkiPaginatedLazyQuery();

  const requestsPaginated = data?.zayavkiPaginated;
  const requests = requestsPaginated?.zayavky;

  let statusParams = params.get('status');
  let dateFrom = params.get('dateFrom');
  let dateTo = params.get('dateTo');

  const location = useLocation();
  const router = useParams();
  let facilityIdURL = '';

  if (typeof router.facilityId === 'string') {
    facilityIdURL = router.facilityId;
  }
  const mutationInput = useMemo(
    () => ({
      page: 1,
      searchQuery: search,
      status,
      sortBy,
      sortWay,
      dateFrom,
      dateTo,
      facilityId: facilityIdURL,
    }),
    [dateFrom, dateTo, facilityIdURL, search, sortBy, sortWay, status]
  );
  const returnData = () => {
    return loadData({
      variables: {
        input: {
          ...mutationInput,
          page,
          dateFrom: value && String(moment(value[0]).valueOf()),
          dateTo: value && String(moment(value[1]).valueOf()),
        },
      },
    });
  };

  const isMeAdmin = useMeRole(roles.Admin);

  const headerMenu = useFacilityHeaderMenu({
    currentLocation: 'Вакансии объекта',
    facilityId: facilityIdURL,
    isMeAdmin,
  });

  const breadCrumbs = useFacilityBreadcrumbs({ facilityId: facilityIdURL });

  const handleChangePage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (statusParams) {
      setStatus(statusParams);
    } else {
      setStatus('');
    }
    if (dateFrom && dateTo) {
      setValue([moment(dateFrom), moment(dateTo)]);
    } else {
      setValue(null);
    }

    loadData({
      variables: {
        input: {
          ...mutationInput,
          dateFrom: dateFrom ? String(moment(dateFrom).valueOf()) : null,
          dateTo: dateTo ? String(moment(dateTo).valueOf()) : null,
          page,
          status: statusParams || '',
        },
      },
    });
  }, [page, sortBy, sortWay, location, statusParams, dateFrom, dateTo, loadData, mutationInput]);

  return (
    <MainLayout title="Заявки" bg="bg-smena_bb-background_base" headerMenu={headerMenu} breadCrumbs={breadCrumbs}>
      <div
        style={{
          maxWidth: maxTableWidth(facilityRequestsColumns) + 'px',
        }}
      >
        <RequestsFacilityFilter
          returnData={returnData}
          value={value}
          status={status}
          setStatus={setStatus}
          setValue={setValue}
          setSearch={setSearch}
          setPage={setPage}
        />
        <div className="flex flex-col shadow-smena rounded-lg">
          <TableHeader
            dataLength={requests?.length}
            columns={facilityRequestsColumns}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortWay={sortWay}
            setSortWay={setSortWay}
          />
          {loading || !requests ? (
            <SkeletonLayout columns={facilityRequestsColumns} />
          ) : requests.length > 0 ? (
            <div className="main-table">
              {requests?.map(request => (
                <RequestFacilityTableRow request={request} key={request.id} columns={facilityRequestsColumns} />
              ))}
            </div>
          ) : (
            <div className="w-full flex justify-center my-5">
              <h3 className="text-smena_text Body1">
                По вашему запросу <span className="font-bold">{search}</span> ничего не нашлось
              </h3>
            </div>
          )}
        </div>
        <div className="py-5">
          {requestsPaginated?.pages !== 0 && (
            <Pagination
              shape="rounded"
              count={requestsPaginated ? requestsPaginated.pages : 10}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default FacilityRequestsPage;
