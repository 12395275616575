import { useCallback, useMemo, useEffect } from 'react';
import { useStore } from '../../../store';
import { roles } from '../../../utils/constVariables';
import { filterFacilities } from '../../../utils/filters/filterFacilities';
import SimpleSelect from '../../ui/Select';
import AcceptedShiftsChart from './AcceptedShifts';
import AllUsersChart from './AllUsers';
import UserLatenessChart from './Latecomers';
import WorkerOnShiftChart from './WorkerOnShift';
import { useMeRole } from '../../../hooks/useRole';
const Trends = () => {
  const { facilities, groups, trendsFacilityId, trendsGroupId, setTrendsFacilityId, setTrendsGroupId, me } = useStore();

  const isMeSupervisor = useMeRole(roles.Supervisor);
  const isMeDirector = useMeRole(roles.ClientDirector);
  const isMeManager = useMeRole(roles.ClientManager);

  const filteredFacilities = useMemo(() => filterFacilities(trendsGroupId, facilities), [facilities, trendsGroupId]);

  const handleFacilityChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
      setTrendsFacilityId(value);
      if (value === 'all') {
        localStorage.setItem('trendsFacilityId', 'all');
      } else {
        localStorage.setItem('trendsFacilityId', value);
      }
    },
    [setTrendsFacilityId]
  );
  const handleGroupChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
      setTrendsFacilityId('all');
      setTrendsGroupId(value);
      if (value === 'all') {
        localStorage.setItem('trendsFacilityGroupId', 'all');
      } else {
        localStorage.setItem('trendsFacilityGroupId', value);
      }
    },
    [setTrendsGroupId, setTrendsFacilityId]
  );

  useEffect(() => {
    const localStorageFacility = localStorage.getItem('trendsFacilityId');
    const localStorageGroup = localStorage.getItem('trendsFacilityGroupId');
    if (localStorageFacility) {
      if (localStorageFacility === 'all') {
        setTrendsFacilityId('all');
      } else {
        setTrendsFacilityId(localStorageFacility);
      }
    }
    if (localStorageGroup) {
      if (localStorageGroup === 'all') {
        setTrendsGroupId('all');
      } else {
        setTrendsGroupId(localStorageGroup);
      }
    }
  }, [setTrendsFacilityId, setTrendsGroupId]);

  useEffect(() => {
    if (groups) {
      if (isMeSupervisor || isMeManager || isMeDirector) {
        if (trendsGroupId === 'all') setTrendsGroupId(groups[0].id);
      }
    }
  }, [groups, isMeDirector, isMeManager, isMeSupervisor, setTrendsGroupId, trendsGroupId]);

  useEffect(() => {
    if (facilities) {
      if (isMeSupervisor || isMeManager) {
        if (trendsFacilityId === 'all') setTrendsFacilityId(facilities[0].id);
      }
    }
  }, [facilities, isMeManager, isMeSupervisor, setTrendsFacilityId, trendsFacilityId]);

  return (
    <div className="trendsBlock">
      <div className="flex justify-between items-center flex-wrap px-5 pt-5">
        <span className="Subtitle1 text-smena_text-secondary">Тренды</span>
        <div className="flex gap-x-5">
          <SimpleSelect
            divClassName="chart-filter"
            label=""
            onChange={handleGroupChange}
            value={trendsGroupId}
            name="groupId"
            allOption={isMeSupervisor || isMeManager || isMeDirector ? undefined : 'Все группы'}
            options={groups}
          />
          <SimpleSelect
            divClassName="chart-filter"
            label=""
            onChange={handleFacilityChange}
            value={trendsFacilityId}
            name="facilityId"
            allOption={isMeSupervisor || isMeManager ? undefined : 'Все объекты'}
            options={filteredFacilities}
          />
        </div>
      </div>
      <div className="chartsBlock">
        <UserLatenessChart />
        <WorkerOnShiftChart />
      </div>
      <div className="chartsBlock">
        <AcceptedShiftsChart />
        {me && [roles.Admin, roles.Supervisor].includes(me.role) && <AllUsersChart />}
      </div>
    </div>
  );
};
export default Trends;
