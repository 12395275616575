import { Icons } from '../../Interfaces/IIcons';

const SelectArrow: React.FC<Icons> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      width="20px"
      height="20px"
      className={`stroke-current ${className}`}
    >
      <path
        // stroke="#6b7280"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6 8l4 4 4-4"
      />
    </svg>
  );
};

export default SelectArrow;
