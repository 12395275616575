import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useUserQuery } from '../../generated/graphql';

import MainLayout from '../../components/Layout/main';
import Loading from '../../components/ui/Loading';
import MainUserInfo from '../../components/users/MainInfo';

import { useStore } from '../../store';
import FacilityAndProfession from '../../components/users/FacilityAndProfession';
import { roles } from '../../utils/constVariables';
import { UserNameSurename } from 'src/utils/userFullName';
import useUserHeaderMenu from 'src/hooks/headerMenu/useUserHeaderMenu';
import useUserBreadcrumbs from 'src/hooks/breadcrumbs/useUserBreadcrumbs';
// import FacilityResponsibility from '../../components/users/FacilityResponsibility';

const UpdateUser: React.FC = () => {
  const router = useParams();

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const { setCurrentUser, setIsUserLoading, me } = useStore();

  const { data, loading } = useUserQuery({
    variables: {
      userId,
    },
  });

  const user = data?.user;

  const isMeAdminSuper = me && [roles.Admin, roles.Supervisor].includes(me.role);
  const isCurrentUserWorkerOrForeman = user && [roles.Worker, roles.Foreman].includes(user.role);

  const crudUserTitle = useMemo(() => {
    if (user) {
      if (isMeAdminSuper) {
        return 'Страница пользователя ' + UserNameSurename(user);
      }
      return 'Страница исполнителя ' + UserNameSurename(user);
    }
    return 'Новый пользователь';
  }, [isMeAdminSuper, user]);

  const headerMenu = useUserHeaderMenu({
    currentLocation: 'Информация',
    userId,
    role: user?.role,
    isMeAdminSuper,
    isCurrentUserWorkerOrForeman,
  });

  const breadCrumbs = useUserBreadcrumbs({ isMeAdminSuper, userId });

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
      setIsUserLoading(false);
    }
  }, [setCurrentUser, setIsUserLoading, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <MainLayout
      title={crudUserTitle}
      bg="bg-smena_bb-background_base"
      headerMenu={headerMenu}
      breadCrumbs={breadCrumbs}
    >
      <div className="grid grid-cols-12 gap-6">
        <MainUserInfo />
        <FacilityAndProfession />
      </div>
    </MainLayout>
  );
};

export default UpdateUser;
