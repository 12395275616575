const Magnifier = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.95262 1.95262C3.20286 0.702379 4.89856 0 6.66667 0C8.43478 0 10.1305 0.702379 11.3807 1.95262C12.631 3.20286 13.3333 4.89856 13.3333 6.66667C13.3333 8.13581 12.8484 9.55495 11.9672 10.7101L15.7397 14.4826C16.0868 14.8297 16.0868 15.3925 15.7397 15.7397C15.3925 16.0868 14.8297 16.0868 14.4826 15.7397L10.7101 11.9672C9.55495 12.8484 8.13581 13.3333 6.66667 13.3333C4.89856 13.3333 3.20286 12.631 1.95262 11.3807C0.702379 10.1305 0 8.43478 0 6.66667C0 4.89856 0.702379 3.20286 1.95262 1.95262ZM6.66667 1.77778C5.37005 1.77778 4.12654 2.29286 3.2097 3.2097C2.29286 4.12654 1.77778 5.37005 1.77778 6.66667C1.77778 7.96328 2.29286 9.20679 3.2097 10.1236C4.12654 11.0405 5.37005 11.5556 6.66667 11.5556C7.96328 11.5556 9.20679 11.0405 10.1236 10.1236C11.0405 9.20679 11.5556 7.96328 11.5556 6.66667C11.5556 5.37005 11.0405 4.12654 10.1236 3.2097C9.20679 2.29286 7.96328 1.77778 6.66667 1.77778Z"
        fill="#1B1F3B"
        fillOpacity="0.4"
      />
    </svg>
  );
};

export default Magnifier;
