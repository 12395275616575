import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DownloadIcon } from '../../../../Assets/icons/download';
import clsx from 'clsx';

import OzonPtExcel from '../../../../Assets/excel/ZagruzkaOZONnovyj_template.xlsx';
import OzonExExcel from '../../../../Assets/excel/Smenavygruzka_3.xlsx';
import AcrossSmall from '../../../../Assets/icons/AcrossSmall';
import {
  ExcelVariant,
  PayoutType,
  UnitType,
  useFacilityCasingQuery,
  useFinishResolveReportUserMutation,
  useResolveModerationUsersQuery,
  useUploadShiftsExcelMutation,
} from '../../../../generated/graphql';
import { useStore } from '../../../../store';
import { errorToast, successToast } from '../../../../utils/notify';
import { errorHandler } from '../../../../utils/errorHandler';
import { moderationReportConfigFn } from 'src/utils/graphqlConfigs/moderationReportConfig';

const OzonBlock = ({ setImportError }: { setImportError: (value: boolean) => void }) => {
  const { moderationReport, setResolveModerationData, setResolveModerationPosition } = useStore();

  const [ozonEx, setOzonEx] = useState<File | null>(null);
  const [ozonPt, setOzonPt] = useState<File | null>(null);

  const OzonExExcelRef = useRef<HTMLInputElement>(null);
  const OzonPtExcelRef = useRef<HTMLInputElement>(null);

  const [uploadShiftsExcel] = useUploadShiftsExcelMutation();

  const moderationReportConfig = moderationReportConfigFn(moderationReport?.getModerationReport.report.id);

  const [finishResolveReportUser] = useFinishResolveReportUserMutation({
    variables: {
      reportId: moderationReport?.getModerationReport.report.id || '',
    },
    awaitRefetchQueries: true,
    refetchQueries: [moderationReportConfig],
  });
  const { data } = useFacilityCasingQuery({
    variables: {
      facilityId: moderationReport?.getModerationReport.report.facility?.id || '',
    },
  });

  const sourceType = useMemo(() => {
    const shtuki = data?.facilityCasings.filter(casing => casing.unit === UnitType.Piece);
    const percent = data?.facilityCasings.filter(casing => casing.unit === UnitType.Point);

    if (shtuki?.length !== 0) {
      return ExcelVariant.CourierOzon;
    }
    if (percent?.length !== 0) {
      return ExcelVariant.OzonPickerNative;
    }
    return null;
  }, [data?.facilityCasings]);

  const { data: resolveData, refetch } = useResolveModerationUsersQuery({
    variables: {
      reportId: moderationReport?.getModerationReport.report.id || '',
    },
  });

  const onChangeEx = useCallback(({ target: { validity, files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (validity.valid && files) {
      const file = files.item(0);
      if (file) {
        setOzonEx(file);
      }
    }
  }, []);

  const onChangePt = useCallback(({ target: { validity, files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (validity.valid && files) {
      const file = files.item(0);
      if (file) {
        setOzonPt(file);
      }
    }
  }, []);

  const getFileName = useCallback((name: string) => {
    const nameArr = name.split('.');
    const extenrionIndex = nameArr.findIndex(el => el === nameArr[nameArr.length - 1]);

    const finalName = nameArr.splice(extenrionIndex, 1);

    return {
      name: nameArr.join('.'),
      extention: finalName[0],
    };
  }, []);

  const piceworkFacilities = useMemo(
    () =>
      data?.facilityCasings
        .filter(casing => casing.payout === PayoutType.Piecework)
        .map(piceworkFacility => piceworkFacility.positionId),
    [data?.facilityCasings]
  );

  const handleUpload = useCallback(() => {
    if (sourceType) {
      uploadShiftsExcel({
        variables: {
          reportId: moderationReport?.getModerationReport.report.id || '',
          sourceType,
          file: ozonPt ? ozonPt : ozonEx,
        },
      })
        .then(() => {
          successToast('Файл успешно загружен');
          if (piceworkFacilities) {
            setResolveModerationPosition(piceworkFacilities);
          }
          refetch({
            reportId: moderationReport?.getModerationReport.report.id || '',
          }).then(refetchRes => {
            if (
              refetchRes.data.resolveModerationUsers.filter(resolveModerationElement => {
                const userNotSelected = resolveModerationElement.userId === null;
                const rowNotDisabled = !resolveModerationElement.disable;
                return userNotSelected && rowNotDisabled;
              }).length
            ) {
              setImportError(true);
            } else {
              finishResolveReportUser()
                .then(() => successToast('Данные сохранены'))
                .catch(error => {
                  errorToast(errorHandler(error));
                });
            }
          });
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    }
  }, [
    finishResolveReportUser,
    moderationReport?.getModerationReport.report.id,
    ozonEx,
    ozonPt,
    piceworkFacilities,
    refetch,
    setImportError,
    setResolveModerationPosition,
    sourceType,
    uploadShiftsExcel,
  ]);

  const conflicts = useMemo(() => {
    if (resolveData?.resolveModerationUsers) {
      return resolveData?.resolveModerationUsers.filter(resolve => resolve.userId === null && !resolve.disable).length;
    }
    return 0;
  }, [resolveData?.resolveModerationUsers]);

  useEffect(() => {
    setResolveModerationData(resolveData?.resolveModerationUsers);
  }, [resolveData?.resolveModerationUsers, setResolveModerationData]);

  return (
    <div className="p-5 h-fit lg:mt-0 mt-5 bg-smena_white shadow-smena rounded-lg flex flex-col justify-between overflow-auto md:overflow-visible">
      <div>
        <div className="ozon-moderation__download-example-block py-4 border-b border-smena_gray-30 Table-H1 text-smena_text-secondary">
          <span>Отчет</span>
          <span>Пример</span>
        </div>
        {sourceType === ExcelVariant.CourierOzon ? (
          <div className="ozon-moderation__download-example-block py-4 px-2">
            <input
              type="file"
              onChange={onChangeEx}
              name="OzonExExcelUpload"
              id="OzonExExcelUpload"
              ref={OzonExExcelRef}
              className="hidden"
              accept=".xlsx, .xls, .csv"
              value=""
            />
            <button
              className={clsx('flex Body2', ozonPt || ozonEx ? '' : 'text-primary')}
              disabled={Boolean(ozonPt || ozonEx)}
              onClick={() => OzonExExcelRef.current?.click()}
            >
              Загрузить OZON EX (курьеры)
              <span className="Caption-small text-smena_text px-1 ml-2 border rounded border-smena_gray-40">.xls</span>
            </button>
            <a href={OzonExExcel} target="_blank" rel="noreferrer">
              <DownloadIcon />
            </a>
            {ozonEx ? (
              <div className="flex items-center gap-x-1 bg-smena_bb-background_light px-3 py-1 my-2 Caption-numbers text-primary rounded-lg">
                <span className="flex items-center">
                  <span className="truncate max-w-[176px]">
                    <span>{getFileName(ozonEx.name).name}</span>
                  </span>
                  <span>.{getFileName(ozonEx.name).extention}</span>
                </span>
                <button
                  onClick={() => {
                    setOzonEx(null);
                  }}
                >
                  <AcrossSmall color="text-primary" />
                </button>
              </div>
            ) : null}
          </div>
        ) : sourceType === ExcelVariant.OzonPickerNative ? (
          <div>
            <input
              type="file"
              onChange={onChangePt}
              name="OzonPtExcelUpload"
              id="OzonPtExcelUpload"
              ref={OzonPtExcelRef}
              className="hidden"
              accept=".xlsx, .xls, .csv"
              value=""
            />
            <div className="ozon-moderation__download-example-block bg-smena_gray-02 py-4 px-2">
              <button
                className={clsx('flex Body2', ozonPt || ozonEx ? '' : 'text-primary')}
                disabled={Boolean(ozonPt || ozonEx)}
                onClick={() => OzonPtExcelRef.current?.click()}
              >
                Загрузить OZON РЦ (выработка)
                <span className="Caption-small text-smena_text px-1 ml-2 border rounded border-smena_gray-40">
                  .xls
                </span>
              </button>
              <a href={OzonPtExcel} target="_blank" rel="noreferrer">
                <DownloadIcon />
              </a>
            </div>
            {ozonPt ? (
              <div className="flex items-center gap-x-1 bg-smena_bb-background_light px-3 py-1 my-2 Caption-numbers text-primary rounded-lg">
                <span className="flex items-center">
                  <span className="truncate" style={{ maxWidth: '11rem' }}>
                    <span>{getFileName(ozonPt.name).name}</span>
                  </span>
                  <span>.{getFileName(ozonPt.name).extention}</span>
                </span>
                <button
                  onClick={() => {
                    setOzonPt(null);
                  }}
                >
                  <AcrossSmall color="text-primary" />
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <span className="Body2 text-smena_text-secondary my-5 block">
            На объекте все професии с часовым типом ставки
          </span>
        )}
      </div>

      <div className="flex flex-col gap-y-4">
        {resolveData && conflicts > 0 && (
          <div>
            <span className="Body1">Последний загруженный отчет</span>
            <div className="flex items-center gap-x-2 Body2 text-smena_text-secondary">
              <div className="flex gap-x-1">
                <span>Нерешенные конфликты:</span>
                <span>{conflicts}</span>
              </div>
              <button
                className="Button2 text-primary"
                onClick={() => {
                  setImportError(true);
                  setResolveModerationData(resolveData.resolveModerationUsers);
                  if (piceworkFacilities) {
                    setResolveModerationPosition(piceworkFacilities);
                  }
                }}
              >
                Решить
              </button>
            </div>
          </div>
        )}

        {ozonPt || ozonEx ? (
          <div>
            <button className="btn-primary_small" onClick={handleUpload}>
              Загрузить
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default OzonBlock;
