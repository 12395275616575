import { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

import { useStore } from '../../../store';
import { sortObj } from '../../../utils/sortObject';
import Across from '../../../Assets/icons/Across';
import Magnifier from '../../../Assets/icons/magnifier';
import Plus from '../../../Assets/icons/plus';
import { getActive } from '../../../utils/get/getActive';
import { cityName } from '../../../utils/filters/filtersName';
import Filter from './Filter';

interface IFacilitiesGroupFilter {
  cityId: string;
  isActive: string | boolean;
  returnData: () => void;
  setSearch: (param: string) => void;
  setPage: (param: number) => void;
  setCityId: (param: string) => void;
  setIsActive: (param: string | boolean) => void;
}

const FacilitiesGroupFilter: React.FC<IFacilitiesGroupFilter> = ({
  returnData,
  setSearch,
  setPage,
  cityId,
  setCityId,
  isActive,
  setIsActive,
}) => {
  const [filter, setFilter] = useState(false);
  const [params, setParams] = useSearchParams();

  const { cities } = useStore();

  let paramsCount = 0;

  let activeParam = params.get('active');
  let cityParam = params.get('cityId');

  const paramCounter = (name: string) => {
    if (params.has(name)) {
      return paramsCount++;
    }
  };

  paramCounter('cityId');
  paramCounter('active');

  const router = useParams();
  let groupId = '';

  if (typeof router.groupId === 'string') {
    groupId = router.groupId;
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value !== '') {
      params.set('search', e.target.value);
      setParams(params);
    } else {
      params.delete('search');
      setParams(params);
    }
    setPage(1);
  };

  const debounceOnChange = debounce(handleChangeSearch, 600);

  const removeParam = (param: string) => {
    if (params.has(param)) {
      params.delete(param);
    } else {
      return;
    }
  };

  const addParams = (paramName: string, param: string) => {
    params.set(paramName, param);
  };

  return (
    <div className="filter-bar flex xs:flex-row flex-col gap-y-2 justify-between">
      <div className="flex flex-col">
        <div className={`flex relative ${paramsCount ? 'mb-2' : ''}`}>
          <button
            type="button"
            className="btn-stroke mr-2 flex gap-x-1 items-center"
            onClick={() => {
              setFilter(value => !value);
            }}
          >
            Фильтр
            {paramsCount ? (
              <span
                className="Button3 text-primary rounded-full bg-primary bg-opacity-20"
                style={{
                  padding: '0px 7.5px',
                }}
              >
                {String(paramsCount)}
              </span>
            ) : null}
          </button>
          <div className="relative w-64">
            <span className="absolute top-0 left-0 transform translate-x-2/4 translate-y-2/4">
              <Magnifier />
            </span>
            <input
              className="input-primary pl-8"
              onChange={debounceOnChange}
              type="search"
              name="search"
              id="search"
              placeholder="Поиск"
            />
          </div>
          {filter && (
            <div className="filter__popup">
              <span
                className="absolute top-0 right-0 cursor-pointer"
                style={{ padding: '15px 15px 0 0' }}
                onClick={() => {
                  setFilter(value => !value);
                }}
              >
                <Across color={'text-smena_gray-90'} />
              </span>
              <span className="H4 text-smena_text mb-3 inline-block">Фильтры</span>

              <div className="mb-3">
                <label htmlFor="city" className="label-primary">
                  Город
                </label>
                <select
                  name="city"
                  id="city"
                  className="select-primary"
                  value={cityId}
                  onChange={e => {
                    setCityId(e.target.value);
                  }}
                >
                  <option value="">Не выбрано</option>
                  {cities &&
                    sortObj(cities).map(city => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="isActive" className="label-primary">
                  Статус активности
                </label>
                <select
                  name="isActive"
                  id="isActive"
                  className="select-primary mt-1"
                  value={String(isActive)}
                  onChange={({ target: { value } }) => {
                    setIsActive('');
                    if (value === 'true') {
                      setIsActive(true);
                    }
                    if (value === 'false') {
                      setIsActive(false);
                    }
                  }}
                >
                  <option value="">Не выбрано</option>
                  <option value="true">Активный</option>
                  <option value="false">Не активный</option>
                </select>
              </div>
              <div className="flex gap-x-5 xs:flex-nowrap flex-wrap gap-y-2">
                <button
                  className="btn-primary xs:w-auto w-full"
                  onClick={() => {
                    if (isActive === '') {
                      removeParam('active');
                    } else {
                      addParams('active', String(isActive));
                    }
                    if (cityId === '') {
                      removeParam('cityId');
                    } else {
                      addParams('cityId', cityId);
                    }
                    setParams(params);
                    setFilter(false);
                    returnData();
                  }}
                >
                  <span>Применить</span>
                </button>
                <button
                  className="btn-stroke xs:w-auto w-full"
                  onClick={() => {
                    setCityId('');
                    setIsActive('');
                    setSearch('');
                    params.delete('active');
                    params.delete('cityId');
                    setParams(params);
                  }}
                >
                  Сбросить
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="flex">
          {cityParam && (
            <Filter
              content={'Город ' + cityName(cities, cityParam)}
              clickHandler={() => {
                setCityId('');
                params.delete('cityId');
                setParams(params);
              }}
            />
          )}

          {activeParam && (
            <Filter
              content={getActive(activeParam)}
              clickHandler={() => {
                setIsActive('');
                params.delete('active');
                setParams(params);
              }}
            />
          )}
        </div>
      </div>

      <div className="flex self-start">
        <Link to={`/facilities/create?groupId=${groupId}`}>
          <button type="button" className="btn-primary_big">
            <span className="flex items-center">
              <span className="inline-flex mr-2">
                <Plus className="text-smena_white" />
              </span>

              <span>Новый объект</span>
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default FacilitiesGroupFilter;
