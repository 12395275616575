import { useMemo } from 'react';
import dayjs from 'dayjs';
import { RegularUserShiftsFragment, ShiftStage } from 'src/generated/graphql';
import { setEndDateSchedule } from 'src/utils/setEndDateSchedule';
import { shiftTitle } from 'src/utils/get/getShiftTitle';

const useTimekeepengShifts = (userShifts?: RegularUserShiftsFragment[]) =>
  useMemo(() => {
    return userShifts
      ?.map(shift => ({
        title: shiftTitle(shift.marks),
        start: dayjs(Number(shift.dateFrom)).toDate(),
        end: setEndDateSchedule(
          dayjs(Number(shift.dateFrom)).toDate(),
          dayjs(Number(shift.dateEnd)).toDate()
        ),
        resource: {
          id: shift.id,
          authorId: shift.authorId || '',
          userId: shift.userId || '',
          dateFrom: shift.dateFrom,
          dateEnd: shift.dateEnd || '',
          facility: shift.facility,
          positionId: shift.positionId || '',
          marks: shift.marks,
          lunchDuration: shift.lunchDuration,
          comment: shift.comment,
          stage: shift.stage,
          rejectedById: shift.rejectedById || '',
          isProbation: shift.isProbation,
        },
      }))
      .filter(
        shift =>
          Number(shift.resource.dateFrom) < dayjs().endOf('day').valueOf() &&
          ![ShiftStage.Refused, ShiftStage.Ineligible, ShiftStage.New].includes(
            shift.resource.stage
          ) &&
          !shift.resource.isProbation
      );
  }, [userShifts]);

export default useTimekeepengShifts;
