import { ICustomEvent } from 'src/Interfaces/IEvent';
import LegendRow from './Row';

interface ILegendShifts {
  [key: string]: ICustomEvent[] | undefined;
}
export const LegendShifts: React.FC<ILegendShifts> = ({
  allShifts,
  goodShifts,
  badShifts,
  withoutMarks,
  workingShifts,
}) => {
  return (
    <div className="mt-11 ml-3 flex flex-col gap-y-5">
      <div className="flex items-center gap-x-1">
        <span className="Table-small text-smena_text-secondary">
          Всего смен:
        </span>
        <span className="Caption-numbers text-smena_text">
          {allShifts?.length}
        </span>
      </div>

      <div className="flex flex-col gap-y-4">
        <LegendRow
          text="«Хорошие» смены"
          bgColor="bg-smena_green-light"
          array={goodShifts}
        />
        <LegendRow
          text="«Плохие» смены"
          bgColor="bg-smena_red-light"
          array={badShifts}
        />
        <LegendRow
          text="Смена без одной или больше отметок"
          bgColor="bg-smena_orange-light"
          array={withoutMarks}
        />
        <LegendRow
          text="В работе"
          bgColor="bg-primary-secondary"
          array={workingShifts}
        />
        <div className="flex items-center">
          <span
            style={{ minWidth: '14px', minHeight: '14px' }}
            className="rounded-full bg-smena_green-light mr-2"
          ></span>
          <span className="Caption-numbers text-smena_text mr-2 w-4">
            {goodShifts?.length}
          </span>
          <span className="Table-small text-smena_text-secondary">
            «Хорошие» смены
          </span>
        </div>
        <div className="flex items-center">
          <span
            style={{ minWidth: '14px', minHeight: '14px' }}
            className="rounded-full bg-smena_red-light mr-2"
          ></span>
          <span className="Caption-numbers text-smena_text mr-2 w-4">
            {badShifts?.length}
          </span>
          <span className="Table-small text-smena_text-secondary">
            «Плохие» смены
          </span>
        </div>
        <div className="flex items-center">
          <span
            style={{ minWidth: '14px', minHeight: '14px' }}
            className="rounded-full bg-smena_orange-light mr-2"
          ></span>
          <span className="Caption-numbers text-smena_text mr-2 w-4">
            {withoutMarks?.length}
          </span>
          <span className="Table-small text-smena_text-secondary">
            Смена без одной или больше отметок
          </span>
        </div>
        <div className="flex items-center">
          <span
            style={{ minWidth: '14px', minHeight: '14px' }}
            className="rounded-full bg-primary-secondary mr-2"
          ></span>
          <span className="Caption-numbers text-smena_text mr-2 w-4">
            {workingShifts?.length}
          </span>
          <span className="Table-small text-smena_text-secondary">
            В работе
          </span>
        </div>
      </div>
    </div>
  );
};
