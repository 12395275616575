import { useMemo } from 'react';
import dayjs from 'dayjs';

// import { RegularZayavkaProgressFragment } from '../../generated/graphql';
import { useStore } from '../../store';
import { positionName } from '../../utils/filters/filtersName';
import { useMeRole } from '../../hooks/useRole';
import { roles } from '../../utils/constVariables';

export const useRequestsSchedule = () => {
  const { positions, request } = useStore();
  const copyOfRequest = useMemo(
    () => (request?.zayavkaSchedule ? [...request?.zayavkaSchedule] : []),
    [request?.zayavkaSchedule]
  );
  return useMemo(
    () =>
      copyOfRequest?.sort((a, b) => {
        if (a && b) {
          if (positionName(positions, a.positionId) < positionName(positions, b.positionId)) {
            return -1;
          }
          if (positionName(positions, a.positionId) > positionName(positions, b.positionId)) {
            return 1;
          }
          if (dayjs(a.dateFrom).valueOf() < dayjs(b.dateFrom).valueOf()) {
            return -1;
          }
          if (dayjs(a.dateFrom).valueOf() > dayjs(b.dateFrom).valueOf()) {
            return 1;
          }
        }
        return 0;
      }),
    [copyOfRequest, positions]
  );
};

export const useCanEditRequest = () => {
  const { request, me } = useStore();
  const isMeSupervisor = useMeRole(roles.Supervisor);

  return useMemo(() => {
    if (isMeSupervisor) {
      // const requestHasNotClientManagers = request?.facility?.clientManagers?.length === 0;
      // const requestHasNotClientDirectors = request?.facility?.facilityGroup?.clientDirectors?.length === 0;
      // const requestHasNotClients = requestHasNotClientManagers && requestHasNotClientDirectors;

      // return isMeRequestAuthor && requestHasNotClients;
      return request?.authorId === me?.id;
    }
    return true;
  }, [isMeSupervisor, me?.id, request?.authorId]);
};
