import React, { useCallback, useEffect } from 'react';
import { useStore } from 'src/store';

export const RowCheckbox: React.FC<{
  id: string;
}> = ({ id }) => {
  const { editScheduleRequestsIds, scheduleRequestsIds } = useStore();

  const [checkbox, setCheckbox] = React.useState(false);

  const checkboxHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setCheckbox(checked);
      editScheduleRequestsIds([id]);
    },
    [editScheduleRequestsIds, id]
  );

  useEffect(() => {
    setCheckbox(scheduleRequestsIds.includes(id));
  }, [scheduleRequestsIds, id]);

  return (
    <input type="checkbox" name="reportId" className="checkbox-primary" checked={checkbox} onChange={checkboxHandler} />
  );
};
