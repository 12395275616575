import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { useStore } from '../../../store';

import CustomEvent from '../CustomEvent';
import CustomToolbar from '../CustomToolbar';
import useRefusedShifts from '../hooks/useRefusedShifts';
import useIneligibleShifts from '../hooks/useIneligibleShifts';
import { LegendSchedule } from './Legend';
import { scheduleClassName } from '../../../utils/scheduleClassName';
import { shiftExist } from '../../../utils/scheduleFns';
import SchedulePopup from './Popup';
import { ICustomEvent } from 'src/Interfaces/IEvent';
import useProbationShifts from '../hooks/useProbationShifts';
import { ISO_DATE, roles } from 'src/utils/constVariables';
import useMainEvents from '../hooks/useScheduleMainEvents';
import useRestShits from '../hooks/useRestShits';
import showSelectedShiftsAtSidebar from '../helpers/showSelectedShiftsAtSidebar';
import onSelectSlotHandler from '../helpers/onSelectSlotHandler';
import { useUserShiftsLazyQuery } from 'src/generated/graphql';
import useShiftsWithoutCanceled from '../hooks/useShiftsWithoutCanceled';
import useSupervisorShifts from '../Shifts/hooks/useSupervisorShifts';
import useNotAcceptedByForemanShifts from '../hooks/useNotAcceptedByForemanShifts';
import useNotAcceptedByWorkerShifts from '../hooks/useNotAcceptedByWorkerShifts';
import useAcceptedShifts from '../hooks/useAcceptedShifts';
import useUserShifts from '../Shifts/hooks/useUserShifts';
import { useMeRole } from '../../../hooks/useRole';

moment.locale('ru');

const localizer = momentLocalizer(moment);
const CalendarMain = () => {
  const [dateFrom, setDateFrom] = useState(dayjs().startOf('month'));
  const [dateTo, setDateTo] = useState(dayjs().endOf('month'));
  const [popup, setPopup] = useState(false);

  const router = useParams();
  const location = useLocation();
  const [params, setParams] = useSearchParams();

  const {
    typeSchedule,
    customScheduleEvents,
    setCustomScheduleEvents,
    nonSuperShifts,
    setNonSuperShifts,
    setSuperShifts,
    facilityId,
    workpostId,
    showSidebar,
    setShowSidebar,
    selectedSchedule,
    setSelectedSchedule,
    setCreateScheduleMenu,
    hiddenCanceledShifts,
    me,
    scheduleEvent,
    addCustomScheduleEvents,
    lunchDuration,
  } = useStore();

  let userId: string = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }

  const isMeSupervisor = useMeRole(roles.Supervisor);

  const [loadData, { data: dataShifts }] = useUserShiftsLazyQuery();

  const userShifts = useUserShifts({ userShifts: dataShifts?.userShifts });

  const notProbationUserShift = useMemo(() => userShifts?.filter(shift => !shift.isProbation), [userShifts]);

  const { mainEvents, mainEventsWithoutCanceled } = useMainEvents(userShifts);

  const shiftsWithoutCanceled = useShiftsWithoutCanceled(userShifts);

  const refusedShifts = useRefusedShifts({ userShifts });

  const ineligibleShifts = useIneligibleShifts({ userShifts });

  const probationShifts = useProbationShifts({ userShifts });

  const acceptedShifts = useAcceptedShifts({ userShifts });

  const notAcceptedByForemanShifts = useNotAcceptedByForemanShifts({
    shiftsWithoutCanceled,
    notProbationUserShift,
  });

  const notAcceptedByWorkerShifts = useNotAcceptedByWorkerShifts({
    shiftsWithoutCanceled,
    notProbationUserShift,
  });

  const restShifts = useRestShits({ userShifts });

  const customMainEvents = useMemo(() => [...mainEvents, ...customScheduleEvents], [mainEvents, customScheduleEvents]);

  const customMainEventsWithoutCanceled = useMemo(
    () => [...mainEventsWithoutCanceled, ...customScheduleEvents],
    [mainEventsWithoutCanceled, customScheduleEvents]
  );

  const supervisorShifts = useSupervisorShifts({ userShifts });

  const showSelectedShiftsAtSidebarFn = useCallback(
    (start: Date) =>
      showSelectedShiftsAtSidebar({
        start,
        mainEvents,
        selectedSchedule,
        setSelectedSchedule,
        showSidebar,
        setShowSidebar,
        setCreateScheduleMenu,
      }),
    [mainEvents, selectedSchedule, setSelectedSchedule, showSidebar, setShowSidebar, setCreateScheduleMenu]
  );

  const showEvents = useMemo(() => {
    if (typeSchedule === 1) {
      if (hiddenCanceledShifts) {
        return mainEventsWithoutCanceled;
      }
      return mainEvents;
    }
    if (hiddenCanceledShifts) {
      return customMainEventsWithoutCanceled;
    }
    return customMainEvents;
  }, [
    typeSchedule,
    mainEvents,
    mainEventsWithoutCanceled,
    customMainEvents,
    customMainEventsWithoutCanceled,
    hiddenCanceledShifts,
  ]);

  const onSelectEventHandler = (obj: ICustomEvent) => {
    const clickOnCustomScheduleEvent = () => {
      if (shiftExist(obj, customScheduleEvents).length > 0) {
        setCustomScheduleEvents(
          customScheduleEvents.filter(event => dayjs(event.start).valueOf() !== dayjs(obj.start).valueOf())
        );
      }
    };

    if (typeSchedule === 2) {
      return clickOnCustomScheduleEvent();
    }
    showSelectedShiftsAtSidebarFn(obj.start);
  };

  const onSelectSlot = useCallback(
    ({ start, end }: { start: Date; end: Date }) =>
      onSelectSlotHandler({
        start,
        end,
        dateFrom,
        dateTo,
        userId,
        workpostId,
        mainEvents,
        customScheduleEvents,
        typeSchedule,
        scheduleEvent,
        me,
        addCustomScheduleEvents,
        lunchDuration,
        showSelectedShiftsAtSidebarFn,
      }),
    [
      dateFrom,
      dateTo,
      userId,
      workpostId,
      mainEvents,
      customScheduleEvents,
      typeSchedule,
      scheduleEvent,
      me,
      addCustomScheduleEvents,
      lunchDuration,
      showSelectedShiftsAtSidebarFn,
    ]
  );

  const onNavigateHandler = (newDate: Date) => {
    const dateFrom = dayjs(newDate).startOf('month');
    const dateTo = dayjs(newDate).endOf('month');

    setDateFrom(dateFrom);
    setDateTo(dateTo);

    params.set('dateFrom', dateFrom.format(ISO_DATE));
    params.set('dateTo', dateTo.format(ISO_DATE));
    setParams(params);
  };

  const onShowMoreHandler = useCallback(
    (shifts: ICustomEvent[]) => {
      if (showSidebar) {
        setCreateScheduleMenu(false);
        setShowSidebar(false);
        setTimeout(() => {
          if (shifts.length > 0) {
            setSelectedSchedule(shifts);
            setShowSidebar(true);
          }
        }, 300);
      } else {
        if (shifts.length > 0) {
          setSelectedSchedule(shifts);
          setShowSidebar(true);
        }
      }
    },
    [setShowSidebar, setCreateScheduleMenu, setSelectedSchedule, showSidebar]
  );

  const mainEventPropGetter = ({ resource }: ICustomEvent): HTMLAttributes<HTMLDivElement> => {
    return scheduleClassName(facilityId, workpostId, resource, nonSuperShifts, isMeSupervisor);
  };

  useEffect(() => {
    loadData({
      variables: {
        input: {
          userId,
          dateFrom: String(Number(dateFrom)),
          dateTo: String(Number(dateTo)),
        },
      },
    });
  }, [dateFrom, dateTo, loadData, userId]);

  useEffect(() => {
    setShowSidebar(false);
    setCreateScheduleMenu(false);
  }, [location, setCreateScheduleMenu, setShowSidebar]);

  useEffect(() => setSuperShifts(supervisorShifts), [supervisorShifts, setSuperShifts]);

  useEffect(() => {
    setNonSuperShifts(restShifts);
  }, [restShifts, setNonSuperShifts]);

  useEffect(() => {
    setSelectedSchedule(undefined);
  }, [setSelectedSchedule]);

  return (
    <>
      <div className="schedule">
        <div className="schedule__wrapper flex flex-wrap">
          <div className="schedule__calendar">
            <Calendar
              selectable
              localizer={localizer}
              formats={{ dateFormat: 'D' }}
              events={showEvents}
              components={{
                event: CustomEvent,
                toolbar: CustomToolbar,
                month: {
                  dateHeader: function dateHeaderFn({ label }) {
                    return <div onClick={undefined}>{label}</div>;
                  },
                },
              }}
              onShowMore={onShowMoreHandler}
              startAccessor="start"
              onSelectEvent={onSelectEventHandler}
              onSelectSlot={onSelectSlot}
              endAccessor="end"
              defaultView="month"
              views={['month']}
              onNavigate={onNavigateHandler}
              eventPropGetter={mainEventPropGetter}
              messages={{
                showMore: target => `...еще ${target}`,
              }}
              doShowMoreDrillDown={false}
            />
          </div>
          <div className="mt-11 ml-3 flex flex-col justify-between">
            <LegendSchedule
              userShifts={userShifts}
              goodShifts={acceptedShifts}
              notAcceptedByForemanShifts={notAcceptedByForemanShifts}
              notAcceptedByWorkerShifts={notAcceptedByWorkerShifts}
              ineligibleShifts={ineligibleShifts}
              refusedShifts={refusedShifts}
              nonSuperShifts={nonSuperShifts}
              probationShifts={probationShifts}
              supervisor={isMeSupervisor}
            />
            {customScheduleEvents.length > 0 && (
              <div className="w-[175px] flex flex-col gap-y-2">
                <button
                  className="btn-secondary_big w-full"
                  onClick={() => {
                    setPopup(true);
                    toast.dismiss('clickYourDays');
                  }}
                >
                  Сохранить
                </button>
                <button
                  className="btn-stroke_big w-full"
                  onClick={() => {
                    setCustomScheduleEvents([]);
                  }}
                >
                  Сбросить все
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {popup && <SchedulePopup setPopup={setPopup} popup={popup} dateFrom={dateFrom} dateTo={dateTo} />}
    </>
  );
};

export default CalendarMain;
