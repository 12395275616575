import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { useStore } from 'src/store';
import { MenuItem } from 'src/types';
import { getNavLinkClassname } from 'src/utils/get/getNavLinkClassname';
// import CustomNavLink from '../../CustomNavLink';

const MenuElement = ({ item }: { item: MenuItem }) => {
  const { menu, hover } = useStore();
  const { id, url, name, icon } = item;

  return (
    <NavLink
      to={url}
      key={id}
      className={({ isActive }) =>
        getNavLinkClassname(isActive, name, menu, hover)
      }
      end={name === 'Главная'}
    >
      <div className="relative">{icon}</div>
      <span className={clsx(menu || hover ? 'flex' : 'hidden', 'ml-[15px]')}>
        {name}
      </span>
    </NavLink>
  );
};

export default MenuElement;
