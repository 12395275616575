import { useStore } from '../../../store';
import { useCallback, useMemo } from 'react';
import { userLastnameAndInitials } from '../../../utils/userLastnameAndInitials';
import dayjs from 'dayjs';
import uniqueArray from '../../../hooks/uniqueArray';
import { positionName } from '../../../utils/filters/filtersName';
import { chain } from 'lodash';

export const useUsersAtScheduleRequest = () => {
  const { scheduleRequests, positions } = useStore();

  const shiftsWithUsers = useMemo(
    () =>
      chain(scheduleRequests).map('zayavkaSchedule').flatten().map('zayavkaShift').flatten().filter('shift').value(),
    [scheduleRequests]
  );

  const usersFromShifts = useMemo(
    () => shiftsWithUsers?.map(requestShift => requestShift?.shift?.user),
    [shiftsWithUsers]
  );

  const uniqueUsers = useMemo(() => uniqueArray({ array: usersFromShifts, field: 'id' }), [usersFromShifts]);

  const usersOptions = useMemo(
    () =>
      uniqueUsers.map(user => ({
        id: user?.id,
        name: userLastnameAndInitials(user),
      })),
    [uniqueUsers]
  );

  const allPositionFromRequest = useMemo(
    () => scheduleRequests?.map(scheduleRequest => scheduleRequest.plans).flat(),
    [scheduleRequests]
  );

  const uniqPositionsFromRequest = useMemo(
    () => uniqueArray({ array: allPositionFromRequest, field: 'positionId' }),
    [allPositionFromRequest]
  );

  const positionOptions = useMemo(
    () =>
      uniqPositionsFromRequest.map(position => ({
        id: position.positionId,
        name: positionName(positions, position.positionId), //fix it
      })),
    [positions, uniqPositionsFromRequest]
  );
  return { usersOptions, positionOptions };
};

export const useActiveRequestSchedule = () => {
  const { scheduleRequests, scheduleRequestsIds, setScheduleRequestsHeaderCheckbox, setScheduleRequestsIds } =
    useStore();
  const activeRequestSchedule = useMemo(() => {
    if (scheduleRequests?.length) {
      return scheduleRequests
        .filter(request => scheduleRequestsIds.includes(request.id))
        .map(scheduleRequest => scheduleRequest.id);
    }
    return [];
  }, [scheduleRequests, scheduleRequestsIds]);

  const checkboxHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setScheduleRequestsHeaderCheckbox(checked);
      if (scheduleRequests) {
        if (checked) {
          setScheduleRequestsIds(scheduleRequests.map(request => request.id));
        } else setScheduleRequestsIds([]);
      }
    },
    [scheduleRequests, setScheduleRequestsHeaderCheckbox, setScheduleRequestsIds]
  );

  // const filteredArr = scheduleRequestsIds
  //   .map(id => scheduleRequests?.filter(scheduleRequest => scheduleRequest.id === id))
  //   .flat();
  //
  // const asd = filteredArr
  //   .map(scheduleRequest => scheduleRequest?.zayavkaSchedule)
  //   .filter(el => el?.length !== 0)
  //   .flat();

  const requestWithSchedule = useMemo(() => {
    if (scheduleRequests) {
      const activeRequests = scheduleRequests.filter(request => activeRequestSchedule.includes(request.id));
      return activeRequests
        ?.map(scheduleRequest => scheduleRequest?.zayavkaSchedule)
        .filter(el => el?.length !== 0)
        .flat();
    }
    return [];
  }, [activeRequestSchedule, scheduleRequests]);

  const sortRequestWithSchedule = useMemo(
    () =>
      requestWithSchedule?.sort((a, b) => {
        if (a && b) {
          if (String(a.position?.name) < String(b.position?.name)) {
            return -1;
          }
          if (String(a.position?.name) > String(b.position?.name)) {
            return 1;
          }
          if (dayjs(a.dateFrom).valueOf() < dayjs(b.dateFrom).valueOf()) {
            return -1;
          }
          if (dayjs(a.dateFrom).valueOf() > dayjs(b.dateFrom).valueOf()) {
            return 1;
          }
        }
        return 0;
      }),
    [requestWithSchedule]
  );

  return { checkboxHandler, requestWithSchedule, sortRequestWithSchedule };
};

export const useFilterRequestWithSchedule = ({ workerId, positionId }: { workerId: string; positionId: string }) => {
  const { sortRequestWithSchedule } = useActiveRequestSchedule();
  return sortRequestWithSchedule?.filter(request => {
    if (workerId) {
      const userScheduleRequest = request?.zayavkaShift?.find(el => el.shift?.userId === workerId);
      return request?.id === userScheduleRequest?.zayavkaScheduleId;
    }

    if (positionId && positionId !== 'all') {
      return request?.positionId === positionId;
    }
    return request;
  });
};
