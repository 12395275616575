import React, { useCallback } from 'react';
import { setTokens } from '../../lib/auth';
import convertPhone from '../../hooks/convertPhone';
import { getRoleRu } from '../../utils/get/getRoleRu';
import { IFastLogin } from './__utils';
import { useLoginByCodeMutation, useLoginMutation } from '../../generated/graphql';
import { useNavigate } from 'react-router-dom';

interface Props {
  entry: IFastLogin;
}

export function FastLoginItem({ entry }: Props) {
  const [loginByPassMutation] = useLoginMutation();
  const [loginByCodeMutation] = useLoginByCodeMutation();
  const navigate = useNavigate();

  const onItemClick = useCallback(async () => {
    const { phone, code, password } = entry;
    if (password) {
      const res = await loginByPassMutation({ variables: { data: { password, phone } } });
      const data = res.data?.login;
      if (data) {
        setTokens({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        });
        navigate('/');
      }
    }
    if (code) {
      const res = await loginByCodeMutation({ variables: { input: { code, phone } } });
      const data = res.data?.loginByCode;
      if (data) {
        setTokens({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        });
        navigate('/');
      }
    }
  }, [entry, loginByCodeMutation, loginByPassMutation, navigate]);

  return (
    <div onClick={onItemClick} className={'hover:bg-gray-100 -mx-5 px-5 cursor-pointer'}>
      <div>{convertPhone(entry.phone)}</div>
      <div>{getRoleRu(entry.role)}</div>
    </div>
  );
}
