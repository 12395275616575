import { Icons } from '../../../Interfaces/IIcons';

const Up: React.FC<Icons> = ({ color }) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${color}`}
    >
      <path d="M0.538169 5.54381L8.26824 5.54381C8.56178 5.54381 8.75748 5.34812 8.75748 5.05457C8.75748 4.95672 8.75748 4.85887 8.65963 4.76102L4.84352 0.162121C4.64783 -0.0335765 4.35428 -0.0335764 4.15858 0.0642725L4.06073 0.162121L0.146773 4.76102C-0.0489245 4.95672 -0.0489245 5.25027 0.146773 5.44597C0.244622 5.54381 0.44032 5.54381 0.538169 5.54381V5.54381Z" />
    </svg>
  );
};

export default Up;
