import {
  RegularModerationShiftFragment,
  ShiftStage,
} from '../generated/graphql';

export const shiftStatus = (shift: RegularModerationShiftFragment) => {
  const { moderation, stage, isProbation } = shift;
  if (isProbation) {
    return {
      text: 'Проб. смена',
      bg: 'bg-smena_purple-light',
      separator: 'text-smena_purple',
    };
  }
  if (stage === ShiftStage.Finished) {
    if (moderation && moderation.toPayment === false) {
      return {
        text: 'Без оплаты',
        bg: 'bg-smena_red-extra_light',
        separator: 'text-smena_red-light',
      };
    }
    return {
      text: 'К оплате',
      bg: 'bg-smena_green-extra_light',
      separator: 'text-smena_green-light',
    };
  }
  if (moderation) {
    if (moderation.toPayment) {
      return {
        text: 'К оплате',
        bg: 'bg-smena_green-extra_light',
        separator: 'text-smena_green-light',
      };
    } else {
      return {
        text: 'Без оплаты',
        bg: 'bg-smena_red-extra_light',
        separator: 'text-smena_red-light',
      };
    }
  } else if (stage === ShiftStage.Refused) {
    return {
      text: 'Отменена',
      bg: 'bg-smena_gray-5',
      separator: 'text-smena_gray-30',
    };
  } else {
    return {
      text: 'К модерации',
      bg: 'bg-smena_yellow-extra_light',
      separator: 'text-smena_yellow-light',
    };
  }
};
