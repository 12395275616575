import {
  ZayavkiPaginatedDocument,
  ZayavkiPaginatedQueryVariables,
} from '../../generated/graphql';

interface IZayavkyConfig {
  query: typeof ZayavkiPaginatedDocument;
  variables: ZayavkiPaginatedQueryVariables;
}

export const zayavkyConfigFn = (facilityId?: string): IZayavkyConfig => ({
  query: ZayavkiPaginatedDocument,
  variables: {
    input: {
      facilityId: facilityId || '',
      page: 1,
    },
  },
});
