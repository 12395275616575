import dayjs from 'dayjs';

import {
  RegularModerationShiftFragment,
  UnitType,
} from '../../../../../../generated/graphql';
import { durationFn } from '../../../../../../utils/durationFn';
import { isInt } from '../../../../../../utils/isInt';
import { showTime } from '../../../../../../utils/showTime';
import { timeObject2 } from '../../../../../../utils/timeObject';
import { unitSymbolFn } from '../../../../../../utils/unitSymbol';

interface IShiftDateStartEnd {
  shift: RegularModerationShiftFragment;
}

const ShiftDateStartEnd: React.FC<IShiftDateStartEnd> = ({ shift }) => {
  const { dateFrom, dateEnd, durationPlan, unit, lunchDuration } = shift;
  const unitSymbol = unitSymbolFn(unit);
  return (
    <div className="sidebar__shift grid-cols-3  gap-y-4">
      <div className="col-span-full flex gap-x-1 Subtitle2">
        <span className="text-smena_text">План:</span>
        <span className="text-smena_text-secondary">
          {unit === UnitType.Hour
            ? `${timeObject2(durationFn(durationPlan)).hours}:${
                timeObject2(durationFn(durationPlan)).minutes
              }`
            : isInt(shift.value)}
          {unitSymbol}
        </span>
      </div>
      <div className="col-span-1 flex flex-col">
        <span className="Subtitle2 text-smena_text">Начало смены</span>
        <span className="Table-small text-smena_text-secondary">
          {dayjs(Number(dateFrom)).format('HH:mm')}
        </span>
      </div>
      <div className="col-span-1 flex flex-col">
        <span className="Subtitle2 text-smena_text">Конец смены</span>
        <span className="Table-small text-smena_text-secondary">
          {dayjs(Number(dateEnd)).format('HH:mm')}
        </span>
      </div>
      <div className="col-span-1 flex flex-col">
        <span className="Subtitle2 text-smena_text">Обед</span>
        <span className="Table-small text-smena_text-secondary">
          {lunchDuration
            ? showTime(durationFn(Number(lunchDuration)))
            : 'Без обеда'}
        </span>
      </div>
    </div>
  );
};

export default ShiftDateStartEnd;
