import clsx from 'clsx';
import { ErrorNotification } from '../ErrorNotification';

interface FormInput {
  divClassName: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  value: string | number | undefined;
  name: string;
  placeholder?: string;
  error?: string;
  validation?: boolean;
  showTextRole?: string[];
}

const TextArea = ({
  divClassName,
  onChange,
  name,
  placeholder,
  value,
  label,
  error,
}: FormInput) => {
  return (
    <div className={clsx(divClassName)}>
      <span className="Subtitle2">{label}</span>
      <textarea
        className={clsx(
          error ? 'textarea-error' : 'textarea-primary',
          'truncate'
        )}
        placeholder={placeholder}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
      />
      {error && <ErrorNotification text={error} />}
    </div>
  );
};
export default TextArea;
