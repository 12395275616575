import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import { Skeleton } from 'antd';

import {
  useCitiesQuery,
  useCountriesQuery,
  useFacilitiesQuery,
  useFacilityGroupsQuery,
  useMeShortlyQuery,
  usePositionsQuery,
} from '../../generated/graphql';

import { useStore } from '../../store';
import Loading from '../ui/Loading';
import Breadcrumbs from '../ui/Breadcrumbs';
import Menu from './Menu';
import CustomLink from './CustomLink';
import { IMainLayout } from '../../Interfaces/IMainLayout';
import NotificationBlock from '../Notification';
import MobileMenuElements from './Menu/Elements/MobileMenuElements';

const MainLayout: React.FC<IMainLayout> = ({ children, title, headerMenu, bg = 'bg-smena_white', breadCrumbs }) => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const { data: dataFacilityGroups, loading: loadingGroups } = useFacilityGroupsQuery();
  const { data: dataFacilities, loading: loadingFacilities } = useFacilitiesQuery();
  const { data: dataCities, loading: loadingCities } = useCitiesQuery();
  const { data: dataPositions, loading: loadingPosition } = usePositionsQuery();
  const { data: dataCountries, loading: loadingCoutries } = useCountriesQuery();
  const { data: dataMe, loading: loadingMe } = useMeShortlyQuery();

  const {
    setMe,
    setFacilities,
    setFacilitiesLoading,
    setPositions,
    setPositionsLoading,
    setCountries,
    setCountriesLoading,
    setGroups,
    setGroupsLoading,
    setCities,
    setCitiesLoading,
    menu,
    hover,
  } = useStore();

  const menuHover = menu || hover;

  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    if (dataMe) setMe(dataMe.meShortly);
    // eslint-disable-next-line
  }, [dataMe]);

  useEffect(() => {
    setCountriesLoading(loadingCoutries);
    if (dataCountries) setCountries(dataCountries?.countries);
    // eslint-disable-next-line
  }, [dataCountries]);

  useEffect(() => {
    setCitiesLoading(loadingCities);
    if (dataCities) setCities(dataCities?.cities);
    // eslint-disable-next-line
  }, [dataCities]);

  useEffect(() => {
    setGroupsLoading(loadingGroups);
    if (dataFacilityGroups) setGroups(dataFacilityGroups?.facilityGroups);
    // eslint-disable-next-line
  }, [dataFacilityGroups]);

  useEffect(() => {
    setFacilitiesLoading(loadingFacilities);
    if (dataFacilities) setFacilities(dataFacilities?.facilities);
    // eslint-disable-next-line
  }, [dataFacilities]);

  useEffect(() => {
    setPositionsLoading(loadingPosition);
    if (dataPositions) setPositions(dataPositions?.positions);
    // eslint-disable-next-line
  }, [dataPositions]);

  if (loadingMe) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900"
          rel="stylesheet"
        />
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <div className="bg-gray flex h-full w-full relative">
        <Menu />
        <div className={clsx(menuHover ? 'close_menu' : 'open_menu', 'content', bg)}>
          <div
            className={clsx(
              'H2 text-smena_text px-5 bg-smena_white pb-6 flex flex-col md:gap-0 gap-y-3',
              bg !== 'bg-smena_white' && headerMenu === undefined ? 'border-b border-smena_gray-30' : '',
              pathname !== '/' ? 'pt-7' : 'pt-8'
            )}
          >
            <div className="flex justify-between">
              <div className="flex items-center gap-x-3 md:hidden">
                <button
                  className="w-5 h-5 md:hidden"
                  onClick={() => {
                    setShowBurgerMenu(value => !value);
                  }}
                >
                  <span
                    className={clsx(
                      "after:content-[''] after:block after:absolute after:h-0.5 after:rounded-lg after:w-full after:bg-primary after:ease-in-out after:duration-300",
                      showBurgerMenu ? 'after:top-0 after:rotate-45' : 'after:-top-2',
                      "before:content-[''] before:block before:absolute before:h-0.5 before:rounded-lg before:w-full before:bg-primary before:ease-in-out before:duration-300",
                      showBurgerMenu ? 'before:top-0 before:-rotate-45' : 'before:top-2',
                      'block relative w-full rounded-lg bg-primary ease-in-out duration-300',
                      showBurgerMenu ? 'h-0' : 'h-0.5'
                    )}
                  ></span>
                </button>
                <Breadcrumbs links={breadCrumbs} />
              </div>
              {pathname !== '/' ? (
                <div className="md:block hidden">
                  <div>{<Breadcrumbs links={breadCrumbs} />}</div>
                  <div>{title ? title : <Skeleton.Input active />}</div>
                </div>
              ) : (
                <div className="md:block hidden">{title ? title : <Skeleton.Input active />}</div>
              )}
              <NotificationBlock />
            </div>
            <div className="block md:hidden">{title ? title : <Skeleton.Input active />}</div>
          </div>

          {headerMenu && (
            <div className="bg-smena_white px-5 flex border-b border-smena_gray-30 overflow-x-auto">
              {headerMenu.map(el => (
                <CustomLink key={el.link} to={el.disabled ? '/' : el.link} aria-disabled={el.disabled}>
                  <button disabled={el.disabled} className="Subtitle2 menu__link">
                    {el.name}
                  </button>
                </CustomLink>
              ))}
            </div>
          )}

          <div
            className={clsx(
              'w-full h-full z-30 flex flex-col p-5 md:px-0 gap-y-1 bg-smena_white ease-in-out duration-300 md:hidden',
              showBurgerMenu ? 'relative top-0 left-0' : 'absolute top-[30px] -left-[9999px]'
            )}
          >
            <MobileMenuElements />
          </div>
          <div className={clsx('w-full px-5 max-w-mac my-6', showBurgerMenu ? 'hidden' : '')}>{children}</div>
        </div>
      </div>
    </div>
  );
};
export default MainLayout;
