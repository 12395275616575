import { Rate } from 'antd';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { LikeIcon } from 'src/Assets/icons/LikeIcon';
import StarIcon from 'src/Assets/icons/StarIcon';
import SimpleSelect from 'src/components/ui/Select';
import MainSidebar from 'src/components/ui/Sidebar';
import TextArea from 'src/components/ui/TextArea';
import {
  RegularUserRatingFragment,
  useCreateUserGradeMutation,
  useCreateUserReviewMutation,
} from 'src/generated/graphql';
// import { useStore } from 'src/store';
import { errorHandler } from 'src/utils/errorHandler';
import { errorToast, successToast } from 'src/utils/notify';
import { UserFullName } from 'src/utils/userFullName';

interface ICreateReviewSidebar {
  user?: RegularUserRatingFragment;
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
}

const CreateReviewSidebar: React.FC<ICreateReviewSidebar> = ({ user, showSidebar, setShowSidebar }) => {
  // const { facilities } = useStore();

  const [facilityId, setFacilityId] = useState('');
  const [facilityIdError, setFacilityIdError] = useState('');
  const [positionId, setPositionId] = useState('');
  const [positionIdError, setPositionIdError] = useState('');
  const [speed, setSpeed] = useState(0);
  const [responsiveness, setResponsiveness] = useState(0);
  const [character, setCharacter] = useState(0);
  const [text, setText] = useState('');
  const [textError, setTextError] = useState('');
  const [isGood, setIsGood] = useState<null | boolean>(null);

  const [createUserGrade] = useCreateUserGradeMutation();
  const [createUserReview] = useCreateUserReviewMutation();

  const facilityAndPositionValidation = () => {
    if (!facilityId) {
      setFacilityIdError('Обязательное поле');
    } else {
      setFacilityIdError('');
    }
    if (!positionId) {
      setPositionIdError('Обязательное поле');
    } else {
      setPositionIdError('');
    }
  };
  const facilityReviewValidation = () => {
    if (!speed || !responsiveness || !character) {
      errorToast('Заполните оценки');
    }
  };

  const canCreateUserGrade = user && facilityId && positionId && speed && responsiveness && character;

  const createUserGradeHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    facilityAndPositionValidation();
    facilityReviewValidation();
    if (canCreateUserGrade) {
      await createUserGrade({
        variables: {
          input: {
            facilityId,
            positionId,
            character,
            responsiveness,
            speed,
            userId: user.id,
          },
        },
      })
        .then(() => successToast('Оценка отправлена'))
        .catch(error => errorToast(errorHandler(error)));
    }
  };
  const createUserReviewHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    facilityAndPositionValidation();
    if (!text) {
      setTextError('Обязательное поле');
    } else {
      setTextError('');
    }
    if (isGood === null) {
      errorToast('Выберите общую оценку');
      return;
    }
    if (user && facilityId && positionId && text) {
      await createUserReview({
        variables: {
          input: {
            facilityId,
            positionId,
            userId: user.id,
            text,
            isGood,
          },
        },
      })
        .then(() => {
          successToast('Отзыв отправлен');
          setIsGood(null);
        })
        .catch(error => errorToast(errorHandler(error)));
    }
  };

  // const currentUserWorkposts = useMemo(
  //   () => [
  //     ...new Map(
  //       facilities
  //         ?.map(facility =>
  //           user?.workposts?.filter(
  //             workpost => facility.id === workpost.facilityId
  //           )
  //         )
  //         .flat()
  //         .map(item => [item!['facilityId'], item])
  //     ).values(),
  //   ],
  //   [facilities, user?.workposts]
  // );
  const currentUserWorkposts = useMemo(
    () => [...new Map(user?.workposts?.map(item => [item!['facilityId'], item])).values()],
    [user?.workposts]
  );

  const filteredUserPositionsWorkposts = useMemo(() => {
    if (facilityId) {
      return [
        ...new Map(
          user?.workposts?.filter(el => el?.facilityId === facilityId)?.map(item => [item!['positionId'], item])
        ).values(),
      ];
    }
    return [];
  }, [facilityId, user?.workposts]);

  const userFacilitiesWorkpostsOptions = useMemo(() => {
    return currentUserWorkposts?.map(workpost => ({
      id: workpost?.facilityId,
      name: workpost?.facility?.name,
    }));
  }, [currentUserWorkposts]);

  const userPositionsWorkpostsOptions = useMemo(() => {
    return filteredUserPositionsWorkposts.map(workpost => ({
      id: workpost?.positionId,
      name: workpost?.position?.name,
    }));
  }, [filteredUserPositionsWorkposts]);

  return (
    <MainSidebar title="Новый отзыв" show={showSidebar} setShow={setShowSidebar}>
      <div className="flex flex-col gap-y-5">
        <h4 className="H4">{UserFullName(user)}</h4>
        <div className="flex justify-between gap-x-5">
          <SimpleSelect
            divClassName="w-200px"
            label="Объект"
            onChange={({ target: { value } }) => {
              setFacilityId(value);
              setPositionId('');
            }}
            value={facilityId}
            name="facilityId"
            options={userFacilitiesWorkpostsOptions}
            error={facilityIdError}
            disabledOption="Выберите объект"
          />
          <SimpleSelect
            divClassName="w-200px"
            label="Профессия"
            onChange={({ target: { value } }) => setPositionId(value)}
            value={positionId}
            name="facilityId"
            options={userPositionsWorkpostsOptions}
            error={positionIdError}
            disabledOption="Выберите профессию"
          />
        </div>
        <div className="flex flex-col gap-y-4">
          <form onSubmit={createUserGradeHandler} className="flex flex-col gap-y-7">
            <div className="flex flex-col p-4 gap-y-2 shadow-smena rounded-lg">
              <span className="Subtitle2">Оценка</span>
              <div className="gap-y-1">
                <div className="flex justify-between ">
                  <span className="Body2 text-smena_text-secondary">Скорость</span>
                  <Rate
                    value={speed}
                    onChange={value => setSpeed(value)}
                    character={<StarIcon />}
                    style={{ color: '#F9CA3E', fontSize: 10 }}
                  />
                </div>
                <div className="flex justify-between">
                  <span className="Body2 text-smena_text-secondary">Ответственность</span>
                  <Rate
                    value={responsiveness}
                    onChange={value => setResponsiveness(value)}
                    character={<StarIcon />}
                    style={{ color: '#F9CA3E', fontSize: 10 }}
                  />
                </div>
                <div className="flex justify-between">
                  <span className="Body2 text-smena_text-secondary">Характер</span>
                  <Rate
                    value={character}
                    onChange={value => setCharacter(value)}
                    character={<StarIcon />}
                    style={{ color: '#F9CA3E', fontSize: 10 }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button type="submit" className="btn-primary">
                Отправить
              </button>
            </div>
          </form>
          <form onSubmit={createUserReviewHandler} className="flex flex-col gap-y-7">
            <div className="flex flex-col p-4 gap-y-2 shadow-smena rounded-lg">
              <TextArea
                divClassName=""
                label="Отзыв"
                name="comment"
                placeholder="Напишите что-нибудь."
                value={text}
                onChange={({ target: { value } }) => {
                  setText(value);
                }}
                error={textError}
              />
              <div className="flex flex-col gap-y-2">
                <span className="Subtitle2 text-smena_text-secondary">Общая оценка</span>
                <div className="flex gap-x-2">
                  <button
                    type="button"
                    className={clsx(
                      'flex items-center gap-x-2 px-2 py-1 rounded-lg text-smena_green-dark bg-smena_green-extra_light Body2',
                      isGood && ' border border-smena_green-dark'
                    )}
                    onClick={() => {
                      if (isGood) {
                        setIsGood(null);
                      } else {
                        setIsGood(true);
                      }
                    }}
                  >
                    <span>
                      <LikeIcon />
                    </span>
                    <span>Рекомендую</span>
                  </button>
                  <button
                    type="button"
                    className={clsx(
                      'flex items-center gap-x-2 px-2 py-1 rounded-lg text-smena_red bg-smena_red-extra_light Body2',
                      isGood === false && 'border border-smena_red-dark'
                    )}
                    onClick={() => {
                      if (isGood === false) {
                        setIsGood(null);
                      } else {
                        setIsGood(false);
                      }
                    }}
                  >
                    <span className="transform rotate-180">
                      <LikeIcon />
                    </span>
                    <span>Не рекомендую</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button type="submit" className="btn-primary">
                Отправить
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainSidebar>
  );
};

export default CreateReviewSidebar;
