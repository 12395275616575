import { useState } from 'react';
import Across from '../../../../Assets/icons/Across';
import { RotateLeftArrow } from '../../../../Assets/icons/RotateLeftArrow';
import { RouteRightArrow } from '../../../../Assets/icons/RouteRightArrow';
import { IPhotoPopup } from '../../../../Interfaces/IPhotoPopup';
interface IPopupExtended extends IPhotoPopup {
  setShowPopup: (show: boolean) => void;
}
export const PhotoPopup: React.FC<IPopupExtended> = ({
  popup,
  place,
  setShowPopup,
}) => {
  const [rotate, setRotate] = useState(0);

  const domainName = process.env.REACT_APP_PUBLIC_DOMAIN + '/';
  const imageURL =
    typeof popup === 'string' ? domainName + popup : URL.createObjectURL(popup);
  return (
    <div
      className="absolute bg-smena_white flex justify-center items-center shadow-smena z-10 rounded-lg"
      style={{
        left: `${place?.offsetLeft}px`,
        top: `${place?.offsetTop}px`,
        width: '600px',
        height: '600px',
      }}
    >
      <img
        src={imageURL}
        alt=""
        className="object-contain"
        style={{
          width: '560px',
          height: '560px',
          transform: `rotate(${rotate}deg)`,
        }}
      />
      <div className="absolute top-6 right-6 w-6 h-6 rounded bg-smena_bb-background_base opacity-60"></div>
      <button
        type="button"
        className="absolute top-6 right-6 w-6 h-6"
        onClick={() => {
          setShowPopup(false);
          setRotate(0);
        }}
      >
        <Across />
      </button>
      <div className="absolute bottom-6 right-14 w-6 h-6 rounded bg-smena_bb-background_base opacity-60"></div>
      <button
        type="button"
        className="absolute bottom-6 right-14 w-6 h-6"
        onClick={() => setRotate(value => value - 90)}
      >
        <RotateLeftArrow />
      </button>
      <div className="absolute bottom-6 right-6 w-6 h-6 rounded bg-smena_bb-background_base opacity-60"></div>
      <button
        type="button"
        className="absolute bottom-6 right-6 w-6 h-6"
        onClick={() => setRotate(value => value + 90)}
      >
        <RouteRightArrow />
      </button>
    </div>
  );
};
