import { nanoid } from 'nanoid';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { GreenCheck } from '../../../../../Assets/icons/GreenCheck';
import {
  RegularUsersFragment,
  useAddUserToModerationReportMutation,
  useFacilityCasingQuery,
} from '../../../../../generated/graphql';
import { useStore } from '../../../../../store';
import { errorHandler } from '../../../../../utils/errorHandler';
import { positionName } from '../../../../../utils/filters/filtersName';
import { errorToast, successToast } from '../../../../../utils/notify';
import { WarningExclamation } from '../../../../../Assets/icons/WarningExclamation';
import Tooltip from '../../../../ui/Tooltip';

export const UsersTable = ({
  user,
  columns,
  hide,
}: {
  user: RegularUsersFragment;
  columns: {
    Header: string;
    name: string;
    className?: string;
    style?: any;
  }[];
  hide: (hide: boolean) => void;
}) => {
  const [tooltip, setTooltip] = useState(false);
  const router = useParams();

  let periodId: string = '';

  if (typeof router.periodId === 'string') {
    periodId = router.periodId;
  }
  const [position, setPosition] = useState('');

  const { moderationReport, positions, addUser } = useStore();
  const getModerationReport = moderationReport?.getModerationReport;
  const { data: facilityCasingsData } = useFacilityCasingQuery({
    variables: {
      facilityId: getModerationReport?.report.facility?.id || '',
    },
  });

  const facilityCasings = facilityCasingsData?.facilityCasings;
  const userWorkposts = user.workposts;

  const acceptedPositions = facilityCasings
    ?.map(facilityCasing =>
      userWorkposts?.filter(
        userWorkpost =>
          userWorkpost.position?.id === facilityCasing.positionId &&
          userWorkpost.facilityId === facilityCasing.facilityId
      )
    )
    .flat();

  const addedUsers = getModerationReport?.rows;

  const errorMessage = useCallback(() => {
    if (!user.isActive) {
      return {
        message:
          'Исполнитель деактивирован. Чтобы добавить, сделайте исполнителя активным.',
        maxWidth: 290,
      };
    }
    if (
      user.facilities?.find(
        facility => facility.id !== getModerationReport?.report.facility?.id
      )
    ) {
      return {
        message: 'Необходимо в анкете исполнителя добавить этот объект.',
        maxWidth: 230,
      };
    }
    if (acceptedPositions && acceptedPositions?.length < 1) {
      return {
        message: 'Необходимо в анкете исполнителя добавить должность',
        maxWidth: 225,
      };
    } else
      return {
        message: '',
        maxWidth: 0,
      };
  }, [user, getModerationReport, acceptedPositions]);

  const canAdd = () => {
    if (!user.isActive) {
      return [];
    }
    if (addedUsers) {
      return addedUsers.filter(
        addedUser =>
          user.workposts &&
          user.workposts.filter(
            workpost =>
              workpost.position?.id === addedUser.position.id &&
              workpost.facilityId === getModerationReport?.report.facility?.id
          ).length > 0
      );
    } else return [];
  };

  const [addUserToModerationReport] = useAddUserToModerationReportMutation();

  return (
    <div
      className="grid table__row w-fit"
      style={{
        gridTemplateColumns: columns
          .map(
            column =>
              'minmax(' +
              column.style.minWidth +
              'px' +
              ', ' +
              column.style.width +
              'px)'
          )
          .join(' '),
        columnGap: 10,
      }}
    >
      <div className={columns[0].className}>
        {canAdd().length < 1 && (
          <span
            className="mr-2 relative"
            onMouseEnter={() => {
              setTooltip(true);
            }}
            onMouseLeave={() => {
              setTooltip(false);
            }}
          >
            <WarningExclamation />
            {tooltip && (
              <Tooltip
                content={errorMessage().message}
                style={{ maxWidth: `${errorMessage().maxWidth}px` }}
              />
            )}
          </span>
        )}
        <span>{user.lastname}</span>
      </div>
      <div className={columns[1].className}>{user.firstname}</div>
      <div className={columns[2].className}>{user.patronymic}</div>
      <div className={columns[3].className}>{user.phone}</div>
      <div className={columns[4].className}>
        <select
          name="workpost"
          id="workpost"
          className="select-primary"
          disabled={!user.isActive || acceptedPositions?.length === 0}
          value={position}
          onChange={({ target: { value } }) => {
            setPosition(value);
          }}
        >
          <option value="">Профессия</option>
          {acceptedPositions?.map(workpost => (
            <option key={nanoid()} value={workpost?.position?.id}>
              {positionName(positions, workpost?.position?.id)}
            </option>
          ))}
        </select>
      </div>
      <div className={columns[5].className + ' flex items-center'}>
        {canAdd().length > 0 && user.isActive ? (
          acceptedPositions &&
          acceptedPositions?.length > 0 &&
          canAdd().filter(user => user.position.id === position).length ===
            0 ? (
            <button
              type="button"
              className="btn-secondary_small"
              disabled={!position}
              onClick={async () => {
                await addUserToModerationReport({
                  variables: {
                    input: {
                      positionId: position,
                      userId: user.id,
                      reportId: periodId,
                    },
                  },
                })
                  .then(e => {
                    if (e.data?.addUserToModerationReport) {
                      successToast('Пользователь добавлен');
                      addUser(e.data?.addUserToModerationReport);
                      hide(false);
                    }
                  })
                  .catch(e => {
                    errorToast(errorHandler(e));
                  });
              }}
            >
              Добавить
            </button>
          ) : (
            <GreenCheck />
          )
        ) : null}
      </div>
    </div>
  );
};
