import React from 'react';
import { ErrorNotification } from '../ErrorNotification';

type FormInput = {
  divClassName: string;
  label: string;
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  defaultChecked?: boolean;
  checked: boolean;
  name: string;
  error?: string;
  disabled?: boolean;
};

const SimpleCheckbox = ({
  divClassName,
  label,
  required,
  onChange,
  checked,
  defaultChecked,
  name,
  error,
  disabled,
}: FormInput) => {
  return (
    <div className={divClassName}>
      <input
        type="checkbox"
        required={required}
        name={name}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        id={name}
        disabled={disabled}
        className={`${error ? 'border-error' : ''} checkbox-primary`}
      />
      <label
        htmlFor={name}
        className={`label-checkbox ${disabled ? 'cursor-not-allowed' : ''}`}
      >
        {label}
      </label>
      {error && <ErrorNotification text={error} />}
    </div>
  );
};

export default SimpleCheckbox;
