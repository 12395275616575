export const timeObject = (time: { hours: number; minutes: number }) => {
  if (time.minutes < 10) {
    return time.hours + ':0' + time.minutes;
  } else {
    return time.hours + ':' + time.minutes;
  }
};
export const timeObject2 = (time: { hours: number; minutes: number }) => {
  return {
    hours: (time.hours < 10 ? '0' : '') + time.hours,
    minutes: String(time.minutes < 10 ? '0' + time.minutes : time.minutes),
  };
};
