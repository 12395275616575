import { findIndex } from 'lodash';
import { setTokens } from '../../lib/auth';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

const PROP_NAME = 'logins';
export interface IFastLogin {
  phone: string;
  role: string;
  password?: string;
  code?: string;
}

export const saveLoginData = (data: IFastLogin) => {
  const curLogins = JSON.parse(localStorage.getItem(PROP_NAME) || '[]') as IFastLogin[];
  const index = findIndex(curLogins, { phone: data.phone });
  if (index > -1) curLogins[index] = data;
  else curLogins.push(data);
  localStorage.setItem(PROP_NAME, JSON.stringify(curLogins));
};

export const getLogins = () => {
  return JSON.parse(localStorage.getItem(PROP_NAME) || '[]') as IFastLogin[];
};

export const setTokensOnLogin = ({
  accessToken,
  refreshToken,
  navigate,
}: {
  accessToken: string;
  refreshToken: string;
  navigate: NavigateFunction;
}) => {
  setTokens({
    accessToken,
    refreshToken,
  });
  navigate('/');
};
