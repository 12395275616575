import { FilterPeriod } from '../../generated/graphql';

export const lastTimeLength = (time: FilterPeriod, sortedData?: any[]) => {
  if (sortedData) {
    switch (time) {
      case FilterPeriod.Year:
        const valuesArr = sortedData.slice(6, 12).map(data => data.value);
        if (valuesArr.length > 0) {
          return valuesArr.reduce((prev, curr) => prev + curr);
        }
        return 0;
      default:
        return sortedData.length > 0
          ? sortedData[sortedData.length - 1].value
          : 0;
    }
  }
  return 0;
};
