export const getStatusRu = (status: string) => {
  const statuses = [
    { id: 'CANCELED', name: 'Отмененные' },
    { id: 'DRAFT', name: 'Не отправлено' },
    { id: 'FINISHED', name: 'Завершена' },
    { id: 'NEW', name: 'Новая' },
    { id: 'REFUSED', name: 'Отменена' },
    { id: 'WORKING', name: 'В работе' },
  ];

  return statuses.find(statusEl => statusEl.id === status)?.name;
};
