export const getPeriodRu = (period?: string): string | undefined => {
  const periodList = [
    {
      period: 'MONTH',
      periodRu: 'Месяц',
    },
    {
      period: 'TWO_WEEK',
      periodRu: 'Две недели',
    },
    {
      period: 'WEEK',
      periodRu: 'Неделя',
    },
  ];

  return periodList.find(item => period === item.period)?.periodRu;
};
