import { useCallback, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { useFacilityQuery } from '../../../../../generated/graphql';

import { useStore } from '../../../../../store';

import { auchanLunchDurationTypes, lunchDurationTypes } from '../../../../../utils/lists/lunchDurationTypes';
import { lunchDurationHandle } from '../../../../../utils/lunchDuration';
import { timeDifference } from '../../../../../utils/timeDifference';
import { errorToast } from '../../../../../utils/notify';

import { LunchDurationSelect } from '../../../../ui/Select/LunchDurationSelect';
import { groupName } from '../../../../../utils/filters/filtersName';
import TimePickerInput from '../../../../ui/Pickers/TimePicker';
import moment from 'moment/moment';

const NewCustomSchedule = () => {
  const [timeStart, setTimeStart] = useState<moment.Moment | null>(null);
  const [timeEnd, setTimeEnd] = useState<moment.Moment | null>(null);

  const {
    scheduleEvent,
    setScheduleEvent,
    currentUser,
    lunchDuration,
    setLunchDuration,
    facilityId,
    setFacilityIdError,
    workpostId,
    setWorkpostIdError,
    groups,
    setStartScheduleTime,
    setEndScheduleTime,
    facilities,
    me,
  } = useStore();

  const { data } = useFacilityQuery({
    variables: {
      id: facilityId,
    },
  });

  const facilityGroupName = groupName(groups, data?.facility.facilityGroupId);

  const onChangeTimeStart = useCallback(
    (timeValue: moment.Moment | null) => {
      if (timeValue) {
        setTimeStart(moment(timeValue).set('milliseconds', 0));
        setStartScheduleTime(timeValue);
        if (facilityGroupName === 'Ашан') {
          setLunchDuration(lunchDurationHandle(timeDifference(timeValue, timeEnd)));
        }
      }
    },
    [facilityGroupName, setLunchDuration, setStartScheduleTime, timeEnd]
  );

  const onChangeTimeEnd = useCallback(
    (timeValue: moment.Moment | null) => {
      if (timeValue) {
        setTimeEnd(timeValue.set('milliseconds', 0));
        setEndScheduleTime(timeValue);
        if (facilityGroupName === 'Ашан') {
          setLunchDuration(lunchDurationHandle(timeDifference(timeStart, timeValue)));
        }
      }
    },
    [facilityGroupName, setLunchDuration, setEndScheduleTime, timeStart]
  );

  const buttonClickHandler = () => {
    setFacilityIdError(facilityId === '');
    setWorkpostIdError(workpostId === '');

    if (timeEnd && timeStart) {
      if (timeEnd?.diff(timeStart) > 0 && timeEnd.diff(timeStart) < 1800000) {
        return errorToast('Смена не может быть меньше 30 минут');
      }
    }

    if (facilityId && workpostId) {
      setScheduleEvent({
        title: `${dayjs(Number(timeStart)).format('HH:mm')} - ${dayjs(Number(timeEnd)).format('HH:mm')}`,
        start: moment(timeStart).toDate(),
        end: moment(timeEnd).toDate(),
        resource: {
          id: nanoid(),
          userId: String(currentUser?.id),
          authorId: String(me?.id),
          dateFrom: String(Number(timeStart)),
          dateEnd: String(Number(timeEnd)),
          stage: 'CUSTOM',
          positionId: workpostId,
          lunchDuration,
          facility: {
            id: facilityId,
            name: facilities?.find(fac => fac.id === facilityId)?.name,
          },
          isProbation: false,
        },
      });
      toast.info('Нажмите на дни в которые хотите добавить смены', {
        theme: 'colored',
        toastId: 'clickYourDays',
      });
    }
  };

  useEffect(() => {
    if (scheduleEvent?.start) {
      setTimeStart(moment(scheduleEvent.start));
      setTimeEnd(moment(scheduleEvent.end));
    } else {
      setTimeStart(moment());
      setTimeEnd(moment().add(30, 'm'));
    }
  }, [scheduleEvent]);

  return (
    <div className="grid grid-cols-4 gap-x-5">
      <TimePickerInput
        //check
        label="Начало смены"
        className="col-span-2 inputHeight"
        popupClassName="fixed"
        value={timeStart}
        onChange={onChangeTimeStart}
      />
      <TimePickerInput
        //check
        label="Конец смены"
        className="col-span-2 inputHeight"
        popupClassName="fixed"
        value={timeEnd}
        onChange={onChangeTimeEnd}
      />
      <LunchDurationSelect
        divClassName="col-span-2"
        label="Обед"
        required
        onChange={({ target: { value } }) => {
          if (facilityGroupName !== 'Ашан') {
            setLunchDuration(Number(value));
          }
        }}
        value={lunchDuration}
        name="lunchDuration"
        autoComplete="lunchDuration"
        options={facilityGroupName === 'Ашан' ? auchanLunchDurationTypes : lunchDurationTypes}
        disabled={facilityGroupName === 'Ашан'}
      />

      <div className="col-span-full flex flex-col items-end mt-7">
        <button onClick={buttonClickHandler} type="button" className="btn-primary_small">
          <span>Перейти к календарю</span>
        </button>
      </div>
    </div>
  );
};

export default NewCustomSchedule;
