import { nanoid } from 'nanoid';
import React from 'react';
import { Map, Placemark, ZoomControl, Clusterer } from 'react-yandex-maps';
import { useStore } from '../../store';

interface IOurMap {
  points?: any[];
  loading: boolean;
}

export const OurMap: React.FC<IOurMap> = React.memo(({ loading, points }) => {
  const { cluster } = useStore();
  return (
    <Map
      defaultState={{ center: [55.75, 37.57], zoom: 5 }}
      width="100%"
      height="100%"
      modules={[
        'clusterer.addon.balloon',
        'geoObject.addon.balloon',
        'layout.ImageWithContent',
      ]}
    >
      <ZoomControl />
      {cluster ? (
        <Clusterer
          options={{
            preset: 'islands#dotIcon',
            clusterDisableClickZoom: true,
            clusterOpenBalloonOnClick: true,
          }}
        >
          {!loading &&
            points?.map(point => {
              return (
                <Placemark
                  key={nanoid()}
                  geometry={point.geometry.coordinates}
                  properties={point.properties}
                  options={point.options}
                />
              );
            })}
        </Clusterer>
      ) : (
        !loading &&
        points?.map(point => {
          return (
            <Placemark
              key={nanoid()}
              geometry={point.geometry.coordinates}
              properties={point.properties}
              options={point.options}
            />
          );
        })
      )}
    </Map>
  );
});
