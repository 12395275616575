import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Check from '../../../Assets/icons/check';

import {
  GetModerationReportDocument,
  GetModerationReportQueryVariables,
  ModerationStatus,
  RegularModerationReportFragment,
  ShiftStage,
  useChangeModerationSelectedUsersMutation,
  useDownloadModerationReportMutation,
  useSetModerationReportStatusMutation,
} from '../../../generated/graphql';

import { useStore } from '../../../store';

import { errorHandler } from '../../../utils/errorHandler';
import { reportsQueryConfig } from '../../../utils/graphqlConfigs/moderationReports';
import { errorToast, infoToast, successToast } from '../../../utils/notify';
import { sortObj } from '../../../utils/sortObject';
import SpinnerLoad from '../../ui/Loader';

import { SplitButton } from '../../ui/SplitButton';

interface IReportFacilityFilter {
  periodId: string;
  report?: RegularModerationReportFragment;
}

export const ReportFacilityFilter: React.FC<IReportFacilityFilter> = ({
  periodId,
  report,
}) => {
  const {
    selectedIndex,
    setSelectedIndex,
    updateModerationsReports,
    moderationReport,
  } = useStore();

  const [resLink, setResLink] = useState('');
  const [status, setStatus] = useState<undefined | string>('');
  const [load, setLoad] = useState(false);
  const [loadBtn, setLoadBtn] = useState(false);

  const optionsSplitButton = ['К оплате', 'Отклонить оплату'];
  const optionsSelect = [
    { id: 'IN_WORK', name: 'В работе' },
    { id: 'SENT', name: 'Отправлено ' },
  ];

  useEffect(() => {
    setStatus(report?.status);
  }, [report]); //eslint-disable-line

  useEffect(() => {
    setSelectedIndex(0);
  }, []); //eslint-disable-line

  const [changeStatus] = useSetModerationReportStatusMutation({
    awaitRefetchQueries: true,
    refetchQueries: [reportsQueryConfig()],
  });

  const moderationReportConfig: {
    query: typeof GetModerationReportDocument;
    variables: GetModerationReportQueryVariables;
  } = {
    query: GetModerationReportDocument,
    variables: {
      id: moderationReport?.getModerationReport.report.id || '',
    },
  };

  const [changeRow] = useChangeModerationSelectedUsersMutation({
    awaitRefetchQueries: true,
    refetchQueries: [moderationReportConfig],
  });

  const [downloadModerationReport] = useDownloadModerationReportMutation();

  const userPositions = useMemo(() => {
    return updateModerationsReports?.map(report => ({
      positionId: report.position.id,
      userId: report.user.id,
    }));
  }, [updateModerationsReports]);

  const disableStatus = useMemo(() => {
    return moderationReport?.getModerationReport.rows?.filter(
      row =>
        row.days.filter(
          day =>
            day.shifts.filter(
              shift =>
                shift.stage !== ShiftStage.Finished && shift.moderation === null
            ).length > 0
        ).length > 0
    );
  }, [moderationReport?.getModerationReport.rows]);

  const mutation = async (toPayment: boolean) => {
    if (userPositions) {
      setLoadBtn(true);
      await changeRow({
        variables: {
          input: {
            userPositions,
            reportId: moderationReport?.getModerationReport.report.id || '',
            toPayment,
          },
        },
      })
        .then(e => {
          if (e.data?.changeModerationSelectedUsers) {
            successToast('Статус успешно изменен');
          }
        })
        .catch(e => {
          infoToast(errorHandler(e));
        })
        .finally(() => {
          setLoadBtn(false);
        });
    }
  };

  const handleClick = () => {
    if (selectedIndex === 0) {
      mutation(true);
    } else if (selectedIndex === 1) {
      mutation(false);
    }
  };

  const downloadXLSHandler = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoad(true);
    toast.info('Создаем отчет...', {
      theme: 'colored',
      type: 'info',
      toastId: 'infoToast',
    });
    await downloadModerationReport({
      variables: {
        id: moderationReport?.getModerationReport.report.id || '',
      },
    })
      .then(response => {
        if (response.data?.downloadModerationReport) {
          setLoad(false);
          const filePath = response.data.downloadModerationReport.filePath;
          const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
          setResLink(url);
          toast.dismiss('infoToast');
        }
      })
      .catch(e => {
        setLoad(false);
        toast.dismiss('infoToast');
        setTimeout(() => {
          errorToast(errorHandler(e));
        }, 1000);
      });
  };

  return (
    <div className="filter-bar flex justify-between">
      <div className="flex items-end">
        {updateModerationsReports?.length! > 0 && (
          <SplitButton
            handleClick={handleClick}
            options={optionsSplitButton}
            buttonContent={
              loadBtn
                ? 'Загрузка'
                : `Статус «${optionsSplitButton[selectedIndex]}»`
            }
            disabled={loadBtn}
          />
        )}
      </div>
      <div className="flex justify-end gap-x-8 flex-wrap">
        <div className="input__status flex flex-col justify-end">
          <label htmlFor={status} className="label-primary">
            Статус документа
          </label>
          <select
            onChange={async ({ target: { value } }) => {
              setStatus(value);
              await changeStatus({
                variables: {
                  input: {
                    reportIds: [periodId],
                    status: value as ModerationStatus,
                  },
                },
              })
                .then(e => {
                  if (e.data?.setModerationReportStatus) {
                    successToast('Статус изменен');
                  }
                })
                .catch(e => {
                  errorToast(errorHandler(e));
                });
            }}
            value={status}
            id={status}
            name={status}
            className="select-primary truncate"
            disabled={disableStatus?.length !== 0}
          >
            {sortObj(optionsSelect).map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-end mt-1">
          {load ? (
            <button className="btn-secondary_small flex gap-x-1">
              <SpinnerLoad />
              <span>Формируем отчет</span>
            </button>
          ) : resLink ? (
            <div>
              <div className="flex gap-x-1">
                <span className="text-smena_green-dark">
                  <Check />
                </span>
                <span className="Subtitle 2">Отчет готов! </span>
              </div>
              <a
                href={resLink}
                onClick={() => successToast('Отчет успешно скачан')}
                className="btn-secondary_small"
              >
                Скачать XLS
              </a>
            </div>
          ) : (
            <button
              disabled={status === 'IN_WORK' || load}
              className="btn-secondary_small"
              onClick={downloadXLSHandler}
            >
              Сформировать XLS
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
