import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';

import DatePickerIcon from '../../../Assets/icons/DatePickerIcon';
import { ISO_DATE } from 'src/utils/constVariables';
import { URLSearchParamsInit } from 'react-router-dom';
import DatePickerInput from '../../ui/Pickers/DatePickerInput';

interface IRequestCalendarPicker {
  date?: dayjs.Dayjs | null;
  setDate: (date?: dayjs.Dayjs | null) => void;
  params: URLSearchParams;
  setParams?: (params: URLSearchParamsInit) => void;
}

const RequestCalendarPicker: React.FC<IRequestCalendarPicker> = ({ date, setDate, params, setParams }) => {
  const calendarIcon = useRef<HTMLButtonElement>(null);

  const [openCalendar, setOpenCalendar] = useState(false);

  return (
    <div className="flex items-center gap-x-3">
      <button
        onClick={() => {
          setDate(date);
          if (setParams) {
            params?.set('date', dayjs(date).subtract(1, 'month').format(ISO_DATE));
            setParams(params);
          }
        }}
      >
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.5 15.5L1.5 8.5L8.5 1.5"
            stroke="#397DFF"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <div className="text-smena_text text-center" style={{ width: '140px' }}>
        {dayjs(date).format('MMMM YYYY')}
      </div>
      <div className="flex items-end">
        <button
          onClick={() => {
            setOpenCalendar(openCalendar => !openCalendar);
          }}
          ref={calendarIcon}
        >
          <DatePickerIcon color="text-primary" opacity="opacity-100" />
        </button>
        <div
          className={`absolute top-0 left-0 w-full h-full ${openCalendar ? '' : 'pointer-events-none'}`}
          onClick={() => {
            if (openCalendar) {
              setOpenCalendar(openCalendar => !openCalendar);
            }
          }}
        ></div>
        <div
          className="absolute opacity-0 invisible"
          style={{
            top: calendarIcon.current?.offsetTop,
            left: calendarIcon.current?.offsetLeft,
          }}
        >
          <DatePickerInput
            // check
            label="Дата"
            picker="month"
            placeholder="За весь период"
            value={date}
            onChange={newValue => {
              setDate(newValue);
              if (setParams) {
                params?.set('date', String(newValue?.startOf('month').format(ISO_DATE)));
                setParams(params);
              }
            }}
            maxDate={dayjs()}
            open={openCalendar}
          />
        </div>
      </div>

      <span
        className="cursor-pointer"
        onClick={() => {
          setDate(date?.add(1, 'month'));
          if (setParams) {
            params?.set('date', dayjs(date).add(1, 'month').format(ISO_DATE));
            setParams(params);
          }
        }}
      >
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.5 1.5L8.5 8.5L1.5 15.5"
            stroke="#397DFF"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </div>
  );
};

export default RequestCalendarPicker;
