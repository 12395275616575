import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import ChevronDown from 'src/Assets/icons/chevron-down';
import { useStore } from 'src/store';
import { MenuItem } from 'src/types';

const MobileSubMenu: React.FC<{ item: MenuItem }> = ({ item }) => {
  const { subMenuActive, setSubMenuActive } = useStore();

  const marginBottom = (itemName: string) => {
    if (itemName === 'Выплаты') {
      return 'mb-10';
    }
  };

  return (
    <ul
      key={item.id}
      className={clsx('flex flex-col z-10', marginBottom(item.name))}
    >
      <li
        className={clsx('group cursor-pointer text-smena_text nav-link')}
        onClick={() => {
          setSubMenuActive(!subMenuActive);
        }}
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex relative">
            {item.icon}
            <span className={clsx('ml-[15px]')}>{item.name}</span>
          </div>
          <ChevronDown
            className={clsx(
              'stroke-current flex group-hover:text-smena_white transition-transform transform',
              subMenuActive ? '' : '-rotate-90'
            )}
          />
        </div>
      </li>

      <ul className={clsx('subMenu', subMenuActive ? 'show-subMenu' : '')}>
        {item.menus?.map(el => (
          <li key={el.name} className="submenu-item relative">
            <NavLink
              to={el.url}
              className={({ isActive }) => {
                return isActive
                  ? 'sub_nav-link_active relative z-10'
                  : 'sub_nav-link text-smena_text relative z-10';
              }}
            >
              <span className={clsx('pointer-events-none ml-[15px] block')}>
                {el.name}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </ul>
  );
};

export default MobileSubMenu;
