import clsx from 'clsx';
import { useState } from 'react';

import ChevronDown from '../../../Assets/icons/chevron-down';
import { ISpoiler } from '../../../Interfaces/ISpoiler';

export const Spoiler: React.FC<ISpoiler> = ({
  header,
  children,
  className,
}) => {
  const [show, setShow] = useState(true);
  return (
    <div className={className}>
      <div
        className={clsx(
          'cursor-pointer relative',
          header ? 'pr-10' : '',
          show ? 'mb-5' : ''
        )}
      >
        {header}
        <button
          className={clsx(
            'absolute top-0 right-0 transform transition-transform translate-y-1/2',
            show && 'rotate-180'
          )}
          onClick={() => setShow(value => !value)}
        >
          <ChevronDown className="text-smena_text" />
        </button>
      </div>
      {show && children}
    </div>
  );
};
