import { useState } from 'react';
import {
  RegularManualAccrualItemFragment,
  useWriteManualAccuralItemMutation,
} from '../../../generated/graphql';
import { useStore } from '../../../store';
import { errorHandler } from '../../../utils/errorHandler';
import { errorToast } from '../../../utils/notify';

interface CustomNameInterface {
  item?: {
    __typename?: 'ManualAccrualItem';
  } & RegularManualAccrualItemFragment;
  userId: string | undefined;
  setUserSum: (sum: number) => void;
}

const CustomName = ({ item, userId, setUserSum }: CustomNameInterface) => {
  const { manualAccural, disabledUsersIds } = useStore();
  const [value, setValue] = useState<number | undefined | null>(
    item?.totalAmount
  );
  const [writeManualAccuralItem] = useWriteManualAccuralItemMutation();
  const send = (customName: string, totalAmount: number | null | undefined) => {
    writeManualAccuralItem({
      variables: {
        input: {
          accrualId: manualAccural ? manualAccural?.id : '',
          activeMonth: 0,
          hardWorker: 0,
          rateAmount: 0,
          superWorker: 0,
          totalAmount,
          userId: userId || '',
          customName,
        },
      },
    })
      .then(e => {
        setUserSum(e.data?.writeManualAccuralItem.userSum!);
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  const canEdit = disabledUsersIds.includes(String(userId));

  return (
    <div className="grid grid-cols-3 px-2">
      <span className="col-span-2">{item?.customName}</span>
      <input
        type="number"
        min="0"
        step={0.01}
        className="py-0 inline-block focus:ring-1 focus:ring-primary sm:text-sm border-none bg-transparent font-roboto"
        style={canEdit ? { cursor: 'not-allowed' } : {}}
        value={value || 0}
        disabled={canEdit}
        onChange={e => {
          setValue(Number(e.target.value));
        }}
        onBlur={() => {
          send(item?.customName!, value);
        }}
      />
    </div>
  );
};

export default CustomName;
