import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MagnifierPlus } from '../../../../Assets/icons/MagnifierPlus';
import { UploadSimple } from '../../../../Assets/icons/upload-simple';
import {
  useRemoveUserDocumentMutation,
  useUploadUserDocumentMutation,
} from '../../../../generated/graphql';
import { IPhotoBlock } from '../../../../Interfaces/IPhotoBlock';
import { useStore } from '../../../../store';
import { roles } from '../../../../utils/constVariables';
import { errorHandler } from '../../../../utils/errorHandler';
import { userDocumentsQueryConfigFn } from '../../../../utils/graphqlConfigs/userDocumentsQueryConfig';
import { errorToast, successToast } from '../../../../utils/notify';

export const PhotoBlock: React.FC<IPhotoBlock> = ({
  name,
  blockType,
  inputPhotoRef,
  inputPhotoBlockRef,
  setPopup,
  setShowPopup,
}) => {
  const [hover, setHover] = useState(false);
  const router = useParams();

  const { userDocuments, setDocument, removeDocument, me } = useStore();

  const file = userDocuments?.find(
    document => document.type === blockType
  )?.file;

  let userId = '';

  if (typeof router.userId === 'string') {
    userId = router.userId;
  }
  const [uploadUserDocument] = useUploadUserDocumentMutation();
  const [removeUserDocument] = useRemoveUserDocumentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userDocumentsQueryConfigFn(userId)],
  });

  const removeHandler = useCallback(async () => {
    await removeUserDocument({
      variables: {
        type: blockType,
        workerId: userId,
      },
    })
      .then(e => {
        if (e.data?.removeUserDocument) {
          successToast('Фото удалено');
          removeDocument(blockType);
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  }, [blockType, userId, removeUserDocument, removeDocument]);

  const domainName = process.env.REACT_APP_PUBLIC_DOMAIN + '/';

  const uploadDocumentHandler = useCallback(
    async (document: File) => {
      uploadUserDocument({
        variables: {
          document,
          type: blockType,
          userId,
        },
      })
        .then(e => {
          if (e.data?.uploadUserDocument) {
            successToast('Фото загружено');
            setDocument(e.data.uploadUserDocument);
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    },
    [userId, blockType, uploadUserDocument, setDocument]
  );

  const canUpload = me && [roles.Admin, roles.Supervisor].includes(me.role);

  return (
    <div className="flex flex-col pl-5 photo-block">
      <label htmlFor={name} className="label-primary">
        Фото
      </label>
      <input
        type="file"
        onChange={({ target: { validity, files } }) => {
          if (validity.valid && files) {
            const file = files.item(0);
            if (file) {
              uploadDocumentHandler(file);
            }
          }
        }}
        name={name}
        id={name}
        ref={inputPhotoRef}
        className="hidden"
        accept="image/png, image/jpeg"
        value=""
      />
      <div className="flex flex-col" ref={inputPhotoBlockRef}>
        <div className="upload_block">
          <button
            className="upload_button"
            type="button"
            onClick={() => {
              if (!file && canUpload) {
                inputPhotoRef?.current?.click();
              }
            }}
          >
            {file ? (
              <div className="w-full h-full relative">
                <img
                  src={domainName + file?.path}
                  alt=""
                  className="object-cover w-full h-full"
                  onMouseEnter={() => {
                    setHover(true);
                  }}
                  onMouseLeave={() => {
                    setHover(false);
                  }}
                  onClick={() => {
                    if (file) {
                      setPopup({
                        popup: file.path,
                        place: inputPhotoBlockRef.current,
                      });
                      setShowPopup(true);
                    }
                  }}
                />
                {hover && (
                  <>
                    <div className="absolute top-0 left-0 w-full h-full bg-smena_black opacity-50 pointer-events-none"></div>
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center gap-y-2 pointer-events-none">
                      <MagnifierPlus />
                      <span className="Body2 text-smena_white w-16">
                        Увеличить фото
                      </span>
                    </div>
                  </>
                )}
              </div>
            ) : canUpload ? (
              <div className="flex flex-col justify-center items-center">
                <UploadSimple />
                <span className="Body2 text-primary px-4 pt-4 text-center">
                  Загрузить фото
                </span>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <span className="Body2 text-primary px-4 text-center">
                  Фото еще не загружено
                </span>
              </div>
            )}
          </button>
          {file && canUpload ? (
            <div className="flex justify-between Body2">
              <button
                className="text-primary"
                onClick={() => {
                  inputPhotoRef.current?.click();
                }}
                type="button"
              >
                Заменить
              </button>
              <button
                className="text-smena_red-dark"
                onClick={removeHandler}
                type="button"
              >
                Удалить
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
