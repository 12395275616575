import { debounce } from 'lodash';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { useStore } from '../../../store';

import { getRoleRu } from '../../../utils/get/getRoleRu';
import { allRoleArray, superWithoutNewbie } from '../../../utils/lists/roleArray';
import { sortObj } from '../../../utils/sortObject';
import Across from '../../../Assets/icons/Across';

import Magnifier from '../../../Assets/icons/magnifier';
import Plus from '../../../Assets/icons/plus';

import Filter from './Filter';
import { documentsStatusList } from '../../../utils/lists/documentsStatusList';
import clsx from 'clsx';
import { roles } from '../../../utils/constVariables';
import { facilityName } from 'src/utils/filters/filtersName';

interface IUsersFilters {
  role: string;
  facilityId: string;
  documentStatus: string;
  isActive: string | boolean;
  returnData: () => void;
  setSearch: (param: string) => void;
  setFacilityId: (param: string) => void;
  setDocumentStatus: (param: string) => void;
  setRole: (param: string) => void;
  setPage: (param: number) => void;
  setIsActive: (param: string | boolean) => void;
}

const UsersFilters = ({
  setSearch,
  setFacilityId,
  setRole,
  setPage,
  role,
  facilityId,
  isActive,
  setIsActive,
  documentStatus,
  setDocumentStatus,
}: IUsersFilters) => {
  const [filter, setFilter] = useState(false);
  const [params, setParams] = useSearchParams();

  const { me, facilities } = useStore();

  let paramsCount = 0;

  params.forEach(() => {
    paramsCount++;
  });

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    if (value !== '') {
      params.set('search', value);
      setParams(params);
    } else {
      params.delete('search');
      setParams(params);
    }
    setPage(1);
  };

  let facilityIdParam = params.get('facilityId');
  let roleParam = params.get('role');
  let documentStatusParam = params.get('documentStatus');
  let activeParam = params.get('active');

  const debounceOnChange = debounce(handleChangeSearch, 600);

  const meRole = me?.role;

  const removeParam = (param: string) => {
    if (params.has(param)) {
      params.delete(param);
    }
  };

  const addParams = (paramName: string, param: string) => {
    params.set(paramName, param);
  };

  const canCreate = me && ![roles.ClientManager, roles.ClientDirector].includes(me.role);

  return (
    <div className="filter-bar flex xs:flex-row flex-col xs:flex-wrap gap-y-2 justify-between">
      <div className="flex flex-col">
        <div className={clsx('flex relative', paramsCount ? 'mb-2' : '')}>
          <button
            type="button"
            className="btn-stroke mr-2 flex gap-x-1 items-center"
            onClick={() => {
              setFilter(value => !value);
            }}
          >
            Фильтр
            {paramsCount ? (
              <span
                className="Button3 text-primary rounded-full bg-primary bg-opacity-20"
                style={{
                  padding: '0px 7.5px',
                }}
              >
                {String(paramsCount)}
              </span>
            ) : null}
          </button>
          <div className="relative w-64">
            <span className="absolute top-0 left-0 transform translate-x-2/4 translate-y-2/4">
              <Magnifier />
            </span>
            <input
              className="input-primary pl-8"
              onChange={debounceOnChange}
              type="search"
              name="search"
              id="search"
              placeholder="Поиск"
            />
          </div>
          {filter && (
            <div className="filter__popup gap-y-3">
              <span
                className="absolute top-0 right-0 cursor-pointer"
                style={{ padding: '15px 15px 0 0' }}
                onClick={() => {
                  setFilter(value => !value);
                }}
              >
                <Across color="text-smena_gray-90" />
              </span>
              <span className="H4 text-smena_text mb-3 inline-block">Фильтры</span>

              <div>
                <label htmlFor="facility" className="label-primary">
                  Объект
                </label>
                <select
                  name="facility"
                  id="facility"
                  className="select-primary"
                  value={facilityId}
                  onChange={e => {
                    setFacilityId(e.target.value);
                  }}
                >
                  <option value="">Не выбрано</option>
                  {facilities &&
                    sortObj(facilities).map(facility => (
                      <option key={facility.id} value={facility.id}>
                        {facility.name}
                      </option>
                    ))}
                </select>
              </div>

              <div>
                <label htmlFor="role" className="label-primary">
                  Роль
                </label>
                <select
                  name="role"
                  id="role"
                  className="select-primary mt-1"
                  value={role}
                  onChange={e => {
                    setRole(e.target.value);
                  }}
                >
                  <option value="">Не выбрано</option>
                  {meRole === roles.Supervisor
                    ? superWithoutNewbie.map(role => (
                        <option key={role.value} value={role.value}>
                          {role.name}
                        </option>
                      ))
                    : allRoleArray.map(role => (
                        <option key={role.value} value={role.value}>
                          {role.name}
                        </option>
                      ))}
                </select>
              </div>
              <div>
                <label htmlFor="documentStatus" className="label-primary">
                  Статус документов
                </label>
                <select
                  name="documentStatus"
                  id="documentStatus"
                  className="select-primary mt-1"
                  value={documentStatus}
                  onChange={({ target: { value } }) => {
                    setDocumentStatus(value);
                  }}
                >
                  <option value="">Не выбрано</option>
                  {documentsStatusList.map(status => (
                    <option key={status.value} value={status.value}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="isActive" className="label-primary">
                  Статус активности
                </label>
                <select
                  name="isActive"
                  id="isActive"
                  className="select-primary mt-1"
                  value={String(isActive)}
                  onChange={({ target: { value } }) => {
                    setIsActive('');
                    if (value === 'true') {
                      setIsActive(true);
                    }
                    if (value === 'false') {
                      setIsActive(false);
                    }
                  }}
                >
                  <option value="">Не выбрано</option>
                  <option value="true">Активный</option>
                  <option value="false">Не активный</option>
                </select>
              </div>
              <div className="flex gap-x-5">
                <button
                  className="btn-primary"
                  onClick={() => {
                    if (facilityId === '') {
                      removeParam('facilityId');
                    } else {
                      addParams('facilityId', facilityId);
                    }
                    if (role === '') {
                      removeParam('role');
                    } else {
                      addParams('role', role);
                    }
                    if (documentStatus === '') {
                      removeParam('documentStatus');
                    } else {
                      addParams('documentStatus', documentStatus);
                    }
                    if (isActive === '') {
                      removeParam('active');
                    } else {
                      addParams('active', String(isActive));
                    }
                    setParams(params);
                    setFilter(false);
                  }}
                >
                  Применить
                </button>
                <button
                  className="btn-stroke xs:w-auto w-full"
                  onClick={() => {
                    setFacilityId('');
                    setRole('');
                    setSearch('');
                    params.delete('role');
                    params.delete('facilityId');
                    params.delete('search');
                    params.delete('documentStatus');
                    params.delete('active');
                    setParams(params);
                    setFilter(false);
                  }}
                >
                  <span>Сбросить</span>
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="flex">
          {facilityIdParam && (
            <Filter
              content={facilityName(facilities, facilityIdParam)}
              clickHandler={() => {
                setFacilityId('');
                params.delete('facilityId');
                setParams(params);
                setPage(1);
              }}
            />
          )}

          {roleParam && (
            <Filter
              content={getRoleRu(roleParam)}
              clickHandler={() => {
                setRole('');
                params.delete('role');
                setParams(params);
                setPage(1);
              }}
            />
          )}
          {documentStatusParam && (
            <Filter
              content={documentsStatusList.find(status => status.value === documentStatusParam)?.name}
              clickHandler={() => {
                setRole('');
                params.delete('documentStatus');
                setParams(params);
                setPage(1);
              }}
            />
          )}
          {activeParam && (
            <Filter
              content={activeParam === 'true' ? 'Активный' : 'Не активный'}
              clickHandler={() => {
                setRole('');
                params.delete('active');
                setParams(params);
                setPage(1);
              }}
            />
          )}
        </div>
      </div>
      {canCreate && (
        <div className="flex self-start">
          <Link to="/users/create">
            <button type="button" className="btn-primary_big flex items-center">
              <span className="inline-flex mr-2">
                <Plus className="text-smena_white" />
              </span>
              Новый пользователь
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default UsersFilters;
