import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import MainLayout from '../components/Layout/main';
import { positionsColumns } from '../components/Table/columns';
import SkeletonLayout from '../components/Table/Skeleton';
import { useStore } from '../store';
import PositionsTable from '../components/Table/PositionsTable';
import TableHeader from '../components/Table/header';
import Plus from '../Assets/icons/plus';
import { ScrollSync } from 'react-scroll-sync';
import { maxTableWidth } from 'src/utils/maxTableWidth';

const PositionPage: React.FC = () => {
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortWay, setSortWay] = useState<string | null>(null);

  const { positionsLoading } = useStore();

  return (
    <MainLayout
      title="Справочник профессий"
      bg="bg-smena_bb-background_base"
      breadCrumbs={[{ path: '/positions', name: 'Профессии' }]}
    >
      <div className="flex xs:flex-row flex-col-reverse gap-y-5">
        <ScrollSync>
          <div
            className="flex flex-col shadow-smena rounded-lg"
            style={{
              maxWidth: maxTableWidth(positionsColumns) + 'px',
            }}
          >
            <TableHeader
              columns={positionsColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortWay={sortWay}
              setSortWay={setSortWay}
            />

            {positionsLoading ? (
              <SkeletonLayout columns={positionsColumns} />
            ) : (
              <PositionsTable columns={positionsColumns} />
            )}
          </div>
        </ScrollSync>
        <div>
          <Link to="/positions/create">
            <button
              type="button"
              className="btn-primary_big xs:ml-10 flex items-center"
            >
              <span className="inline-flex mr-2">
                <Plus className="text-smena_white" />
              </span>
              Новая профессия
            </button>
          </Link>
        </div>
      </div>
    </MainLayout>
  );
};

export default PositionPage;
