import { useState } from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';

import {
  PayoutType,
  UnitType,
  useRemoveCasingMutation,
  useUpdateCasingMutation,
} from '../../generated/graphql';

import { errorHandler } from '../../utils/errorHandler';
import { errorToast, infoToast, successToast } from '../../utils/notify';

import { useStore } from '../../store';

import { IProfession } from '../../Interfaces/IProffesion';

import SimpleInput from '../ui/Input';
import SimpleCheckbox from '../ui/Checkbox';
import SimpleSelect from '../ui/Select';

import Across from '../../Assets/icons/Across';
import Edit from '../../Assets/icons/edit';
import CheckCircle from '../../Assets/icons/checkCircle';
import { payoutTypes } from '../../utils/lists/payoutTypes';
import { allUnits, hourlyUnit, units } from '../../utils/lists/units';
import { roles } from '../../utils/constVariables';
import { calendarHasProbationDays } from '../../utils/lists/calendarDaysIntership';
import { FacilitiesCasingQueryConfigFn } from 'src/utils/graphqlConfigs/facilityCasingsConfig';

const Profession: React.FC<IProfession> = ({ casing }) => {
  const [edit, setEdit] = useState(false);

  const { positions, me } = useStore();

  const facilitiesCasingQueryConfig = FacilitiesCasingQueryConfigFn(
    casing?.facilityId || ''
  );

  const [removeCasing] = useRemoveCasingMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilitiesCasingQueryConfig],
  });

  const [updateCasing] = useUpdateCasingMutation({
    awaitRefetchQueries: true,
    refetchQueries: [facilitiesCasingQueryConfig],
  });

  const adminSuper =
    me && ![roles.ClientManager, roles.ClientDirector].includes(me.role);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        positionId: String(casing?.positionId),
        payout: String(casing?.payout),
        unit: String(casing?.unit),
        hourRate: casing?.hourRate || 0,
        isSelfEmployed: Boolean(casing?.isSelfEmployed),
        selfEmployedRate: casing?.selfEmployedRate || 0,
        hasProbation: Boolean(casing?.hasProbation),
        probationPeriod: casing?.probationPeriod,
        probationRate: casing?.probationRate,
      }}
      validate={values => {
        let errors: any = {};
        if (!values.hourRate) {
          errors.hourRate = 'Вы не указали ставку';
        }
        if (Number(values.hourRate) === 0) {
          errors.hourRate = 'Ставка не может быть равна 0';
        }
        if (!/^\d+[.]?\d{1,2}$/.test(String(values.hourRate))) {
          errors.hourRate = 'Некорректный ввод';
        }
        if (Number(values.hourRate) > 1000) {
          errors.hourRate = 'Ставка не может быть больше 1000';
        }
        if (Number(values.selfEmployedRate) > 1000) {
          errors.selfEmployedRate = 'Бонус не может быть больше 1000';
        }
        if (values.hasProbation) {
          if (!values.probationPeriod) {
            errors.probationPeriod = 'Вы не указали период';
          }
          if (!values.probationRate) {
            errors.probationRate = 'Вы не указали ставку';
          }
        }
        return errors;
      }}
      onSubmit={async values => {
        await updateCasing({
          variables: {
            input: {
              facilityId: casing?.facilityId || '',
              hourRate: Number(values.hourRate),
              payout: values?.payout as PayoutType,
              unit: values?.unit as UnitType,
              positionId: values.positionId,
              isSelfEmployed: values.isSelfEmployed,
              selfEmployedRate: Number(values.selfEmployedRate),
              hasProbation: values.hasProbation,
              probationPeriod: values.hasProbation
                ? Number(values.probationPeriod)
                : null,
              probationRate: values.hasProbation
                ? Number(values.probationRate)
                : null,
            },
          },
        })
          .then(res => {
            if (res.data?.updateCasing) {
              successToast('Должность изменена');
            }
          })
          .catch(e => {
            errorToast(errorHandler(e));
          })
          .finally(() => {
            setEdit(value => !value);
          });
      }}
    >
      {({
        values,
        setFieldValue,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className={clsx(
              'col-span-full grid add_profession__table items-center px-6',
              adminSuper
                ? 'add_profession__table-edit'
                : ' add_profession__table-view'
            )}
          >
            <span className="Subtitle2 py-3">
              {
                positions?.find(position => position.id === values.positionId)
                  ?.name
              }
            </span>
            {edit ? (
              <>
                <SimpleSelect
                  divClassName="w-full py-3 pr-5"
                  label=""
                  onChange={e => {
                    handleChange(e);
                    if (e.target.value === 'PIECEWORK') {
                      setFieldValue('unit', 'PIECE');
                    } else {
                      setFieldValue('unit', 'HOUR');
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.payout}
                  name="payout"
                  autoComplete="payout"
                  options={payoutTypes}
                  error={touched.payout && errors.payout ? errors.payout : ''}
                />
                <SimpleSelect
                  divClassName="w-full py-3 pr-5"
                  label=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.unit}
                  name="unit"
                  autoComplete="unit"
                  options={values.payout === 'HOURLY' ? hourlyUnit : units}
                  error={touched.unit && errors.unit ? errors.unit : ''}
                />
                <SimpleInput
                  divClassName="py-3 pr-5"
                  label=""
                  onChange={handleChange}
                  float
                  onBlur={handleBlur}
                  value={values.hourRate}
                  type="text"
                  name="hourRate"
                  error={
                    touched.hourRate && errors.hourRate ? errors.hourRate : ''
                  }
                />
                <SimpleCheckbox
                  divClassName="flex items-center py-3 pl-2 pr-5 h-full bg-smena_bb-background_base"
                  label=""
                  required={false}
                  onChange={handleChange}
                  checked={values.isSelfEmployed}
                  name="isSelfEmployed"
                />
                <SimpleInput
                  divClassName="py-3 pr-3 mr-2 h-full bg-smena_bb-background_base flex justify-center"
                  label=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  regText={true}
                  value={values.selfEmployedRate}
                  type="text"
                  name="selfEmployedRate"
                  error={
                    touched.selfEmployedRate && errors.selfEmployedRate
                      ? errors.selfEmployedRate
                      : ''
                  }
                />

                <SimpleCheckbox
                  divClassName="py-3 pr-5"
                  label=""
                  onChange={handleChange}
                  checked={values.hasProbation}
                  name="hasProbation"
                />

                <SimpleSelect
                  divClassName="py-3 pr-5"
                  label=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.probationPeriod || ''}
                  sort={false}
                  disabled={!values.hasProbation}
                  name="probationPeriod"
                  autoComplete="probationPeriod"
                  disabledOption="Выберите период"
                  options={calendarHasProbationDays}
                  error={
                    touched.probationPeriod && errors.probationPeriod
                      ? errors.probationPeriod
                      : ''
                  }
                />
                <SimpleInput
                  divClassName="py-3 pr-5"
                  label=""
                  placeholder="100"
                  onChange={handleChange}
                  regText={true}
                  onBlur={handleBlur}
                  value={Number(values.probationRate)}
                  disabled={!values.hasProbation}
                  type="text"
                  name="probationRate"
                  error={
                    touched.probationRate && errors.probationRate
                      ? errors.probationRate
                      : ''
                  }
                />
              </>
            ) : (
              <>
                <span className="Subtitle2 text-smena_text py-3">
                  {payoutTypes.find(type => type.id === values.payout)?.name}
                </span>
                <span className="Subtitle2 text-smena_text py-3">
                  {allUnits.find(unit => unit.id === values.unit)?.name}
                </span>
                <span className="Subtitle2 text-smena_text py-3">
                  {values.hourRate}₽
                </span>
                <span className="Subtitle2 text-smena_text py-3 pr-5 pl-2 h-full bg-smena_bb-background_base">
                  {values.isSelfEmployed ? 'Да' : 'Нет'}
                </span>
                <span className="Subtitle2 text-smena_text py-3 h-full mr-2 bg-smena_bb-background_base">
                  {values.selfEmployedRate}₽
                </span>
                <span className="Subtitle2 text-smena_text py-3">
                  {values.hasProbation ? 'Да' : 'Нет'}
                </span>
                <span
                  className={clsx(
                    'Subtitle2 py-3',
                    values.hasProbation
                      ? 'text-smena_text'
                      : 'text-smena_text-secondary'
                  )}
                >
                  {calendarHasProbationDays.find(
                    day => day.id === values.probationPeriod
                  )?.name || '-'}
                </span>
                <span
                  className={clsx(
                    'Subtitle2 py-3',
                    values.hasProbation
                      ? 'text-smena_text'
                      : 'text-smena_text-secondary'
                  )}
                >
                  {values.probationRate ? Number(values.probationRate) : '-'}
                </span>
              </>
            )}
            {adminSuper && (
              <div className="flex items-center justify-center gap-x-4 p-3 bg-smena_bb-background_base h-full">
                <button
                  className="justify-self-end transform hover:scale-110"
                  type="button"
                  onClick={async () => {
                    await removeCasing({
                      variables: {
                        facilityId: casing?.facilityId || '',
                        positionId: values.positionId,
                      },
                    })
                      .then(res => {
                        if (res.data?.removeCasing) {
                          infoToast('Профессия удалена');
                        }
                      })
                      .catch(e => {
                        errorToast(errorHandler(e));
                      });
                  }}
                >
                  <Across color={'text-smena_red-mark'} />
                </button>
                {edit ? (
                  <button
                    className="justify-self-end transform hover:scale-110"
                    type="submit"
                  >
                    <CheckCircle className="#1B984D" />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={e => {
                      e.preventDefault();
                      setEdit(value => !value);
                    }}
                    className="justify-self-end transform hover:scale-110"
                  >
                    <Edit />
                  </button>
                )}
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Profession;
