import { useEffect, useState } from 'react';
import {
  RegularManualAccrualItemFragment,
  useWriteManualAccuralItemMutation,
} from '../../../generated/graphql';
import { useStore } from '../../../store';
import { errorHandler } from '../../../utils/errorHandler';
import { errorToast } from '../../../utils/notify';
import PaymentsInput from './input';

interface PaymentsRowInterface {
  item?: {
    __typename?: 'ManualAccrualItem';
  } & RegularManualAccrualItemFragment;
  userId: string | undefined;
  setUserSum: (sum: number) => void;
}

const PaymentsRow = ({ item, userId, setUserSum }: PaymentsRowInterface) => {
  const [onFocus, setOnFocus] = useState(false);
  const { manualAccural, disabledUsersIds } = useStore();
  const [inputState, setInputState] = useState<{
    rateAmount?: number | null;
    hardWorker?: number | null;
    activeMonth?: number | null;
    superWorker?: number | null;
    totalAmount?: number | null;
  }>({
    rateAmount: item?.rateAmount,
    hardWorker: item?.hardWorker,
    activeMonth: item?.activeMonth,
    superWorker: item?.superWorker,
    totalAmount: item?.totalAmount,
  });

  const [writeManualAccuralItem] = useWriteManualAccuralItemMutation();
  const hourRate = manualAccural?.facility?.WorkPosts?.find(
    workpost => workpost.positionId === item?.position?.id
  )?.hourRate;

  const canEdit = disabledUsersIds.includes(userId || '');

  useEffect(() => {
    setUserSum(item?.userSum!);
    // eslint-disable-next-line
  }, []);

  const send = async () => {
    const { activeMonth, hardWorker, rateAmount, superWorker } = inputState;
    await writeManualAccuralItem({
      variables: {
        input: {
          accrualId: manualAccural ? manualAccural?.id : '',
          activeMonth,
          hardWorker,
          rateAmount,
          superWorker,
          totalAmount: null,
          userId: userId || '',
          positionId: item?.position?.id,
        },
      },
    })
      .then(e => {
        setInputState(e.data?.writeManualAccuralItem!);
        setUserSum(e.data?.writeManualAccuralItem.userSum!);
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  return (
    <div
      className={`grid justify-items-start grid-cols-7  ${
        onFocus ? 'bg-smena_bb-background' : ''
      }`}
      style={{
        gridTemplateColumns: '2fr repeat(6, 1fr)',
      }}
    >
      <span className="p-2 text-left text-sm text-smena_gray-50 tracking-wider w-full">
        {item?.position?.name}
      </span>
      <span
        className="block p-2 w-full text-sm border-none bg-transparent font-roboto"
        onFocus={() => {
          setOnFocus(true);
        }}
      >
        {hourRate === null ? 'Не указана' : hourRate}
      </span>
      <PaymentsInput
        setOnFocus={setOnFocus}
        value={item?.rateAmount}
        setInputState={setInputState}
        name="rateAmount"
        send={send}
        canEdit={canEdit}
      />
      <PaymentsInput
        setOnFocus={setOnFocus}
        value={item?.hardWorker}
        setInputState={setInputState}
        name="hardWorker"
        send={send}
        canEdit={canEdit}
      />
      <PaymentsInput
        setOnFocus={setOnFocus}
        value={item?.activeMonth}
        setInputState={setInputState}
        name="activeMonth"
        send={send}
        canEdit={canEdit}
      />
      <PaymentsInput
        setOnFocus={setOnFocus}
        value={item?.superWorker}
        setInputState={setInputState}
        name="superWorker"
        send={send}
        canEdit={canEdit}
      />
      <span className="p-2 text-left text-xs font-medium text-smena_gray-50 uppercase tracking-wider font-roboto">
        {inputState?.totalAmount}
      </span>
    </div>
  );
};

export default PaymentsRow;
