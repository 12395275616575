export const MagnifierPlus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6234 10.875C14.6234 11.0739 14.5444 11.2647 14.4038 11.4054C14.2631 11.546 14.0723 11.625 13.8734 11.625H11.6234V13.875C11.6234 14.0739 11.5444 14.2647 11.4038 14.4054C11.2631 14.546 11.0723 14.625 10.8734 14.625C10.6745 14.625 10.4838 14.546 10.3431 14.4054C10.2025 14.2647 10.1234 14.0739 10.1234 13.875V11.625H7.87344C7.67452 11.625 7.48376 11.546 7.34311 11.4054C7.20245 11.2647 7.12344 11.0739 7.12344 10.875C7.12344 10.6761 7.20245 10.4853 7.34311 10.3447C7.48376 10.204 7.67452 10.125 7.87344 10.125H10.1234V7.87502C10.1234 7.67611 10.2025 7.48535 10.3431 7.34469C10.4838 7.20404 10.6745 7.12502 10.8734 7.12502C11.0723 7.12502 11.2631 7.20404 11.4038 7.34469C11.5444 7.48535 11.6234 7.67611 11.6234 7.87502V10.125H13.8734C14.0723 10.125 14.2631 10.204 14.4038 10.3447C14.5444 10.4853 14.6234 10.6761 14.6234 10.875ZM21.5328 21.5344C21.3895 21.673 21.1978 21.7505 20.9984 21.7505C20.799 21.7505 20.6074 21.673 20.4641 21.5344L16.4141 17.475C14.7084 18.9077 12.5154 19.6266 10.2926 19.4817C8.06972 19.3369 5.98862 18.3394 4.48329 16.6975C2.97797 15.0555 2.16466 12.8958 2.21297 10.6688C2.26129 8.44175 3.16751 6.31934 4.74264 4.74422C6.31776 3.1691 8.44016 2.26288 10.6672 2.21456C12.8942 2.16624 15.0539 2.97956 16.6959 4.48488C18.3379 5.9902 19.3353 8.07131 19.4801 10.2942C19.625 12.517 18.9061 14.7099 17.4734 16.4156L21.5328 20.4656C21.6736 20.6078 21.7527 20.7999 21.7527 21C21.7527 21.2002 21.6736 21.3922 21.5328 21.5344ZM10.8734 18C12.2826 18 13.6602 17.5822 14.8319 16.7992C16.0036 16.0163 16.9168 14.9036 17.4561 13.6016C17.9954 12.2997 18.1365 10.8671 17.8615 9.485C17.5866 8.10289 16.908 6.83334 15.9116 5.83689C14.9151 4.84044 13.6456 4.16185 12.2635 3.88693C10.8813 3.61201 9.44874 3.75311 8.14682 4.29238C6.84489 4.83166 5.73212 5.74489 4.94922 6.91659C4.16631 8.08829 3.74844 9.46583 3.74844 10.875C3.75092 12.7639 4.50238 14.5748 5.83804 15.9104C7.1737 17.2461 8.98453 17.9975 10.8734 18Z"
        fill="white"
      />
    </svg>
  );
};
