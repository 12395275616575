import { FilterPeriod } from '../../generated/graphql';

export const intervalChart = (time: FilterPeriod) => {
  switch (time) {
    case FilterPeriod.Day:
    case FilterPeriod.AllTime:
      // case FilterPeriod.HalfYear:
      // case FilterPeriod.Year:
      return 1;
    default:
      return 0;
  }
};
